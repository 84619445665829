import { Alert, AlertTitle, Box, Paper, Stack } from '@mui/material'
import { ApiError } from 'types'
import { toText } from 'utils/helpers'

interface Props {
  apiError: ApiError
}

export default function ApiErrorView({
  apiError
}: Props): JSX.Element {
  return (
    <Stack
      direction='column'
      spacing={1}
      alignItems='center'
      justifyContent='center'
      sx={{
        width: '100%',
        height: '100%'
      }}>
      <Box flexGrow={1} />
      <Paper>
        <Alert
          severity='error'
          variant='filled'>
          <AlertTitle>Error</AlertTitle>
          {toText(apiError)}
        </Alert>
      </Paper>
      <Box flexGrow={2} />
    </Stack>
  )
}