import { ChevronRight, ExpandMore } from '@mui/icons-material'
import { Box, Checkbox } from '@mui/material'
import { TreeItem, TreeView } from '@mui/x-tree-view'
import { DomainTypeCell } from 'components/attribute/AttributeCell'
import { useCallback, useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { ContextDomainTypeNode, ContextTree } from 'types'
import { DomainTypeSettingsContext } from 'utils/context'
import { getHeading } from 'utils/helpers'

export const NODE_ID_SEPARATOR = '_'

export function getNodeIds<T = unknown>(
  node: ContextDomainTypeNode<T>,
  nodeIdPath: string[],
  getAdditionalNodeIds?: (node: ContextDomainTypeNode<T> | null, nodeIdPath: string[]) => string[]
): string[] {
  return [
    nodeIdPath.join(NODE_ID_SEPARATOR),
    ...node.nodes.flatMap(attributeNode => attributeNode.nodes.flatMap((node, index) => getNodeIds(
      node,
      [...nodeIdPath, attributeNode.attribute.Name, String(index)],
      getAdditionalNodeIds
    ))),
    ...getAdditionalNodeIds?.(node, nodeIdPath) ?? []
  ]
}

function isChildOf(nodeId: string): (id: string) => boolean {
  return id => `${id}_`.startsWith(`${nodeId}_`)
}

function isStrictChildOf(nodeId: string): (id: string) => boolean {
  return id => id.startsWith(`${nodeId}_`)
}

function isNotChildOf(nodeId: string): (id: string) => boolean {
  return id => !isChildOf(nodeId)(id)
}

function isParentOf(nodeId: string): (id: string) => boolean {
  return id => nodeId.startsWith(`${id}_`)
}

interface DomainTypeNodeProps<T = unknown> {
  readonly node: ContextDomainTypeNode<T>
  readonly nodeIdPath: string[]
  readonly labelPath: string[]
  readonly selectable?: boolean
  readonly selectedNodeIds: string[]
  readonly onClickCheckbox: (id: string) => void
  readonly renderAdditionalNodes?: (node: ContextDomainTypeNode<T> | null, nodeIdPath: string[], labelPath: string[]) => (JSX.Element | null)[]
}

function DomainTypeNode<T = unknown>({
  node,
  nodeIdPath,
  labelPath,
  selectable = false,
  selectedNodeIds,
  onClickCheckbox,
  renderAdditionalNodes
}: DomainTypeNodeProps<T>): JSX.Element {
  const nodeId = nodeIdPath.join(NODE_ID_SEPARATOR)
  const allSelectableChildNodeIds = useMemo(() => {
    return getNodeIds(node, nodeIdPath)
  }, [node, nodeIdPath])
  const selectedChildNodeIds = useMemo(() => {
    return selectedNodeIds.filter(isChildOf(nodeId))
  }, [nodeId, selectedNodeIds])
  const settingsContext = useContext(DomainTypeSettingsContext)
  const domainTypes = useSelector(getAllDomainTypes)
  const label = useMemo(() => {
    return (
      <Box
        display='flex'
        alignItems='center'
        gap={1}>
        {selectable && (
          <Checkbox
            size='small'
            checked={selectedNodeIds.includes(nodeId)}
            indeterminate={selectedChildNodeIds.length > 0
              && selectedChildNodeIds.length < allSelectableChildNodeIds.length}
            onClick={event => {
              event.stopPropagation()
              onClickCheckbox(nodeId)
            }} />
        )}
        <DomainTypeCell
          disableLink
          attributeValue={{
            attribute: {
              Name: '',
              Title: '',
              AttributeType: 'domainType',
              AttributeDomainType: node.domainType.Id
            },
            value: node.instance
          }} />
      </Box>
    )
  }, [allSelectableChildNodeIds.length, node.domainType, node.instance, nodeId, onClickCheckbox, selectable, selectedChildNodeIds.length, selectedNodeIds])
  const nextLabelPath = useMemo(() => {
    return [...labelPath, getHeading(settingsContext, domainTypes, node.domainType, node.instance)]
  }, [domainTypes, labelPath, node.domainType, node.instance, settingsContext])
  const childItems = useMemo(() => {
    return node.nodes.map(attributeNode => attributeNode.nodes.map((node, index) => (
      <DomainTypeNode
        key={index}
        node={node}
        nodeIdPath={[...nodeIdPath, attributeNode.attribute.Name, String(index)]}
        labelPath={nextLabelPath}
        selectable={selectable}
        selectedNodeIds={selectedNodeIds}
        onClickCheckbox={onClickCheckbox}
        renderAdditionalNodes={renderAdditionalNodes} />
    )))
  }, [node.nodes, nodeIdPath, nextLabelPath, selectable, selectedNodeIds, onClickCheckbox, renderAdditionalNodes])
  const additionalItems = useMemo(() => {
    return renderAdditionalNodes?.(node, nodeIdPath, nextLabelPath) ?? []
  }, [nextLabelPath, node, nodeIdPath, renderAdditionalNodes])
  return childItems.length > 0 || additionalItems.length > 0
    ? (
      <TreeItem
        nodeId={nodeId}
        label={label}>
        {childItems}
        {additionalItems}
      </TreeItem>
    )
    : (
      <TreeItem
        nodeId={nodeId}
        label={label} />
    )
}

interface Props<T> {
  readonly contextTree: ContextTree<T>
  readonly includeRootNode?: boolean
  readonly defaultUnexpanded?: boolean
  readonly selectable?: boolean
  readonly selectedNodeIds?: string[]
  readonly onSelectionChange?: (ids: string[]) => void
  readonly getAdditionalNodeIds?: (node: ContextDomainTypeNode<T> | null, nodeIdPath: string[]) => string[]
  readonly renderAdditionalNodes?: (node: ContextDomainTypeNode<T> | null, nodeIdPath: string[], labelPath: string[]) => (JSX.Element | null)[]
}

export default function ContextTreeView<T = unknown>({
  contextTree,
  includeRootNode = false,
  defaultUnexpanded = false,
  selectable = false,
  selectedNodeIds = [],
  onSelectionChange,
  getAdditionalNodeIds,
  renderAdditionalNodes
}: Props<T>): JSX.Element {
  const additionalRootNodeIds = useMemo(() => {
    return getAdditionalNodeIds?.(null, []) ?? []
  }, [getAdditionalNodeIds])
  const allSelectableNodeIds = useMemo(() => {
    return contextTree
      .flatMap((node, index) => getNodeIds(node, [String(index)]))
      .concat(additionalRootNodeIds)
  }, [additionalRootNodeIds, contextTree])
  const allNodeIds = useMemo(() => {
    return contextTree
      .flatMap((node, index) => getNodeIds(node, [String(index)], getAdditionalNodeIds))
      .concat(additionalRootNodeIds)
  }, [additionalRootNodeIds, contextTree, getAdditionalNodeIds])
  const onClickCheckbox = useCallback((id: string) => {
    const childNodeIds = allSelectableNodeIds
      .filter(isChildOf(id))
    if (!childNodeIds.every(nodeId => selectedNodeIds.includes(nodeId))) {
      const parentNodeIds = allSelectableNodeIds
        .filter(isParentOf(id))
      onSelectionChange?.([
        ...new Set(
          selectedNodeIds
            .concat(childNodeIds)
            .concat(parentNodeIds)
        )
      ])
    } else {
      let nextSelectedNodeIds = selectedNodeIds
      let parentNodeId = id
      do {
        nextSelectedNodeIds = nextSelectedNodeIds
          .filter(isNotChildOf(parentNodeId))
        parentNodeId = parentNodeId.split(NODE_ID_SEPARATOR).slice(0, -1).join(NODE_ID_SEPARATOR)
      } while (parentNodeId !== '' && !nextSelectedNodeIds.some(isStrictChildOf(parentNodeId)))
      onSelectionChange?.(nextSelectedNodeIds)
    }
  }, [allSelectableNodeIds, onSelectionChange, selectedNodeIds])
  const treeItems = useMemo(() => {
    const additionalNodes = (renderAdditionalNodes?.(null, [], []) ?? [])
      .filter((element): element is JSX.Element => element !== null)
    return contextTree.map((node, index) => (
      <DomainTypeNode
        key={index}
        node={node}
        nodeIdPath={[String(index)]}
        labelPath={[]}
        selectable={selectable}
        selectedNodeIds={selectedNodeIds}
        onClickCheckbox={onClickCheckbox}
        renderAdditionalNodes={renderAdditionalNodes} />
    )).concat(additionalNodes)
  }, [contextTree, onClickCheckbox, renderAdditionalNodes, selectable, selectedNodeIds])
  return (
    <TreeView
      defaultExpanded={defaultUnexpanded
        ? []
        : allNodeIds}
      defaultCollapseIcon={<ExpandMore />}
      defaultExpandIcon={<ChevronRight />}
      sx={{ width: '100%' }}>
      {includeRootNode
        ? (
          <TreeItem
            nodeId=''
            label={(
              <Box
                display='flex'
                alignItems='center'
                gap={1}>
                {selectable && (
                  <Checkbox
                    size='small'
                    checked={selectedNodeIds.length === allSelectableNodeIds.length}
                    indeterminate={selectedNodeIds.length > 0 && selectedNodeIds.length < allSelectableNodeIds.length}
                    onClick={event => {
                      event.stopPropagation()
                    }}
                    onChange={(event, checked) => {
                      if (selectedNodeIds.length === allSelectableNodeIds.length) {
                        onSelectionChange?.([])
                      } else {
                        onSelectionChange?.(allSelectableNodeIds)
                      }
                    }} />
                )}
                All (Click To Expand)
              </Box>
            )}>
            {treeItems}
          </TreeItem>
        )
        : treeItems}
    </TreeView>
  )
}