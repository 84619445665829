import { GridRenderCellParams } from '@mui/x-data-grid'
import AttributeCell from 'components/attribute/AttributeCell'
import AppendDomainTypeContext from 'components/domainType/AppendDomainTypeContext'
import { memo, useMemo } from 'react'
import { Attribute, AttributeChainValue, ChainValue, DomainType, DomainTypeAttribute, DomainTypeInstance } from 'types'

interface CustomCellProps {
  params: GridRenderCellParams
  attribute: Attribute
  domainTypeChain: DomainType[]
  attributeChain: DomainTypeAttribute[]
  valueGetters: ((params: GridRenderCellParams) => ChainValue)[]
}
function CustomCell({
  params,
  attribute,
  domainTypeChain,
  attributeChain,
  valueGetters
}: CustomCellProps): JSX.Element {
  const newContext = useMemo(() => {
    return domainTypeChain
      .map((type, index): [DomainType, DomainTypeInstance] => {
        return [type, valueGetters[index]?.(params) ?? {}] as [DomainType, DomainTypeInstance]
      })
  }, [domainTypeChain, params, valueGetters])
  const attributeChainValue = useMemo<AttributeChainValue>(() => {
    const value = valueGetters[valueGetters.length - 1]?.(params) ?? null
    return {
      attribute,
      value
    }
  }, [attribute, valueGetters, params])
  return (
    <AppendDomainTypeContext
      newInstances={newContext}
      newAttributes={attributeChain}>
      <AttributeCell
        attributeChainValue={attributeChainValue}
        columnWidth={params.colDef.computedWidth}
        disableLink />
    </AppendDomainTypeContext>
  )
}

export default memo(CustomCell, (v1, v2) => {
  return v1.params.row === v2.params.row
    && v1.params.colDef.computedWidth === v2.params.colDef.computedWidth
})