import { TextField, TextFieldProps } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { DateTime } from 'luxon'
import { useCallback } from 'react'
import { DateAttributeValue } from 'types'
import { DEFAULT_ATTRIBUTE_INPUT_SIZE, DEFAULT_ATTRIBUTE_INPUT_VARIANT } from 'utils/constants'
import { dateToString, isListAttributeValue, toErrorText } from 'utils/helpers'
import { NarrowedProps } from './AttributeInput'
import NotSupportedInput from './NotSupportedInput'

const MASK = '__/__/____'

function isInvalidDate(
  value: string | null,
  inputValue: unknown
): boolean {
  return value === null
    && String(inputValue ?? '').length === MASK.length
}

export default function DateInput({
  attributeValue,
  readOnly,
  disabled,
  pathError,
  textFieldProps,
  onChange
}: NarrowedProps<DateAttributeValue>): JSX.Element | null {
  const handleChange = useCallback((date: DateTime | null) => {
    if (onChange === undefined) {
      return
    }
    onChange({
      attribute: attributeValue.attribute,
      value: date?.toUTC().toISO() ?? null
    })
  }, [attributeValue.attribute, onChange])
  if (isListAttributeValue(attributeValue)) {
    return (
      <NotSupportedInput
        attributeValue={attributeValue}
        textFieldProps={textFieldProps} />
    )
  }
  return (
    <DatePicker
      label={attributeValue.attribute.Title}
      value={dateToString(attributeValue.value)}
      onChange={handleChange}
      disabled={disabled}
      readOnly={readOnly}
      mask={MASK}
      renderInput={(params: TextFieldProps) => {
        const isInvalid = isInvalidDate(attributeValue.value, params.inputProps?.value)
        const helperText = isInvalid
          ? 'Invalid Date'
          : toErrorText(pathError)
        return (
          <TextField
            {...params}
            variant={DEFAULT_ATTRIBUTE_INPUT_VARIANT}
            fullWidth
            size={DEFAULT_ATTRIBUTE_INPUT_SIZE}
            required={attributeValue.attribute.Required ?? false}
            error={isInvalid || pathError !== undefined}
            helperText={helperText}
            {...textFieldProps} />
        )
      }} />
  )
}
