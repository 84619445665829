import { Close, DynamicForm, LockOutlined } from '@mui/icons-material'
import { Box, ButtonGroup, Typography } from '@mui/material'
import AppendDomainTypeContext from 'components/domainType/AppendDomainTypeContext'
import DomainTypeButtons from 'components/domainType/DomainTypeButtons'
import DomainTypeHeading from 'components/domainType/DomainTypeHeading'
import TextIconButton from 'components/utils/TextIconButton'
import TooltipIconButton from 'components/utils/TooltipIconButton'
import { ComponentProps, useMemo } from 'react'
import { DataformResultsAttribute, DomainType, DomainTypeInstance, MultiDataformResultsAttribute } from 'types'
import { Dataform, Results } from 'types/dataform'
import { DATAFORM_RESULTS_DOMAIN_TYPE_NAME, PATH_SEPARATOR } from 'utils/constants'
import { DomainTypeContext } from 'utils/context'
import { isNullOrUndefined } from 'utils/helpers'
import { useDataformResultsAttribute, useDomainType } from 'utils/hooks'
import { NotInitialisedState, State, stateToResults } from './dataformReducer'

interface HeaderProps {
  readonly dataformDomainType: DomainType | null
  readonly state: State | NotInitialisedState
  readonly attribute: DataformResultsAttribute | [MultiDataformResultsAttribute, string] | null
  readonly readOnly: boolean
  readonly showNotFilledInAlert: boolean
  readonly isLoading: boolean
  onUnlock(): void
  onClose?(): void
}

const components: ComponentProps<typeof DomainTypeButtons>['components'] = {
  Container: props => (
    <ButtonGroup
      variant='contained'
      {...props} />
  ),
  Button: props => (
    <TextIconButton
      iconOnly
      {...props} />
  ),
  Empty: null
}

interface ReadOnlyDataformResultsButtonsProps {
  readonly results: Results
  readonly attribute: DataformResultsAttribute | [MultiDataformResultsAttribute, string] | null
}
function ReadOnlyDataformResultsButtons({
  results,
  attribute
}: ReadOnlyDataformResultsButtonsProps): JSX.Element | null {
  const dataformResultsDomainType = useDomainType(DATAFORM_RESULTS_DOMAIN_TYPE_NAME, null)
  const dataformResultsAttribute = useDataformResultsAttribute()
  const newAttributes = useMemo(() => {
    return isNullOrUndefined(dataformResultsAttribute)
      ? []
      : [dataformResultsAttribute]
  }, [dataformResultsAttribute])
  const instances = useMemo<DomainTypeInstance[]>(() => {
    if (isNullOrUndefined(attribute)) {
      return []
    }
    const details = Array.isArray(attribute)
      ? {
        Id: `${attribute[0].Name}_${attribute[1]}`,
        Title: `${attribute[0].Title}${PATH_SEPARATOR}${attribute[1]}`
      }
      : {
        Id: attribute.Name,
        Title: attribute.Title
      }
    return [
      {
        ...results,
        ...details
      }
    ]
  }, [attribute, results])
  if (dataformResultsDomainType === null
    || isNullOrUndefined(attribute)) {
    return null
  }
  return (
    <AppendDomainTypeContext
      newAttributes={newAttributes}>
      <DomainTypeButtons
        domainType={dataformResultsDomainType}
        target={{
          type: 'instances',
          instances
        }}
        on='TableToolbar'
        components={components}
        priority='medium'
        renderPopoverButtonInsideContainer />
    </AppendDomainTypeContext>
  )
}

interface NonReadOnlyDataformResultsButtonsProps {
  readonly dataformDomainType: DomainType | null
  readonly dataform: Dataform
  readonly results: Results
}
function NonReadOnlyDataformResultsButtons({
  dataformDomainType,
  dataform,
  results
}: NonReadOnlyDataformResultsButtonsProps): JSX.Element | null {
  if (dataformDomainType === null) {
    return null
  }
  return (
    <DomainTypeContext.Provider
      value={{
        instances: [],
        attributes: [],
        batchInstances: []
      }}>
      <DomainTypeButtons
        domainType={dataformDomainType}
        target={{
          type: 'instances',
          instances: [dataform as unknown as DomainTypeInstance]
        }}
        on={null}
        components={components}
        priority='medium'
        renderPopoverButtonInsideContainer
        parameterValues={[
          {
            attribute: {
              Name: 'Results',
              Title: 'Results',
              AttributeType: 'dataformResults',
              SelectBy: 'name'
            },
            value: results
          }
        ]} />
    </DomainTypeContext.Provider>
  )
}

export default function Header({
  dataformDomainType,
  state,
  attribute,
  readOnly,
  showNotFilledInAlert,
  isLoading,
  onClose,
  onUnlock
}: HeaderProps): JSX.Element {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: 1,
        p: 1
      }}>
      {dataformDomainType === null
        ? (
          <>
            <DynamicForm color='primary' />
            <Typography variant='subtitle1'>Dataform</Typography>
          </>
        )
        : (
          <DomainTypeHeading
            domainType={dataformDomainType}
            instance={state.type === 'notInitialised'
              ? undefined
              : state.dataform as unknown as DomainTypeInstance}
            isLoading={isLoading}
            title='Dataform:'>
            {state.type === 'initialised' && !readOnly && state.complete && (
              <TooltipIconButton
                tooltipText='Unlock'
                icon={<LockOutlined />}
                onClick={onUnlock} />
            )}
            <Box flexGrow={1} />
            {state.type === 'initialised'
              && !showNotFilledInAlert
              && (
                readOnly
                  ? (
                    <ReadOnlyDataformResultsButtons
                      results={stateToResults(state)}
                      attribute={attribute} />
                  )
                  : (
                    <NonReadOnlyDataformResultsButtons
                      dataformDomainType={dataformDomainType}
                      dataform={state.dataform}
                      results={stateToResults(state)} />
                  )
              )}
            {onClose !== undefined && (
              <TooltipIconButton
                tooltipText='Close'
                icon={<Close />}
                onClick={onClose} />
            )}
          </DomainTypeHeading>
        )}
    </Box>
  )
}