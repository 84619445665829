import { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes, getCompany, getUser } from 'state/reducers'
import { AttributeValue, DomainType } from 'types'
import { getDomainTypeActions, isNullOrUndefined } from 'utils/helpers'
import { ActionButton, getActionButton, useFilterContext } from 'utils/hooks'
import { ItemTimelineItem } from '../types'
import { DomainTypeContext } from 'utils/context'
import { getTimelineItemsActionDetails } from '../helpers'

export default function useTimelineItemsActionButton(
  domainType: DomainType,
  itemsDomainType: DomainType,
  actionName: string,
  selectedItems: ItemTimelineItem[],
  parameterValues?: AttributeValue[]
): ActionButton | null {
  const domainTypes = useSelector(getAllDomainTypes)
  const filterContext = useFilterContext()
  const domainTypeContext = useContext(DomainTypeContext)
  const user = useSelector(getUser)
  const company = useSelector(getCompany)
  const actionDetails = useMemo(() => {
    const itemsActions = getDomainTypeActions(domainTypes, itemsDomainType)
    const itemsAction = itemsActions.find(([, action]) => action.Name === actionName)
    return getTimelineItemsActionDetails(
      user,
      itemsAction,
      selectedItems,
      filterContext,
      domainTypeContext,
      domainType,
      itemsDomainType,
      {},
      {}
    )
  }, [actionName, domainType, domainTypeContext, domainTypes, filterContext, itemsDomainType, selectedItems, user])
  return useMemo(() => {
    if (isNullOrUndefined(actionDetails)) {
      return null
    }
    return getActionButton(
      domainTypes,
      actionDetails,
      user,
      company,
      {
        type: 'instances',
        instances: []
      },
      parameterValues
    )
  }, [actionDetails, company, domainTypes, parameterValues, user])
}