import { Box, useTheme } from '@mui/material'
import DomainTypeTooltip from 'components/domainType/DomainTypeTooltip'
import * as O from 'fp-ts/Option'
import { Id, ReactCalendarItemRendererProps } from 'react-calendar-timeline'
import { useSelector } from 'react-redux'
import { getAllDomainTypes, getUser } from 'state/reducers'
import { getContrastingColour, getUniqueId } from 'utils/helpers'
import { TIMELINE_HOVER_EVENT_SOURCE, getMouseDownMessage, getTimelineItemInstance } from '../helpers'
import { CustomTimelineItem, MovingStop } from '../types'
import TimelineRoute from './TimelineRoute'

interface Props {
  readonly rendererProps: ReactCalendarItemRendererProps<CustomTimelineItem>
  readonly movingStop: MovingStop | null
  addMessage(message: string): void
  handleContextMenu(event: React.MouseEvent<HTMLDivElement>, id: string): void
  onStopDragStart(id: Id): void
  onStopDragEnd(): void
  onMoveRouteStop(movingStop: MovingStop): void
}

export default function TimelineItem({
  rendererProps: {
    item,
    itemContext,
    getItemProps,
    getResizeProps
  },
  movingStop,
  addMessage,
  handleContextMenu,
  onStopDragStart,
  onStopDragEnd,
  onMoveRouteStop
}: Props): JSX.Element {
  const theme = useTheme()
  const user = useSelector(getUser)
  const domainTypes = useSelector(getAllDomainTypes)
  if (item.type === 'route') {
    return (
      <TimelineRoute
        item={item}
        itemContext={itemContext}
        getItemProps={getItemProps}
        getResizeProps={getResizeProps}
        movingStop={movingStop}
        addMessage={addMessage}
        handleContextMenu={handleContextMenu}
        onStopDragStart={onStopDragStart}
        onStopDragEnd={onStopDragEnd}
        onMoveRouteStop={onMoveRouteStop} />
    )
  }
  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps()
  const colour = getContrastingColour(
    item.colour ?? theme.palette.primary.main,
    theme.palette.mode,
    theme.palette.contrastThreshold
  )
  const instance = getTimelineItemInstance(item)
  const itemProps = getItemProps({
    ...item.itemProps,
    style: {
      background: colour,
      color: theme.palette.getContrastText(colour)
    }
  })
  return (
    <DomainTypeTooltip
      domainType={item.subtype}
      instance={instance}
      enableHeadingLink
      hoverEventSource={TIMELINE_HOVER_EVENT_SOURCE}>
      <div
        {...itemProps}
        className={`${itemProps.className} ${getUniqueId(domainTypes, item.subtype, instance)}`}
        title={undefined}
        onMouseDown={event => {
          event.stopPropagation()
          if (event.button !== 0) {
            return
          }
          if (item.canMove === true) {
            return
          }
          const mouseDownMessage = getMouseDownMessage(item, user)
          if (O.isSome(mouseDownMessage)) {
            addMessage(mouseDownMessage.value)
          }
        }}
        onContextMenu={event => {
          handleContextMenu(event, String(item.id))
        }}>
        {item.canResize === 'both'
          ? (
            <div {...leftResizeProps}>
              <div className='rct-item-handler-icon' />
            </div>
          )
          : ''}
        <div
          className='rct-item-content'
          style={{ maxHeight: `${itemContext.dimensions.height}` }}>
          {item.items.length > 1 && (
            <Box
              sx={{
                bgcolor: theme => theme.palette.augmentColor({
                  color: {
                    main: colour
                  }
                }).light,
                ml: '-6px',
                p: 0.5,
                mr: 0.5
              }}>
              {item.items.length}
            </Box>
          )}
          {itemContext.title}
        </div>
        {item.canResize === 'both'
          ? (
            <div {...rightResizeProps}>
              <div className='rct-item-handler-icon' />
            </div>
          )
          : ''}
      </div>
    </DomainTypeTooltip>
  )
}