import { ApiKeyCreateButton, DataformDetails, DataformResultsCell, DomainTypeComponentOverrides, EquipmentCell, EquipmentInput, ImageHandleCell, ImageHandleTableSection, PersonCreateDialog } from 'components/overrides'
import What3WordsInput from 'components/overrides/input/What3WordsInput'
import { createContext } from 'react'

interface Context {
  readonly global: DomainTypeComponentOverrides
  readonly overrides: Partial<Record<string, DomainTypeComponentOverrides>>
}

export default createContext<Context>({
  global: {},
  overrides: {
    Equipment: {
      cell: EquipmentCell,
      input: EquipmentInput
    },
    'Dataform:Dataform': {
      details: DataformDetails
    },
    DataformResults: {
      cell: DataformResultsCell
    },
    'ApiKey:ApiKey': {
      createButton: ApiKeyCreateButton
    },
    'Person:Person': {
      createDialog: PersonCreateDialog
    },
    ImageHandle: {
      tableSection: ImageHandleTableSection,
      cell: ImageHandleCell
    },
    What3Words: {
      input: What3WordsInput
    }
  }
})
