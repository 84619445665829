import { Box, useTheme } from '@mui/material'
import { ResultsInput } from 'components/dataform'
import { useMemo } from 'react'
import { NonListDataformResultsAttributeValue } from 'types'
import { DATAFORM_RESULTS_STATUSES, TOOLTIP_DELAY } from 'utils/constants'
import { getDataformResultsCompleteness, getDataformResultsIcon } from 'utils/helpers'
import { useDataformResults } from 'utils/hooks'
import LightTooltip from '../../utils/LightTooltip'
import { NarrowedProps } from './AttributeCell'
import EnumCell from './EnumCell'

export default function DataformResultsCell({
  attributeValue,
  columnWidth,
  disableTooltip = false
}: NarrowedProps<NonListDataformResultsAttributeValue> & { disableTooltip?: boolean }): JSX.Element | null {
  const [dataform, results, errorMessage] = useDataformResults(attributeValue)
  const theme = useTheme()
  const [cornerIcon, color] = getDataformResultsIcon(attributeValue.value, theme)
  const completeness = getDataformResultsCompleteness(attributeValue.value)
  const cell = useMemo(() => {
    return (
      <EnumCell
        attributeValue={{
          attribute: {
            ...attributeValue.attribute,
            AttributeType: 'enum',
            EnumeratedType: {
              ExternalId: 'Status',
              GlobalExternalId: 'Status',
              Values: [
                {
                  Id: '',
                  Description: DATAFORM_RESULTS_STATUSES[completeness],
                  Value: String(completeness),
                  Colour: color,
                  Icon: cornerIcon
                }
              ]
            }
          },
          value: completeness
        }} />
    )
  }, [attributeValue.attribute, color, completeness, cornerIcon])
  if (disableTooltip || completeness === 3) {
    return cell
  }
  return (
    <LightTooltip
      enterDelay={TOOLTIP_DELAY}
      enterNextDelay={TOOLTIP_DELAY}
      minWidth={750}
      maxWidth={750}
      minHeight={400}
      maxHeight={400}
      title={(
        <ResultsInput
          key={JSON.stringify(results)}
          dataform={dataform}
          initialResults={results}
          attribute={attributeValue.attribute}
          errorMessage={errorMessage}
          readOnly
          maxHeight='279px'
          minHeight='279px' />
      )}>
      <Box sx={{ cursor: 'pointer' }}>
        {cell}
      </Box>
    </LightTooltip>
  )
}
