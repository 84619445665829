import { Box, CardActionArea, CardContent, CircularProgress, Grid, Card as MaterialCard, Paper, Stack } from '@mui/material'
import { DomainTypeGridSummary } from 'components/domainType/DomainTypeSummary'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { DomainType, DomainTypeInstance } from 'types'
import { getDomainTypeSetting } from 'utils/helpers'
import FindPagination from '../FindPagination'
import { ROW_HEIGHT } from '../TableView'

const COLUMN_WIDTH = 400

const emptyItemsToFixLayout = [
  <Grid
    key='layout-0'
    item
    pt={0}
    flex={`1 1 ${COLUMN_WIDTH}px`} />,
  <Grid
    key='layout-1'
    item
    pt={0}
    flex={`1 1 ${COLUMN_WIDTH}px`} />,
  <Grid
    key='layout-2'
    item
    pt={0}
    flex={`1 1 ${COLUMN_WIDTH}px`} />,
  <Grid
    key='layout-3'
    item
    pt={0}
    flex={`1 1 ${COLUMN_WIDTH}px`} />,
  <Grid
    key='layout-4'
    item
    pt={0}
    flex={`1 1 ${COLUMN_WIDTH}px`} />,
  <Grid
    key='layout-5'
    item
    pt={0}
    flex={`1 1 ${COLUMN_WIDTH}px`} />
]

interface Props {
  domainType: DomainType
  items: DomainTypeInstance[] | null | undefined
  page: number
  pageSize: number
  total: number
  onPageChange?(page: number): void
  onPageSizeChange?(pageSize: number): void
  onCardClick(id: string): void
  setPage?(value: number): void
  isLoading: boolean
}

interface CardProps {
  domainType: DomainType
  instance: DomainTypeInstance
  page?: number
  pageSize?: number
  total?: number
  onPageChange?(page: number): void
  onPageSizeChange?(pageSize: number): void
  onCardClick(id: string): void
}

function Card({
  domainType,
  instance,
  onCardClick
}: CardProps): JSX.Element {
  const domainTypes = useSelector(getAllDomainTypes)
  const identifier = getDomainTypeSetting(domainTypes, domainType, 'Identifier') ?? 'Id'
  return (
    <Grid
      item
      p={1}
      flex={`1 1 ${COLUMN_WIDTH}px`}>
      <MaterialCard
        sx={{
          height: '100%',
          cursor: 'pointer',
          background: theme => theme.palette.background.paper
        }}
        onClick={() => onCardClick(String(instance[identifier]))}>
        <CardActionArea
          sx={{
            fontSize: '0.875rem',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start'
          }}>
          <CardContent
            sx={{
              p: 0,
              width: '100%'
            }}>
            <DomainTypeGridSummary
              domainType={domainType}
              instance={instance}
              columnsSetting='Columns'
              disableCellLinks />
          </CardContent>
        </CardActionArea>
      </MaterialCard>
    </Grid>
  )
}

export default function CardsView({
  domainType,
  items,
  isLoading,
  page,
  pageSize,
  total,
  onCardClick,
  onPageChange,
  onPageSizeChange
}: Props): JSX.Element {
  return (
    <Paper>
      <Box>
        {isLoading
          ? (
            <Stack
              direction='row'
              justifyContent='center'
              alignItems='center'
              minHeight={2 * ROW_HEIGHT}>
              <CircularProgress />
            </Stack>
          )
          : (
            <Grid
              container
              spacing={0}>
              {items?.map((instance, i) => {
                return (
                  <Card
                    key={i}
                    domainType={domainType}
                    instance={instance}
                    onCardClick={onCardClick} />
                )
              })}
              {emptyItemsToFixLayout}
            </Grid>
          )}
      </Box>
      <Box
        display='flex'
        justifyContent='flex-end'
        alignItems='center'
        height='52px'>
        <FindPagination
          page={page}
          total={total}
          pageSize={pageSize}
          rowCount={items?.length ?? 0}
          isLoading={false}
          setPage={onPageChange}
          setPageSize={onPageSizeChange} />
      </Box>
    </Paper>
  )
}

