import { either as E } from 'fp-ts'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { Attribute, DomainType, DomainTypeAttribute, DomainTypeInstance, EnumAttribute, Filter, NonListAttribute, NonListDomainTypeAttributeValue, RefAttribute } from 'types'
import { limitSearchPageSize } from 'utils/api'
import { CONTEXT_PREFIX, unifyFilters } from 'utils/filters'
import { getAttributeChain, getDomainTypeExternalId, getDomainTypeSetting, getIdentifier, getParentDomainTypes, getRootDomainType, hasApi, isNullOrUndefined, isValidTimelineGroupByAttribute } from 'utils/helpers'
import { useApi, useCancellableApiSession, useFilterContext } from 'utils/hooks'
import { CustomTimelineGroup } from './types'

function canLoadAllGroups(
  attribute: Attribute | undefined
): attribute is NonListAttribute<DomainTypeAttribute | RefAttribute | EnumAttribute> {
  return isValidTimelineGroupByAttribute(attribute)
    && attribute.AttributeType !== 'string'
}

function getEnumAttributeGroups(
  attribute: NonListAttribute<EnumAttribute>
): CustomTimelineGroup[] {
  return attribute.EnumeratedType.Values
    .map(value => ({
      attribute: attribute,
      value: value.Value
    }))
    .map(attributeValue => ({
      id: attributeValue.value,
      title: '',
      attributeValue
    }))
}

function getDomainTypeOrRefAttributeGroups(
  domainTypes: Partial<Record<string, DomainType>>,
  attribute: NonListAttribute<DomainTypeAttribute | RefAttribute>,
  attributeDomainType: DomainType,
  instances: DomainTypeInstance[]
): CustomTimelineGroup[] {
  const identifier = getIdentifier(domainTypes, attributeDomainType)
  return instances
    .map(instance => ({
      id: String(instance[identifier]),
      title: '',
      attributeValue: {
        attribute: {
          ...attribute,
          AttributeType: 'domainType'
        },
        value: instance
      }
    }))
}

function getAdditionalFilterContext(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType,
  itemsDomainType: DomainType
): NonListDomainTypeAttributeValue[] {
  return [domainType, itemsDomainType]
    .flatMap(domainType => {
      const rootDomainType = getRootDomainType(domainTypes, domainType) ?? domainType
      const subtypeProperty = getDomainTypeSetting(domainTypes, rootDomainType, 'Subtype')
      if (isNullOrUndefined(subtypeProperty)) {
        return []
      }
      const parents = getParentDomainTypes(domainTypes, domainType)
      return parents.map(parent => ({
        attribute: {
          Name: getDomainTypeExternalId(parent),
          Title: parent.Title,
          AttributeType: 'domainType',
          AttributeDomainType: parent.Id
        },
        value: {
          [subtypeProperty]: domainType.Name
        }
      }))
    })
}

export default function useAllGroups(
  domainType: DomainType,
  itemsDomainType: DomainType,
  groupByPath: string | null,
  filters: Filter[]
): CustomTimelineGroup[] {
  const domainTypes = useSelector(getAllDomainTypes)
  const filterContext = useFilterContext()
  const [allGroups, setAllGroups] = useState<CustomTimelineGroup[]>([])
  const api = useApi()
  const loadGroups = useCancellableApiSession(api)
  useEffect(() => {
    const attributeChain = getAttributeChain(domainTypes, domainType, groupByPath)
    const groupByAttribute = attributeChain?.[attributeChain.length - 1]
    if (!canLoadAllGroups(groupByAttribute)) {
      setAllGroups([])
      return
    }
    if (groupByAttribute.AttributeType === 'enum') {
      const groups = getEnumAttributeGroups(groupByAttribute)
      setAllGroups(groups)
      return
    }
    const attributeDomainType = domainTypes[groupByAttribute.AttributeDomainType]
    if (!hasApi(domainTypes, attributeDomainType)) {
      setAllGroups([])
      return
    }
    const apiSession = loadGroups.cancelPreviousAndStartNew()
    if (!apiSession.isSignedIn) {
      setAllGroups([])
      return
    }
    const additionalFilterContext = getAdditionalFilterContext(
      domainTypes,
      domainType,
      itemsDomainType
    )
    const unifiedFilters = unifyFilters(
      filterContext,
      domainType,
      filters,
      groupByAttribute.Filters ?? []
    )
    apiSession
      .withRequestContext({
        filterContext: {
          ...filterContext,
          [CONTEXT_PREFIX]: [
            ...filterContext[CONTEXT_PREFIX],
            ...additionalFilterContext
          ]
        }
      })
      .search(
        attributeDomainType.DatabaseTable ?? attributeDomainType.Name,
        attributeDomainType.Name,
        [],
        unifiedFilters,
        [],
        1,
        limitSearchPageSize(400)
      )
      .then(response => {
        if (E.isLeft(response)) {
          setAllGroups([])
          return
        }
        const groups = getDomainTypeOrRefAttributeGroups(
          domainTypes,
          groupByAttribute,
          attributeDomainType,
          response.right.results
        )
        setAllGroups(groups)
      })
  }, [domainType, domainTypes, filterContext, filters, groupByPath, itemsDomainType, loadGroups])
  return allGroups
}