import { ChevronLeftOutlined, ChevronRightOutlined, ExpandLessOutlined, ExpandMoreOutlined } from '@mui/icons-material'
import { Box, Collapse, Divider, Fade, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Toolbar, Tooltip, listItemIconClasses, useTheme } from '@mui/material'
import AvatarCornerIcon from 'components/utils/AvatarCornerIcon'
import DrawerDragHandle from 'components/utils/DrawerDragHandle'
import Help from 'components/utils/Help'
import * as t from 'io-ts'
import { NumberFromString } from 'io-ts-types'
import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { setMenuSectionOpen, toggleSideMenuExpanded } from 'state/actions/preferences'
import { getPreferences } from 'state/reducers'
import { CELL_AVATAR_BACKGROUND_ALPHA, SIDE_MENU_AVATAR_SCALE } from 'utils/constants'
import { getContrastingColour, getTransparentBackgroundColour, isNullOrUndefined } from 'utils/helpers'
import { useDrawerDragHandle, useLocalStorageState, useMenuSections } from 'utils/hooks'
import ScrollBar from '../ScrollBar'
import Drawer, { DRAG_HANDLE_WIDTH } from './Drawer'
import classes from './SideMenu.classes'

const MIN_MENU_WIDTH = 200
const MAX_MENU_WIDTH = 500
const DEFAULT_MENU_WIDTH = 275

const widthCodec = t.string.pipe(NumberFromString)

export default function SideMenu(): JSX.Element {
  const menuSections = useMenuSections()
  const {
    sideMenuPinned,
    openMenuSections
  } = useSelector(getPreferences)
  const dispatch = useDispatch()
  const location = useLocation()
  const theme = useTheme()
  const [drawerWidth, setDrawerWidth] = useLocalStorageState(
    'sideMenuWidth',
    DEFAULT_MENU_WIDTH,
    widthCodec
  )
  const {
    isDragging,
    ref,
    onMouseDown
  } = useDrawerDragHandle(
    'right',
    MIN_MENU_WIDTH,
    MAX_MENU_WIDTH,
    setDrawerWidth
  )
  const content = useMemo(() => {
    return (
      <>
        <Tooltip
          key={String(sideMenuPinned)}
          title={sideMenuPinned ? 'Unpin' : 'Pin'}
          disableInteractive>
          <IconButton
            size='small'
            className='collapse'
            onClick={() => (dispatch(toggleSideMenuExpanded()))}
            sx={{
              position: 'absolute',
              top: '96px',
              right: '-17px',
              zIndex: '999',
              backgroundColor: theme.palette.background.default,
              opacity: 0.7
            }}>
            {sideMenuPinned ? <ChevronLeftOutlined sx={{ opacity: 1 }} /> : <ChevronRightOutlined sx={{ opacity: 1 }} />}
          </IconButton>
        </Tooltip>
        <Toolbar />
        <ScrollBar>
          <Box
            pb='40px'
            sx={{
              [`& .${listItemIconClasses.root}`]: {
                minWidth: '44px'
              }
            }}>
            {menuSections.map((section, i) => {
              const id = section.category?.Value ?? null
              const open = openMenuSections.includes(id)
              const colour = getContrastingColour(
                section.category?.Colour ?? theme.palette.primary.main,
                theme.palette.mode,
                theme.palette.contrastThreshold
              )
              const borderColour = getTransparentBackgroundColour(
                colour,
                theme.palette.mode,
                (Number(open) / CELL_AVATAR_BACKGROUND_ALPHA + 1) * CELL_AVATAR_BACKGROUND_ALPHA
              )
              const description = section.category?.Description ?? 'Other'
              return (
                <div key={i}>
                  <List
                    disablePadding>
                    <ListItemButton
                      sx={{
                        borderLeft: `8px solid ${borderColour}`,
                        minHeight: '48px'
                      }}
                      onClick={() => {
                        dispatch(setMenuSectionOpen(id, !open))
                      }}>
                      {isNullOrUndefined(section.category?.Icon)
                        ? null
                        : (
                          <Collapse
                            orientation='horizontal'
                            in={!open}>
                            <Fade in={!open}>
                              <ListItemIcon>
                                <AvatarCornerIcon
                                  avatarScale={SIDE_MENU_AVATAR_SCALE}
                                  background={colour}
                                  icon={section.category?.Icon ?? ''} />
                              </ListItemIcon>
                            </Fade>
                          </Collapse>
                        )}
                      <ListItemText
                        className='text'
                        primary={description}
                        primaryTypographyProps={{
                          fontWeight: 600
                        }} />
                      {open
                        ? (
                          <ExpandLessOutlined
                            sx={{
                              fontSize: '28px',
                              color: 'text.secondary'
                            }} />
                        )
                        : sideMenuPinned && (
                          <ExpandMoreOutlined sx={{
                            fontSize: '28px',
                            color: 'text.secondary'
                          }} />
                        )}
                    </ListItemButton>
                    <Collapse
                      in={open}
                      timeout='auto'>
                      <List
                        sx={{
                          borderLeft: `8px solid ${borderColour}`
                        }}>
                        {section.items.map((item) => (
                          <ListItemButton
                            key={item.text}
                            component={Link}
                            to={item.path}
                            selected={location.pathname === item.path}>
                            <Collapse
                              orientation='horizontal'
                              sx={{ flexShrink: 0 }}
                              in={open}>
                              <Fade
                                in={open}>
                                <ListItemIcon>
                                  {item.icon}
                                </ListItemIcon>
                              </Fade>
                            </Collapse>
                            <ListItemText
                              className='text'
                              primary={(
                                <Help
                                  type='domainType'
                                  domainType={item.domainType}>
                                  {item.text}
                                </Help>
                              )} />
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>
                  </List>
                  <Divider />
                </div>
              )
            })}
          </Box>
        </ScrollBar>
      </>
    )
  }, [dispatch, location.pathname, menuSections, openMenuSections, sideMenuPinned, theme.palette.background.default, theme.palette.contrastThreshold, theme.palette.mode, theme.palette.primary.main])
  return (
    <Drawer
      ref={ref}
      open={sideMenuPinned}
      isDragging={isDragging}
      drawerWidth={drawerWidth}
      className={classes.drawer}
      variant='permanent'
      classes={{
        paper: classes.drawerPaper
      }}>
      {sideMenuPinned && (
        <DrawerDragHandle
          placement='right'
          offset={-DRAG_HANDLE_WIDTH / 2}
          isDragging={isDragging}
          onMouseDown={onMouseDown} />
      )}
      {content}
    </Drawer>
  )
}
