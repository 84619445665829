import { useCallback, useRef, useState } from 'react'

interface Output {
  readonly isDragging: boolean
  readonly ref: React.MutableRefObject<HTMLDivElement | null>
  onMouseDown(): void
}

export function useDrawerDragHandle(
  placement: 'left' | 'right',
  minDrawerWidth: number,
  maxDrawerWidth: number,
  setDrawerWidth: (width: number) => void
): Output {
  const [isDragging, setIsDragging] = useState(false)
  const ref = useRef<HTMLDivElement | null>(null)

  const handleMouseDown = () => {
    setIsDragging(true)
    document.addEventListener('mouseup', handleMouseUp, true)
    document.addEventListener('mousemove', handleMouseMove, true)
  }

  const handleMouseUp = () => {
    setIsDragging(false)
    document.removeEventListener('mouseup', handleMouseUp, true)
    document.removeEventListener('mousemove', handleMouseMove, true)
  }

  const handleMouseMove = useCallback((e: MouseEvent) => {
    e.preventDefault()
    if (ref.current === null) {
      return
    }
    const newWidth = placement === 'left'
      ? ref.current.getBoundingClientRect().right - e.clientX + 2
      : e.clientX - ref.current.getBoundingClientRect().left + 2
    const clampedNewWidth = Math.max(minDrawerWidth, Math.min(newWidth, maxDrawerWidth))
    if (clampedNewWidth >= minDrawerWidth && clampedNewWidth <= maxDrawerWidth) {
      setDrawerWidth(clampedNewWidth)
    }
  }, [maxDrawerWidth, minDrawerWidth, placement, setDrawerWidth])

  return {
    isDragging,
    ref,
    onMouseDown: handleMouseDown
  }
}