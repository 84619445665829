import { DeleteOutlined, ExpandMoreOutlined, MoreHorizOutlined } from '@mui/icons-material'
import { TabContext } from '@mui/lab'
import { Autocomplete, Box, Button, ClickAwayListener, Divider, IconButton, ListItemIcon, ListItemText, MenuItem, MenuList, Popover, Portal, Stack, Tab, TabProps, Tabs, TextField, tabsClasses } from '@mui/material'
import useResizeObserver from '@react-hook/resize-observer'
import SaveDomainTypeSettingButton from 'components/domainType/SaveDomainTypeSettingButton'
import * as E from 'fp-ts/Either'
import { NumberFromString } from 'io-ts-types'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes, getUser } from 'state/reducers'
import { ApiError, DomainType, Query } from 'types'
import { OverriderDetails } from 'utils/context/DomainTypeOverriderContext'
import { getRootDomainType, isInRole, isNullOrUndefined } from 'utils/helpers'
import { SignedInApi, useApi, useCancellableApiSession, useLocalStorageState } from 'utils/hooks'
import { v4 } from 'uuid'

interface QueryTabProps extends TabProps {
  readonly domainType: DomainType
  readonly query: Query
  readonly active: boolean
  readonly overriderDetails?: OverriderDetails
  fetchTotal(api: SignedInApi, query: Query): Promise<E.Either<ApiError, number>>
  onApplyQuery(query: Query): void
}

const openTitleStyle = {
  width: 'calc(100% - 24px)',
  marginRight: '-24px'
}
const openMoreButtonStyle = {
  display: 'inline-flex'
}

function QueryTab({
  domainType,
  query,
  active,
  overriderDetails,
  fetchTotal,
  onApplyQuery,
  ...TabProps
}: QueryTabProps): JSX.Element {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const api = useApi()
  const search = useCancellableApiSession(api)
  const domainTypes = useSelector(getAllDomainTypes)
  const rootDomainType = getRootDomainType(domainTypes, domainType)
  const [total, setTotal] = useLocalStorageState(
    `query.${domainType.Id}.${query.Id}.total`,
    0,
    NumberFromString
  )
  const [currentDomainType, setCurrentDomainType] = useState(domainType)
  const [performedQuery, setPerformedQuery] = useState(false)
  useEffect(() => {
    if (performedQuery === true && currentDomainType === domainType) {
      return
    }
    const apiSession = search.cancelPreviousAndStartNew()
    async function doFetch() {
      if (rootDomainType === null || !apiSession.isSignedIn) {
        return search.cancel
      }
      const response = await fetchTotal(apiSession, query)
      if (E.isRight(response)) {
        setPerformedQuery(true)
        setCurrentDomainType(domainType)
        setTotal(response.right)
      }
    }
    doFetch()
    return search.cancel
  }, [api, fetchTotal, query, rootDomainType, search, performedQuery, currentDomainType, domainType, setTotal])
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const open = anchorEl !== null
  const tabRef = useRef<HTMLDivElement | null>(null)
  const moreButtonStyle = open ? openMoreButtonStyle : { display: 'none' }
  const titleStyle = open ? openTitleStyle : undefined
  return (
    <>
      <Tab
        ref={tabRef}
        sx={{
          pr: 1,
          '& .title': {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            display: 'block',
            alignItems: 'center',
            ...titleStyle
          },
          '& .more-button': {
            p: 0,
            ...moreButtonStyle
          },
          '&:hover .title': query.Id === 'all'
            ? {}
            : openTitleStyle,
          '&:hover .more-button': query.Id === 'all'
            ? {}
            : openMoreButtonStyle
        }}
        label={(
          <Stack
            direction='row'
            component='span'
            gap={0.5}
            alignItems='center'
            maxWidth='100%'>
            <span className='title'>
              {query.Title}
              &nbsp;&nbsp;
              {total}
            </span>
            <Box flexGrow={1} />
            <Stack
              direction='row'
              component='span'
              alignItems='center'>
              <IconButton
                className='more-button'
                size='small'
                color={active ? 'primary' : 'inherit'}
                onMouseDown={event => event.stopPropagation()}
                onClick={event => {
                  event.stopPropagation()
                  setAnchorEl(event.currentTarget)
                }}>
                <MoreHorizOutlined />
              </IconButton>
            </Stack>
          </Stack>
        )}
        {...TabProps} />
      <ClickAwayListener
        onClickAway={() => setAnchorEl(null)}>
        <Popover
          open={open}
          onClose={() => setAnchorEl(null)}
          onClick={event => event.stopPropagation()}
          anchorReference='anchorEl'
          anchorEl={anchorEl}
          anchorOrigin={{
            horizontal: 'left',
            vertical: 'bottom'
          }}>
          <MenuList>
            <MenuItem
              onClick={() => setDeleteDialogOpen(true)}>
              <ListItemIcon>
                <DeleteOutlined />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          </MenuList>
        </Popover>
      </ClickAwayListener>
      <Portal>
        <span
          onClick={event => event.stopPropagation()}
          onFocus={event => event.stopPropagation()}>
          <SaveDomainTypeSettingButton
            domainType={domainType}
            setting='Queries'
            value={[query]}
            disabled={query.Id === 'all'}
            mode='delete'
            saveTo={overriderDetails ?? 'domainType'}
            title='Query'
            dialogOpen={deleteDialogOpen}
            onChangeDialogOpen={open => {
              setDeleteDialogOpen(open)
              setAnchorEl(null)
            }} />
        </span>
      </Portal>
    </>
  )
}

function getRightPosition(element: HTMLElement | null | undefined): number {
  if (isNullOrUndefined(element)) {
    return 0
  }
  return element.getBoundingClientRect().right
}

interface Props {
  domainType: DomainType
  currentQuery: Query
  overriderQueries: [Query, OverriderDetails][]
  domainTypeQueries: Query[]
  containerRef: React.RefObject<HTMLDivElement | null>
  fetchTotal(api: SignedInApi, query: Query): Promise<E.Either<ApiError, number>>
  onApplyQuery(query: Query): void
}

export default function QueryTabs({
  domainType,
  currentQuery,
  overriderQueries,
  domainTypeQueries,
  containerRef,
  fetchTotal,
  onApplyQuery
}: Props): JSX.Element {
  const value = useMemo(() => [
    {
      ...currentQuery,
      Id: v4(),
      Title: ''
    }
  ], [currentQuery])
  const [saveDialogOpen, setSaveDialogOpen] = useState(false)
  const user = useSelector(getUser)
  const ref = useRef<HTMLDivElement>(null)
  const [renderKey, forceRenderToShowTabScrollButtons] = useState(v4())
  useResizeObserver(ref, () => forceRenderToShowTabScrollButtons(v4()))
  const tabs = useMemo(() => {
    return domainTypeQueries
      .filter(query => isNullOrUndefined(query.Role) || isInRole(user, query.Role))
      .map(query => (
        <QueryTab
          key={query.Id}
          id={query.Id}
          value={query.Id}
          query={query}
          domainType={domainType}
          active={query.Id === currentQuery.Id}
          fetchTotal={fetchTotal}
          onApplyQuery={onApplyQuery} />
      ))
      .concat(overriderQueries
        .filter(([query]) => isNullOrUndefined(query.Role) || isInRole(user, query.Role))
        .map(([query, overriderDetails]) => (
          <QueryTab
            key={query.Id}
            id={query.Id}
            value={query.Id}
            query={query}
            overriderDetails={overriderDetails}
            domainType={domainType}
            active={query.Id === currentQuery.Id}
            fetchTotal={fetchTotal}
            onApplyQuery={onApplyQuery} />
        )))
  }, [currentQuery.Id, domainType, domainTypeQueries, fetchTotal, onApplyQuery, overriderQueries, user])
  const addButtonRef = useRef<HTMLButtonElement | null>(null)
  const addButton = useMemo(() => {
    return (
      <>
        <Button
          ref={addButtonRef}
          key={currentQuery.Id}
          variant='text'
          color='primary'
          sx={{
            whiteSpace: 'nowrap',
            flexShrink: 0,
            alignSelf: 'center'
          }}
          onClick={() => setSaveDialogOpen(true)}>
          Add Query
        </Button>
        <SaveDomainTypeSettingButton
          domainType={domainType}
          setting='Queries'
          value={value}
          title='Query'
          onSaveSuccess={value => {
            if (value?.length === 1) {
              const firstValue = value[0]
              if (firstValue !== undefined) {
                onApplyQuery(firstValue)
              }
            }
          }}
          dialogOpen={saveDialogOpen}
          onChangeDialogOpen={setSaveDialogOpen} />
      </>
    )
  }, [currentQuery.Id, domainType, onApplyQuery, saveDialogOpen, value])
  const [showQueriesInput, setShowQueriesInput] = useState(false)
  const queriesInputRef = useRef<HTMLInputElement | null>(null)
  return useMemo(() => (
    <>
      <TabContext value={currentQuery.Id}>
        <Tabs
          ref={ref}
          sx={{
            [`& .${tabsClasses.scrollButtons}.Mui-disabled`]: {
              opacity: 0.3
            },
            [`& .${tabsClasses.indicator}`]: {
              left: 0
            }
          }}
          variant='scrollable'
          value={currentQuery.Id}
          onChange={(event, id: string) => {
            const query = domainTypeQueries
              .concat(overriderQueries.map(([query]) => query))
              .find(query => query.Id === id)
            if (query !== undefined) {
              onApplyQuery(query)
            }
          }}>
          {tabs}
        </Tabs>
      </TabContext>
      {tabs.length > 1 && addButtonRef.current !== null && (getRightPosition(addButtonRef.current) === getRightPosition(containerRef.current)) && (
        <Autocomplete
          key={currentQuery.Id}
          size='small'
          onBlur={() => setShowQueriesInput(false)}
          blurOnSelect
          clearOnBlur
          value={null}
          openOnFocus
          onChange={(event, query) => {
            if (query !== null) {
              onApplyQuery(query)
            }
          }}
          options={domainTypeQueries
            .filter(query => isNullOrUndefined(query.Role) || isInRole(user, query.Role))
            .concat(overriderQueries
              .filter(([query]) => isNullOrUndefined(query.Role) || isInRole(user, query.Role))
              .map(([query, overriderDetails]) => query)
            )}
          getOptionLabel={query => query.Title}
          renderInput={params => (
            <>
              <TextField
                {...params}
                inputRef={queriesInputRef}
                sx={{
                  display: showQueriesInput ? 'inline-flex' : 'none',
                  minWidth: 200
                }}
                label='Queries' />
              <Button
                key={currentQuery.Id}
                sx={{
                  display: showQueriesInput ? 'none' : 'inline-flex',
                  whiteSpace: 'nowrap',
                  flexShrink: 0,
                  alignSelf: 'center'
                }}
                variant='text'
                color='primary'
                onClick={event => {
                  setShowQueriesInput(true)
                  setTimeout(() => {
                    queriesInputRef.current?.focus()
                  }, 0)
                }}
                endIcon={(
                  <ExpandMoreOutlined />
                )}>
                Queries
              </Button>
            </>
          )} />
      )}
      <Divider
        key={renderKey}
        orientation='vertical'
        variant='middle'
        flexItem />
      {addButton}
    </>
  ), [addButton, containerRef, currentQuery.Id, domainTypeQueries, onApplyQuery, overriderQueries, renderKey, showQueriesInput, tabs, user])
}
