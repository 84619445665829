import { KeyboardArrowDownOutlined } from '@mui/icons-material'
import { Box, Link, ListItemIcon, Menu, MenuItem, Stack } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { ThemeProvider } from '@mui/system'
import useResizeObserver from '@react-hook/resize-observer'
import { CellTooltip, DomainTypeCell, DomainTypeTooltip } from 'components/attribute/AttributeCell'
import { DefaultDomainTypeCell } from 'components/attribute/AttributeCell/DomainTypeCell'
import DomainTypeHeading, { DefaultDomainTypeHeading } from 'components/domainType/DomainTypeHeading'
import DomainTypeIcon from 'components/domainType/DomainTypeIcon'
import DomainTypeMap from 'components/domainType/DomainTypeMap'
import { either as E } from 'fp-ts'
import { constant, identity, pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'
import { BooleanFromString, NumberFromString } from 'io-ts-types'
import { ComponentProps, ContextType, memo, useCallback, useContext, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes, getUser } from 'state/reducers'
import { DomainType, DomainTypeInstance } from 'types'
import { DomainTypeCodec } from 'utils/codecs'
import { MIN_PAGE_WIDTH, MIN_SIDE_PANEL_WIDTH, PAGE_PADDING } from 'utils/constants'
import { DomainTypeComponentOverrideContext, DomainTypeContext, DomainTypeSettingsContext, SidePanel, SidePanelContext } from 'utils/context'
import { getCachedSubtypes, getDomainTypeAttributes, getDomainTypeSetting, getHeading, getParentDomainTypes, getRootDomainType, getSubtype, shouldRenderCellAsLink } from 'utils/helpers'
import { useDomainType, useLocalStorageState, useSubtypesCache } from 'utils/hooks'
import AppendDomainTypeContext from '../domainType/AppendDomainTypeContext'
import DomainTypeTheme from '../domainType/DomainTypeTheme'
import { LocalDetailsPage } from '../pages/DetailsPage'
import FindPage from '../pages/FindPage'
import ResizableDrawer from './ResizableDrawer'
import ScrollBar from './ScrollBar'

type Props = {
  name: string
  children: JSX.Element
}

const defaultDrawerWidth = 600
const Main = styled('div', {
  shouldForwardProp: (prop) => prop !== 'drawerWidth' && prop !== 'open'
})<{
  drawerWidth: number | 'max',
}>(({ drawerWidth }) => ({
  flexGrow: 1,
  width: drawerWidth === 'max'
    ? 0
    : `calc(100% - ${drawerWidth}px)`,
  paddingTop: 0
}))

function makeSubtypeSelectHeading(setSidePanel: (sidePanel: SidePanel | null) => void) {
  return function SubtypeSelectHeading(props: ComponentProps<typeof DomainTypeHeading>): JSX.Element {
    const domainTypes = useSelector(getAllDomainTypes)
    const settingsContext = useContext(DomainTypeSettingsContext)
    const heading = getHeading(settingsContext, domainTypes, props.domainType, props.instance ?? {})
    const theme = useTheme()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
      setAnchorEl(null)
    }
    const instanceDomainType = pipe(
      props.instance,
      DomainTypeCodec.decode,
      E.match(constant(null), identity)
    )
    const subtypesCache = useSubtypesCache()
    if (instanceDomainType === null
      || props.title !== 'Details:') {
      return (
        <DefaultDomainTypeHeading {...props} />
      )
    }
    const menuDomainTypes = getParentDomainTypes(domainTypes, instanceDomainType)
      .reverse()
      .concat(getCachedSubtypes(domainTypes, instanceDomainType, subtypesCache).slice(1))
    return (
      <Stack
        direction='row'
        gap={1}
        alignItems='center'
        padding={0}
        width='100%'>
        <DomainTypeIcon
          avatar
          avatarScale={1.2}
          domainType={props.domainType} />
        <Stack
          component='h3'
          direction='row'
          onClick={handleClick}
          alignItems='center'
          sx={{ cursor: 'pointer' }}
          overflow='hidden'
          textOverflow='ellipsis'
          whiteSpace='nowrap'
          color={theme.palette.text.secondary}>
          {heading}
          <KeyboardArrowDownOutlined />
        </Stack>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}>
          {menuDomainTypes.map(menuDomainType => (
            <MenuItem
              key={menuDomainType.Id}
              selected={menuDomainType.Id === props.instance?.['Id']}
              onClick={() => {
                setSidePanel({
                  type: 'domainTypeDetails',
                  id: menuDomainType.Id
                })
              }}>
              <ListItemIcon>
                <DomainTypeIcon
                  domainType={menuDomainType}
                  avatar />
              </ListItemIcon>
              {menuDomainType.Title}
            </MenuItem>
          ))}
        </Menu>
        {props.children}
      </Stack>
    )
  }
}

function makeSidePanelCell(setSidePanel: (sidePanel: SidePanel | null) => void) {
  return function SidePanelCell(props: ComponentProps<typeof DomainTypeCell>): JSX.Element | null {
    const {
      attributeValue,
      columnWidth,
      disableLink = false
    } = props
    const domainTypes = useSelector(getAllDomainTypes)
    const domainType = domainTypes[attributeValue.attribute.AttributeDomainType]
    const settingsContext = useContext(DomainTypeSettingsContext)
    const user = useSelector(getUser)
    const theme = useTheme()
    const newInstances = useMemo<[DomainType, DomainTypeInstance][]>(
      () => attributeValue.value === null || domainType === undefined
        ? []
        : [[domainType, attributeValue.value]],
      [attributeValue.value, domainType]
    )
    const newAttributes = useMemo(
      () => [attributeValue.attribute],
      [attributeValue.attribute]
    )
    const subtypesCache = useSubtypesCache()
    if (domainType === undefined || attributeValue.value === null) {
      return null
    }
    const rootDomainType = getRootDomainType(domainTypes, domainType)
    const subtype = getSubtype(domainTypes, rootDomainType ?? domainType, attributeValue.value, subtypesCache) ?? domainType
    const role = getDomainTypeSetting(domainTypes, subtype, 'ViewRole')
    const attributes = getDomainTypeAttributes(domainTypes, subtype)
    const api = getDomainTypeSetting(domainTypes, domainType, 'Api') ?? false
    const idAttribute = attributes.find(attribute => attribute.Name === 'Id')
    const heading = getHeading(settingsContext, domainTypes, domainType, attributeValue.value)
    if (shouldRenderCellAsLink(api, idAttribute, disableLink, user, role)) {
      return (
        <DomainTypeTheme domainType={domainType}>
          <DomainTypeTooltip
            domainType={domainType}
            instance={attributeValue.value}>
            <Link
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                gap: '8px',
                width: 'fit-content',
                color: theme.palette.muiPrimary.main
              }}
              onClick={event => {
                event.stopPropagation()
                const id = attributeValue.value?.['Id']
                if (typeof id === 'string') {
                  setSidePanel({
                    type: 'domainTypeDetails',
                    id
                  })
                }
              }}>
              <DomainTypeIcon
                avatar
                domainType={domainType} />
              <AppendDomainTypeContext
                newInstances={newInstances}
                newAttributes={newAttributes}>
                <CellTooltip
                  value={heading}
                  columnWidth={columnWidth} />
              </AppendDomainTypeContext>
            </Link>
          </DomainTypeTooltip>
        </DomainTypeTheme>
      )
    }
    return (
      <DefaultDomainTypeCell {...props} />
    )
  }
}

interface SidePanelComponentProps {
  readonly sidePanel: SidePanel | null
  setSidePanel(sidePanel: SidePanel | null): void
  isFullScreen: boolean
  setIsFullScreen(isFullScreen: boolean): void
}

const SidePanelComponent = memo(function Panel({
  sidePanel,
  setSidePanel,
  isFullScreen,
  setIsFullScreen
}: SidePanelComponentProps): JSX.Element | null {
  const domainTypeDomainType = useDomainType('DomainType', 'DomainType')
  const domainTypes = useSelector(getAllDomainTypes)
  const domainTypeComponentOverrideContext = useContext(DomainTypeComponentOverrideContext)
  const componentOverrideContext = useMemo((): ContextType<typeof DomainTypeComponentOverrideContext> => ({
    ...domainTypeComponentOverrideContext,
    overrides: {
      ...domainTypeComponentOverrideContext.overrides,
      'DomainType:DomainType': {
        ...domainTypeComponentOverrideContext.overrides['DomainType:DomainType'],
        heading: makeSubtypeSelectHeading(setSidePanel),
        cell: makeSidePanelCell(setSidePanel)
      }
    }
  }), [domainTypeComponentOverrideContext, setSidePanel])
  const onResize = useCallback((action: 'maximise' | 'minimise' | 'close') => {
    switch (action) {
      case 'maximise':
        return setIsFullScreen(true)
      case 'minimise':
        return setIsFullScreen(false)
      case 'close':
        if (sidePanel?.onClose !== undefined) {
          sidePanel.onClose()
        } else {
          setSidePanel(null)
        }
    }
  }, [setIsFullScreen, setSidePanel, sidePanel])
  if (sidePanel === null) {
    return null
  }
  switch (sidePanel.type) {
    case 'details':
      return (
        <DomainTypeContext.Provider value={sidePanel.context}>
          <ThemeProvider theme={sidePanel.theme}>
            <DomainTypeTheme domainType={sidePanel.domainType}>
              <Box
                p={PAGE_PADDING}
                pt={0}>
                <LocalDetailsPage
                  rootDomainType={sidePanel.domainType}
                  instance={sidePanel.instance}
                  isFullScreen={isFullScreen}
                  onResize={onResize} />
              </Box>
            </DomainTypeTheme>
          </ThemeProvider>
        </DomainTypeContext.Provider>
      )
    case 'find':
      return (
        <Box
          p={PAGE_PADDING}
          pt={0}>
          <FindPage
            domainType={sidePanel.domainType} />
        </Box>
      )
    case 'domainTypeDetails':
      if (domainTypeDomainType === null) {
        return null
      }
      return (
        <DomainTypeComponentOverrideContext.Provider
          value={componentOverrideContext}>
          <Box
            p={PAGE_PADDING}
            pt={0}>
            <LocalDetailsPage
              rootDomainType={domainTypeDomainType}
              instance={(domainTypes[sidePanel.id] ?? {}) as unknown as DomainTypeInstance}
              isFullScreen={isFullScreen}
              onResize={onResize} />
          </Box>
        </DomainTypeComponentOverrideContext.Provider>
      )
    case 'map':
      return (
        <ThemeProvider theme={sidePanel.theme}>
          <DomainTypeTheme domainType={sidePanel.domainType}>
            <DomainTypeMap
              domainType={sidePanel.domainType}
              instances={sidePanel.instances}
              isFullScreen={isFullScreen}
              onResize={onResize} />
          </DomainTypeTheme>
        </ThemeProvider>
      )
  }
})

const widthCodec = t.string.pipe(NumberFromString)

export default function SidePanelContainer({
  children,
  name
}: Props): JSX.Element {
  const [drawerWidthSetting, setDrawerWidth] = useLocalStorageState(
    `sidePanelDrawerWidth.${name}`,
    defaultDrawerWidth,
    widthCodec
  )
  const [isFullScreen, setIsFullScreen] = useLocalStorageState(
    `sidePanelIsFullScreen.${name}`,
    false,
    BooleanFromString
  )
  const sidePanels = useContext(SidePanelContext)
  const [openChildren, setOpenChildren] = useState<string[]>([])
  const onToggleChild = useCallback((name: string, open: boolean) => {
    setOpenChildren(openChildren => {
      if (open && !openChildren.includes(name)) {
        return openChildren.concat(name)
      }
      if (!open && openChildren.includes(name)) {
        return openChildren.filter(openChild => openChild !== name)
      }
      return openChildren
    })
  }, [])
  const [sidePanel, setSidePanelState] = useState<SidePanel | null>(null)
  const setSidePanel = useCallback((sidePanel: SidePanel | null) => {
    setSidePanelState(sidePanel)
    for (const parent of sidePanels) {
      parent.onToggleChild(name, sidePanel !== null)
    }
  }, [name, sidePanels])
  const contextValue = useMemo<ContextType<typeof SidePanelContext>>(() => [
    ...sidePanels,
    {
      name,
      setSidePanel,
      sidePanel,
      onToggleChild
    }
  ], [name, onToggleChild, setSidePanel, sidePanel, sidePanels])
  const open = sidePanel !== null
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [containerWidth, setContainerWidth] = useState(0)
  const drawerWidth = useMemo(() => {
    if (!open) {
      return 0
    }
    if (isFullScreen || drawerWidthSetting >= containerWidth) {
      return 'max'
    }
    return Math.max(MIN_SIDE_PANEL_WIDTH, drawerWidthSetting)
  }, [containerWidth, drawerWidthSetting, isFullScreen, open])
  useResizeObserver(containerRef, entry => setContainerWidth(entry.contentRect.width))
  const component = useMemo(() => {
    return (
      <SidePanelComponent
        key={sidePanel?.id}
        sidePanel={sidePanel}
        setSidePanel={setSidePanel}
        isFullScreen={isFullScreen}
        setIsFullScreen={setIsFullScreen} />
    )
  }, [isFullScreen, setIsFullScreen, setSidePanel, sidePanel])
  return (
    <Stack
      ref={containerRef}
      direction='row'
      height='calc(100vh - 64px)'
      maxWidth='100%'>
      <Main
        drawerWidth={drawerWidth}>
        <ScrollBar>
          <SidePanelContext.Provider value={contextValue}>
            {children}
          </SidePanelContext.Provider>
        </ScrollBar>
      </Main>
      <ResizableDrawer
        drawerWidth={drawerWidth}
        containerWidth={containerWidth}
        setDrawerWidth={setDrawerWidth}
        open={open}
        isFullScreen={isFullScreen}
        minParentWidth={openChildren.length > 0
          ? MIN_SIDE_PANEL_WIDTH
          : MIN_PAGE_WIDTH}>
        {component}
      </ResizableDrawer>
    </Stack>
  )
}
