import { Box, useTheme } from '@mui/material'
import MultiResultsInput from 'components/dataform/MultiResultsInput'
import { useMemo } from 'react'
import { NonListMultiDataformResultsAttributeValue } from 'types'
import { DATAFORM_RESULTS_STATUSES, TOOLTIP_DELAY } from 'utils/constants'
import { getMultiDataformResultsCompleteness, getMultiDataformResultsIcon } from 'utils/helpers'
import { useMultiDataformResults } from 'utils/hooks'
import LightTooltip from '../../utils/LightTooltip'
import { NarrowedProps } from './AttributeCell'
import EnumCell from './EnumCell'

export default function MultiDataformResultsCell({
  attributeValue,
  columnWidth,
  disableTooltip = false
}: NarrowedProps<NonListMultiDataformResultsAttributeValue> & { disableTooltip?: boolean }): JSX.Element | null {
  const [multiResults, errorMessage] = useMultiDataformResults(attributeValue, true)
  const theme = useTheme()
  const [cornerIcon, color] = getMultiDataformResultsIcon(attributeValue.value, theme)
  const completeness = getMultiDataformResultsCompleteness(attributeValue.value)
  const cell = useMemo(() => {
    return (
      <EnumCell
        attributeValue={{
          attribute: {
            ...attributeValue.attribute,
            AttributeType: 'enum',
            EnumeratedType: {
              ExternalId: 'Status',
              GlobalExternalId: 'Status',
              Values: [
                {
                  Id: '',
                  Description: DATAFORM_RESULTS_STATUSES[completeness],
                  Value: String(completeness),
                  Colour: color,
                  Icon: cornerIcon
                }
              ]
            }
          },
          value: completeness
        }} />
    )
  }, [attributeValue.attribute, color, completeness, cornerIcon])
  if (disableTooltip || completeness === 3) {
    return cell
  }
  return (
    <LightTooltip
      enterDelay={TOOLTIP_DELAY}
      enterNextDelay={TOOLTIP_DELAY}
      minWidth={750}
      maxWidth={750}
      minHeight={400}
      maxHeight={400}
      title={(
        <MultiResultsInput
          key={JSON.stringify(multiResults)}
          initialMultiResults={multiResults}
          attribute={attributeValue.attribute}
          errorMessage={errorMessage}
          readOnly
          maxHeight='230px'
          minHeight='230px' />
      )}>
      <Box>
        {cell}
      </Box>
    </LightTooltip>
  )
}
