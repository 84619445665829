import { Shortcut } from 'components/utils/DateRangePicker'
import { DateTime } from 'luxon'

const shortcuts: Shortcut[] = [
  {
    label: 'Today',
    getValue: () => {
      const now = DateTime.now()
      return [now.startOf('day'), now.endOf('day')]
    }
  },
  {
    label: 'Yesterday',
    getValue: () => {
      const yesterday = DateTime.now().minus({ days: 1 })
      return [yesterday.startOf('day'), yesterday.endOf('day')]
    }
  },
  {
    label: 'Last 7 Days',
    getValue: () => {
      const now = DateTime.now()
      return [now.minus({ days: 6 }).startOf('day'), now.endOf('day')]
    }
  },
  {
    label: 'Last 14 Days',
    getValue: () => {
      const now = DateTime.now()
      return [now.minus({ days: 13 }).startOf('day'), now.endOf('day')]
    }
  },
  {
    label: 'Last 30 Days',
    getValue: () => {
      const now = DateTime.now()
      return [now.minus({ days: 29 }).startOf('day'), now.endOf('day')]
    }
  },
  {
    label: 'Last 60 Days',
    getValue: () => {
      const now = DateTime.now()
      return [now.minus({ days: 59 }).startOf('day'), now.endOf('day')]
    }
  },
  {
    label: 'Last 90 Days',
    getValue: () => {
      const now = DateTime.now()
      return [now.minus({ days: 89 }).startOf('day'), now.endOf('day')]
    }
  },
  {
    label: 'Last 180 Days',
    getValue: () => {
      const now = DateTime.now()
      return [now.minus({ days: 179 }).startOf('day'), now.endOf('day')]
    }
  },
  {
    label: 'Last 365 Days',
    getValue: () => {
      const now = DateTime.now()
      return [now.minus({ days: 364 }).startOf('day'), now.endOf('day')]
    }
  },
  {
    label: 'This Week',
    getValue: () => {
      const now = DateTime.now()
      return [now.startOf('week'), now.endOf('week')]
    }
  },
  {
    label: 'Week to Date',
    getValue: () => {
      const now = DateTime.now()
      return [now.startOf('week'), now.endOf('day')]
    }
  },
  {
    label: 'Previous Week to Date',
    getValue: () => {
      const now = DateTime.now()
      return [now.minus({ weeks: 1 }).startOf('week'), now.endOf('day')]
    }
  },
  {
    label: 'Last Week',
    getValue: () => {
      const now = DateTime.now()
      return [now.minus({ weeks: 1 }).startOf('week'), now.minus({ weeks: 1 }).endOf('week')]
    }
  },
  {
    label: 'Last 2 Weeks',
    getValue: () => {
      const now = DateTime.now()
      return [now.minus({ weeks: 1 }).startOf('week'), now.endOf('week')]
    }
  },
  {
    label: 'This Month',
    getValue: () => {
      const now = DateTime.now()
      return [now.startOf('month'), now.endOf('month')]
    }
  },
  {
    label: 'Month to Date',
    getValue: () => {
      const now = DateTime.now()
      return [now.startOf('month'), now.endOf('day')]
    }
  },
  {
    label: 'Previous Month to Date',
    getValue: () => {
      const now = DateTime.now()
      return [now.minus({ months: 1 }).startOf('month'), now.endOf('day')]
    }
  },
  {
    label: 'Last Month',
    getValue: () => {
      const now = DateTime.now()
      return [now.minus({ months: 1 }).startOf('month'), now.minus({ months: 1 }).endOf('month')]
    }
  },
  {
    label: 'This Quarter',
    getValue: () => {
      const now = DateTime.now()
      return [now.startOf('quarter'), now.endOf('quarter')]
    }
  },
  {
    label: 'Last Quarter',
    getValue: () => {
      const now = DateTime.now()
      return [now.minus({ quarters: 1 }).startOf('quarter'), now.minus({ quarters: 1 }).endOf('quarter')]
    }
  },
  {
    label: 'This Year',
    getValue: () => {
      const now = DateTime.now()
      return [now.startOf('year'), now.endOf('year')]
    }
  },
  {
    label: 'Year to Date',
    getValue: () => {
      const now = DateTime.now()
      return [now.startOf('year'), now.endOf('day')]
    }
  },
  {
    label: 'Previous Year to Date',
    getValue: () => {
      const now = DateTime.now()
      return [now.minus({ years: 1 }).startOf('year'), now.endOf('day')]
    }
  },
  {
    label: 'Last Year',
    getValue: () => {
      const now = DateTime.now()
      return [now.minus({ years: 1 }).startOf('year'), now.minus({ years: 1 }).endOf('year')]
    }
  }
]

export default shortcuts