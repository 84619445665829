import { Close, FullscreenExitOutlined, FullscreenOutlined } from '@mui/icons-material'
import { Box, ButtonGroup, Stack } from '@mui/material'
import AppendDomainTypeContext from 'components/domainType/AppendDomainTypeContext'
import DomainTypeHeading from 'components/domainType/DomainTypeHeading'
import DomainTypeTheme from 'components/domainType/DomainTypeTheme'
import OperationsDialog from 'components/domainType/OperationsDialog'
import { OverridableDomainTypeComponent } from 'components/overrides'
import ApiErrorView from 'components/utils/ApiErrorView'
import TooltipIconButton from 'components/utils/TooltipIconButton'
import { useMemo } from 'react'
import { ApiError, DomainType, DomainTypeInstance } from 'types'
import { isNullOrUndefined } from 'utils/helpers'
import DetailsToolbar from './DetailsToolbar'
import ExpandableListView from './ExpandableListView'
import TabsView from './TabsView'
import { DetailsPageView } from './detailsPageReducer'
import { DetailsPageSection, HighlightedRow } from './useDetails'

interface Props {
  localInstance?: DomainTypeInstance,
  isLoading: boolean
  instance: DomainTypeInstance
  apiError?: ApiError | null
  domainType: DomainType
  sections: DetailsPageSection[]
  highlightedRow?: HighlightedRow | null
  onHighlightRow?(highlightedRow: HighlightedRow | null): void
  view: DetailsPageView
  onChangeView(view: DetailsPageView): void
  onListItemClick(id: string): void
  selectedTab: string
  onTabChange(id: string): void
  onInvalidate?(): void
  isFullScreen?: boolean
  onResize?(action: 'maximise' | 'minimise' | 'close'): void
}

function DefaultDetailsPage({
  isLoading,
  sections,
  domainType,
  instance,
  apiError,
  highlightedRow = null,
  onHighlightRow,
  view,
  onChangeView,
  onListItemClick,
  selectedTab,
  onTabChange,
  onInvalidate,
  isFullScreen = false,
  onResize
}: Props): JSX.Element {
  const newInstances = useMemo<[DomainType, DomainTypeInstance][]>(
    () => [[domainType, instance]],
    [domainType, instance]
  )
  const instances = useMemo(() => {
    return [instance]
  }, [instance])
  if (!isNullOrUndefined(apiError)) {
    return (
      <ApiErrorView apiError={apiError} />
    )
  }
  const isRemoteDetailsPage = onHighlightRow !== undefined
  return (
    <AppendDomainTypeContext
      newInstances={newInstances}
      onInvalidate={onInvalidate}>
      {isRemoteDetailsPage && (
        <OperationsDialog
          domainType={domainType}
          target={{
            type: 'instances',
            instances
          }}
          on='DetailsHeader' />
      )}
      <Stack>
        <Stack
          sx={{
            height: '40px',
            mt: 1,
            mb: 1
          }}
          direction='row'
          alignItems='center'>
          <DomainTypeHeading
            domainType={domainType}
            instance={instance}
            isLoading={isLoading}
            title='Details:'>
            <Box flexGrow={1} />
            {onResize !== undefined && (
              <ButtonGroup
                size='small'
                variant='text'>
                {isFullScreen
                  ? (
                    <TooltipIconButton
                      tooltipText='Exit Full Screen'
                      size='small'
                      icon={<FullscreenExitOutlined />}
                      onClick={() => onResize('minimise')} />
                  )
                  : (
                    <TooltipIconButton
                      tooltipText='Full Screen'
                      size='small'
                      icon={<FullscreenOutlined />}
                      onClick={() => onResize('maximise')} />
                  )}
                <TooltipIconButton
                  tooltipText='Close'
                  size='small'
                  icon={<Close />}
                  onClick={() => onResize('close')} />
              </ButtonGroup>
            )}
          </DomainTypeHeading>
        </Stack>
        <DetailsToolbar
          domainType={domainType}
          instance={instance}
          view={view}
          onChangeView={onChangeView} />
        <Box>
          <Box display={view === 'expandableList' ? 'block' : 'none'}>
            <ExpandableListView
              domainType={domainType}
              isLoading={isLoading}
              sections={sections}
              highlightedRow={highlightedRow}
              onHighlightRow={onHighlightRow}
              onListItemClick={onListItemClick} />
          </Box>
          <Box display={view === 'tabs' ? 'block' : 'none'}>
            <TabsView
              domainType={domainType}
              isLoading={isLoading}
              sections={sections}
              highlightedRow={highlightedRow}
              onHighlightRow={onHighlightRow}
              selectedTab={selectedTab}
              onTabChange={onTabChange} />
          </Box>
        </Box>
      </Stack>
    </AppendDomainTypeContext>
  )
}

export default function DetailsPage(props: Props): JSX.Element {
  const {
    domainType
  } = props
  return (
    <DomainTypeTheme domainType={domainType}>
      <OverridableDomainTypeComponent<'details'>
        component='details'
        domainType={domainType}
        props={props}
        DefaultComponent={DefaultDetailsPage} />
    </DomainTypeTheme>
  )
}
