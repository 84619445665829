import ButtonsPopover from 'components/domainType/ButtonsPopover'
import { ElementType, JSXElementConstructor, PropsWithChildren, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getUser } from 'state/reducers'
import { AttributeValue, ButtonLocation, DomainType } from 'types'
import { isInRole } from 'utils/helpers'
import { ButtonTarget, useButtons } from 'utils/hooks'
import DomainTypeButton from './DomainTypeButton'

export type DomainTypeButtonPriority = 'high' | 'medium' | 'low' | 'hideAll'

const priorityOrder: DomainTypeButtonPriority[] = [
  'low', 'medium', 'high', 'hideAll'
]

export type ContainerProps = PropsWithChildren<unknown>

export interface ButtonProps {
  readonly text: string
  readonly icon: string
  readonly disabled?: boolean
  readonly onClick: () => void
  readonly component?: ElementType<unknown> | string
}

interface Components {
  readonly Container: JSXElementConstructor<ContainerProps>
  readonly Button: JSXElementConstructor<ButtonProps>
  readonly Empty: JSXElementConstructor<unknown> | null
}

interface Props {
  readonly domainType: DomainType
  readonly target: ButtonTarget
  readonly on: ButtonLocation | null
  readonly components: Components
  readonly additionalButtons?: ButtonProps[]
  readonly priority?: DomainTypeButtonPriority
  readonly renderPopoverButtonInsideContainer?: boolean
  readonly parameterValues?: AttributeValue[]
  readonly leafNodeType?: 'active' | 'nested'
  readonly onComplete?: () => void
  readonly withinPopover?: boolean
}

export default function DomainTypeButtons({
  domainType,
  target,
  on,
  components,
  additionalButtons,
  parameterValues,
  priority,
  renderPopoverButtonInsideContainer = false,
  leafNodeType = 'active',
  onComplete,
  withinPopover = false
}: Props): JSX.Element | null {
  const popover = useMemo(() => (
    <ButtonsPopover
      key='popover'
      domainType={domainType}
      target={target}
      on={on}
      priority={priority}
      leafNodeType={leafNodeType} />
  ), [domainType, target, leafNodeType, on, priority])
  const user = useSelector(getUser)
  const buttons = useButtons(domainType, target, on, parameterValues, leafNodeType)
  const buttonComponents = useMemo(() => {
    const buttonComponents = [
      ...(additionalButtons ?? [])
        .map(additionalButton => (
          <components.Button
            key={additionalButton.text}
            text={additionalButton.text}
            icon={additionalButton.icon}
            disabled={additionalButton.disabled}
            onClick={additionalButton.onClick} />
        )),
      ...buttons
        .filter(button => {
          if (priority === undefined) {
            return true
          }

          if (withinPopover) {
            return priorityOrder.indexOf(button.priority ?? 'low') < priorityOrder.indexOf(priority)
          }

          return priorityOrder.indexOf(button.priority ?? 'low') >= priorityOrder.indexOf(priority)
        })
        .filter(button => isInRole(user, button.role))
        .map(button => (
          <DomainTypeButton
            key={`${button.domainType.Id}_${button.name}`}
            target={target}
            button={button}
            Component={components.Button}
            onComplete={onComplete} />
        ))
    ]
    if (renderPopoverButtonInsideContainer && ((additionalButtons?.length ?? 0) + buttons.length > buttonComponents.length)) {
      buttonComponents.push(popover)
    }

    return buttonComponents
  }, [additionalButtons, buttons, renderPopoverButtonInsideContainer, popover, components, priority, user, target, onComplete, withinPopover])

  return (
    <>
      {buttonComponents.length === 0
        ? components.Empty !== null
          ? (
            <components.Container>
              <components.Empty />
            </components.Container>
          )
          : null
        : (
          <components.Container>
            {buttonComponents}
          </components.Container>
        )}
    </>
  )
}
