import { JSXElementConstructor, useCallback, useContext, useState } from 'react'
import { DomainTypeContext } from 'utils/context'
import { ActionButton as ActionButtonType, ButtonTarget } from 'utils/hooks'
import ActionDialog from '../ActionDialog'
import { ButtonProps } from './DomainTypeButtons'

interface Props {
  readonly button: ActionButtonType
  readonly target: ButtonTarget
  readonly Component: JSXElementConstructor<ButtonProps>
  readonly onComplete?: () => void
}

export default function ActionButton({
  button,
  target,
  Component,
  onComplete
}: Props): JSX.Element | null {
  const [dialogOpen, setDialogOpen] = useState(false)
  const context = useContext(DomainTypeContext)

  const onClick = useCallback(async () => {
    setDialogOpen(true)
  }, [])

  const onSuccess = useCallback(() => {
    setDialogOpen(false)
    context.onInvalidate?.()
    onComplete?.()
  }, [context, onComplete])

  const onCloseDialog = useCallback(() => {
    setDialogOpen(false)
    onComplete?.()
  }, [setDialogOpen, onComplete])

  return (
    <>
      <Component
        text={button.name}
        disabled={button.disabled}
        icon={button.icon}
        onClick={onClick} />
      <ActionDialog
        open={dialogOpen}
        actionButton={button}
        target={target}
        onClose={onCloseDialog}
        onPerform={onSuccess} />
    </>
  )
}
