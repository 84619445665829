import * as E from 'fp-ts/Either'
import { pipe } from 'fp-ts/lib/function'
import { DateTime } from 'luxon'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { DomainType, DomainTypeInstance, Filter, Query, Sort } from 'types'
import { DomainTypeContext } from 'utils/context'
import { OverriderDetails } from 'utils/context/DomainTypeOverriderContext'
import { applyAllFilters, applyAnyFilters, getAnyAllFilters } from 'utils/filters'
import { getContextTree, getDomainTypeSetting, getPatchRequestBody, getRootDomainType, isNullOrUndefined } from 'utils/helpers'
import { ButtonTarget, SnackPack, useApi, useDomainTypeSetting, useFilterContext, useQueries, useSnackPack } from 'utils/hooks'
import { getChangeItemDatesPatchRequestBody, getChangeRouteDatesPatchRequestDatabaseTableAndBody, getMoveStopToSameRoutePutRequestBody } from './helpers'
import { CalendarProps, CalendarTimelineSettings, CustomTimelineItem, FindPageView, GroupByValue, MovingStop } from './types'
import useApplyFiltersSortsAndPage from './useApplyFiltersSortsAndPage'

export interface UseInMemoryFindOutput {
  items: DomainTypeInstance[]
  calendarItems: DomainTypeInstance[]
  total: number
  page: number
  pageSize: number
  searchText: string
  sorts: Sort[]
  filterLinkOperator: 'and' | 'or'
  filters: Filter[]
  overriderQueries: [Query, OverriderDetails][]
  domainTypeQueries: Query[]
  currentQuery: Query
  checkedRowIds: string[]
  checkedItems: DomainTypeInstance[]
  allChecked: boolean
  selectionTarget: ButtonTarget
  view: FindPageView
  calendarProps: CalendarProps
  snackPack: SnackPack
  onSearchTextChange(value?: string): void
  onFilterLinkOperatorChange(value: 'and' | 'or'): void
  onFiltersChange(value: Filter[]): void
  onPageChange(value: number): void
  onPageSizeChange(value: number): void
  onSortsChange(value: Sort[]): void
  getTotal(query: Query): number
  onApplyQuery(query: Query): void
  onCheckedRowIdsChange(ids: string[]): void
  onAllCheckedChange(value: boolean): void
  onViewChange(value: FindPageView): void
}

const EMPTY_CHECKED_ROW_IDS: string[] = []

export default function useInMemoryFind(
  domainType: DomainType,
  rows: DomainTypeInstance[],
  queryId: string,
  setQueryId: (queryId: string) => void
): UseInMemoryFindOutput {
  const [filterLinkOperator, setFilterLinkOperator] = useState<'and' | 'or'>('and')
  const [filters, setFilters] = useState<Filter[]>([])
  const [sorts, setSorts] = useState<Sort[]>([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(15)
  const [searchText, setSearchText] = useState('')
  const [editingItem, setEditingItem] = useState<CalendarProps['editingItem']>(null)
  const [movingStop, setMovingStop] = useState<CalendarProps['movingStop']>(null)
  const [actionDetails, setActionDetails] = useState<CalendarProps['actionDetails']>(undefined)
  const [changeItemDatesErrorCode, setChangeItemDatesErrorCode] = useState<string | undefined>(undefined)
  const domainTypes = useSelector(getAllDomainTypes)
  const filterContext = useFilterContext()
  const {
    applyFilters,
    applySorts,
    applyPage
  } = useApplyFiltersSortsAndPage(
    domainType,
    filters,
    filterLinkOperator,
    searchText,
    sorts,
    page,
    pageSize
  )
  const [view, setView] = useDomainTypeSetting(domainType, 'FindView', 'table')
  const filteredItems = useMemo(() => {
    return applyFilters(rows, row => row)
  }, [applyFilters, rows])
  const sortedItems = useMemo(() => {
    return applySorts(filteredItems, row => row)
  }, [filteredItems, applySorts])
  const items = useMemo(() => {
    if (view === 'calendar' || view === 'timeline') {
      return []
    }
    return applyPage(sortedItems)
  }, [applyPage, sortedItems, view])
  const calendarItems = useMemo(() => {
    if (view !== 'calendar' && view !== 'timeline') {
      return []
    }
    return sortedItems
  }, [sortedItems, view])
  const getTotal = useCallback((query: Query) => {
    const [anyFilters, allFilters] = getAnyAllFilters(
      domainTypes,
      domainType,
      query.FilterLinkOperator ?? 'and',
      query.Filters,
      query.SearchText ?? ''
    )
    return rows
      .filter(row => {
        if (anyFilters.length === 0) {
          return row
        }
        return applyAnyFilters(
          domainTypes,
          domainType,
          row,
          anyFilters,
          filterContext
        )
      })
      .filter(row => {
        return applyAllFilters(
          domainTypes,
          domainType,
          row,
          allFilters,
          filterContext
        )
      }).length
  }, [domainType, domainTypes, filterContext, rows])
  const {
    currentQuery,
    overriderQueries,
    domainTypeQueriesWithAll
  } = useQueries(domainType, queryId)
  const onApplyQuery = useCallback((query: Query) => {
    setFilterLinkOperator(query.FilterLinkOperator ?? 'and')
    setFilters(query.Filters)
    setSorts(query.Sorts)
    setSearchText(query.SearchText ?? '')
    setQueryId(query.Id)
  }, [setQueryId])
  const onSearchTextChange = useCallback((value: string) => {
    setSearchText(value)
  }, [])
  const onFilterLinkOperatorChange = useCallback((value: 'and' | 'or') => {
    setFilterLinkOperator(value)
  }, [])
  const onFiltersChange = useCallback((value: Filter[]) => {
    setFilters(value)
  }, [])
  const onSortsChange = useCallback((value: Sort[]) => {
    setSorts(value)
  }, [])
  const onPageSizeChange = useCallback((value: number) => {
    setPageSize(value)
    setPage(Math.floor(((page - 1) * pageSize + 1) / value) + 1)
  }, [page, pageSize])
  const [allChecked, onAllCheckedChange] = useState(false)
  const [checkedRowIds, setCheckedRowIds] = useState<string[]>(EMPTY_CHECKED_ROW_IDS)
  const onCheckedRowIdsChange = useCallback((ids: string[]) => {
    setCheckedRowIds(ids)
    onAllCheckedChange(false)
  }, [])
  useEffect(() => {
    onCheckedRowIdsChange(EMPTY_CHECKED_ROW_IDS)
  }, [onCheckedRowIdsChange, page, pageSize])
  const checkedItems = useMemo(() => {
    if (checkedRowIds.length === 0) {
      return []
    }
    const identifier = getDomainTypeSetting(domainTypes, domainType, 'Identifier') ?? 'Id'
    return items.filter(item => {
      const itemId = String(item[identifier])
      return checkedRowIds.includes(itemId)
    })
  }, [checkedRowIds, domainType, domainTypes, items])
  const selectionTarget = useMemo((): ButtonTarget => {
    if (allChecked) {
      return {
        type: 'instances',
        instances: sortedItems
      }
    }
    return {
      type: 'instances',
      instances: checkedItems
    }
  }, [allChecked, checkedItems, sortedItems])
  const [calendarView, setCalendarView] = useDomainTypeSetting(domainType, 'CalendarView', 'month')
  const [date, setDate] = useState<Date>(DateTime.now().toJSDate())
  const api = useApi()
  const domainTypeContext = useContext(DomainTypeContext)
  const onChangeItemDates = useCallback(async (
    type: CustomTimelineItem['type'],
    itemId: string,
    domainTypeChain: CalendarTimelineSettings[],
    groupItems: DomainTypeInstance[],
    items: DomainTypeInstance[],
    startDate: number,
    endDate: number,
    groupByValue?: GroupByValue,
    actionDetails?: CalendarProps['actionDetails']
  ) => {
    if (!api.isSignedIn) {
      return
    }
    if (type === 'route') {
      const [databaseTable, body] = getChangeRouteDatesPatchRequestDatabaseTableAndBody(
        domainTypeChain,
        items[0],
        startDate
      )
      setEditingItem({
        itemId,
        startDate,
        endDate,
        groupByValue
      })
      setActionDetails(actionDetails)
      setChangeItemDatesErrorCode(undefined)
      if (actionDetails !== undefined) {
        setActionDialogOpen(true)
        return
      }
      const response = await api.patch(
        databaseTable,
        body
      )
      pipe(
        response,
        E.match(
          apiError => setChangeItemDatesErrorCode(apiError.errorCode),
          () => domainTypeContext.onInvalidate?.()
        )
      )
      return
    }
    const body = getChangeItemDatesPatchRequestBody(
      domainTypeChain,
      groupItems,
      items,
      startDate,
      endDate,
      groupByValue
    )
    if (body === null) {
      return
    }

    const contextTree = getContextTree(
      domainTypeContext,
      domainType,
      [body]
    )
    const node = contextTree[0]
    if (node === undefined) {
      return null
    }
    const patchRequestBody = getPatchRequestBody(
      domainTypes,
      node
    )
    if (patchRequestBody === undefined) {
      return
    }
    const rootDomainType = getRootDomainType(
      domainTypes,
      node.domainType
    )
    if (rootDomainType === null) {
      return null
    }
    setEditingItem({
      itemId,
      startDate,
      endDate,
      groupByValue
    })
    setActionDetails(actionDetails)
    setChangeItemDatesErrorCode(undefined)
    if (actionDetails !== undefined) {
      setActionDialogOpen(true)
      return
    }
    const response = await api.patch(
      rootDomainType.Name,
      patchRequestBody
    )
    pipe(
      response,
      E.match(
        apiError => setChangeItemDatesErrorCode(apiError.errorCode),
        () => domainTypeContext.onInvalidate?.()
      )
    )
  }, [api, domainType, domainTypeContext, domainTypes])
  const onMoveRouteStop = useCallback((movingStop: MovingStop) => {
    async function move() {
      if (!api.isSignedIn) {
        return
      }
      if (movingStop.fromRoute.Id !== movingStop.toRoute.Id) {
        return
      }
      const putRequestBody = getMoveStopToSameRoutePutRequestBody(
        movingStop.fromRoute,
        movingStop.fromIndex,
        movingStop.toIndex
      )
      const databaseTable = movingStop.routeDatabaseTable
      if (putRequestBody === null
        || isNullOrUndefined(databaseTable)) {
        return
      }
      setMovingStop(null)
      await api.put(
        databaseTable,
        putRequestBody as unknown as DomainTypeInstance
      )
      domainTypeContext.onInvalidate?.()
    }
    move()
  }, [api, domainTypeContext])
  const instance = domainTypeContext.instances[0]?.[1]
  useEffect(() => {
    setEditingItem(null)
    setActionDetails(undefined)
  }, [instance])
  const context = useContext(DomainTypeContext)
  const [actionDialogOpen, setActionDialogOpen] = useState(false)
  const onCloseActionDialog = useCallback(() => {
    setActionDialogOpen(false)
    setEditingItem(null)
  }, [])
  const onPerformAction = useCallback(() => {
    setActionDialogOpen(false)
    context.onInvalidate?.()
  }, [context])
  const snackPack = useSnackPack()
  const { addMessage } = snackPack
  const { formatMessage } = useIntl()
  useEffect(() => {
    if (changeItemDatesErrorCode === undefined) {
      return
    }
    addMessage(formatMessage({
      id: changeItemDatesErrorCode,
      defaultMessage: changeItemDatesErrorCode
    }))
  }, [changeItemDatesErrorCode, formatMessage, addMessage])
  const calendarProps = useMemo(() => ({
    view: calendarView,
    date,
    editingItem,
    movingStop,
    actionDetails,
    actionDialogOpen,
    onViewChange: setCalendarView,
    onDateChange: setDate,
    onChangeItemDates,
    onCloseActionDialog,
    onPerformAction,
    onMoveRouteStop
  }), [actionDetails, actionDialogOpen, calendarView, date, editingItem, movingStop, onChangeItemDates, onCloseActionDialog, onMoveRouteStop, onPerformAction, setCalendarView])
  return {
    items,
    calendarItems,
    total: filteredItems.length,
    page,
    pageSize,
    searchText,
    sorts,
    filterLinkOperator,
    filters,
    overriderQueries,
    domainTypeQueries: domainTypeQueriesWithAll,
    currentQuery,
    checkedRowIds,
    checkedItems,
    selectionTarget,
    allChecked,
    view,
    calendarProps,
    snackPack,
    onSearchTextChange,
    onFilterLinkOperatorChange,
    onFiltersChange,
    onPageChange: setPage,
    onPageSizeChange,
    onSortsChange,
    getTotal,
    onApplyQuery,
    onCheckedRowIdsChange,
    onAllCheckedChange,
    onViewChange: setView
  }
}
