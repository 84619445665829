import { ActionEffect, CLEAR_JOB, CLEAR_PENDING_INTEREST_IN_JOB, CONFIRM_INTEREST_IN_JOBS, ClearJobAction, ClearPendingInterestInJobAction, ConfirmInterestInJobsAction, JobStatus, REGISTER_PENDING_INTEREST_IN_JOB, RegisterPendingInterestInJobAction, UPDATE_JOB_STATUS, UpdateJobStatusAction } from 'types'

export function registerPendingInterestInJob(
  jobId: string,
  effect: ActionEffect,
  labelPath: string[]
): RegisterPendingInterestInJobAction {
  return {
    type: REGISTER_PENDING_INTEREST_IN_JOB,
    payload: {
      jobId,
      effect,
      labelPath
    }
  }
}

export function clearPendingInterestInJob(jobId: string): ClearPendingInterestInJobAction {
  return {
    type: CLEAR_PENDING_INTEREST_IN_JOB,
    payload: {
      jobId
    }
  }
}

export function confirmInterestInJobs(): ConfirmInterestInJobsAction {
  return {
    type: CONFIRM_INTEREST_IN_JOBS
  }
}

export function updateJobStatus(jobStatus: JobStatus): UpdateJobStatusAction {
  return {
    type: UPDATE_JOB_STATUS,
    payload: {
      jobStatus
    }
  }
}

export function clearJob(jobId: string): ClearJobAction {
  return {
    type: CLEAR_JOB,
    payload: {
      jobId
    }
  }
}