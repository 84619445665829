import { Box, TextFieldProps } from '@mui/material'
import { FormEvent } from 'react'
import { AttributeValue, PathErrors } from 'types'
import AttributeInput from '../AttributeInput/AttributeInput'

interface Props {
  readonly attributeValues: AttributeValue[]
  readonly readOnly?: boolean
  readonly disabled?: boolean
  readonly pathErrors: PathErrors
  readonly textFieldProps?: Partial<TextFieldProps>
  onChange(attributeValue: AttributeValue): void
  onSubmit?(): void
  readonly isInsideAnotherForm?: boolean
}

export default function AttributeForm({
  attributeValues,
  readOnly = false,
  disabled = false,
  pathErrors,
  textFieldProps,
  onChange,
  onSubmit,
  isInsideAnotherForm = false
}: Props): JSX.Element {
  return (
    <Box
      component={isInsideAnotherForm ? 'div' : 'form'}
      display='flex'
      flexDirection='column'
      gap={1}
      autoComplete='off'
      onSubmit={(event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        event.stopPropagation()
        onSubmit?.()
      }}>
      {attributeValues
        .filter(attributeValue => !(attributeValue.hidden ?? false))
        .map(attributeValue => (
          <AttributeInput
            key={attributeValue.attribute.Name}
            attributeValue={attributeValue}
            pathError={pathErrors[attributeValue.attribute.Name]}
            readOnly={readOnly}
            disabled={disabled}
            textFieldProps={textFieldProps}
            onChange={onChange} />
        ))
      }
      {!isInsideAnotherForm && (
        <input
          type='submit'
          hidden />
      )}
    </Box>
  )
}