import { forwardRef, useImperativeHandle, useRef } from 'react'

export type UploadFileInputHandle = {
  open(): void
}

interface Props {
  readonly multiple?: boolean | null
  readonly accept?: string[]
  onChange(files: File[]): void
}

export default forwardRef<UploadFileInputHandle, Props>(function UploadFileInput(
  {
    multiple = false,
    accept = [],
    onChange
  }: Props,
  ref
): JSX.Element {
  const inputRef = useRef<HTMLInputElement | null>(null)
  useImperativeHandle(ref, () => ({
    open() {
      inputRef.current?.click()
    }
  }))
  return (
    <input
      hidden
      ref={inputRef}
      multiple={multiple ?? false}
      type='file'
      accept={accept.join(',')}
      onChange={event => onChange(Array.from(event.target.files ?? []))} />
  )
})

