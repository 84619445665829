import { Badge, IconButton, IconButtonProps, Snackbar, Stack, Tooltip } from '@mui/material'
import { ForwardedRef, MouseEvent, ReactNode, forwardRef, useState } from 'react'

interface Props extends IconButtonProps {
  readonly tooltipText: string
  readonly toastText?: string
  readonly icon: ReactNode,
  readonly badgeContent?: ReactNode,
  readonly onClick: (e: MouseEvent) => void
}

export default forwardRef(function TooltipIconButton({
  tooltipText,
  toastText,
  icon,
  badgeContent,
  onClick,
  ...rest
}: Props, ref: ForwardedRef<HTMLButtonElement>): JSX.Element {
  const [snackbarVisible, setSnackbarVisible] = useState(false)
  function handleSnackbarClose() {
    setSnackbarVisible(false)
  }
  function internalOnClick(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault()
    e.stopPropagation()
    e.currentTarget.blur()
    onClick(e)
    if (toastText !== undefined) {
      setSnackbarVisible(true)
    }
  }
  return (
    <Stack
      direction='row'
      alignItems='center'>
      <Tooltip
        title={tooltipText}
        disableInteractive>
        <div>
          <Badge
            badgeContent={badgeContent}
            color='primary'>
            <IconButton
              tabIndex={-1}
              onClick={internalOnClick}
              ref={ref}
              {...rest}>
              {icon}
            </IconButton>
          </Badge>
        </div>
      </Tooltip>
      <Snackbar
        open={snackbarVisible}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        message={toastText} />
    </Stack>
  )
})