import { Typography } from '@mui/material'
import AttributeCell from 'components/attribute/AttributeCell'
import { ReactCalendarGroupRendererProps } from 'react-calendar-timeline'
import { DomainType } from 'types'
import { isNullOrUndefined } from 'utils/helpers'
import { NULL_GROUP_ID } from '../helpers'
import { CalendarTimelineSettings, CustomTimelineGroup, CustomTimelineItem } from '../types'

interface Props {
  readonly rendererProps: ReactCalendarGroupRendererProps<CustomTimelineGroup>
  readonly timelineItems: CustomTimelineItem[]
  readonly visibleTimeStart: number
  readonly visibleTimeEnd: number
  readonly itemsDomainType: DomainType
  readonly domainTypeChain: CalendarTimelineSettings[]
  readonly usesRoutes: boolean
}

export default function TimelineGroup({
  rendererProps: {
    group,
    isRightSidebar
  },
  timelineItems,
  visibleTimeStart,
  visibleTimeEnd,
  itemsDomainType,
  domainTypeChain,
  usesRoutes
}: Props): JSX.Element {
  if (isRightSidebar === true) {
    const total = timelineItems
      .flatMap(item => {
        if (usesRoutes) {
          if (item.type === 'route') {
            return item.timelineItems
          } else {
            return []
          }
        } else if (item.type === 'item') {
          return [item]
        }
        return []
      })
      .filter(item => item.group === group.id)
      .filter(item => item.start_time < visibleTimeEnd && item.end_time > visibleTimeStart)
      .map(item => item.items.length)
      .reduce((a, b) => a + b, 0)
    return (
      <span>{total} {total === 1 ? itemsDomainType.Title : itemsDomainType.PluralTitle}</span>
    )
  }
  if (group.id === NULL_GROUP_ID
    && !isNullOrUndefined(domainTypeChain[domainTypeChain.length - 1]?.groupByAttribute)) {
    return (
      <Typography
        variant='body1'
        fontSize='inherit'
        color='GrayText'>
        None
      </Typography>
    )
  }
  return group.attributeValue !== undefined
    ? (
      <AttributeCell
        attributeChainValue={group.attributeValue}
        disableLink />
    )
    : <>{group.title}</>
}