import { either as E } from 'fp-ts'
import { constant, identity, pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'
import { useCallback, useState } from 'react'

export function useLocalStorageState<T>(
  name: string,
  defaultValue: T,
  codec: t.Type<T, string, unknown>
): [T, (value: T) => void] {
  const localStorageValue = pipe(
    localStorage.getItem(name),
    codec.decode,
    E.match(
      constant(defaultValue),
      identity
    )
  )
  const [value, setValue] = useState(localStorageValue)
  const onValueChange = useCallback((value: T) => {
    localStorage.setItem(name, codec.encode(value))
    setValue(value)
  }, [codec, name])
  return [value, onValueChange]
}