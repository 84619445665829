import { ButtonProps } from 'components/domainType/DomainTypeButtons/DomainTypeButtons'
import { ContextType, JSXElementConstructor, useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes, getCompany, getUser } from 'state/reducers'
import { ActionDomainTypeButton, Alert, AttributeValue, ButtonLocation, Company, ContextAttributeNode, ContextTree, DomainType, DomainTypeAction, DomainTypeAttribute, DomainTypeButton, DomainTypeInstance, ListAttribute, Query, User } from 'types'
import { PATH_SEPARATOR } from 'utils/constants'
import { DomainTypeContext, DomainTypeSettingsContext, SettingsContext, getSettingValue } from 'utils/context'
import { FilterContext, applyAllFilters, applyContextFilters } from 'utils/filters'
import { doesNotUpdateEntity, getDomainTypeAttributes, getDomainTypeButtons, getNodes, isDomainTypeListAttribute, isInRole, validateInstancesMatchCurrentCompany } from 'utils/helpers'
import { ActionDetails, useActions } from './useActions'
import { useFilterContext } from './useFilterContext'
import { useSubtypesCache } from './useSubtypesCache'

interface BaseButton {
  readonly domainType: DomainType
  readonly disabled: boolean
  readonly visible: boolean
  readonly showOn?: string[] | null
  readonly role: string | null
  readonly name: string
  readonly priority?: 'high' | 'medium' | 'low' | null
  readonly alert?: Alert | null
  readonly confirmationAlert?: Alert | null
}

export interface ActionButton extends BaseButton {
  readonly type: 'action'
  readonly apiDomainType: DomainType
  readonly pageDomainType: DomainType
  readonly icon: string
  readonly action: DomainTypeAction
  readonly contextTree: ContextTree
  readonly parameterValues?: AttributeValue[]
}

export interface EditButton extends BaseButton {
  readonly type: 'edit'
}

export interface CreateButton extends BaseButton {
  readonly type: 'create'
}

export interface DeleteButton extends BaseButton {
  readonly type: 'delete'
}

export interface CustomButtonProps {
  readonly button: CustomButton
  readonly target: ButtonTarget
  readonly Component: JSXElementConstructor<ButtonProps>
  onComplete?: () => void
}

export interface CustomButton extends BaseButton {
  readonly type: 'custom'
  readonly icon: string
  readonly Component: JSXElementConstructor<CustomButtonProps>
}

export type Button =
  | ActionButton
  | EditButton
  | CreateButton
  | DeleteButton
  | CustomButton

const OTHER_BUTTON_TYPES: Record<Exclude<DomainTypeButton['Type'], 'ActionButton'>, Exclude<Button['type'], 'action' | 'custom'>> = {
  CreateButton: 'create',
  EditButton: 'edit',
  DeleteButton: 'delete'
}

function getAttributeChain(
  node?: ContextAttributeNode
): ListAttribute<DomainTypeAttribute>[] {
  if (node === undefined) {
    return []
  }
  return (
    node.type === 'context'
      ? []
      : [node.attribute]
  ).concat(getAttributeChain(node.nodes[0]?.nodes[0]))
}

function getInstances(contextTree: ContextTree): [DomainType, DomainTypeInstance][] {
  return getNodes(contextTree)
    .filter(node => !node.type.includes('placeholder'))
    .map((node): [DomainType, DomainTypeInstance] => [node.domainType, node.instance])
}

export function getActionButton(
  domainTypes: Partial<Record<string, DomainType>>,
  details: ActionDetails,
  user: User | null,
  company: Company | null,
  target: ButtonTarget,
  parameterValues?: AttributeValue[]
): ActionButton | null {
  const correspondingActionButton = getDomainTypeButtons(domainTypes, details.actionDomainType)
    .map(([domainType, button]) => button)
    .filter((button): button is ActionDomainTypeButton => button.Type === 'ActionButton')
    .find(button => button.Action === details.action.Name)
  if (correspondingActionButton === undefined) {
    return null
  }
  const attributeChain = getAttributeChain(details.unfilteredContextTree[0]?.nodes[0])
  const enableForOtherCompanies = doesNotUpdateEntity(details.action)
  return {
    type: 'action',
    domainType: details.actionDomainType,
    disabled: details.disabled
      || isDisabledNonFilterConditions(
        correspondingActionButton,
        domainTypes,
        details.actionDomainType,
        target,
        company,
        getInstances(details.contextTree),
        enableForOtherCompanies
      ) === true,
    visible: details.visible && isInRole(user, correspondingActionButton.Role),
    showOn: correspondingActionButton.ShowOn,
    role: correspondingActionButton.Role,
    name: attributeChain
      .map(attribute => attribute.Title)
      .concat(correspondingActionButton.Name)
      .join(PATH_SEPARATOR),
    apiDomainType: details.apiDomainType,
    pageDomainType: details.pageDomainType,
    icon: correspondingActionButton.Icon,
    action: details.action,
    contextTree: details.contextTree,
    parameterValues,
    priority: correspondingActionButton.Priority === 'medium'
      ? attributeChain.length === 0
        ? 'medium'
        : 'low'
      : correspondingActionButton.Priority,
    alert: correspondingActionButton.Alert,
    confirmationAlert: correspondingActionButton.ConfirmationAlert
  }
}

export function isDisabledNonFilterConditions(
  button: DomainTypeButton,
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType,
  target: ButtonTarget,
  company: Company | null,
  contextInstances: [DomainType, DomainTypeInstance][],
  enableForOtherCompanies = false
): boolean | DomainTypeInstance[] {
  if ((button.Type === 'ActionButton' || button.Type === 'DeleteButton')
    && target.type === 'query'
    && button.ByQuery !== true) {
    return true
  }
  if (target.type === 'query') {
    return (button.Conditions?.length ?? 0) > 0
  }
  const instances = target.type === 'none'
    ? []
    : target.instances

  if (!enableForOtherCompanies && !validateInstancesMatchCurrentCompany(
    domainTypes,
    contextInstances
      .concat(instances.map(instance => [domainType, instance])),
    company
  )) {
    return true
  }

  return instances
}

export function isDisabled(
  button: DomainTypeButton,
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType,
  filterContext: FilterContext,
  settingsContext: SettingsContext,
  target: ButtonTarget,
  company: Company | null,
  contextInstances: ContextType<typeof DomainTypeContext>['instances']
): boolean {
  const enableEditNullCompany = getSettingValue(
    settingsContext,
    domainTypes,
    contextInstances[0]?.[0] ?? domainType,
    'enableEditNullCompany'
  ) ?? false
  const result = isDisabledNonFilterConditions(
    button,
    domainTypes,
    domainType,
    target,
    company,
    contextInstances,
    enableEditNullCompany
  )

  if (typeof result === 'boolean') {
    return result
  }

  return !applyContextFilters(
    domainTypes,
    domainType,
    button.Conditions ?? [],
    filterContext
  ) || !result.every(instance => {
    return applyAllFilters(
      domainTypes,
      domainType,
      instance,
      button.Conditions ?? [],
      filterContext
    )
  })
}

function getButtons(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType,
  target: ButtonTarget,
  actions: ActionDetails[],
  user: User | null,
  filterContext: FilterContext,
  subtypesCache: Partial<Record<string, DomainType[]>>,
  settingsContext: ContextType<typeof DomainTypeSettingsContext>,
  company: Company | null,
  domainTypeContext: ContextType<typeof DomainTypeContext>,
  parameterValues?: AttributeValue[]
): Button[] {
  const customButtons = getSettingValue(
    settingsContext,
    domainTypes,
    domainType,
    'customButtons'
  )?.(domainTypes, domainType, filterContext, subtypesCache, target, user) ?? []
  const actionButtons = actions.flatMap<ActionButton>(details => {
    const actionButton = getActionButton(
      domainTypes,
      details,
      user,
      company,
      target,
      parameterValues
    )
    if (actionButton === null) {
      return []
    }
    return [actionButton]
  })
  const otherButtons = getDomainTypeButtons(domainTypes, domainType).flatMap<Button>(([domainType, button]) => {
    const disabled = isDisabled(
      button,
      domainTypes,
      domainType,
      filterContext,
      settingsContext,
      target,
      company,
      domainTypeContext.instances
    )
    let visible = isInRole(user, button.Role)
    if (target.type === 'none') {
      visible = button.Type !== 'EditButton' && button.Type !== 'DeleteButton' && visible && !disabled
    }

    switch (button.Type) {
      case 'ActionButton':
        return []
      default:
        return [
          {
            type: OTHER_BUTTON_TYPES[button.Type],
            domainType: domainType,
            disabled,
            visible,
            showOn: button.ShowOn,
            role: button.Role,
            name: button.Name,
            priority: button.Priority,
            alert: button.Alert,
            confirmationAlert: button.ConfirmationAlert
          }
        ]
    }
  })
  return [
    ...customButtons,
    ...actionButtons,
    ...otherButtons
  ]
}

function isVisible(
  button: Button,
  on: ButtonLocation | null
): boolean {
  return button.visible
    && (
      on === null
        ? (button.showOn?.length ?? 0) === 0
        : button.showOn?.includes(on) ?? false
    )
}

const priorityOrder = {
  high: 0,
  medium: 1,
  low: 2
}

function sortByPriority(b1: Button, b2: Button): 1 | -1 {
  return priorityOrder[(b1.priority ?? 'low')] >= priorityOrder[(b2.priority ?? 'low')] ? 1 : -1
}

export interface InstancesButtonTarget {
  readonly type: 'instances'
  readonly instances: DomainTypeInstance[]
}

export interface QueryButtonTarget {
  readonly type: 'query'
  readonly query: Query
  readonly total: number
}

export interface NoneButtonTarget {
  readonly type: 'none'
}

export type ButtonTarget =
  | InstancesButtonTarget
  | QueryButtonTarget
  | NoneButtonTarget

export function useButtons(
  domainType: DomainType,
  target: ButtonTarget,
  on: ButtonLocation | null,
  parameterValues?: AttributeValue[],
  leafNodeType?: 'active' | 'nested'
): Button[] {
  const domainTypes = useSelector(getAllDomainTypes)
  const company = useSelector(getCompany)
  const domainTypeContext = useContext(DomainTypeContext)
  const filterContext = useFilterContext()
  const settingsContext = useContext(DomainTypeSettingsContext)
  const actions = useActions(domainType, target, on, leafNodeType)
  const user = useSelector(getUser)
  const subtypesCache = useSubtypesCache()
  return useMemo(() => {
    return getButtons(domainTypes, domainType, target, actions, user, filterContext, subtypesCache, settingsContext, company, domainTypeContext, parameterValues)
      .sort(sortByPriority)
      .filter(button => isVisible(button, on))
  }, [domainTypes, domainType, target, actions, user, filterContext, subtypesCache, settingsContext, company, domainTypeContext, parameterValues, on])
}

export interface DomainTypeListAttributeButton {
  readonly attributes: ListAttribute<DomainTypeAttribute>[]
  readonly button: Button
}

export function useDomainTypeListAttributeButtons(
  domainType: DomainType,
  instance: DomainTypeInstance | undefined,
  parameterValues?: AttributeValue[]
): DomainTypeListAttributeButton[] {
  const domainTypes = useSelector(getAllDomainTypes)
  const filterContext = useFilterContext()
  const subtypesCache = useSubtypesCache()
  const settingsContext = useContext(DomainTypeSettingsContext)
  const user = useSelector(getUser)
  const attributes = getDomainTypeAttributes(domainTypes, domainType)
  const domainTypeListAttributes = attributes.filter(isDomainTypeListAttribute)
  const company = useSelector(getCompany)
  const domainTypeContext = useContext(DomainTypeContext)
  return useMemo(() => {
    if (instance === undefined) {
      return []
    }
    return domainTypeListAttributes
      .flatMap(attribute => {
        const attributeDomainType = domainTypes[attribute.AttributeDomainType]
        if (attributeDomainType === undefined) {
          return []
        }
        const target: ButtonTarget = {
          type: 'instances',
          instances: []
        }
        return getButtons(domainTypes, attributeDomainType, target, [], user, filterContext, subtypesCache, settingsContext, company, domainTypeContext, parameterValues)
          .sort(sortByPriority)
          .filter(button => isVisible(button, 'TableToolbar'))
          .map(button => ({
            attributes: [attribute],
            button
          }))
      })
  }, [company, domainTypeContext, domainTypeListAttributes, domainTypes, filterContext, instance, parameterValues, settingsContext, subtypesCache, user])
}