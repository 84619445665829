import { pipe } from 'fp-ts/lib/function'
import { Lens } from 'monocle-ts'
import { ContextType, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes, getUser } from 'state/reducers'
import { Attribute, AttributeValue, DomainType, DomainTypeAttribute, DomainTypeInstance, EnumeratedValue, NonListEnumAttributeValue, RefAttribute } from 'types'
import { SYSTEM_COMPANY_ID } from 'utils/constants'
import { DomainTypeContext, DomainTypeSettingsContext, getSettingValue } from 'utils/context'
import { stringifyFilterValue } from 'utils/filters'
import { getAttributeValue, getCachedSubtypes, getDomainTypeAttribute, getDomainTypeAttributes, getDomainTypeSetting, getIdentifier, getRootDomainType, getSubtype, hasApi, isNullOrUndefined, isOfType, isRequired, makeSortFunction } from 'utils/helpers'
import { useSubtypesCache } from './useSubtypesCache'

function filterEnumSubtypeValues(
  values: EnumeratedValue[],
  childDomainTypeNames: string[]
): EnumeratedValue[] {
  return values
    .filter(v => childDomainTypeNames.includes(v.Value))
}

function getEnumSubtypeAttributeValue(
  attributeValue: NonListEnumAttributeValue,
  childDomainTypeNames: string[]
): NonListEnumAttributeValue {
  const values = filterEnumSubtypeValues(
    attributeValue.attribute.EnumeratedType.Values,
    childDomainTypeNames
  )
  const value = attributeValue.value ?? (values.length === 1 ? values[0]?.Value ?? null : null)
  return {
    attribute: {
      ...attributeValue.attribute,
      EnumeratedType: {
        ...attributeValue.attribute.EnumeratedType,
        Values: values
      }
    },
    value,
    hidden: value !== null && values.length === 1
  }
}

function mergeAttributeValues(
  previousAttributeValues: AttributeValue[],
  attributeValues: AttributeValue[]
): AttributeValue[] {
  return attributeValues.map(attributeValue => {
    return {
      ...previousAttributeValues
        .find(previousAttributeValue => previousAttributeValue.attribute.Id === attributeValue.attribute.Id),
      ...attributeValue
    }
  })
}

function createDomainTypeInstance(
  attributeValues: AttributeValue[],
  isApiDomainType: boolean,
  selectedCompanyId: string | undefined,
  nullSystemCompany = false,
  existingInstance: DomainTypeInstance | null
): DomainTypeInstance {
  const instance: DomainTypeInstance = {}
  if (!(selectedCompanyId === SYSTEM_COMPANY_ID && nullSystemCompany)
    && (isApiDomainType
      || attributeValues.find(a => a.attribute.Name === 'Company' && a.attribute.AttributeType === 'ref'))) {
    instance.Company = existingInstance?.Company !== undefined
      ? existingInstance.Company
      : selectedCompanyId
  }
  for (const attributeValue of attributeValues) {
    if (attributeValue.attribute.AttributeType === 'string') {
      instance[attributeValue.attribute.Name] = attributeValue.value === ''
        ? null
        : attributeValue.value
    } else {
      instance[attributeValue.attribute.Name] = attributeValue.value
    }
  }
  return {
    ...existingInstance,
    ...instance
  }
}

const attributeValueValueLens = Lens.fromProp<AttributeValue>()('value')
const attributeValueAttributeFiltersLens = Lens.fromPath<AttributeValue<DomainTypeAttribute | RefAttribute>>()(['attribute', 'Filters'])

function getRelationshipValue(
  attribute: Attribute,
  relationship: NonNullable<ContextType<typeof DomainTypeContext>['relationship']>
): AttributeValue['value'] {
  const value = attribute.AttributeType === 'domainType'
    ? relationship.instance
    : relationship.id
  if (attribute.List === true) {
    return [value]
  }
  return value
}

function getRelationshipAttributeValue(
  domainTypes: Partial<Record<string, DomainType>>,
  from: DomainTypeInstance,
  attribute: Attribute,
  relationship: ContextType<typeof DomainTypeContext>['relationship'],
  formMode: 'create' | 'edit'
): AttributeValue {
  if (formMode === 'edit'
    || relationship === undefined
    || attribute.Id === undefined
    || (attribute.AttributeType !== 'ref' && attribute.AttributeType !== 'domainType')) {
    return getAttributeValue(from, attribute)
  }
  const attributeValue = getAttributeValue(from, attribute)
  const attributeDomainType = domainTypes[attribute.AttributeDomainType]
  if (!hasApi(domainTypes, attributeDomainType)) {
    return attributeValue
  }
  const attributeChainIndex = relationship.attributeChain
    .findIndex(chainAttribute => chainAttribute.Id === attribute.Id)
  if (attributeChainIndex === -1) {
    return attributeValue
  }
  const precedingChain = relationship.attributeChain.slice(0, attributeChainIndex)
  if (precedingChain
    .some(chainAttribute => chainAttribute.List === true)) {
    return attributeValue
  }
  const restOfChain = relationship.attributeChain.slice(attributeChainIndex + 1)
  if (restOfChain.length === 0) {
    const matchesIdFilter = {
      Property: getIdentifier(domainTypes, attributeDomainType),
      Operator: 'eq',
      Value: stringifyFilterValue(relationship.id)
    }
    return pipe(
      attributeValue,
      attributeValueAttributeFiltersLens.modify(filters => [
        ...filters ?? [],
        matchesIdFilter
      ]),
      attributeValueValueLens.set(getRelationshipValue(
        attribute,
        relationship
      ))
    )
  }
  const restOfChainMatchesIdFilter = {
    Property: restOfChain.map(attribute => attribute.Name).join('_'),
    Operator: 'eq',
    Value: stringifyFilterValue(relationship.id)
  }
  return attributeValueAttributeFiltersLens.modify(filters => [
    ...filters ?? [],
    restOfChainMatchesIdFilter
  ])(attributeValue)
}

export interface UseDomainTypeFormOutput {
  allAttributeValues: AttributeValue[]
  attributeValues: AttributeValue[]
  subtype: DomainType
  createInstance(attributeValues: AttributeValue[]): DomainTypeInstance
  onChange(attributeValue: AttributeValue): void
  onReset(): void
}

export const FORM_MODE_ORDER_SETTING = {
  create: 'CreateForm',
  edit: 'EditForm'
} as const

interface CalculatedState {
  readonly allAttributeValues: AttributeValue[]
  readonly attributeValues: AttributeValue[]
  readonly subtype: DomainType
}

export function useDomainTypeForm(
  domainType: DomainType,
  instance: DomainTypeInstance | null,
  formMode: 'create' | 'edit',
  onInstanceChange?: (instance: DomainTypeInstance, attributeValues: AttributeValue[]) => void,
  removeErrorAtPath?: (path: string) => void
): UseDomainTypeFormOutput {
  const domainTypes = useSelector(getAllDomainTypes)
  const rootDomainType = getRootDomainType(domainTypes, domainType)
  const user = useSelector(getUser)
  const [calculatedState, setCalculatedState] = useState<CalculatedState>({
    allAttributeValues: [],
    attributeValues: [],
    subtype: domainType
  })
  const { relationship } = useContext(DomainTypeContext)
  const isApiDomainType = getDomainTypeSetting(domainTypes, domainType, 'Api') ?? false
  const settingsContext = useContext(DomainTypeSettingsContext)
  const nullSystemCompany = getSettingValue(settingsContext, domainTypes, domainType, 'nullSystemCompany')
  const createInstance = useCallback((attributeValues: AttributeValue[]) => {
    return createDomainTypeInstance(attributeValues, isApiDomainType, user?.selectedCompanyId, nullSystemCompany, instance)
  }, [instance, isApiDomainType, nullSystemCompany, user?.selectedCompanyId])
  const identifier = getDomainTypeSetting(domainTypes, domainType, 'Identifier') ?? 'Id'
  const subtypeAttributeName = useMemo(
    () => getDomainTypeSetting(domainTypes, domainType, 'Subtype'),
    [domainType, domainTypes]
  )
  const subtypesCache = useSubtypesCache()
  const childDomainTypeNames = useMemo(
    () => getCachedSubtypes(domainTypes, domainType, subtypesCache).map(t => t.Name),
    [domainType, domainTypes, subtypesCache]
  )
  const getCalculatedState = useCallback((instance: DomainTypeInstance): CalculatedState => {
    const subtype = getSubtype(
      domainTypes,
      rootDomainType ?? domainType,
      instance,
      subtypesCache
    ) ?? domainType
    const serializationType = getDomainTypeSetting(domainTypes, subtype, 'SerializationType')
    const all = getDomainTypeAttributes(domainTypes, subtype)
      .filter(attribute => attribute.Name !== 'Company' || attribute.AttributeType !== 'ref')
      .filter(attribute => attribute.Name !== identifier || formMode !== 'edit')
      .filter(attribute => attribute.Name !== identifier
        || attribute.AttributeType === 'string'
        || domainType.DatabaseTable === null
        || attribute.Required === true)
      .map(attribute => {
        const attributeValue = getRelationshipAttributeValue(
          domainTypes,
          instance,
          attribute,
          relationship,
          formMode
        )
        if (attribute.Name === subtypeAttributeName) {
          if (isOfType(attributeValue, 'enum', false)) {
            return getEnumSubtypeAttributeValue(attributeValue, childDomainTypeNames)
          }
          if (isOfType(attributeValue, 'string', false)) {
            return {
              ...attributeValue,
              hidden: childDomainTypeNames.length <= 1
            }
          }
        }
        if (attribute.Name === 'Type' && !isNullOrUndefined(serializationType)) {
          return {
            ...attributeValue,
            value: serializationType,
            hidden: formMode === 'create'
          }
        }
        return attributeValue
      })
    const attributeOrder = {
      create: getDomainTypeSetting(domainTypes, subtype, FORM_MODE_ORDER_SETTING.create) ?? all
        .filter(isRequired)
        .map(attributeValue => attributeValue.attribute.Name),
      edit: getDomainTypeSetting(domainTypes, subtype, FORM_MODE_ORDER_SETTING.edit) ?? all
        .map(attributeValue => attributeValue.attribute.Name)
    }
    const create = all
      .filter(attributeValue => {
        if (isRequired(attributeValue)) {
          return true
        }
        return attributeOrder.create.includes(attributeValue.attribute.Name)
      })
      .sort(makeSortFunction(attributeOrder.create, attributeValue => attributeValue.attribute.Name))
    const edit = all
      .filter(attributeValue => {
        return attributeOrder.edit.includes(attributeValue.attribute.Name)
      })
      .sort(makeSortFunction(attributeOrder.edit, attributeValue => attributeValue.attribute.Name))
    const filteredSorted = {
      create,
      edit
    }[formMode]
    return {
      allAttributeValues: all,
      attributeValues: filteredSorted,
      subtype
    }
  }, [domainTypes, rootDomainType, domainType, subtypesCache, formMode, identifier, relationship, subtypeAttributeName, childDomainTypeNames])
  const onReset = useCallback(() => {
    const newCalculatedState = getCalculatedState(instance ?? {})
    if (typeof subtypeAttributeName !== 'string') {
      setCalculatedState(newCalculatedState)
      return
    }
    const subtypeAttribute = getDomainTypeAttribute(domainTypes, domainType, subtypeAttributeName)
    if (subtypeAttribute?.AttributeType === 'enum') {
      const filteredValues = filterEnumSubtypeValues(
        subtypeAttribute.EnumeratedType.Values,
        childDomainTypeNames
      )
      const value = filteredValues.length === 1
        ? filteredValues[0]?.Value ?? null
        : newCalculatedState.subtype.Name
      setCalculatedState(getCalculatedState({
        [subtypeAttributeName]: value,
        ...instance
      }))
    } else {
      setCalculatedState(getCalculatedState({
        [subtypeAttributeName]: newCalculatedState.subtype.Name,
        ...instance
      }))
    }
  }, [childDomainTypeNames, domainType, domainTypes, getCalculatedState, instance, subtypeAttributeName])
  const onChange = useCallback((attributeValue: AttributeValue) => {
    const index = calculatedState.attributeValues
      .findIndex(a => a.attribute.Name === attributeValue.attribute.Name)
    const newAttributeValues = [
      ...calculatedState.attributeValues.slice(0, index),
      attributeValue,
      ...calculatedState.attributeValues.slice(index + 1)
    ]
    const instance = createInstance(newAttributeValues)
    const newCalculatedState = getCalculatedState(instance)
    const newMergedCalculatedState = {
      allAttributeValues: mergeAttributeValues(
        newAttributeValues,
        newCalculatedState.allAttributeValues
      ),
      attributeValues: mergeAttributeValues(
        newAttributeValues,
        newCalculatedState.attributeValues
      ),
      subtype: newCalculatedState.subtype
    }
    if (onInstanceChange !== undefined) {
      onInstanceChange(instance, newMergedCalculatedState.attributeValues)
    }
    removeErrorAtPath?.(attributeValue.attribute.Name)
    setCalculatedState(newMergedCalculatedState)
  }, [calculatedState.attributeValues, createInstance, getCalculatedState, onInstanceChange, removeErrorAtPath])
  useEffect(() => {
    setCalculatedState(getCalculatedState(createInstance(calculatedState.attributeValues)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domainTypes, domainType])
  return {
    ...calculatedState,
    createInstance,
    onChange,
    onReset
  }
}
