import { AnyAction } from '@reduxjs/toolkit'
import { combineReducers, Reducer } from 'redux'
import { RouterState } from 'redux-first-history/build/es6/reducer'
import { Action, AdalProperties, Company, DomainType, Job, Person, PreferencesState, SIGNOUT, State, User } from 'types'
import auth, * as fromAuth from './auth'
import domainTypes, * as fromDomainTypes from './domainTypes'
import jobs, * as fromJobs from './jobs'
import preferences, * as fromPreferences from './preferences'

export default (routerReducer: Reducer<RouterState, AnyAction>): Reducer<State, Action> => {
  const appReducer = combineReducers({
    router: routerReducer,
    auth,
    preferences,
    domainTypes,
    jobs
  })

  return (state, action) => {
    if (action.type === SIGNOUT) {
      return appReducer(undefined, action)
    }

    return appReducer(state, action)
  }
}

export function getAdalProperties(state: State): AdalProperties | null {
  return fromAuth.getAdalProperties(state.auth)
}

export function getUser(state: State): User | null {
  return fromAuth.getUser(state.auth)
}

export function getPerson(state: State): Person | null {
  return fromAuth.getPerson(state.auth)
}

export function getCompany(state: State): Company | null {
  return fromAuth.getCompany(state.auth)
}

export function getPreferences(state: State): PreferencesState {
  return fromPreferences.getPreferences(state.preferences)
}

export function getAllDomainTypes(state: State): Partial<Record<string, DomainType>> {
  return fromDomainTypes.getAll(state.domainTypes)
}

export function getJobs(state: State): Job[] {
  return fromJobs.getJobs(state.jobs)
}