import { Attribute, AttributeValue, DomainType, ListAttributeValue, NonListAttributeValue, NonListDomainTypeAttributeValue } from 'types'

export const ME_PREFIX = '@me'
export const CONTEXT_PREFIX = '@context'

export interface FilterContext {
  readonly [ME_PREFIX]: string | null
  readonly [CONTEXT_PREFIX]: NonListDomainTypeAttributeValue[]
  readonly domainTypes: Partial<Record<string, DomainType>>
}

export interface ApiFilter {
  readonly Property: string
  readonly Operator: string
  readonly Value: unknown
}

export interface FilterOperatorBase<A extends Attribute, Input extends Attribute = A> {
  readonly operator: string
  readonly label: string
  canApply(attribute?: Attribute): attribute is A
  readonly inputAttributeType: Input['AttributeType'],
  readonly list?: true
  readonly listInput: Input['List']
  readonly inputAttributeProperties: Omit<Input, keyof A>
}

export interface NonListFilterOperator<A extends Attribute, Input extends Attribute = A> extends FilterOperatorBase<A, Input> {
  list?: never
  apply(
    attributeValue: NonListAttributeValue<A>,
    inputAttributeValue: AttributeValue<Input>,
    domainTypes: Partial<Record<string, DomainType>>,
    context: FilterContext,
    propertyChain: string[]
  ): boolean
}

export interface ListFilterOperator<A extends Attribute, Input extends Attribute = A> extends FilterOperatorBase<A, Input> {
  list: true
  apply(
    attributeValue: ListAttributeValue<A>,
    inputAttributeValue: AttributeValue<Input>,
    domainTypes: Partial<Record<string, DomainType>>,
    context: FilterContext,
    propertyChain: string[]
  ): boolean
}

export type FilterOperator<A extends Attribute, Input extends Attribute = A> = NonListFilterOperator<A, Input> | ListFilterOperator<A, Input>

export interface AbsoluteDateFilterValue {
  readonly Type: 'AbsoluteDateFilterValue'
  readonly Value: string
}

export interface RelativeDateFilterValue {
  readonly Type: 'RelativeDateFilterValue'
  readonly Value: 'TodayRelativeDateFilterValueValue'
}

export type DateFilterValue =
  | AbsoluteDateFilterValue
  | RelativeDateFilterValue

export interface AbsoluteDateTimeFilterValue {
  readonly Type: 'AbsoluteDateTimeFilterValue',
  readonly Value: string
}

export interface RelativeDateTimeFilterValue {
  readonly Type: 'RelativeDateTimeFilterValue'
  readonly Value: 'NowRelativeDateTimeFilterValueValue'
}

export type DateTimeFilterValue =
  | AbsoluteDateTimeFilterValue
  | RelativeDateTimeFilterValue

export type RelativeDateRangeMeasure = 'days' | 'weeks' | 'months' | 'years'

export type CalendarRelativeDateRangeMeasure = 'weeks' | 'months' | 'years'

export interface NextRelativeDateRange {
  readonly Type: 'NextRelativeDateRange'
  readonly Units: number
  readonly Measure: RelativeDateRangeMeasure
  readonly IncludeToday: boolean
}

export interface NextCalendarRelativeDateRange {
  readonly Type: 'NextCalendarRelativeDateRange'
  readonly Units: number
  readonly Measure: CalendarRelativeDateRangeMeasure
  readonly IncludeCurrent: boolean
}

export interface LastRelativeDateRange {
  readonly Type: 'LastRelativeDateRange'
  readonly Units: number
  readonly Measure: RelativeDateRangeMeasure
  readonly IncludeToday: boolean
}

export interface LastCalendarRelativeDateRange {
  readonly Type: 'LastCalendarRelativeDateRange'
  readonly Units: number
  readonly Measure: CalendarRelativeDateRangeMeasure
  readonly IncludeCurrent: boolean
}

export interface ThisRelativeDateRange {
  readonly Type: 'ThisRelativeDateRange'
  readonly Measure: 'week' | 'month' | 'year'
}

export type RelativeDateRange =
  | NextRelativeDateRange
  | NextCalendarRelativeDateRange
  | LastRelativeDateRange
  | LastCalendarRelativeDateRange
  | ThisRelativeDateRange

export interface AbsoluteDateRangeFilterValue {
  readonly Type: 'AbsoluteDateRangeFilterValue'
  readonly From: string
  readonly To: string
}

export interface RelativeDateRangeFilterValue {
  readonly Type: 'RelativeDateRangeFilterValue'
  readonly Range: RelativeDateRange
}

export type DateRangeFilterValue =
  | AbsoluteDateRangeFilterValue
  | RelativeDateRangeFilterValue

export type RelativeDateTimeRangeMeasure = 'minutes' | 'hours' | 'days' | 'weeks' | 'months' | 'years'

export interface NextRelativeDateTimeRange {
  readonly Type: 'NextRelativeDateTimeRange'
  readonly Units: number
  readonly Measure: RelativeDateTimeRangeMeasure
}

export interface NextCalendarRelativeDateTimeRange {
  readonly Type: 'NextCalendarRelativeDateTimeRange'
  readonly Units: number
  readonly Measure: RelativeDateTimeRangeMeasure
  readonly IncludeCurrent: boolean
}

export interface LastRelativeDateTimeRange {
  readonly Type: 'LastRelativeDateTimeRange'
  readonly Units: number
  readonly Measure: RelativeDateTimeRangeMeasure
}

export interface LastCalendarRelativeDateTimeRange {
  readonly Type: 'LastCalendarRelativeDateTimeRange'
  readonly Units: number
  readonly Measure: RelativeDateTimeRangeMeasure
  readonly IncludeCurrent: boolean
}

export interface ThisRelativeDateTimeRange {
  readonly Type: 'ThisRelativeDateTimeRange'
  readonly Measure: 'minute' | 'hour' | 'day' | 'week' | 'month' | 'year'
}

export type RelativeDateTimeRange =
  | NextRelativeDateTimeRange
  | NextCalendarRelativeDateTimeRange
  | LastRelativeDateTimeRange
  | LastCalendarRelativeDateTimeRange
  | ThisRelativeDateTimeRange

export interface AbsoluteDateTimeRangeFilterValue {
  readonly Type: 'AbsoluteDateTimeRangeFilterValue'
  readonly From: string
  readonly To: string
}

export interface RelativeDateTimeRangeFilterValue {
  readonly Type: 'RelativeDateTimeRangeFilterValue'
  readonly Range: RelativeDateTimeRange
}

export type DateTimeRangeFilterValue =
  | AbsoluteDateTimeRangeFilterValue
  | RelativeDateTimeRangeFilterValue