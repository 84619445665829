import { ChevronRight, ExpandMore } from '@mui/icons-material'
import { Box, Button, Checkbox, Paper, Stack } from '@mui/material'
import { TreeItem, TreeView, treeItemClasses } from '@mui/x-tree-view'
import { DomainTypeCell } from 'components/attribute/AttributeCell'
import { ListApiDomainTypeInput } from 'components/attribute/AttributeInput'
import DomainTypeHeading from 'components/domainType/DomainTypeHeading'
import FileSaver from 'file-saver'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes, getCompany } from 'state/reducers'
import { Attribute, DomainType, DomainTypeAction, DomainTypeInstance, ListDomainTypeAttributeValue, Query } from 'types'
import { stringifyFilterValue } from 'utils/filters'
import { getDomainTypeExternalId, isNotNullOrUndefined, isNullOrUndefined } from 'utils/helpers'
import { useDomainType } from 'utils/hooks'

function isChildOf(nodeId: string): (id: string) => boolean {
  return id => `${id}_`.startsWith(`${nodeId}_`)
}

function isStrictChildOf(nodeId: string): (id: string) => boolean {
  return id => id.startsWith(`${nodeId}_`)
}

function isNotChildOf(nodeId: string): (id: string) => boolean {
  return id => !isChildOf(nodeId)(id)
}

function isParentOf(nodeId: string): (id: string) => boolean {
  return id => nodeId.startsWith(`${id}_`)
}

interface NodeCheckboxProps {
  nodeId: string
  allNodeIds: string[]
  selectedNodeIds: string[]
  onClickCheckbox(nodeId: string): void
}

function NodeCheckbox({
  nodeId,
  allNodeIds,
  selectedNodeIds,
  onClickCheckbox
}: NodeCheckboxProps): JSX.Element {
  const selectedChildNodeIds = selectedNodeIds.filter(isChildOf(nodeId))
  const allChildNodeIds = allNodeIds.filter(isChildOf(nodeId))
  return (
    <Checkbox
      size='small'
      checked={selectedNodeIds.includes(nodeId)}
      indeterminate={selectedNodeIds.filter(isChildOf(nodeId)).length > 0
        && selectedChildNodeIds.length < allChildNodeIds.length}
      onClick={event => {
        event.stopPropagation()
        onClickCheckbox(nodeId)
      }} />
  )
}

interface AddDomainTypeButtonProps {
  id: string
  selectedNodeIds: string[]
  dependency: boolean
  disabled?: boolean
  onAddDomainType(domainType: DomainType): void
}

function AddDomainTypeButton({
  id,
  selectedNodeIds,
  dependency,
  disabled,
  onAddDomainType
}: AddDomainTypeButtonProps): JSX.Element | null {
  const domainTypes = useSelector(getAllDomainTypes)
  const domainType = domainTypes[id]
  if (domainType === undefined) {
    return null
  }
  return (
    <Button
      size='small'
      disabled={disabled}
      color={selectedNodeIds.includes(domainType.Id)
        ? 'success'
        : dependency ? 'warning' : 'primary'}
      variant={selectedNodeIds.includes(domainType.Id)
        ? 'outlined'
        : 'contained'}
      onClick={event => {
        event.stopPropagation()
        onAddDomainType(domainType)
      }}>
      {domainType.Title}
    </Button>
  )
}

function getDomainTypeDependencies(
  domainType: DomainType
): string[] {
  return isNullOrUndefined(domainType.Parent)
    ? []
    : [domainType.Parent]
}

function getAttributeDependencies(
  attribute: Attribute
): string[] {
  if (attribute.AttributeType === 'contextRef') {
    return [
      attribute.AttributeDomainType,
      attribute.ContextDomainType
    ].filter((value, i, array) => array.indexOf(value) === i)
  }
  if (attribute.AttributeType !== 'domainType'
    && attribute.AttributeType !== 'ref') {
    return []
  }
  return [attribute.AttributeDomainType]
}

function getActionDependencies(
  action: DomainTypeAction
): string[] {
  const contextDependencies = (action.Context ?? [])
    .map(context => context.DomainType)
  const parameterDependencies = (action.Parameters ?? [])
    .flatMap(parameter => {
      if (parameter.Type === 'AttributeActionParameter'
        || parameter.Type === 'FileActionParameter'
        || parameter.Type === 'StaticActionParameter') {
        return []
      }
      return getAttributeDependencies(parameter.Attribute)
    })
  const effectDependencies = (action.Effects ?? [])
    .flatMap(effect => {
      if (effect.Type !== 'CreateItemsActionEffect') {
        return []
      }
      return [effect.DomainType]
    })
  return contextDependencies
    .concat(parameterDependencies)
    .concat(effectDependencies)
    .filter((value, i, array) => array.indexOf(value) === i)
}

function getQueryDependencies(
  query: Query
): string[] {
  return (query.DomainTypeOverrides ?? [])
    .flatMap(override => {
      return [override.Id].concat((override.Queries ?? []).flatMap(getQueryDependencies))
    })
    .filter((value, i, array) => array.indexOf(value) === i)
}

interface Dependency {
  id: string
  externalId: string
}

interface ExportData {
  domainTypes: Partial<DomainType>[]
  dependencies: Dependency[]
}

function processExportItem<T>(
  item: T,
  id: string,
  list: T[] | null | undefined,
  dependencies: string[],
  getDependencies: (item: T) => string[],
  selectedNodeIds: string[]
): void {
  if (!selectedNodeIds.includes(id)) {
    return
  }
  list?.push(item)
  dependencies.push(...getDependencies(item))
}

function getExportedDomainType(
  domainType: DomainType,
  selectedNodeIds: string[]
): [Partial<DomainType>, string[]] {
  const exportedDomainType: Partial<DomainType> = {
    ...domainType,
    Attributes: [],
    Actions: [],
    Buttons: [],
    Queries: []
  }
  const dependencies = getDomainTypeDependencies(domainType)
  for (const attribute of domainType.Attributes) {
    processExportItem(
      attribute,
      `${domainType.Id}_Attributes_${attribute.Id}`,
      exportedDomainType.Attributes,
      dependencies,
      getAttributeDependencies,
      selectedNodeIds
    )
  }
  for (const action of domainType.Actions ?? []) {
    processExportItem(
      action,
      `${domainType.Id}_Actions_${action.Name}`,
      exportedDomainType.Actions,
      dependencies,
      getActionDependencies,
      selectedNodeIds
    )
  }
  for (const button of domainType.Buttons ?? []) {
    processExportItem(
      button,
      `${domainType.Id}_Buttons_${button.Name}`,
      exportedDomainType.Buttons,
      dependencies,
      () => [],
      selectedNodeIds
    )
  }
  for (const query of domainType.Queries ?? []) {
    processExportItem(
      query,
      `${domainType.Id}_Queries_${query.Id}`,
      exportedDomainType.Queries,
      dependencies,
      getQueryDependencies,
      selectedNodeIds
    )
  }
  return [exportedDomainType, dependencies]
}

export default function ExportDomainTypesPage(): JSX.Element | null {
  const domainTypes = useSelector(getAllDomainTypes)
  const domainTypeDomainType = useDomainType('DomainType', 'DomainType')
  const attributeDomainType = useDomainType('Attribute', 'Attribute')
  const actionDomainType = useDomainType('Action', null)
  const buttonDomainType = useDomainType('Button', null)
  const queryDomainType = useDomainType('Query', null)
  const company = useSelector(getCompany)
  const [selectedNodeIds, setSelectedNodeIds] = useState<string[]>([])
  const [domainTypesToExport, setDomainTypesToExport] = useState<DomainType[]>([])
  const attributeValue: ListDomainTypeAttributeValue = useMemo(() => ({
    attribute: {
      Name: 'DomainTypes',
      Title: 'Add Domain Type',
      AttributeType: 'domainType',
      AttributeDomainType: domainTypeDomainType?.Id ?? '',
      List: true,
      Filters: [
        {
          Property: 'Company',
          Operator: 'eq',
          Value: stringifyFilterValue(company?.Id)
        }
      ]
    },
    value: domainTypesToExport as unknown as DomainTypeInstance[]
  }), [company?.Id, domainTypeDomainType?.Id, domainTypesToExport])
  const domainTypesInCompany = useMemo(() => {
    return Object.values(domainTypes)
      .filter(isNotNullOrUndefined)
      .filter(domainType => domainType.Company === company?.Id)
  }, [company?.Id, domainTypes])
  const allNodeIds = useMemo(() => {
    return domainTypesInCompany
      .flatMap(domainType => {
        return [
          domainType.Id,
          `${domainType.Id}_Attributes`,
          `${domainType.Id}_Actions`,
          `${domainType.Id}_Buttons`,
          `${domainType.Id}_Queries`,
          `${domainType.Id}_Subtypes`
        ]
          .concat(domainType.Attributes.map(attribute => `${domainType.Id}_Attributes_${attribute.Id ?? ''}`))
          .concat((domainType.Actions ?? []).map(action => `${domainType.Id}_Actions_${action.Name}`))
          .concat((domainType.Buttons ?? []).map(button => `${domainType.Id}_Buttons_${button.Name}`))
          .concat((domainType.Queries ?? []).map(query => `${domainType.Id}_Queries_${query.Id}`))
      })
  }, [domainTypesInCompany])
  const onClickCheckbox = useCallback((id: string) => {
    const childNodeIds = allNodeIds
      .filter(isChildOf(id))
    setSelectedNodeIds(selectedNodeIds => {
      if (!childNodeIds.every(nodeId => selectedNodeIds.includes(nodeId))) {
        const parentNodeIds = allNodeIds
          .filter(isParentOf(id))
        return [
          ...new Set(
            selectedNodeIds
              .concat(childNodeIds)
              .concat(parentNodeIds)
          )
        ]
      } else {
        let nextSelectedNodeIds = selectedNodeIds
        let parentNodeId = id
        do {
          nextSelectedNodeIds = nextSelectedNodeIds
            .filter(isNotChildOf(parentNodeId))
          parentNodeId = parentNodeId.split('_').slice(0, -1).join('_')
        } while (parentNodeId !== '' && !nextSelectedNodeIds.some(isStrictChildOf(parentNodeId)))
        return nextSelectedNodeIds
      }
    })
  }, [allNodeIds])
  const onAddDomainType = useCallback((domainType: DomainType) => {
    setDomainTypesToExport(list => {
      if (domainTypesToExport.find(type => type.Id === domainType.Id)) {
        return list
      }
      return domainTypesToExport.concat([domainType])
    })
  }, [domainTypesToExport])
  const ref = useRef<DomainType[]>([])
  useEffect(() => {
    const previous = ref.current
    const current = domainTypesToExport
    ref.current = domainTypesToExport

    const removed = previous.filter(domainType => !current.find(type => type.Id === domainType.Id))
    const added = current.filter(domainType => !previous.find(type => type.Id === domainType.Id))
    for (const removedType of removed) {
      onClickCheckbox(removedType.Id)
    }
    for (const addedType of added) {
      onClickCheckbox(addedType.Id)
    }
  }, [domainTypesToExport, onClickCheckbox])
  const onClickExport = useCallback(() => {
    const initialState: ExportData = {
      domainTypes: [],
      dependencies: []
    }
    const exportData = domainTypesToExport.reduce((prev, curr) => {
      const [exportedDomainType, dependencies] = getExportedDomainType(
        curr,
        selectedNodeIds
      )
      return {
        domainTypes: prev.domainTypes
          .concat([exportedDomainType]),
        dependencies: prev.dependencies
          .concat(dependencies
            .filter(id => !selectedNodeIds.includes(id))
            .map(id => {
              const dependencyDomainType = domainTypes[id]
              return {
                id,
                externalId: dependencyDomainType === undefined
                  ? ''
                  : getDomainTypeExternalId(dependencyDomainType)
              }
            }))
          .filter((value, i, array) => array.findIndex(dependency => dependency.id === value.id) === i)
      }
    }, initialState)
    FileSaver.saveAs(
      new Blob([
        JSON.stringify(exportData, null, 2)
      ]),
      'Domain Types.json'
    )
  }, [domainTypes, domainTypesToExport, selectedNodeIds])
  if (domainTypeDomainType === null) {
    return null
  }
  return (
    <>
      <Stack
        gap={1}
        direction='row'
        alignItems='center'
        sx={{
          mt: 1,
          mb: 1,
          minHeight: '40px'
        }}>
        <DomainTypeHeading
          domainType={domainTypeDomainType}
          isLoading={false}
          plural
          title='Export:'
          flexWrap='wrap' />
      </Stack>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}>
        {domainTypesToExport.length > 0 && (
          <TreeView
            defaultExpanded={allNodeIds}
            defaultCollapseIcon={<ExpandMore />}
            defaultExpandIcon={<ChevronRight />}
            sx={{
              [`& .${treeItemClasses.content}`]: {
                pt: 0.5,
                pb: 0.5
              }
            }}>
            {domainTypesToExport
              .map(domainType => (
                <TreeItem
                  key={domainType.Id}
                  nodeId={domainType.Id}
                  label={(
                    <Box
                      display='flex'
                      alignItems='center'
                      gap={1}>
                      <DomainTypeCell
                        attributeValue={{
                          attribute: {
                            Name: 'DomainType',
                            Title: 'Domain Type',
                            AttributeType: 'domainType',
                            AttributeDomainType: domainTypeDomainType.Id
                          },
                          value: domainType as unknown as DomainTypeInstance
                        }} />
                      <Button
                        size='small'
                        variant='contained'
                        color='error'
                        onClick={event => {
                          event.stopPropagation()
                          setDomainTypesToExport(list => {
                            return list.filter(type => type.Id !== domainType.Id)
                          })
                        }}>
                        Remove
                      </Button>
                      <Box flexGrow={1} />
                      {getDomainTypeDependencies(domainType).map(id => (
                        <AddDomainTypeButton
                          key={id}
                          id={id}
                          selectedNodeIds={selectedNodeIds}
                          dependency
                          disabled={!domainTypesInCompany.find(type => type.Id === id)}
                          onAddDomainType={onAddDomainType} />
                      ))}
                    </Box>
                  )}>
                  {domainType.Attributes.length > 0 && (
                    <TreeItem
                      nodeId={`${domainType.Id}_Attributes`}
                      label={(
                        <Box
                          display='flex'
                          alignItems='center'
                          gap={1}>
                          <NodeCheckbox
                            nodeId={`${domainType.Id}_Attributes`}
                            allNodeIds={allNodeIds}
                            selectedNodeIds={selectedNodeIds}
                            onClickCheckbox={onClickCheckbox} />
                          Attributes
                        </Box>
                      )}>
                      {domainType.Attributes.map(attribute => (
                        <TreeItem
                          key={attribute.Id}
                          nodeId={`${domainType.Id}_Attributes_${attribute.Id ?? ''}`}
                          label={(
                            <Box
                              display='flex'
                              alignItems='center'
                              gap={1}>
                              <NodeCheckbox
                                nodeId={`${domainType.Id}_Attributes_${attribute.Id ?? ''}`}
                                allNodeIds={allNodeIds}
                                selectedNodeIds={selectedNodeIds}
                                onClickCheckbox={onClickCheckbox} />
                              <DomainTypeCell
                                attributeValue={{
                                  attribute: {
                                    Name: 'Attribute',
                                    Title: 'Attribute',
                                    AttributeType: 'domainType',
                                    AttributeDomainType: attributeDomainType?.Id ?? ''
                                  },
                                  value: attribute as unknown as DomainTypeInstance
                                }} />
                              <Box flexGrow={1} />
                              {selectedNodeIds.includes(`${domainType.Id}_Attributes_${attribute.Id ?? ''}`)
                                && getAttributeDependencies(attribute).map(id => (
                                  <AddDomainTypeButton
                                    key={id}
                                    id={id}
                                    selectedNodeIds={selectedNodeIds}
                                    dependency
                                    disabled={!domainTypesInCompany.find(type => type.Id === id)}
                                    onAddDomainType={onAddDomainType} />
                                ))}
                            </Box>
                          )}>
                        </TreeItem>
                      ))}
                    </TreeItem>
                  )}
                  {(domainType.Actions ?? []).length > 0 && (
                    <TreeItem
                      nodeId={`${domainType.Id}_Actions`}
                      label={(
                        <Box
                          display='flex'
                          alignItems='center'
                          gap={1}>
                          <NodeCheckbox
                            nodeId={`${domainType.Id}_Actions`}
                            allNodeIds={allNodeIds}
                            selectedNodeIds={selectedNodeIds}
                            onClickCheckbox={onClickCheckbox} />
                          Actions
                        </Box>
                      )}>
                      {domainType.Actions?.map(action => (
                        <TreeItem
                          key={action.Name}
                          nodeId={`${domainType.Id}_Actions_${action.Name}`}
                          label={(
                            <Box
                              display='flex'
                              alignItems='center'
                              gap={1}>
                              <NodeCheckbox
                                nodeId={`${domainType.Id}_Actions_${action.Name}`}
                                allNodeIds={allNodeIds}
                                selectedNodeIds={selectedNodeIds}
                                onClickCheckbox={onClickCheckbox} />
                              <DomainTypeCell
                                attributeValue={{
                                  attribute: {
                                    Name: 'Action',
                                    Title: 'Action',
                                    AttributeType: 'domainType',
                                    AttributeDomainType: actionDomainType?.Id ?? ''
                                  },
                                  value: action as unknown as DomainTypeInstance
                                }} />
                              <Box flexGrow={1} />
                              {selectedNodeIds.includes(`${domainType.Id}_Actions_${action.Name}`)
                                && getActionDependencies(action).map(id => (
                                  <AddDomainTypeButton
                                    key={id}
                                    id={id}
                                    selectedNodeIds={selectedNodeIds}
                                    dependency
                                    disabled={!domainTypesInCompany.find(type => type.Id === id)}
                                    onAddDomainType={onAddDomainType} />
                                ))}
                            </Box>
                          )}>
                        </TreeItem>
                      ))}
                    </TreeItem>
                  )}
                  {(domainType.Buttons ?? []).length > 0 && (
                    <TreeItem
                      nodeId={`${domainType.Id}_Buttons`}
                      label={(
                        <Box
                          display='flex'
                          alignItems='center'
                          gap={1}>
                          <NodeCheckbox
                            nodeId={`${domainType.Id}_Buttons`}
                            allNodeIds={allNodeIds}
                            selectedNodeIds={selectedNodeIds}
                            onClickCheckbox={onClickCheckbox} />
                          Buttons
                        </Box>
                      )}>
                      {domainType.Buttons?.map(button => (
                        <TreeItem
                          key={button.Name}
                          nodeId={`${domainType.Id}_Buttons_${button.Name}`}
                          label={(
                            <Box
                              display='flex'
                              alignItems='center'
                              gap={1}>
                              <NodeCheckbox
                                nodeId={`${domainType.Id}_Buttons_${button.Name}`}
                                allNodeIds={allNodeIds}
                                selectedNodeIds={selectedNodeIds}
                                onClickCheckbox={onClickCheckbox} />
                              <DomainTypeCell
                                attributeValue={{
                                  attribute: {
                                    Name: 'Button',
                                    Title: 'Button',
                                    AttributeType: 'domainType',
                                    AttributeDomainType: buttonDomainType?.Id ?? ''
                                  },
                                  value: button as unknown as DomainTypeInstance
                                }} />
                            </Box>
                          )}>
                        </TreeItem>
                      ))}
                    </TreeItem>
                  )}
                  {(domainType.Queries ?? []).length > 0 && (
                    <TreeItem
                      nodeId={`${domainType.Id}_Queries`}
                      label={(
                        <Box
                          display='flex'
                          alignItems='center'
                          gap={1}>
                          <NodeCheckbox
                            nodeId={`${domainType.Id}_Queries`}
                            allNodeIds={allNodeIds}
                            selectedNodeIds={selectedNodeIds}
                            onClickCheckbox={onClickCheckbox} />
                          Queries
                        </Box>
                      )}>
                      {domainType.Queries?.map(query => (
                        <TreeItem
                          key={query.Id}
                          nodeId={`${domainType.Id}_Queries_${query.Id}`}
                          label={(
                            <Box
                              display='flex'
                              alignItems='center'
                              gap={1}>
                              <NodeCheckbox
                                nodeId={`${domainType.Id}_Queries_${query.Id}`}
                                allNodeIds={allNodeIds}
                                selectedNodeIds={selectedNodeIds}
                                onClickCheckbox={onClickCheckbox} />
                              <DomainTypeCell
                                attributeValue={{
                                  attribute: {
                                    Name: 'Query',
                                    Title: 'Query',
                                    AttributeType: 'domainType',
                                    AttributeDomainType: queryDomainType?.Id ?? ''
                                  },
                                  value: query as unknown as DomainTypeInstance
                                }} />
                              <Box flexGrow={1} />
                              {selectedNodeIds.includes(`${domainType.Id}_Queries_${query.Id}`)
                                && getQueryDependencies(query).map(id => (
                                  <AddDomainTypeButton
                                    key={id}
                                    id={id}
                                    selectedNodeIds={selectedNodeIds}
                                    dependency
                                    disabled={!domainTypesInCompany.find(type => type.Id === id)}
                                    onAddDomainType={onAddDomainType} />
                                ))}
                            </Box>
                          )}>
                        </TreeItem>
                      ))}
                    </TreeItem>
                  )}
                  {domainTypesInCompany.filter(type => type.Parent === domainType.Id).length > 0 && (
                    <TreeItem
                      nodeId={`${domainType.Id}_Subtypes`}
                      label={(
                        <Box
                          display='flex'
                          alignItems='center'
                          gap={1}>
                          Subtypes
                        </Box>
                      )}>
                      {domainTypesInCompany.filter(type => type.Parent === domainType.Id)
                        .map(subtype => (
                          <TreeItem
                            key={subtype.Id}
                            nodeId={`${domainType.Id}_Subtypes_${subtype.Id}`}
                            label={(
                              <AddDomainTypeButton
                                id={subtype.Id}
                                selectedNodeIds={selectedNodeIds}
                                dependency={false}
                                onAddDomainType={onAddDomainType} />
                            )}>
                          </TreeItem>
                        ))}
                    </TreeItem>
                  )}
                </TreeItem>
              ))}
          </TreeView>
        )}
        <ListApiDomainTypeInput
          domainTypes={domainTypes}
          domainType={domainTypeDomainType}
          attributeValue={attributeValue}
          readOnly={false}
          onChange={attributeValue => setDomainTypesToExport((attributeValue.value ?? []) as unknown as DomainType[])} />
        <Button
          variant='contained'
          color='success'
          disabled={domainTypesToExport.length === 0}
          onClick={onClickExport}
          sx={{
            alignSelf: 'end'
          }}>
          Export {domainTypesToExport.length} Domain Type{domainTypesToExport.length === 1 ? '' : 's'}
        </Button>
      </Paper>
    </>
  )
}