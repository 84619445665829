// --------------------------------------------------------------------------------------------------------------------
// <copyright file="ResetPasswordForm.tsx" company="Spartan Solutions Ltd.">
//   Copyright © Spartan Solutions Ltd. All rights reserved.
// </copyright>
// --------------------------------------------------------------------------------------------------------------------

import { Login, Visibility, VisibilityOff } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Alert, IconButton, InputAdornment, Link } from '@mui/material'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { styled, useTheme } from '@mui/material/styles'
import * as E from 'fp-ts/Either'
import { ReactComponent as LightThemePhalanxLogo } from 'phalanx_black.svg'
import { ReactComponent as DarkThemePhalanxLogo } from 'phalanx_white.svg'
import { useCallback, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { push } from 'redux-first-history'
import { ApiError } from 'types'
import { PAGE_URL } from 'utils/constants'
import { useApi } from 'utils/hooks'
import { resetPasswordFulfilled } from '../../state/actions/auth'

const PREFIX = 'ResetPasswordForm'

const classes = {
  paper: `${PREFIX}-paper`,
  avatar: `${PREFIX}-avatar`,
  form: `${PREFIX}-form`,
  submit: `${PREFIX}-submit`
}

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`& .${classes.paper}`]: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },

  [`& .${classes.avatar}`]: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },

  [`& .${classes.form}`]: {
    width: '100%',
    marginTop: theme.spacing(1)
  },

  [`& .${classes.submit}`]: {
    margin: theme.spacing(3, 0, 2)
  }
}))

const messages = defineMessages({
  'newPassword.label': {
    id: 'resetPasswordForm.newPassword.label',
    defaultMessage: 'New Password'
  },
  'button.text': {
    id: 'resetPasswordForm.button.text',
    defaultMessage: 'Reset Password'
  },
  'button.loadingText': {
    id: 'resetPasswordForm.button.loadingText',
    defaultMessage: 'Resetting Password...'
  },
  'api.errorCode.resetPassword.exception': {
    id: 'resetPasswordForm.exception',
    defaultMessage: 'A problem occurred when trying to reset your password'
  },
  'api.errorCode.invalidResponse': {
    id: 'resetPasswordForm.invalidResponse',
    defaultMessage: 'A problem occurred when trying to reset your password'
  }
})

function Copyright(): JSX.Element {
  return (
    <Typography
      variant='body2'
      color='textSecondary'
      align='center'>
      {'Copyright © '}
      <Link
        href='https://spartansolutions.com/'
        target='_blank'>
        Spartan Solutions Ltd
      </Link>{' '}
      {new Date().getFullYear()}
      .
    </Typography>
  )
}

export default function ResetPasswordForm(): JSX.Element {
  const { username = '', token = '' } = useParams()
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const [values, setValues] = useState({
    newPassword: ''
  })
  const onChange = (key: keyof typeof values, value: string): void => setValues({
    ...values,
    [key]: value
  })
  const [isLoading, setIsLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const api = useApi()
  const resetPasswordHandler = useCallback(async (response: E.Either<ApiError, void>) => {
    if (E.isRight(response)) {
      dispatch(resetPasswordFulfilled())
      dispatch(push(PAGE_URL.HOME))
    } else {
      const errorCode = response.left.errorCode as keyof typeof messages

      const messageText = errorCode in messages ? messages[errorCode] : null

      const message = messageText ? formatMessage(messageText) : errorCode

      setErrorMessage(message)
    }
  }, [dispatch, formatMessage])
  const performResetPassword = useCallback(async () => {
    if (api.isSignedIn) {
      return
    }
    setIsLoading(true)
    const resetValues = {
      ...values,
      username: username,
      token: token
    }
    const response = await api.resetPassword(resetValues)
    setIsLoading(false)
    resetPasswordHandler(response)
  }, [api, values, username, token, resetPasswordHandler])
  const theme = useTheme()
  return (
    <StyledContainer
      maxWidth='xs'>
      <CssBaseline />
      <div className={classes.paper}>
        {theme.palette.mode === 'dark'
          ? (
            <DarkThemePhalanxLogo
              height='40'
              width='200' />
          )
          : (
            <LightThemePhalanxLogo
              height='40'
              width='200' />
          )}
        {errorMessage && (
          <Alert
            sx={{ mt: 2 }}
            severity='error'>
            {errorMessage}
          </Alert>
        )}
        <form
          className={classes.form}
          onSubmit={(e) => {
            e.preventDefault()
            performResetPassword()
          }}>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='newPassword'
            label={formatMessage(messages['newPassword.label'])}
            type={!showPassword ? 'password' : undefined}
            name='newPassword'
            autoFocus
            value={values.newPassword}
            onChange={({ target }) => onChange('newPassword', target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      setShowPassword(!showPassword)
                    }
                    }
                    edge='end'
                    size='large'>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }} />
          <LoadingButton
            type='submit'
            fullWidth
            loading={isLoading}
            variant='contained'
            loadingPosition='end'
            endIcon={<Login />}
            color='primary'
            className={classes.submit}
            size='large'>
            {isLoading
              ? formatMessage(messages['button.loadingText'])
              : formatMessage(messages['button.text'])}
          </LoadingButton>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </StyledContainer>
  )
}
