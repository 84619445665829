import { Theme } from '@mui/material'
import { ContextType, createContext } from 'react'
import { DomainType, DomainTypeInstance } from 'types'
import { DomainTypeContext } from 'utils/context'

interface FindSidePanel {
  readonly id: string
  readonly type: 'find'
  readonly domainType: DomainType
  readonly theme: Theme
  onClose?(): void
}

interface DetailsSidePanel {
  readonly id: string
  readonly type: 'details'
  readonly context: ContextType<typeof DomainTypeContext>
  readonly domainType: DomainType
  readonly instance: DomainTypeInstance
  readonly title?: string
  readonly theme: Theme
  onClose?(): void
}

interface DomainTypeDetailsSidePanel {
  readonly id: string
  readonly type: 'domainTypeDetails'
  onClose?(): void
}

interface MapSidePanel {
  readonly id: string
  readonly type: 'map'
  readonly domainType: DomainType
  readonly instances: DomainTypeInstance[]
  readonly theme: Theme
  onClose?(): void
}

export type SidePanel =
  | FindSidePanel
  | DetailsSidePanel
  | DomainTypeDetailsSidePanel
  | MapSidePanel

interface SidePanelDetails {
  name: string
  setSidePanel(sidePanel: SidePanel | null): void
  sidePanel: SidePanel | null
  onToggleChild(name: string, open: boolean): void
}

export default createContext<SidePanelDetails[]>([])