import { Divider, MenuItem } from '@mui/material'
import { GridColumnMenuContainer, GridColumnMenuProps, HideGridColMenuItem, SortGridMenuItems } from '@mui/x-data-grid'
import { forwardRef, useContext } from 'react'
import TableViewContext from './TableViewContext'

export default forwardRef<HTMLUListElement, GridColumnMenuProps>(function CustomColumnMenu(props: GridColumnMenuProps, ref) {
  const { hideMenu, currentColumn } = props
  const { setColumnVisibility, onAddFilter } = useContext(TableViewContext)
  return (
    <GridColumnMenuContainer
      ref={ref}
      {...props}>
      <SortGridMenuItems
        onClick={hideMenu}
        column={currentColumn} />
      <Divider />
      <MenuItem
        onClick={event => {
          event.stopPropagation()
          onAddFilter({
            Property: currentColumn.field,
            Operator: currentColumn.filterOperators?.[0]?.value ?? 'like',
            Value: null
          })
          hideMenu(event)
        }}>
        Add Filter
      </MenuItem>
      <Divider />
      <HideGridColMenuItem
        onClick={event => {
          setColumnVisibility(currentColumn.field, false)
          hideMenu(event)
        }}
        column={currentColumn} />
    </GridColumnMenuContainer>
  )
})