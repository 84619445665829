import { Grid } from '@mui/material'
import EnumInput from 'components/attribute/AttributeInput/EnumInput'
import DateRangePicker, { Shortcut } from 'components/utils/DateRangePicker'
import * as t from 'io-ts'
import { DateTime } from 'luxon'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { DomainTypeInstance } from 'types'
import { Dashboard as DashboardType, Panel as PanelType } from 'types/dashboard'
import { getIdentifier, requiresFormsAuthenticationCookieRefresh } from 'utils/helpers'
import { useApi, useLocalStorageState } from 'utils/hooks'
import { v4 } from 'uuid'
import Panel from './Panel'
import VariableInput from './VariableInput'
import shortcuts from './shortcuts'

interface Props {
  readonly location: string
  readonly dashboard: DashboardType
  readonly variableValues?: Partial<Record<string, string[]>>
}

export default function Dashboard({
  location,
  dashboard,
  variableValues
}: Props): JSX.Element {
  const [variableInputValues, setVariableInputValues] = useState<Partial<Record<string, DomainTypeInstance[]>>>({})
  const domainTypes = useSelector(getAllDomainTypes)
  const [lastSelectedShortcut, setLastSelectedShortcut] = useLocalStorageState(
    `${dashboard.Id}.lastSelectedShortcut`,
    shortcuts[0]?.label ?? 'Today',
    t.string
  )
  const shortcut = useMemo(() => {
    return shortcuts.find(shortcut => shortcut.label === lastSelectedShortcut)
  }, [lastSelectedShortcut])
  const [shortcutStartDate, shortcutEndDate] = useMemo(() => {
    return shortcut?.getValue() ?? [DateTime.now().startOf('day'), DateTime.now().endOf('day')]
  }, [shortcut])
  const [startDate, onStartDateChange] = useState(shortcutStartDate)
  const [endDate, onEndDateChange] = useState(shortcutEndDate)
  const onShortcutSelect = useCallback((shortcut: Shortcut) => {
    setLastSelectedShortcut(shortcut.label)
  }, [setLastSelectedShortcut])
  const panelVariableValues = useMemo(() => {
    return Object.keys(variableInputValues)
      .reduce((prev, curr) => {
        const variable = dashboard.Variables
          ?.find(variable => variable.Name === curr)
        if (variable === undefined) {
          return prev
        }
        const domainType = domainTypes[variable.DomainType]
        if (domainType === undefined) {
          return prev
        }
        const identifier = getIdentifier(domainTypes, domainType)
        prev[curr] = variableInputValues[curr]
          ?.map(instance => String(instance[variable.Property ?? identifier])) ?? []
        return prev
      }, variableValues ?? {})
  }, [dashboard.Variables, domainTypes, variableInputValues, variableValues])
  const [expandedPanelId, setExpandedPanelId] = useLocalStorageState(
    `${dashboard.Id}.${location}.expandedPanelId`,
    '',
    t.string
  )
  const expandedPanel = useMemo(() => {
    return dashboard.Panels?.find(panel => panel.Id === expandedPanelId)
  }, [dashboard.Panels, expandedPanelId])
  const onExpandPanel = useCallback((panel: PanelType) => {
    setExpandedPanelId(panel.Id)
  }, [setExpandedPanelId])
  const onCollapsePanel = useCallback(() => {
    setExpandedPanelId('')
  }, [setExpandedPanelId])
  const isLoading = requiresFormsAuthenticationCookieRefresh()
  const api = useApi()
  const [, forceRender] = useState(v4())
  useEffect(() => {
    if (isLoading && api.isSignedIn) {
      api.refreshFormsAuthenticationCookie()
        .then(() => {
          forceRender(v4())
        })
    }
  }, [api, isLoading])
  return (
    <Grid
      container
      mt={0}
      spacing={1}>
      <Grid
        container
        spacing={2}
        p={1}
        item
        xs={12}>
        {expandedPanel !== undefined && (
          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            lg={4}
            xl={3}>
            <EnumInput
              attributeValue={{
                attribute: {
                  Name: 'panel',
                  Title: 'Panel',
                  AttributeType: 'enum',
                  EnumeratedType: {
                    ExternalId: '',
                    GlobalExternalId: '',
                    Values: dashboard.Panels?.map(panel => ({
                      Id: panel.Id,
                      Value: panel.Id,
                      Description: panel.Title
                    })) ?? []
                  },
                  List: false
                },
                value: expandedPanel.Id
              }}
              textFieldProps={{
                size: 'medium',
                variant: 'outlined'
              }}
              readOnly={false}
              disableClearable
              disableSorting
              onChange={attributeValue => setExpandedPanelId(String(attributeValue.value))} />
          </Grid>
        )}
        <Grid
          item
          xs={6}
          sm={6}
          md={6}
          lg={4}
          xl={3}>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onEndDateChange={onEndDateChange}
            onStartDateChange={onStartDateChange}
            startDateLabel='From'
            endDateLabel='To'
            textFieldProps={{
              size: 'medium',
              variant: 'outlined'
            }}
            shortcuts={shortcuts}
            onShortcutSelect={onShortcutSelect} />
        </Grid>
        {dashboard.Variables
          ?.filter(variable => !(variable.Name in (variableValues ?? {})))
          .map(variable => (
            <Grid
              key={variable.Name}
              item
              xs={6}
              sm={6}
              md={6}
              lg={4}
              xl={3}>
              <VariableInput
                variable={variable}
                value={variableInputValues[variable.Name]}
                onChange={value => setVariableInputValues(previousValues => ({
                  ...previousValues,
                  [variable.Name]: value
                }))} />
            </Grid>
          ))}
      </Grid>
      {expandedPanel !== undefined
        ? (
          <Grid
            key={expandedPanel.Id}
            item
            xs={12}>
            <Panel
              dashboard={dashboard}
              panel={expandedPanel}
              startDate={startDate}
              endDate={endDate}
              isExpanded
              isLoading={isLoading}
              variableValues={panelVariableValues}
              onExpand={() => onExpandPanel(expandedPanel)}
              onCollapse={onCollapsePanel} />
          </Grid>
        )
        : dashboard.Panels
          ?.map(panel => (
            <Grid
              key={panel.Id}
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={4}>
              <Panel
                dashboard={dashboard}
                panel={panel}
                startDate={startDate}
                endDate={endDate}
                isExpanded={false}
                isLoading={isLoading}
                variableValues={panelVariableValues}
                onExpand={() => onExpandPanel(panel)}
                onCollapse={onCollapsePanel} />
            </Grid>
          ))}
    </Grid>
  )
}