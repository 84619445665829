import { Check, CloseOutlined, ErrorOutline } from '@mui/icons-material'
import { Box, Button, CircularProgress, Icon, Stack } from '@mui/material'
import { TreeItem } from '@mui/x-tree-view'
import { DomainTypeCell } from 'components/attribute/AttributeCell'
import TooltipIconButton from 'components/utils/TooltipIconButton'
import * as E from 'fp-ts/Either'
import * as t from 'io-ts'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clearJob, clearPendingInterestInJob, registerPendingInterestInJob, updateJobStatus } from 'state/actions/jobs'
import { getJobs } from 'state/reducers'
import { ActionEffect, CompletedJobStatus, ContextDomainTypeNode, ContextTree, CreateItemsSuccessEffectResult, DownloadFromFileStoreSuccessResult, DownloadInstanceSuccessResult, EffectResult, EffectResults, JobDetails, JobStatus } from 'types'
import { DownloadFileDataCodec } from 'utils/codecs'
import { PATH_SEPARATOR } from 'utils/constants'
import { isNullOrUndefined, wait } from 'utils/helpers'
import { useApi } from 'utils/hooks'
import ContextTreeView, { NODE_ID_SEPARATOR } from './ContextTreeView'

const RESULT_COLOUR = {
  Success: 'success',
  Error: 'error'
} as const

const RESULT_ICON = {
  Success: (
    <Icon color={RESULT_COLOUR.Success}>
      <Check />
    </Icon>
  ),
  Error: (
    <Icon color={RESULT_COLOUR.Error}>
      <ErrorOutline />
    </Icon>
  )
}

interface CreateItemsSuccessEffectResultTreeItemProps {
  readonly nodeId: string
  readonly effect: ActionEffect
  readonly effectResult: CreateItemsSuccessEffectResult
}

function CreateItemsSuccessEffectResultTreeItem({
  nodeId,
  effect,
  effectResult
}: CreateItemsSuccessEffectResultTreeItemProps): JSX.Element {
  return (
    <TreeItem
      nodeId={nodeId}
      label={(
        <Box
          display='flex'
          gap={1}>
          {effectResult.Name}
        </Box>
      )}>
      {effectResult.Items.map((createResult, index) => {
        if (effect.Type !== 'CreateItemsActionEffect') {
          return null
        }
        return (
          <TreeItem
            key={index}
            nodeId={[nodeId, index].join(NODE_ID_SEPARATOR)}
            label={(
              <Box
                display='flex'
                gap={1}>
                {RESULT_ICON[createResult.Result]}
                {createResult.Result === 'Success'
                  ? (
                    <DomainTypeCell
                      attributeValue={{
                        attribute: {
                          Name: 'Item',
                          Title: 'Item',
                          AttributeType: 'domainType',
                          AttributeDomainType: effect.DomainType
                        },
                        value: createResult.Item
                      }} />
                  )
                  : createResult.Message}
              </Box>
            )} />
        )
      })}
    </TreeItem>
  )
}

function getInProgressJobStatusText(jobStatus: JobStatus | null): string {
  if (jobStatus === null) {
    return 'Pending...'
  }
  if (!isNullOrUndefined(jobStatus.ProgressMessage)) {
    return jobStatus.ProgressMessage
  }
  if (jobStatus.Status === 'Ready') {
    return 'Pending...'
  }
  return 'Running...'
}

interface CompletedJobResultLabelProps {
  readonly jobStatus: CompletedJobStatus
  readonly effect: ActionEffect
}

function CompletedJobResultLabel({
  jobStatus,
  effect
}: CompletedJobResultLabelProps): JSX.Element {
  const api = useApi()
  const hasDownloadedRef = useRef(false)
  return useMemo(() => {
    if (t.type({ errorText: t.string }).is(jobStatus.Data)
      && jobStatus.Data.errorText) {
      return (
        <Box
          display='flex'
          gap={1}
          alignItems='center'>
          {RESULT_ICON.Error}
          {jobStatus.Data.errorText}
        </Box>
      )
    }
    if (t.type({ errorText: t.array(t.string) }).is(jobStatus.Data)) {
      return (
        <Box
          display='flex'
          gap={1}
          alignItems='center'>
          {RESULT_ICON.Error}
          <Box
            display='flex'
            flexDirection='column'
            gap={0}
            alignItems='left'>
            {jobStatus.Data.errorText.map(s => <span key={s}>{s}</span>)}
          </Box>
        </Box>
      )
    }
    if (t.type({ successText: t.string }).is(jobStatus.Data)) {
      return (
        <Box
          display='flex'
          gap={1}
          alignItems='center'>
          {RESULT_ICON.Success}
          {jobStatus.Data.successText}
        </Box>
      )
    }
    if (t.type({ successText: t.array(t.string) }).is(jobStatus.Data)) {
      return (
        <Box
          display='flex'
          gap={1}
          alignItems='center'>
          {RESULT_ICON.Success}
          <Box
            display='flex'
            flexDirection='column'
            gap={0}
            alignItems='left'>
            {jobStatus.Data.successText.map(s => <span key={s}>{s}</span>)}
          </Box>
        </Box>
      )
    }
    if (effect.Type === 'QueueJobActionEffect'
      && effect.DownloadFile === true
      && DownloadFileDataCodec.is(jobStatus.Data)) {
      return (
        <Box
          display='flex'
          gap={1}
          alignItems='center'>
          {RESULT_ICON.Success}
          <Button
            ref={button => {
              if (!hasDownloadedRef.current) {
                button?.click()
                hasDownloadedRef.current = true
              }
            }}
            size='small'
            variant='text'
            onClick={async () => {
              if (!api.isSignedIn) {
                return
              }
              if (DownloadFileDataCodec.is(jobStatus.Data)) {
                await api.downloadFromCache(jobStatus.Data.fileName)
              }
            }}>
            Download
          </Button>
        </Box>
      )
    }
    return (
      <Box
        display='flex'
        gap={1}
        alignItems='center'>
        {RESULT_ICON.Success}
        Success
      </Box>
    )
  }, [api, effect, jobStatus.Data])
}

interface JobSuccessEffectResultTreeItemProps {
  readonly nodeId: string
  readonly labelPath: string[]
  readonly effect: ActionEffect
  readonly effectResult: { readonly Name: string, readonly Job: JobDetails | Record<string, never> }
}

function JobSuccessEffectResultTreeItem({
  nodeId,
  labelPath,
  effect,
  effectResult
}: JobSuccessEffectResultTreeItemProps): JSX.Element {
  const api = useApi()
  const [pollCounter, setPollCounter] = useState(0)
  const [jobStatus, setJobStatus] = useState<JobStatus | null>(null)
  const dispatch = useDispatch()
  const jobs = useSelector(getJobs)
  useEffect(() => {
    if (effectResult.Job.Id === '' || isNullOrUndefined(effectResult.Job.Id)) {
      return
    }
    dispatch(registerPendingInterestInJob(effectResult.Job.Id, effect, labelPath))
  }, [dispatch, effect, effectResult, labelPath])
  const getJobStatus = useCallback(async () => {
    if (!api.isSignedIn) {
      return
    }
    if (effectResult.Job.Id === '') {
      return
    }
    const response = await api.getJobStatus(effectResult.Job.Id)
    if (E.isLeft(response)) {
      await wait(2000)
      setPollCounter(pollCounter + 1)
      return
    }
    setJobStatus(response.right)
    dispatch(updateJobStatus(response.right))
    if (response.right.Status === 'Completed'
      || response.right.Status === 'Errored') {
      dispatch(clearPendingInterestInJob(effectResult.Job.Id))
      return
    }
    await wait(2000)
    setPollCounter(pollCounter + 1)
  }, [api, dispatch, effectResult.Job.Id, pollCounter])
  useEffect(() => {
    getJobStatus()
  }, [getJobStatus])
  const job = jobs.find(job => job.jobId === effectResult.Job.Id)

  const label = useMemo(() => {
    if (jobStatus?.Status === 'Completed') {
      return (
        <CompletedJobResultLabel
          jobStatus={jobStatus}
          effect={effect} />
      )
    }
    if (effectResult.Job.Id === '') {
      return (
        <Box
          display='flex'
          gap={1}
          alignItems='center'>
          {RESULT_ICON.Error}
          {effectResult.Job.Message}
        </Box>
      )
    }
    if (jobStatus?.Status === 'Errored') {
      return (
        <Box
          display='flex'
          gap={1}
          alignItems='center'>
          {RESULT_ICON.Error}
          {jobStatus.ErrorMessage}
        </Box>
      )
    }
    return (
      <Box
        display='flex'
        gap={1}
        alignItems='center'>
        <Stack
          direction='row'
          alignItems='center'>
          <CircularProgress size={20} />
        </Stack>
        {getInProgressJobStatusText(jobStatus)}
      </Box>
    )
  }, [effect, effectResult, jobStatus])
  return (
    <TreeItem
      nodeId={nodeId}
      label={(
        <Box
          display='flex'
          gap={1}
          alignItems='center'>
          {job !== undefined
            ? [...job.labelPath, job.effect.Name].join(PATH_SEPARATOR)
            : effectResult.Name}
          {job !== undefined
            && job.jobStatus?.Status !== 'Completed'
            && job.jobStatus?.Status !== 'Errored'
            && (
              <>
                <Box flexGrow={1} />
                <TooltipIconButton
                  size='small'
                  tooltipText='Stop tracking this job'
                  icon={<CloseOutlined />}
                  onClick={() => dispatch(clearJob(job.jobId))} />
              </>
            )}
        </Box>
      )}>
      <TreeItem
        nodeId={[nodeId, 'Success'].join(NODE_ID_SEPARATOR)}
        label={label} />
    </TreeItem>
  )
}

interface DownloadFromFileStoreSuccessEffectResultTreeItemProps {
  readonly nodeId: string
  readonly effect: ActionEffect
  readonly effectResult: DownloadFromFileStoreSuccessResult
}

function DownloadFromFileStoreSuccessEffectResultTreeItem({
  nodeId,
  effect,
  effectResult
}: DownloadFromFileStoreSuccessEffectResultTreeItemProps): JSX.Element {
  const api = useApi()
  const hasDownloadedRef = useRef(false)
  return (
    <TreeItem
      nodeId={nodeId}
      label={effectResult.Name}>
      <TreeItem
        nodeId={[nodeId, 'Success'].join(NODE_ID_SEPARATOR)}
        label={(
          <Box
            display='flex'
            gap={1}>
            {RESULT_ICON.Success}
            <Button
              ref={button => {
                if (!hasDownloadedRef.current) {
                  button?.click()
                  hasDownloadedRef.current = true
                }
              }}
              size='small'
              variant='text'
              onClick={async () => {
                if (!api.isSignedIn) {
                  return
                }
                await api.downloadFromInstance(
                  effectResult.DomainTypeName,
                  effectResult.Id,
                  effectResult.FileId,
                  effectResult.FileName
                )
              }}>
              Download
            </Button>
          </Box>
        )} />
    </TreeItem>
  )
}

interface DownloadInstanceSuccessEffectResultTreeItemProps {
  readonly nodeId: string
  readonly effect: ActionEffect
  readonly effectResult: DownloadInstanceSuccessResult
}

function DownloadInstanceSuccessEffectResultTreeItem({
  nodeId,
  effect,
  effectResult
}: DownloadInstanceSuccessEffectResultTreeItemProps): JSX.Element {
  const api = useApi()
  const hasDownloadedRef = useRef(false)
  return (
    <TreeItem
      nodeId={nodeId}
      label={effectResult.Name}>
      <TreeItem
        nodeId={[nodeId, 'Success'].join(NODE_ID_SEPARATOR)}
        label={(
          <Box
            display='flex'
            gap={1}>
            {RESULT_ICON.Success}
            <Button
              ref={button => {
                if (!hasDownloadedRef.current) {
                  button?.click()
                  hasDownloadedRef.current = true
                }
              }}
              size='small'
              variant='text'
              onClick={async () => {
                if (!api.isSignedIn) {
                  return
                }
                await api.downloadInstance(
                  effectResult.DomainTypeName,
                  effectResult.InstanceId
                )
              }}>
              Download
            </Button>
          </Box>
        )} />
    </TreeItem>
  )
}

interface EffectResultTreeItemProps {
  readonly nodeIdPath: string[]
  readonly labelPath: string[]
  readonly effect: ActionEffect | undefined
  readonly effectResult: EffectResult
}

function EffectResultTreeItem({
  nodeIdPath,
  labelPath,
  effect,
  effectResult
}: EffectResultTreeItemProps): JSX.Element {
  const nodeId = [...nodeIdPath, effectResult.Name].join(NODE_ID_SEPARATOR)
  if (effectResult.Result === 'Error') {
    return (
      <TreeItem
        nodeId={nodeId}
        label={effectResult.Name}>
        <TreeItem
          nodeId={[nodeId, 'Error'].join(NODE_ID_SEPARATOR)}
          label={(
            <Box
              display='flex'
              gap={1}>
              {RESULT_ICON.Error}
              {effectResult.Message}
            </Box>
          )} />
      </TreeItem>
    )
  }
  const defaultTreeItem = (
    <TreeItem
      nodeId={nodeId}
      label={effectResult.Name}>
      <TreeItem
        nodeId={[nodeId, 'Success'].join(NODE_ID_SEPARATOR)}
        label={(
          <Box
            display='flex'
            gap={1}>
            {RESULT_ICON.Success}
            Success
          </Box>
        )} />
    </TreeItem>
  )
  if (effect === undefined) {
    return defaultTreeItem
  }
  switch (effectResult.Type) {
    case 'CreateItemsActionEffect': {
      return (
        <CreateItemsSuccessEffectResultTreeItem
          nodeId={nodeId}
          effect={effect}
          effectResult={effectResult} />
      )
    }
    case 'QueueJobActionEffect':
    case 'SendEmailActionEffect':
    case 'SendPushNotificationActionEffect':
    case 'MoveEquipmentActionEffect':
      return (
        <JobSuccessEffectResultTreeItem
          nodeId={nodeId}
          labelPath={labelPath}
          effect={effect}
          effectResult={effectResult} />
      )
    case 'DownloadFromFileStoreActionEffect':
      return (
        <DownloadFromFileStoreSuccessEffectResultTreeItem
          nodeId={nodeId}
          effect={effect}
          effectResult={effectResult} />
      )
    case 'DownloadInstanceActionEffect':
      return (
        <DownloadInstanceSuccessEffectResultTreeItem
          nodeId={nodeId}
          effect={effect}
          effectResult={effectResult} />
      )
    default:
      return defaultTreeItem
  }
}

export interface ActionEffectResults {
  readonly PathEffectResults: ContextTree<EffectResults>
  readonly RequestEffectResults: EffectResult[]
}

interface Props {
  readonly effects: ActionEffect[]
  readonly actionEffectResults: ActionEffectResults
}

export default function ActionEffectResultsView({
  effects,
  actionEffectResults
}: Props): JSX.Element {
  const getAdditionalNodeIds = useCallback((
    node: ContextDomainTypeNode<EffectResults> | null,
    nodeIdPath: string[]
  ) => {
    if (node === null) {
      return actionEffectResults.RequestEffectResults.map(effectResult => effectResult.Name)
    }
    return node.EffectResults?.map(effectResult => [...nodeIdPath, effectResult.Name].join(NODE_ID_SEPARATOR)) ?? []
  }, [actionEffectResults.RequestEffectResults])
  const renderAdditionalNodes = useCallback((
    node: ContextDomainTypeNode<EffectResults> | null,
    nodeIdPath: string[],
    labelPath: string[]
  ) => {
    if (node === null) {
      return actionEffectResults.RequestEffectResults.map(effectResult => {
        const effect = effects.find(effect => effect.Name === effectResult.Name)
        return (
          <EffectResultTreeItem
            key={effectResult.Name}
            effect={effect}
            effectResult={effectResult}
            nodeIdPath={nodeIdPath}
            labelPath={labelPath} />
        )
      })
    }
    return node.EffectResults?.map(effectResult => {
      const effect = effects.find(effect => effect.Name === effectResult.Name)
      return (
        <EffectResultTreeItem
          key={effectResult.Name}
          effect={effect}
          effectResult={effectResult}
          nodeIdPath={nodeIdPath}
          labelPath={labelPath} />
      )
    }) ?? []
  }, [actionEffectResults.RequestEffectResults, effects])
  return (
    <ContextTreeView
      contextTree={actionEffectResults.PathEffectResults}
      getAdditionalNodeIds={getAdditionalNodeIds}
      renderAdditionalNodes={renderAdditionalNodes} />
  )
}