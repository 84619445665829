import { Action, Company, EDIT_DOMAIN_TYPE_OVERRIDER, FETCH_COMPANY_FULFILLED } from 'types'
import { makeOverrideLens } from 'utils/helpers'

export default function company(state: Company | null = null, action: Action): Company | null {
  switch (action.type) {
    case FETCH_COMPANY_FULFILLED:
      return action.payload.company
    case EDIT_DOMAIN_TYPE_OVERRIDER: {
      if (state === null) {
        return state
      }
      if (action.payload.overriderDetails.root !== 'company') {
        return state
      }
      const lens = makeOverrideLens(action.payload.overriderDetails.path, action.payload.domainTypeId)
      return {
        ...state,
        ...lens.modify(previous => ({
          ...previous,
          [action.payload.setting]: action.payload.value
        }))(state) ?? state
      }
    }
    default:
      return state
  }
}

export function getCompany(state: Company | null): Company | null {
  return state
}