import { useContext, useEffect } from 'react'
import EventBusContext, { EventMapping } from 'utils/context/EventBusContext'

export function useEventHandler<Type extends keyof EventMapping>(
  key: Type,
  handler: (event: EventMapping[Type]) => void
): void {
  const eventBus = useContext(EventBusContext)
  useEffect(() => {
    eventBus.on(key, handler)
    return () => {
      eventBus.remove(key, handler)
    }
  }, [eventBus, handler, key])
}