import { PropsWithChildren, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { DomainType } from 'types'
import { SubtypesCacheContext } from 'utils/context'
export default function SubtypesCache({
  children
}: PropsWithChildren<unknown>): JSX.Element {
  const domainTypes = useSelector(getAllDomainTypes)
  const subtypesCache = useMemo<Partial<Record<string, DomainType[]>>>(() => {
    return {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domainTypes])
  return (
    <SubtypesCacheContext.Provider value={subtypesCache}>
      {children}
    </SubtypesCacheContext.Provider>
  )
}