import { CheckBox } from '@mui/icons-material'
import { Badge, Box, ButtonGroup, Checkbox, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Paper } from '@mui/material'
import ActionDialog from 'components/domainType/ActionDialog'
import { ActionButton } from 'components/domainType/DomainTypeButtons'
import DomainTypeIcon from 'components/domainType/DomainTypeIcon'
import DomainTypeTooltip from 'components/domainType/DomainTypeTooltip'
import ScrollBar from 'components/navigation/ScrollBar'
import TextIconButton from 'components/utils/TextIconButton'
import { DateTime } from 'luxon'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes, getUser } from 'state/reducers'
import { AttributeValue, DomainType, Filter, Sort } from 'types'
import { HoverStartEvent, MapMarkerClickEvent } from 'utils/context/EventBusContext'
import { getUniqueId, isNullOrUndefined, makeSortFunction } from 'utils/helpers'
import { ButtonTarget, useDefaultSorts, useEventBus, useEventHandler, useOverriders } from 'utils/hooks'
import ListToolbar from '../ListToolbar'
import { getColumns } from '../TableView'
import { CREATE_ROUTE_ACTION_NAME, TIMELINE_HOVER_EVENT_SOURCE, getTimelineItemInstance } from '../helpers'
import { ItemTimelineItem } from '../types'
import useApplyFiltersSortsAndPage from '../useApplyFiltersSortsAndPage'
import useSortsAndFilters from '../useSortsAndFilters'
import useTimelineItemsActionButton from './useTimelineItemsActionButton'

interface TimelineListItemProps {
  readonly item: ItemTimelineItem
  readonly selectedIndex: number
  onSelectedChange(id: string, selected: boolean): void
}

function TimelineListItem({
  item,
  selectedIndex,
  onSelectedChange
}: TimelineListItemProps): JSX.Element {
  const eventBus = useEventBus()
  const instance = getTimelineItemInstance(item)
  const selected = selectedIndex !== -1
  const domainTypes = useSelector(getAllDomainTypes)
  return (
    <ListItem
      key={item.id}
      className={getUniqueId(domainTypes, item.subtype, instance)}
      disablePadding
      onMouseOver={() => {
        eventBus.dispatch({
          type: 'hoverStart',
          domainType: item.subtype,
          instance,
          source: TIMELINE_HOVER_EVENT_SOURCE
        })
      }}
      onMouseOut={() => {
        eventBus.dispatch({
          type: 'hoverEnd',
          domainType: item.subtype,
          instance,
          source: TIMELINE_HOVER_EVENT_SOURCE
        })
      }}>
      <ListItemButton
        disableGutters
        onClick={() => onSelectedChange(String(item.id), !selected)}>
        <ListItemIcon
          sx={{
            minWidth: 'unset',
            mr: 2
          }}>
          <Checkbox
            checked={selected}
            disableRipple
            onChange={(event, checked) => onSelectedChange(String(item.id), checked)}
            edge='end'
            checkedIcon={(
              <Badge
                badgeContent={selectedIndex + 1}
                color='primary'
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}>
                <CheckBox />
              </Badge>
            )} />
        </ListItemIcon>
        <DomainTypeTooltip
          domainType={item.subtype}
          enableHeadingLink
          instance={getTimelineItemInstance(item)}
          hoverEventSource={TIMELINE_HOVER_EVENT_SOURCE}>
          <ListItemAvatar
            sx={{
              minWidth: 'unset',
              mr: 2
            }}>
            <DomainTypeIcon
              avatar
              domainType={item.subtype} />
          </ListItemAvatar>
        </DomainTypeTooltip>
        <ListItemText
          sx={{ m: 0 }}
          primary={item.title}
          secondary={item.subtitle} />
      </ListItemButton>
    </ListItem>
  )
}

interface Props {
  readonly domainType: DomainType
  readonly itemsDomainType: DomainType
  readonly items: ItemTimelineItem[]
}

export default function TimelineList({
  domainType,
  itemsDomainType,
  items
}: Props): JSX.Element {
  const domainTypes = useSelector(getAllDomainTypes)
  const [panelOpen, setPanelOpen] = useState<string | false>(false)
  const defaultSorts = useDefaultSorts(itemsDomainType)
  const [sorts, setSorts] = useState<Sort[]>(defaultSorts)
  const [filters, setFilters] = useState<Filter[]>([])
  const [filterLinkOperator, setFilterLinkOperator] = useState<'and' | 'or'>('and')
  const {
    sortsRef,
    filtersRef,
    panelKey: sortsAndFiltersPanelKey
  } = useSortsAndFilters(
    panelOpen,
    sorts,
    setSorts,
    filters,
    setFilters,
    filterLinkOperator,
    setFilterLinkOperator
  )
  const overriders = useOverriders()
  const user = useSelector(getUser)
  const columns = useMemo(() => getColumns(
    domainTypes,
    [domainType],
    overriders,
    [],
    false,
    user
  ), [domainType, domainTypes, overriders, user])
  const [searchText, setSearchText] = useState('')
  const {
    applyFilters,
    applySorts
  } = useApplyFiltersSortsAndPage(
    domainType,
    filters,
    filterLinkOperator,
    searchText,
    sorts,
    1,
    25
  )
  const filteredSortedItems = useMemo(() => applySorts(
    applyFilters(
      items.filter(item => isNullOrUndefined(item.routeId)),
      getTimelineItemInstance
    ),
    getTimelineItemInstance
  ), [applyFilters, applySorts, items])
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  useEffect(() => {
    setSelectedIds(selectedIds => selectedIds
      .filter(id => filteredSortedItems.find(item => String(item.id) === id) !== undefined))
  }, [filteredSortedItems])
  const selectedItems = useMemo(() => {
    return filteredSortedItems
      .filter(items => selectedIds.includes(String(items.id)))
      .sort(makeSortFunction(selectedIds, items => String(items.id)))
  }, [filteredSortedItems, selectedIds])
  const parameterValues = useMemo<AttributeValue[]>(() => [
    {
      attribute: {
        Name: 'Start Time',
        Title: 'Start Time',
        AttributeType: 'dateTime'
      },
      value: DateTime.fromMillis(Math.min(...selectedItems.map(item => item.start_time))).toISO()
    }
  ], [selectedItems])
  const createRouteActionButton = useTimelineItemsActionButton(
    domainType,
    itemsDomainType,
    CREATE_ROUTE_ACTION_NAME,
    selectedItems,
    parameterValues
  )
  const onSelectedChange = useCallback((id: string, selected: boolean) => {
    setSelectedIds(current => {
      const currentWithoutSelected = current.filter(currentId => currentId !== id)
      if (selected) {
        return [...currentWithoutSelected, id]
      }
      return currentWithoutSelected
    })
  }, [])
  const [actionDialogOpen, setActionDialogOpen] = useState(false)
  const onCloseActionDialog = useCallback(() => {
    setActionDialogOpen(false)
  }, [])
  const target = useMemo<ButtonTarget>(() => {
    return {
      type: 'instances',
      instances: selectedItems.map(getTimelineItemInstance)
    }
  }, [selectedItems])
  const onHoverStart = useCallback((event: HoverStartEvent) => {
    if (event.source === TIMELINE_HOVER_EVENT_SOURCE) {
      return
    }
    const id = getUniqueId(domainTypes, event.domainType, event.instance)
    const element = document.querySelector(`.timeline-list .${id}`)
    if (element !== null) {
      element.scrollIntoView({ block: 'nearest' })
    }
  }, [domainTypes])
  useEventHandler('hoverStart', onHoverStart)
  const onMapMarkerClick = useCallback((event: MapMarkerClickEvent) => {
    const targetId = getUniqueId(domainTypes, event.domainType, event.instance)
    const id = items
      .find(item => getUniqueId(domainTypes, item.subtype, getTimelineItemInstance(item)) === targetId)?.id
    if (id !== undefined
      && filteredSortedItems.find(item => item.id === id) !== undefined) {
      onSelectedChange(String(id), !selectedIds.includes(String(id)))
    }
  }, [domainTypes, filteredSortedItems, items, onSelectedChange, selectedIds])
  useEventHandler('mapMarkerClick', onMapMarkerClick)
  const eventBus = useEventBus()
  useEffect(() => {
    eventBus.dispatch({
      type: 'timelineListSelectionChange',
      selectedIds: selectedItems
        .map(item => getUniqueId(domainTypes, item.subtype, getTimelineItemInstance(item)))
    })
  }, [domainTypes, eventBus, selectedItems])
  return (
    <>
      {createRouteActionButton !== null && (
        <ActionDialog
          open={actionDialogOpen}
          actionButton={createRouteActionButton}
          target={{
            type: 'instances',
            instances: selectedItems.map(getTimelineItemInstance)
          }}
          onClose={onCloseActionDialog}
          onPerform={onCloseActionDialog} />
      )}
      <Box
        className='timeline-list'>
        <Paper className='timeline-list-paper'>
          <ListToolbar
            domainType={domainType}
            panelOpen={panelOpen}
            setPanelOpen={setPanelOpen}
            filterLinkOperator={filterLinkOperator}
            filters={filters}
            onFiltersChange={setFilters}
            sorts={sorts}
            onSortsChange={setSorts}
            columns={columns}
            searchText={searchText}
            onSearch={setSearchText}
            sortsRef={sortsRef}
            filtersRef={filtersRef}
            sortsAndFiltersPanelKey={sortsAndFiltersPanelKey}>
            {createRouteActionButton !== null && (
              <ButtonGroup>
                <ActionButton
                  button={createRouteActionButton}
                  target={target}
                  Component={props => (
                    <TextIconButton
                      iconOnly
                      {...props} />
                  )} />
              </ButtonGroup>
            )}
          </ListToolbar>
          <Box
            className='timeline-list-content'>
            <ScrollBar>
              {filteredSortedItems.length === 0
                ? (
                  <List
                    dense
                    disablePadding>
                    <ListItem>
                      <ListItemText primary='No rows' />
                    </ListItem>
                  </List>
                )
                : (
                  <List
                    dense
                    disablePadding>
                    {filteredSortedItems
                      .map(item => (
                        <TimelineListItem
                          key={item.id}
                          item={item}
                          selectedIndex={selectedIds.indexOf(String(item.id))}
                          onSelectedChange={onSelectedChange} />
                      ))}
                  </List>
                )}
            </ScrollBar>
          </Box>
        </Paper>
      </Box>
    </>
  )
}