import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material'
import DomainTypeHeading from 'components/domainType/DomainTypeHeading'
import * as E from 'fp-ts/Either'
import { useCallback, useContext, useMemo, useState } from 'react'
import { ApiError } from 'types'
import { DomainTypeContext } from 'utils/context'
import { CustomButtonProps, useApi } from 'utils/hooks'
import DialogAlert from './DialogAlert'

export default function RetryUpdatesButton({
  button,
  target,
  Component,
  onComplete
}: CustomButtonProps): JSX.Element | null {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [isRetrying, setIsRetrying] = useState(false)
  const context = useContext(DomainTypeContext)
  const instances = useMemo(() => {
    return target.type === 'instances'
      ? target.instances
      : []
  }, [target])
  const instance = useMemo(() => instances.length === 1 ? instances[0] : undefined, [instances])
  const api = useApi()
  const onCloseDialog = useCallback(() => {
    setDialogOpen(false)
    setApiError(null)
    if (onComplete !== undefined) {
      onComplete()
    }
  }, [setDialogOpen, onComplete])
  const [apiError, setApiError] = useState<ApiError | null>(null)
  const onRetry = useCallback(async () => {
    if (!api.isSignedIn) {
      return
    }
    setIsRetrying(true)
    const response = await api.retryUpdates(
      String(instance?.['Id'])
    )
    if (E.isRight(response)) {
      onCloseDialog()
      context.onInvalidate?.()
    } else {
      setApiError(response.left)
    }
    setIsRetrying(false)
  }, [api, context, instance, onCloseDialog])
  return (
    <>
      <Component
        text={button.name}
        icon={button.icon}
        onClick={() => setDialogOpen(true)}
        disabled={button.disabled || instance === undefined} />
      <Dialog
        fullWidth
        maxWidth='md'
        open={dialogOpen}
        onKeyDown={event => event.stopPropagation()}>
        <DialogTitle>
          <DomainTypeHeading
            domainType={button.domainType}
            count={instances.length}
            plural={instances.length > 1}
            instance={instance}
            isLoading={false}
            title='Retry Updates:' />
        </DialogTitle>
        <DialogAlert
          isLoading={isRetrying}
          errorCode={apiError?.errorCode ?? ''}
          title='Failed to retry updates'
          hideEmptyErrorCode />
        <DialogActions>
          <Button
            disabled={isRetrying}
            variant='text'
            onClick={onCloseDialog}>
            Cancel
          </Button>
          <LoadingButton
            disabled={isRetrying}
            loading={isRetrying}
            onClick={onRetry}>
            Retry Updates
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}