import { TextField, TextFieldProps } from '@mui/material'
import { AttributeValue } from 'types'
import { DEFAULT_ATTRIBUTE_INPUT_SIZE, DEFAULT_ATTRIBUTE_INPUT_VARIANT } from 'utils/constants'

interface Props {
  readonly attributeValue: AttributeValue
  readonly textFieldProps?: Partial<TextFieldProps>
}

export default function NotSupportedInput({
  attributeValue,
  textFieldProps
}: Props): JSX.Element | null {
  return (
    <TextField
      label={attributeValue.attribute.Title}
      id={attributeValue.attribute.Name}
      variant={DEFAULT_ATTRIBUTE_INPUT_VARIANT}
      fullWidth
      size={DEFAULT_ATTRIBUTE_INPUT_SIZE}
      required={attributeValue.attribute.Required ?? false}
      error
      helperText={`Attributes of type ${attributeValue.attribute.AttributeType}${attributeValue.attribute.List === true ? '[]' : ''} are not yet supported by this form`}
      {...textFieldProps}
      inputProps={{
        ...textFieldProps?.inputProps,
        readOnly: true
      }} />
  )
}
