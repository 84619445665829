
import { TextFieldProps } from '@mui/material'
import { useCallback } from 'react'
import { DomainType, DomainTypeInstance, ListDomainTypeAttributeValue, PathError } from 'types'
import MultiSelectAutocompleteInput from '../MultiSelectAutocompleteInput'

interface ListApiDomainTypeProps {
  readonly domainTypes: Partial<Record<string, DomainType>>
  readonly domainType: DomainType
  readonly attributeValue: ListDomainTypeAttributeValue
  readonly disabled?: boolean
  readonly readOnly: boolean
  readonly pathError?: PathError
  readonly textFieldProps?: Partial<TextFieldProps>
  onChange?(attributeValue: ListDomainTypeAttributeValue): void
}

export default function ListApiDomainTypeInput({
  domainTypes,
  domainType,
  attributeValue,
  disabled,
  readOnly,
  pathError,
  textFieldProps,
  onChange }: ListApiDomainTypeProps): JSX.Element | null {
  const handleChange = useCallback((value: DomainTypeInstance[]) => {
    if (onChange === undefined) {
      return
    }
    onChange({
      attribute: attributeValue.attribute,
      value
    })
  }, [attributeValue.attribute, onChange])

  return (
    <MultiSelectAutocompleteInput
      domainTypes={domainTypes}
      domainType={domainType}
      attributeValue={attributeValue}
      disabled={disabled}
      readOnly={readOnly}
      pathError={pathError}
      textFieldProps={textFieldProps}
      onChange={handleChange} />
  )
}