import { LoadingButton } from '@mui/lab'
import { Alert, AlertTitle, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import DomainTypeHeading from 'components/domainType/DomainTypeHeading'
import DialogAlert from 'components/utils/DialogAlert'
import { useCallback, useMemo, useState } from 'react'
import { ActionResponse, DomainType } from 'types'
import { isNullOrUndefined } from 'utils/helpers'
import { ButtonTarget, useDelete } from 'utils/hooks'
import ActionEffectResultsView, { ActionEffectResults } from './ActionEffectResultsView'
import { useDispatch } from 'react-redux'
import { confirmInterestInJobs } from 'state/actions/jobs'

interface Props {
  readonly open: boolean
  readonly target: ButtonTarget
  readonly domainType: DomainType
  onClose(): void
  onDeleteSuccess(): void
}

export default function DeleteDialog({
  open,
  target,
  domainType,
  onClose,
  onDeleteSuccess
}: Props): JSX.Element {
  const dispatch = useDispatch()
  const onCloseActionEffectResultsDialog = useCallback(() => {
    dispatch(confirmInterestInJobs())
    onDeleteSuccess()
  }, [dispatch, onDeleteSuccess])
  const [actionResponse, setActionResponse] = useState<ActionResponse | null>(null)
  const onSuccess = useCallback((actionResponse?: ActionResponse) => {
    if (actionResponse === undefined) {
      onDeleteSuccess()
    } else {
      setActionResponse(actionResponse)
    }
  }, [onDeleteSuccess])
  const actionEffectResults = useMemo((): ActionEffectResults | null => {
    if (actionResponse === null) {
      return null
    }
    return {
      RequestEffectResults: actionResponse.EffectResults,
      PathEffectResults: []
    }
  }, [actionResponse])
  const {
    isDeleting,
    errorCode,
    onDelete
  } = useDelete(domainType, target, onSuccess)
  const singleInstance = useMemo(() => {
    if (target.type === 'query' || target.type === 'none') {
      return undefined
    }
    return target.instances.length === 1
      ? target.instances[0]
      : undefined
  }, [target])
  const count = target.type === 'instances'
    ? target.instances.length
    : target.type === 'query'
      ? target.total
      : 0
  return (
    <Dialog
      fullWidth
      maxWidth='md'
      open={open}
      onKeyDown={event => event.stopPropagation()}>
      <DialogTitle>
        <DomainTypeHeading
          domainType={domainType}
          count={count}
          plural={count > 1}
          instance={singleInstance}
          isLoading={false}
          title='Delete:' />
      </DialogTitle>
      {actionEffectResults === null
        ? (
          <>
            <DialogAlert
              isLoading={isDeleting}
              errorCode={errorCode}
              hideEmptyErrorCode />
            <DialogActions>
              <Button
                disabled={isDeleting}
                variant='text'
                onClick={onClose}>
                Cancel
              </Button>
              <LoadingButton
                disabled={isDeleting}
                loading={isDeleting}
                onClick={onDelete}>
                Delete
              </LoadingButton>
            </DialogActions>
          </>
        )
        : (
          <>
            <DialogContent>
              <Alert severity='success'>
                <AlertTitle>
                  All action effects triggered successfully
                </AlertTitle>
                {!isNullOrUndefined(actionEffectResults) && (
                  <ActionEffectResultsView
                    effects={[
                      {
                        Name: `Delete ${domainType.PluralTitle}`,
                        Type: 'QueueJobActionEffect',
                        Target: 'request',
                        DownloadFile: false
                      }
                    ]}
                    actionEffectResults={actionEffectResults} />
                )}
              </Alert>
            </DialogContent>
            <DialogActions>
              <Button
                variant='text'
                onClick={onCloseActionEffectResultsDialog}>
                Close
              </Button>
            </DialogActions>
          </>
        )}
    </Dialog>
  )
}