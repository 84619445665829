import { CalendarTodayOutlined, CalendarViewDayOutlined, CalendarViewMonthOutlined, CalendarViewWeekOutlined, GridViewOutlined, NavigateBeforeOutlined, NavigateNextOutlined, SettingsOutlined, TableViewOutlined, TodayOutlined, ViewTimelineOutlined } from '@mui/icons-material'
import { Box, Button, ButtonGroup, Divider, Icon, Paper, Popover, PopoverProps, Stack, Tooltip, Typography, buttonClasses } from '@mui/material'
import { GridColumnIcon, GridColumns } from '@mui/x-data-grid'
import DomainTypeSplitButton from 'components/domainType/DomainTypeSplitButton'
import TooltipIconButton from 'components/utils/TooltipIconButton'
import { DateTime, Interval } from 'luxon'
import { MutableRefObject, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { DomainType, Filter, Sort } from 'types'
import { dateToString } from 'utils/helpers'
import { ButtonTarget } from 'utils/hooks'
import CalendarTimelineSettingsPanel from './CalendarTimelineSettingsPanel'
import CalendarViewPanel from './CalendarViewPanel'
import ColumnsPanel from './ColumnsPanel'
import FindDatePickerPanel from './DatePickerPanel'
import FiltersAndSorts from './FiltersAndSorts'
import FiltersPanel from './FiltersPanel'
import FindViewPanel from './FindViewPanel'
import SearchInput from './SearchInput'
import SortPanel from './SortPanel'
import { CalendarProps, FindPageView } from './types'

interface Props {
  domainType: DomainType | null
  panelOpen: string | false
  setPanelOpen(value: string | false): void
  selectedColumn: string | undefined
  setSelectedColumn(value: string | undefined): void
  filterLinkOperator: 'and' | 'or'
  filtersRef: MutableRefObject<{
    filterLinkOperator: 'and' | 'or',
    filters: Filter[]
  }>
  filters: Filter[] | undefined
  sorts: Sort[] | undefined
  sortsRef: MutableRefObject<{
    sorts: Sort[]
  }>
  columns: GridColumns
  setColumnVisibility(field: string, isVisible: boolean): void
  selectionTarget: ButtonTarget
  isExporting: boolean
  onClickExport?(columns: string[]): void
  searchText: string
  autoFocusSearchInput?: boolean
  onSearch(searchText: string): void
  onSearchTextChange?(searchText: string): void
  onFiltersChange?(filters: Filter[]): void
  onSortsChange?(sorts: Sort[]): void
  view: FindPageView
  onViewChange(view: FindPageView): void
  calendarProps: CalendarProps
  sortsAndFiltersPanelKey: string
}

function incrementDate(
  date: Date,
  view: CalendarProps['view'],
  direction: 'forwards' | 'backwards'
): Date {
  const dateTime = DateTime.fromJSDate(date)
  const operation = direction === 'forwards'
    ? 'plus'
    : 'minus'
  switch (view) {
    case 'day':
      return dateTime[operation]({ days: 1 }).toJSDate()
    case 'week':
      return dateTime[operation]({ weeks: 1 }).toJSDate()
    case 'month':
      return dateTime[operation]({ months: 1 }).toJSDate()
  }
}

function formatDate(
  date: Date,
  view: CalendarProps['view']
): string | null {
  const dateTime = DateTime.fromJSDate(date)
  switch (view) {
    case 'day':
      return dateToString(dateTime.toUTC().toISO())
    case 'week':
      return Interval
        .fromDateTimes(dateTime.startOf('week'), dateTime.endOf('week'))
        .toLocaleString({
          month: 'short',
          year: 'numeric'
        })
    case 'month':
      return DateTime.fromJSDate(date).toLocaleString({
        month: 'short',
        year: 'numeric'
      })
  }
}

const viewIcons = {
  table: <TableViewOutlined />,
  cards: <GridViewOutlined />,
  calendar: <CalendarTodayOutlined />,
  timeline: <ViewTimelineOutlined />
}

const viewTooltipText = {
  table: 'Table View',
  cards: 'Cards View',
  calendar: 'Calendar View',
  timeline: 'Timeline View'
}

const calendarViewIcons = {
  day: <CalendarViewDayOutlined />,
  week: <CalendarViewWeekOutlined />,
  month: <CalendarViewMonthOutlined />
}

const calendarViewTooltipText = {
  day: 'Day View',
  week: 'Week View',
  month: 'Month View'
}

export default memo(function FindToolbar({
  columns,
  panelOpen,
  setPanelOpen,
  filterLinkOperator,
  filters,
  sorts,
  sortsRef,
  isExporting,
  onClickExport,
  domainType,
  selectionTarget,
  searchText,
  autoFocusSearchInput,
  onSearch,
  onSearchTextChange,
  onFiltersChange,
  onSortsChange,
  setColumnVisibility,
  selectedColumn,
  setSelectedColumn,
  filtersRef,
  view,
  onViewChange,
  calendarProps,
  sortsAndFiltersPanelKey
}: Props): JSX.Element {
  const rootRef = useRef<HTMLDivElement | null>(null)
  const [els, setEls] = useState<[HTMLDivElement, HTMLButtonElement, HTMLButtonElement] | null>(null)
  const [rootEl, filtersButtonEl, sortButtonEl] = els ?? [null, null, null]
  const filtersButtonRef = useRef<HTMLButtonElement | null>(null)
  const sortButtonRef = useRef<HTMLButtonElement | null>(null)
  useEffect(() => {
    if (els !== null) {
      return
    }
    const rootElement = rootRef.current
    const filtersButtonElement = filtersButtonRef.current
    const sortButtonElement = sortButtonRef.current
    if (rootElement !== null
      && filtersButtonElement !== null
      && sortButtonElement !== null) {
      setEls([rootElement, filtersButtonElement, sortButtonElement])
    }
  }, [els, panelOpen])
  const anchorEl = useMemo(() => {
    return {
      filters: filtersButtonEl,
      sorts: sortButtonEl
    }[String(panelOpen)] ?? rootEl
  }, [filtersButtonEl, panelOpen, rootEl, sortButtonEl])
  const [anchorOrigin, transformOrigin] = useMemo((): [PopoverProps['anchorOrigin'], PopoverProps['transformOrigin']] => {
    const origin = (vertical: 'top' | 'bottom', horizontal: 'left' | 'right') => ({
      vertical,
      horizontal
    })
    const panelPlacement: Partial<Record<string, [PopoverProps['anchorOrigin'], PopoverProps['transformOrigin']]>> = {
      view: [origin('bottom', 'right'), origin('top', 'right')],
      columns: [origin('bottom', 'right'), origin('top', 'right')],
      filters: [origin('bottom', 'left'), origin('top', 'left')],
      sorts: [origin('bottom', 'left'), origin('top', 'left')],
      calendarView: [origin('bottom', 'right'), origin('top', 'right')],
      datePicker: [origin('bottom', 'left'), origin('top', 'left')],
      calendarTimelineSettings: [origin('bottom', 'right'), origin('top', 'right')]
    }
    return panelPlacement[String(panelOpen)] ?? [origin('bottom', 'left'), origin('top', 'left')]
  }, [panelOpen])
  const popoverMarginTop = useMemo(() => {
    if (panelOpen !== 'filters' && panelOpen !== 'sorts') {
      return undefined
    }
    return '19px'
  }, [panelOpen])
  const handleClickAway = useCallback(() => {
    setPanelOpen(false)
    setSelectedColumn(undefined)
  }, [setPanelOpen, setSelectedColumn])
  const on = useMemo(() => {
    const isTargetingInstances = selectionTarget.type === 'query'
      || (selectionTarget.type === 'instances' && selectionTarget.instances.length > 0)
    return isTargetingInstances
      ? 'TableRow'
      : 'TableToolbar'
  }, [selectionTarget])
  const additionalButtons = useMemo(() => onClickExport !== undefined
    ? [
      {
        text: 'Export Search Results',
        icon: isExporting
          ? 'downloading'
          : 'save_alt_rounded',
        disabled: isExporting,
        onClick: () => {
          onClickExport(columns
            .filter(column => column.type !== 'actions')
            .filter(column => !(column.hide ?? false))
            .map(column => column.field))
        }
      }
    ]
    : [], [columns, isExporting, onClickExport])
  const panel = useMemo(() => {
    return (
      <>
        {panelOpen === 'view' && (
          <FindViewPanel
            domainType={domainType}
            view={view}
            onViewChange={onViewChange}
            onClose={handleClickAway} />
        )}
        {panelOpen === 'columns' && (
          <ColumnsPanel
            columns={columns}
            domainType={domainType}
            setColumnVisibility={setColumnVisibility} />
        )}
        {panelOpen === 'sorts' && (
          <SortPanel
            key={sortsAndFiltersPanelKey}
            columns={columns}
            onClose={handleClickAway}
            sortsRef={sortsRef} />
        )}
        {panelOpen === 'filters' && (
          <FiltersPanel
            key={sortsAndFiltersPanelKey}
            columns={columns}
            domainType={domainType}
            onClose={handleClickAway}
            selectedColumn={selectedColumn}
            filtersRef={filtersRef} />
        )}
        {panelOpen === 'calendarView' && (
          <CalendarViewPanel
            domainType={domainType}
            view={calendarProps.view}
            onViewChange={calendarProps.onViewChange}
            onClose={handleClickAway} />
        )}
        {panelOpen === 'datePicker' && (
          <FindDatePickerPanel
            view={calendarProps.view}
            date={calendarProps.date}
            onDateChange={calendarProps.onDateChange}
            onClose={handleClickAway} />
        )}
        {panelOpen === 'calendarTimelineSettings' && domainType !== null && (
          <CalendarTimelineSettingsPanel
            domainType={domainType}
            view={view}
            onClose={handleClickAway} />
        )}
      </>
    )
  }, [panelOpen, domainType, view, onViewChange, handleClickAway, columns, setColumnVisibility, sortsAndFiltersPanelKey, sortsRef, selectedColumn, filtersRef, calendarProps.view, calendarProps.onViewChange, calendarProps.date, calendarProps.onDateChange])
  return (
    <>
      <Stack
        ref={rootRef}
        direction='row'
        gap={1}
        alignItems='center'
        pb={2}
        flexWrap='nowrap'>
        {domainType !== null && (
          <DomainTypeSplitButton
            key={on}
            domainType={domainType}
            on={on}
            target={selectionTarget}>
            <Divider
              orientation='vertical'
              variant='middle'
              sx={{
                ml: 1,
                mr: 1
              }}
              flexItem />
          </DomainTypeSplitButton>
        )}
        {['calendar', 'timeline'].includes(view) && (
          <>
            <ButtonGroup
              variant='contained'
              color='primary'>
              <Tooltip title='Jump To Today'>
                <Button
                  sx={{
                    pl: 0,
                    pr: 0,
                    [`& .${buttonClasses.startIcon}`]: {
                      m: 0
                    }
                  }}
                  startIcon={(
                    <TodayOutlined />
                  )}
                  onClick={() => {
                    calendarProps.onDateChange(DateTime.now().toJSDate())
                  }} />
              </Tooltip>
              <Tooltip title={`Previous ${calendarProps.view[0]?.toUpperCase()}${calendarProps.view.slice(1)}`}>
                <Button
                  sx={{
                    pl: 0,
                    pr: 0,
                    [`& .${buttonClasses.startIcon}`]: {
                      m: 0
                    }
                  }}
                  startIcon={(
                    <NavigateBeforeOutlined />
                  )}
                  onClick={() => {
                    calendarProps.onDateChange(
                      incrementDate(calendarProps.date, calendarProps.view, 'backwards')
                    )
                  }} />
              </Tooltip>
              <Tooltip title={`Next ${calendarProps.view[0]?.toUpperCase()}${calendarProps.view.slice(1)}`}>
                <Button
                  sx={{
                    pl: 0,
                    pr: 0,
                    [`& .${buttonClasses.startIcon}`]: {
                      m: 0
                    }
                  }}
                  startIcon={(
                    <NavigateNextOutlined />
                  )}
                  onClick={() => {
                    calendarProps.onDateChange(
                      incrementDate(calendarProps.date, calendarProps.view, 'forwards')
                    )
                  }} />
              </Tooltip>
              <Button
                sx={{
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  whiteSpace: 'nowrap'
                }}
                onClick={event => {
                  event.preventDefault()
                  setPanelOpen(panelOpen === 'datePicker' ? false : 'datePicker')
                }}>
                <Typography
                  variant='button'>
                  {formatDate(calendarProps.date, calendarProps.view)}
                </Typography>
              </Button>
            </ButtonGroup>
            <Divider
              orientation='vertical'
              variant='middle'
              flexItem
              sx={{
                ml: 1,
                mr: 1
              }} />
          </>
        )}
        {domainType !== null && (
          <>
            <SearchInput
              domainType={domainType}
              searchText={searchText}
              autoFocus={autoFocusSearchInput}
              columns={columns}
              filters={filters}
              onSearch={onSearch}
              onSearchTextChange={onSearchTextChange}
              onFiltersChange={onFiltersChange} />
            <FiltersAndSorts
              domainType={domainType}
              filterLinkOperator={filterLinkOperator}
              filters={filters}
              sorts={sorts}
              panelOpen={panelOpen}
              onFiltersChange={onFiltersChange}
              onSortsChange={onSortsChange}
              setPanelOpen={setPanelOpen}
              filtersButtonRef={filtersButtonRef}
              sortButtonRef={sortButtonRef} />
          </>
        )}
        <Box flexGrow={1} />
        {additionalButtons.length > 0 && (
          <>
            <ButtonGroup variant='text'>
              {additionalButtons.map(button => (
                <TooltipIconButton
                  key={button.text}
                  icon={(
                    <Icon>
                      {button.icon}
                    </Icon>
                  )}
                  tooltipText={button.text}
                  color='primary'
                  onClick={button.onClick}
                  disabled={button.disabled} />
              ))}
            </ButtonGroup>
            <Divider
              orientation='vertical'
              variant='middle'
              flexItem />
          </>
        )}
        <ButtonGroup variant='text'>
          <TooltipIconButton
            icon={viewIcons[view]}
            tooltipText={viewTooltipText[view]}
            color='primary'
            onClick={event => {
              event.preventDefault()
              setPanelOpen(panelOpen === 'view' ? false : 'view')
            }} />
          {['table', 'cards'].includes(view)
            ? (
              <TooltipIconButton
                icon={<GridColumnIcon />}
                tooltipText='Columns'
                color='primary'
                onClick={event => {
                  event.preventDefault()
                  setPanelOpen(panelOpen === 'columns' ? false : 'columns')
                }} />
            )
            : (
              <>
                <TooltipIconButton
                  icon={calendarViewIcons[calendarProps.view]}
                  tooltipText={calendarViewTooltipText[calendarProps.view]}
                  color='primary'
                  onClick={event => {
                    event.preventDefault()
                    setPanelOpen(panelOpen === 'calendarView' ? false : 'calendarView')
                  }} />
                <TooltipIconButton
                  icon={<SettingsOutlined />}
                  tooltipText='Settings'
                  color='primary'
                  onClick={event => {
                    event.preventDefault()
                    setPanelOpen(panelOpen === 'calendarTimelineSettings' ? false : 'calendarTimelineSettings')
                  }} />
              </>
            )}
        </ButtonGroup>
      </Stack>
      <Popover
        key={String(panelOpen)}
        onClose={handleClickAway}
        open={panelOpen !== false}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        onResize={undefined}
        onResizeCapture={undefined}
        sx={{
          mt: popoverMarginTop,
          zIndex: 1300
        }}>
        <Paper
          elevation={8}
          sx={{
            width: '100%',
            minWidth: '300px',
            maxHeight: '450px'
          }}>
          {panel}
        </Paper>
      </Popover>
    </>
  )
})
