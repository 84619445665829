import * as E from 'fp-ts/Either'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { ActionResponse, DomainType, DomainTypeInstance } from 'types'
import { getDomainTypeSetting, getRootDomainType } from 'utils/helpers'
import { useApi } from './useApi'
import { ButtonTarget, InstancesButtonTarget, QueryButtonTarget } from './useButtons'
import { useEditListAttribute } from './useEditListAttribute'

interface UseDeleteOutput {
  isDeleting: boolean
  errorCode: string
  onDelete(): void
}

export function useDelete(
  instanceDomainType: DomainType,
  target: ButtonTarget,
  onDeleteSuccess: (actionResponse?: ActionResponse) => void
): UseDeleteOutput {
  const domainTypes = useSelector(getAllDomainTypes)
  const rootDomainType = getRootDomainType(domainTypes, instanceDomainType)
  const api = useApi()
  const [isDeleting, setIsDeleting] = useState(false)
  const [errorCode, setErrorCode] = useState('')
  const isApiDomainType = getDomainTypeSetting(domainTypes, instanceDomainType, 'Api') ?? false
  const identifier = getDomainTypeSetting(domainTypes, instanceDomainType, 'Identifier') ?? 'Id'
  const onEditListAttributeSuccess = useCallback(() => onDeleteSuccess(), [onDeleteSuccess])
  const {
    isEditing: isEditingListAttribute,
    onEditListAttribute
  } = useEditListAttribute(instanceDomainType, onEditListAttributeSuccess)
  const onDelete = useCallback(() => {
    if (!isApiDomainType) {
      if (target.type === 'query' || target.type === 'none') {
        return
      }
      onEditListAttribute(target.instances.map(original => ({
        original,
        edited: {
          ...original,
          _delete: true
        }
      })))
      return
    }
    setErrorCode('')
    async function performDeleteInstances(target: InstancesButtonTarget) {
      if (rootDomainType === null || !api.isSignedIn) {
        return
      }
      setIsDeleting(true)
      const successfullyDeleted: DomainTypeInstance[] = []
      let errorCode: string | undefined = undefined
      for (const instance of target.instances) {
        const response = await api.delete(
          rootDomainType.Name,
          String(instance[identifier])
        )
        if (E.isRight(response)) {
          successfullyDeleted.push(instance)
        } else {
          errorCode = response.left.errorCode
        }
      }

      if (successfullyDeleted.length > 0) {
        onDeleteSuccess()
      } else if (errorCode !== undefined) {
        setErrorCode(errorCode)
      }
      setIsDeleting(false)
    }
    async function performDeleteByQuery(target: QueryButtonTarget) {
      if (rootDomainType === null || !api.isSignedIn) {
        return
      }
      setIsDeleting(true)
      const response = await api.deleteByQuery(
        rootDomainType.Name,
        instanceDomainType,
        target.query,
        target.total
      )
      if (E.isRight(response)) {
        onDeleteSuccess(response.right)
      } else {
        setErrorCode(response.left.errorCode)
      }
      setIsDeleting(false)
    }

    if (target.type === 'none') {
      return
    }

    if (target.type === 'instances') {
      performDeleteInstances(target)
    } else {
      performDeleteByQuery(target)
    }
  }, [api, identifier, instanceDomainType, isApiDomainType, onDeleteSuccess, onEditListAttribute, rootDomainType, target])
  return {
    isDeleting: isDeleting || isEditingListAttribute,
    errorCode,
    onDelete
  }
}