import ChangeMyPasswordButton from 'components/utils/ChangeMyPasswordButton'
import ChangePasswordButton from 'components/utils/ChangePasswordButton'
import RetryUpdatesButton from 'components/utils/RetryUpdatesButton'
import * as E from 'fp-ts/Either'
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'
import { identity } from 'io-ts'
import { createContext } from 'react'
import { BoolAttribute, DomainType, DomainTypeAttribute, DomainTypeInstance, NonListAttribute, NumberAttribute, RefAttribute, User } from 'types'
import { AttributeTypeCodecs, PersonCodec } from 'utils/codecs'
import { FilterContext } from 'utils/filters'
import { AbsoluteDateRangeFilterValueCodec, AbsoluteDateTimeRangeFilterValueCodec, RelativeDateRangeCodec, RelativeDateTimeRangeCodec } from 'utils/filters/codecs'
import { canEditInstancesAttributes, dateTimeToString, dateToString, getDomainType, getDomainTypeButtons, getDomainTypeExternalId, getHeading, getParentDomainTypes, getSubtype, getValue, isNullOrUndefined } from 'utils/helpers'
import { ButtonTarget, CustomButton, isDisabled } from 'utils/hooks'

interface DomainTypeSettingsContext {
  disableRelationship?(
    domainTypes: Partial<Record<string, DomainType>>,
    attributeChain: (DomainTypeAttribute | RefAttribute)[],
    index: number
  ): boolean
  readonly nullSystemCompany?: true
  getHeading?(
    settingsContext: SettingsContext,
    domainTypes: Partial<Record<string, DomainType>>,
    domainType: DomainType,
    instance: DomainTypeInstance
  ): string
  customButtons?(
    domainTypes: Partial<Record<string, DomainType>>,
    domainType: DomainType,
    filterContext: FilterContext,
    subtypesCache: Partial<Record<string, DomainType[]>>,
    target: ButtonTarget,
    user: User | null
  ): CustomButton[]
  readonly listInputIncludeAll?: true
  readonly enableEditNullCompany?: true
}

export interface SettingsContext {
  readonly global: DomainTypeSettingsContext
  readonly overrides: Partial<Record<string, DomainTypeSettingsContext>>
}

export function getSettingValue<T extends keyof DomainTypeSettingsContext>(
  settingsContext: SettingsContext,
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType,
  setting: T
): DomainTypeSettingsContext[T] | undefined {
  const parentDomainTypes = getParentDomainTypes(domainTypes, domainType)
  for (const parent of parentDomainTypes) {
    const key = getDomainTypeExternalId(parent)
    const overriddenSetting = settingsContext.overrides[key]?.[setting]
    if (overriddenSetting !== undefined) {
      return overriddenSetting
    }
  }
  return settingsContext.global[setting]
}

export function getEquipmentAttributes(domainTypes: Partial<Record<string, DomainType>>): {
  isSerialisedAttribute: NonListAttribute<BoolAttribute>
  quantityAttribute: NonListAttribute<NumberAttribute>
  equipmentTypeAttribute: NonListAttribute<DomainTypeAttribute>
  serialisedEquipmentAttribute: NonListAttribute<DomainTypeAttribute>
} {
  return {
    isSerialisedAttribute: {
      Name: 'IsSerialised',
      AttributeType: 'bool',
      Title: 'Is Serialised'
    },
    quantityAttribute: {
      Name: 'Quantity',
      AttributeType: 'number',
      Title: 'Quantity'
    },
    equipmentTypeAttribute: {
      Name: 'EquipmentType',
      AttributeType: 'domainType',
      AttributeDomainType: getDomainType(domainTypes, 'EquipmentType', 'EquipmentType')?.Id ?? '',
      Title: 'Equipment Type'
    },
    serialisedEquipmentAttribute: {
      Name: 'SerialisedEquipment',
      AttributeType: 'domainType',
      AttributeDomainType: getDomainType(domainTypes, 'SerialisedEquipment', 'SerialisedEquipment')?.Id ?? '',
      Title: 'Serialised Equipment'
    }
  }
}

function getEquipmentDomainType(
  domainTypes: Partial<Record<string, DomainType>>,
  equipment: DomainTypeInstance
): DomainType | null {
  const { serialisedEquipmentAttribute, equipmentTypeAttribute, isSerialisedAttribute } = getEquipmentAttributes(domainTypes)
  const isSerialised = getValue(equipment, isSerialisedAttribute) ?? false
  const serialisedEquipmentDomainType = domainTypes[serialisedEquipmentAttribute.AttributeDomainType]
  const equipmentTypeDomainType = domainTypes[equipmentTypeAttribute.AttributeDomainType]
  if (serialisedEquipmentDomainType === undefined || equipmentTypeDomainType === undefined) {
    return null
  }
  return isSerialised
    ? serialisedEquipmentDomainType
    : equipmentTypeDomainType
}

export default createContext<SettingsContext>({
  global: {},
  overrides: {
    'Location:Location': {
      listInputIncludeAll: true,
      disableRelationship(domainTypes, attributeChain) {
        return attributeChain.slice(1).some(attribute => {
          const attributeDomainType = domainTypes[attribute.AttributeDomainType]
          if (isNullOrUndefined(attributeDomainType)) {
            return true
          }
          return ['SerialisedEquipment:SerialisedEquipment', 'StockLocation:StockLocation'].includes(getDomainTypeExternalId(attributeDomainType))
        })
      }
    },
    'Company:Company': {
      listInputIncludeAll: true
    },
    'Role:Role': {
      listInputIncludeAll: true
    },
    'DomainType:DomainType': {
      listInputIncludeAll: true
    },
    'Person:Person': {
      disableRelationship(domainTypes, attributeChain, index) {
        if (attributeChain.length > 1 && index < attributeChain.length - 1) {
          return true
        }
        return attributeChain.slice(1).some(attribute => {
          const attributeDomainType = domainTypes[attribute.AttributeDomainType]
          if (isNullOrUndefined(attributeDomainType)) {
            return true
          }
          return ['Tag:Tag', 'AttachedFileHandle:AttachedFileHandle'].includes(getDomainTypeExternalId(attributeDomainType))
        })
      },
      customButtons(domainTypes, domainType, filterContext, subtypesCache, target, user) {
        const instances = target.type === 'instances'
          ? target.instances
          : []
        const instance = instances[0]
        const person = pipe(
          instance,
          PersonCodec.decode,
          E.match(
            () => null,
            identity
          )
        )
        const subtype = getSubtype(domainTypes, domainType, instance ?? {}, subtypesCache) ?? domainType
        const editButton = getDomainTypeButtons(domainTypes, subtype)
          .map(([, button]) => button)
          .find(button => button.Type === 'EditButton')
        const emptySettingsContext: SettingsContext = {
          global: {},
          overrides: {}
        }
        return [
          {
            type: 'custom',
            icon: 'password',
            domainType: subtype,
            disabled: false,
            visible: instances.length === 1
              && person?.Id === user?.id,
            showOn: ['TableRow', 'DetailsHeader'],
            role: 'Phalanx Users',
            name: 'Change My Password',
            priority: 'high',
            Component: ChangeMyPasswordButton
          },
          {
            type: 'custom',
            icon: 'password',
            domainType: subtype,
            disabled: editButton === undefined
              || isDisabled(editButton, domainTypes, subtype, filterContext, emptySettingsContext, target, null, []),
            visible: editButton !== undefined
              && ['state', true].includes(
                canEditInstancesAttributes(domainTypes, subtype, filterContext, emptySettingsContext, instances, user, [])
              )
              && instances.length === 1
              && person?.Id !== user?.id,
            showOn: ['TableRow', 'DetailsHeader'],
            role: editButton?.Role ?? 'Phalanx Person Editors',
            name: 'Change Password',
            priority: 'high',
            Component: ChangePasswordButton
          }
        ]
      }
    },
    Equipment: {
      getHeading(settingsContext, domainTypes, domainType, instance): string {
        const equipmentDomainType = getEquipmentDomainType(domainTypes, instance)
        if (equipmentDomainType === null) {
          return domainType.Title
        }
        const equipmentInstance = instance[equipmentDomainType.Name]
        if (!AttributeTypeCodecs.domainType.is(equipmentInstance)) {
          return equipmentDomainType.Name
        }
        return getHeading(
          settingsContext,
          domainTypes,
          equipmentDomainType,
          equipmentInstance
        )
      }
    },
    AbsoluteDateRangeFilterValue: {
      getHeading(settingsContext, domainTypes, domainType, instance) {
        if (!AbsoluteDateRangeFilterValueCodec.is(instance)) {
          return 'Invalid'
        }
        return `${dateToString(instance.From)} - ${dateToString(instance.To)}`
      }
    },
    RelativeDateRange: {
      getHeading(settingsContext, domainTypes, domainType, instance) {
        if (!RelativeDateRangeCodec.is(instance)) {
          return 'Invalid'
        }
        switch (instance.Type) {
          case 'LastCalendarRelativeDateRange':
            return `Last ${instance.Units} calendar ${instance.Measure}${instance.IncludeCurrent ? '' : ' (not including current)'}`
          case 'LastRelativeDateRange':
            return `Last ${instance.Units} ${instance.Measure}${instance.IncludeToday ? '' : ' (not including today)'}`
          case 'NextCalendarRelativeDateRange':
            return `Next ${instance.Units} calendar ${instance.Measure}${instance.IncludeCurrent ? '' : ' (not including current)'}`
          case 'NextRelativeDateRange':
            return `Next ${instance.Units} ${instance.Measure}${instance.IncludeToday ? '' : ' (not including today)'}`
        }
        return `This calendar ${instance.Measure}`
      }
    },
    AbsoluteDateTimeRangeFilterValue: {
      getHeading(settingsContext, domainTypes, domainType, instance) {
        if (!AbsoluteDateTimeRangeFilterValueCodec.is(instance)) {
          return 'Invalid'
        }
        return `${dateTimeToString(instance.From)} - ${dateTimeToString(instance.To)}`
      }
    },
    RelativeDateTimeRange: {
      getHeading(settingsContext, domainTypes, domainType, instance) {
        if (!RelativeDateTimeRangeCodec.is(instance)) {
          return 'Invalid'
        }
        switch (instance.Type) {
          case 'LastCalendarRelativeDateTimeRange':
            return `Last ${instance.Units} calendar ${instance.Measure}${instance.IncludeCurrent ? '' : ' (not including current)'}`
          case 'LastRelativeDateTimeRange':
            return `Last ${instance.Units} ${instance.Measure}`
          case 'NextCalendarRelativeDateTimeRange':
            return `Next ${instance.Units} calendar ${instance.Measure}${instance.IncludeCurrent ? '' : '(not including current)'}`
          case 'NextRelativeDateTimeRange':
            return `Next ${instance.Units} ${instance.Measure}`
          case 'ThisRelativeDateTimeRange':
            return `This calendar ${instance.Measure}`
        }
      }
    },
    'Work:Work': {
      customButtons(domainTypes, domainType, filterContext, subtypesCache, target, user) {
        const instances = target.type === 'instances'
          ? target.instances
          : []
        const instance = instances[0]
        const work = pipe(
          instance,
          t.type({ Tasks: t.array(t.type({ Status: t.string })) }).decode,
          E.match(
            () => null,
            identity
          )
        )
        const subtype = getSubtype(domainTypes, domainType, instance ?? {}, subtypesCache) ?? domainType
        return [
          {
            type: 'custom',
            icon: 'replay',
            domainType: subtype,
            disabled: false,
            visible: instances.length === 1
              && (work?.Tasks.some(task => task.Status === 'FailedUpdate') ?? false),
            showOn: ['DetailsHeader'],
            role: 'Phalanx Work Debugger',
            name: 'Retry Updates',
            priority: 'high',
            Component: RetryUpdatesButton
          }
        ]
      }
    },
    'Dataform:Dataform': {
      nullSystemCompany: true
    },
    'Metadata:Metadata': {
      nullSystemCompany: true
    },
    'AlertType:Metadata': {
      enableEditNullCompany: true
    }
  }
})
