import { EnumAttributeValue } from 'types'
import { isListAttributeValue } from 'utils/helpers'
import { NarrowedProps } from '../AttributeInput'
import ListEnumInput from './ListEnumInput'
import NonListEnumInput from './NonListEnumInput'

export default function EnumInput({
  attributeValue,
  readOnly,
  disabled,
  pathError,
  textFieldProps,
  disableClearable = false,
  disableSorting = false,
  onChange
}: NarrowedProps<EnumAttributeValue> & { disableClearable?: boolean, disableSorting?: boolean }): JSX.Element {
  if (isListAttributeValue(attributeValue)) {
    return (
      <ListEnumInput
        readOnly={readOnly}
        attributeValue={attributeValue}
        disabled={disabled}
        pathError={pathError}
        textFieldProps={textFieldProps}
        onChange={onChange} />
    )
  }
  return (
    <NonListEnumInput
      readOnly={readOnly}
      attributeValue={attributeValue}
      disabled={disabled}
      pathError={pathError}
      textFieldProps={textFieldProps}
      disableClearable={disableClearable}
      disableSorting={disableSorting}
      onChange={onChange} />
  )
}
