import { TextField } from '@mui/material'
import { ChangeEvent, useCallback, useState } from 'react'
import { NumberAttributeValue } from 'types'
import { DEFAULT_ATTRIBUTE_INPUT_SIZE, DEFAULT_ATTRIBUTE_INPUT_VARIANT } from 'utils/constants'
import { isListAttributeValue, toErrorText } from 'utils/helpers'
import { NarrowedProps } from './AttributeInput'
import NotSupportedInput from './NotSupportedInput'

export default function NumberInput({
  attributeValue,
  readOnly,
  pathError,
  disabled,
  textFieldProps,
  onChange
}: NarrowedProps<NumberAttributeValue>): JSX.Element | null {
  const [displayValue, setDisplayValue] = useState(attributeValue.value?.toString())
  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (onChange === undefined) {
      return
    }
    setDisplayValue(event.target.value)
    onChange({
      attribute: attributeValue.attribute,
      value: isNaN(event.target.valueAsNumber)
        ? null
        : event.target.valueAsNumber
    })
  }, [attributeValue.attribute, onChange])
  if (isListAttributeValue(attributeValue)) {
    return (
      <NotSupportedInput
        attributeValue={attributeValue}
        textFieldProps={textFieldProps} />
    )
  }
  return (
    <TextField
      value={displayValue === undefined
        ? ''
        : displayValue}
      label={attributeValue.attribute.Title}
      id={attributeValue.attribute.Name}
      type='number'
      variant={DEFAULT_ATTRIBUTE_INPUT_VARIANT}
      fullWidth
      size={DEFAULT_ATTRIBUTE_INPUT_SIZE}
      onChange={handleChange}
      required={attributeValue.attribute.Required ?? false}
      disabled={disabled}
      error={pathError !== undefined}
      helperText={toErrorText(pathError)}
      {...textFieldProps}
      inputProps={{
        ...textFieldProps?.inputProps,
        inputMode: 'numeric',
        pattern: '^[-0-9][0-9]+$',
        readOnly
      }} />
  )
}
