import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes, getUser } from 'state/reducers'
import { ActionContext, ButtonLocation, ContextAttributeNode, ContextDomainTypeNode, ContextTree, DomainType, DomainTypeAction, DomainTypeAttribute, DomainTypeInstance, Filter, ListAttribute, User } from 'types'
import { Results } from 'types/dataform'
import { DATAFORM_RESULTS_DOMAIN_TYPE_NAME, PATH_SEPARATOR } from 'utils/constants'
import { CONTEXT_PREFIX, FilterContext, applyAllFilters, applyContextFilters, getContextAttributeValues, getTemplateContextValues } from 'utils/filters'
import { getBatchInstances, getCachedSubtypes, getContextTree, getDomainTypeActions, getDomainTypeAttributes, getDomainTypeExternalId, getDomainTypeSetting, getLeafNodes, getNodes, getValue, isBranchNode, isDomainTypeListAttribute, isInRole, isLeafNode, isNullOrUndefined, makeAttributeTypeGuard, requiresNoInstances } from 'utils/helpers'
import { ButtonTarget } from './useButtons'
import { useDataformResultsAttribute } from './useDataformResultsAttribute'
import { useDomainTypeContextWithoutOnInvalidate } from './useDomainTypeContextWithoutOnInvalidate'
import { useFilterContext } from './useFilterContext'

export interface ActionDetails {
  readonly apiDomainType: DomainType
  readonly actionDomainType: DomainType
  readonly pageDomainType: DomainType
  readonly action: DomainTypeAction
  readonly contextTree: ContextTree
  readonly unfilteredContextTree: ContextTree
  readonly disabled: boolean
  readonly visible: boolean
  readonly roles: string[]
}

function getDomainTypeListValue(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType,
  instance: DomainTypeInstance,
  attribute: ListAttribute<DomainTypeAttribute>,
  dataformResultsAttribute: ListAttribute<DomainTypeAttribute> | null
): DomainTypeInstance[] {
  if (attribute.Name !== dataformResultsAttribute?.Name) {
    return getValue(instance, attribute) ?? []
  }
  const attributes = getDomainTypeAttributes(domainTypes, domainType)
  const dataformResults = attributes
    .filter(makeAttributeTypeGuard('dataformResults', false))
    .map(attribute => [attribute.Name, attribute.Title, getValue(instance, attribute)])
    .filter((tuple): tuple is [string, string, Results] => !isNullOrUndefined(tuple[2]))
  const multiDataformResults = attributes
    .filter(makeAttributeTypeGuard('multiDataformResults', false))
    .flatMap(attribute => {
      const value = getValue(instance, attribute)
      if (isNullOrUndefined(value)) {
        return []
      }
      return Object.keys(value)
        .map(key => [`${attribute.Name}_${key}`, `${attribute.Title}${PATH_SEPARATOR}${key}`, value[key]])
        .filter((tuple): tuple is [string, string, Results] => !isNullOrUndefined(tuple[2]))
    })
  return dataformResults
    .concat(multiDataformResults)
    .map(([name, title, results]) => ({
      ...results,
      Id: name,
      Title: title
    }))
}

function addAttributeToTree(
  domainTypes: Partial<Record<string, DomainType>>,
  contextTree: ContextTree,
  attribute: ListAttribute<DomainTypeAttribute>,
  dataformResultsAttribute: ListAttribute<DomainTypeAttribute> | null
): ContextTree {
  return contextTree.map((node): ContextDomainTypeNode => {
    if (isLeafNode(node)) {
      const attributeDomainType = domainTypes[attribute.AttributeDomainType]
      const nextTree: ContextTree = getDomainTypeListValue(
        domainTypes,
        node.domainType,
        node.instance,
        attribute,
        dataformResultsAttribute
      )
        .map((subInstance): ContextDomainTypeNode | undefined => {
          if (attributeDomainType === undefined) {
            return undefined
          }
          return {
            domainType: attributeDomainType,
            instance: subInstance,
            type: 'nested',
            nodes: []
          }
        })
        .filter((value): value is ContextDomainTypeNode => value !== undefined)
      if (nextTree.length === 0 && attributeDomainType !== undefined) {
        nextTree.push({
          domainType: attributeDomainType,
          instance: {},
          type: 'nested-placeholder',
          nodes: []
        })
      }
      return {
        ...node,
        nodes: [
          {
            attribute,
            nodes: nextTree,
            type: 'nested'
          }
        ]
      }
    }
    return {
      ...node,
      nodes: node.nodes.map<ContextAttributeNode>(attributeNode => ({
        ...attributeNode,
        nodes: addAttributeToTree(domainTypes, attributeNode.nodes, attribute, dataformResultsAttribute)
      }))
    }
  })
}

function isNotPlaceholder(node: ContextDomainTypeNode): boolean {
  return node.type !== 'nested-placeholder' && node.type !== 'active-placeholder'
}

function applyActionConditions(
  domainTypes: Partial<Record<string, DomainType>>,
  actionDomainType: DomainType,
  conditions: Filter[],
  contextTree: ContextTree,
  filterContext: FilterContext,
  isAdmin: boolean
): ContextTree {
  const nonPlaceholderNodes = contextTree.filter(isNotPlaceholder)
  const leafNodes = nonPlaceholderNodes.filter(isLeafNode)
  const branchNodes = nonPlaceholderNodes.filter(isBranchNode)
  const filteredLeafNodes = isAdmin
    ? []
    : leafNodes.filter(node => {
      return applyAllFilters(
        domainTypes,
        actionDomainType,
        node.instance,
        conditions,
        filterContext
      )
    })
  if (!isAdmin
    && leafNodes.every(node => node.type === 'active')
    && filteredLeafNodes.length !== leafNodes.length) {
    return []
  }
  return [
    ...filteredLeafNodes,
    ...branchNodes
      .map(node => {
        return {
          ...node,
          nodes: node.nodes
            .map(attributeNode => ({
              ...attributeNode,
              nodes: applyActionConditions(
                domainTypes,
                actionDomainType,
                conditions,
                attributeNode.nodes,
                filterContext,
                isAdmin
              )
            }))
        }
      })
  ]
}

interface AvailableContext {
  readonly domainType: DomainType
  readonly type: 'context' | 'active' | 'nested' | 'active-placeholder' | 'nested-placeholder'
  readonly count: number
  readonly node: ContextDomainTypeNode
}

interface InvalidContext {
  readonly context: ActionContext
  readonly validationResult: 'disable' | 'hide'
}

function makeTree(
  parentNodes: [ContextDomainTypeNode, ContextAttributeNode][],
  node: ContextDomainTypeNode
): ContextTree {
  const firstParentNode = parentNodes[0]
  if (firstParentNode === undefined) {
    return [node]
  }
  const [domainTypeNode, attributeNode] = firstParentNode
  return [
    {
      ...domainTypeNode,
      nodes: [
        {
          ...attributeNode,
          nodes: makeTree(parentNodes.slice(1), node)
        }
      ]
    }
  ]
}

function validateContext(
  domainTypes: Partial<Record<string, DomainType>>,
  actionDomainType: DomainType,
  childDomainTypesCache: Partial<Record<string, DomainType[]>>,
  parentDomainTypesCache: Partial<Record<string, DomainType[]>>,
  context: ActionContext[] | null | undefined,
  node: ContextDomainTypeNode,
  count: number,
  availableContext: AvailableContext[],
  conditions: Filter[] | null = [],
  filterContext: FilterContext,
  parentNodes: [ContextDomainTypeNode, ContextAttributeNode][] = []
): InvalidContext[] {
  const subtypeIds = getCachedSubtypes(
    domainTypes,
    actionDomainType,
    childDomainTypesCache
  )
    .map(domainType => domainType.Id)
  if (isBranchNode(node)) {
    const invalidContext = node.nodes.flatMap(attributeNode => {
      const attributeDomainType = domainTypes[attributeNode.attribute.AttributeDomainType]
      if (attributeDomainType === undefined) {
        return attributeNode.nodes.flatMap(domainTypeNode => {
          return validateContext(
            domainTypes,
            actionDomainType,
            childDomainTypesCache,
            parentDomainTypesCache,
            context,
            domainTypeNode,
            attributeNode.nodes.filter(isBranchNode).length,
            [],
            conditions,
            filterContext,
            parentNodes
          )
        })
      }
      return attributeNode.nodes.flatMap(domainTypeNode => {
        return validateContext(
          domainTypes,
          actionDomainType,
          childDomainTypesCache,
          parentDomainTypesCache,
          context,
          domainTypeNode,
          attributeNode.nodes.filter(isBranchNode).length,
          [
            ...availableContext.slice(0, -1),
            {
              domainType: node.domainType,
              type: node.type,
              count,
              node: domainTypeNode
            },
            {
              domainType: attributeDomainType,
              type: node.type,
              count: 0,
              node: domainTypeNode
            }
          ],
          conditions,
          filterContext,
          parentNodes.concat([[node, attributeNode]])
        )
      })
    })
    return invalidContext
      .filter(({ context }, i) => invalidContext.findIndex(c => c.context === context) === i)
  }
  const contextTree = makeTree(parentNodes, node)
  const batchInstances = getBatchInstances(contextTree)
  const actionFilterContext = {
    ...filterContext,
    [CONTEXT_PREFIX]: getContextAttributeValues(
      domainTypes,
      contextTree,
      batchInstances,
      parentDomainTypesCache
    )
  }
  const matchesContextFilters = applyContextFilters(
    domainTypes,
    actionDomainType,
    conditions ?? [],
    actionFilterContext
  )
  return (context ?? []).map((contextItem): [ActionContext, 'valid' | 'disable' | 'hide'] => {
    const contextItemDomainType = domainTypes[contextItem.DomainType]
    if (!contextItemDomainType) {
      return [contextItem, 'hide']
    }
    const validChildTypes = getCachedSubtypes(
      domainTypes,
      contextItemDomainType,
      childDomainTypesCache
    )
    const matchingContext = availableContext
      .find(availableContextItem => {
        return validChildTypes
          .find(childType => childType.Id === availableContextItem.domainType.Id)
      })
    if (matchingContext === undefined) {
      return [contextItem, 'hide']
    }
    if (isNotParent(contextItem, matchingContext)) {
      return [contextItem, 'hide']
    }
    if (isUnsupportedNestedBatch(contextItem, matchingContext)) {
      return [contextItem, 'hide']
    }
    if (isUnsupportedBatch(contextItem, matchingContext)) {
      return [contextItem, 'disable']
    }
    if (isPlaceholder(matchingContext)) {
      return [contextItem, 'disable']
    }
    if (!matchesContextFilters) {
      return [contextItem, 'disable']
    }
    return [contextItem, 'valid']
  })
    .flatMap(([context, validationResult]): InvalidContext[] => {
      if (validationResult === 'valid') {
        return []
      }
      return [
        {
          context,
          validationResult
        }
      ]
    })

  function isUnsupportedBatch(
    contextItem: ActionContext,
    matchingContext: AvailableContext
  ) {
    return !contextItem.Batch
      && matchingContext.type === 'active'
      && matchingContext.count > 1
  }

  function isUnsupportedNestedBatch(
    contextItem: ActionContext,
    matchingContext: AvailableContext
  ) {
    return !contextItem.Batch
      && (matchingContext.type === 'nested' || matchingContext.type === 'nested-placeholder')
  }

  function isPlaceholder(matchingContext: AvailableContext) {
    return matchingContext.type === 'active-placeholder'
      || matchingContext.type === 'nested-placeholder'
  }

  function isNotParent(
    contextItem: ActionContext,
    matchingContext: AvailableContext
  ) {
    if (contextItem.Parent !== true) {
      return false
    }
    return !subtypeIds.includes(matchingContext.node.domainType.Id)
  }
}

export function getActionDetails(
  domainTypes: Partial<Record<string, DomainType>>,
  apiDomainType: DomainType,
  pageDomainType: DomainType,
  childDomainTypesCache: Partial<Record<string, DomainType[]>>,
  parentDomainTypesCache: Partial<Record<string, DomainType[]>>,
  contextTree: ContextTree,
  target: ButtonTarget,
  filterContext: FilterContext,
  user: User | null,
  actionDomainType: DomainType,
  action: DomainTypeAction
): ActionDetails {
  const invalidContext = contextTree.flatMap(node => {
    return validateContext(
      domainTypes,
      actionDomainType,
      childDomainTypesCache,
      parentDomainTypesCache,
      action.Context,
      node,
      contextTree.filter(isBranchNode).length,
      [
        {
          domainType: apiDomainType,
          type: 'context',
          count: 1,
          node
        }
      ],
      action.Conditions,
      filterContext
    )
  })
  const batchInstances = getBatchInstances(contextTree)
  const actionFilterContext = Object.assign(
    {},
    filterContext,
    {
      [CONTEXT_PREFIX]: getContextAttributeValues(
        domainTypes,
        contextTree,
        batchInstances,
        parentDomainTypesCache
      )
    }
  )
  const isAdmin = requiresNoInstances(action)
  const filteredTree = applyActionConditions(
    domainTypes,
    actionDomainType,
    action.Conditions ?? [],
    contextTree,
    actionFilterContext,
    isAdmin
  ).filter(node => isAdmin || getLeafNodes([node]).length > 0)
  const leafNodes = getLeafNodes(filteredTree)
  const isBatch = leafNodes.length > 1 || target.type === 'query'
  const leafNodeType = getLeafNodes(contextTree)[0]?.type
  const isNotNested = leafNodeType !== 'nested' && leafNodeType !== 'nested-placeholder'
  const roles = getTemplateContextValues(action.Role, actionFilterContext)
  const disabled = isActionDisabled(
    invalidContext,
    target,
    leafNodes,
    isAdmin,
    isBatch,
    action,
    isNotNested)
  return {
    apiDomainType,
    pageDomainType,
    actionDomainType,
    action,
    contextTree: filteredTree,
    unfilteredContextTree: contextTree,
    disabled,
    visible: isActionVisible(
      invalidContext,
      isNotNested,
      action,
      roles,
      user,
      target,
      disabled),
    roles
  }
}

function isActionVisible(
  invalidContext: InvalidContext[],
  isNotNested: boolean,
  action: DomainTypeAction,
  roles: string[],
  user: User | null,
  target: ButtonTarget,
  buttonDisabled: boolean
): boolean {
  if (target.type === 'none') {
    return !buttonDisabled
  }

  return !invalidContext.some(context => context.validationResult === 'hide')
    && (isNotNested || action.Batch)
    && roles.every(role => isInRole(user, role))
}

function isActionDisabled(
  invalidContext: InvalidContext[],
  target: ButtonTarget,
  leafNodes: ContextDomainTypeNode[],
  isAdmin: boolean,
  isBatch: boolean,
  action: DomainTypeAction,
  isNotNested: boolean
): boolean {
  if (invalidContext.length > 0) {
    return true
  }
  if (isAdmin) {
    return false
  }
  if (isBatch && !action.Batch) {
    return true
  }

  if (target.type === 'query') {
    return ((action.Conditions?.length ?? 0) > 0 || !isNotNested)
  }
  else {
    return leafNodes.length === 0
  }
}

function getActions(
  domainTypes: Partial<Record<string, DomainType>>,
  apiDomainType: DomainType,
  pageDomainType: DomainType,
  currentDomainType: DomainType,
  childDomainTypesCache: Partial<Record<string, DomainType[]>>,
  parentDomainTypesCache: Partial<Record<string, DomainType[]>>,
  contextTree: ContextTree,
  target: ButtonTarget,
  on: ButtonLocation | null,
  filterContext: FilterContext,
  user: User | null,
  dataformResultsAttribute: ListAttribute<DomainTypeAttribute> | null
): ActionDetails[] {
  const attributes = getDomainTypeAttributes(domainTypes, currentDomainType)
  const hasDataformResults = attributes
    .some(attribute => attribute.AttributeType === 'dataformResults'
      || attribute.AttributeType === 'multiDataformResults')
  return getDomainTypeActions(domainTypes, currentDomainType)
    .map(([actionDomainType, action]) => getActionDetails(
      domainTypes,
      apiDomainType,
      pageDomainType,
      childDomainTypesCache,
      parentDomainTypesCache,
      contextTree,
      target,
      filterContext,
      user,
      actionDomainType,
      action
    )).concat(
      attributes
        .concat(!hasDataformResults
          || isNullOrUndefined(dataformResultsAttribute)
          || getDomainTypeExternalId(currentDomainType) === DATAFORM_RESULTS_DOMAIN_TYPE_NAME
          ? []
          : [dataformResultsAttribute])
        .filter(isDomainTypeListAttribute)
        .filter(() => on !== 'TableToolbar')
        .flatMap(attribute => {
          const attributeDomainType = domainTypes[attribute.AttributeDomainType]
          if (attributeDomainType === undefined) {
            return []
          }
          if (getNodes(contextTree).find(node => node.domainType.Id === attributeDomainType.Id)) {
            return []
          }
          return getActions(
            domainTypes,
            apiDomainType,
            pageDomainType,
            attributeDomainType,
            childDomainTypesCache,
            parentDomainTypesCache,
            addAttributeToTree(
              domainTypes,
              contextTree,
              attribute,
              dataformResultsAttribute
            ),
            target,
            on,
            filterContext,
            user,
            dataformResultsAttribute
          )
        })
    )
}

export function useActions(
  domainType: DomainType,
  target: ButtonTarget,
  on: ButtonLocation | null,
  leafNodeType?: 'active' | 'nested'
): ActionDetails[] {
  const context = useDomainTypeContextWithoutOnInvalidate()
  const domainTypes = useSelector(getAllDomainTypes)
  const filterContext = useFilterContext()
  const user = useSelector(getUser)
  const dataformResultsAttribute = useDataformResultsAttribute()
  return useMemo(() => {
    const instances = target.type === 'instances'
      ? target.instances
      : []
    const contextTree = getContextTree(context, domainType, instances, leafNodeType, true)
    const apiDomainType = context.instances[0]?.[0] ?? domainType
    return getActions(
      domainTypes,
      apiDomainType,
      domainType,
      domainType,
      {},
      {},
      contextTree,
      target,
      on,
      filterContext,
      user,
      dataformResultsAttribute
    )
      .filter(actionButton => getDomainTypeSetting(domainTypes, actionButton.apiDomainType, 'Api'))
  }, [target, context, domainType, leafNodeType, domainTypes, on, filterContext, user, dataformResultsAttribute])
}