import { Alert, Backdrop, Box, BoxProps, Button, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Paper } from '@mui/material'
import AppendDomainTypeContext from 'components/domainType/AppendDomainTypeContext'
import DomainTypeTheme from 'components/domainType/DomainTypeTheme'
import * as E from 'fp-ts/Either'
import { useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes, getPerson } from 'state/reducers'
import { ApiError, DataformResultsAttribute, DomainTypeInstance, MultiDataformResultsAttribute } from 'types'
import { Dataform, Element, ElementValueTypes, Results } from 'types/dataform'
import { DomainTypeContext } from 'utils/context'
import { isNullOrUndefined, makeSortFunction } from 'utils/helpers'
import { useApi, useDomainTypeContextInstance } from 'utils/hooks'
import resultsReducer, { backButtonClicked, dataformEdited, elementValueChanged, groupStepperClicked, initialised, nextButtonClicked, stateToResults, unlocked } from './dataformReducer'
import FooterButtons from './FooterButtons'
import GroupInput from './GroupInput'
import GroupStepper from './GroupStepper'
import Header from './Header'

interface Props {
  readonly dataform: Dataform | null
  readonly initialResults: Results | null
  readonly attribute: DataformResultsAttribute | [MultiDataformResultsAttribute, string] | null
  readonly errorMessage?: string
  readonly readOnly?: boolean
  readonly noBoxShadow?: boolean
  readonly maxHeight?: BoxProps['maxHeight']
  readonly minHeight?: BoxProps['minHeight']
  readonly isLoading?: boolean
  readonly editable?: boolean
  onChange?(value: Results): void
  onComplete?(): void
  onClose?(): void
}

function hasNotBeenFilledIn(
  results: Results | null,
  readOnly = false
): boolean {
  return isNullOrUndefined(results) && readOnly
}

export default function ResultsInput({
  dataform,
  initialResults,
  attribute,
  errorMessage,
  readOnly = false,
  noBoxShadow = false,
  maxHeight,
  minHeight,
  isLoading = false,
  editable = false,
  onChange,
  onComplete,
  onClose
}: Props): JSX.Element | null {
  const domainTypes = useSelector(getAllDomainTypes)
  const dataformDomainType = Object.values(domainTypes)
    .find(domainType => domainType?.Name === 'Dataform') ?? null
  const groupsAttribute = dataformDomainType?.Attributes
    .find(attribute => attribute.Name === 'Groups')
  const [
    state,
    dispatch
  ] = useReducer(
    resultsReducer,
    {
      type: 'notInitialised'
    }
  )
  const work = useDomainTypeContextInstance('Work', 'Work')
  const task = useDomainTypeContextInstance('Task', 'Task')
  const person = useSelector(getPerson)
  const defaultValueContext = useMemo(() => ({
    work,
    task,
    loggedInPerson: person
  }), [person, task, work])
  useEffect(() => {
    if (state.type === 'notInitialised' && dataform !== null) {
      dispatch(initialised(
        dataform,
        initialResults,
        defaultValueContext
      ))
    }
  }, [dataform, defaultValueContext, initialResults, editable, state.type])
  useEffect(() => {
    if (state.type === 'initialised' && dataform !== null && editable) {
      dispatch(dataformEdited(
        dataform,
        defaultValueContext
      ))
    }
  }, [dataform, defaultValueContext, editable, state.type])
  useEffect(() => {
    if (state.type === 'initialised' && readOnly !== true) {
      onChange?.(stateToResults(state))
    }
  }, [onChange, readOnly, state])
  const lastCompleteValueRef = useRef(initialResults?.Complete)
  useEffect(() => {
    if (state.type !== 'initialised') {
      return
    }
    if (state.complete
      && lastCompleteValueRef.current !== true) {
      onComplete?.()
    }
    lastCompleteValueRef.current = state.complete
  }, [onComplete, initialResults?.Complete, state])
  const onElementValueChange = useCallback(<E extends Element>(
    element: E,
    value: ElementValueTypes[E['ElementType']]
  ): void => {
    dispatch(elementValueChanged(element, value))
  }, [])
  const onGroupStepperClick = useCallback((index: number) => dispatch(groupStepperClicked(index)), [])
  const onBackButtonClick = useCallback(() => dispatch(backButtonClicked()), [])
  const onNextButtonClick = useCallback(() => dispatch(nextButtonClicked()), [])
  const onUnlock = useCallback(() => dispatch(unlocked()), [])
  const elevation = noBoxShadow ? 0 : 2
  const {
    activeGroup = 0,
    elements = {},
    showRequiredErrors = false,
    complete = false
  } = state.type === 'initialised' ? state : {}
  const group = dataform?.Groups[activeGroup]
  const formReadOnly = readOnly || complete
  const showNotFilledInAlert = !isLoading && hasNotBeenFilledIn(initialResults, readOnly)
  const showErrorAlert = useMemo(() => {
    return !isLoading && !showNotFilledInAlert && errorMessage !== undefined
  }, [errorMessage, isLoading, showNotFilledInAlert])
  const showDataform = !isLoading && dataform !== null && !showNotFilledInAlert
  const newAttributes = useMemo(() => {
    return groupsAttribute !== undefined
      ? [groupsAttribute]
      : []
  }, [groupsAttribute])
  const api = useApi()
  const context = useContext(DomainTypeContext)
  const [isEditing, setIsEditing] = useState(false)
  const [editError, setEditError] = useState<ApiError | null>(null)
  const performEdit = useCallback(async (dataform: Dataform) => {
    if (!api.isSignedIn) {
      return
    }
    setIsEditing(true)
    const response = await api.put('Dataform', dataform as unknown as DomainTypeInstance)
    if (E.isLeft(response)) {
      setEditError(response.left)
    }
    setIsEditing(false)
    context.onInvalidate?.()
  }, [api, context])
  const onCloseEditErrorDialog = useCallback(() => {
    setEditError(null)
  }, [])
  const onChangeGroupOrder = useCallback((groupOrder: string[]) => {
    if (isNullOrUndefined(dataform)) {
      return
    }
    performEdit({
      ...dataform,
      Groups: dataform.Groups
        .slice()
        .sort(makeSortFunction(groupOrder, group => group.Id))
    })
  }, [dataform, performEdit])
  const onChangeElementOrder = useCallback((elementOrder: string[]) => {
    if (isNullOrUndefined(dataform)) {
      return
    }
    const group = dataform.Groups[activeGroup]
    if (isNullOrUndefined(group)) {
      return
    }
    performEdit({
      ...dataform,
      Groups: [
        ...dataform.Groups.slice(0, activeGroup),
        {
          ...group,
          Elements: group.Elements
            .slice()
            .sort(makeSortFunction(elementOrder, element => element.Id))
        },
        ...dataform.Groups.slice(activeGroup + 1)
      ]
    })
  }, [activeGroup, dataform, performEdit])
  return (
    <DomainTypeTheme domainType={dataformDomainType}>
      <Paper
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column'
        }}
        elevation={elevation}>
        <Backdrop
          open={isEditing}
          sx={{
            position: 'absolute',
            zIndex: 1
          }}>
          <CircularProgress />
        </Backdrop>
        <Dialog
          maxWidth='md'
          open={!isNullOrUndefined(editError)}
          onClose={onCloseEditErrorDialog}>
          <DialogTitle>
            Error
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Failed to edit dataform
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onCloseEditErrorDialog}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Box
          p={1}
          display='flex'
          flexDirection='column'>
          <Header
            dataformDomainType={dataformDomainType}
            state={state}
            attribute={attribute}
            readOnly={readOnly}
            showNotFilledInAlert={showNotFilledInAlert}
            isLoading={isLoading}
            onClose={onClose}
            onUnlock={onUnlock} />
          <AppendDomainTypeContext
            newAttributes={newAttributes}>
            <Collapse in={showDataform}>
              <GroupStepper
                activeGroup={activeGroup}
                dataform={dataform}
                state={state}
                formReadOnly={formReadOnly}
                showRequiredErrors={showRequiredErrors}
                editable={editable}
                onClick={onGroupStepperClick}
                onChangeGroupOrder={onChangeGroupOrder} />
            </Collapse>
          </AppendDomainTypeContext>
        </Box>
        <Collapse
          in={showErrorAlert}>
          <Alert
            severity='error'>
            {errorMessage}
          </Alert>
        </Collapse>
        <Collapse in={showNotFilledInAlert}>
          <Alert
            severity='info'>
            This form has not been filled in yet
          </Alert>
        </Collapse>
        <Collapse in={showDataform}>
          <Divider />
          <Box
            maxHeight={maxHeight}
            minHeight={minHeight}
            overflow='auto'>
            <AppendDomainTypeContext
              newAttributes={newAttributes}>
              <GroupInput
                dataform={dataform}
                elements={elements}
                group={group}
                onElementValueChange={onElementValueChange}
                formReadOnly={formReadOnly}
                showRequiredErrors={showRequiredErrors}
                editable={editable}
                onChangeElementOrder={onChangeElementOrder} />
            </AppendDomainTypeContext>
          </Box>
          {!readOnly && (
            <FooterButtons
              activeGroup={activeGroup}
              dataform={dataform}
              complete={complete}
              onBack={onBackButtonClick}
              onNext={onNextButtonClick} />
          )}
        </Collapse>
      </Paper>
    </DomainTypeTheme>
  )
}