import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import AttributeForm from 'components/attribute/AttributeForm'
import AppendDomainTypeContext from 'components/domainType/AppendDomainTypeContext'
import DomainTypeHeading from 'components/domainType/DomainTypeHeading'
import { OverridableDomainTypeComponent } from 'components/overrides'
import AttributeListSettingPopover from 'components/domainType/AttributeListSettingPopover'
import DialogAlert from 'components/utils/DialogAlert'
import { useContext, useEffect } from 'react'
import { DomainType, DomainTypeInstance } from 'types'
import { FormModeContext } from 'utils/context'
import { useCreate } from 'utils/hooks'

interface Props {
  readonly open: boolean
  readonly domainType: DomainType
  readonly formMode?: 'create' | 'edit'
  readonly bypassApi?: boolean
  onClose(): void
  onCreateSuccess(instance: DomainTypeInstance): void
}

function DefaultCreateDialog({
  open,
  domainType,
  formMode,
  bypassApi = false,
  onClose,
  onCreateSuccess
}: Props): JSX.Element {
  const formModeContext = useContext(FormModeContext)
  formMode = formMode ?? formModeContext ?? 'create'
  const {
    isApiDomainType,
    isCreating,
    allAttributeValues,
    attributeValues,
    subtype,
    pathErrors,
    errorCode,
    createInstance,
    onChange,
    onCreate,
    onReset
  } = useCreate(domainType, onCreateSuccess, bypassApi)
  useEffect(() => {
    if (open) {
      onReset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])
  const verb = isApiDomainType
    ? 'Create'
    : 'Add'
  const instance = createInstance(attributeValues)
  return (
    <FormModeContext.Provider value={formMode}>
      <Dialog
        fullWidth
        maxWidth='md'
        open={open}
        onKeyDown={event => event.stopPropagation()}>
        <DialogTitle>
          <DomainTypeHeading
            domainType={domainType}
            instance={instance}
            isLoading={false}
            title={`${verb}:`} />
        </DialogTitle>
        <DialogAlert
          isLoading={isCreating}
          errorCode={errorCode} />
        <DialogContent>
          <AppendDomainTypeContext newInstances={[[domainType, instance]]}>
            <AttributeForm
              attributeValues={attributeValues}
              pathErrors={pathErrors}
              onChange={onChange}
              onSubmit={isCreating ? undefined : onCreate} />
          </AppendDomainTypeContext>
          <AttributeListSettingPopover
            domainType={subtype}
            allAttributeValues={allAttributeValues}
            setting='CreateForm'
            alwaysShowRequired />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isCreating}
            variant='text'
            onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            disabled={isCreating}
            loading={isCreating}
            onClick={onCreate}>
            {verb}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </FormModeContext.Provider>
  )
}

export default function CreateDialog(props: Props): JSX.Element {
  const {
    domainType
  } = props
  return (
    <OverridableDomainTypeComponent<'createDialog'>
      component='createDialog'
      domainType={domainType}
      props={props}
      DefaultComponent={DefaultCreateDialog} />
  )
}