import { ExpandMore } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, accordionSummaryClasses, Box, FormControlLabel, FormGroup, Switch, Typography } from '@mui/material'
import DataformResultsCell from 'components/attribute/AttributeCell/DataformResultsCell'
import MultiDataformResultsCell from 'components/attribute/AttributeCell/MultiDataformResultsCell'
import SaveDomainTypeSettingButton from 'components/domainType/SaveDomainTypeSettingButton'
import DomainTypeDetailsButton from 'components/utils/DomainTypeDetailsButton'
import Help from 'components/utils/Help'
import { ContextType, useContext, useMemo, useState } from 'react'
import { useStore } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { DomainType, State } from 'types'
import { DomainTypeComponentOverrideContext } from 'utils/context'
import { getOverriddenComponent, isListAttributeValue } from 'utils/helpers'
import { useDomainTypeSetting } from 'utils/hooks'
import { FindPageView, ROW_HEIGHT } from '../FindPage'
import Section from './Section'
import SectionIcon from './SectionIcon'
import { DetailsPageSection, HighlightedRow } from './useDetails'

const EMPTY_DATA_TABLE_HEIGHT = 349 + 17

function calculateSectionHeight(
  section: DetailsPageSection,
  findView: FindPageView,
  domainTypeComponentOverrideContext: ContextType<typeof DomainTypeComponentOverrideContext>,
  domainTypes: Partial<Record<string, DomainType>>
): string | undefined {
  if (findView !== 'table') {
    return undefined
  }
  switch (section.type) {
    case 'summary':
    case 'dataformResults':
    case 'multiDataformResults':
      return undefined
    case 'table': {
      const OverriddenComponent = getOverriddenComponent(
        domainTypes,
        section.domainType,
        domainTypeComponentOverrideContext,
        'tableSection'
      )
      if (OverriddenComponent !== undefined) {
        return undefined
      }
      return `${EMPTY_DATA_TABLE_HEIGHT + (Math.min(((section.rows ?? []).length || 2), 15) - 2) * ROW_HEIGHT}px`
    }
    case 'related':
      return `${EMPTY_DATA_TABLE_HEIGHT}px`
  }
}

interface SectionAccordionProps {
  domainType: DomainType
  section: DetailsPageSection
  isLoading: boolean
  highlightedRow: HighlightedRow | null
  onHighlightRow?(highlightedRow: HighlightedRow | null): void
  enteringSections: string[]
  setEnteringSections(value: string[]): void
  onListItemClick(id: string): void
}

function SectionAccordion({
  domainType,
  section,
  isLoading,
  highlightedRow,
  onHighlightRow,
  enteringSections,
  onListItemClick,
  setEnteringSections
}: SectionAccordionProps): JSX.Element {
  const saveDomainTypeSettingButton = useMemo(() => {
    return section.type !== 'summary' && (
      <SaveDomainTypeSettingButton
        domainType={domainType}
        setting='Sections'
        value={[
          {
            Id: section.id,
            Title: section.title,
            Hidden: section.hidden,
            Expanded: section.expanded
          }
        ]}
        mode='edit'
        title='Section' />
    )
  }, [domainType, section.expanded, section.hidden, section.id, section.title, section.type])
  const domainTypeComponentOverrideContext = useContext(DomainTypeComponentOverrideContext)
  const store = useStore<State>()
  const [findView] = useDomainTypeSetting(section.domainType, 'FindView', 'table')
  const title = useMemo(() => {
    const titleText = (
      <Typography>
        {`${section.title} ${(section.type === 'table' ? `(${(section.rows ?? []).length})` : '')}`}
      </Typography>
    )
    switch (section.type) {
      case 'related':
        return (
          <>
            <Help
              type='domainType'
              domainType={section.domainType}>
              {titleText}
            </Help>
            <DomainTypeDetailsButton id={section.domainType.Id} />
          </>
        )
      case 'dataformResults':
        if (isListAttributeValue(section.attributeValue)) {
          return null
        }
        return (
          <>
            <Help
              type='attribute'
              attribute={section.attributeValue.attribute}>
              {titleText}
            </Help>
            <DataformResultsCell
              attributeValue={section.attributeValue}
              disableTooltip />
          </>
        )
      case 'multiDataformResults':
        if (isListAttributeValue(section.attributeValue)) {
          return null
        }
        return (
          <>
            <Help
              type='attribute'
              attribute={section.attributeValue.attribute}>
              {titleText}
            </Help>
            <MultiDataformResultsCell
              attributeValue={section.attributeValue}
              disableTooltip />
          </>
        )
      case 'table':
        return (
          <>
            <Help
              type='attribute'
              attribute={section.attribute}>
              {titleText}
            </Help>
            <DomainTypeDetailsButton id={section.domainType.Id} />
          </>
        )
    }
    return titleText
  }, [section])
  return (
    <Accordion
      key={section.id}
      elevation={1}
      expanded={!isLoading && section.expanded}
      onChange={(event, isExpanded) => onListItemClick(section.id)}
      sx={{
        '&:before': {
          display: 'none'
        },
        '& .MuiCollapse-wrapper': {
          minHeight: enteringSections.includes(section.id)
            ? calculateSectionHeight(
              section,
              findView,
              domainTypeComponentOverrideContext,
              getAllDomainTypes(store.getState())
            )
            : undefined
        },
        '& .MuiAccordionDetails-root': {
          paddingTop: 0
        }
      }}
      TransitionProps={{
        onEnter: () => setEnteringSections([...enteringSections, section.id]),
        onEntered: () => setEnteringSections(enteringSections.filter(id => id !== section.id)),
        appear: true,
        timeout: 500,
        mountOnEnter: true,
        unmountOnExit: true,
        style: {
          height: 'unset'
        }
      }}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        sx={{
          [`& .${accordionSummaryClasses.content}`]: {
            alignItems: 'center',
            gap: 2
          },
          gap: 2,
          flexDirection: 'row-reverse'
        }}>
        <Typography
          component='span'
          sx={{
            display: 'flex'
          }}>
          <SectionIcon
            section={section}
            domainType={section.domainType} />
        </Typography>
        {title}
        <Box flexGrow={1} />
        {saveDomainTypeSettingButton}
      </AccordionSummary>
      <AccordionDetails>
        {!isLoading && (
          <Section
            section={section}
            isLoading={['related', 'dataformResults', 'multiDataformResults'].includes(section.type)
              && enteringSections.includes(section.id)}
            highlightedRow={highlightedRow}
            onHighlightRow={onHighlightRow} />
        )}
      </AccordionDetails>
    </Accordion>
  )
}

interface Props {
  domainType: DomainType
  isLoading: boolean
  sections: DetailsPageSection[]
  highlightedRow: HighlightedRow | null
  onHighlightRow?(highlightedRow: HighlightedRow | null): void
  onListItemClick(id: string): void
}

export default function ExpandableListView({
  domainType,
  isLoading,
  sections,
  highlightedRow,
  onListItemClick,
  onHighlightRow
}: Props): JSX.Element {
  const [enteringSections, setEnteringSections] = useState<string[]>([])
  const [showHidden, setShowHidden] = useState(false)
  return (
    <div>
      {sections
        .filter(section => !section.hidden)
        .map(section => (
          <SectionAccordion
            key={section.id}
            domainType={domainType}
            section={section}
            isLoading={isLoading}
            highlightedRow={highlightedRow}
            onHighlightRow={onHighlightRow}
            enteringSections={enteringSections}
            setEnteringSections={setEnteringSections}
            onListItemClick={onListItemClick} />
        ))}
      <Box
        p={1}
        paddingRight={0}
        display='flex'
        justifyContent='flex-end'>
        <FormGroup>
          <FormControlLabel
            sx={{ margin: 0 }}
            control={(
              <Switch
                checked={showHidden}
                onChange={(event, value) => setShowHidden(value)} />
            )}
            label='Show Hidden' />
        </FormGroup>
      </Box>
      {showHidden && sections
        .filter(section => section.hidden)
        .map(section => (
          <SectionAccordion
            key={section.id}
            domainType={domainType}
            section={section}
            isLoading={isLoading}
            highlightedRow={highlightedRow}
            onHighlightRow={onHighlightRow}
            enteringSections={enteringSections}
            setEnteringSections={setEnteringSections}
            onListItemClick={onListItemClick} />
        ))}
    </div>
  )
}
