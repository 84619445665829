import { useTheme } from '@mui/material'
import AppendDomainTypeContext from 'components/domainType/AppendDomainTypeContext'
import DomainTypeIcon from 'components/domainType/DomainTypeIcon'
import DomainTypeTheme from 'components/domainType/DomainTypeTheme'
import { OverridableDomainTypeComponent } from 'components/overrides'
import { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Link, generatePath } from 'react-router-dom'
import { getAllDomainTypes, getUser } from 'state/reducers'
import { Attribute, DomainType, DomainTypeInstance, NonListDomainTypeAttributeValue } from 'types'
import { PAGE_URL } from 'utils/constants'
import { DomainTypeSettingsContext } from 'utils/context'
import { getDomainTypeAttributes, getDomainTypeSetting, getHeading, getRootDomainType, getSubtype, getValue, shouldRenderCellAsLink } from 'utils/helpers'
import { useSubtypesCache } from 'utils/hooks'
import DomainTypeTooltip from '../../domainType/DomainTypeTooltip'
import { NarrowedProps } from './AttributeCell'
import CellTooltip from './CellTooltip'

interface ApiDomainTypeCellProps {
  readonly domainTypes: Partial<Record<string, DomainType>>,
  readonly domainType: DomainType,
  readonly attributeValue: NonListDomainTypeAttributeValue,
  readonly idAttribute: Attribute,
  readonly heading: string,
  readonly columnWidth: number | undefined
  readonly newInstances: [DomainType, DomainTypeInstance][]
  readonly newAttributes: Attribute[]
}

function ApiDomainTypeCell({
  domainTypes,
  domainType,
  attributeValue,
  idAttribute,
  heading,
  columnWidth,
  newInstances,
  newAttributes
}: ApiDomainTypeCellProps): JSX.Element | null {
  const theme = useTheme()
  if (attributeValue.value === null) {
    return null
  }
  const rootDomainType = getRootDomainType(domainTypes, domainType)
  const rootDomainTypeName = rootDomainType?.Name
  const id = getValue(attributeValue.value, idAttribute)
  return (
    <DomainTypeTheme domainType={domainType}>
      <DomainTypeTooltip
        domainType={domainType}
        instance={attributeValue.value}>
        <Link
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            width: 'fit-content',
            color: theme.palette.muiPrimary.main
          }}
          to={{
            pathname: generatePath(PAGE_URL.DETAILS, {
              databaseTable: rootDomainType?.DatabaseTable ?? domainType.Name,
              name: rootDomainTypeName ?? domainType.Name,
              id: encodeURIComponent(id?.toString() ?? 'id')
            })
          }}
          state={{
            instance: attributeValue.value
          }}
          onClick={event => event.stopPropagation()}>
          <DomainTypeIcon
            avatar
            domainType={domainType} />
          <AppendDomainTypeContext
            newInstances={newInstances}
            newAttributes={newAttributes}>
            <CellTooltip
              value={heading}
              columnWidth={columnWidth} />
          </AppendDomainTypeContext>
        </Link>
      </DomainTypeTooltip>
    </DomainTypeTheme>
  )
}

type Props = NarrowedProps<NonListDomainTypeAttributeValue>

export function DefaultDomainTypeCell({
  attributeValue,
  columnWidth,
  disableLink = false
}: Props): JSX.Element | null {
  const domainTypes = useSelector(getAllDomainTypes)
  const domainType = domainTypes[attributeValue.attribute.AttributeDomainType]
  const settingsContext = useContext(DomainTypeSettingsContext)
  const user = useSelector(getUser)
  const newInstances = useMemo<[DomainType, DomainTypeInstance][]>(
    () => attributeValue.value === null || domainType === undefined
      ? []
      : [[domainType, attributeValue.value]],
    [attributeValue.value, domainType]
  )
  const newAttributes = useMemo(
    () => [attributeValue.attribute],
    [attributeValue.attribute]
  )
  const subtypesCache = useSubtypesCache()
  if (domainType === undefined || attributeValue.value === null) {
    return null
  }
  const rootDomainType = getRootDomainType(domainTypes, domainType)
  const subtype = getSubtype(domainTypes, rootDomainType ?? domainType, attributeValue.value, subtypesCache) ?? domainType
  const role = getDomainTypeSetting(domainTypes, subtype, 'ViewRole')
  const attributes = getDomainTypeAttributes(domainTypes, subtype)
  const api = getDomainTypeSetting(domainTypes, subtype, 'Api') ?? false
  const idAttribute = attributes.find(attribute => attribute.Name === 'Id')
  const heading = getHeading(settingsContext, domainTypes, subtype, attributeValue.value)
  if (shouldRenderCellAsLink(api, idAttribute, disableLink, user, role)) {
    return (
      <ApiDomainTypeCell
        domainTypes={domainTypes}
        domainType={subtype}
        attributeValue={attributeValue}
        idAttribute={idAttribute}
        heading={heading}
        columnWidth={columnWidth}
        newInstances={newInstances}
        newAttributes={newAttributes} />
    )
  }
  return (
    <DomainTypeTheme domainType={domainType}>
      <DomainTypeTooltip
        domainType={subtype}
        instance={attributeValue.value}
        enableHeadingLink={disableLink}>
        <span
          style={{
            cursor: 'pointer',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            gap: '8px'
          }}>
          <DomainTypeIcon
            avatar
            domainType={subtype} />
          <AppendDomainTypeContext
            newInstances={newInstances}
            newAttributes={newAttributes}>
            <CellTooltip
              value={heading}
              columnWidth={columnWidth} />
          </AppendDomainTypeContext>
        </span>
      </DomainTypeTooltip>
    </DomainTypeTheme>
  )
}

export default function DomainTypeCell(
  props: Props
): JSX.Element | null {
  const domainTypes = useSelector(getAllDomainTypes)
  return (
    <OverridableDomainTypeComponent<'cell'>
      component='cell'
      domainType={domainTypes[props.attributeValue.attribute.AttributeDomainType]}
      props={props}
      DefaultComponent={DefaultDomainTypeCell} />
  )
}
