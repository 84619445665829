import { Box, Grid, Stack, Typography, useTheme } from '@mui/material'
import AttributeCell from 'components/attribute/AttributeCell'
import ButtonsPopover from 'components/domainType/ButtonsPopover'
import DomainTypeDetailsButton from 'components/utils/DomainTypeDetailsButton'
import Help from 'components/utils/Help'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Link, generatePath } from 'react-router-dom'
import { getAllDomainTypes, getUser } from 'state/reducers'
import { AttributeChainValue, DomainType, DomainTypeInstance } from 'types'
import { CELL_AVATAR_BACKGROUND_ALPHA, PAGE_URL } from 'utils/constants'
import { getAttributeChain, getAttributeChainTitleForDisplay, getChainValue, getDomainTypeAttributes, getDomainTypeSetting, getRootDomainType, getTransparentBackgroundColour, getValue, shouldRenderCellAsLink } from 'utils/helpers'
import DomainTypeIcon from '../DomainTypeIcon'
import useDomainTypeSummary, { COLUMN_WIDTH, OverridableStringListSetting } from './useDomainTypeSummary'

interface Props {
  domainType: DomainType
  instance: DomainTypeInstance
  columnsSetting: OverridableStringListSetting
  hideButtons?: boolean
  hideHeading?: boolean
  showSettingPopper?: boolean
  enableHeadingLink?: boolean
  disableCellLinks?: boolean
}

export default function DomainTypeGridSummary({
  domainType,
  instance,
  columnsSetting,
  hideButtons = false,
  hideHeading = false,
  showSettingPopper = false,
  enableHeadingLink = false,
  disableCellLinks = false
}: Props): JSX.Element {
  const domainTypes = useSelector(getAllDomainTypes)
  const {
    subtype,
    heading,
    columns,
    emptyItemsToFixLayout,
    settingPopperPlaceholderGridItem,
    settingPopperButton
  } = useDomainTypeSummary(
    domainType,
    instance,
    columnsSetting
  )
  const user = useSelector(getUser)
  const theme = useTheme()
  const headingContent = useMemo(() => {
    const role = getDomainTypeSetting(domainTypes, subtype, 'ViewRole')
    const attributes = getDomainTypeAttributes(domainTypes, subtype)
    const api = getDomainTypeSetting(domainTypes, subtype, 'Api') ?? false
    const idAttribute = attributes.find(attribute => attribute.Name === 'Id')
    const rootDomainType = getRootDomainType(domainTypes, subtype)
    if (shouldRenderCellAsLink(api, idAttribute, !enableHeadingLink, user, role)) {
      return (
        <>
          <Box flexGrow={1} />
          <h4 style={{
            margin: 0,
            color: theme.palette.text.secondary
          }}>
            {subtype.Title}:
          </h4>
          <Link
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              width: 'fit-content',
              color: theme.palette.muiPrimary.main
            }}
            to={{
              pathname: generatePath(PAGE_URL.DETAILS, {
                databaseTable: rootDomainType?.DatabaseTable ?? domainType.Name,
                name: rootDomainType?.Name ?? domainType.Name,
                id: encodeURIComponent(getValue(instance, idAttribute)?.toString() ?? 'id')
              })
            }}
            state={{
              instance
            }}
            onClick={event => event.stopPropagation()}>
            <DomainTypeIcon
              avatar
              domainType={domainType} />
            {heading}
          </Link>
          <Box flexGrow={1} />
          <Help
            type='domainType'
            domainType={subtype} />
          <DomainTypeDetailsButton id={subtype.Id} />
        </>
      )
    }
    return (
      <>
        <Box flexGrow={1} />
        <h4 style={{
          margin: 0,
          color: theme.palette.text.secondary
        }}>
          {subtype.Title}:
        </h4>
        <DomainTypeIcon
          avatar
          avatarScale={1.2}
          domainType={subtype} />
        <Typography variant='subtitle1'>
          {heading}
        </Typography>
        <Box flexGrow={1} />
        <Help
          type='domainType'
          domainType={subtype} />
        <DomainTypeDetailsButton id={subtype.Id} />
      </>
    )
  }, [domainType, domainTypes, enableHeadingLink, heading, instance, subtype, theme.palette.muiPrimary.main, theme.palette.text.secondary, user])
  return (
    <Stack
      gap={1}
      overflow='hidden'>
      {!hideHeading && (
        <Stack
          display='flex'
          flexDirection='row'
          alignItems='center'
          gap={1}
          p={1}
          pt={2}>
          {headingContent}
          {!hideButtons && (
            <>
              <Box flexGrow={1} />
              <span
                onClick={event => event.stopPropagation()}
                onMouseDown={event => event.stopPropagation()}>
                <ButtonsPopover
                  key='popover'
                  domainType={subtype}
                  target={{
                    type: 'instances',
                    instances: [instance]
                  }}
                  on='TableRow' />
              </span>
            </>
          )}
        </Stack>
      )}
      <Grid
        container
        p={0}
        alignItems='stretch'
        marginBottom='-1px'>
        {columns.flatMap(column => {
          const attributeChain = getAttributeChain(domainTypes, subtype, column)
          if (attributeChain === undefined) {
            return []
          }
          const attribute = attributeChain[attributeChain.length - 1]
          if (attribute === undefined) {
            return []
          }
          const value = getChainValue(instance, attributeChain)
          const attributeChainValue: AttributeChainValue = {
            attribute,
            value
          }
          return [
            <Grid
              key={column}
              container
              item
              columns={4}
              alignItems='stretch'
              flex={`1 1 ${COLUMN_WIDTH}px`}
              borderBottom={theme => `1px solid ${theme.palette.divider}`}>
              <Grid
                item
                p={1}
                xs={2}
                display='flex'
                alignItems='center'
                justifyContent='flex-start'
                textAlign='left'
                sx={{
                  background: theme => getTransparentBackgroundColour(
                    theme.palette.primary.main,
                    theme.palette.mode,
                    CELL_AVATAR_BACKGROUND_ALPHA / 2
                  )
                }}>
                <Help
                  type='attribute'
                  attribute={attribute}>
                  <Typography
                    variant='body2'
                    fontWeight={500}
                    color={theme => theme.palette.text.secondary}>
                    {getAttributeChainTitleForDisplay(attributeChain)}
                  </Typography>
                </Help>
              </Grid>
              <Grid
                item
                display='flex'
                alignItems='center'
                p={1}
                xs={2}>
                <Typography
                  variant='body2'
                  component='div'
                  overflow='hidden'
                  display='flex'
                  width='100%'>
                  <AttributeCell
                    key={column}
                    attributeChainValue={attributeChainValue}
                    disableLink={disableCellLinks} />
                </Typography>
              </Grid>
            </Grid>
          ]
        }).concat(showSettingPopper
          ? [settingPopperPlaceholderGridItem]
          : []
        )}
        {emptyItemsToFixLayout}
      </Grid>
      {showSettingPopper && settingPopperButton}
    </Stack>
  )
}