import { Autocomplete, Stack, TextField, useTheme } from '@mui/material'
import AttributeCell from 'components/attribute/AttributeCell'
import AvatarCornerIcon from 'components/utils/AvatarCornerIcon'
import { useCallback, useMemo } from 'react'
import { EnumeratedValue, NonListEnumAttributeValue } from 'types'
import { DEFAULT_ATTRIBUTE_INPUT_SIZE, DEFAULT_ATTRIBUTE_INPUT_VARIANT } from 'utils/constants'
import { getContrastingColour, getSortedEnumeratedValues, isNullOrUndefined, toErrorText } from 'utils/helpers'
import { NarrowedProps } from '../AttributeInput'
import LabelledInput from '../LabelledInput'

export default function NonListEnumInput({
  attributeValue,
  readOnly,
  disabled,
  pathError,
  textFieldProps,
  disableClearable = false,
  disableSorting = false,
  onChange
}: NarrowedProps<NonListEnumAttributeValue> & { disableClearable?: boolean, disableSorting?: boolean }): JSX.Element {
  const enumeratedValues = useMemo(() => {
    return disableSorting
      ? attributeValue.attribute.EnumeratedType.Values
      : getSortedEnumeratedValues(attributeValue.attribute.EnumeratedType)
  }, [attributeValue.attribute.EnumeratedType, disableSorting])
  const selectedValue = useMemo(() => {
    return enumeratedValues.find(ev => ev.Value === String(attributeValue.value)) ?? null
  }, [attributeValue.value, enumeratedValues])
  const handleChange = useCallback((event: unknown, value: EnumeratedValue | null) => {
    if (onChange === undefined) {
      return
    }
    onChange({
      attribute: attributeValue.attribute,
      value: value?.Value ?? null
    })
  }, [attributeValue.attribute, onChange])
  const theme = useTheme()
  if (readOnly) {
    return (
      <LabelledInput
        label={attributeValue.attribute.Title}
        required={attributeValue.attribute.Required ?? false}>
        {selectedValue === null
          ? <>&nbsp;</>
          : (
            <AttributeCell attributeChainValue={attributeValue} />
          )}
      </LabelledInput>
    )
  }
  return (
    <Autocomplete
      options={enumeratedValues}
      value={selectedValue}
      disabled={disabled}
      readOnly={readOnly}
      onChange={handleChange}
      renderOption={(props, option) => (
        <li {...props}>
          <Stack
            direction='row'
            gap={1}
            alignItems='center'>
            {!isNullOrUndefined(option.Icon) && (
              <AvatarCornerIcon
                background={getContrastingColour(
                  option.Colour ?? theme.palette.primary.main,
                  theme.palette.mode,
                  theme.palette.contrastThreshold
                )}
                icon={option.Icon} />
            )}
            {option.Description}
          </Stack>
        </li>
      )}
      getOptionLabel={(option: EnumeratedValue) => option.Description}
      disableClearable={disableClearable}
      renderInput={params => (
        <TextField
          {...params}
          variant={DEFAULT_ATTRIBUTE_INPUT_VARIANT}
          size={DEFAULT_ATTRIBUTE_INPUT_SIZE}
          required={attributeValue.attribute.Required ?? false}
          error={pathError !== undefined}
          helperText={toErrorText(pathError)}
          label={attributeValue.attribute.Title}
          fullWidth
          {...textFieldProps} />
      )} />
  )
}
