import { Backdrop, Box, CircularProgress, Stack, Toolbar } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import * as E from 'fp-ts/Either'
import * as t from 'io-ts'
import { ReactComponent as PhalanxLogo } from 'phalanx_white.svg'
import queryString from 'query-string'
import { useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Route, Routes, generatePath, useLocation } from 'react-router-dom'
import { fetchAdalPropertiesFulfilled, fetchCompanyFulfilled, fetchPersonFulfilled } from 'state/actions/auth'
import { fetchDomainTypesFulfilled } from 'state/actions/domainTypes'
import { getAllDomainTypes, getCompany, getPerson } from 'state/reducers'
import { Attribute, DomainTypeAction, DomainTypeButton, DomainTypeInstance } from 'types'
import { AdalPropertiesCodec, AttributeCodec, CompanyCodec, DomainTypeActionCodec, DomainTypeButtonCodec, DomainTypeCodec, PersonCodec } from 'utils/codecs'
import { PAGE_URL } from 'utils/constants'
import { useApi } from 'utils/hooks'
import IsAuthenticated from '../containers/auth/IsAuthenticated'
import IsNotAuthenticated from '../containers/auth/IsNotAuthenticated'
import ForgotPasswordForm from './auth/ForgotPasswordForm'
import ResetPasswordForm from './auth/ResetPasswordForm'
import SignInForm from './auth/SignInForm'
import FindDialog from './domainType/FindDialog'
import PushDomainTypeOverriderContext from './domainType/PushDomainTypeOverriderContext'
import PortalRoutes from './navigation/PortalRoutes'
import PrimarySearchAppBar from './navigation/PrimarySearchAppBar'
import SideMenu from './navigation/SideMenu'
import PrettyPrintIoTsErrors from 'io-ts-better-union-error-reporter/dist/PrettyPrintIoTsErrors'

interface Fetched {
  person?: boolean
  company?: string
  domainTypes?: string
}

export default function Layout(): JSX.Element {
  const api = useApi()
  const urlPath = useLocation().pathname
  const dispatch = useDispatch()
  const fetchedRef = useRef<Fetched>({})
  useEffect(() => {
    async function fetchAdalProperties() {
      if (api.isSignedIn) {
        return
      }
      const response = await api.getAdalProperties()
      if (E.isRight(response) && AdalPropertiesCodec.is(response.right)) {
        dispatch(fetchAdalPropertiesFulfilled(response.right))
      }
    }
    fetchAdalProperties()
  }, [api, dispatch])
  useEffect(() => {
    async function fetchPerson() {
      if (!api.isSignedIn) {
        fetchedRef.current.person = false
        return
      }
      if (fetchedRef.current.person === true) {
        return
      }
      fetchedRef.current.person = true
      const response = await api.get('Person', api.user.id)
      if (E.isRight(response) && PersonCodec.is(response.right)) {
        dispatch(fetchPersonFulfilled(response.right))
      }
    }
    fetchPerson()
  }, [api, dispatch])
  useEffect(() => {
    async function fetchCompany() {
      if (!api.isSignedIn
        || (api.user.selectedCompanyId == null)) {
        fetchedRef.current.company = undefined
        return
      }
      if (fetchedRef.current.company === api.user.selectedCompanyId) {
        return
      }
      fetchedRef.current.company = api.user.selectedCompanyId
      const response = await api.get('Company', api.user.selectedCompanyId)
      if (E.isRight(response) && CompanyCodec.is(response.right)) {
        dispatch(fetchCompanyFulfilled(response.right))
      }
    }
    fetchCompany()
  }, [dispatch, api])
  useEffect(() => {
    async function fetchDomainTypes() {
      if (!api.isSignedIn) {
        fetchedRef.current.domainTypes = undefined
        return
      }
      if (fetchedRef.current.domainTypes === api.user.selectedCompanyId) {
        return
      }
      fetchedRef.current.domainTypes = api.user.selectedCompanyId
      const response = await api.all('DomainType')
      if (E.isRight(response) && t.array(DomainTypeCodec).is(response.right)) {
        dispatch(fetchDomainTypesFulfilled(response.right))
      } else if (E.isRight(response)) {
        for (const domainType of response.right) {
          const validationResult = DomainTypeCodec.validate(domainType, [])
          if (E.isLeft(validationResult)) {
            console.error(`error parsing: DomainType: ${JSON.stringify(domainType)}`)
            console.error(PrettyPrintIoTsErrors(validationResult.left))
            logFailedDomainTypeDetails(domainType)
          }
        }

        console.log(t.array(DomainTypeCodec).validate(response.right, []))
      }
    }
    fetchDomainTypes()
  }, [api, dispatch])
  const person = useSelector(getPerson)
  const company = useSelector(getCompany)
  const domainTypes = useSelector(getAllDomainTypes)
  const content = useMemo<JSX.Element>(() => {
    if (person === null
      || company === null
      || Object.values(domainTypes).length === 0) {
      return (
        <Backdrop
          open
          sx={{
            background: theme => theme.palette.spartanBlack.main,
            color: 'white',
            display: 'flex',
            flexDirection: 'column',
            gap: 2
          }}>
          <Box flexGrow={1} />
          <Stack
            direction='row'
            alignItems='center'
            spacing={1}>
            <PhalanxLogo
              height='40'
              width='200' />
          </Stack>
          <CircularProgress
            sx={{
              color: theme => theme.palette.spartanRed.main
            }} />
          <Box flexGrow={2} />
        </Backdrop>
      )
    }
    return (
      <PushDomainTypeOverriderContext
        overriderDetails={{
          overrider: company,
          type: 'company',
          root: 'company',
          path: []
        }}>
        <PushDomainTypeOverriderContext
          overriderDetails={{
            overrider: person,
            type: 'person',
            root: 'person',
            path: []
          }}>
          <PrimarySearchAppBar />
          <SideMenu />
          <Box
            sx={{
              flexGrow: 1,
              height: '100%',
              overflow: 'hidden',
              marginRight: 'calc(-1 * (100vw - 100%))'
            }}>
            <Toolbar />
            <PortalRoutes />
            <FindDialog />
          </Box>
        </PushDomainTypeOverriderContext>
      </PushDomainTypeOverriderContext>
    )
  }, [company, domainTypes, person])
  return (
    <>
      <IsAuthenticated>
        <Box sx={{
          display: 'flex',
          height: '100%',
          overflowX: 'hidden',
          marginRight: 'calc(-1 * (100vw - 100%))'
        }}>
          <CssBaseline enableColorScheme />
          {content}
        </Box>
      </IsAuthenticated>
      <IsNotAuthenticated>
        <Routes>
          <Route
            path={PAGE_URL.SIGNIN}
            element={<SignInForm />} />
          <Route
            path={generatePath(PAGE_URL.RESET_PASSWORD, {
              username: ':username',
              token: ':token'
            })}
            element={<ResetPasswordForm />} />
          <Route
            path={PAGE_URL.FORGOT_PASSWORD}
            element={<ForgotPasswordForm />} />
          <Route
            path='*'
            element={
              <Navigate
                to={{
                  pathname: PAGE_URL.SIGNIN,
                  search: queryString.stringify({
                    redirect: urlPath
                  })
                }}
                replace />
            } />
        </Routes>
      </IsNotAuthenticated>
    </>
  )
}
function logFailedDomainTypeDetails(domainType: DomainTypeInstance) {
  logTypeSpecificErrors<Attribute>(domainType.Attributes, AttributeCodec, 'Attribute')
  logTypeSpecificErrors<DomainTypeButton>(domainType.Buttons, DomainTypeButtonCodec, 'Button')
  logTypeSpecificErrors<DomainTypeAction>(domainType.Actions, DomainTypeActionCodec, 'Action')
}

function logTypeSpecificErrors<T>(entites: unknown, codec: t.Type<T, T, unknown>, name: string): void {
  if (t.array(t.unknown).is(entites)) {
    if (entites.length < 1) {
      return
    }
    for (const entity of entites) {
      const result = codec.decode(entity)
      if (E.isLeft(result)) {
        console.error(`error parsing: ${name}: ${JSON.stringify(entity)}`)
        console.error(PrettyPrintIoTsErrors(result.left))
      }
    }
  }
}
