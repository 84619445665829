import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { DomainTypeAttribute, DomainTypeInstance, ListAttribute, NonListContextRefAttributeValue } from 'types'
import DomainTypeContext from 'utils/context/DomainTypeContext'
import { applyAllFilters } from 'utils/filters'
import { getCachedSubtypes, getDomainTypeAttributes, getDomainTypeSetting, getValue, isDomainTypeListAttribute } from 'utils/helpers'
import { useFilterContext } from './useFilterContext'
import { useSubtypesCache } from './useSubtypesCache'

interface UseContextRefOutput {
  readonly contextAttribute: ListAttribute<DomainTypeAttribute>
  readonly contextList: DomainTypeInstance[]
  readonly contextValue: DomainTypeInstance | null
}

export function useContextRef(attributeValue: NonListContextRefAttributeValue): UseContextRefOutput | null {
  const { instances: context } = useContext(DomainTypeContext)
  const domainTypes = useSelector(getAllDomainTypes)
  const filterContext = useFilterContext()
  const subtypesCache = useSubtypesCache()
  if (context.length === 0) {
    return null
  }
  const contextDomainType = domainTypes[attributeValue.attribute.ContextDomainType]
  if (contextDomainType === undefined) {
    return null
  }
  const childDomainTypes = getCachedSubtypes(domainTypes, contextDomainType, subtypesCache)
  const contextItem = [...context]
    .reverse()
    .find(([domainType]) => childDomainTypes.includes(domainType))
  if (contextItem === undefined) {
    return null
  }
  const [domainType, instance] = contextItem
  const contextAttribute = getDomainTypeAttributes(domainTypes, domainType)
    .filter(isDomainTypeListAttribute)
    .find(a => a.Name === attributeValue.attribute.ContextAttributeName)
  if (contextAttribute === undefined) {
    return null
  }
  const contextAttributeDomainType = domainTypes[contextAttribute.AttributeDomainType]
  if (contextAttributeDomainType === undefined) {
    return null
  }
  const contextList = getValue(instance, contextAttribute)
    ?.filter(item => applyAllFilters(
      domainTypes,
      contextAttributeDomainType,
      item,
      attributeValue.attribute.Filters ?? [],
      filterContext
    ))
  if (contextList === undefined) {
    return null
  }
  const identifier = getDomainTypeSetting(domainTypes, contextAttributeDomainType, 'Identifier') ?? 'Id'
  const contextValue = contextList
    .find(item => item[identifier] === attributeValue.value) ?? null
  return {
    contextAttribute,
    contextList,
    contextValue
  }
}