import { ArrowDownwardOutlined, ArrowUpwardOutlined } from '@mui/icons-material'
import { Chip, ChipProps, Stack } from '@mui/material'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { DomainType, Sort } from 'types'
import { PATH_SEPARATOR } from 'utils/constants'
import { getAttributeChain, getTransparentBackgroundColour } from 'utils/helpers'

interface Props {
  readonly domainType: DomainType
  readonly sort: Sort
  readonly ChipProps?: Partial<ChipProps>
}

export default function SortChip({
  domainType,
  sort,
  ChipProps
}: Props): JSX.Element {
  const domainTypes = useSelector(getAllDomainTypes)
  const attributeChain = getAttributeChain(domainTypes, domainType, sort.Property)
  const propertyLabel = useMemo(() => {
    return attributeChain?.map(attribute => attribute.Title).join(PATH_SEPARATOR)
  }, [attributeChain])
  return (
    <Chip
      variant='filled'
      size='medium'
      sx={{
        background: theme => getTransparentBackgroundColour(
          theme.palette.text.primary,
          theme.palette.mode
        )
      }}
      label={(
        <Stack
          direction='row'
          gap={1}
          alignItems='center'>
          <span>
            {propertyLabel}
          </span>
          {sort.Direction === 'asc'
            ? <ArrowUpwardOutlined
              sx={{ color: 'text.secondary' }}
              fontSize='small' />
            : <ArrowDownwardOutlined
              sx={{ color: 'text.secondary' }}
              fontSize='small' />}
        </Stack>
      )}
      {...ChipProps} />
  )
}