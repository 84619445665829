import { Chip, ChipProps, MenuItem, outlinedInputClasses, Select, selectClasses, Stack } from '@mui/material'
import AttributeCell from 'components/attribute/AttributeCell'
import { ReactNode, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { AttributeValue, DomainType, Filter } from 'types'
import { PATH_SEPARATOR } from 'utils/constants'
import { filterOperators, makeInputAttributeValue } from 'utils/filters'
import { getAttributeChain, getTransparentBackgroundColour, isNotNullOrUndefined, isNullOrUndefined } from 'utils/helpers'
import { useFilterContext } from 'utils/hooks'

interface Props {
  readonly domainType: DomainType
  readonly filter: Filter
  readonly valueNode?: ReactNode
  readonly ChipProps?: Partial<ChipProps>
  readonly permanent?: boolean
  readonly permanentFilterOptions?: string[]
  readonly selectedPermanentFilterOption?: number
  onPermanentFilterOptionChange?(index: number): void
}

export default function FilterChip({
  domainType,
  filter,
  valueNode,
  ChipProps,
  permanent = false,
  permanentFilterOptions = [],
  selectedPermanentFilterOption = 0,
  onPermanentFilterOptionChange
}: Props): JSX.Element {
  const domainTypes = useSelector(getAllDomainTypes)
  const filterContext = useFilterContext()
  const attributeChain = getAttributeChain(domainTypes, domainType, filter.Property)
  const lastAttribute = useMemo(() => {
    if (attributeChain === undefined) {
      return null
    }
    return attributeChain[attributeChain.length - 1]
  }, [attributeChain])
  const filterOperator = useMemo(() => {
    if (isNullOrUndefined(lastAttribute)) {
      return null
    }
    return filterOperators
      .find(f => f.operator === filter.Operator
        && f.canApply(lastAttribute))
  }, [filter.Operator, lastAttribute])

  const attributeValue = useMemo<AttributeValue | null>(() => {
    return isNotNullOrUndefined(filterOperator) && isNotNullOrUndefined(lastAttribute)
      ? makeInputAttributeValue(
        lastAttribute,
        filterOperator,
        filter.Value,
        filterContext,
        domainTypes
      )
      : null
  }, [domainTypes, filter.Value, filterContext, filterOperator, lastAttribute])
  const propertyLabel = useMemo(() => {
    if (!permanent) {
      return attributeChain?.map(attribute => attribute.Title).join(PATH_SEPARATOR)
    }
    return (
      <Select
        size='small'
        disableUnderline
        value={selectedPermanentFilterOption}
        onChange={(event => {
          onPermanentFilterOptionChange?.(Number(event.target.value))
        })}
        sx={{
          fontSize: 'inherit',
          mr: -1.5,
          [`& .${selectClasses.select}`]: {
            pt: 1,
            pb: 1,
            pl: 0
          },
          [`& .${outlinedInputClasses.notchedOutline}`]: {
            border: 'none'
          }
        }}>
        {permanentFilterOptions.map((value, index) => (
          <MenuItem
            key={index}
            value={index}>
            {value}
          </MenuItem>
        ))}
      </Select>
    )
  }, [attributeChain, onPermanentFilterOptionChange, permanent, permanentFilterOptions, selectedPermanentFilterOption])
  return (
    <Chip
      variant='filled'
      size='medium'
      sx={{
        background: theme => getTransparentBackgroundColour(
          theme.palette.text.primary,
          theme.palette.mode
        )
      }}
      label={(
        <Stack
          direction='row'
          gap={1}
          alignItems='center'>
          <span>
            {propertyLabel}
          </span>
          <span>
            {filterOperator?.label ?? filter.Operator}
          </span>
          {valueNode ?? (attributeValue !== null && (
            <span>
              {attributeValue.attribute.AttributeType === 'string' && <>&quot;</>}
              <AttributeCell
                attributeChainValue={attributeValue}
                disableLink />
              {attributeValue.attribute.AttributeType === 'string' && <>&quot;</>}
            </span>
          ))}
        </Stack>
      )}
      {...ChipProps} />
  )
}