import { DeleteOutlined } from '@mui/icons-material'
import { Chip, chipClasses, CircularProgress, ClickAwayListener, ListItemIcon, ListItemText, MenuItem, MenuList, Popover, Portal, Stack } from '@mui/material'
import IsInRole from 'components/auth/IsInRole'
import SaveDomainTypeSettingButton from 'components/domainType/SaveDomainTypeSettingButton'
import * as E from 'fp-ts/Either'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { ApiError, DomainType, Query } from 'types'
import { OverriderDetails } from 'utils/context/DomainTypeOverriderContext'
import { getRootDomainType, getTransparentBackgroundColour } from 'utils/helpers'
import { SignedInApi, useApi, useCancellableApiSession } from 'utils/hooks'
import { v4 } from 'uuid'

interface QueryChipProps {
  readonly domainType: DomainType
  readonly query: Query
  readonly active: boolean
  readonly overriderDetails?: OverriderDetails
  fetchTotal(api: SignedInApi, query: Query): Promise<E.Either<ApiError, number>>
  onApplyQuery(query: Query): void
}

function QueryChip({
  domainType,
  query,
  active,
  overriderDetails,
  fetchTotal,
  onApplyQuery
}: QueryChipProps): JSX.Element {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const api = useApi()
  const search = useCancellableApiSession(api)
  const domainTypes = useSelector(getAllDomainTypes)
  const rootDomainType = getRootDomainType(domainTypes, domainType)
  const [total, setTotal] = useState<number | null>(null)
  const [currentDomainType, setCurrentDomainType] = useState(domainType)
  const [performedQuery, setPerformedQuery] = useState(false)
  useEffect(() => {
    if (performedQuery === true && currentDomainType === domainType) {
      return
    }
    const apiSession = search.cancelPreviousAndStartNew()
    async function doFetch() {
      if (rootDomainType === null || !apiSession.isSignedIn) {
        return search.cancel
      }
      const response = await fetchTotal(apiSession, query)
      if (E.isRight(response)) {
        setPerformedQuery(true)
        setCurrentDomainType(domainType)
        setTotal(response.right)
      }
    }
    doFetch()
    return search.cancel
  }, [api, fetchTotal, query, rootDomainType, search, performedQuery, currentDomainType, domainType])
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number
    mouseY: number
  } | null>(null)
  const anchorPosition = useMemo(() => {
    return contextMenu !== null
      ? {
        top: contextMenu.mouseY,
        left: contextMenu.mouseX
      }
      : undefined
  }, [contextMenu])
  return (
    <>
      <Chip
        key={query.Id}
        sx={{
          [`& .${chipClasses.label}`]: {
            display: 'flex',
            gap: 1,
            alignItems: 'center'
          },
          background: theme => getTransparentBackgroundColour(
            theme.palette.text.primary,
            theme.palette.mode
          )
        }}
        label={(
          <>
            <span>{query.Title}</span>
            {total === null
              ? (
                <CircularProgress
                  size='0.8125rem'
                  sx={{
                    marginRight: '-3px',
                    marginLeft: '-3px'
                  }}
                  color='inherit' />
              )
              : total}
          </>
        )}
        variant='filled'
        color={active
          ? 'primary'
          : undefined}
        onClick={event => {
          event.stopPropagation()
          onApplyQuery(query)
        }}
        onContextMenu={event => {
          if (query.Id === 'all') {
            return
          }
          event.preventDefault()
          setContextMenu(
            contextMenu === null
              ? {
                mouseX: event.clientX - 2,
                mouseY: event.clientY - 4
              }
              : null
          )
        }} />
      <ClickAwayListener
        onClickAway={() => setContextMenu(null)}>
        <Popover
          open={anchorPosition !== undefined}
          onClose={event => setContextMenu(null)}
          onClick={event => event.stopPropagation()}
          anchorReference='anchorPosition'
          anchorPosition={anchorPosition}>
          <MenuList>
            <MenuItem
              onClick={() => setDeleteDialogOpen(true)}>
              <ListItemIcon>
                <DeleteOutlined />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          </MenuList>
        </Popover>
      </ClickAwayListener>
      <Portal>
        <span
          onClick={event => event.stopPropagation()}
          onFocus={event => event.stopPropagation()}>
          <SaveDomainTypeSettingButton
            domainType={domainType}
            setting='Queries'
            value={[query]}
            disabled={query.Id === 'all'}
            mode='delete'
            saveTo={overriderDetails ?? 'domainType'}
            title='Query'
            dialogOpen={deleteDialogOpen}
            onChangeDialogOpen={open => {
              setDeleteDialogOpen(open)
              setContextMenu(null)
            }} />
        </span>
      </Portal>
    </>
  )
}

interface Props {
  domainType: DomainType
  currentQuery: Query
  overriderQueries: [Query, OverriderDetails][]
  domainTypeQueries: Query[]
  hideAddButton?: boolean
  renderContainer?: boolean
  fetchTotal(api: SignedInApi, query: Query): Promise<E.Either<ApiError, number>>
  onApplyQuery(query: Query): void
}

export default function QueryChips({
  domainType,
  currentQuery,
  overriderQueries,
  domainTypeQueries,
  hideAddButton = false,
  renderContainer = false,
  fetchTotal,
  onApplyQuery
}: Props): JSX.Element {
  const value = useMemo(() => [
    {
      ...currentQuery,
      Id: v4(),
      Title: ''
    }
  ], [currentQuery])
  const [saveDialogOpen, setSaveDialogOpen] = useState(false)

  const contents = useMemo(() => (
    <>
      {domainTypeQueries
        .map(query => (
          <IsInRole
            role={query.Role ?? null}
            key={`${query.Id}Role`}>
            <QueryChip
              key={query.Id}
              domainType={domainType}
              query={query}
              active={query.Id === currentQuery.Id}
              fetchTotal={fetchTotal}
              onApplyQuery={onApplyQuery} />
          </IsInRole>
        ))}
      {overriderQueries
        .map(([query, overriderDetails]) => (
          <IsInRole
            role={query.Role ?? null}
            key={`${query.Id}Role`}>
            <QueryChip
              key={query.Id}
              domainType={domainType}
              query={query}
              active={query.Id === currentQuery.Id}
              overriderDetails={overriderDetails}
              fetchTotal={fetchTotal}
              onApplyQuery={onApplyQuery} />
          </IsInRole>
        ))}
      {!hideAddButton && (
        <>
          <Chip
            key={currentQuery.Id}
            label='Add Query'
            variant='outlined'
            color='primary'
            onClick={() => setSaveDialogOpen(true)} />
          <SaveDomainTypeSettingButton
            domainType={domainType}
            setting='Queries'
            value={value}
            title='Query'
            onSaveSuccess={value => {
              if (value?.length === 1) {
                const firstValue = value[0]
                if (firstValue !== undefined) {
                  onApplyQuery(firstValue)
                }
              }
            }}
            dialogOpen={saveDialogOpen}
            onChangeDialogOpen={setSaveDialogOpen} />
        </>
      )}
    </>
  ), [currentQuery.Id, domainType, domainTypeQueries, fetchTotal, hideAddButton, onApplyQuery, overriderQueries, saveDialogOpen, value])
  if (!renderContainer) {
    return contents
  }
  return (
    <Stack
      direction='row'
      gap={1}
      alignItems='center'
      flexWrap='wrap'>
      {contents}
    </Stack>
  )
}
