import * as E from 'fp-ts/Either'
import * as t from 'io-ts'
import { useEffect, useMemo, useState } from 'react'
import { MultiDataformResultsAttributeValue } from 'types'
import { Dataform, MultiResult, MultiResults, Results } from 'types/dataform'
import { limitSearchPageSize } from 'utils/api'
import { DataformCodec } from 'utils/codecs/dataform'
import { stringifyFilterValue } from 'utils/filters'
import { isListAttributeValue, isNullOrUndefined } from 'utils/helpers'
import { getBySelectionCriteria, getEquipmentFromTask } from 'utils/helpers/dataform'
import { useCancellableApiSession, useDomainTypeContextInstance } from '.'
import { SignedInApi, useApi } from './useApi'

function getResults(
  attributeValue: MultiDataformResultsAttributeValue,
  dataformName: string
): Results | null {
  return attributeValue.value === null || Array.isArray(attributeValue.value)
    ? null
    : attributeValue.value[dataformName] ?? null
}

export function useMultiDataformResults(
  attributeValue: MultiDataformResultsAttributeValue,
  readOnly: boolean
): [MultiResults, string | undefined] {
  const api = useApi()
  const [alreadyAnsweredDataforms, setAlreadyAnsweredDataforms] = useState<(readonly [string, Dataform][]) | null>(null)
  const [selectionDataforms, setSelectionDataforms] = useState<(readonly [string, Dataform])[] | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const existingResults = useMemo(() => {
    return attributeValue.value === null || Array.isArray(attributeValue.value)
      ? []
      : Object.entries(attributeValue.value)
        .filter((entry): entry is [string, Results] => !isNullOrUndefined(entry[1]))
  }, [attributeValue.value])
  const work = useDomainTypeContextInstance('Work', 'Work')
  const task = useDomainTypeContextInstance('Task', 'Task')
  const context = useMemo(() => ({
    Work: work,
    Task: task,
    ...getEquipmentFromTask(task)
  }), [task, work])
  const loadDataforms = useCancellableApiSession(api)
  useEffect(() => {
    const apiSession = loadDataforms.cancelPreviousAndStartNew()
    if (alreadyAnsweredDataforms !== null) {
      return
    }
    async function loadByName(
      api: SignedInApi
    ): Promise<E.Either<string, (readonly [string, Dataform])[]>> {
      const response = await api
        .search(
          'Dataform',
          'Dataform',
          [],
          [
            {
              Property: 'Name',
              Operator: 'in',
              Value: stringifyFilterValue(attributeValue.attribute.DataformNames)
            }
          ],
          [],
          1,
          limitSearchPageSize(attributeValue.attribute.DataformNames?.length ?? 0)
        )
      if (E.isLeft(response)) {
        return E.left('Failed to load dataforms')
      }
      const result = t.array(DataformCodec).decode(response.right.results)
      if (E.isLeft(result)) {
        return E.left('Failed to load dataforms')
      }
      return E.right(result.right
        .map(dataform => [dataform.Name, dataform] as const))
    }
    async function loadBySelectionCriteria(
      api: SignedInApi
    ): Promise<E.Either<string, (readonly [string, Dataform])[]>> {
      if (isNullOrUndefined(attributeValue.attribute.SelectionCategory)) {
        return E.left('Selection Category is required')
      }
      if (isNullOrUndefined(attributeValue.attribute.SelectionSubcategory)) {
        return E.left('Selection Subcategory is required')
      }
      const dataforms = await getBySelectionCriteria(
        api,
        attributeValue.attribute.SelectionCategory,
        attributeValue.attribute.SelectionSubcategory,
        context,
        attributeValue.attribute.DefaultSelectionProperties ?? undefined,
        attributeValue.attribute.Purpose ?? undefined
      )
      return E.right(dataforms
        .map(({ key, dataform }) => [key, dataform] as const))
    }
    async function load() {
      if (isListAttributeValue(attributeValue)) {
        return
      }
      if (!apiSession.isSignedIn) {
        return
      } else {
        const alreadyAnsweredResponse = await apiSession.search(
          'Dataform',
          'Dataform',
          [],
          [
            {
              Property: 'Id',
              Operator: 'in',
              Value: stringifyFilterValue(existingResults
                .map(([key, results]) => results.DataformId))
            }
          ],
          [],
          1,
          limitSearchPageSize(existingResults.length),
          true
        )
        const selectionResponse = attributeValue.attribute.SelectBy === 'name'
          ? await loadByName(apiSession)
          : await loadBySelectionCriteria(apiSession)
        if (E.isRight(alreadyAnsweredResponse)) {
          if (t.array(DataformCodec).is(alreadyAnsweredResponse.right.results)) {
            setAlreadyAnsweredDataforms(alreadyAnsweredResponse.right.results
              .map((dataform: Dataform) => [
                existingResults
                  .find(([key, results]) => results.DataformId === dataform.Id)?.[0],
                dataform
              ] as const)
              .filter((entry): entry is [string, Dataform] => entry[0] !== undefined))
          } else {
            setErrorMessage('One or more of the dataforms is in an invalid format')
          }
        } else {
          setErrorMessage('Failed to load previously answered dataforms')
        }
        if (readOnly && existingResults.length > 0) {
          setSelectionDataforms([])
          return
        }
        if (E.isRight(selectionResponse)) {
          setSelectionDataforms(selectionResponse.right)
        } else {
          setErrorMessage(selectionResponse.left)
        }
      }
    }
    load()
    return loadDataforms.cancel
  }, [alreadyAnsweredDataforms, attributeValue, context, existingResults, loadDataforms, readOnly])
  const hasLoaded = useMemo(() => {
    return alreadyAnsweredDataforms !== null
      && selectionDataforms !== null
      && errorMessage === undefined
  }, [alreadyAnsweredDataforms, errorMessage, selectionDataforms])
  const dataformsWithResults = useMemo(() => {
    const multiResults = (selectionDataforms ?? [])
      .map(([key, dataform]): MultiResult => {
        return {
          key,
          dataform: alreadyAnsweredDataforms
            ?.find(([_, alreadyAnsweredDataform]) => alreadyAnsweredDataform.Name === dataform.Name)?.[1] ?? dataform,
          results: getResults(attributeValue, key)
        } as const
      })
      .concat((alreadyAnsweredDataforms ?? [])
        .filter(([_, dataform]) => {
          return !selectionDataforms?.find(([_, selectionDataform]) => selectionDataform.Name === dataform.Name)
        })
        .map(([key, dataform]) => {
          return {
            key,
            dataform,
            results: getResults(attributeValue, key)
          }
        }))
    if (!hasLoaded) {
      return multiResults
    }
    return multiResults
      .concat(existingResults
        .filter(([existingKey]) => !multiResults.find(({ key }) => existingKey === key))
        .map(([key, results]) => ({
          key,
          dataform: null,
          results,
          errorMessage: 'The dataform for which these results were captured no longer exists'
        })))
  }, [alreadyAnsweredDataforms, attributeValue, existingResults, hasLoaded, selectionDataforms])
  const emptyErrorMessage = useMemo(() => {
    if (attributeValue.attribute.SelectBy === 'selectionCriteria') {
      return 'No dataforms were found matching the selection criteria'
    }
    if ((attributeValue.attribute.DataformNames?.length ?? 0) === 0) {
      return 'Dataform Names is required'
    }
    return `No dataforms were found with names ${attributeValue.attribute.DataformNames?.join(', ')}`
  }, [attributeValue.attribute.DataformNames, attributeValue.attribute.SelectBy])
  if (isListAttributeValue(attributeValue)) {
    return [[], errorMessage]
  }
  if (hasLoaded && dataformsWithResults.length === 0) {
    return [dataformsWithResults, emptyErrorMessage]
  }
  return [dataformsWithResults, errorMessage]
}