import { Autocomplete, Box, Dialog, DialogContent, DialogTitle, Stack, TextField, Typography, useTheme } from '@mui/material'
import { FormEvent, Fragment, useCallback, useState } from 'react'
import { FIND_DIALOG_KEY, OPERATIONS_DIALOG_KEY } from 'utils/constants'
import { getContrastingColour } from 'utils/helpers'
import { useMenuSections, useNavigate, useWindowEventListener } from 'utils/hooks'
import AvatarCornerIcon from '../utils/AvatarCornerIcon'

export default function FindDialog(): JSX.Element {
  const [open, setOpen] = useState(false)
  const onKeyDown = useCallback((event: KeyboardEvent) => {
    if (open || event.key !== FIND_DIALOG_KEY) {
      return
    }
    event.preventDefault()
    setOpen(true)
  }, [open])
  useWindowEventListener('keydown', onKeyDown)
  const menuSections = useMenuSections()
  const menuItems = menuSections
    .flatMap(section => section.items
      .map(item => [section.category, item] as const))
  const theme = useTheme()
  const navigate = useNavigate()
  return (
    <Dialog
      fullWidth
      maxWidth='md'
      open={open}
      onClose={() => setOpen(false)}
      onKeyDown={event => {
        if (event.key === OPERATIONS_DIALOG_KEY) {
          setOpen(false)
        }
      }}>
      <DialogTitle>
        <h3
          style={{
            margin: 0,
            color: theme.palette.text.primary
          }}>
          Find
        </h3>
      </DialogTitle>
      <DialogContent>
        <Box
          component='form'
          display='flex'
          flexDirection='column'
          gap={1}
          autoComplete='off'
          onSubmit={(event: FormEvent) => event.preventDefault()}>
          <Autocomplete
            sx={{ marginTop: 1 }}
            size='small'
            openOnFocus
            onClose={(event, reason) => {
              setOpen(false)
              if (reason === 'selectOption') {
                return
              }
              if (document.activeElement instanceof HTMLElement) {
                document.activeElement.blur()
              }
              const autoFocusInput = document.querySelector('input[auto-focus="yes"]')
              if (autoFocusInput instanceof HTMLInputElement) {
                autoFocusInput.focus()
              }
            }}
            autoHighlight
            onChange={(event, value, reason) => {
              if (value === null) {
                return
              }
              setOpen(false)
              const [, menuItem] = value
              navigate.toFindPage(menuItem.domainType)
            }}
            groupBy={([category]) => category?.Value ?? ''}
            renderGroup={props => {
              const section = menuSections
                .find(section => section.category?.Value === props.group)
              const colour = getContrastingColour(
                section?.category?.Colour ?? theme.palette.primary.main,
                theme.palette.mode,
                theme.palette.contrastThreshold
              )
              return (
                <Fragment key={props.key}>
                  <li>
                    <Stack
                      direction='row'
                      gap={1}
                      p={1}
                      alignItems='center'>
                      <AvatarCornerIcon
                        avatarScale={1.5}
                        background={colour}
                        icon={section?.category?.Icon ?? ''} />
                      <Typography
                        sx={{
                          fontWeight: 500,
                          color: theme.palette.text.secondary
                        }}>
                        {section?.category?.Description ?? 'Other'}
                      </Typography>
                    </Stack>
                  </li>
                  {props.children}
                </Fragment>
              )
            }}
            renderOption={(props, [category, menuItem]) => (
              <li
                {...props}>
                <Stack
                  direction='row'
                  gap={1}
                  alignItems='center'>
                  {menuItem.icon}
                  {menuItem.text}
                </Stack>
              </li>
            )}
            value={null}
            getOptionLabel={([category, menuItem]) => menuItem.text}
            options={menuItems}
            renderInput={(params) => (
              <TextField
                {...params}
                inputRef={node => open && node?.focus()}
                label='Find' />
            )} />
        </Box>
      </DialogContent>
    </Dialog>
  )
}