import { Chip, Icon, useTheme } from '@mui/material'
import { NonListEnumAttributeValue } from 'types'
import { getContrastingColour, getTransparentBackgroundColour, isNullOrUndefined } from 'utils/helpers'
import { NarrowedProps } from './AttributeCell'
import CellTooltip from './CellTooltip'

export default function EnumCell({
  attributeValue,
  columnWidth
}: NarrowedProps<NonListEnumAttributeValue>): JSX.Element | null {
  const enumeratedType = attributeValue.attribute.EnumeratedType
  const enumeratedValue = enumeratedType.Values.find(ev => ev.Value === String(attributeValue.value))
  const theme = useTheme()
  if (isNullOrUndefined(enumeratedValue)) {
    return null
  }
  const color = isNullOrUndefined(enumeratedValue.Colour)
    ? theme.palette.text.primary
    : getContrastingColour(
      enumeratedValue.Colour,
      theme.palette.mode,
      theme.palette.contrastThreshold
    )
  const background = getTransparentBackgroundColour(
    color,
    theme.palette.mode
  )
  return (
    <Chip
      sx={{
        pointerEvents: 'none',
        background,
        color
      }}
      icon={isNullOrUndefined(enumeratedValue.Icon)
        ? undefined
        : (
          <Icon
            style={{
              color,
              fontSize: '16px'
            }}>
            {enumeratedValue.Icon}
          </Icon>
        )}
      label={(
        <CellTooltip
          value={enumeratedValue.Description}
          columnWidth={columnWidth} />
      )}
      size='small' />
  )
}
