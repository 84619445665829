
export const LOCALE = 'en'

export const PAGE_URL = {
  HOME: '/',
  SIGNIN: '/',
  FIND: '/find/:databaseTable/:name',
  DETAILS: '/details/:databaseTable/:name/:id',
  EXPORT_DOMAIN_TYPES: '/exportDomainTypes',
  NOT_HOME: '/:path',
  SIGNOUT: '/signout',
  RESET_PASSWORD: '/resetPassword/:username/:token',
  FORGOT_PASSWORD: '/forgotPassword'
}

export const TOOLTIP_DELAY = 250

export const NOT_ALL_REQUIRED_VALUES_HAVE_BEEN_ENTERED = 'Not all required values have been entered'

export const PAGE_PADDING = 2

export const DATAFORM_DATE_ELEMENT_FORMAT = 'yyyy-MM-dd'
export const DATAFORM_TIME_ELEMENT_FORMAT = 'HH:mm'

export const PATH_SEPARATOR = ' ▸ '

export const OPERATIONS_DIALOG_KEY = '.'
export const FIND_DIALOG_KEY = '/'

export const DATAFORM_RESULTS_DOMAIN_TYPE_NAME = 'DataformResults'

export const ATTRIBUTE_PATH_SEPARATOR = '_'

export const CELL_AVATAR_BACKGROUND_ALPHA = 0.1

export const MIN_SIDE_PANEL_WIDTH = 400
export const MIN_PAGE_WIDTH = 700

export const SIDE_MENU_AVATAR_SCALE = 4 / 3

export const DATAFORM_RESULTS_STATUSES = [
  'Partially Completed',
  'Completed - Unexpected',
  'Completed - OK',
  'Not Filled In'
] as const

export const ATTRIBUTE_LIST_SETTING_POPOVER_WIDTH = 500

export const SYSTEM_COMPANY_ID = 'system'

export const DEFAULT_ATTRIBUTE_INPUT_VARIANT = 'standard'
export const DEFAULT_ATTRIBUTE_INPUT_SIZE = 'small'

export const FORMS_AUTHENTICATION_COOKIE_NAME = '.ASPXAUTH'