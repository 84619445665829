import { Box, Button, FormControl, FormControlLabel, IconButton, Stack, Switch, TextFieldProps, useTheme } from '@mui/material'
import { GridAddIcon, GridCloseIcon, GridColumns } from '@mui/x-data-grid'
import EnumInput from 'components/attribute/AttributeInput/EnumInput'
import { BooleanFromString } from 'io-ts-types'
import { Dispatch, MutableRefObject, useEffect, useMemo, useReducer } from 'react'
import { Sort } from 'types'
import { useLocalStorageState } from 'utils/hooks'
import sortsReducer, { SortsAction, addSort, deleteSort, editSort } from './sortsReducer'

interface Props {
  readonly columns: GridColumns
  onClose(): void
  readonly sortsRef: MutableRefObject<{
    sorts: Sort[]
  }>
}

const textFieldProps: Partial<TextFieldProps> = {}

interface SortFormProps {
  readonly sort: Sort
  readonly index: number
  readonly sortableColumns: GridColumns
  readonly includeHiddenColumns: boolean
  readonly dispatchSortsAction: Dispatch<SortsAction>
}

function SortForm({
  sort,
  index,
  sortableColumns,
  includeHiddenColumns,
  dispatchSortsAction
}: SortFormProps): JSX.Element {
  return (
    <Stack
      direction='row'
      p={1}
      gap={1}
      alignItems='flex-end'>
      <FormControl
        sx={{
          flexShrink: 0,
          justifyContent: 'flex-end',
          marginRight: 0.5,
          marginBottom: 0.2
        }}>
        <IconButton
          title='Delete'
          onClick={() => dispatchSortsAction(deleteSort(index))}>
          <GridCloseIcon fontSize='small' />
        </IconButton>
      </FormControl>
      <FormControl
        sx={{
          width: 300
        }}>
        <EnumInput
          attributeValue={{
            attribute: {
              Name: 'columnField',
              Title: 'Column',
              AttributeType: 'enum',
              EnumeratedType: {
                ExternalId: '',
                GlobalExternalId: '',
                Values: sortableColumns.map(column => ({
                  Id: column.field,
                  Value: column.field,
                  Description: column.headerName ?? column.field
                }))
              },
              List: false
            },
            value: sort.Property
          }}
          readOnly={false}
          textFieldProps={textFieldProps}
          disableClearable
          disableSorting={!includeHiddenColumns}
          onChange={attributeValue => {
            const column = sortableColumns.find(column => column.field === attributeValue.value)
            dispatchSortsAction(editSort(index, 'Property', column?.field ?? ''))
          }} />
      </FormControl>
      <FormControl
        sx={{
          width: 150
        }}>
        <EnumInput
          attributeValue={{
            attribute: {
              Name: 'direction',
              Title: 'Direction',
              AttributeType: 'enum',
              EnumeratedType: {
                ExternalId: '',
                GlobalExternalId: '',
                Values: [
                  {
                    Id: 'asc',
                    Value: 'asc',
                    Description: 'Ascending'
                  },
                  {
                    Id: 'desc',
                    Value: 'desc',
                    Description: 'Descending'
                  }
                ]
              },
              List: false
            },
            value: sort.Direction
          }}
          readOnly={false}
          textFieldProps={textFieldProps}
          disableClearable
          disableSorting
          onChange={attributeValue => dispatchSortsAction(
            editSort(
              index,
              'Direction',
              attributeValue.value as 'asc' | 'desc'
            )
          )} />
      </FormControl>
    </Stack>
  )
}

export default function SortPanel({
  columns,
  onClose,
  sortsRef
}: Props): JSX.Element {
  const [sorts, dispatchSortsAction] = useReducer(sortsReducer, sortsRef.current.sorts)
  useEffect(() => {
    sortsRef.current = {
      sorts
    }
  }, [sorts, sortsRef])
  const theme = useTheme()
  const [includeHiddenColumnsSetting, setIncludeHiddenColumns] = useLocalStorageState(
    'sortPanel.includeHiddenColumns',
    false,
    BooleanFromString
  )
  const allSortableColumns = useMemo(() => {
    return columns
      .filter(column => (column.sortable ?? false))
  }, [columns])
  const visibleSortableColumns = useMemo(() => {
    return allSortableColumns
      .filter(column => !(column.hide ?? false))
  }, [allSortableColumns])
  const usesHiddenColumn = useMemo(() => {
    return sorts
      .some(sort => !visibleSortableColumns.some(column => column.field === sort.Property))
  }, [sorts, visibleSortableColumns])
  const includeHiddenColumns = includeHiddenColumnsSetting || usesHiddenColumn
  return (
    <Stack
      flex='1'
      tabIndex={-1}
      onKeyDown={event => event.stopPropagation()}>
      <Stack
        flex='1 1'
        component='form'
        onSubmit={event => {
          event.preventDefault()
          event.stopPropagation()
          onClose()
        }}
        overflow={{
          x: 'hidden',
          y: 'auto'
        }}
        minWidth={522}
        maxHeight={400}>
        <h3
          style={{
            margin: 0,
            paddingLeft: theme.spacing(2),
            paddingTop: theme.spacing(1)
          }}>
          Sort
        </h3>
        {sorts
          .map<[Sort, number]>((sort, index) => [sort, index])
          .map(([sort, index]) => (
            <SortForm
              key={index}
              sort={sort}
              index={index}
              sortableColumns={includeHiddenColumns
                ? allSortableColumns
                : visibleSortableColumns}
              includeHiddenColumns={includeHiddenColumns}
              dispatchSortsAction={dispatchSortsAction} />
          ))}
        <input
          type='submit'
          hidden />
      </Stack>
      <Stack
        direction='row'
        justifyContent='space-between'
        p={0.5}>
        {sorts.length === 0 && (
          <Button
            onClick={() => {
              const firstColumn = includeHiddenColumns
                ? allSortableColumns[0]
                : visibleSortableColumns[0]
              if (!firstColumn) {
                return
              }
              dispatchSortsAction(addSort({
                Property: firstColumn.field,
                Direction: 'asc'
              }))
            }}
            startIcon={<GridAddIcon />}
            color='primary'>
            Add Sort
          </Button>
        )}
        <Box flexGrow={1} />
        <FormControlLabel
          control={(
            <Switch
              disabled={usesHiddenColumn}
              checked={includeHiddenColumns}
              onChange={(event, checked) => setIncludeHiddenColumns(checked)} />
          )}
          label='Include Hidden Columns' />
      </Stack>
    </Stack>
  )
}
