import { Box, Button, Stack } from '@mui/material'
import ActionEffectResultsView from 'components/domainType/ActionEffectResultsView'
import ScrollBar from 'components/navigation/ScrollBar'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clearJob, confirmInterestInJobs } from 'state/actions/jobs'
import { getJobs } from 'state/reducers'

export default function JobsView(): JSX.Element {
  const jobs = useSelector(getJobs)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(confirmInterestInJobs())
  }, [dispatch])
  const completedJobs = jobs
    .filter(job => job.jobStatus?.Status === 'Completed'
      || job.jobStatus?.Status === 'Errored')
  return (
    <Stack
      gap={1}
      minWidth={500}>
      <Box
        p={2}
        pb={0}>
        <h4
          style={{
            margin: 0,
            marginBottom: 0
          }}>
          Background Jobs
        </h4>
      </Box>
      {jobs.length === 0
        ? (
          <Box
            component='span'
            p={2}
            pt={0}>
            You are not running any background jobs
          </Box>
        )
        : (
          <>
            <ScrollBar style={{
              maxHeight: 400,
              paddingLeft: '8px',
              paddingRight: '8px'
            }}>
              <ActionEffectResultsView
                key={jobs.length}
                effects={jobs.map(job => ({
                  ...job.effect,
                  Name: job.jobId
                }))}
                actionEffectResults={{
                  RequestEffectResults: jobs.map(job => ({
                    Type: 'QueueJobActionEffect',
                    Job: {
                      Id: job.jobId
                    },
                    Result: 'Success',
                    Name: job.jobId
                  })),
                  PathEffectResults: []
                }} />
            </ScrollBar>
            <Box
              p={1}
              pt={0}
              textAlign='right'>
              <Button
                variant='text'
                disabled={completedJobs.length === 0}
                onClick={() => {
                  for (const job of completedJobs) {
                    dispatch(clearJob(job.jobId))
                  }
                }}>
                Clear Completed Jobs
              </Button>
            </Box>
          </>
        )}
    </Stack>
  )
}