import MuiDrawer, { DrawerProps } from '@mui/material/Drawer'
import { emphasize, styled, Theme } from '@mui/material/styles'

const CLOSED_MENU_WIDTH = 69
export const DRAG_HANDLE_WIDTH = 4

const widthTransition = (theme: Theme) => theme.transitions.create('width', {
  easing: theme.transitions.easing.sharp,
  duration: theme.transitions.duration.short
})

export default styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'isDragging' && prop !== 'drawerWidth'
})<DrawerProps & { isDragging: boolean, drawerWidth: number }>(({ theme, open, isDragging, drawerWidth }) => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  backgroundColor: emphasize(theme.palette.background.paper, 0.02),
  overflow: 'visible',
  '& .MuiDrawer-paper': {
    backgroundColor: emphasize(theme.palette.background.paper, 0.02),
    overflowY: 'visible'
  },
  ...!isDragging && {
    transition: widthTransition(theme),
    '& .MuiDrawer-paper': {
      backgroundColor: emphasize(theme.palette.background.paper, 0.02),
      overflowY: 'visible',
      width: drawerWidth,
      transition: widthTransition(theme)
    }
  },
  ...open === true && {
    width: drawerWidth + DRAG_HANDLE_WIDTH,
    '& .MuiDrawer-paper': {
      backgroundColor: emphasize(theme.palette.background.paper, 0.02),
      overflowY: 'visible',
      width: drawerWidth
    }
  },
  '& .collapse': {
    opacity: 0
  },
  '&:hover .collapse': {
    opacity: 0.7
  },
  '& .text': {
    display: open === true ? 'inline' : 'none'
  },
  ...(open !== true) && {
    width: CLOSED_MENU_WIDTH,
    '.MuiDrawer-paper': {
      width: CLOSED_MENU_WIDTH,
      backgroundColor: emphasize(theme.palette.background.paper, 0.02),
      overflowY: 'visible'
    },
    '&:hover .MuiDrawer-paper': {
      width: drawerWidth,
      boxShadow: theme.shadows[1]
    },
    '&:hover .text': {
      display: 'inline'
    }
  }
}))