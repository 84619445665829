import { Dialog, DialogContent, Alert, DialogActions, Button, AlertTitle } from '@mui/material'
import { Alert as AlertType } from 'types'
interface Props {
  alert: AlertType,
  open: boolean,
  onClose: (result: boolean) => void
}

export default function ConfirmationDialog({
  alert,
  open,
  onClose
}: Props): JSX.Element {
  return (
    <Dialog
      open={open}>
      <DialogContent>
        <Alert
          severity={alert.Severity}>
          <AlertTitle>{alert.Severity.charAt(0).toUpperCase() + alert.Severity.slice(1)}</AlertTitle>
          {alert.Text}
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button
          variant='text'
          autoFocus
          onClick={() => onClose(false)}>
          Cancel
        </Button>
        <Button
          variant='text'
          onClick={() => onClose(true)}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}