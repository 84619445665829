import { Alert, Box, Button, InputBase, Link, MenuItem, Select } from '@mui/material'
import Pagination from '@mui/material/Pagination'
import { ContextType, memo, useContext } from 'react'
import { useSelector } from 'react-redux'
import { Link as RouterLink, generatePath } from 'react-router-dom'
import { getAllDomainTypes, getUser } from 'state/reducers'
import { PAGE_URL } from 'utils/constants'
import { getDomainTypeSetting, isInRole, isNullOrUndefined } from 'utils/helpers'
import { useDomainType } from 'utils/hooks'
import TableViewContext from './TableView/TableViewContext'

type Keys =
  | 'page'
  | 'total'
  | 'pageSize'
  | 'rowCount'
  | 'isLoading'
  | 'setPage'
  | 'setPageSize'
type Props = Pick<ContextType<typeof TableViewContext>, Keys>

function SearchIndexLink(): JSX.Element {
  const searchIndexDomainType = useDomainType('SearchIndex', 'Metadata')
  const domainTypes = useSelector(getAllDomainTypes)
  const role = getDomainTypeSetting(domainTypes, searchIndexDomainType, 'ViewRole')
  const user = useSelector(getUser)
  if (!isNullOrUndefined(role) && !isInRole(user, role)) {
    return <>search index</>
  }
  return (
    <Link
      component={RouterLink}
      sx={{
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: 'inline-flex',
        alignItems: 'center',
        gap: '8px',
        color: theme => theme.palette.muiPrimary.main
      }}
      to={{
        pathname: generatePath(PAGE_URL.FIND, {
          databaseTable: 'Metadata',
          name: 'SearchIndex'
        })
      }}
      onClick={event => event.stopPropagation()}>
      search index
    </Link>
  )
}

export default memo(function FindPagination({
  page = 1,
  total = 0,
  pageSize = 15,
  rowCount = 0,
  isLoading,
  setPage,
  setPageSize
}: Props): JSX.Element {
  const from = Math.min(total, Math.max(0, (page - 1) * pageSize + 1))
  const to = Math.min(from + pageSize - 1, total)
  const expectedRowCount = to - from + 1
  const hasFewerRowsThanExpected = to > 0 && rowCount < expectedRowCount
  const { checkedItems, domainType, allChecked, onAllCheckedChange, onCheckedRowIdsChange } = useContext(TableViewContext)
  return (
    <>
      {(checkedItems.length === pageSize && rowCount < total)
        ? (
          <>
            <Box
              component='span'
              ml={2}>
              {allChecked
                ? `All ${total} ${domainType?.PluralTitle} are selected.`
                : `All ${checkedItems.length} rows on this page are selected.`}
            </Box>
            <Button
              variant='text'
              onClick={() => {
                if (!allChecked) {
                  onAllCheckedChange(true)
                } else {
                  onCheckedRowIdsChange([])
                }
              }}>
              {allChecked
                ? 'Clear selection'
                : `Select all ${total} ${domainType?.PluralTitle}`}
            </Button>
          </>
        )
        : (
          <Box
            component='span'
            ml={2}>
            {checkedItems.length === 1
              ? `${checkedItems.length} row selected`
              : `${checkedItems.length} rows selected`}
          </Box>
        )
      }
      {!isLoading && hasFewerRowsThanExpected && (
        <Alert
          sx={{
            p: 1,
            pt: 0,
            pb: 0,
            ml: 1
          }}
          severity='error'>
          This page has fewer rows than expected.
          There might be a problem with the <SearchIndexLink />.
        </Alert>
      )}
      <Box flexGrow={1} />
      <p>Items per page:</p>
      <Select
        input={<InputBase />}
        variant='standard'
        value={pageSize}
        style={{
          marginRight: '32px',
          marginLeft: '8px'
        }}
        onChange={event => {
          if (setPageSize !== undefined && typeof event.target.value === 'number') {
            setPageSize(event.target.value)
          }
        }}>
        <MenuItem value={15}>15</MenuItem>
        <MenuItem value={25}>25</MenuItem>
        <MenuItem value={50}>50</MenuItem>
        <MenuItem value={100}>100</MenuItem>
      </Select>
      <p style={{ marginRight: '20px' }}>{`${from}-${to} of ${total}`}</p>
      <Pagination
        color='primary'
        count={Math.ceil(total / pageSize)}
        page={page}
        onChange={(event, value) => {
          if (setPage !== undefined) {
            setPage(value)
          }
        }} />
    </>
  )
})