import { Box, alpha, useTheme } from '@mui/material'
import * as O from 'fp-ts/Option'
import { DateTime } from 'luxon'
import { useMemo, useRef } from 'react'
import { Id, ReactCalendarItemRendererProps } from 'react-calendar-timeline'
import { useDrop } from 'react-dnd'
import { useSelector } from 'react-redux'
import { getAllDomainTypes, getUser } from 'state/reducers'
import { getContrastingColour, getDomainTypeSetting, getId, getUniqueId } from 'utils/helpers'
import { useEventBus } from 'utils/hooks'
import { getMouseDownMessage, getRouteOriginAndDestination, getTimelineItemInstance } from '../helpers'
import { MovingStop, RouteTimelineItem, StopDragData } from '../types'
import RouteEdge from './RouteEdge'
import RouteStop from './RouteStop'

interface Props extends ReactCalendarItemRendererProps<RouteTimelineItem> {
  readonly movingStop: MovingStop | null
  addMessage(message: string): void
  handleContextMenu(event: React.MouseEvent<HTMLDivElement>, id: string): void
  onStopDragStart(id: Id): void
  onStopDragEnd(): void
  onMoveRouteStop(movingStop: MovingStop): void
}

export default function TimelineRoute({
  item,
  itemContext,
  getItemProps,
  getResizeProps,
  movingStop,
  addMessage,
  handleContextMenu,
  onStopDragStart,
  onStopDragEnd,
  onMoveRouteStop
}: Props): JSX.Element {
  const theme = useTheme()
  const user = useSelector(getUser)
  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps()
  const colour = getContrastingColour(
    item.colour ?? theme.palette.primary.main,
    theme.palette.mode,
    theme.palette.contrastThreshold
  )
  const rootRef = useRef<HTMLDivElement | null>(null)
  const eventBus = useEventBus()
  const [, dropRef] = useDrop(() => ({
    accept: 'stop',
    canDrop(stop: StopDragData) {
      return item.id === stop.item.routeId
    },
    drop(stop: StopDragData, monitor) {
      const initialSourceOffset = monitor.getInitialSourceClientOffset()
      const initialOffset = monitor.getInitialClientOffset()
      const previewOffset = (initialOffset?.x ?? 0) - (initialSourceOffset?.x ?? 0)

      const offset = monitor.getClientOffset()
      if (offset === null || rootRef.current === null) {
        return
      }
      const rect = rootRef.current.getBoundingClientRect()
      const x = offset.x - rect.left - previewOffset
      const timeToPxRatio = (item.end_time - item.start_time) / itemContext.dimensions.width
      const time = item.start_time + x * timeToPxRatio
      const targetIndex = item.route.Stops
        .findIndex(stop => time < DateTime.fromISO(stop.ArrivalTime).toMillis())
      eventBus.dispatch({
        type: 'timelineRouteHoverEnd'
      })
      onMoveRouteStop({
        routeDatabaseTable: item.subtype.DatabaseTable ?? null,
        fromRoute: stop.route,
        fromIndex: stop.index,
        toRoute: item.route,
        toIndex: (targetIndex + item.route.Stops.length + 1) % (item.route.Stops.length + 1),
        toTime: time,
        item: stop.item
      })
    }
  }), [eventBus, itemContext, item])
  const instance = getTimelineItemInstance(item)
  const itemProps = getItemProps({
    ...item.itemProps,
    style: {
      background: undefined,
      color: theme.palette.getContrastText(colour)
    }
  })
  const width = itemContext.dimensions.width
  const domainTypes = useSelector(getAllDomainTypes)
  const [origin, destination] = getRouteOriginAndDestination(
    domainTypes,
    item.domainTypeChain[0]?.domainType,
    item.route
  )
  const stopIds = useMemo(() => {
    return item.timelineItems
      .map(timelineItem => [
        timelineItem,
        item.route.Stops
          .findIndex(stop => stop.Id === getId(domainTypes, timelineItem.subtype, getTimelineItemInstance(timelineItem)))
      ] as const)
      .sort((a, b) => a[1] - b[1])
      .map(([timelineItem]) => getUniqueId(domainTypes, timelineItem.subtype, getTimelineItemInstance(timelineItem)))
  }, [domainTypes, item.route.Stops, item.timelineItems])
  return (
    <Box
      {...itemProps}
      sx={{
        '&.rct-route.rct-item': {
          background: alpha(colour, 0.5)
        },
        '.timeline-container:not(.dragging) &.rct-route.rct-item:hover, &.rct-route.rct-item.dragging': {
          background: alpha(colour, 0.75)
        }
      }}
      className={`${itemProps.className} rct-route ${itemContext.dragging ? 'dragging' : ''} ${getUniqueId(domainTypes, item.subtype, instance)}`}
      title={undefined}
      onMouseEnter={() => {
        eventBus.dispatch({
          type: 'timelineRouteHoverStart',
          stopIds
        })
      }}
      onMouseLeave={() => {
        eventBus.dispatch({
          type: 'timelineRouteHoverEnd'
        })
      }}
      onMouseDown={event => {
        event.stopPropagation()
        if (event.button !== 0) {
          return
        }
        if (item.canMove === true) {
          return
        }
        const mouseDownMessage = getMouseDownMessage(item, user)
        if (O.isSome(mouseDownMessage)) {
          addMessage(mouseDownMessage.value)
        }
      }}
      onContextMenu={event => {
        handleContextMenu(event, String(item.id))
      }}>
      {item.canResize === 'both'
        ? (
          <div {...leftResizeProps}>
            <div className='rct-item-handler-icon' />
          </div>
        )
        : ''}
      <div
        className='rct-item-content'
        ref={node => {
          dropRef(node)
          rootRef.current = node
        }}
        style={{
          maxHeight: `${itemContext.dimensions.height}`,
          position: 'relative',
          width: '100%'
        }}>
        {origin !== null && (
          <RouteEdge
            colour={getDomainTypeSetting(domainTypes, origin[0], 'Colour')}
            instance={origin[1]}
            domainType={origin[0]}
            routeStartTime={item.start_time}
            routeEndTime={item.end_time}
            width={width}
            time={item.start_time} />
        )}
        {movingStop?.toRoute.Id === item.id && (
          <RouteStop
            key={movingStop.item.id}
            route={item.route}
            item={movingStop.item}
            index={movingStop.toIndex}
            routeStartTime={item.start_time}
            routeEndTime={item.end_time}
            editingOffset={item.start_time - item.preEditStartTime}
            width={width}
            time={movingStop.toTime}
            onStopDragStart={onStopDragStart}
            onStopDragEnd={onStopDragEnd} />
        )}
        {item.timelineItems
          .map(timelineItem => {
            const instance = getTimelineItemInstance(timelineItem)
            const instanceId = getId(domainTypes, timelineItem.subtype, instance)
            const stopIndex = item.route.Stops.findIndex(stop => stop.Id === instanceId)
            if (movingStop?.fromRoute.Id === item.id
              && movingStop.fromIndex === stopIndex) {
              return null
            }
            const stop = item.route.Stops[stopIndex]
            if (stop === undefined) {
              return null
            }
            const stopStartTime = DateTime.fromISO(stop.ArrivalTime).toMillis()
            return (
              <RouteStop
                key={timelineItem.id}
                route={item.route}
                item={timelineItem}
                index={stopIndex}
                routeStartTime={item.start_time}
                routeEndTime={item.end_time}
                editingOffset={item.start_time - item.preEditStartTime}
                width={width}
                time={stopStartTime}
                onStopDragStart={onStopDragStart}
                onStopDragEnd={onStopDragEnd} />
            )
          })}
        {destination !== null && (
          <RouteEdge
            colour={getDomainTypeSetting(domainTypes, destination[0], 'Colour')}
            instance={destination[1]}
            domainType={destination[0]}
            routeStartTime={item.start_time}
            routeEndTime={item.end_time}
            width={width}
            time={item.end_time - item.route.DestinationDwellMinutes * 60000} />
        )}
      </div>
      {item.canResize === 'both'
        ? (
          <div {...rightResizeProps}>
            <div className='rct-item-handler-icon' />
          </div>
        )
        : ''}
    </Box>
  )
}