import EditDialog from 'components/domainType/EditDialog'
import * as E from 'fp-ts/Either'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { getUser } from 'state/reducers'
import { ApiError, DomainType, DomainTypeInstance } from 'types'
import { DomainTypeContext } from 'utils/context'
import { CustomButtonProps, useApi } from 'utils/hooks'

export default function ChangeMyPasswordButton({
  button,
  target,
  Component,
  onComplete
}: CustomButtonProps): JSX.Element | null {
  const [dialogOpen, setDialogOpen] = useState(false)
  const context = useContext(DomainTypeContext)
  const instances = useMemo(() => {
    return target.type === 'instances'
      ? target.instances
      : []
  }, [target])
  const instance = useMemo(() => instances.length === 1 ? instances[0] : undefined, [instances])
  const user = useSelector(getUser)
  const api = useApi()
  const onCloseDialog = useCallback(() => {
    setDialogOpen(false)
    if (onComplete !== undefined) {
      onComplete()
    }
  }, [setDialogOpen, onComplete])
  const [apiError, setApiError] = useState<ApiError | null>(null)
  const onSuccess = useCallback(async (instance: DomainTypeInstance) => {
    if (!api.isSignedIn) {
      return
    }
    const response = await api.changeMyPassword(
      String(instance['OldPassword']),
      String(instance['Password'])
    )
    if (E.isRight(response)) {
      onCloseDialog()
      context.onInvalidate?.()
    } else {
      setApiError(response.left)
    }
  }, [api, context, onCloseDialog])
  const customDomainType = useMemo((): DomainType => {
    return {
      ...button.domainType,
      EditForm: ['OldPassword', 'Password'],
      Attributes: [
        ...button.domainType.Attributes,
        {
          AttributeType: 'string',
          Name: 'OldPassword',
          Title: 'Old Password',
          Format: 'password',
          Required: true
        },
        {
          AttributeType: 'string',
          Name: 'Password',
          Title: 'Password',
          Format: 'password',
          Required: true
        }
      ]
    }
  }, [button.domainType])
  const [loginType, setLoginType] = useState<'AD' | 'Local' | null>(null)
  useEffect(() => {
    async function getLoginType() {
      if (!api.isSignedIn
        || user === null) {
        return
      }
      const response = await api.getLoginType(user.id)
      if (E.isRight(response)) {
        setLoginType(response.right)
      }
    }
    getLoginType()
  }, [api, user])
  if (loginType !== 'Local') {
    return null
  }
  return (
    <>
      <Component
        text={button.name}
        icon={button.icon}
        onClick={() => setDialogOpen(true)}
        disabled={button.disabled || instance === undefined} />
      <EditDialog
        key={dialogOpen.toString()}
        open={dialogOpen}
        bypassApi
        hideSettingPopper
        errorCode={apiError?.errorCode}
        pathError={apiError?.pathErrors}
        domainType={customDomainType}
        instance={instance ?? null}
        title='Change My Password:'
        onClose={onCloseDialog}
        onEditSuccess={onSuccess} />
    </>
  )
}