import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import AttributeForm from 'components/attribute/AttributeForm'
import AppendDomainTypeContext from 'components/domainType/AppendDomainTypeContext'
import AttributeListSettingPopover from 'components/domainType/AttributeListSettingPopover'
import DomainTypeHeading from 'components/domainType/DomainTypeHeading'
import DialogAlert from 'components/utils/DialogAlert'
import { useContext, useEffect, useMemo } from 'react'
import { DomainType, DomainTypeInstance, PathError } from 'types'
import { PathErrorsCodec } from 'utils/codecs'
import FormModeContext from 'utils/context/FormModeContext'
import { FORM_MODE_ORDER_SETTING, useEdit } from 'utils/hooks'

interface Props {
  readonly open: boolean
  readonly instance: DomainTypeInstance | null
  readonly pathError?: PathError
  readonly domainType: DomainType
  readonly formMode?: 'create' | 'edit'
  readonly bypassApi?: boolean
  readonly hideSettingPopper?: boolean
  readonly errorCode?: string
  readonly title?: string
  onClose(): void
  onEditSuccess(instance: DomainTypeInstance): void
}

export default function EditDialog({
  open,
  instance,
  pathError,
  domainType,
  formMode,
  bypassApi = false,
  hideSettingPopper = false,
  errorCode,
  title,
  onClose,
  onEditSuccess
}: Props): JSX.Element {
  const formModeContext = useContext(FormModeContext)
  formMode = formMode ?? formModeContext ?? 'edit'
  const {
    isEditing,
    allAttributeValues,
    attributeValues,
    subtype,
    pathErrors,
    errorCode: editErrorCode,
    createInstance,
    onChange,
    onEdit,
    onReset
  } = useEdit(
    domainType,
    instance,
    formMode,
    PathErrorsCodec.is(pathError)
      ? pathError
      : undefined,
    onEditSuccess,
    bypassApi
  )
  useEffect(() => {
    if (open) {
      onReset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])
  const newInstances = useMemo<[DomainType, DomainTypeInstance][]>(
    () => [[domainType, createInstance(attributeValues)]],
    [attributeValues, createInstance, domainType]
  )
  return (
    <FormModeContext.Provider value={formMode}>
      <Dialog
        fullWidth
        maxWidth='md'
        open={open}
        onKeyDown={event => event.stopPropagation()}>
        <DialogTitle>
          <DomainTypeHeading
            domainType={domainType}
            instance={instance ?? undefined}
            isLoading={false}
            title={title ?? 'Edit:'} />
        </DialogTitle>
        <DialogAlert
          isLoading={isEditing}
          errorCode={errorCode ?? editErrorCode} />
        <DialogContent>
          <AppendDomainTypeContext newInstances={newInstances}>
            <AttributeForm
              attributeValues={attributeValues}
              pathErrors={pathErrors}
              onChange={onChange}
              onSubmit={isEditing ? undefined : onEdit} />
          </AppendDomainTypeContext>
          {!hideSettingPopper && (
            <AttributeListSettingPopover
              domainType={subtype}
              allAttributeValues={allAttributeValues}
              setting={FORM_MODE_ORDER_SETTING[formMode]}
              alwaysShowRequired={formMode === 'create'} />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isEditing}
            variant='text'
            onClick={onClose}>
            Discard Edits
          </Button>
          <LoadingButton
            disabled={isEditing}
            loading={isEditing}
            onClick={onEdit}>
            Apply Edits
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </FormModeContext.Provider>
  )
}