import * as t from 'io-ts'
import { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { DomainTypeInstance } from 'types'
import { DomainTypeContext } from 'utils/context'
import { getCachedSubtypes } from 'utils/helpers'
import { useDomainType, useSubtypesCache } from '.'

export function useDomainTypeContextInstance(
  name: string,
  databaseTable: string | null
): DomainTypeInstance | null
export function useDomainTypeContextInstance<T>(
  name: string,
  databaseTable: string | null,
  codec: t.Type<T>
): T | null
export function useDomainTypeContextInstance<T extends DomainTypeInstance = DomainTypeInstance>(
  name: string,
  databaseTable: string | null,
  codec?: t.Type<T>
): T | null {
  const domainTypes = useSelector(getAllDomainTypes)
  const domainTypeContext = useContext(DomainTypeContext)
  const targetDomainType = useDomainType(name, databaseTable)
  const subtypesCache = useSubtypesCache()
  return useMemo(() => {
    if (targetDomainType === null
      || domainTypeContext.instances.length === 0) {
      return null
    }
    const targetDomainTypes = getCachedSubtypes(domainTypes, targetDomainType, subtypesCache)
    const reversedContext = [...domainTypeContext.instances].reverse()
    const instance = reversedContext.find(([domainType]) => {
      return targetDomainTypes
        .find(childDomainType => childDomainType.Id === domainType.Id)
    })?.[1] ?? null
    if (codec === undefined) {
      return instance as T | null
    }
    return codec.is(instance)
      ? instance
      : null
  }, [codec, domainTypeContext.instances, domainTypes, subtypesCache, targetDomainType])
}