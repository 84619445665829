import { Edit, Visibility } from '@mui/icons-material'
import { Dialog, IconButton, InputAdornment, TextField, useTheme } from '@mui/material'
import MultiResultsInput from 'components/dataform/MultiResultsInput'
import CornerIcon from 'components/utils/CornerIcon'
import { useCallback, useRef, useState } from 'react'
import { MultiDataformResultsAttributeValue, ValueTypes } from 'types'
import { MultiResults } from 'types/dataform'
import { DEFAULT_ATTRIBUTE_INPUT_SIZE, DEFAULT_ATTRIBUTE_INPUT_VARIANT } from 'utils/constants'
import { getMultiDataformResultsIcon, isListAttributeValue, toErrorText } from 'utils/helpers'
import { useMultiDataformResults } from 'utils/hooks'
import { NarrowedProps } from '../AttributeInput'
import NotSupportedInput from '../NotSupportedInput'

function multiResultsToDictionary(multiResults: MultiResults): ValueTypes['multiDataformResults'] {
  return multiResults.reduce((prev, curr) => {
    prev[curr.key] = curr.results
    return prev
  }, {} as ValueTypes['multiDataformResults'])
}

export default function MultiDataformResultsInput({
  attributeValue,
  readOnly,
  pathError,
  textFieldProps,
  onChange
}: NarrowedProps<MultiDataformResultsAttributeValue>): JSX.Element {
  const [open, setOpen] = useState(false)
  const handleChange = useCallback((value: ValueTypes['multiDataformResults'] | null) => {
    onChange?.({
      ...attributeValue,
      value
    })
  }, [attributeValue, onChange])
  const [multiResults, errorMessage] = useMultiDataformResults(attributeValue, readOnly)
  const resultsRef = useRef<ValueTypes['multiDataformResults']>(multiResultsToDictionary(multiResults))
  const applyEdits = useCallback(() => {
    setOpen(false)
    handleChange(resultsRef.current)
  }, [handleChange])
  const handleLocalChange = useCallback((multiResults: MultiResults) => {
    resultsRef.current = multiResultsToDictionary(multiResults)
  }, [])
  const theme = useTheme()
  if (isListAttributeValue(attributeValue)) {
    return (
      <NotSupportedInput
        attributeValue={attributeValue}
        textFieldProps={textFieldProps} />
    )
  }
  const [cornerIcon, cornerIconColor] = getMultiDataformResultsIcon(attributeValue.value, theme)
  return (
    <>
      <Dialog
        fullWidth
        maxWidth='md'
        open={open}>
        <MultiResultsInput
          initialMultiResults={multiResults}
          attribute={attributeValue.attribute}
          errorMessage={errorMessage}
          readOnly={readOnly}
          maxHeight='50vh'
          onChange={handleLocalChange}
          onComplete={applyEdits}
          onClose={applyEdits} />
      </Dialog>
      <TextField
        label={attributeValue.attribute.Title}
        id={attributeValue.attribute.Name}
        variant={DEFAULT_ATTRIBUTE_INPUT_VARIANT}
        fullWidth
        size={DEFAULT_ATTRIBUTE_INPUT_SIZE}
        value=''
        required={attributeValue.attribute.Required ?? false}
        error={pathError !== undefined}
        helperText={toErrorText(pathError)}
        {...textFieldProps}
        InputProps={{
          ...textFieldProps?.InputProps,
          readOnly: true,
          startAdornment: cornerIcon !== null
            ? (
              <InputAdornment position='start'>
                <CornerIcon
                  icon='dynamic_form'
                  cornerIcon={cornerIcon}
                  cornerIconColor={cornerIconColor} />
              </InputAdornment>
            )
            : null,
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                aria-label='open dataform dialog'
                edge='end'
                onClick={() => {
                  setOpen(true)
                }}>
                {readOnly
                  ? <Visibility />
                  : <Edit />}
              </IconButton>
            </InputAdornment>
          )
        }} />
    </>
  )
}
