import { LoadingButton } from '@mui/lab'
import { Grid, TextField, TextFieldProps } from '@mui/material'
import { NotSupportedInput, ReadOnlyDomainTypeInput } from 'components/attribute/AttributeInput'
import { either as E } from 'fp-ts'
import { constant, identity, pipe } from 'fp-ts/lib/function'
import { useEffect, useState } from 'react'
import { ApiError, DomainTypeAttributeValue, DomainTypeInstance, PathError, What3Words } from 'types'
import { What3WordsCodec } from 'utils/codecs'
import { DEFAULT_ATTRIBUTE_INPUT_SIZE, DEFAULT_ATTRIBUTE_INPUT_VARIANT } from 'utils/constants'
import { isListAttributeValue, toErrorText } from 'utils/helpers'
import { useApi, useCancellableApiSession } from 'utils/hooks'

interface What3WordsProps {
  readonly attributeValue: DomainTypeAttributeValue
  readonly readOnly: boolean
  readonly disabled?: boolean
  readonly pathError?: PathError
  readonly textFieldProps?: Partial<TextFieldProps>,
  onChange?(attributeValue: DomainTypeAttributeValue): void
}

export default function What3WordsInput({
  attributeValue,
  readOnly,
  disabled,
  pathError,
  textFieldProps,
  onChange
}: What3WordsProps): JSX.Element {
  const value = pipe(
    attributeValue.value,
    What3WordsCodec.decode,
    E.match(
      constant(null),
      identity
    )
  )
  const [word1, setWord1] = useState('')
  const [word2, setWord2] = useState('')
  const [word3, setWord3] = useState('')
  useEffect(() => {
    setWord1(word1 => value?.Word1 ?? word1)
    setWord2(word2 => value?.Word2 ?? word2)
    setWord3(word3 => value?.Word3 ?? word3)
  }, [value])
  const [response, setResponse] = useState<E.Either<ApiError, What3Words> | null>(null)
  const api = useApi()
  const validate = useCancellableApiSession(api)
  const [validationState, setValidationState] = useState<'pending' | 'invalid' | 'valid'>('pending')
  useEffect(() => {
    if (!word1 || !word2 || !word3) {
      setValidationState('pending')
      return
    }
    const apiSession = validate.cancelPreviousAndStartNew()
    if (!apiSession.isSignedIn) {
      return
    }
    setValidationState('pending')
    apiSession.validateWhat3Words(
      word1,
      word2,
      word3
    ).then(response => {
      if (E.isRight(response)) {
        setValidationState('valid')
      } else {
        setValidationState('invalid')
      }
      setResponse(response)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [word1, word2, word3])
  useEffect(() => {
    if (response === null) {
      return
    }
    if (E.isRight(response)) {
      const what3Words = response.right as unknown as DomainTypeInstance
      if (attributeValue.value === what3Words) {
        return
      }
      onChange?.({
        ...attributeValue,
        value: what3Words
      })
    } else {
      if (attributeValue.value === null) {
        return
      }
      onChange?.({
        ...attributeValue,
        value: null
      })
    }
  }, [attributeValue, onChange, response])
  if (isListAttributeValue(attributeValue)) {
    return (
      <NotSupportedInput
        attributeValue={attributeValue}
        textFieldProps={textFieldProps} />
    )
  }
  if (readOnly) {
    return (
      <ReadOnlyDomainTypeInput
        attributeValue={attributeValue}
        pathError={pathError}
        textFieldProps={textFieldProps} />
    )
  }
  const incomplete = !word1 || !word2 || !word3
  return (
    <Grid
      container
      columns={15}>
      <Grid
        item
        xs={3}>
        <TextField
          label={attributeValue.attribute.Title}
          id={attributeValue.attribute.Name}
          variant={DEFAULT_ATTRIBUTE_INPUT_VARIANT}
          fullWidth
          size={DEFAULT_ATTRIBUTE_INPUT_SIZE}
          sx={theme => ({
            '& .MuiInputLabel-root': {
              color: theme.palette.text.primary
            },
            '& .MuiInputLabel-root.Mui-error': {
              color: theme.palette.error.main
            },
            '& .Mui-disabled:before': {
              borderBottomStyle: 'solid !important'
            }
          })}
          required={attributeValue.attribute.Required ?? false}
          error={pathError !== undefined}
          helperText={toErrorText(pathError)}
          {...textFieldProps}
          InputProps={{
            ...textFieldProps?.InputProps,
            readOnly: true,
            disabled: true
          }} />
      </Grid>
      <Grid
        item
        xs={3}>
        <TextField
          label='Word 1'
          variant={DEFAULT_ATTRIBUTE_INPUT_VARIANT}
          fullWidth
          size={DEFAULT_ATTRIBUTE_INPUT_SIZE}
          required={attributeValue.attribute.Required ?? false}
          value={word1}
          onChange={event => {
            setWord1(event.target.value)
          }}
          {...textFieldProps}
          InputProps={{
            ...textFieldProps?.InputProps,
            readOnly,
            disabled
          }} />
      </Grid>
      <Grid
        item
        xs={3}>
        <TextField
          label='Word 2'
          variant={DEFAULT_ATTRIBUTE_INPUT_VARIANT}
          fullWidth
          size={DEFAULT_ATTRIBUTE_INPUT_SIZE}
          required={attributeValue.attribute.Required ?? false}
          value={word2}
          onChange={event => {
            setWord2(event.target.value)
          }}
          {...textFieldProps}
          InputProps={{
            ...textFieldProps?.InputProps,
            readOnly,
            disabled
          }} />
      </Grid>
      <Grid
        item
        xs={3}>
        <TextField
          label='Word 3'
          variant={DEFAULT_ATTRIBUTE_INPUT_VARIANT}
          fullWidth
          size={DEFAULT_ATTRIBUTE_INPUT_SIZE}
          required={attributeValue.attribute.Required ?? false}
          value={word3}
          onChange={event => {
            setWord3(event.target.value)
          }}
          {...textFieldProps}
          InputProps={{
            ...textFieldProps?.InputProps,
            readOnly,
            disabled
          }} />
      </Grid>
      <Grid
        item
        xs={3}
        sx={{
          display: 'flex',
          alignItems: 'flex-start'
        }}>
        <LoadingButton
          variant='contained'
          disableElevation
          fullWidth
          disabled={incomplete}
          color={({
            pending: 'warning',
            invalid: 'error',
            valid: 'success'
          } as const)[validationState]}
          loading={!incomplete && validationState === 'pending'}
          onClick={() => {
            window.open(`https://what3words.com/${word1}.${word2}.${word3}`, '_blank')
          }}
          sx={{
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            marginTop: theme => theme.spacing(1)
          }}>
          {{
            pending: 'Check Location',
            invalid: 'Invalid',
            valid: 'Check Location'
          }[validationState]}
        </LoadingButton>
      </Grid>
    </Grid>
  )
}