import { CloseOutlined, FullscreenExitOutlined, FullscreenOutlined } from '@mui/icons-material'
import { Box, ButtonGroup, Stack, alpha, useTheme } from '@mui/material'
import useResizeObserver from '@react-hook/resize-observer'
import TooltipIconButton from 'components/utils/TooltipIconButton'
import L, { Map, divIcon } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { renderToString } from 'react-dom/server'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-cluster'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { DomainType, DomainTypeInstance } from 'types'
import { ATTRIBUTE_PATH_SEPARATOR, PAGE_PADDING } from 'utils/constants'
import { HoverEndEvent, HoverStartEvent, TimelineListSelectionChangeEvent, TimelineRouteHoverEndEvent, TimelineRouteHoverStartEvent, TimelineRoutesChangeEvent } from 'utils/context/EventBusContext'
import { getDomainTypeSetting, getLatLong, getMapAttributeChains, getSubtype, getUniqueId, getValue, hasApi, isNotNullOrUndefined } from 'utils/helpers'
import { useDomainTypeColour, useEventBus, useEventHandler, useSubtypesCache } from 'utils/hooks'
import DomainTypeHeading from './DomainTypeHeading'
import DomainTypeTooltip from './DomainTypeTooltip'

delete (L.Icon.Default.prototype as unknown as { _getIconUrl: unknown })._getIconUrl

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

interface MaterialIconMarkerProps {
  icon: string
  cornerIcon: string | null | undefined
  iconColour: string
  iconBackgroundColour: string
  markerColour: string
  outlineColour: string
  outlineWidth: number
  scale: number
  selectedIndex: number
}

const ICON_MARKER_WIDTH = 32
const ICON_MARKER_HEIGHT = 42
const ICON_MARKER_DIV_WIDTH = 12

function MaterialIconMarker({
  icon,
  cornerIcon,
  iconColour,
  iconBackgroundColour,
  markerColour,
  outlineColour,
  outlineWidth,
  scale,
  selectedIndex
}: MaterialIconMarkerProps): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={ICON_MARKER_WIDTH}
      height={ICON_MARKER_HEIGHT}
      viewBox={`0 0 ${ICON_MARKER_WIDTH} ${ICON_MARKER_HEIGHT}`}
      style={{
        position: 'absolute',
        marginLeft: `-${ICON_MARKER_WIDTH / 2 - ICON_MARKER_DIV_WIDTH / 2}px`,
        marginTop: `-${(scale - 1) * ICON_MARKER_HEIGHT / 2 + ICON_MARKER_HEIGHT - ICON_MARKER_DIV_WIDTH / 2}px`
      }}
      transform={`scale(${scale},${scale})`}>
      <path
        xmlns='http://www.w3.org/2000/svg'
        d='M15.6,1c-7.7,0-14,6.3-14,14c0,10.5,14,26,14,26s14-15.5,14-26C29.6,7.3,23.3,1,15.6,1z'
        fill={markerColour}
        stroke={outlineColour}
        strokeWidth={outlineWidth} />
      <circle
        xmlns='http://www.w3.org/2000/svg'
        cx='15.5'
        cy='15'
        r='11'
        fill={iconBackgroundColour} />
      <g xmlns='http://www.w3.org/2000/svg'>
        <text
          xmlns='http://www.w3.org/2000/svg'
          x='15.5'
          y='16'
          textAnchor='middle'
          dominantBaseline='middle'
          className='selected-icon'
          fill={iconColour}
          style={{
            fontSize: '0.75rem',
            fontWeight: 500
          }}>
          {selectedIndex + 1}
        </text>
        <text
          xmlns='http://www.w3.org/2000/svg'
          x='7'
          y='23'
          className='material-icons-outlined domain-type-icon'
          fill={iconColour}
          fontFamily='Material Icons Outlined'
          style={{ fontSize: '17px' }}>
          {icon}
        </text>
        {isNotNullOrUndefined(cornerIcon) && (
          <text
            xmlns='http://www.w3.org/2000/svg'
            x='17'
            y='26'
            className='material-icons-outlined domain-type-icon'
            fill={iconColour}
            fontFamily='Material Icons Outlined'
            style={{
              fontSize: '10px',
              fontWeight: '600',
              textShadow: `-1px -1px 0 ${iconBackgroundColour}, 1px -1px 0 ${iconBackgroundColour}, -1px 1px 0 ${iconBackgroundColour}, 1px 1px 0 ${iconBackgroundColour}`
            }}>
            {cornerIcon}
          </text>
        )}
      </g>
    </svg>
  )
}

type TooltipMarkerProps = MaterialIconMarkerProps & {
  position: [number, number]
  selected: boolean
  blinking: boolean
  selectedIndex: number
  onMouseOver?(): void
  onMouseLeave?(): void
  onClick?(): void
}

const TooltipMarker = forwardRef<HTMLElement, TooltipMarkerProps>(function TooltipMarker({
  selected,
  blinking,
  selectedIndex,
  position,
  icon,
  cornerIcon,
  iconColour,
  iconBackgroundColour,
  markerColour,
  outlineColour,
  outlineWidth,
  scale,
  onMouseLeave,
  onMouseOver,
  onClick
}, ref) {
  const markerIcon = useMemo(() => divIcon({
    className: `custom-icon ${selected ? 'marker-selected' : ''} ${blinking ? 'marker-blinking' : ''}`,
    html: renderToString(
      <MaterialIconMarker
        icon={icon}
        cornerIcon={cornerIcon}
        iconColour={iconColour}
        iconBackgroundColour={iconBackgroundColour}
        markerColour={markerColour}
        outlineColour={outlineColour}
        outlineWidth={outlineWidth}
        scale={scale}
        selectedIndex={selectedIndex} />
    )
  }), [blinking, cornerIcon, icon, iconBackgroundColour, iconColour, markerColour, outlineColour, outlineWidth, scale, selected, selectedIndex])
  // because divIcon uses renderToString
  // the link to the underlying DOM element is lost each time markerIcon is recreated
  // this can cause tooltips to remain open when they should be closed
  // calling onMouseLeave forces the tooltip to close
  useEffect(() => {
    onMouseLeave?.()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markerIcon])
  return (
    <Marker
      ref={marker => {
        const element = marker?.getElement()
        if (ref !== null && element !== undefined) {
          if (typeof ref === 'function') {
            ref(element)
          } else {
            ref.current = element
          }
        }
      }}
      eventHandlers={{
        mouseover: onMouseOver,
        mouseout: onMouseLeave,
        click: onClick
      }}
      position={position}
      icon={markerIcon} />
  )
})

interface DomainTypeMarkerProps {
  domainType: DomainType
  instance: DomainTypeInstance
  position: [number, number]
  scale?: number
  selectedIndex?: number
  blinking?: boolean
  routeDomainType?: DomainType
  routed?: boolean
  hoveringRouteIndex?: number
}

const HOVER_EVENT_SOURCE = 'DomainTypeMarker'

function DomainTypeMarker({
  domainType,
  instance,
  position,
  scale = 1,
  selectedIndex = -1,
  blinking = false,
  routeDomainType,
  routed = false,
  hoveringRouteIndex = -1
}: DomainTypeMarkerProps): JSX.Element {
  const selected = selectedIndex !== -1
  const hoveringRoute = hoveringRouteIndex !== -1
  const theme = useTheme()
  const domainTypes = useSelector(getAllDomainTypes)
  const itemColour = useDomainTypeColour(domainType)
  const routeColour = useDomainTypeColour(routeDomainType ?? null)
  const markerColour = routed
    ? alpha(routeColour, hoveringRoute ? 0.75 : 0.5)
    : selected
      ? routeColour
      : itemColour
  const icon = getDomainTypeSetting(domainTypes, domainType, 'Icon') ?? ''
  const cornerIcon = getDomainTypeSetting(domainTypes, domainType, 'CornerIcon')
  const augmentedColour = theme.palette.augmentColor({
    color: {
      main: markerColour
    }
  })
  const outlineColour = augmentedColour.dark
  const iconColour = theme.palette.getContrastText(itemColour)
  const outlineWidth = 1
  const eventBus = useEventBus()
  const onClick = useCallback(() => {
    eventBus.dispatch({
      type: 'mapMarkerClick',
      domainType,
      instance
    })
  }, [domainType, eventBus, instance])
  return (
    <DomainTypeTooltip
      domainType={domainType}
      instance={instance}
      enableHeadingLink
      enterDelay={500}
      enterNextDelay={500}
      hoverEventSource={HOVER_EVENT_SOURCE}
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset(props: { placement: string }) {
                if (props.placement === 'top') {
                  return [0, scale * ICON_MARKER_HEIGHT - ICON_MARKER_DIV_WIDTH / 2]
                }
                return []
              }
            }
          }
        ]
      }}>
      <TooltipMarker
        position={position}
        icon={icon}
        cornerIcon={cornerIcon}
        iconColour={iconColour}
        iconBackgroundColour={itemColour}
        markerColour={markerColour}
        outlineColour={outlineColour}
        outlineWidth={outlineWidth}
        scale={scale}
        selectedIndex={selected
          ? selectedIndex
          : hoveringRouteIndex}
        selected={selected || hoveringRoute}
        blinking={blinking}
        onClick={onClick} />
    </DomainTypeTooltip>
  )
}

interface Props {
  readonly domainType: DomainType
  readonly instances: DomainTypeInstance[]
  readonly isFullScreen: boolean
  onResize(action: 'maximise' | 'minimise' | 'close'): void
}

export default function DomainTypeMap({
  domainType,
  instances,
  isFullScreen,
  onResize
}: Props): JSX.Element {
  const containerRef = useRef<HTMLDivElement>(null)
  const mapRef = useRef<Map>()
  useResizeObserver(containerRef, () => mapRef.current?.invalidateSize({
    pan: false,
    debounceMoveend: true
  }))
  const domainTypes = useSelector(getAllDomainTypes)
  const subtypesCache = useSubtypesCache()
  const mapInstances = useMemo(() => {
    return instances
      .map(instance => {
        const subtype = getSubtype(domainTypes, domainType, instance, subtypesCache) ?? domainType
        const mapAttributeChains = getMapAttributeChains(domainTypes, subtype)
        const gps = getDomainTypeSetting(domainTypes, subtype, 'Gps')
        const mapBy = mapAttributeChains
          .find(attributeChain => attributeChain.slice().reverse()
            .map(attribute => attribute.Name)
            .join(ATTRIBUTE_PATH_SEPARATOR) === gps)
        if (mapBy === undefined) {
          return null
        }
        const latLong = getLatLong(instance, mapBy)
        if (latLong === undefined) {
          return null
        }
        const id = getUniqueId(domainTypes, subtype, instance)
        return [instance, subtype, latLong, id] as const
      })
      .filter(isNotNullOrUndefined)
  }, [domainType, domainTypes, instances, subtypesCache])
  const otherMapItems = useMemo(() => {
    return instances
      .flatMap(instance => {
        const subtype = getSubtype(domainTypes, domainType, instance, subtypesCache) ?? domainType
        const mapAttributeChains = getMapAttributeChains(domainTypes, subtype)
        return mapAttributeChains
          .map(mapBy => {
            const latLong = getLatLong(instance, mapBy)
            if (latLong === undefined) {
              return null
            }
            const [mapAttribute, ...reversePath] = mapBy
            const path = reversePath.reverse()
            let mapInstance = instance
            const remainingPath = path
            for (const attribute of path) {
              remainingPath.shift()
              const attributeDomainType = domainTypes[attribute.AttributeDomainType]
              if (attributeDomainType === undefined) {
                return null
              }
              const nextInstance = getValue(mapInstance, attribute)
              if (nextInstance === null) {
                return null
              }
              const attributeSubtype = getSubtype(domainTypes, attributeDomainType, nextInstance, subtypesCache) ?? attributeDomainType
              const gps = getDomainTypeSetting(domainTypes, attributeSubtype, 'Gps')
              if (gps !== remainingPath.map(attribute => attribute.Name).concat(mapAttribute.Name).join(ATTRIBUTE_PATH_SEPARATOR)) {
                return null
              }
              if (hasApi(domainTypes, attributeSubtype)) {
                const id = getUniqueId(domainTypes, attributeSubtype, nextInstance)
                return [nextInstance, attributeSubtype, latLong, id] as const
              }
              mapInstance = nextInstance
            }
            return null
          })
      })
      .filter(isNotNullOrUndefined)
      .filter((item, index, array) => array
        .findIndex(otherItem => otherItem[3] === item[3]) === index)
  }, [domainType, domainTypes, instances, subtypesCache])
  const fitBounds = useCallback(() => {
    if (mapInstances.length > 0) {
      mapRef.current?.fitBounds(mapInstances.concat(otherMapItems).map(([, , latLong]) => latLong))
    }
  }, [mapInstances, otherMapItems])
  useEffect(() => fitBounds(), [fitBounds])
  const [hoveringIds, setHoveringIds] = useState<string[]>([])
  const onHoverStart = useCallback((event: HoverStartEvent) => {
    if (event.source === HOVER_EVENT_SOURCE) {
      return
    }
    const id = getUniqueId(domainTypes, event.domainType, event.instance)
    setHoveringIds(hoveringIds => hoveringIds.concat(id))
  }, [domainTypes])
  const onHoverEnd = useCallback((event: HoverEndEvent) => {
    if (event.source === HOVER_EVENT_SOURCE) {
      return
    }
    const id = getUniqueId(domainTypes, event.domainType, event.instance)
    setHoveringIds(hoveringIds => hoveringIds.filter(otherId => otherId !== id))
  }, [domainTypes])
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const onTimelineListSelectionChange = useCallback((event: TimelineListSelectionChangeEvent) => {
    setSelectedIds(event.selectedIds)
  }, [])
  const [routedIds, setRoutedIds] = useState<string[]>([])
  const [routeDomainType, setRouteDomainType] = useState<DomainType | undefined>()
  const onTimelineRoutesChange = useCallback((event: TimelineRoutesChangeEvent) => {
    setRoutedIds(event.routedIds)
    setRouteDomainType(event.routeDomainType)
  }, [])
  const [hoveringRouteIds, setHoveringRouteIds] = useState<string[]>([])
  const onTimelineRouteHoverStart = useCallback((event: TimelineRouteHoverStartEvent) => {
    setHoveringRouteIds(event.stopIds)
  }, [])
  const onTimelineRouteHoverEnd = useCallback((event: TimelineRouteHoverEndEvent) => {
    setHoveringRouteIds([])
  }, [])
  useEventHandler('hoverStart', onHoverStart)
  useEventHandler('hoverEnd', onHoverEnd)
  useEventHandler('timelineListSelectionChange', onTimelineListSelectionChange)
  useEventHandler('timelineRoutesChange', onTimelineRoutesChange)
  useEventHandler('timelineRouteHoverStart', onTimelineRouteHoverStart)
  useEventHandler('timelineRouteHoverEnd', onTimelineRouteHoverEnd)
  return (
    <Box
      ref={containerRef}
      pt={1}
      height='calc(100vh - 64px)'
      sx={{
        '& .marker-blinking svg': {
          animation: 'blinker 1s linear infinite',
          zIndex: 1000
        },
        '& .selected-icon': {
          display: 'none'
        },
        '& .marker-selected .domain-type-icon': {
          display: 'none'
        },
        '& .marker-selected .selected-icon': {
          display: 'block'
        }
      }}>
      <Stack
        gap={1}
        pl={PAGE_PADDING}
        pr={PAGE_PADDING}
        direction='row'
        alignItems='center'
        sx={{
          mb: 1,
          minHeight: '40px'
        }}>
        <DomainTypeHeading
          title='Map:'
          domainType={domainType}
          plural
          count={instances.length}
          isLoading={false}>
          <Box flexGrow={1} />
          <ButtonGroup
            size='small'
            variant='text'>
            {isFullScreen
              ? (
                <TooltipIconButton
                  tooltipText='Exit Full Screen'
                  size='small'
                  icon={<FullscreenExitOutlined />}
                  onClick={() => onResize('minimise')} />
              )
              : (
                <TooltipIconButton
                  tooltipText='Full Screen'
                  size='small'
                  icon={<FullscreenOutlined />}
                  onClick={() => onResize('maximise')} />
              )}
            <TooltipIconButton
              tooltipText='Close'
              size='small'
              icon={<CloseOutlined />}
              onClick={() => onResize('close')} />
          </ButtonGroup>
        </DomainTypeHeading>
      </Stack>
      <MapContainer
        ref={map => {
          const isFirstRender = mapRef.current === undefined
          if (map !== null) {
            mapRef.current = map
          }
          if (isFirstRender) {
            fitBounds()
          }
        }}
        center={[51.505, -0.09]}
        scrollWheelZoom
        style={{
          width: '100%',
          height: 'calc(100% - 40px - 8px)'
        }}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
        {otherMapItems.map(([instance, domainType, position]) => {
          const id = getUniqueId(domainTypes, domainType, instance)
          return (
            <DomainTypeMarker
              key={id}
              domainType={domainType}
              instance={instance}
              position={position}
              scale={1.5}
              blinking={hoveringIds.includes(id)} />
          )
        })}
        {mapInstances.map(([instance, domainType, position]) => {
          const id = getUniqueId(domainTypes, domainType, instance)
          if (!hoveringIds.includes(id)) {
            return null
          }
          return (
            <DomainTypeMarker
              key={id}
              domainType={domainType}
              instance={instance}
              position={position}
              selectedIndex={selectedIds.indexOf(id)}
              blinking
              routeDomainType={routeDomainType}
              routed={routedIds.includes(id)}
              hoveringRouteIndex={hoveringRouteIds.indexOf(id)} />
          )
        })}
        <MarkerClusterGroup
          chunkedLoading
          maxClusterRadius={5}>
          {mapInstances.map(([instance, domainType, position]) => {
            const id = getUniqueId(domainTypes, domainType, instance)
            if (hoveringIds.includes(id)) {
              return null
            }
            return (
              <DomainTypeMarker
                key={id}
                domainType={domainType}
                instance={instance}
                position={position}
                selectedIndex={selectedIds.indexOf(id)}
                routeDomainType={routeDomainType}
                routed={routedIds.includes(id)}
                hoveringRouteIndex={hoveringRouteIds.indexOf(id)} />
            )
          })}
        </MarkerClusterGroup>
      </MapContainer>
    </Box>
  )
}