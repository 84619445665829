import * as E from 'fp-ts/Either'
import { useEffect, useMemo, useState } from 'react'
import { DataformResultsAttributeValue, NonListDataformResultsAttributeValue } from 'types'
import { Dataform, Results } from 'types/dataform'
import { DataformCodec } from 'utils/codecs/dataform'
import { stringifyFilterValue } from 'utils/filters'
import { isListAttributeValue, isNullOrUndefined } from 'utils/helpers'
import { getBySelectionCriteria, getEquipmentFromTask } from 'utils/helpers/dataform'
import { SignedInApi, useApi } from './useApi'
import { useCancellableApiSession } from './useCancellableApiSession'
import { useDomainTypeContextInstance } from './useDomainTypeContextInstance'
import { limitSearchPageSize } from 'utils/api'

export function useDataformResults(
  attributeValue: DataformResultsAttributeValue,
  readOnly = false
): [Dataform | null, Results | null, string | undefined] {
  const api = useApi()
  const [dataform, setDataform] = useState<Dataform | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const work = useDomainTypeContextInstance('Work', 'Work')
  const task = useDomainTypeContextInstance('Task', 'Task')
  const context = useMemo(() => ({
    Work: work,
    Task: task,
    ...getEquipmentFromTask(task)
  }), [task, work])
  useEffect(() => {
    setErrorMessage(undefined)
    setDataform(null)
  }, [attributeValue.attribute.DataformName])
  const loadDataform = useCancellableApiSession(api)
  useEffect(() => {
    const apiSession = loadDataform.cancelPreviousAndStartNew()
    if (dataform !== null) {
      return
    }
    async function loadById(
      attributeValue: NonListDataformResultsAttributeValue & { value: Results },
      api: SignedInApi
    ) {
      const response = await api.get(
        'Dataform',
        attributeValue.value.DataformId
      )
      if (E.isRight(response)) {
        if (DataformCodec.is(response.right)) {
          setDataform(response.right)
        } else {
          setErrorMessage('The dataform has an invalid format')
        }
      } else {
        setErrorMessage('The dataform for which these results were captured no longer exists')
      }
    }
    async function loadByName(
      attributeValue: NonListDataformResultsAttributeValue,
      api: SignedInApi
    ) {
      if (isNullOrUndefined(attributeValue.attribute.DataformName)) {
        setErrorMessage('Dataform Name is required')
        return
      }
      const response = await api.search(
        'Dataform',
        'Dataform',
        [],
        [
          {
            Property: 'Name',
            Operator: 'eq',
            Value: stringifyFilterValue(attributeValue.attribute.DataformName)
          },
          {
            Property: 'deleted',
            Operator: 'eq',
            Value: stringifyFilterValue(false)
          }
        ],
        [],
        1,
        limitSearchPageSize(1)
      )
      if (E.isRight(response)) {
        const dataform = response.right.results[0]
        if (dataform === undefined) {
          setErrorMessage(`No dataform was found with name ${attributeValue.attribute.DataformName}`)
        } else if (DataformCodec.is(dataform)) {
          setDataform(dataform)
        } else {
          setErrorMessage('The dataform has an invalid format')
        }
      } else {
        setErrorMessage(`Failed to load dataform ${attributeValue.attribute.DataformName}`)
      }
    }
    async function loadBySelectionCriteria(
      attributeValue: NonListDataformResultsAttributeValue,
      api: SignedInApi
    ) {
      if (isNullOrUndefined(attributeValue.attribute.SelectionCategory)) {
        setErrorMessage('Selection Category is required')
        return
      }
      if (isNullOrUndefined(attributeValue.attribute.SelectionSubcategory)) {
        setErrorMessage('Selection Subcategory is required')
        return
      }
      const dataforms = await getBySelectionCriteria(
        api,
        attributeValue.attribute.SelectionCategory,
        attributeValue.attribute.SelectionSubcategory,
        context,
        attributeValue.attribute.DefaultSelectionProperties ?? undefined,
        attributeValue.attribute.Purpose ?? undefined
      )
      const first = dataforms[0]
      if (!isNullOrUndefined(first)) {
        setDataform(first.dataform)
      } else {
        setErrorMessage('No dataform was found matching the selection criteria')
      }
    }
    if (isListAttributeValue(attributeValue)) {
      return
    }
    if (!apiSession.isSignedIn) {
      return
    }
    if (!isNullOrUndefined(attributeValue.value)) {
      loadById(
        {
          ...attributeValue,
          value: attributeValue.value
        },
        apiSession
      )
      return
    }
    if (readOnly) {
      return
    }
    if (attributeValue.attribute.SelectBy === 'name') {
      loadByName(attributeValue, apiSession)
    } else {
      loadBySelectionCriteria(attributeValue, apiSession)
    }
    return loadDataform.cancel
  }, [attributeValue, context, dataform, loadDataform, readOnly])
  if (isListAttributeValue(attributeValue)) {
    return [null, null, errorMessage]
  }
  return [dataform, attributeValue.value, errorMessage]
}