import { Avatar, darken, decomposeColor, iconClasses, lighten, useTheme } from '@mui/material'
import { ComponentProps } from 'react'
import { getTransparentBackgroundColour } from 'utils/helpers'
import CornerIcon from './CornerIcon'
import { CELL_AVATAR_BACKGROUND_ALPHA } from 'utils/constants'

interface Props extends Omit<ComponentProps<typeof CornerIcon>, 'color'> {
  readonly background: string
  readonly avatarScale?: number
  readonly filled?: boolean
}

export default function AvatarCornerIcon({
  background,
  avatarScale = 1,
  filled = false,
  ...props
}: Props): JSX.Element {
  const theme = useTheme()
  const transparentBackground = getTransparentBackgroundColour(
    background,
    theme.palette.mode
  )
  const textShadowColor = {
    dark: darken,
    light: lighten
  }[theme.palette.mode](background, 0.9 - (decomposeColor(transparentBackground).values[3] ?? CELL_AVATAR_BACKGROUND_ALPHA))
  return (
    <Avatar
      variant='rounded'
      sx={{
        background: filled
          ? background
          : transparentBackground,
        width: `${1.5 * avatarScale}rem`,
        height: `${1.5 * avatarScale}rem`,
        [`& .${iconClasses.root}`]: {
          fontSize: `${avatarScale}rem`
        }
      }}>
      <CornerIcon
        {...props}
        color={filled
          ? theme.palette.getContrastText(background)
          : background}
        textShadowColor={filled
          ? background
          : textShadowColor} />
    </Avatar>
  )
}