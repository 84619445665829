import { useCallback, useContext } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { AttributeValue, DomainType, DomainTypeInstance, Filter, Sort } from 'types'
import { DomainTypeSettingsContext } from 'utils/context'
import { applyAllFilters, applyAnyFilters, getAnyAllFilters } from 'utils/filters'
import { getAttributeChainValues, getSortableValue } from 'utils/helpers'
import { useFilterContext } from 'utils/hooks'

interface Output {
  applyFilters<T>(items: T[], getInstance: (item: T) => DomainTypeInstance): T[]
  applySorts<T>(items: T[], getInstance: (item: T) => DomainTypeInstance): T[]
  applyPage<T>(items: T[]): T[]
}

export default function useApplyFiltersSortsAndPage(
  domainType: DomainType,
  filters: Filter[],
  filterLinkOperator: 'and' | 'or',
  searchText: string,
  sorts: Sort[],
  page: number,
  pageSize: number
): Output {
  const domainTypes = useSelector(getAllDomainTypes)
  const filterContext = useFilterContext()
  const settingsContext = useContext(DomainTypeSettingsContext)
  const applyFilters = useCallback(<T>(
    items: T[],
    getInstance: (item: T) => DomainTypeInstance
  ): T[] => {
    const [anyFilters, allFilters] = getAnyAllFilters(
      domainTypes,
      domainType,
      filterLinkOperator,
      filters,
      searchText
    )
    return items
      .filter(row => {
        if (anyFilters.length === 0) {
          return row
        }
        return applyAnyFilters(
          domainTypes,
          domainType,
          getInstance(row),
          anyFilters,
          filterContext
        )
      })
      .filter(row => {
        return applyAllFilters(
          domainTypes,
          domainType,
          getInstance(row),
          allFilters,
          filterContext
        )
      })
  }, [domainTypes, domainType, filterLinkOperator, filters, searchText, filterContext])
  const applySorts = useCallback(<T>(
    items: T[],
    getInstance: (item: T) => DomainTypeInstance
  ): T[] => {
    if (sorts.length === 0) {
      return items
    }
    const sort = sorts[0]
    if (sort === undefined) {
      return items
    }
    const sortedItems = [...items]
    sortedItems.sort((item1: T, item2: T) => {
      const attributeChainValue1 = getAttributeChainValues(
        domainTypes,
        domainType,
        getInstance(item1),
        sort.Property
      )
      const attributeChainValue2 = getAttributeChainValues(
        domainTypes,
        domainType,
        getInstance(item2),
        sort.Property
      )
      const item1Value = attributeChainValue1 !== undefined
        ? getSortableValue(
          settingsContext,
          domainTypes,
          {
            attribute: attributeChainValue1.attribute,
            value: attributeChainValue1.values[0] ?? null
          } as AttributeValue
        )
        : null
      const item2Value = attributeChainValue2
        ? getSortableValue(
          settingsContext,
          domainTypes,
          {
            attribute: attributeChainValue2.attribute,
            value: attributeChainValue2.values[0] ?? null
          } as AttributeValue
        )
        : null
      if (item1Value === item2Value) {
        return 0
      }

      else if (item1Value === null) {
        return -1
      }
      else if (item2Value === null) {
        return 1
      }

      if (item1Value < item2Value) {
        return -1
      }
      if (item1Value > item2Value) {
        return 1
      }

      return 0
    })
    return sort.Direction === 'desc' ? sortedItems.reverse() : sortedItems
  }, [domainType, domainTypes, settingsContext, sorts])
  const applyPage = useCallback(<T>(items: T[]): T[] => {
    const startIndex = (page - 1) * pageSize
    return items.slice(startIndex, startIndex + pageSize)
  }, [page, pageSize])
  return {
    applyFilters,
    applySorts,
    applyPage
  }
}