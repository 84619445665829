import { Box, CircularProgress, Divider, List, ListItemButton, ListSubheader, Paper, Stack, listItemButtonClasses, listSubheaderClasses, useTheme } from '@mui/material'
import { GridColumns } from '@mui/x-data-grid'
import { DomainTypeCell } from 'components/attribute/AttributeCell'
import EnumCell from 'components/attribute/AttributeCell/EnumCell'
import ScrollBar from 'components/navigation/ScrollBar'
import { isNumber } from 'fp-ts/lib/number'
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { DomainType, DomainTypeAttribute, EnumAttribute, Filter, RefAttribute } from 'types'
import { stringifyFilterValue } from 'utils/filters'
import { getDomainTypeAttribute, getDomainTypeSetting, isNotNullOrUndefined } from 'utils/helpers'
import { useApiDomainTypeAutocomplete } from 'utils/hooks'

interface Props {
  readonly domainType: DomainType
  readonly columns: GridColumns
  readonly searchText: string
  onAddFilter(filter: Filter): void
  readonly selectedSearchColumn: number | null
  readonly selectedSearchValue: number | null
  setSearchColumns(searchColumns: number[] | null): void
  readonly setSelectedFilter: Dispatch<SetStateAction<Filter | null>>
}

interface EnumQuickFilterColumn {
  readonly type: 'enum'
  readonly column: GridColumns[number]
  readonly attribute: EnumAttribute
}

interface DomainTypeQuickFilterColumn {
  readonly type: 'domainType'
  readonly column: GridColumns[number]
  readonly attribute: DomainTypeAttribute
  readonly attributeDomainType: DomainType
}

interface RefQuickFilterColumn {
  readonly type: 'ref'
  readonly column: GridColumns[number]
  readonly attribute: RefAttribute
  readonly attributeDomainType: DomainType
}

interface NonApiDomainTypeQuickFilterColumn {
  readonly type: 'nonApiDomainType'
  readonly column: GridColumns[number]
}

interface StringQuickFilterColumn {
  readonly type: 'string'
  readonly column: GridColumns[number]
}

type QuickFilterColumn =
  | EnumQuickFilterColumn
  | DomainTypeQuickFilterColumn
  | RefQuickFilterColumn
  | NonApiDomainTypeQuickFilterColumn
  | StringQuickFilterColumn

function stripDiacritics(string: string) {
  return typeof string.normalize !== 'undefined'
    ? string.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    : string
}

type IsLoading = '0'
const IS_LOADING: IsLoading = '0'
type LoadingSearchColumn =
  | number
  | IsLoading
  | null

interface EnumQuickFiltersProps {
  readonly column: GridColumns[number]
  readonly attribute: EnumAttribute
  readonly searchText: string
  onAddFilter(filter: Filter): void
  readonly columnIndex: number
  readonly selectedIndex: number | null
  readonly setLoadingSearchColumns: Dispatch<SetStateAction<LoadingSearchColumn[]>>
  readonly setSelectedFilter: Dispatch<SetStateAction<Filter | null>>
}

function EnumQuickFilters({
  column,
  attribute,
  searchText,
  onAddFilter,
  columnIndex,
  selectedIndex,
  setLoadingSearchColumns,
  setSelectedFilter
}: EnumQuickFiltersProps): JSX.Element | null {
  const results = useMemo(() => {
    return attribute.EnumeratedType.Values
      .filter(enumeratedValue => {
        return stripDiacritics(enumeratedValue.Description.toLowerCase()).indexOf(searchText) > -1
      })
  }, [attribute.EnumeratedType.Values, searchText])
  useEffect(() => {
    setLoadingSearchColumns(previous => [
      ...previous.slice(0, columnIndex),
      results.length,
      ...previous.slice(columnIndex + 1)
    ])
  }, [columnIndex, results, setLoadingSearchColumns])
  if (results.length === 0) {
    return null
  }
  return (
    <>
      <Divider />
      <ListSubheader>
        {column.headerName}
      </ListSubheader>
      {results
        .map((enumeratedValue, index) => {
          const filter = {
            Property: column.field,
            Operator: 'eq',
            Value: stringifyFilterValue(enumeratedValue.Value)
          }
          return (
            <ListItemButton
              key={enumeratedValue.Id}
              selected={selectedIndex === index}
              ref={node => {
                if (selectedIndex === index) {
                  node?.scrollIntoView({ block: 'center' })
                  setSelectedFilter(previous => {
                    if (previous?.Property === filter.Property
                      && previous.Operator === filter.Operator
                      && previous.Value === filter.Value) {
                      return previous
                    }
                    return filter
                  })
                }
              }}
              onClick={() => onAddFilter(filter)}>
              <span>is</span>
              <EnumCell
                attributeValue={{
                  attribute,
                  value: enumeratedValue.Value
                }} />
            </ListItemButton>
          )
        })}
    </>
  )
}

interface DomainTypeQuickFiltersProps {
  readonly column: GridColumns[number]
  readonly attribute: DomainTypeAttribute
  readonly attributeDomainType: DomainType
  readonly searchText: string
  onAddFilter(filter: Filter): void
  readonly columnIndex: number
  readonly selectedIndex: number | null
  readonly setLoadingSearchColumns: Dispatch<SetStateAction<LoadingSearchColumn[]>>
  readonly setSelectedFilter: Dispatch<SetStateAction<Filter | null>>
}

function DomainTypeQuickFilters({
  column,
  attribute,
  attributeDomainType,
  searchText,
  onAddFilter,
  columnIndex,
  selectedIndex,
  setLoadingSearchColumns,
  setSelectedFilter
}: DomainTypeQuickFiltersProps): JSX.Element | null {
  const domainTypes = useSelector(getAllDomainTypes)
  const {
    options,
    loading,
    setSearchText
  } = useApiDomainTypeAutocomplete(
    domainTypes,
    attributeDomainType,
    attribute.Filters,
    undefined,
    false,
    attribute.BypassDomainTypeFilters,
    5
  )
  useEffect(() => {
    setSearchText(searchText)
  }, [searchText, setSearchText])
  useEffect(() => {
    setLoadingSearchColumns(previous => [
      ...previous.slice(0, columnIndex),
      loading
        ? IS_LOADING
        : options.length,
      ...previous.slice(columnIndex + 1)
    ])
  }, [columnIndex, loading, options.length, setLoadingSearchColumns])
  if (loading || options.length === 0) {
    return null
  }
  return (
    <>
      <Divider />
      <ListSubheader>
        <Stack
          direction='row'
          gap={1}
          alignItems='center'>
          {column.headerName}
        </Stack>
      </ListSubheader>
      {options.map((option, index) => {
        const filter = {
          Property: column.field,
          Operator: 'eq',
          Value: stringifyFilterValue(String(option.Id))
        }
        return (
          <ListItemButton
            key={String(option.Id)}
            selected={selectedIndex === index}
            ref={node => {
              if (selectedIndex === index) {
                node?.scrollIntoView({ block: 'center' })
                setSelectedFilter(previous => {
                  if (previous?.Property === filter.Property
                    && previous.Operator === filter.Operator
                    && previous.Value === filter.Value) {
                    return previous
                  }
                  return filter
                })
              }
            }}
            onClick={() => onAddFilter(filter)}>
            <span>is</span>
            <DomainTypeCell
              attributeValue={{
                attribute,
                value: option
              }}
              disableLink />
          </ListItemButton>
        )
      })}
    </>
  )
}

interface RefQuickFiltersProps {
  readonly column: GridColumns[number]
  readonly attribute: RefAttribute
  readonly attributeDomainType: DomainType
  readonly searchText: string
  onAddFilter(filter: Filter): void
  readonly columnIndex: number
  readonly selectedIndex: number | null
  readonly setLoadingSearchColumns: Dispatch<SetStateAction<LoadingSearchColumn[]>>
  readonly setSelectedFilter: Dispatch<SetStateAction<Filter | null>>
}

function RefQuickFilters({
  column,
  attribute,
  attributeDomainType,
  searchText,
  onAddFilter,
  columnIndex,
  selectedIndex,
  setLoadingSearchColumns,
  setSelectedFilter
}: RefQuickFiltersProps): JSX.Element | null {
  const domainTypes = useSelector(getAllDomainTypes)
  const {
    options,
    loading,
    setSearchText
  } = useApiDomainTypeAutocomplete(
    domainTypes,
    attributeDomainType,
    attribute.Filters,
    undefined,
    false,
    attribute.BypassDomainTypeFilters,
    5
  )
  useEffect(() => {
    setSearchText(searchText)
  }, [searchText, setSearchText])
  useEffect(() => {
    setLoadingSearchColumns(previous => [
      ...previous.slice(0, columnIndex),
      loading
        ? IS_LOADING
        : options.length,
      ...previous.slice(columnIndex + 1)
    ])
  }, [columnIndex, loading, options.length, setLoadingSearchColumns])
  if (options.length === 0) {
    return null
  }
  return (
    <>
      <Divider />
      <ListSubheader>
        <Stack
          direction='row'
          gap={1}
          alignItems='center'>
          {column.headerName}
        </Stack>
      </ListSubheader>
      {options.map((option, index) => {
        const filter = {
          Property: column.field,
          Operator: 'eq',
          Value: stringifyFilterValue(String(option.Id))
        }
        return (
          <ListItemButton
            key={String(option.Id)}
            selected={selectedIndex === index}
            ref={node => {
              if (selectedIndex === index) {
                node?.scrollIntoView({ block: 'center' })
                setSelectedFilter(previous => {
                  if (previous?.Property === filter.Property
                    && previous.Operator === filter.Operator
                    && previous.Value === filter.Value) {
                    return previous
                  }
                  return filter
                })
              }
            }}
            onClick={() => onAddFilter(filter)}>
            <span>is</span>
            <DomainTypeCell
              attributeValue={{
                attribute: {
                  ...attribute,
                  AttributeType: 'domainType'
                },
                value: option
              }}
              disableLink />
          </ListItemButton>
        )
      })}
    </>
  )
}

interface NonApiDomainTypeQuickFiltersProps {
  readonly column: GridColumns[number]
  readonly searchText: string
  onAddFilter(filter: Filter): void
  readonly columnIndex: number
  readonly selectedIndex: number | null
  readonly setLoadingSearchColumns: Dispatch<SetStateAction<LoadingSearchColumn[]>>
  readonly setSelectedFilter: Dispatch<SetStateAction<Filter | null>>
}

function NonApiDomainTypeQuickFilters({
  column,
  searchText,
  onAddFilter,
  columnIndex,
  selectedIndex,
  setLoadingSearchColumns,
  setSelectedFilter
}: NonApiDomainTypeQuickFiltersProps): JSX.Element | null {
  useEffect(() => {
    setLoadingSearchColumns(previous => [
      ...previous.slice(0, columnIndex),
      1,
      ...previous.slice(columnIndex + 1)
    ])
  }, [columnIndex, searchText, setLoadingSearchColumns])
  const filter = {
    Property: column.field,
    Operator: 'like',
    Value: stringifyFilterValue(searchText)
  }
  return (
    <>
      <Divider />
      <ListSubheader>
        <Stack
          direction='row'
          gap={1}
          alignItems='center'>
          {column.headerName}
        </Stack>
      </ListSubheader>
      <ListItemButton
        selected={selectedIndex === 0}
        ref={node => {
          if (selectedIndex === 0) {
            node?.scrollIntoView({ block: 'center' })
            setSelectedFilter(previous => {
              if (previous?.Property === filter.Property
                && previous.Operator === filter.Operator
                && previous.Value === filter.Value) {
                return previous
              }
              return filter
            })
          }
        }}
        onClick={() => onAddFilter(filter)}>
        <span>contains</span>
        &quot;{searchText}&quot;
      </ListItemButton>
    </>
  )
}

interface StringQuickFiltersProps {
  readonly column: GridColumns[number]
  readonly searchText: string
  onAddFilter(filter: Filter): void
  readonly columnIndex: number
  readonly selectedIndex: number | null
  readonly setLoadingSearchColumns: Dispatch<SetStateAction<LoadingSearchColumn[]>>
  readonly setSelectedFilter: Dispatch<SetStateAction<Filter | null>>
}

function StringQuickFilters({
  column,
  searchText,
  onAddFilter,
  columnIndex,
  selectedIndex,
  setLoadingSearchColumns,
  setSelectedFilter
}: StringQuickFiltersProps): JSX.Element | null {
  const filters = [
    {
      Property: column.field,
      Operator: 'like',
      Value: stringifyFilterValue(searchText)
    },
    {
      Property: column.field,
      Operator: 'eq',
      Value: stringifyFilterValue(searchText)
    }
  ]
  useEffect(() => {
    setLoadingSearchColumns(previous => [
      ...previous.slice(0, columnIndex),
      2,
      ...previous.slice(columnIndex + 1)
    ])
  }, [columnIndex, searchText, setLoadingSearchColumns])

  return (
    <>
      <Divider />
      <ListSubheader>
        <Stack
          direction='row'
          gap={1}
          alignItems='center'>
          {column.headerName}
        </Stack>
      </ListSubheader>
      {filters.map((filter, index) => (
        <ListItemButton
          key={index}
          selected={selectedIndex === index}
          ref={node => {
            if (selectedIndex === index) {
              node?.scrollIntoView({ block: 'center' })
              setSelectedFilter(previous => {
                if (previous?.Property === filter.Property
                  && previous.Operator === filter.Operator
                  && previous.Value === filter.Value) {
                  return previous
                }
                return filter
              })
            }
          }}
          onClick={() => onAddFilter(filter)}>
          <span>{filter.Operator === 'eq'
            ? 'is'
            : 'contains'}</span>
          &quot;{searchText}&quot;
        </ListItemButton>
      ))}
    </>
  )
}

export default function QuickFiltersPanel({
  domainType,
  columns,
  searchText,
  onAddFilter,
  selectedSearchColumn,
  selectedSearchValue,
  setSearchColumns,
  setSelectedFilter
}: Props): JSX.Element | null {
  const domainTypes = useSelector(getAllDomainTypes)
  const searchableColumns = useMemo(() => {
    return columns
      .filter(column => column.filterable)
      .filter(column => !(column.hide ?? false))
      .flatMap((column): QuickFilterColumn[] => {
        const attribute = getDomainTypeAttribute(domainTypes, domainType, column.field)
        if (attribute === undefined) {
          return []
        }
        if (attribute.AttributeType === 'enum') {
          return [
            {
              type: 'enum',
              column,
              attribute
            }
          ]
        }
        if (attribute.AttributeType === 'string') {
          return [
            {
              type: 'string',
              column
            }
          ]
        }
        if (attribute.AttributeType !== 'domainType'
          && attribute.AttributeType !== 'ref') {
          return []
        }
        const attributeDomainType = domainTypes[attribute.AttributeDomainType]
        if (attributeDomainType === undefined) {
          return []
        }
        if (!(getDomainTypeSetting(domainTypes, attributeDomainType, 'Api') ?? false)) {
          return [
            {
              type: 'nonApiDomainType',
              column
            }
          ]
        }
        if (attribute.AttributeType === 'ref') {
          return [
            {
              type: 'ref',
              column,
              attribute,
              attributeDomainType
            }
          ]
        }
        return [
          {
            type: 'domainType',
            column,
            attribute,
            attributeDomainType
          }
        ]
      })
  }, [columns, domainType, domainTypes])
  useEffect(() => {
    setSearchColumns(null)
  }, [searchableColumns, setSearchColumns])
  const [loadingSearchColumns, setLoadingSearchColumns] = useState<LoadingSearchColumn[]>(
    searchableColumns.map(() => null)
  )
  useEffect(() => {
    if (loadingSearchColumns.every(isNotNullOrUndefined)) {
      setSearchColumns(loadingSearchColumns.map(Number))
    } else {
      setSearchColumns(null)
    }
  }, [loadingSearchColumns, setSearchColumns])
  const anyIsLoading = useMemo(() => {
    return !loadingSearchColumns.every(isNumber)
  }, [loadingSearchColumns])
  const theme = useTheme()
  if (searchableColumns.length === 0) {
    return null
  }
  return (
    <Paper
      sx={{
        mt: 1,
        [`& .${listSubheaderClasses.root}`]: {
          lineHeight: theme => theme.spacing(4)
        },
        [`& .${listItemButtonClasses.root}`]: {
          pl: 4,
          gap: 1
        }
      }}>
      <h3
        style={{
          margin: 0,
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          paddingTop: theme.spacing(1),
          display: 'flex',
          alignItems: 'center'
        }}>
        Quick Filters
        <Box
          component='span'
          flexGrow={1} />
        {anyIsLoading && (
          <CircularProgress size='16px' />
        )}
      </h3>
      <ScrollBar
        style={{
          maxHeight: 400
        }}>
        <List
          dense>
          {searchableColumns.map((searchableColumn, columnIndex) => {
            const selectedIndex = selectedSearchColumn === columnIndex
              ? selectedSearchValue
              : null
            return (
              <>
                {searchableColumn.type === 'enum' && (
                  <EnumQuickFilters
                    column={searchableColumn.column}
                    attribute={searchableColumn.attribute}
                    searchText={searchText}
                    onAddFilter={onAddFilter}
                    columnIndex={columnIndex}
                    selectedIndex={selectedIndex}
                    setLoadingSearchColumns={setLoadingSearchColumns}
                    setSelectedFilter={setSelectedFilter} />
                )}
                {searchableColumn.type === 'domainType' && (
                  <DomainTypeQuickFilters
                    column={searchableColumn.column}
                    attribute={searchableColumn.attribute}
                    attributeDomainType={searchableColumn.attributeDomainType}
                    searchText={searchText}
                    onAddFilter={onAddFilter}
                    columnIndex={columnIndex}
                    selectedIndex={selectedIndex}
                    setLoadingSearchColumns={setLoadingSearchColumns}
                    setSelectedFilter={setSelectedFilter} />
                )}
                {searchableColumn.type === 'ref' && (
                  <RefQuickFilters
                    column={searchableColumn.column}
                    attribute={searchableColumn.attribute}
                    attributeDomainType={searchableColumn.attributeDomainType}
                    searchText={searchText}
                    onAddFilter={onAddFilter}
                    columnIndex={columnIndex}
                    selectedIndex={selectedIndex}
                    setLoadingSearchColumns={setLoadingSearchColumns}
                    setSelectedFilter={setSelectedFilter} />
                )}
                {searchableColumn.type === 'nonApiDomainType' && (
                  <NonApiDomainTypeQuickFilters
                    column={searchableColumn.column}
                    searchText={searchText}
                    onAddFilter={onAddFilter}
                    columnIndex={columnIndex}
                    selectedIndex={selectedIndex}
                    setLoadingSearchColumns={setLoadingSearchColumns}
                    setSelectedFilter={setSelectedFilter} />
                )}
                {searchableColumn.type === 'string' && (
                  <StringQuickFilters
                    column={searchableColumn.column}
                    searchText={searchText}
                    onAddFilter={onAddFilter}
                    columnIndex={columnIndex}
                    selectedIndex={selectedIndex}
                    setLoadingSearchColumns={setLoadingSearchColumns}
                    setSelectedFilter={setSelectedFilter} />
                )}
              </>
            )
          })}
        </List>
      </ScrollBar>
    </Paper>
  )
}