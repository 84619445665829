import { TextFieldProps } from '@mui/material'
import AttributeForm from 'components/attribute/AttributeForm'
import { ListApiDomainTypeInput, ListNonApiDomainTypeInput, NonListApiDomainTypeInput, ReadOnlyDomainTypeInput } from 'components/attribute/AttributeInput'
import AppendDomainTypeContext from 'components/domainType/AppendDomainTypeContext'
import { useEffect, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { AttributeValue, DomainType, DomainTypeAttributeValue, DomainTypeInstance, ListDomainTypeAttributeValue, PathError } from 'types'
import { getDomainTypeSetting, isListAttributeValue, isNullOrUndefined } from 'utils/helpers'
import { useDomainTypeForm } from 'utils/hooks'

interface Props {
  readonly attributeValue: DomainTypeAttributeValue
  readonly readOnly: boolean
  readonly disabled?: boolean
  readonly pathError?: PathError
  readonly textFieldProps?: Partial<TextFieldProps>
  onChange?(attributeValue: DomainTypeAttributeValue): void
}

interface InlineDomainTypeFormProps {
  readonly domainType: DomainType
  readonly instance: DomainTypeInstance | null
  readonly readOnly: boolean
  readonly disabled?: boolean
  readonly pathError: PathError | undefined
  readonly textFieldProps?: Partial<TextFieldProps>
  onInstanceChange(instance: DomainTypeInstance, attributeValues: AttributeValue[]): void
}

export function InlineAttributeForm({
  domainType,
  instance,
  readOnly,
  disabled,
  pathError,
  textFieldProps,
  onInstanceChange
}: InlineDomainTypeFormProps): JSX.Element {
  const {
    attributeValues,
    onChange
  } = useDomainTypeForm(
    domainType,
    instance,
    'edit',
    onInstanceChange
  )
  const hasRunRef = useRef(false)
  useEffect(() => {
    if (hasRunRef.current || attributeValues.length === 0) {
      return
    }
    hasRunRef.current = true
    onInstanceChange(instance ?? {}, attributeValues)
  }, [attributeValues, instance, onInstanceChange])
  return (
    <AttributeForm
      attributeValues={attributeValues}
      readOnly={readOnly}
      disabled={disabled}
      pathErrors={typeof pathError === 'object' && !Array.isArray(pathError)
        ? pathError
        : {}}
      textFieldProps={textFieldProps}
      onChange={onChange}
      isInsideAnotherForm />
  )
}

interface SingleListInlineInputProps {
  readonly attributeValue: ListDomainTypeAttributeValue
  readonly domainType: DomainType
  readonly readOnly: boolean
  readonly disabled?: boolean
  readonly pathError?: PathError
  readonly textFieldProps?: Partial<TextFieldProps>
  onChange?(attributeValue: DomainTypeAttributeValue): void
}

export function SingleListInlineInput({
  attributeValue,
  readOnly,
  disabled,
  pathError,
  textFieldProps,
  onChange,
  domainType
}: SingleListInlineInputProps): JSX.Element {
  const newAttributes = useMemo(() => {
    return [attributeValue.attribute]
  }, [attributeValue.attribute])
  const newInstances = useMemo<[DomainType, DomainTypeInstance][]>(() => {
    return [[domainType, attributeValue.value?.[0] ?? {}]]
  }, [attributeValue.value, domainType])
  return (
    <AppendDomainTypeContext
      newAttributes={newAttributes}
      newInstances={newInstances}>
      <InlineAttributeForm
        domainType={domainType}
        instance={attributeValue.value?.[0] ?? {}}
        pathError={pathError}
        readOnly={readOnly}
        disabled={disabled}
        textFieldProps={textFieldProps}
        onInstanceChange={(instance, attributeValues) => onChange?.({
          ...attributeValue,
          value: [instance],
          inlineAttributeValues: attributeValues
        })} />
    </AppendDomainTypeContext>
  )
}

export default function InlineDomainTypeInput({
  attributeValue,
  readOnly,
  disabled = false,
  pathError,
  textFieldProps,
  onChange
}: Props): JSX.Element | null {
  const domainTypes = useSelector(getAllDomainTypes)
  const domainType = domainTypes[attributeValue.attribute.AttributeDomainType]
  if (isNullOrUndefined(domainType)) {
    return null
  }
  if (readOnly) {
    return (
      <ReadOnlyDomainTypeInput
        attributeValue={attributeValue}
        pathError={pathError}
        textFieldProps={textFieldProps} />
    )
  }
  if (isListAttributeValue(attributeValue)) {
    if (getDomainTypeSetting(domainTypes, domainType, 'Api') ?? false) {
      return (
        <ListApiDomainTypeInput
          domainTypes={domainTypes}
          domainType={domainType}
          attributeValue={attributeValue}
          disabled={disabled}
          readOnly={readOnly}
          pathError={pathError}
          textFieldProps={textFieldProps}
          onChange={onChange} />
      )
    }
    if (attributeValue.attribute.Single ?? false) {
      return (
        <SingleListInlineInput
          domainType={domainType}
          attributeValue={attributeValue}
          readOnly={readOnly}
          disabled={disabled}
          pathError={pathError}
          textFieldProps={textFieldProps}
          onChange={onChange} />
      )
    }
    return (
      <ListNonApiDomainTypeInput
        domainTypes={domainTypes}
        domainType={domainType}
        attributeValue={attributeValue}
        disabled={disabled}
        readOnly={readOnly}
        pathError={pathError}
        textFieldProps={textFieldProps}
        onChange={onChange} />
    )
  } else if (getDomainTypeSetting(domainTypes, domainType, 'Api') ?? false) {
    return (
      <NonListApiDomainTypeInput
        domainTypes={domainTypes}
        domainType={domainType}
        attributeValue={attributeValue}
        disabled={disabled}
        pathError={pathError}
        textFieldProps={textFieldProps}
        onChange={onChange} />
    )
  }
  return (
    <InlineAttributeForm
      domainType={domainType}
      instance={attributeValue.value}
      pathError={pathError}
      readOnly={readOnly}
      disabled={disabled}
      textFieldProps={textFieldProps}
      onInstanceChange={(instance, attributeValues) => onChange?.({
        ...attributeValue,
        value: instance,
        inlineAttributeValues: attributeValues
      })} />
  )
}