import { ArrowDropDownOutlined } from '@mui/icons-material'
import { Button, ButtonGroup, Icon, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Popover } from '@mui/material'
import TextIconButton from 'components/utils/TextIconButton'
import * as t from 'io-ts'
import { PropsWithChildren, useCallback, useMemo, useRef, useState } from 'react'
import { ButtonLocation, DomainType } from 'types'
import { requiresNoInstances } from 'utils/helpers'
import { ButtonTarget, Button as ButtonType, useButtons, useLocalStorageState } from 'utils/hooks'
import { DomainTypeButton } from './DomainTypeButtons'
import { ButtonProps } from './DomainTypeButtons/DomainTypeButtons'

interface Props {
  readonly domainType: DomainType
  readonly on: ButtonLocation
  readonly target: ButtonTarget
  readonly additionalButtons?: ButtonProps[]
  readonly leafNodeType?: 'active' | 'nested'
}

type SplitButtonButton =
  | { id: string, type: 'domainTypeButton', button: ButtonType }
  | { id: string, type: 'additionalButton', button: ButtonProps }

export default function DomainTypeSplitButton({
  target,
  on,
  domainType,
  additionalButtons,
  leafNodeType = 'active',
  children
}: PropsWithChildren<Props>): JSX.Element | null {
  const domainTypeButtons = useButtons(domainType, target, on, undefined, leafNodeType)
  const buttons = useMemo(() => {
    return domainTypeButtons
      .filter(button => {
        if (on !== 'TableToolbar') {
          return true
        }
        if (button.type === 'edit' || button.type === 'delete') {
          return false
        }
        if (button.type === 'action') {
          return requiresNoInstances(button.action)
        }
        return true
      })
      .map((button): SplitButtonButton => {
        return {
          id: `${button.type}.${button.name}`,
          type: 'domainTypeButton',
          button
        }
      })
      .concat((additionalButtons ?? []).map((button): SplitButtonButton => ({
        id: `additional.${button.text}`,
        type: 'additionalButton',
        button
      })))
  }, [additionalButtons, domainTypeButtons, on])
  const firstEnabledId = buttons.find(button => {
    if (button.type === 'additionalButton') {
      return button.button.disabled !== true
    }
    return !button.button.disabled
  })?.id
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLDivElement>(null)
  const [selectedId, setSelectedId] = useLocalStorageState(
    `${domainType.Id}.${on}.button`,
    firstEnabledId ?? '',
    t.string
  )
  const handleMenuItemClick = useCallback((
    id: string
  ) => {
    setSelectedId(id)
    setOpen(false)
  }, [setSelectedId])

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen)
  }, [])

  const handleClose = useCallback((event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }, [])
  const lastSelectedButton = useMemo(() => {
    return buttons.find(button => button.id === selectedId)
  }, [buttons, selectedId])
  const lastSelectedEnabledButton = useMemo(() => {
    if (lastSelectedButton === undefined) {
      return null
    }
    if (lastSelectedButton.type === 'additionalButton'
      && lastSelectedButton.button.disabled === true) {
      return null
    }
    if (lastSelectedButton.type === 'domainTypeButton'
      && lastSelectedButton.button.disabled) {
      return null
    }
    return lastSelectedButton
  }, [lastSelectedButton])
  const firstEnabledButton = useMemo(() => {
    return buttons.find(button => button.id === firstEnabledId)
  }, [buttons, firstEnabledId])
  const button = lastSelectedEnabledButton ?? firstEnabledButton ?? lastSelectedButton ?? buttons[0]
  if (button === undefined) {
    return null
  }
  return (
    <>
      <ButtonGroup
        variant='contained'
        ref={anchorRef}>
        {button.type === 'domainTypeButton'
          ? (
            <DomainTypeButton
              target={target}
              button={button.button}
              Component={TextIconButton}
              onComplete={() => undefined} />
          )
          : (
            <TextIconButton
              text={button.button.text}
              icon={button.button.icon}
              disabled={button.button.disabled}
              onClick={button.button.onClick} />
          )}
        {buttons.length > 1 && (
          <Button
            size='small'
            onClick={handleToggle}>
            <ArrowDropDownOutlined />
          </Button>
        )}
      </ButtonGroup>
      {children}
      <Popover
        sx={{
          zIndex: theme => theme.zIndex.drawer + 1
        }}
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        role={undefined}>
        <Paper>
          <MenuList
            autoFocusItem>
            {buttons.map(button => (
              button.type === 'domainTypeButton'
                ? (
                  <DomainTypeButton
                    key={`${button.button.type}.${button.button.name}`}
                    target={target}
                    button={button.button}
                    Component={props => (
                      <MenuItem
                        selected={button.id === selectedId}
                        disabled={props.disabled}
                        onClick={props.onClick}>
                        <ListItemIcon>
                          <Icon>{props.icon}</Icon>
                        </ListItemIcon>
                        <ListItemText>{props.text}</ListItemText>
                      </MenuItem>
                    )}
                    onComplete={() => {
                      handleMenuItemClick(button.id)
                    }} />
                )
                : (
                  <MenuItem
                    key={button.button.text}
                    selected={button.id === selectedId}
                    disabled={button.button.disabled}
                    onClick={() => {
                      handleMenuItemClick(button.id)
                      button.button.onClick()
                    }}>
                    <ListItemIcon>
                      <Icon>{button.button.icon}</Icon>
                    </ListItemIcon>
                    <ListItemText>{button.button.text}</ListItemText>
                  </MenuItem>
                )
            ))}
          </MenuList>
        </Paper>
      </Popover>
    </>
  )
}