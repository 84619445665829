import { Backdrop, Box, CircularProgress, ClickAwayListener, Icon, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Popover, Snackbar, Stack, styled, useTheme } from '@mui/material'
import useResizeObserver from '@react-hook/resize-observer'
import ActionDialog from 'components/domainType/ActionDialog'
import DomainTypeButtons from 'components/domainType/DomainTypeButtons'
import * as O from 'fp-ts/Option'
import { Duration } from 'luxon'
import { ComponentProps, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import Timeline, { DateHeader, Id, SidebarHeader, TimelineHeaders, TimelineMarkers, TodayMarker } from 'react-calendar-timeline'
import 'react-calendar-timeline/lib/Timeline.css'
import { useSelector } from 'react-redux'
import { getAllDomainTypes, getCompany, getUser } from 'state/reducers'
import { DomainType, DomainTypeInstance, Filter } from 'types'
import { PATH_SEPARATOR } from 'utils/constants'
import { DomainTypeContext, DomainTypeSettingsContext } from 'utils/context'
import { HoverEndEvent, HoverStartEvent } from 'utils/context/EventBusContext'
import { getDomainTypeSetting, getOverridableDomainTypeSettingAttribute, getUniqueId, isDomainTypeListAttribute, isNullOrUndefined, isValidTimelineRouteByAttribute } from 'utils/helpers'
import { SnackPack, getActionButton, useEventBus, useEventHandler, useFilterContext, useNavigate, useOverriders } from 'utils/hooks'
import MissingStartDateAlert from '../MissingStartDateAlert'
import { DAY_END_OFFSET, DAY_START_OFFSET, REMOVE_FROM_ROUTE_ACTION_NAME, TIMELINE_HOVER_EVENT_SOURCE, getActionParameterValues, getAttributeValueInvalidReason, getGroupByPath, getItemFilterContext, getMinMaxTimes, getRouteFilterContext, getTimelineGroupsAndItems, getTimelineItemChains, getTimelineItemInstance, labelFormat } from '../helpers'
import { CalendarProps, ItemTimelineItem, RouteTimelineItem } from '../types'
import useAllGroups from '../useAllGroups'
import TimelineGroup from './TimelineGroup'
import TimelineItem from './TimelineItem'
import TimelineList from './TimelineList'
import useTimelineItemsActionButton from './useTimelineItemsActionButton'

interface Props extends CalendarProps {
  isLoading: boolean
  domainType: DomainType
  items: DomainTypeInstance[]
  snackPack: SnackPack
  filters: Filter[]
  setPanelOpen(panel: string): void
  onItemClick(id: string): void
}

const LIST_WIDTH = '250px'

const Root = styled(Box)((
  {
    theme
  }
) => {
  const dividerPrimary = theme.palette.grey[{
    light: '300' as const,
    dark: '700' as const
  }[theme.palette.mode]]
  const dividerSecondary = theme.palette.grey[{
    light: '200' as const,
    dark: '800' as const
  }[theme.palette.mode]]
  return {
    '& .timeline-container': {
      width: '100%',
      height: 'fit-content'
    },
    '& .timeline-container.with-list': {
      width: `calc(100% - ${LIST_WIDTH})`
    },
    '& .timeline-list': {
      width: LIST_WIDTH,
      height: 'calc(100vh - 64px - 40px - 16px - 48px - 16px - 1px - 16px - 56px)',
      position: 'sticky',
      top: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column'
    },
    '& .timeline-list-paper': {
      maxHeight: '100%'
    },
    '& .timeline-list-content': {
      height: 'calc(100% - 40px)'
    },
    '& .react-calendar-timeline .rct-dateHeader': {
      background: `${theme.palette.background.default}!important`,
      borderLeft: 'none',
      borderRight: `1px ${dividerSecondary} solid`,
      borderBottom: 'none',
      borderTop: `1px ${dividerPrimary} solid`,
      fontSize: '0.875rem'
    },
    '& .react-calendar-timeline .rct-dateHeader-primary': {
      borderTop: 'none',
      color: theme.palette.text.primary
    },
    '& .rct-header-root': {
      background: `${theme.palette.background.default}!important`,
      borderRight: 'none'
    },
    '& .navigationBox': {
      background: `${theme.palette.background.default}!important`
    },
    '& .rct-item ': {
      background: theme.palette.primary.main,
      margin: 0,
      borderRadius: '5px',
      textAlign: 'left',
      overflowX: 'clip',
      cursor: 'pointer!important',
      fontSize: '1rem!important',
      border: `1px solid ${dividerSecondary}!important`,
      userSelect: 'none',
      minWidth: '20px'
    },
    '& .rct-item.rct-route': {
      border: 'none!important',
      overflow: 'visible'
    },
    '& .rct-item.rct-route .rct-item-content': {
      overflow: 'visible'
    },
    '& .react-calendar-timeline .rct-item .rct-item-content': {
      whiteSpace: 'nowrap',
      display: 'inline-flex',
      alignItems: 'center',
      flexWrap: 'nowrap',
      lineHeight: 'initial'
    },
    '& .rct-item-handler-left': {
      left: '0px !important'
    },
    '& .rct-item-handler': {
      position: 'absolute',
      top: '4px',
      bottom: 0,
      width: 'unset!important',
      maxWidth: 'unset!important',
      minWidth: 'unset!important',
      display: 'flex',
      alignItems: 'center'
    },
    '& .rct-item-handler-right': {
      right: '0px !important'
    },
    '& .rct-item-handler-icon': {
      display: 'block',
      width: '10px',
      marginTop: 'auto',
      marginBottom: 'auto',
      height: '100%',
      cursor: 'ew-resize'
    },
    '& .rct-hl-odd': {
      background: `${theme.palette.background.paper}!important`
    },
    '& .react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd': {
      background: `${theme.palette.background.paper}!important`
    },
    '& .react-calendar-timeline .rct-scroll': {
      overflowX: 'hidden !important'
    },
    '& .react-calendar-timeline .rct-horizontal-lines .rct-hl-even, .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd': {
      borderBottom: `1px ${dividerSecondary} solid`
    },
    '& .react-calendar-timeline .rct-horizontal-lines .rct-hl-even:last-child, .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd:last-child': {
      borderBottom: 'none'
    },
    '& .react-calendar-timeline .rct-vertical-lines .rct-vl': {
      borderLeft: 'none',
      borderRight: `1px ${dividerSecondary} solid`
    },
    '& .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6, .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0': {
      background: 'unset'
    },
    '& .react-calendar-timeline .rct-sidebar .rct-sidebar-row': {
      borderBottom: `1px ${dividerSecondary} solid`,
      display: 'flex',
      lineHeight: 'unset!important',
      alignItems: 'center'
    },
    '& .react-calendar-timeline .rct-sidebar .rct-sidebar-row:last-child': {
      borderBottom: 'none'
    },
    '& .react-calendar-timeline .rct-sidebar': {
      borderRight: `1px ${dividerPrimary} solid`,
      fontSize: '0.875rem'
    },
    '& .react-calendar-timeline .rct-header-root': {
      borderBottom: `1px ${dividerPrimary} solid`
    },
    '& .react-calendar-timeline .rct-header-root > div:first-child': {
      borderRight: `1px ${dividerPrimary} solid`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '0.875rem',
      padding: '0px'
    },
    '& .react-calendar-timeline .rct-header-root > div[style*="width: 0px"]:first-child': {
      borderRight: 'none'
    },
    '& .react-calendar-timeline .rct-header-root > div:last-child:not(.rct-calendar-header)': {
      borderLeft: `1px ${dividerPrimary} solid`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '0.875rem',
      padding: '4px'
    },
    '& .rct-scroll': {
      borderRight: 'none'
    },
    '& .react-calendar-timeline .rct-sidebar.rct-sidebar-right': {
      borderLeft: `1px ${dividerPrimary} solid`
    },
    '& .react-calendar-timeline .rct-calendar-header': {
      border: 'none'
    },
    '& .timeline-item-blinking': {
      animation: 'blinker 1s linear infinite'
    }
  }
})

const components: ComponentProps<typeof DomainTypeButtons>['components'] = {
  Container: MenuList,
  Button: props => (
    <MenuItem
      disabled={props.disabled}
      onClick={props.onClick}>
      <ListItemIcon>
        <Icon>{props.icon}</Icon>
      </ListItemIcon>
      <ListItemText>{props.text}</ListItemText>
    </MenuItem>
  ),
  Empty: () => (
    <MenuItem
      disabled>
      <ListItemText>No Actions</ListItemText>
    </MenuItem>
  )
}

const DAY_MILLIS = Duration.fromDurationLike({ days: 1 }).toMillis()

export default function TimelineView({
  isLoading,
  domainType,
  items,
  view,
  date,
  editingItem,
  movingStop,
  actionDetails,
  actionDialogOpen,
  snackPack,
  filters,
  setPanelOpen,
  onChangeItemDates,
  onCloseActionDialog,
  onPerformAction,
  onItemClick,
  onMoveRouteStop
}: Props): JSX.Element | null {
  useEffect(() => {
    window.dispatchEvent(new Event('resize'))
  }, [items])
  const [width, setWidth] = useState(Math.random())
  const ref = useRef<HTMLDivElement | null>(null)
  useResizeObserver(ref, entry => setWidth(entry.contentRect.width))
  const domainTypes = useSelector(getAllDomainTypes)
  const overriders = useOverriders()
  const timelineItemsAttribute = getOverridableDomainTypeSettingAttribute(domainTypes, domainType, overriders, 'TimelineItems')
  let itemsDomainType = domainType
  if (isDomainTypeListAttribute(timelineItemsAttribute)) {
    itemsDomainType = domainTypes[timelineItemsAttribute.AttributeDomainType] ?? domainType
  }
  const user = useSelector(getUser)
  const company = useSelector(getCompany)
  const [domainTypeChain, itemChains] = useMemo(() => {
    return getTimelineItemChains(domainTypes, domainType, overriders, items)
  }, [domainType, domainTypes, items, overriders])
  const groupByPath = useMemo(() => {
    return getGroupByPath(domainTypeChain)
  }, [domainTypeChain])
  const allGroups = useAllGroups(domainType, itemsDomainType, groupByPath, filters)
  const [minTime, maxTime] = getMinMaxTimes(date, view)
  const [[visibleTimeStart, visibleTimeEnd], setVisibleTimes] = useState([minTime, maxTime])
  useEffect(() => {
    if (view === 'day') {
      setVisibleTimes([
        minTime + Duration.fromDurationLike(DAY_START_OFFSET).toMillis(),
        maxTime - Duration.fromDurationLike(DAY_END_OFFSET).toMillis()
      ])
    } else {
      setVisibleTimes([minTime, maxTime])
    }
  }, [minTime, maxTime, view])
  const domainTypeContext = useContext(DomainTypeContext)
  const filterContext = useFilterContext()
  const settingsContext = useContext(DomainTypeSettingsContext)
  const subtypesCache = useMemo<Partial<Record<string, DomainType[]>>>(() => ({}), [])
  const [_timelineGroups, _timelineItems] = useMemo(() => getTimelineGroupsAndItems(
    domainTypes,
    domainTypeChain,
    itemsDomainType,
    itemChains,
    editingItem,
    user,
    overriders,
    domainTypeContext,
    settingsContext,
    filterContext,
    subtypesCache,
    view,
    allGroups
  ), [domainTypes, domainTypeChain, itemsDomainType, itemChains, editingItem, user, overriders, domainTypeContext, settingsContext, filterContext, subtypesCache, view, allGroups])
  const routeDomainType = useMemo(() => {
    const routeByAttribute = domainTypeChain[domainTypeChain.length - 1]?.routeByAttribute
    if (isValidTimelineRouteByAttribute(routeByAttribute)) {
      return domainTypes[routeByAttribute.AttributeDomainType]
    }
    return undefined
  }, [domainTypeChain, domainTypes])
  const usesRoutes = useMemo(() => {
    return routeDomainType !== undefined
  }, [routeDomainType])
  const timelineItems = _timelineItems
    .filter(item => item.start_time <= maxTime && item.end_time >= minTime)
  const timelineGroups = _timelineGroups
    .map(group => ({ ...group }))
  const [draggingStopFromId, setDraggingStopFromId] = useState<Id | null>(null)
  const onStopDragStart = useCallback(setDraggingStopFromId, [setDraggingStopFromId])
  const onStopDragEnd = useCallback(() => setDraggingStopFromId(null), [])
  const selected = useMemo(() => {
    return timelineItems
      .map(item => item.id)
      .filter(id => id !== draggingStopFromId) as unknown[] as number[]
  }, [draggingStopFromId, timelineItems])
  const actionButton = useMemo(() => {
    if (actionDetails === undefined
      || editingItem === null) {
      return null
    }
    const item = timelineItems
      .find(item => item.id === editingItem.itemId)
    if (item === undefined) {
      return null
    }
    const itemDomainTypeChain = item.type === 'item'
      ? item.domainTypeChain
      : item.timelineItems[0]?.domainTypeChain ?? item.domainTypeChain
    return getActionButton(
      domainTypes,
      actionDetails,
      user,
      company,
      {
        type: 'instances',
        instances: []
      },
      getActionParameterValues(
        itemDomainTypeChain,
        actionDetails.action,
        editingItem.startDate,
        editingItem.endDate,
        editingItem.groupByValue
      )
    )
  }, [actionDetails, company, domainTypes, editingItem, timelineItems, user])
  const [contextMenuItemId, setContextMenuItemId] = useState<string | null>(null)
  const contextMenuItem = useMemo(() => {
    return timelineItems.find(item => item.id === contextMenuItemId)
  }, [contextMenuItemId, timelineItems])
  const contextMenuDomainType = useMemo(() => {
    if (contextMenuItem === undefined) {
      return undefined
    }
    return contextMenuItem.itemsSubtype
  }, [contextMenuItem])
  const navigate = useNavigate()
  const isApiDomainType = getDomainTypeSetting(domainTypes, contextMenuDomainType, 'Api') ?? false
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number
    mouseY: number
  } | null>(null)
  const handleContextMenu = useCallback((event: React.MouseEvent, id: string) => {
    event.preventDefault()
    setContextMenuItemId(id)
    setContextMenu(
      contextMenu === null
        ? {
          mouseX: event.clientX - 2,
          mouseY: event.clientY - 4
        }
        : null
    )
  }, [contextMenu])
  const handleClose = useCallback(() => {
    setContextMenu(null)
  }, [])
  const contextMenuInstance = useMemo(() => {
    if (contextMenuItem === undefined) {
      return undefined
    }
    return contextMenuItem.groupItems.length > 0
      ? contextMenuItem.groupItems[0]
      : contextMenuItem.items[0]
  }, [contextMenuItem])
  const additionalButtons = useMemo(() => {
    return (isApiDomainType
      && contextMenuDomainType !== undefined
      && contextMenuInstance !== undefined)
      ? [
        {
          text: 'Open In New Tab',
          icon: 'open_in_new',
          onClick: () => navigate.toDetailsPage(contextMenuDomainType, contextMenuInstance, true)
        }
      ]
      : undefined
  }, [isApiDomainType, contextMenuDomainType, contextMenuInstance, navigate])
  const anchorPosition = useMemo(() => {
    return contextMenu !== null
      ? {
        top: contextMenu.mouseY,
        left: contextMenu.mouseX
      }
      : undefined
  }, [contextMenu])
  const contextMenuItemDomainTypeContext = useMemo(() => ({
    ...domainTypeContext,
    ...contextMenuItem?.context
  }), [contextMenuItem?.context, domainTypeContext])
  const {
    open: snackbarOpen,
    message,
    addMessage,
    handleClose: handleSnackbarClose,
    handleExited
  } = snackPack
  const minTimeRange = Duration.fromObject({ hours: 12 }).toMillis()
  const maxTimeRange = maxTime - minTime
  const dragSnap = useMemo(() => {
    return domainTypeChain[domainTypeChain.length - 1]?.startDateAttribute?.AttributeType === 'date'
      ? DAY_MILLIS
      : undefined
  }, [domainTypeChain])
  const theme = useTheme()
  const showGroups = useMemo(() => {
    return !isNullOrUndefined(domainTypeChain[domainTypeChain.length - 1]?.groupByAttribute)
  }, [domainTypeChain])
  const [leftSidebarWidth, rightSidebarWidth] = useMemo(() => {
    return showGroups
      ? [150, 150]
      : [0, 0]
  }, [showGroups])
  const [removeFromRouteActionDialogOpen, setRemoveFromRouteActionDialogOpen] = useState(false)
  const itemsToRemoveFromRoute = useMemo(() => {
    return contextMenuItem?.type === 'route'
      ? contextMenuItem.timelineItems
      : []
  }, [contextMenuItem])
  const removeFromRouteActionButton = useTimelineItemsActionButton(
    domainType,
    itemsDomainType,
    REMOVE_FROM_ROUTE_ACTION_NAME,
    itemsToRemoveFromRoute
  )
  const onCloseRemoveFromRouteActionDialog = useCallback(() => {
    setRemoveFromRouteActionDialogOpen(false)
  }, [])
  const onPerformRemoveFromRouteAction = useCallback(() => {
    handleClose()
    setRemoveFromRouteActionDialogOpen(false)
    onPerformAction()
  }, [handleClose, onPerformAction])
  const contextMenuPopover = useMemo(() => {
    return (
      <ClickAwayListener
        onClickAway={handleClose}>
        <Popover
          open={anchorPosition !== undefined}
          onClose={handleClose}
          anchorReference='anchorPosition'
          anchorPosition={anchorPosition}>
          {contextMenuItem?.type === 'route'
            ? (
              <components.Container>
                <components.Button
                  disabled={removeFromRouteActionButton?.disabled ?? true}
                  text={removeFromRouteActionButton?.name ?? 'Remove From Route'}
                  icon={removeFromRouteActionButton?.icon ?? 'remove_circle'}
                  onClick={() => setRemoveFromRouteActionDialogOpen(true)} />
              </components.Container>
            )
            : (
              <DomainTypeContext.Provider
                value={contextMenuItemDomainTypeContext}>
                <DomainTypeButtons
                  domainType={contextMenuDomainType ?? domainType}
                  target={{
                    type: 'instances',
                    instances: contextMenuItem?.items ?? []
                  }}
                  on='TableRow'
                  components={components}
                  additionalButtons={additionalButtons}
                  leafNodeType={(contextMenuItem?.domainTypeChain ?? []).length > 1
                    ? 'nested'
                    : 'active'}
                  onComplete={handleClose}
                  parameterValues={
                    contextMenuItem === undefined
                      ? []
                      : getActionParameterValues(
                        contextMenuItem.domainTypeChain,
                        contextMenuItem.changeGroupActionDetails?.action,
                        contextMenuItem.start_time,
                        contextMenuItem.end_time
                      )} />
              </DomainTypeContext.Provider>
            )}
        </Popover>
      </ClickAwayListener>
    )
  }, [additionalButtons, anchorPosition, contextMenuDomainType, contextMenuItem, contextMenuItemDomainTypeContext, domainType, handleClose, removeFromRouteActionButton?.disabled, removeFromRouteActionButton?.icon, removeFromRouteActionButton?.name])
  const onHoverStart = useCallback((event: HoverStartEvent) => {
    if (event.source === TIMELINE_HOVER_EVENT_SOURCE) {
      return
    }
    const elements = document.getElementsByClassName(getUniqueId(domainTypes, event.domainType, event.instance))
    for (const element of elements) {
      element.classList.add('timeline-item-blinking')
    }
  }, [domainTypes])
  const onHoverEnd = useCallback((event: HoverEndEvent) => {
    if (event.source === TIMELINE_HOVER_EVENT_SOURCE) {
      return
    }
    const elements = document.getElementsByClassName(getUniqueId(domainTypes, event.domainType, event.instance))
    for (const element of elements) {
      element.classList.remove('timeline-item-blinking')
    }
  }, [domainTypes])
  useEventHandler('hoverStart', onHoverStart)
  useEventHandler('hoverEnd', onHoverEnd)
  const backdropOpen = useMemo(() => {
    return isLoading || editingItem !== null || movingStop !== null
  }, [editingItem, isLoading, movingStop])
  const changeRouteDatesAfterPerformChangeGroupAction = useCallback(() => {
    const item = timelineItems.find(item => item.id === editingItem?.itemId)
    if (item?.type !== 'route') {
      onPerformAction()
    } else {
      onChangeItemDates?.(
        item.type,
        String(item.id),
        item.domainTypeChain,
        item.groupItems,
        item.items,
        item.start_time,
        item.end_time,
        editingItem?.groupByValue
      )
    }
  }, [editingItem?.groupByValue, editingItem?.itemId, onChangeItemDates, onPerformAction, timelineItems])
  const eventBus = useEventBus()
  useEffect(() => {
    eventBus.dispatch({
      type: 'timelineRoutesChange',
      routeDomainType,
      routedIds: timelineItems
        .filter((item): item is RouteTimelineItem => item.type === 'route')
        .flatMap(item => item.timelineItems)
        .map(item => getUniqueId(domainTypes, item.subtype, getTimelineItemInstance(item)))
    })
  }, [domainTypes, eventBus, routeDomainType, timelineItems])
  const [isDragging, setIsDragging] = useState(false)
  const timelineContainerClassName = useMemo(() => {
    return `timeline-container ${usesRoutes ? 'with-list' : ''} ${isDragging ? 'dragging' : ''}`
  }, [isDragging, usesRoutes])
  return (
    <Root
      style={{
        overflow: 'visible',
        height: 'auto',
        position: 'relative'
      }}>
      <MissingStartDateAlert
        domainTypeChain={domainTypeChain}
        setPanelOpen={setPanelOpen}
        view='timeline' />
      <Backdrop
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          color: theme => theme.palette.primary.main,
          background: theme => theme.palette.action.hover,
          zIndex: theme => theme.zIndex.drawer + 1
        }}
        open={backdropOpen}>
        <CircularProgress color='inherit' />
      </Backdrop>
      {actionButton !== null && (
        <DomainTypeContext.Provider
          value={{
            ...domainTypeContext,
            ...timelineItems.find(item => item.id === editingItem?.itemId)?.context
          }}>
          <ActionDialog
            key={actionDialogOpen.toString()}
            open={actionDialogOpen}
            actionButton={actionButton}
            target={{
              type: 'instances',
              instances: []
            }}
            onClose={onCloseActionDialog}
            onPerform={changeRouteDatesAfterPerformChangeGroupAction} />
        </DomainTypeContext.Provider>
      )}
      {removeFromRouteActionButton !== null && (
        <ActionDialog
          open={removeFromRouteActionDialogOpen}
          actionButton={removeFromRouteActionButton}
          target={{
            type: 'instances',
            instances: itemsToRemoveFromRoute.map(getTimelineItemInstance)
          }}
          onClose={onCloseRemoveFromRouteActionDialog}
          onPerform={onPerformRemoveFromRouteAction} />
      )}
      <Stack
        direction='row'
        gap={1}>
        {usesRoutes && (
          <TimelineList
            domainType={domainType}
            itemsDomainType={itemsDomainType}
            items={timelineItems
              .filter((item): item is ItemTimelineItem => item.type === 'item')} />
        )}
        <Paper
          className={timelineContainerClassName}
          ref={ref}>
          <Timeline
            key={`${width}_${showGroups}`}
            groups={timelineGroups}
            items={timelineItems
              .filter(item => usesRoutes ? item.type === 'route' : true)}
            stackItems
            minResizeWidth={0}
            sidebarWidth={leftSidebarWidth}
            rightSidebarWidth={rightSidebarWidth}
            minZoom={minTimeRange}
            maxZoom={maxTimeRange}
            useResizeHandle
            onTimeChange={(newVisibleTimeStart, newVisibleTimeEnd, updateScrollCanvas) => {
              if (newVisibleTimeStart < minTime && newVisibleTimeEnd > maxTime) {
                setVisibleTimes([minTime, maxTime])
              } else if (newVisibleTimeStart < minTime) {
                setVisibleTimes([minTime, Math.min(minTime + (newVisibleTimeEnd - newVisibleTimeStart), maxTime)])
              } else if (newVisibleTimeEnd > maxTime) {
                setVisibleTimes([Math.max(maxTime - (newVisibleTimeEnd - newVisibleTimeStart), minTime), maxTime])
              } else {
                setVisibleTimes([newVisibleTimeStart, newVisibleTimeEnd])
              }
            }}
            selected={selected}
            visibleTimeStart={visibleTimeStart}
            visibleTimeEnd={visibleTimeEnd}
            lineHeight={31}
            itemHeightRatio={30 / 31}
            dragSnap={dragSnap}
            onItemDrag={() => {
              if (!isDragging) {
                setIsDragging(true)
              }
            }}
            onItemResize={(itemId, time, edge) => {
              setIsDragging(false)
              const item = timelineItems.find(timelineItem => timelineItem.id === itemId)
              if (item === undefined) {
                return
              }
              if (edge === 'left') {
                onChangeItemDates?.(
                  item.type,
                  String(itemId),
                  item.domainTypeChain,
                  item.groupItems,
                  item.items,
                  time,
                  item.end_time,
                  undefined,
                  item.moveResizeActionDetails
                )
              } else {
                onChangeItemDates?.(
                  item.type,
                  String(itemId),
                  item.domainTypeChain,
                  item.groupItems,
                  item.items,
                  item.start_time,
                  time,
                  undefined,
                  item.moveResizeActionDetails
                )
              }
            }}
            onItemMove={(itemId, dragTime, groupIndex) => {
              setIsDragging(false)
              const item = timelineItems.find(timelineItem => timelineItem.id === itemId)
              if (item === undefined) {
                return
              }
              const group = timelineGroups[groupIndex]
              if (group === undefined) {
                return
              }
              if (item.group !== group.id) {
                const itemFilterContext = item.type === 'item'
                  ? getItemFilterContext(
                    domainTypes,
                    item.context ?? domainTypeContext,
                    item.items,
                    item.changeGroupActionDetails,
                    item.itemsSubtype,
                    user
                  )
                  : getRouteFilterContext(
                    domainTypes,
                    item,
                    itemsDomainType,
                    item.changeGroupActionDetails,
                    user
                  )
                const invalidReason = getAttributeValueInvalidReason(
                  group.attributeValue,
                  itemFilterContext,
                  settingsContext,
                  item.changeGroupActionDetails?.action
                )
                if (O.isSome(invalidReason)) {
                  addMessage(invalidReason.value)
                  return
                }
              }
              onChangeItemDates?.(
                item.type,
                String(itemId),
                item.domainTypeChain,
                item.groupItems,
                item.items,
                dragTime,
                dragTime + (item.end_time - item.start_time),
                group,
                item.group !== group.id
                  ? item.changeGroupActionDetails
                  : item.moveResizeActionDetails
              )
            }}
            onItemDoubleClick={itemId => {
              const id = String(itemId).split('_')[0]
              if (id === undefined) {
                return
              }
              onItemClick(id)
            }}
            itemRenderer={rendererProps => (
              <TimelineItem
                rendererProps={rendererProps}
                movingStop={movingStop}
                addMessage={addMessage}
                handleContextMenu={handleContextMenu}
                onStopDragStart={onStopDragStart}
                onStopDragEnd={onStopDragEnd}
                onMoveRouteStop={onMoveRouteStop} />
            )}
            groupRenderer={rendererProps => (
              <TimelineGroup
                rendererProps={rendererProps}
                timelineItems={timelineItems}
                visibleTimeStart={visibleTimeStart}
                visibleTimeEnd={visibleTimeEnd}
                itemsDomainType={itemsDomainType}
                domainTypeChain={domainTypeChain}
                usesRoutes={usesRoutes} />
            )}>
            <TimelineHeaders
              style={{
                top: 0,
                position: 'sticky',
                zIndex: 200,
                background: 'white'
              }}>
              {leftSidebarWidth && (
                <SidebarHeader>
                  {({ getRootProps }) => {
                    const title = isNullOrUndefined(domainTypeChain[domainTypeChain.length - 1]?.groupByAttribute)
                      ? ''
                      : domainTypeChain
                        .map(timelineSettings => {
                          return timelineSettings.itemsAttribute?.Title ?? timelineSettings.groupByAttribute?.Title
                        })
                        .filter(Boolean)
                        .join(PATH_SEPARATOR)
                    return (
                      <div
                        {...getRootProps()}>{title}</div>
                    )
                  }}
                </SidebarHeader>
              )}
              {rightSidebarWidth && (
                <SidebarHeader variant='right'>
                  {({ getRootProps }) => (
                    <div {...getRootProps()}>Total</div>
                  )}
                </SidebarHeader>
              )}
              <DateHeader
                unit='primaryHeader'
                labelFormat={labelFormat} />
              <DateHeader
                labelFormat={labelFormat} />
            </TimelineHeaders>
            <TimelineMarkers>
              <TodayMarker date={new Date()}>
                {({ styles }) => (
                  <div
                    style={{
                      ...styles,
                      backgroundColor: theme.palette.primary.main
                    }} />
                )}
              </TodayMarker>
            </TimelineMarkers>
          </Timeline>
        </Paper>
      </Stack>
      {contextMenuPopover}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        TransitionProps={{
          onExited: handleExited
        }}
        message={message} />
    </Root>
  )
}