import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { RefAttributeValue } from 'types'
import { getRootDomainType, isListAttributeValue } from 'utils/helpers'
import { NarrowedProps } from '../AttributeInput'
import ListRefInput from './ListRefInput'
import NonListRefInput from './NonListRefInput'
import ReadOnlyRefInput from './ReadOnlyRefInput'

export default function RefInput({
  attributeValue,
  readOnly,
  disabled,
  pathError,
  textFieldProps,
  onChange
}: NarrowedProps<RefAttributeValue>): JSX.Element | null {
  const domainTypes = useSelector(getAllDomainTypes)
  const domainType = domainTypes[attributeValue.attribute.AttributeDomainType]
  if (!domainType) {
    return null
  }
  const rootDomainType = getRootDomainType(domainTypes, domainType)
  if (!rootDomainType) {
    return null
  }
  if (isListAttributeValue(attributeValue)) {
    return (
      <ListRefInput
        domainTypes={domainTypes}
        domainType={domainType}
        attributeValue={attributeValue}
        disabled={disabled}
        readOnly={readOnly}
        pathError={pathError}
        textFieldProps={textFieldProps}
        onChange={onChange} />
    )
  }
  if (readOnly) {
    return (
      <ReadOnlyRefInput
        attributeValue={attributeValue}
        pathError={pathError}
        textFieldProps={textFieldProps} />
    )
  }
  return (
    <NonListRefInput
      domainTypes={domainTypes}
      domainType={domainType}
      attributeValue={attributeValue}
      disabled={disabled}
      pathError={pathError}
      textFieldProps={textFieldProps}
      onChange={onChange} />
  )
}
