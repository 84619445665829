import { createContext } from 'react'
import { DomainType, DomainTypeInstance } from 'types'

export interface HoverStartEvent {
  readonly type: 'hoverStart'
  readonly domainType: DomainType
  readonly instance: DomainTypeInstance
  readonly source: string
}

export interface HoverEndEvent {
  readonly type: 'hoverEnd'
  readonly domainType: DomainType
  readonly instance: DomainTypeInstance
  readonly source: string
}

export interface MapMarkerClickEvent {
  readonly type: 'mapMarkerClick'
  readonly domainType: DomainType
  readonly instance: DomainTypeInstance
}

export interface TimelineListSelectionChangeEvent {
  readonly type: 'timelineListSelectionChange'
  readonly selectedIds: string[]
}

export interface TimelineRoutesChangeEvent {
  readonly type: 'timelineRoutesChange'
  readonly routeDomainType: DomainType | undefined
  readonly routedIds: string[]
}

export interface TimelineRouteHoverStartEvent {
  readonly type: 'timelineRouteHoverStart'
  readonly stopIds: string[]
}

export interface TimelineRouteHoverEndEvent {
  readonly type: 'timelineRouteHoverEnd'
}

export interface EventMapping {
  hoverStart: HoverStartEvent
  hoverEnd: HoverEndEvent
  mapMarkerClick: MapMarkerClickEvent
  timelineListSelectionChange: TimelineListSelectionChangeEvent
  timelineRoutesChange: TimelineRoutesChangeEvent
  timelineRouteHoverStart: TimelineRouteHoverStartEvent
  timelineRouteHoverEnd: TimelineRouteHoverEndEvent
}

export interface EventBus {
  on<Type extends keyof EventMapping>(key: Type, handler: (event: EventMapping[Type]) => void): void
  remove<Type extends keyof EventMapping>(key: Type, handler: (event: EventMapping[Type]) => void): void
  dispatch(event: EventMapping[keyof EventMapping]): void
}

type EventHandlers = {
  [Key in keyof EventMapping]: ((event: EventMapping[keyof EventMapping]) => void)[]
}

interface EventBusImplementation extends EventBus {
  readonly handlers: EventHandlers
}

export function createEventBus(): EventBus {
  const eventBus: EventBusImplementation = {
    handlers: {
      hoverStart: [],
      hoverEnd: [],
      mapMarkerClick: [],
      timelineListSelectionChange: [],
      timelineRoutesChange: [],
      timelineRouteHoverStart: [],
      timelineRouteHoverEnd: []
    },
    on(key, handler) {
      this.handlers[key].push(handler as (event: EventMapping[keyof EventMapping]) => void)
    },
    remove(key, handlerToRemove) {
      this.handlers[key] = this.handlers[key]
        .filter(handler => handler !== handlerToRemove)
    },
    dispatch(event) {
      for (const handler of this.handlers[event.type]) {
        handler(event)
      }
    }
  }
  return eventBus
}

export default createContext<EventBus>(createEventBus())