import { readonlyArray as A, taskEither as TE } from 'fp-ts'
import * as E from 'fp-ts/Either'
import { sequenceT } from 'fp-ts/lib/Apply'
import { pipe } from 'fp-ts/lib/function'
import { useCallback, useEffect, useState } from 'react'
import { ApiError, DomainType, DomainTypeInstance, Filter, SearchResponse } from 'types'
import { useDebouncedCallback } from 'use-debounce'
import { limitSearchPageSize } from 'utils/api'
import { CONTEXT_PREFIX, getSearchTextFilters, stringifyFilterValue } from 'utils/filters'
import { chunk, getDomainTypeSetting, getRootDomainType, getStringFields } from 'utils/helpers'
import { useApi, useCancellableApiSession, useFilterContext } from 'utils/hooks'
import { v4 } from 'uuid'

interface ApiDomainTypeAutocompleteOutput {
  readonly open: boolean
  readonly setOpen: (value: boolean) => void
  readonly rootDomainType: DomainType
  readonly options: DomainTypeInstance[]
  readonly loading: boolean
  readonly searchText: string
  readonly setSearchText: (value: string) => void
  readonly apiError: ApiError | null
}

export function useApiDomainTypeAutocomplete(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType,
  filters: Filter[] | null | undefined,
  includeIds: string[] | undefined,
  includeAll: boolean,
  bypassDomainTypeFilters: string[] | null | undefined,
  pageSize = 30
): ApiDomainTypeAutocompleteOutput {
  const rootDomainType = getRootDomainType(domainTypes, domainType) ?? domainType
  const api = useApi()
  const [open, setOpen] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [options, setOptions] = useState<DomainTypeInstance[]>([])
  const [loadingKey, setLoadingKey] = useState<string | false>(v4())
  const [apiError, setApiError] = useState<ApiError | null>(null)
  const identifier = getDomainTypeSetting(domainTypes, domainType, 'Identifier') ?? 'Id'
  const search = useCancellableApiSession(api)
  const filterContext = useFilterContext()
  const onError = useCallback((apiError: ApiError) => {
    setLoadingKey(false)
    setApiError(apiError)
  }, [])
  const onResponses = useCallback(([response, includeIdsResponse]: [SearchResponse, readonly SearchResponse[]]) => {
    setLoadingKey(false)
    const includeIdsResults = (includeIds?.length ?? 0) > 0
      ? includeIdsResponse.flatMap(response => response.results)
      : []
    setOptions(
      includeIdsResults.concat(response.results)
    )
  }, [includeIds?.length])
  const performSearch = useCallback(function doSearch(
    searchText: string,
    domainTypes: Partial<Record<string, DomainType>>,
    domainType: DomainType,
    rootDomainType: DomainType
  ): () => void {
    const apiSession = search.cancelPreviousAndStartNew()
    if (!apiSession.isSignedIn) {
      return search.cancel
    }
    setApiError(null)
    const anyFilters = getSearchTextFilters(
      searchText,
      getStringFields(domainTypes, [rootDomainType])
    )
    if (includeAll) {
      apiSession.searchAll(
        rootDomainType.Name,
        domainType.Name,
        anyFilters,
        filters ?? [],
        [],
        false,
        bypassDomainTypeFilters
      ).then(response => {
        if (E.isRight(response)) {
          setLoadingKey(false)
          setOptions(response.right.results)
        }
      })
    } else {
      const response = () => apiSession.search(
        rootDomainType.DatabaseTable ?? rootDomainType.Name,
        domainType.Name,
        anyFilters,
        (filters ?? []).concat({
          Property: identifier,
          Operator: 'nin',
          Value: stringifyFilterValue(includeIds ?? [])
        }),
        [],
        1,
        limitSearchPageSize(pageSize + (includeIds?.length ?? 0)),
        false,
        bypassDomainTypeFilters
      )
      const includeIdsResponse = pipe(
        includeIds ?? [],
        chunk(limitSearchPageSize(includeIds?.length ?? 0)),
        A.map(ids => () => {
          return apiSession.search(
            rootDomainType.DatabaseTable ?? rootDomainType.Name,
            domainType.Name,
            [],
            [
              {
                Property: identifier,
                Operator: 'in',
                Value: stringifyFilterValue(ids)
              }
            ],
            [],
            1,
            limitSearchPageSize(ids.length),
            false,
            bypassDomainTypeFilters
          )
        }),
        TE.sequenceArray
      )
      pipe(
        sequenceT(TE.ApplicativePar)(response, includeIdsResponse),
        TE.match(onError, onResponses)
      )()
    }
    return search.cancel
  }, [search, includeAll, filters, bypassDomainTypeFilters, includeIds, onError, onResponses, identifier, pageSize])
  const debouncedPerformSearch = useDebouncedCallback((
    searchText: string,
    domainTypes: Partial<Record<string, DomainType>>,
    domainType: DomainType,
    rootDomainType: DomainType
  ) => performSearch(searchText, domainTypes, domainType, rootDomainType), 500)
  useEffect(() => {
    if (searchText || includeIds !== undefined) {
      setLoadingKey(v4())
    }
  }, [searchText, includeIds])
  useEffect(() => {
    if (filters?.some(filter => filter.Value?.includes(CONTEXT_PREFIX)) ?? false) {
      setLoadingKey(v4())
    }
  }, [filterContext, filters])
  useEffect(() => {
    if (loadingKey === false) {
      return
    }
    return debouncedPerformSearch(
      searchText,
      domainTypes,
      domainType,
      rootDomainType
    )
  }, [debouncedPerformSearch, domainType, domainTypes, loadingKey, rootDomainType, searchText])
  return {
    open,
    setOpen,
    rootDomainType,
    options,
    loading: loadingKey !== false,
    searchText,
    setSearchText,
    apiError
  }
}