import { DragHandle } from '@mui/icons-material'
import { Alert, Box, ButtonGroup, Step, StepButton, stepButtonClasses, stepClasses, StepLabel, stepLabelClasses, Stepper } from '@mui/material'
import ButtonsPopover from 'components/domainType/ButtonsPopover'
import DomainTypeButtons from 'components/domainType/DomainTypeButtons'
import TextIconButton from 'components/utils/TextIconButton'
import TooltipIconButton from 'components/utils/TooltipIconButton'
import { ComponentProps, useEffect, useMemo, useRef, useState } from 'react'
import { useDrop } from 'react-dnd'
import { Flipped, Flipper } from 'react-flip-toolkit'
import { DomainType, DomainTypeInstance } from 'types'
import { Dataform, Group } from 'types/dataform'
import { getDataformResultsCompleteness, isNullOrUndefined, makeSortFunction } from 'utils/helpers'
import { useDomainType, useDragToReorder } from 'utils/hooks'
import { NotInitialisedState, State, stateToResults } from './dataformReducer'

const components: ComponentProps<typeof DomainTypeButtons>['components'] = {
  Container: props => (
    <ButtonGroup
      variant='contained'
      size='small'
      {...props} />
  ),
  Button: TextIconButton,
  Empty: () => null
}

interface GroupStepperProps {
  readonly activeGroup: number
  readonly dataform: Dataform | null
  readonly state: State | NotInitialisedState
  readonly formReadOnly: boolean
  readonly showRequiredErrors: boolean
  readonly editable: boolean
  onClick(index: number): void
  onChangeGroupOrder(groupOrder: string[]): void
}

interface SortableStepProps {
  readonly activeGroup: number
  readonly dataform: Dataform
  readonly state: State
  readonly index: number
  readonly group: Group
  readonly groupOrder: string[]
  readonly editable: boolean
  readonly groupDomainType: DomainType | null
  readonly showRequiredErrors: boolean
  onStepClick(index: number): void
  onChangeGroupOrder(groupOrder: string[]): void
  onCommitGroupOrder(groupOrder: string[]): void
}

function SortableStep({
  activeGroup,
  dataform,
  state,
  index,
  group,
  groupOrder,
  editable,
  groupDomainType,
  showRequiredErrors,
  onStepClick,
  onChangeGroupOrder,
  onCommitGroupOrder
}: SortableStepProps): JSX.Element {
  const dropRef = useRef<HTMLDivElement | null>(null)
  const {
    drag,
    preview,
    drop,
    opacity
  } = useDragToReorder(
    group.Id,
    groupOrder,
    'group',
    dropRef,
    'horizontal',
    onChangeGroupOrder,
    onCommitGroupOrder
  )
  const completeness = getDataformResultsCompleteness(stateToResults({
    ...state,
    dataform: {
      ...dataform,
      Groups: [group]
    }
  }))
  const validationError = showRequiredErrors
    && activeGroup === index
  return (
    <Flipped flipId={group.Id}>
      <div
        ref={node => {
          dropRef.current = node
          preview(drop(dropRef.current))
        }}
        style={{ display: 'flex' }}>
        <StepButton
          onClick={() => onStepClick(index)}
          sx={{ opacity }}>
          <StepLabel
            error={validationError || completeness === 1}>
            {group.Title}
          </StepLabel>
        </StepButton>
        {editable && (
          <>
            <div ref={drag}>
              <TooltipIconButton
                icon={<DragHandle />}
                sx={{
                  cursor: 'move',
                  opacity
                }}
                tooltipText=''
                onClick={() => undefined} />
            </div>
            {!isNullOrUndefined(groupDomainType) && (
              <Box sx={{ opacity }}>
                <ButtonsPopover
                  domainType={groupDomainType}
                  target={{
                    type: 'instances',
                    instances: [group] as unknown[] as DomainTypeInstance[]
                  }}
                  on='TableRow' />
              </Box>
            )}
          </>
        )}
      </div>
    </Flipped>
  )
}

export default function GroupStepper({
  activeGroup,
  dataform,
  state,
  formReadOnly,
  showRequiredErrors,
  editable,
  onClick,
  onChangeGroupOrder
}: GroupStepperProps): JSX.Element | null {
  const groupDomainType = useDomainType('DataformGroup', 'DataformGroup')
  const [, drop] = useDrop(() => ({ accept: 'group' }))
  const groupOrder = useMemo(() => {
    return dataform?.Groups.map(group => group.Id) ?? []
  }, [dataform?.Groups])
  const [localGroupOrder, setLocalGroupOrder] = useState(groupOrder)
  useEffect(() => {
    setLocalGroupOrder(groupOrder)
  }, [groupOrder])
  if (dataform === null
    || state.type === 'notInitialised') {
    return null
  }
  const groupButtons = (
    <>
      {editable && !isNullOrUndefined(groupDomainType) && (
        <DomainTypeButtons
          components={components}
          domainType={groupDomainType}
          target={{
            type: 'instances',
            instances: []
          }}
          on='TableToolbar' />
      )}
    </>
  )
  if (dataform.Groups.length === 0) {
    return (
      <Box marginTop={1}>
        <Alert
          severity='info'
          action={groupButtons}>
          This dataform has no groups
        </Alert>
      </Box>
    )
  }
  return (
    <Flipper flipKey={localGroupOrder.join(',')}>
      <Stepper
        ref={drop}
        activeStep={activeGroup}
        nonLinear={editable || formReadOnly}
        sx={{
          marginTop: 1,
          overflowX: 'auto',
          overflowY: 'hidden'
        }}>
        {dataform.Groups
          .sort(makeSortFunction(localGroupOrder, group => group.Id))
          .map((group, index) => {
            return (
              <Step
                key={group.Id}
                completed={formReadOnly || activeGroup > index}
                sx={{
                  [`& .${stepLabelClasses.label}.${stepClasses.completed}`]: {
                    color: theme => activeGroup === index
                      ? theme.palette.text.primary
                      : theme.palette.text.secondary
                  },
                  [`& .${stepButtonClasses.root}`]: {
                    margin: 0,
                    marginLeft: -1,
                    marginRight: -1,
                    padding: 1
                  },
                  display: 'flex',
                  gap: 1
                }}>
                <SortableStep
                  activeGroup={activeGroup}
                  dataform={dataform}
                  state={state}
                  index={index}
                  group={group}
                  groupOrder={localGroupOrder}
                  editable={editable}
                  groupDomainType={groupDomainType}
                  showRequiredErrors={showRequiredErrors}
                  onStepClick={onClick}
                  onChangeGroupOrder={setLocalGroupOrder}
                  onCommitGroupOrder={onChangeGroupOrder} />
              </Step>
            )
          })}
        {groupButtons}
      </Stepper>
    </Flipper>
  )
}