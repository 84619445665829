import { Box, ClickAwayListener, Divider, ListItemButton, ListItemText, MenuList, Paper, Popper, Stack, TextField, TextFieldProps, Typography, alpha, createFilterOptions, darken, lighten, styled } from '@mui/material'
import { DatePicker, PickersDay, PickersDayProps, StaticDatePicker, dayPickerClasses, pickersDayClasses } from '@mui/x-date-pickers'
import ScrollBar from 'components/navigation/ScrollBar'
import { DateTime, Interval } from 'luxon'
import { useCallback, useMemo, useRef, useState } from 'react'
import { DEFAULT_ATTRIBUTE_INPUT_SIZE, DEFAULT_ATTRIBUTE_INPUT_VARIANT, FIND_DIALOG_KEY, OPERATIONS_DIALOG_KEY } from 'utils/constants'

interface Props {
  startDate: DateTime
  endDate: DateTime
  onStartDateChange(startDate: DateTime): void
  onEndDateChange(endDate: DateTime): void
  startDateLabel?: string
  endDateLabel?: string
  textFieldProps?: Partial<TextFieldProps>
  shortcuts?: Shortcut[]
  onShortcutSelect?(shortcut: Shortcut): void
}

export interface Shortcut {
  readonly label: string
  getValue(): [DateTime, DateTime]
}

const PickersDayWrapper = styled(Box)(({ theme }) => ({
  [`& .${pickersDayClasses.selected}`]: {
    transition: 'none'
  },
  [`& .${pickersDayClasses.root}`]: {
    backgroundColor: 'transparent',
    transform: 'scale(1.1)'
  },
  '&.in-range': {
    borderRadius: 0,
    '&:first-child': {
      borderTopLeftRadius: '50%',
      borderBottomLeftRadius: '50%'
    },
    '&:last-child': {
      borderTopRightRadius: '50%',
      borderBottomRightRadius: '50%'
    },
    backgroundColor: {
      light: lighten,
      dark: darken
    }[theme.palette.mode](theme.palette.primary.main, 0.8),
    [`& .${pickersDayClasses.root}:hover`]: {
      backgroundColor: {
        light: lighten,
        dark: darken
      }[theme.palette.mode](theme.palette.primary.main, 0.7)
    }
  },
  '&.first-day, &.last-day': {
    [`& .${pickersDayClasses.root}`]: {
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main),
      [`&.${pickersDayClasses.root}:hover`]: {
        backgroundColor: theme.palette.primary.dark
      }
    }
  },
  '& .range-preview': {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: 'transparent'
  },
  '&.first-day': {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
    '& .range-preview': {
      borderRadius: '50%'
    },
    '&.previewing-before': {
      '&:not(:first-child) .range-preview': {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0
      },
      '& .range-preview': {
        borderColor: `${alpha(theme.palette.text.primary, 0.12)} transparent`,
        borderStyle: 'dashed'
      }
    }
  },
  '&.last-day': {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
    '& .range-preview': {
      borderRadius: '50%'
    },
    '&.previewing-after': {
      '&:not(:last-child) .range-preview': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0
      },
      '& .range-preview': {
        borderColor: `${alpha(theme.palette.text.primary, 0.12)} transparent`,
        borderStyle: 'dashed'
      }
    }
  },
  '&.in-new-range': {
    '& .range-preview': {
      borderStyle: 'dashed',
      borderColor: `${alpha(theme.palette.text.primary, 0.12)} transparent`,
      borderRadius: 0
    },
    '&:first-child .range-preview': {
      borderLeftColor: alpha(theme.palette.text.primary, 0.12),
      borderTopLeftRadius: '50%',
      borderBottomLeftRadius: '50%'
    },
    '&:last-child .range-preview': {
      borderRightColor: alpha(theme.palette.text.primary, 0.12),
      borderTopRightRadius: '50%',
      borderBottomRightRadius: '50%'
    },
    '&.hovering': {
      [`& .${pickersDayClasses.root}`]: {
        border: `1px solid ${theme.palette.grey[500]}`
      },
      '&.previewing-before .range-preview': {
        borderLeftColor: alpha(theme.palette.text.primary, 0.12),
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%'
      },
      '&.previewing-after .range-preview': {
        borderRightColor: alpha(theme.palette.text.primary, 0.12),
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%'
      }
    }
  },
  [`&.can-be-new-start-date.hovering .${pickersDayClasses.root}`]: {
    border: `1px solid ${theme.palette.grey[500]}`
  }
}))

function getPickersDayClassName(
  isInRange: boolean,
  isFirstDay: boolean,
  isLastDay: boolean,
  isInNewRange: boolean,
  isPreviewingBefore: boolean,
  isPreviewingAfter: boolean,
  isHovering: boolean,
  canBeNewStartDate: boolean
): string {
  return `${isInRange ? 'in-range' : ''} ${isFirstDay ? 'first-day' : ''} ${isLastDay ? 'last-day' : ''} ${isInNewRange ? 'in-new-range' : ''} ${isPreviewingBefore ? 'previewing-before' : ''} ${isPreviewingAfter ? 'previewing-after' : ''} ${isHovering ? 'hovering' : ''} ${canBeNewStartDate ? 'can-be-new-start-date' : ''}`
}

const filterShortcuts = createFilterOptions<Shortcut>()

export default function DateRangePicker({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  startDateLabel,
  endDateLabel,
  textFieldProps,
  shortcuts = [],
  onShortcutSelect
}: Props): JSX.Element {
  const [mode, setMode] = useState<'closed' | 'start' | 'end'>('closed')
  const [hoverDate, setHoverDate] = useState<DateTime | null>(null)
  const stackRef = useRef<HTMLDivElement | null>(null)
  const [shortcutSearchText, setShortcutSearchText] = useState('')
  const filteredShortcuts = useMemo(() => {
    return filterShortcuts(
      shortcuts,
      {
        inputValue: shortcutSearchText,
        getOptionLabel: shortcut => shortcut.label
      }
    )
  }, [shortcutSearchText, shortcuts])
  const onClose = useCallback(() => {
    setMode('closed')
    setShortcutSearchText('')
  }, [])
  const renderDay = (
    renderDate: DateTime,
    selectedDates: Array<DateTime | null>,
    pickersDayProps: PickersDayProps<DateTime>
  ) => {
    const isInRange = Interval.fromDateTimes(
      startDate.startOf('day'),
      endDate.endOf('day')
    ).contains(renderDate)
    const isFirstDay = renderDate.hasSame(startDate, 'day')
    const isLastDay = renderDate.hasSame(endDate, 'day')
    const isInNewRange = mode === 'start'
      ? Interval.fromDateTimes(
        hoverDate ?? startDate,
        startDate
      ).contains(renderDate)
      : Interval.fromDateTimes(
        endDate,
        hoverDate?.endOf('day') ?? endDate
      ).contains(renderDate)
    const canBeInNewRange = mode === 'start'
      ? renderDate.endOf('day') < endDate.endOf('day')
      : renderDate.startOf('day') > startDate.startOf('day')
    const [isPreviewingBefore, isPreviewingAfter] = hoverDate !== null
      ? [
        mode === 'start' && hoverDate < startDate,
        mode === 'end' && hoverDate > endDate
      ]
      : [false, false]
    const canBeNewStartDate = !isInNewRange && !isInRange && !canBeInNewRange
    const isHovering = Boolean(hoverDate?.hasSame(renderDate, 'day'))
    return (
      <PickersDayWrapper
        className={getPickersDayClassName(
          isInRange,
          isFirstDay,
          isLastDay,
          isInNewRange,
          isPreviewingBefore,
          isPreviewingAfter,
          isHovering,
          canBeNewStartDate
        )}
        onMouseEnter={() => setHoverDate(renderDate)}
        onMouseLeave={() => setHoverDate(null)}>
        <Box className='range-preview'>
          <PickersDay
            {...pickersDayProps}
            autoFocus={false}
            disableMargin />
        </Box>
      </PickersDayWrapper>
    )
  }
  return (
    <>
      <Stack
        ref={stackRef}
        direction='row'
        alignItems='center'
        spacing={1}>
        <DatePicker
          label={startDateLabel ?? 'Start Date'}
          disableOpenPicker
          value={startDate}
          onChange={newValue => {
            if (newValue !== null) {
              onStartDateChange(newValue.startOf('day'))
              if (newValue.endOf('day') > endDate.endOf('day')) {
                onEndDateChange(newValue.endOf('day'))
              }
            }
          }}
          showDaysOutsideCurrentMonth
          renderDay={renderDay}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              size={DEFAULT_ATTRIBUTE_INPUT_SIZE}
              variant={DEFAULT_ATTRIBUTE_INPUT_VARIANT}
              onClick={event => event.stopPropagation()}
              onFocus={() => setMode('start')}
              focused={mode === 'start' || undefined}
              onKeyDown={event => {
                if ((event.key === OPERATIONS_DIALOG_KEY || event.key === FIND_DIALOG_KEY)) {
                  event.stopPropagation()
                }
              }}
              {...textFieldProps} />
          )} />
        <Typography
          component='div'
          p={1}>
          –
        </Typography>
        <DatePicker
          label={endDateLabel ?? 'End Date'}
          disableOpenPicker
          value={endDate}
          onChange={newValue => {
            if (newValue !== null) {
              onEndDateChange(newValue.endOf('day'))
              if (newValue.startOf('day') < startDate.startOf('day')) {
                onStartDateChange(newValue.startOf('day'))
              }
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              size={DEFAULT_ATTRIBUTE_INPUT_SIZE}
              variant={DEFAULT_ATTRIBUTE_INPUT_VARIANT}
              onClick={event => event.stopPropagation()}
              onFocus={() => setMode('end')}
              focused={mode === 'end' || undefined}
              onKeyDown={event => {
                if ((event.key === OPERATIONS_DIALOG_KEY || event.key === FIND_DIALOG_KEY)) {
                  event.stopPropagation()
                }
              }}
              {...textFieldProps} />
          )} />
      </Stack>
      <ClickAwayListener onClickAway={onClose}>
        <Popper
          open={mode !== 'closed'}
          anchorEl={stackRef.current}
          placement='bottom-start'>
          <Paper elevation={4}>
            <Stack
              sx={{
                [`& .${dayPickerClasses.slideTransition}`]: {
                  minHeight: 254
                }
              }}
              direction='row'
              divider={(
                <Divider
                  orientation='vertical'
                  flexItem />
              )}>
              {shortcuts.length > 0 && (
                <Box>
                  <Box p={1}>
                    <TextField
                      size='small'
                      placeholder='Search shortcuts'
                      value={shortcutSearchText}
                      onChange={event => setShortcutSearchText(event.target.value)} />
                  </Box>
                  <Box height={302}>
                    <ScrollBar>
                      <MenuList dense>
                        {filteredShortcuts.map(shortcut => {
                          const [shortcutStartDate, shortcutEndDate] = shortcut.getValue()
                          return (
                            <ListItemButton
                              key={shortcut.label}
                              selected={shortcutStartDate.hasSame(startDate, 'day')
                                && shortcutEndDate.hasSame(endDate, 'day')}
                              onClick={() => {
                                const [startDate, endDate] = shortcut.getValue()
                                onStartDateChange(startDate)
                                onEndDateChange(endDate)
                                onClose()
                                onShortcutSelect?.(shortcut)
                              }}>
                              <ListItemText>
                                {shortcut.label}
                              </ListItemText>
                            </ListItemButton>
                          )
                        })}
                      </MenuList>
                    </ScrollBar>
                  </Box>
                </Box>
              )}
              <StaticDatePicker
                displayStaticWrapperAs='desktop'
                label='From'
                value={mode === 'start'
                  ? startDate.endOf('day')
                  : endDate}
                onChange={newValue => {
                  if (newValue === null) {
                    return
                  }
                  if (mode === 'start') {
                    onStartDateChange(newValue.startOf('day'))
                    if (newValue.endOf('day') > endDate.endOf('day')) {
                      onEndDateChange(newValue.endOf('day'))
                    }
                    setMode('end')
                  } else if (mode === 'end') {
                    onEndDateChange(newValue.endOf('day'))
                    if (newValue.startOf('day') < startDate.startOf('day')) {
                      onStartDateChange(newValue.startOf('day'))
                    } else {
                      onClose()
                    }
                  }
                }}
                showDaysOutsideCurrentMonth
                renderDay={renderDay}
                renderInput={(params) => (
                  <TextField
                    {...params} />
                )} />
            </Stack>
          </Paper>
        </Popper>
      </ClickAwayListener>
    </>
  )
}