import { MapOutlined } from '@mui/icons-material'
import { Box, Divider, Snackbar, Stack } from '@mui/material'
import DomainTypeHeading from 'components/domainType/DomainTypeHeading'
import QueryTabs from 'components/domainType/QueryTabs'
import TooltipIconButton from 'components/utils/TooltipIconButton'
import * as E from 'fp-ts/Either'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes, getUser } from 'state/reducers'
import { ApiError, DomainType, DomainTypeInstance, Filter, Query, Sort } from 'types'
import { OverriderDetails } from 'utils/context/DomainTypeOverriderContext'
import { ButtonTarget, SignedInApi, SnackPack, useDomainTypeSetting, useOverriders } from 'utils/hooks'
import CalendarView from './CalendarView'
import CardsView from './CardsView'
import FindToolbar from './FindToolbar'
import PermanentFilters from './PermanentFilters'
import TableView, { getColumns } from './TableView'
import TimelineView from './TimelineView'
import filtersReducer, { addFilter } from './filtersReducer'
import { CalendarProps, FindPageView } from './types'
import useSortsAndFilters from './useSortsAndFilters'

interface Props {
  domainType: DomainType
  isLoading: boolean
  hideColumns?: string[]
  items: DomainTypeInstance[]
  total: number
  calendarItems: DomainTypeInstance[]
  page: number
  pageSize: number
  searchText: string
  sorts: Sort[]
  filters: Filter[]
  permanentFilters?: Filter[]
  permanentFilterOptions?: string[]
  selectedPermanentFilterOption?: number
  permanentFilterValueNode?: JSX.Element
  overriderQueries: [Query, OverriderDetails][]
  domainTypeQueries: Query[]
  currentQuery: Query
  isExporting: boolean
  view: FindPageView
  calendarProps: CalendarProps
  highlightedRowIds?: string[]
  hideHeading?: boolean
  filterLinkOperator: 'and' | 'or'
  checkedRowIds: string[]
  checkedItems: DomainTypeInstance[]
  allChecked: boolean
  selectionTarget: ButtonTarget
  autoFocusSearchInput?: boolean
  snackPack: SnackPack
  mapOpen?: boolean
  onSearch(): void
  onFiltersChange(value: Filter[]): void
  onPageChange(value: number): void
  onPageSizeChange(value: number): void
  onSortsChange(value: Sort[]): void
  onRowClick(id: string): void
  onApplyQuery(query: Query): void
  onClickExport?(columns: string[]): void
  onViewChange(value: FindPageView): void
  onSearchTextChange?(value: string): void
  onFilterLinkOperatorChange(value: 'and' | 'or'): void
  onCheckedRowIdsChange(ids: string[]): void
  onAllCheckedChange(value: boolean): void
  onPermanentFilterOptionChange?(index: number): void
  fetchTotal(api: SignedInApi, query: Query): Promise<E.Either<ApiError, number>>
  onMapOpenChange?(open: boolean): void
}

export default function FindView({
  isLoading,
  domainType,
  hideColumns,
  items,
  total,
  calendarItems,
  page,
  pageSize,
  searchText,
  sorts,
  filters,
  overriderQueries,
  domainTypeQueries,
  currentQuery,
  isExporting,
  view,
  calendarProps,
  highlightedRowIds,
  hideHeading = false,
  filterLinkOperator,
  checkedRowIds,
  checkedItems,
  allChecked,
  selectionTarget,
  autoFocusSearchInput = false,
  permanentFilters,
  permanentFilterOptions,
  selectedPermanentFilterOption,
  permanentFilterValueNode,
  snackPack,
  mapOpen = false,
  onPermanentFilterOptionChange,
  onSearch,
  onSearchTextChange,
  onFiltersChange,
  onPageChange,
  onPageSizeChange,
  onSortsChange,
  onRowClick,
  onApplyQuery,
  onClickExport,
  onViewChange,
  onFilterLinkOperatorChange,
  onCheckedRowIdsChange,
  onAllCheckedChange,
  fetchTotal,
  onMapOpenChange
}: Props): JSX.Element {
  const domainTypes = useSelector(getAllDomainTypes)
  const overriders = useOverriders()
  const user = useSelector(getUser)
  const columns = useMemo(() => getColumns(
    domainTypes,
    [domainType],
    overriders,
    [],
    false,
    user
  ), [domainType, domainTypes, overriders, user])
  const [panelOpen, setPanelOpen] = useState<string | false>(false)
  const {
    sortsRef,
    filtersRef,
    panelKey: sortsAndFiltersPanelKey
  } = useSortsAndFilters(
    panelOpen,
    sorts,
    onSortsChange,
    filters,
    onFiltersChange,
    filterLinkOperator,
    onFilterLinkOperatorChange
  )
  const [, editColumns] = useDomainTypeSetting(
    domainType,
    'Columns',
    []
  )
  const setColumnVisibility = useCallback((field: string, isVisible: boolean) => {
    editColumns(
      columns
        .filter(column => {
          return (!(column.hide ?? false) && field !== column.field)
            || (field === column.field && isVisible) || column.field === 'actions'
        })
        .map(column => column.field)
    )
  }, [columns, editColumns])
  const [selectedColumn, setSelectedColumn] = useState<string>()
  const onAddFilter = useCallback((filter: Filter): void => {
    filtersRef.current.filters = filtersReducer(filtersRef.current.filters, addFilter(filter))
    setSelectedColumn(filter.Property)
    setPanelOpen(panelOpen === 'filters' ? false : 'filters')
  }, [filtersRef, panelOpen])
  const onClickFilterIcon = useCallback((column: string): void => {
    setPanelOpen(panelOpen === 'filters' ? false : 'filters')
    setSelectedColumn(column)
  }, [panelOpen])
  const currentQueryToAdd = useMemo(() => ({
    ...currentQuery,
    Filters: filters,
    Sorts: sorts,
    SearchText: searchText,
    FilterLinkOperator: filterLinkOperator
  }), [currentQuery, filterLinkOperator, filters, searchText, sorts])
  const {
    open: snackbarOpen,
    message,
    handleClose: handleSnackbarClose,
    handleExited
  } = snackPack
  const queryTabsContainerRef = useRef<HTMLDivElement | null>(null)
  const heading = useMemo(() => {
    return !hideHeading && (
      <Stack
        gap={1}
        direction='row'
        alignItems='center'
        sx={{
          mt: 1,
          mb: 1,
          minHeight: '40px'
        }}>
        <DomainTypeHeading
          domainType={domainType}
          isLoading={false}
          plural
          title='Find:' />
        {onMapOpenChange !== undefined && !mapOpen && (
          <TooltipIconButton
            tooltipText='Open Map'
            color='primary'
            icon={<MapOutlined />}
            onClick={() => onMapOpenChange(true)} />
        )}
      </Stack>
    )
  }, [domainType, hideHeading, mapOpen, onMapOpenChange])
  const tabs = useMemo(() => {
    return (
      <Stack
        ref={queryTabsContainerRef}
        direction='row'
        gap={1}
        mb={2}
        width='100%'
        borderBottom={1}
        borderColor='divider'
        alignItems='center'>
        <PermanentFilters
          domainType={domainType}
          permanentFilterOptions={permanentFilterOptions}
          permanentFilters={permanentFilters}
          selectedPermanentFilterOption={selectedPermanentFilterOption}
          onPermanentFilterOptionChange={onPermanentFilterOptionChange}
          permanentFilterValueNode={permanentFilterValueNode} />
        {(permanentFilters?.length ?? 0) > 0 && (
          <Divider
            orientation='vertical'
            variant='middle'
            flexItem />
        )}
        <QueryTabs
          domainType={domainType}
          currentQuery={currentQueryToAdd}
          overriderQueries={overriderQueries}
          domainTypeQueries={domainTypeQueries}
          containerRef={queryTabsContainerRef}
          fetchTotal={fetchTotal}
          onApplyQuery={onApplyQuery} />
      </Stack>
    )
  }, [currentQueryToAdd, domainType, domainTypeQueries, fetchTotal, onApplyQuery, onPermanentFilterOptionChange, overriderQueries, permanentFilterOptions, permanentFilterValueNode, permanentFilters, selectedPermanentFilterOption])
  const toolbar = useMemo(() => {
    return (
      <FindToolbar
        columns={columns}
        panelOpen={panelOpen}
        setPanelOpen={setPanelOpen}
        filterLinkOperator={filterLinkOperator}
        filters={filters}
        sorts={sorts}
        onSortsChange={onSortsChange}
        isExporting={isExporting}
        onClickExport={onClickExport}
        domainType={domainType}
        selectionTarget={selectionTarget}
        searchText={searchText}
        autoFocusSearchInput={autoFocusSearchInput}
        onSearch={onSearch}
        onSearchTextChange={onSearchTextChange}
        onFiltersChange={onFiltersChange}
        setColumnVisibility={setColumnVisibility}
        filtersRef={filtersRef}
        sortsRef={sortsRef}
        selectedColumn={selectedColumn}
        setSelectedColumn={setSelectedColumn}
        view={view}
        onViewChange={onViewChange}
        calendarProps={calendarProps}
        sortsAndFiltersPanelKey={sortsAndFiltersPanelKey} />
    )
  }, [autoFocusSearchInput, calendarProps, columns, domainType, filterLinkOperator, filters, filtersRef, isExporting, onClickExport, onFiltersChange, onSearch, onSearchTextChange, onSortsChange, onViewChange, panelOpen, searchText, selectedColumn, selectionTarget, setColumnVisibility, sorts, sortsAndFiltersPanelKey, sortsRef, view])
  return (
    <Stack>
      {heading}
      {tabs}
      <Box>
        {toolbar}
        {view === 'table' && (
          <TableView
            items={items}
            domainType={domainType}
            isLoading={isLoading}
            sorts={sorts}
            page={page}
            pageSize={pageSize}
            total={total}
            checkedRowIds={checkedRowIds}
            checkedItems={checkedItems}
            allChecked={allChecked}
            selectionTarget={selectionTarget}
            highlightedRowIds={highlightedRowIds}
            hideColumns={hideColumns}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onSortsChange={onSortsChange}
            onRowClick={onRowClick}
            onCheckedRowIdsChange={onCheckedRowIdsChange}
            onAllCheckedChange={onAllCheckedChange}
            onColumnVisibilityChange={setColumnVisibility}
            onAddFilter={onAddFilter}
            filters={filters}
            onClickFilterIcon={onClickFilterIcon} />
        )}
        {view === 'cards' && (
          <CardsView
            domainType={domainType}
            items={items}
            page={page}
            pageSize={pageSize}
            total={total}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onCardClick={onRowClick}
            isLoading={isLoading} />
        )}
        {view === 'calendar' && (
          <CalendarView
            isLoading={isLoading}
            domainType={domainType}
            items={calendarItems}
            snackPack={snackPack}
            onItemClick={onRowClick}
            setPanelOpen={setPanelOpen}
            {...calendarProps} />
        )}
        {view === 'timeline' && (
          <TimelineView
            isLoading={isLoading}
            domainType={domainType}
            items={calendarItems}
            snackPack={snackPack}
            onItemClick={onRowClick}
            setPanelOpen={setPanelOpen}
            filters={filters}
            {...calendarProps} />
        )}
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        TransitionProps={{
          onExited: handleExited
        }}
        message={message} />
    </Stack>
  )
}
