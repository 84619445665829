import { Alert, alertClasses, AlertTitle, Box, LinearProgress } from '@mui/material'
import { useIntl } from 'react-intl'

interface Props {
  readonly isLoading: boolean
  readonly errorCode: string
  readonly title?: string
  readonly hideEmptyErrorCode?: boolean
}

export default function DialogAlert({
  isLoading,
  errorCode,
  title,
  hideEmptyErrorCode = false
}: Props): JSX.Element | null {
  const { formatMessage } = useIntl()
  return errorCode
    ? (
      <Alert severity='error'>
        {title !== undefined && (
          <AlertTitle>{title}</AlertTitle>
        )}
        {formatMessage({
          id: errorCode,
          defaultMessage: errorCode
        })}
      </Alert>
    )
    : hideEmptyErrorCode
      ? null
      : (
        <Alert
          severity='info'
          sx={{
            [`& .${alertClasses.message}`]: {
              width: '100%'
            }
          }}>
          {title !== undefined && (
            <AlertTitle>{title}</AlertTitle>
          )}
          {isLoading
            ? (
              <Box
                pt={1.1}
                pb={1.1}>
                <LinearProgress />
              </Box>
            )
            : 'Values marked with * are required'}
        </Alert>
      )
}