import { Action, TOGGLE_SIDE_MENU_PINNED } from 'types'

const getDefaultState = (): boolean => {
  return window.localStorage.getItem('sideMenuPinned') !== String(false)
}

export default (state: boolean = getDefaultState(), action: Action): boolean => {
  switch (action.type) {
    case TOGGLE_SIDE_MENU_PINNED: {
      const sideMenuPinned = !state
      window.localStorage.setItem('sideMenuPinned', String(sideMenuPinned))
      return sideMenuPinned
    }
    default:
      return state
  }
}