import { Stack } from '@mui/material'
import { DomainType, Filter } from 'types'
import FilterChip from './FilterChip'

interface Props {
  domainType: DomainType
  permanentFilters?: Filter[]
  permanentFilterOptions?: string[]
  selectedPermanentFilterOption?: number
  permanentFilterValueNode?: JSX.Element
  onPermanentFilterOptionChange?(index: number): void
}

export default function PermanentFilters({
  domainType,
  permanentFilters = [],
  permanentFilterOptions = [],
  selectedPermanentFilterOption = 0,
  permanentFilterValueNode,
  onPermanentFilterOptionChange
}: Props): JSX.Element | null {
  if (permanentFilters.length === 0) {
    return null
  }
  return (
    <Stack
      direction='row'
      gap={1}
      pr={0}
      alignItems='center'
      flexWrap='wrap'
      flexShrink={1}>
      {permanentFilters.map((filter, i) => (
        <FilterChip
          key={`${filter.Property}${filter.Operator}${filter.Value}`}
          domainType={domainType}
          filter={filter}
          permanent
          permanentFilterOptions={permanentFilterOptions}
          selectedPermanentFilterOption={selectedPermanentFilterOption}
          valueNode={permanentFilterValueNode}
          onPermanentFilterOptionChange={onPermanentFilterOptionChange} />
      ))}
    </Stack>
  )
}