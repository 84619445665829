import { Login } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Alert, Link, Stack } from '@mui/material'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { styled, useTheme } from '@mui/material/styles'
import * as E from 'fp-ts/Either'
import { ReactComponent as LightThemePhalanxLogo } from 'phalanx_black.svg'
import { ReactComponent as DarkThemePhalanxLogo } from 'phalanx_white.svg'
import { useCallback, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { ApiError } from 'types'
import { PAGE_URL } from 'utils/constants'
import { useApi } from 'utils/hooks'
const PREFIX = 'ForgotPasswordForm'

const classes = {
  paper: `${PREFIX}-paper`,
  avatar: `${PREFIX}-avatar`,
  form: `${PREFIX}-form`,
  submit: `${PREFIX}-submit`
}

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`& .${classes.paper}`]: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },

  [`& .${classes.avatar}`]: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },

  [`& .${classes.form}`]: {
    width: '100%',
    marginTop: theme.spacing(1)
  },

  [`& .${classes.submit}`]: {
    margin: theme.spacing(3, 0, 2)
  }
}))

const messages = defineMessages({
  'header.text': {
    id: 'forgotPasswordForm.header.text',
    defaultMessage: 'PHALANX'
  },
  'username.label': {
    id: 'forgotPasswordForm.username.label',
    defaultMessage: 'Username'
  },
  'button.text': {
    id: 'forgotPasswordForm.button.text',
    defaultMessage: 'Request Password Reset'
  },
  'button.loadingText': {
    id: 'forgotPasswordForm.button.loadingText',
    defaultMessage: 'Requesting Password Reset...'
  },
  'successAlert.text': {
    id: 'forgotPasswordForm.successAlert.text',
    defaultMessage: 'You should receive an email shortly with instructions on how to reset your password'
  },
  'api.errorCode.resetPassword.exception': {
    id: 'forgotPasswordForm.exception',
    defaultMessage: 'A problem occurred when trying to request a password reset'
  },
  'api.errorCode.invalidResponse': {
    id: 'forgotPasswordForm.invalidResponse',
    defaultMessage: 'A problem occurred when trying to request a password reset'
  }
})

function Copyright(): JSX.Element {
  return (
    <Typography
      variant='body2'
      color='textSecondary'
      align='center'>
      {'Copyright © '}
      <Link
        href='https://spartansolutions.com/'
        target='_blank'>
        Spartan Solutions Ltd
      </Link>{' '}
      {new Date().getFullYear()}
      .
    </Typography>
  )
}

export default function ResetPasswordForm(): JSX.Element {
  const { formatMessage } = useIntl()
  const [values, setValues] = useState({
    username: ''
  })
  const onChange = (key: keyof typeof values, value: string): void => setValues({
    ...values,
    [key]: value
  })
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const api = useApi()
  const forgotPasswordHandler = useCallback(async (response: E.Either<ApiError, void>) => {
    if (E.isRight(response)) {
      setShowSuccessAlert(true)
      return
    }
    const errorCode = response.left.errorCode as keyof typeof messages

    const messageText = errorCode in messages ? messages[errorCode] : null

    const message = messageText ? formatMessage(messageText) : errorCode

    setErrorMessage(message)
  }, [formatMessage])
  const performResetPassword = useCallback(async () => {
    if (api.isSignedIn) {
      return
    }
    setIsLoading(true)
    const response = await api.forgotPassword(values.username)
    setIsLoading(false)
    forgotPasswordHandler(response)
  }, [api, values.username, forgotPasswordHandler])
  const theme = useTheme()
  return (
    <StyledContainer
      maxWidth='xs'>
      <CssBaseline />
      <div className={classes.paper}>
        {theme.palette.mode === 'dark'
          ? (
            <DarkThemePhalanxLogo
              height='40'
              width='200' />
          )
          : (
            <LightThemePhalanxLogo
              height='40'
              width='200' />
          )}
        {showSuccessAlert && (
          <Alert
            sx={{ mt: 2 }}
            severity='success'>
            {formatMessage(messages['successAlert.text'])}
          </Alert>
        )}
        {errorMessage && (
          <Alert
            sx={{ mt: 2 }}
            severity='error'>
            {errorMessage}
          </Alert>
        )}
        <form
          className={classes.form}
          onSubmit={(e) => {
            e.preventDefault()
            performResetPassword()
          }}>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            name='username'
            label={formatMessage(messages['username.label'])}
            id='username'
            value={values.username}
            onChange={({ target }) => onChange('username', target.value)} />
          <LoadingButton
            type='submit'
            fullWidth
            loading={isLoading}
            variant='contained'
            loadingPosition='end'
            endIcon={<Login />}
            color='primary'
            className={classes.submit}
            size='large'>
            {isLoading
              ? formatMessage(messages['button.loadingText'])
              : formatMessage(messages['button.text'])}
          </LoadingButton>
        </form>
      </div>
      <Stack alignItems='center'>
        <div>
          Return to <Link href={PAGE_URL.HOME}>Sign In</Link>
        </div>
      </Stack>
      <Box mt={8}>
        <Copyright />
      </Box>
    </StyledContainer>
  )
}
