import { AddOutlined } from '@mui/icons-material'
import { Autocomplete, Box, Button, Checkbox, Chip, Icon, Paper, Stack, TextField, useTheme } from '@mui/material'
import AttributeCell from 'components/attribute/AttributeCell'
import AvatarCornerIcon from 'components/utils/AvatarCornerIcon'
import { HTMLAttributes, PropsWithChildren, useCallback, useMemo, useState } from 'react'
import { EnumeratedValue, ListEnumAttributeValue } from 'types'
import { DEFAULT_ATTRIBUTE_INPUT_SIZE, DEFAULT_ATTRIBUTE_INPUT_VARIANT } from 'utils/constants'
import { fixMultipleAutocompleteRequiredBehaviour, getContrastingColour, getSortedEnumeratedValues, getTransparentBackgroundColour, isNullOrUndefined, toErrorText } from 'utils/helpers'
import { NarrowedProps } from '../AttributeInput'
import LabelledInput from '../LabelledInput'

function PaperComponent({
  onClick,
  ...props
}: PropsWithChildren<HTMLAttributes<HTMLElement>>): JSX.Element {
  return (
    <Paper {...props}>
      <Box
        p={1}>
        <Button
          variant='text'
          startIcon={<AddOutlined />}
          onMouseDown={event => {
            event.preventDefault()
          }}
          onClick={onClick}>
          Add All
        </Button>
      </Box>
      {props.children}
    </Paper>
  )
}

export default function ListEnumInput({
  attributeValue,
  readOnly,
  disabled,
  pathError,
  textFieldProps,
  onChange
}: NarrowedProps<ListEnumAttributeValue>): JSX.Element {
  const enumeratedValues = useMemo(() => {
    return getSortedEnumeratedValues(attributeValue.attribute.EnumeratedType)
  }, [attributeValue.attribute.EnumeratedType])
  const selectedValues = useMemo(() => {
    const stringValues = (attributeValue.value ?? []).map(String)
    return enumeratedValues.filter(ev => stringValues.includes(ev.Value))
  }, [attributeValue.value, enumeratedValues])
  const handleChange = useCallback((event: unknown, value: EnumeratedValue[] | null) => {
    if (onChange === undefined) {
      return
    }
    onChange({
      attribute: attributeValue.attribute,
      value: value
        ?.map(enumeratedValue => enumeratedValue.Value) ?? null
    })
  }, [attributeValue.attribute, onChange])
  const [inputValue, setInputValue] = useState('')
  const theme = useTheme()
  if (readOnly) {
    return (
      <LabelledInput
        label={attributeValue.attribute.Title}
        required={attributeValue.attribute.Required ?? false}>
        <AttributeCell attributeChainValue={attributeValue} />
      </LabelledInput>
    )
  }
  return (
    <Autocomplete
      multiple
      disabled={disabled}
      onChange={handleChange}
      disableCloseOnSelect
      renderOption={(props, option, { selected }) => {
        return (
          <li
            {...props}
            key={option.Value}>
            <Checkbox
              size={DEFAULT_ATTRIBUTE_INPUT_SIZE}
              sx={{
                width: 24,
                height: 24,
                marginRight: '8px'
              }}
              checked={selected} />
            <Stack
              direction='row'
              gap={1}
              alignItems='center'>
              {!isNullOrUndefined(option.Icon) && (
                <AvatarCornerIcon
                  background={getContrastingColour(
                    option.Colour ?? theme.palette.primary.main,
                    theme.palette.mode,
                    theme.palette.contrastThreshold
                  )}
                  icon={option.Icon} />
              )}
              {option.Description}
            </Stack>
          </li>
        )
      }}
      inputValue={inputValue}
      onInputChange={(event, value, reason) => {
        if (reason !== 'reset') {
          setInputValue(value)
        }
      }}
      PaperComponent={PaperComponent}
      componentsProps={{
        paper: {
          onClick: () => handleChange(null, enumeratedValues)
        }
      }}
      getOptionLabel={(option: EnumeratedValue) => option.Description}
      value={selectedValues}
      options={enumeratedValues}
      readOnly={readOnly}
      renderTags={(value, getTagProps, ownerState) => {
        const numTags = value.length
        const limitTags = 1
        return (
          <>
            {value.slice(0, limitTags).map((option, index) => {
              const color = isNullOrUndefined(option.Colour)
                ? theme.palette.text.primary
                : getContrastingColour(
                  option.Colour,
                  theme.palette.mode,
                  theme.palette.contrastThreshold
                )
              const background = getTransparentBackgroundColour(
                color,
                theme.palette.mode
              )
              return (
                <Chip
                  {...getTagProps({ index })}
                  onDelete={undefined}
                  key={index}
                  size={textFieldProps?.size ?? DEFAULT_ATTRIBUTE_INPUT_SIZE}
                  sx={{
                    background,
                    color
                  }}
                  icon={isNullOrUndefined(option.Icon)
                    ? undefined
                    : (
                      <Icon style={{ color }}>
                        {option.Icon}
                      </Icon>
                    )}
                  label={ownerState.getOptionLabel(option)} />
              )
            })}
            {numTags > limitTags && ` +${numTags - limitTags}`}
            &nbsp;
          </>
        )
      }}
      renderInput={params => (
        <TextField
          {...params}
          variant={DEFAULT_ATTRIBUTE_INPUT_VARIANT}
          size={DEFAULT_ATTRIBUTE_INPUT_SIZE}
          required={attributeValue.attribute.Required ?? false}
          error={pathError !== undefined}
          helperText={toErrorText(pathError)}
          label={attributeValue.attribute.Title}
          onKeyDown={event => {
            if (event.key === 'Backspace') {
              event.stopPropagation()
            }
          }}
          fullWidth
          {...textFieldProps}
          inputProps={{
            ...textFieldProps?.inputProps,
            ...fixMultipleAutocompleteRequiredBehaviour(params, attributeValue, selectedValues)
          }} />
      )} />
  )
}
