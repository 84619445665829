import * as t from 'io-ts'
import { Dashboard, Panel, Variable } from 'types/dashboard'

export const VariableCodec: t.Type<Variable> = t.intersection([
  t.type({
    Name: t.string,
    Label: t.string,
    DomainType: t.string
  }),
  t.partial({
    Property: t.union([t.string, t.null])
  })
])

export const PanelCodec: t.Type<Panel> = t.intersection([
  t.type({
    Id: t.string,
    Title: t.string,
    ShowOnHomePage: t.boolean
  }),
  t.partial({
    ShowOnDetailsPages: t.union([t.array(t.string), t.null])
  })
])

export const DashboardCodec: t.Type<Dashboard> = t.intersection([
  t.type({
    Id: t.string,
    Category: t.string,
    Subcategory: t.string,
    Value: t.string,
    Role: t.string
  }),
  t.partial({
    Variables: t.union([t.array(VariableCodec), t.null]),
    Panels: t.union([t.array(PanelCodec), t.null])
  })
])