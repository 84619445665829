import { FilterAltOutlined, SortOutlined } from '@mui/icons-material'
import { Badge, Stack, Theme, useMediaQuery } from '@mui/material'
import TooltipIconButton from 'components/utils/TooltipIconButton'
import { useContext, useMemo } from 'react'
import { DomainType, Filter, Sort } from 'types'
import { SidePanelContext } from 'utils/context'
import { isNotNullOrUndefined } from 'utils/helpers'
import FilterChip from './FilterChip'
import SortChip from './SortChip'
import filtersReducer, { deleteFilter } from './filtersReducer'
import sortsReducer, { deleteSort } from './sortsReducer'

interface Props {
  domainType: DomainType
  filterLinkOperator: 'and' | 'or'
  filters: Filter[] | undefined
  onFiltersChange?(filters: Filter[]): void
  sorts: Sort[] | undefined
  onSortsChange?(sorts: Sort[]): void
  panelOpen: string | false
  setPanelOpen(panel: string | false): void
  filtersButtonRef?: React.RefObject<HTMLButtonElement>
  sortButtonRef?: React.RefObject<HTMLButtonElement>
  autoFocus?: boolean
  hideChips?: boolean
}

export default function FiltersAndSorts({
  domainType,
  filterLinkOperator,
  filters = [],
  onFiltersChange,
  sorts = [],
  onSortsChange,
  panelOpen,
  setPanelOpen,
  filtersButtonRef,
  sortButtonRef,
  hideChips = false
}: Props): JSX.Element {
  const isXl = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'))
  const sidePanels = useContext(SidePanelContext)
  const isAnySidePanelOpen = sidePanels
    .some(value => isNotNullOrUndefined(value.sidePanel))
  const shouldHideChips = useMemo(() => {
    return hideChips || !isXl || isAnySidePanelOpen
  }, [hideChips, isAnySidePanelOpen, isXl])
  return (
    <>
      <Badge
        color='primary'
        badgeContent={shouldHideChips
          ? filters.length
          : 0}>
        <TooltipIconButton
          ref={filtersButtonRef}
          icon={<FilterAltOutlined />}
          tooltipText='Filters'
          size='small'
          color='primary'
          onClick={event => {
            event.preventDefault()
            setPanelOpen(panelOpen === 'filters' ? false : 'filters')
          }} />
      </Badge>
      {!shouldHideChips && filters.length > 0 && (
        <Stack
          direction='row'
          gap={1}
          pr={0}
          alignItems='center'
          flexWrap='wrap'
          flexShrink={1}>
          {filters
            .map((filter, i) => (
              <FilterChip
                key={`${filter.Property}${filter.Operator}${filter.Value}`}
                domainType={domainType}
                filter={filter}
                ChipProps={{
                  onDelete() {
                    onFiltersChange?.(filtersReducer(filters, deleteFilter(i)))
                  },
                  onClick() {
                    setPanelOpen(panelOpen === 'filters' ? false : 'filters')
                  }
                }} />
            ))}
        </Stack>
      )}
      <Badge
        color='primary'
        badgeContent={shouldHideChips
          ? sorts.length
          : 0}>
        <TooltipIconButton
          ref={sortButtonRef}
          icon={<SortOutlined />}
          tooltipText='Sort'
          size='small'
          color='primary'
          onClick={event => {
            event.preventDefault()
            setPanelOpen(panelOpen === 'sorts' ? false : 'sorts')
          }} />
      </Badge>
      {!shouldHideChips && sorts.length > 0 && (
        <Stack
          direction='row'
          gap={1}
          pr={0}
          alignItems='center'
          flexWrap='wrap'
          flexShrink={1}>
          {sorts.map((sort, i) => (
            <SortChip
              key={sort.Property}
              domainType={domainType}
              sort={sort}
              ChipProps={{
                onDelete() {
                  onSortsChange?.(sortsReducer(sorts, deleteSort(i)))
                },
                onClick() {
                  setPanelOpen(panelOpen === 'sorts' ? false : 'sorts')
                }
              }} />
          ))}
        </Stack>
      )}
    </>
  )
}
