import { styled } from '@mui/material'

export default styled('div', {
  shouldForwardProp: prop => prop !== 'isDragging' && prop !== 'placement' && prop !== 'offset'
})<{ isDragging: boolean, placement: 'left' | 'right', offset?: number }>(({ theme, isDragging, placement, offset = 0 }) => ({
  width: '5px',
  cursor: isDragging
    ? 'default'
    : 'ew-resize',
  padding: '4px 0 0',
  borderTop: '1px solid #ddd',
  top: 0,
  position: 'absolute',
  [placement]: offset,
  bottom: 0,
  zIndex: 100,
  backgroundColor: isDragging
    ? theme.palette.primary.main
    : 'lightGray',
  paddingBottom: '100%',
  height: '100%',
  boxSizing: undefined,
  '&:hover': {
    backgroundColor: theme.palette.primary.main
  }
}))