import { Chip, useTheme } from '@mui/material'
import { NonListBoolAttributeValue } from 'types'
import { getTransparentBackgroundColour } from 'utils/helpers'
import { NarrowedProps } from './AttributeCell'

export default function BoolCell({
  attributeValue,
  columnWidth
}: NarrowedProps<NonListBoolAttributeValue>): JSX.Element | null {
  const theme = useTheme()
  if (attributeValue.value === null) {
    return null
  }
  const background = getTransparentBackgroundColour(
    theme.palette.text.primary,
    theme.palette.mode
  )
  return (
    <Chip
      sx={{
        pointerEvents: 'none',
        background
      }}
      label={attributeValue.value ? 'Yes' : 'No'}
      size='small' />
  )
}
