import { SearchOutlined } from '@mui/icons-material'
import { ClickAwayListener, InputAdornment, Popper, Stack, TextField, inputBaseClasses } from '@mui/material'
import { GridColumns } from '@mui/x-data-grid'
import { FormEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { DomainType, Filter } from 'types'
import { FIND_DIALOG_KEY, OPERATIONS_DIALOG_KEY } from 'utils/constants'
import QuickFiltersPanel from './QuickFiltersPanel'
import filtersReducer, { addFilter, deleteFilter } from './filtersReducer'

interface Props {
  domainType: DomainType
  searchText: string
  columns: GridColumns
  onSearch(searchText: string): void
  onSearchTextChange?(searchText: string): void
  filters: Filter[] | undefined
  onFiltersChange?(filters: Filter[]): void
  autoFocus?: boolean
}

export default function SearchInput({
  domainType,
  searchText,
  columns,
  onSearch,
  onSearchTextChange,
  filters = [],
  onFiltersChange,
  autoFocus = false
}: Props): JSX.Element {
  const [internalSearchText, setSearchText] = useState(searchText)
  useEffect(() => setSearchText(searchText), [searchText])
  useEffect(() => {
    if (onSearchTextChange !== undefined) {
      onSearchTextChange(internalSearchText)
    }
  }, [internalSearchText, onSearchTextChange])
  const inputRef = useRef<HTMLInputElement>()
  const formRef = useRef<HTMLFormElement>(null)
  const onAddFilter = useCallback((filter: Filter) => {
    onFiltersChange?.(filtersReducer(filters, addFilter(filter)))
    setSearchText('')
    inputRef.current?.focus()
  }, [filters, onFiltersChange])
  const [selectedSearchColumn, setSelectedSearchColumn] = useState<number | null>(null)
  const [selectedSearchValue, setSelectedSearchValue] = useState<number | null>(null)
  const [selectedFilter, setSelectedFilter] = useState<Filter | null>(null)
  const [searchColumns, setSearchColumns] = useState<number[] | null>(null)
  const [clickedOutsideQuickFiltersPanel, setCickedOutsideQuickFiltersPanel] = useState(false)
  useEffect(() => {
    setSelectedSearchColumn(null)
    setSelectedSearchValue(null)
    setSelectedFilter(null)
    setSearchColumns(null)
  }, [internalSearchText])
  const upArrowPressed = useCallback(() => {
    if (searchColumns === null
      || searchColumns.length === 0
      || searchColumns.every(column => column === 0)) {
      return
    }
    let searchColumn = selectedSearchColumn ?? 0
    let searchValue = selectedSearchValue === null
      ? -1
      : selectedSearchValue - 1
    while (searchValue < 0) {
      searchColumn = (searchColumn + searchColumns.length - 1) % searchColumns.length
      searchValue = (searchColumns[searchColumn] ?? 0) - 1
    }
    setSelectedSearchColumn(searchColumn)
    setSelectedSearchValue(searchValue)
  }, [searchColumns, selectedSearchColumn, selectedSearchValue])
  const downArrowPressed = useCallback(() => {
    if (searchColumns === null
      || searchColumns.length === 0
      || searchColumns.every(column => column === 0)) {
      return
    }
    let searchColumn = selectedSearchColumn ?? 0
    let searchValue = selectedSearchValue === null
      ? 0
      : selectedSearchValue + 1
    while (searchValue >= (searchColumns[searchColumn] ?? 0)) {
      searchColumn = (searchColumn + 1) % searchColumns.length
      searchValue = 0
    }
    setSelectedSearchColumn(searchColumn)
    setSelectedSearchValue(searchValue)
  }, [searchColumns, selectedSearchColumn, selectedSearchValue])

  const searchResultsPanelOpen = useMemo(() => {
    return !clickedOutsideQuickFiltersPanel
      && internalSearchText !== ''
      && internalSearchText !== searchText
      && onFiltersChange !== undefined
  }, [internalSearchText, onFiltersChange, searchText, clickedOutsideQuickFiltersPanel])
  return (
    <ClickAwayListener
      onClickAway={event => {
        setCickedOutsideQuickFiltersPanel(true)
      }}>
      <Stack direction='row'>
        <Stack
          ref={formRef}
          component='form'
          onSubmit={(event: FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            if (selectedFilter !== null) {
              onAddFilter(selectedFilter)
            } else {
              onSearch(internalSearchText)
            }
          }}
          alignItems='center'
          direction='row'>
          <TextField
            size='small'
            sx={{
              width: 200,
              [`& .${inputBaseClasses.root}`]: {
                pr: 0
              }
            }}
            inputRef={inputRef}
            placeholder='Search'
            value={internalSearchText}
            onKeyDown={event => {
              if (internalSearchText === ''
                && (event.key === OPERATIONS_DIALOG_KEY || event.key === FIND_DIALOG_KEY)) {
                event.currentTarget.blur()
              } else if (searchColumns !== null
                && (event.key === 'ArrowUp' || event.key === 'ArrowDown')) {
                if (event.key === 'ArrowUp') {
                  upArrowPressed()
                } else {
                  downArrowPressed()
                }
                event.preventDefault()
                event.stopPropagation()
              } else if (internalSearchText === '' && event.key === 'Backspace') {
                onFiltersChange?.(filtersReducer(filters, deleteFilter(filters.length - 1)))
                event.stopPropagation()
              } else {
                event.stopPropagation()
              }
            }}
            onChange={event => {
              setCickedOutsideQuickFiltersPanel(false)
              setSearchText(event.target.value)
            }}
            inputProps={{
              'aria-label': 'search',
              'auto-focus': autoFocus ? 'yes' : 'no'
            }}
            autoFocus={autoFocus}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchOutlined />
                </InputAdornment>
              )
            }} />
        </Stack>
        <Popper
          open={searchResultsPanelOpen}
          anchorEl={formRef.current}
          sx={theme => ({
            zIndex: theme.zIndex.modal,
            minWidth: '400px'
          })}
          placement='bottom-start'
          onResize={undefined}
          onResizeCapture={undefined}>
          <QuickFiltersPanel
            domainType={domainType}
            columns={columns}
            searchText={internalSearchText}
            onAddFilter={onAddFilter}
            selectedSearchColumn={selectedSearchColumn}
            selectedSearchValue={selectedSearchValue}
            setSearchColumns={setSearchColumns}
            setSelectedFilter={setSelectedFilter} />
        </Popper>
      </Stack>
    </ClickAwayListener>
  )
}
