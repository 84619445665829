import { createContext } from 'react'
import { Company, Person, Query } from 'types'

interface QueryPathStep {
  readonly type: 'query'
  readonly id: string
}

interface OverridePathStep {
  readonly type: 'override'
  readonly id: string
}

type PathStep =
  | QueryPathStep
  | OverridePathStep

export interface CompanyOverriderDetails {
  readonly overrider: Company
  readonly type: 'company'
  readonly root: 'company'
  readonly path: PathStep[]
}

export interface PersonOverriderDetails {
  readonly overrider: Person
  readonly type: 'person'
  readonly root: 'person'
  readonly path: PathStep[]
}

export interface BaseQueryOverriderDetails {
  readonly overrider: Query
  readonly type: 'query'
  readonly path: PathStep[]
}

export interface CompanyQueryOverriderDetails extends BaseQueryOverriderDetails {
  readonly root: 'company'
}

export interface PersonQueryOverriderDetails extends BaseQueryOverriderDetails {
  readonly root: 'person'
}

export interface DomainTypeQueryOverriderDetails extends BaseQueryOverriderDetails {
  readonly root: 'domainType'
  readonly id: string
}

export type QueryOverriderDetails =
  | CompanyQueryOverriderDetails
  | PersonQueryOverriderDetails
  | DomainTypeQueryOverriderDetails

export type OverriderDetails =
  | CompanyOverriderDetails
  | PersonOverriderDetails
  | QueryOverriderDetails

export default createContext<OverriderDetails[]>([])