import { SyncOutlined } from '@mui/icons-material'
import { Badge, IconButton, Snackbar } from '@mui/material'
import { MouseEvent, forwardRef, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { getJobs } from 'state/reducers'
import { Job } from 'types'
import { PATH_SEPARATOR } from 'utils/constants'
import { useSnackPack } from 'utils/hooks'

interface Props {
  onClick(event: MouseEvent): void
}

export default forwardRef<HTMLButtonElement, Props>(function JobsButton({
  onClick,
  ...rest
}, ref): JSX.Element {
  const jobs = useSelector(getJobs)
  const inProgress = jobs
    .filter(job => job.jobStatus === undefined
      || job.jobStatus.Status === 'Ready'
      || job.jobStatus.Status === 'Reserved')
  const {
    addMessage,
    open: snackbarOpen,
    message,
    handleClose: handleSnackbarClose,
    handleExited
  } = useSnackPack()
  const previousJobsRef = useRef<Job[]>(jobs)
  useEffect(() => {
    const newlyCompletedJobs = jobs
      .filter(job => job.jobStatus?.Status === 'Completed')
      .filter(job => previousJobsRef.current
        .find(previousJob => previousJob.jobId === job.jobId)
        ?.jobStatus?.Status !== 'Completed')
    const newlyErroredJobs = jobs
      .filter(job => job.jobStatus?.Status === 'Errored')
      .filter(job => previousJobsRef.current
        .find(previousJob => previousJob.jobId === job.jobId)
        ?.jobStatus?.Status !== 'Errored')
    const newJobs = inProgress
      .filter(job => !previousJobsRef.current.find(previousJob => previousJob.jobId === job.jobId))
    previousJobsRef.current = jobs

    if (newJobs.length > 0) {
      addMessage(`${newJobs.length} job${newJobs.length === 1 ? '' : 's'} will continue to run in the background`, 'jobs')
    }
    for (const job of newlyCompletedJobs) {
      addMessage(
        `${[...job.labelPath, job.effect.Name].join(PATH_SEPARATOR)} succeeded`,
        job.jobId
      )
    }
    for (const job of newlyErroredJobs) {
      addMessage(
        `${[...job.labelPath, job.effect.Name].join(PATH_SEPARATOR)} errored`,
        job.jobId
      )
    }
  }, [addMessage, inProgress, jobs])
  return (
    <>
      <IconButton
        aria-label='jobs'
        aria-controls='primary-search-jobs-view'
        aria-haspopup='true'
        onClick={onClick}
        color='inherit'
        size='medium'
        ref={ref}
        {...rest}>
        <Badge
          color='primary'
          badgeContent={inProgress.length}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}>
          <Badge
            color='success'
            badgeContent={jobs.filter(job => job.jobStatus?.Status === 'Completed').length}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}>
            <Badge
              color='error'
              badgeContent={jobs.filter(job => job.jobStatus?.Status === 'Errored').length}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}>
              <SyncOutlined
                sx={{
                  animation: inProgress.length > 0
                    ? 'spin 2s linear infinite'
                    : undefined,
                  '@keyframes spin': {
                    '0%': {
                      transform: 'rotate(360deg)'
                    },
                    '100%': {
                      transform: 'rotate(0deg)'
                    }
                  }
                }} />
            </Badge>
          </Badge>
        </Badge>
      </IconButton>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        TransitionProps={{
          onExited: handleExited
        }}
        message={message} />
    </>
  )
})