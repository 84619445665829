import { Action, SET_HELP } from 'types'

const getDefaultState = (): boolean => {
  return window.localStorage.getItem('help') !== String(false)
}

export default (state = getDefaultState(), action: Action): boolean => {
  switch (action.type) {
    case SET_HELP:
      window.localStorage.setItem('help', String(action.payload.value))
      return action.payload.value
    default:
      return state
  }
}