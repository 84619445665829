import { Icon } from '@mui/material'
import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { getUser } from 'state/reducers'
import { DomainTypeSettingsContext, SidePanelContext } from 'utils/context'
import { canEditInstancesAttributes } from 'utils/helpers'
import { useDomainType, useFilterContext } from 'utils/hooks'
import TooltipIconButton from './TooltipIconButton'

interface Props {
  id: string
}

export default function DomainTypeDetailsButton({
  id
}: Props): JSX.Element | null {
  const domainTypeDomainType = useDomainType('DomainType', 'DomainType')
  const filterContext = useFilterContext()
  const settingsContext = useContext(DomainTypeSettingsContext)
  const user = useSelector(getUser)
  const sidePanels = useContext(SidePanelContext)
  const sidePanelContext = sidePanels
    .find(sidePanel => sidePanel.name === 'root')
  if (domainTypeDomainType === null) {
    return null
  }
  const canEditDomainType = canEditInstancesAttributes(
    filterContext.domainTypes,
    domainTypeDomainType,
    filterContext,
    settingsContext,
    [],
    user,
    []
  )
  if (sidePanelContext === undefined || canEditDomainType !== true) {
    return null
  }
  return (
    <TooltipIconButton
      size='small'
      color='primary'
      tooltipText={filterContext.domainTypes[id]?.Title ?? ''}
      icon={<Icon>{domainTypeDomainType.Icon}</Icon>}
      onClick={() => sidePanelContext.setSidePanel({
        type: 'domainTypeDetails',
        id
      })} />
  )
}