import { Box, Button, FormControl, FormControlLabel, IconButton, Stack, Switch, TextFieldProps, useTheme } from '@mui/material'
import { GridAddIcon, GridCloseIcon, GridColumns, GridFilterFormProps, GridLinkOperator } from '@mui/x-data-grid'
import EnumInput from 'components/attribute/AttributeInput/EnumInput'
import { BooleanFromString } from 'io-ts-types'
import { ComponentProps, MutableRefObject, useEffect, useImperativeHandle, useMemo, useReducer, useRef, useState } from 'react'
import { DomainType, Filter } from 'types'
import { stringifyFilterValue } from 'utils/filters'
import { useLocalStorageState } from 'utils/hooks'
import { FiltersPanelContext } from './TableView'
import filtersReducer, { addFilter, deleteFilter, editFilter } from './filtersReducer'

interface Props {
  domainType: DomainType | null
  selectedColumn: string | undefined
  filtersRef: MutableRefObject<{
    filterLinkOperator: 'and' | 'or',
    filters: Filter[]
  }>
  columns: GridColumns
  onClose(): void
}

const textFieldProps: Partial<TextFieldProps> = {}

function getMultiLinkOperator(filterLinkOperator: 'and' | 'or'): GridLinkOperator {
  return filterLinkOperator === 'and'
    ? GridLinkOperator.And
    : GridLinkOperator.Or
}

function FilterForm({
  item,
  filterableColumns,
  domainType,
  valueInputProps,
  hasMultipleFilters,
  showMultiFilterOperators = false,
  multiFilterOperator,
  focusElementRef,
  includeHiddenColumns,
  applyFilterChanges,
  applyMultiFilterOperatorChanges,
  deleteFilter
}: GridFilterFormProps & { filterableColumns: GridColumns, domainType: DomainType | null, includeHiddenColumns: boolean }): JSX.Element {
  const valueRef = useRef<HTMLElement>(null)
  const currentColumn = item.columnField
    ? filterableColumns.find(column => column.field === item.columnField)
    : null
  const currentOperator = useMemo(() => {
    if (item.operatorValue === undefined
      || !item.operatorValue
      || !currentColumn) {
      return null
    }
    return currentColumn.filterOperators
      ?.find((operator) => operator.value === item.operatorValue)
  }, [item, currentColumn])
  useImperativeHandle(
    focusElementRef,
    () => ({
      focus: () => {
        if (currentOperator?.InputComponent) {
          valueRef.current?.focus()
        }
      }
    }),
    [currentOperator]
  )
  return (
    <Stack
      direction='row'
      p={1}
      alignItems='flex-end'
      gap={1}>
      <FormControl
        sx={{
          flexShrink: 0,
          justifyContent: 'flex-end',
          marginBottom: 0.2
        }}>
        <IconButton
          title='Delete'
          onClick={() => deleteFilter(item)}>
          <GridCloseIcon fontSize='small' />
        </IconButton>
      </FormControl>
      <FormControl
        sx={{
          minWidth: 70,
          justifyContent: 'end',
          display: hasMultipleFilters ? 'flex' : 'none',
          visibility: showMultiFilterOperators ? 'visible' : 'hidden'
        }}>
        <EnumInput
          attributeValue={{
            attribute: {
              Name: 'filterLinkOperator',
              Title: '',
              AttributeType: 'enum',
              EnumeratedType: {
                ExternalId: '',
                GlobalExternalId: '',
                Values: [
                  {
                    Id: 'and',
                    Value: 'and',
                    Description: 'And'
                  },
                  {
                    Id: 'or',
                    Value: 'or',
                    Description: 'Or'
                  }
                ]
              },
              List: false
            },
            value: multiFilterOperator ?? null
          }}
          readOnly={false}
          textFieldProps={textFieldProps}
          disableClearable
          disableSorting
          onChange={attributeValue => applyMultiFilterOperatorChanges(
            attributeValue.value === 'and'
              ? GridLinkOperator.And
              : GridLinkOperator.Or
          )} />
      </FormControl>
      <FormControl
        sx={{
          width: 300
        }}>
        <EnumInput
          attributeValue={{
            attribute: {
              Name: 'columnField',
              Title: 'Column',
              AttributeType: 'enum',
              EnumeratedType: {
                ExternalId: '',
                GlobalExternalId: '',
                Values: filterableColumns.map(column => ({
                  Id: column.field,
                  Value: column.field,
                  Description: column.headerName ?? column.field
                }))
              },
              List: false
            },
            value: item.columnField
          }}
          readOnly={false}
          textFieldProps={textFieldProps}
          disableClearable
          disableSorting={!includeHiddenColumns}
          onChange={attributeValue => {
            const column = filterableColumns.find(column => column.field === attributeValue.value)
            const newOperator = column?.filterOperators
              ?.find(operator => operator.value === item.operatorValue) || column?.filterOperators?.[0]
            applyFilterChanges({
              ...item,
              operatorValue: newOperator?.value,
              columnField: attributeValue.value === null
                ? ''
                : String(attributeValue.value)
            })
          }} />
      </FormControl>
      <FormControl
        sx={{
          width: 150
        }}>
        <EnumInput
          attributeValue={{
            attribute: {
              Name: 'operatorValue',
              Title: 'Operator',
              AttributeType: 'enum',
              EnumeratedType: {
                ExternalId: '',
                GlobalExternalId: '',
                Values: currentColumn?.filterOperators?.map(operator => ({
                  Id: operator.value,
                  Value: operator.value,
                  Description: operator.label ?? operator.value
                })) ?? []
              },
              List: false
            },
            value: item.operatorValue ?? null
          }}
          readOnly={false}
          textFieldProps={textFieldProps}
          disableClearable
          disableSorting
          onChange={attributeValue => applyFilterChanges({
            ...item,
            operatorValue: attributeValue.value === null
              ? undefined
              : String(attributeValue.value)
          })} />
      </FormControl>
      <FormControl
        sx={{
          width: 300
        }}>
        {currentOperator?.InputComponent !== undefined
          ? (
            <currentOperator.InputComponent
              apiRef={{} as ComponentProps<typeof currentOperator.InputComponent>['apiRef']}
              item={item}
              applyValue={applyFilterChanges}
              focusElementRef={valueRef}
              {...currentOperator.InputComponentProps}
              textFieldProps={textFieldProps}
              domainType={domainType}
              {...valueInputProps} />
          )
          : null}
      </FormControl>
    </Stack>
  )
}

export default function FiltersPanel({
  filtersRef,
  columns,
  domainType,
  onClose
}: Props): JSX.Element {
  const [filterLinkOperator, setFilterLinkOperator] = useState<'and' | 'or'>(filtersRef.current.filterLinkOperator)
  const [filters, dispatchFiltersAction] = useReducer(filtersReducer, filtersRef.current.filters)
  useEffect(() => {
    filtersRef.current = {
      filterLinkOperator,
      filters
    }
  }, [filterLinkOperator, filters, filtersRef])
  const theme = useTheme()
  const [includeHiddenColumnsSetting, setIncludeHiddenColumns] = useLocalStorageState(
    'filtersPanel.includeHiddenColumns',
    false,
    BooleanFromString
  )
  const allFilterableColumns = useMemo(() => {
    return columns
      .filter(column => (column.filterable ?? false))
  }, [columns])
  const visibleFilterableColumns = useMemo(() => {
    return allFilterableColumns
      .filter(column => !(column.hide ?? false))
  }, [allFilterableColumns])
  const usesHiddenColumn = useMemo(() => {
    return filters
      .some(filter => !visibleFilterableColumns.some(column => column.field === filter.Property))
  }, [filters, visibleFilterableColumns])
  const includeHiddenColumns = includeHiddenColumnsSetting || usesHiddenColumn
  return (
    <FiltersPanelContext.Provider
      value={{
        filters,
        dispatchFiltersAction
      }}>
      <Stack
        flex='1'
        tabIndex={-1}
        onKeyDown={event => event.stopPropagation()}>
        <Stack
          flex='1 1'
          overflow={{
            x: 'hidden',
            y: 'auto'
          }}
          component='form'
          onSubmit={event => {
            event.preventDefault()
            event.stopPropagation()
            onClose()
          }}
          maxHeight={400}
          minWidth={826}>
          <h3
            style={{
              margin: 0,
              paddingLeft: theme.spacing(2),
              paddingTop: theme.spacing(1)
            }}>
            Filters
          </h3>
          {filters
            .map<[Filter, number]>((filter, index) => [filter, index])
            .map(([filter, index]) => (
              <FilterForm
                key={index}
                item={{
                  id: index,
                  columnField: filter.Property,
                  value: filter.Value,
                  operatorValue: filter.Operator
                }}
                filterableColumns={includeHiddenColumns
                  ? allFilterableColumns
                  : visibleFilterableColumns}
                domainType={domainType}
                hasMultipleFilters={filters.length > 1}
                showMultiFilterOperators={index > 0}
                multiFilterOperator={getMultiLinkOperator(filterLinkOperator)}
                disableMultiFilterOperator={index > 1}
                applyFilterChanges={newFilter => {
                  if (newFilter.columnField !== filter.Property) {
                    dispatchFiltersAction(editFilter(index, 'Property', newFilter.columnField))
                  }
                  if (newFilter.operatorValue !== filter.Operator) {
                    dispatchFiltersAction(editFilter(index, 'Operator', newFilter.operatorValue ?? 'like'))
                  }
                  if (newFilter.value === undefined) {
                    dispatchFiltersAction(deleteFilter(index))
                  }
                }}
                applyMultiFilterOperatorChanges={setFilterLinkOperator}
                deleteFilter={() => {
                  dispatchFiltersAction(deleteFilter(index))
                }}
                includeHiddenColumns={includeHiddenColumns} />
            ))}
          <input
            type='submit'
            hidden />
        </Stack>
        <Stack
          direction='row'
          justifyContent='space-between'
          p={0.5}>
          <Button
            onClick={() => {
              const firstColumn = includeHiddenColumns
                ? allFilterableColumns[0]
                : visibleFilterableColumns[0]
              if (!firstColumn) {
                return
              }
              dispatchFiltersAction(addFilter({
                Property: firstColumn.field,
                Operator: firstColumn.filterOperators?.find(t => true)?.value ?? 'like',
                Value: stringifyFilterValue(undefined)
              }))
            }}
            startIcon={<GridAddIcon />}
            color='primary'>
            Add Filter
          </Button>
          <Box flexGrow={1} />
          <FormControlLabel
            control={(
              <Switch
                disabled={usesHiddenColumn}
                checked={includeHiddenColumns}
                onChange={(event, checked) => setIncludeHiddenColumns(checked)} />
            )}
            label='Include Hidden Columns' />
        </Stack>
      </Stack>
    </FiltersPanelContext.Provider>
  )
}
