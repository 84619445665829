import { Sort } from 'types'

const ADD_SORT = 'ADD_SORT'
type AddSortAction = {
  readonly type: typeof ADD_SORT
  readonly payload: {
    readonly sort: Sort
  }
}
export const addSort = (sort: Sort): AddSortAction => ({
  type: ADD_SORT,
  payload: {
    sort
  }
})

const EDIT_SORT = 'EDIT_SORT'
interface EditSortAction<Key extends keyof Sort> {
  readonly type: typeof EDIT_SORT
  readonly payload: {
    readonly index: number
    readonly key: Key
    readonly value: Sort[Key]
  }
}
export const editSort = <Key extends keyof Sort>(index: number, key: Key, value: Sort[Key]): EditSortAction<Key> => ({
  type: EDIT_SORT,
  payload: {
    index,
    key,
    value
  }
})

const DELETE_SORT = 'DELETE_SORT'
type DeleteSortAction = {
  readonly type: typeof DELETE_SORT
  readonly payload: {
    readonly index: number
  }
}
export const deleteSort = (index: number): DeleteSortAction => ({
  type: DELETE_SORT,
  payload: {
    index
  }
})

export type SortsAction =
  | AddSortAction
  | EditSortAction<keyof Sort>
  | DeleteSortAction

export default function sortsReducer(state: Sort[] = [], action: SortsAction): Sort[] {
  switch (action.type) {
    case ADD_SORT:
      return [
        ...state,
        action.payload.sort
      ]
    case EDIT_SORT: {
      const sort = state[action.payload.index]
      if (sort === undefined) {
        return state
      }
      return [
        ...state.slice(0, action.payload.index),
        {
          ...sort,
          [action.payload.key]: action.payload.value
        },
        ...state.slice(action.payload.index + 1)
      ]
    }
    case DELETE_SORT:
      return [
        ...state.slice(0, action.payload.index),
        ...state.slice(action.payload.index + 1)
      ]
    default:
      return state
  }
}