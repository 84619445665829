import { useTheme } from '@mui/material'
import * as E from 'fp-ts/Either'
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'
import { BooleanFromString, DateFromISOString, NumberFromString } from 'io-ts-types'
import { DateTime } from 'luxon'
import { useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { ApiError, Attribute, DomainType, DomainTypeInstance, DomainTypeOverrider, Filter, Query, Sort } from 'types'
import { limitSearchPageSize } from 'utils/api'
import { JsonFilterCodec, JsonFromUnknown, JsonSortCodec } from 'utils/codecs'
import { DomainTypeContext, SidePanel, SidePanelContext } from 'utils/context'
import { getAnyAllFilters, stringifyFilterValue } from 'utils/filters'
import { getDomainTypeSetting, getRootDomainType, isNullOrUndefined, isValidStartEndDateAttribute } from 'utils/helpers'
import { ButtonTarget, SignedInApi, SnackPack, useApi, useCancellableApiSession, useDeepEqualMemo, useDefaultSorts, useDomainTypeSetting, useNavigate, useOverriders, useSearchParams, useSnackPack } from 'utils/hooks'
import { ActionDetails } from 'utils/hooks/useActions'
import findPageReducer, { changeItemDatesError, closeActionDialog, defaultState, moveRouteStop, performAction, performChangeItemDates, performSearch, performSearchFulfilled, viewChanged } from './findPageReducer'
import { getChangeItemDatesPatchRequestBody, getChangeRouteDatesPatchRequestDatabaseTableAndBody, getMoveStopToSameRoutePutRequestBody, getTimelineItemChains } from './helpers'
import { CalendarProps, CalendarTimelineSettings, CustomTimelineItem, FindPageView, GroupByValue, MovingStop } from './types'

export const codecs = {
  page: NumberFromString,
  pageSize: NumberFromString,
  searchText: t.string,
  sorts: JsonFromUnknown.pipe(t.array(JsonSortCodec)),
  filterLinkOperator: t.union([t.literal('and'), t.literal('or')]),
  filters: JsonFromUnknown.pipe(t.array(JsonFilterCodec)),
  bypassDomainTypeFilters: JsonFromUnknown.pipe(t.array(t.string)),
  bypassSearchIndex: BooleanFromString,
  queryId: t.string,
  date: DateFromISOString
}

function getCalendarViewFilters(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType,
  overriders: DomainTypeOverrider[],
  view: FindPageView,
  calendarView: CalendarProps['view'],
  date: Date
): Filter[] | null {
  if (!isCalendarView(view)) {
    return null
  }
  const [domainTypeChain] = getTimelineItemChains(domainTypes, domainType, overriders)
  let index = domainTypeChain.length - 1
  let startDateAttribute: Attribute | null | undefined = undefined
  let endDateAttribute: Attribute | null | undefined = undefined
  do {
    if (index < 0) {
      return null
    }
    ({
      startDateAttribute,
      endDateAttribute
    } = domainTypeChain[index--] ?? {})
    endDateAttribute = endDateAttribute ?? startDateAttribute
  } while (
    startDateAttribute === null
    || !isValidStartEndDateAttribute(startDateAttribute)
    || !isValidStartEndDateAttribute(endDateAttribute)
  )
  const prefix = domainTypeChain.slice(0, index + 1).map(settings => settings.itemsAttribute?.Name ?? '')
  const startOfWindow = DateTime.fromJSDate(date).startOf(calendarView).toUTC().toISO()
  const endOfWindow = DateTime.fromJSDate(date).endOf(calendarView).toUTC().toISO()
  return [
    {
      Property: [...prefix, startDateAttribute.Name].join('_'),
      Operator: startDateAttribute.AttributeType === 'date'
        ? 'lte'
        : 'lt',
      Value: stringifyFilterValue(endOfWindow)
    },
    {
      Property: [...prefix, endDateAttribute.Name].join('_'),
      Operator: endDateAttribute.AttributeType === 'date'
        ? 'gte'
        : 'gt',
      Value: stringifyFilterValue(startOfWindow)
    }
  ]
}

function isCalendarView(view: FindPageView): boolean {
  return view === 'calendar' || view === 'timeline'
}

interface UseFindOutput {
  isLoading: boolean
  items: DomainTypeInstance[]
  total: number
  calendarItems: DomainTypeInstance[]
  calendarProps: CalendarProps
  page: number
  pageSize: number
  searchText: string
  sorts: Sort[]
  filterLinkOperator: 'and' | 'or'
  filters: Filter[]
  isExporting: boolean
  checkedRowIds: string[]
  checkedItems: DomainTypeInstance[]
  allChecked: boolean
  selectionTarget: ButtonTarget
  view: FindPageView
  snackPack: SnackPack
  mapOpen: boolean
  highlightedRowIds: string[]
  onSearch(): void
  onSearchTextChange(value?: string): void
  onFilterLinkOperatorChange(value: 'and' | 'or'): void
  onFiltersChange(value: Filter[]): void
  onPageChange(value: number): void
  onPageSizeChange(value: number): void
  onSortsChange(value: Sort[]): void
  onRowClick(id: string): void
  fetchTotal(api: SignedInApi, query: Query): Promise<E.Either<ApiError, number>>
  onApplyQuery(query: Query): void
  onClickExport: undefined | ((columns: string[]) => void)
  onCheckedRowIdsChange(ids: string[]): void
  onAllCheckedChange(value: boolean): void
  onViewChange(value: FindPageView): void
  onMapOpenChange?(open: boolean): void
}

const EMPTY_CHCKED_ROW_IDS: string[] = []

export default function useFind(
  domainType: DomainType,
  urlPrefix = '',
  additionalFilters?: Filter[]
): UseFindOutput {
  const domainTypes = useSelector(getAllDomainTypes)
  const rootDomainType = useSelector(() => getRootDomainType(domainTypes, domainType))
  const defaultSorts = useDefaultSorts(domainType)
  const defaults = useMemo(() => ({
    page: 1,
    pageSize: 15,
    searchText: '',
    sorts: defaultSorts,
    filters: [],
    filterLinkOperator: 'and' as 'and' | 'or',
    bypassDomainTypeFilters: [],
    bypassSearchIndex: false,
    queryId: '',
    date: DateTime.now().toJSDate()
  }), [defaultSorts])
  const [
    {
      isLoading,
      searchResponse,
      editingItem,
      movingStop,
      actionDetails,
      actionDialogOpen,
      changeItemDatesErrorCode,
      wasDisplayingCalendarView,
      searchInputs: {
        page,
        pageSize,
        searchText,
        filters,
        sorts,
        filterLinkOperator,
        bypassSearchIndex,
        bypassDomainTypeFilters
      }
    },
    dispatch
  ] = useReducer(findPageReducer, defaultState)
  const [
    {
      date,
      ...searchInputs
    },
    {
      push: pushState
    }
  ] = useSearchParams(codecs, defaults, urlPrefix)
  const onSortsChange = useCallback((value: Sort[]) => pushState({
    sorts: value
  }), [pushState])
  const [view, setView] = useDomainTypeSetting(
    domainType,
    'FindView',
    'table'
  )
  const onViewChange = useCallback((newView: FindPageView) => {
    dispatch(viewChanged(view, newView))
    setView(newView)
  }, [setView, view])
  const api = useApi()
  const overriders = useOverriders()
  const [calendarView, onCalendarViewChange] = useDomainTypeSetting(
    domainType,
    'CalendarView',
    'month'
  )
  const calendarViewFilters = useDeepEqualMemo(useMemo(
    () => getCalendarViewFilters(domainTypes, domainType, overriders, view, calendarView, date),
    [calendarView, date, domainType, domainTypes, overriders, view]
  ))
  const isDisplayingCalendarView = isCalendarView(view)
  const search = useCancellableApiSession(api)
  const onSearch = useCallback(function doSearch() {
    const apiSession = search.cancelPreviousAndStartNew()
    if (rootDomainType === null || !apiSession.isSignedIn) {
      return search.cancel
    }
    const [searchPage, searchPageSize] = isDisplayingCalendarView
      ? [1, 500]
      : [searchInputs.page, searchInputs.pageSize]
    dispatch(performSearch({
      page: searchInputs.page,
      pageSize: searchInputs.pageSize,
      searchText: searchInputs.searchText,
      filters: searchInputs.filters,
      sorts: searchInputs.sorts,
      filterLinkOperator: searchInputs.filterLinkOperator,
      bypassSearchIndex: searchInputs.bypassSearchIndex,
      bypassDomainTypeFilters: searchInputs.bypassDomainTypeFilters
    }))
    const [anyFilters, allFilters] = getAnyAllFilters(
      domainTypes,
      domainType,
      searchInputs.filterLinkOperator,
      searchInputs.filters,
      searchInputs.searchText,
      additionalFilters
    )
    apiSession.search(
      rootDomainType.DatabaseTable ?? rootDomainType.Name,
      domainType.Name,
      anyFilters,
      allFilters.concat(calendarViewFilters ?? []),
      searchInputs.sorts,
      searchPage,
      limitSearchPageSize(searchPageSize),
      searchInputs.bypassSearchIndex,
      searchInputs.bypassDomainTypeFilters
    )
      .then(response => {
        if (E.isRight(response)) {
          dispatch(performSearchFulfilled(response.right, isDisplayingCalendarView))
        }
      })
    return search.cancel
  }, [search, rootDomainType, isDisplayingCalendarView, searchInputs.page, searchInputs.pageSize, searchInputs.searchText, searchInputs.filters, searchInputs.sorts, searchInputs.filterLinkOperator, searchInputs.bypassSearchIndex, searchInputs.bypassDomainTypeFilters, domainTypes, domainType, additionalFilters, calendarViewFilters])
  useEffect(() => {
    return onSearch()
  }, [onSearch])
  const onSearchTextChange = useCallback((value: string) => pushState({
    searchText: value,
    page: 1
  }), [pushState])
  const onFilterLinkOperatorChange = useCallback((value: 'and' | 'or') => pushState({
    page: 1,
    filterLinkOperator: value
  }), [pushState])
  const onFiltersChange = useCallback((value: Filter[]) => pushState({
    page: 1,
    filters: value
  }), [pushState])
  const onPageChange = useCallback((value: number) => pushState({
    page: value
  }), [pushState])
  const onPageSizeChange = useCallback((value: number) => pushState({
    pageSize: value,
    page: Math.floor(((page - 1) * pageSize + 1) / value) + 1
  }), [pushState, page, pageSize])

  const navigate = useNavigate()
  const domainTypeContext = useContext(DomainTypeContext)
  const fetchTotal = useCallback(async (api: SignedInApi, query: Query) => {
    const [anyFilters, allFilters] = getAnyAllFilters(
      domainTypes,
      domainType,
      query.FilterLinkOperator ?? 'and',
      query.Filters,
      query.SearchText ?? '',
      additionalFilters
    )
    const response = await api.search(
      rootDomainType?.Name ?? domainType.DatabaseTable ?? '',
      domainType.Name,
      anyFilters,
      allFilters,
      query.Sorts,
      1,
      limitSearchPageSize(0),
      query.BypassSearchIndex ?? false,
      query.BypassDomainTypeFilters ?? []
    )
    return pipe(
      response,
      E.map(
        searchResult => searchResult.totalHits
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalFilters, domainType, domainTypes, rootDomainType?.Name, searchResponse])
  const onApplyQuery = useCallback((query: Query) => {
    pushState({
      filters: query.Filters,
      sorts: query.Sorts,
      searchText: query.SearchText ?? '',
      page: 1,
      queryId: query.Id,
      filterLinkOperator: query.FilterLinkOperator ?? 'and',
      bypassDomainTypeFilters: query.BypassDomainTypeFilters ?? [],
      bypassSearchIndex: query.BypassSearchIndex ?? false
    })
  }, [pushState])
  const [isExporting, setIsExporting] = useState(false)
  const onClickExport = useCallback(async (columns: string[]) => {
    if (rootDomainType === null || !api.isSignedIn) {
      return
    }
    setIsExporting(true)
    const [anyFilters, allFilters] = getAnyAllFilters(
      domainTypes,
      domainType,
      filterLinkOperator,
      filters,
      searchText,
      additionalFilters
    )

    await api.export(
      rootDomainType.DatabaseTable ?? rootDomainType.Name,
      domainType.Name,
      anyFilters,
      allFilters,
      sorts,
      columns,
      bypassSearchIndex,
      bypassDomainTypeFilters
    )
    setIsExporting(false)
  }, [additionalFilters, api, bypassDomainTypeFilters, bypassSearchIndex, domainType, domainTypes, filterLinkOperator, filters, rootDomainType, searchText, sorts])
  const [allChecked, onAllCheckedChange] = useState(false)
  const [checkedRowIds, setCheckedRowIds] = useState<string[]>(EMPTY_CHCKED_ROW_IDS)
  const onCheckedRowIdsChange = useCallback((ids: string[]) => {
    setCheckedRowIds(ids)
    onAllCheckedChange(false)
  }, [])
  useEffect(() => {
    onCheckedRowIdsChange(EMPTY_CHCKED_ROW_IDS)
  }, [onCheckedRowIdsChange, page, pageSize])
  const onChangeItemDates = useCallback(async (
    type: CustomTimelineItem['type'],
    itemId: string,
    domainTypeChain: CalendarTimelineSettings[],
    groupItems: DomainTypeInstance[],
    items: DomainTypeInstance[],
    startDate: number,
    endDate: number,
    groupByValue?: GroupByValue,
    actionDetails?: ActionDetails
  ) => {
    if (rootDomainType === null || !api.isSignedIn) {
      return
    }
    if (type === 'route') {
      const [databaseTable, body] = getChangeRouteDatesPatchRequestDatabaseTableAndBody(
        domainTypeChain,
        items[0],
        startDate
      )
      dispatch(performChangeItemDates({
        itemId,
        startDate,
        endDate,
        groupByValue
      }, actionDetails))
      if (actionDetails !== undefined) {
        return
      }
      const response = await api.patch(
        databaseTable,
        body
      )
      if (E.isRight(response)) {
        onSearch()
      } else {
        dispatch(changeItemDatesError(response.left.errorCode))
      }
      return
    }
    const body = getChangeItemDatesPatchRequestBody(
      domainTypeChain,
      groupItems,
      items,
      startDate,
      endDate,
      groupByValue
    )
    if (body === null) {
      return
    }
    dispatch(performChangeItemDates({
      itemId,
      startDate,
      endDate,
      groupByValue
    }, actionDetails))
    if (actionDetails !== undefined) {
      return
    }
    const response = await api.patch(
      rootDomainType.Name,
      body
    )
    if (E.isRight(response)) {
      onSearch()
    } else {
      dispatch(changeItemDatesError(response.left.errorCode))
    }
  }, [api, onSearch, rootDomainType])
  const onMoveRouteStop = useCallback((movingStop: MovingStop) => {
    async function move() {
      if (!api.isSignedIn) {
        return
      }
      if (movingStop.fromRoute.Id !== movingStop.toRoute.Id) {
        return
      }
      const putRequestBody = getMoveStopToSameRoutePutRequestBody(
        movingStop.fromRoute,
        movingStop.fromIndex,
        movingStop.toIndex
      )
      const databaseTable = movingStop.routeDatabaseTable
      if (putRequestBody === null
        || isNullOrUndefined(databaseTable)) {
        return
      }
      dispatch(moveRouteStop(movingStop))
      await api.put(
        databaseTable,
        putRequestBody as unknown as DomainTypeInstance
      )
      onSearch()
    }
    move()
  }, [api, onSearch])
  const onDateChange = useCallback((value: Date) => pushState({
    date: value
  }), [pushState])
  const onCloseActionDialog = useCallback(() => {
    dispatch(closeActionDialog())
  }, [])
  const onPerformAction = useCallback(() => {
    dispatch(performAction())
    onSearch()
  }, [onSearch])
  const snackPack = useSnackPack()
  const { addMessage } = snackPack
  const { formatMessage } = useIntl()
  useEffect(() => {
    if (changeItemDatesErrorCode === undefined) {
      return
    }
    addMessage(formatMessage({
      id: changeItemDatesErrorCode,
      defaultMessage: changeItemDatesErrorCode
    }))
  }, [changeItemDatesErrorCode, formatMessage, addMessage])
  const calendarProps = useMemo(() => ({
    view: calendarView,
    date,
    editingItem,
    movingStop,
    actionDetails,
    actionDialogOpen,
    onViewChange: onCalendarViewChange,
    onDateChange,
    onChangeItemDates,
    onCloseActionDialog,
    onPerformAction,
    onMoveRouteStop
  }), [actionDetails, actionDialogOpen, calendarView, date, editingItem, movingStop, onCalendarViewChange, onChangeItemDates, onCloseActionDialog, onDateChange, onMoveRouteStop, onPerformAction])
  const {
    items,
    calendarItems
  } = useMemo(() => ({
    items: !wasDisplayingCalendarView
      ? searchResponse?.results ?? []
      : [],
    calendarItems: wasDisplayingCalendarView
      ? searchResponse?.results ?? []
      : []
  }), [searchResponse?.results, wasDisplayingCalendarView])
  const checkedItems = useMemo(() => {
    if (checkedRowIds.length === 0) {
      return []
    }
    const identifier = getDomainTypeSetting(domainTypes, domainType, 'Identifier') ?? 'Id'
    return items.filter(item => {
      const itemId = String(item[identifier])
      return checkedRowIds.includes(itemId)
    })
  }, [checkedRowIds, domainType, domainTypes, items])
  const total = isDisplayingCalendarView === wasDisplayingCalendarView
    ? searchResponse?.totalHits ?? 0
    : 0
  const selectionTarget = useMemo((): ButtonTarget => {
    if (allChecked) {
      return {
        type: 'query',
        query: {
          Id: 'buttonTarget',
          Title: 'Button Target',
          SearchText: searchText,
          Filters: filters,
          FilterLinkOperator: filterLinkOperator,
          Sorts: sorts,
          BypassDomainTypeFilters: bypassDomainTypeFilters,
          BypassSearchIndex: bypassSearchIndex,
          AdditionalFilters: additionalFilters
        },
        total
      }
    }
    return {
      type: 'instances',
      instances: checkedItems
    }
  }, [additionalFilters, allChecked, bypassDomainTypeFilters, bypassSearchIndex, checkedItems, filterLinkOperator, filters, searchText, sorts, total])
  const sidePanels = useContext(SidePanelContext)
  const previewSidePanelContext = sidePanels
    .find(sidePanel => sidePanel.name === 'preview')
  const { setSidePanel: setPreviewSidePanel } = previewSidePanelContext ?? {
    sidePanel: null,
    setSidePanel: () => null
  }
  const mapSidePanelContext = sidePanels
    .find(sidePanel => sidePanel.name === 'map')
  const { sidePanel: currentMapSidePanel, setSidePanel: setMapSidePanel } = mapSidePanelContext ?? {
    sidePanel: null,
    setSidePanel: () => null
  }
  const theme = useTheme()
  const mapSidePanel = useMemo<SidePanel>(() => ({
    id: domainType.Id,
    type: 'map',
    domainType,
    instances: isDisplayingCalendarView
      ? calendarItems
      : items,
    theme
  }), [calendarItems, domainType, isDisplayingCalendarView, items, theme])
  useEffect(() => {
    if (currentMapSidePanel?.id === mapSidePanel.id) {
      setMapSidePanel(mapSidePanel)
    }
  }, [mapSidePanel, setMapSidePanel, currentMapSidePanel?.id])
  const onMapOpenChange = useCallback((open: boolean) => {
    if (open) {
      setMapSidePanel(mapSidePanel)
    } else {
      setMapSidePanel(null)
    }
  }, [mapSidePanel, setMapSidePanel])
  const gps = useMemo(() => {
    return getDomainTypeSetting(domainTypes, domainType, 'Gps')
  }, [domainType, domainTypes])
  useEffect(() => {
    if (currentMapSidePanel?.id !== domainType.Id) {
      setMapSidePanel(null)
    }
  }, [domainType.Id, setMapSidePanel, currentMapSidePanel?.id])
  const [highlightedRowId, setHighlightedRowId] = useState<string | null>(null)
  const onRowClick = useCallback((id: string) => {
    if (rootDomainType === null) {
      return
    }

    // use to open in side panel
    //setHighlightedRowId(id)
    const identifier = getDomainTypeSetting(domainTypes, rootDomainType, 'Identifier') ?? 'Id'
    const instance = searchResponse?.results.find(row => String(row[identifier]) === id)
    if (instance !== undefined) {
      navigate.toDetailsPage(domainType, instance)
    }
  }, [domainType, domainTypes, navigate, rootDomainType, searchResponse?.results])
  const previewSidePanel = useMemo<SidePanel | null>(() => {
    const identifier = getDomainTypeSetting(domainTypes, rootDomainType, 'Identifier') ?? 'Id'
    const instance = searchResponse?.results.find(row => String(row[identifier]) === highlightedRowId)
    if (instance === undefined) {
      return null
    }
    return {
      id: `${domainType.Id}_${highlightedRowId}`,
      type: 'details',
      context: domainTypeContext,
      domainType: domainType,
      instance: instance,
      title: domainType.Title,
      theme,
      onClose: () => setHighlightedRowId(null)
    }
  }, [domainType, domainTypeContext, domainTypes, highlightedRowId, rootDomainType, searchResponse?.results, theme])
  useEffect(() => {
    setPreviewSidePanel(previewSidePanel)
  }, [previewSidePanel, setPreviewSidePanel])
  const highlightedRowIds = useMemo(() => {
    if (highlightedRowId === null) {
      return []
    }
    return [highlightedRowId]
  }, [highlightedRowId])
  return {
    view,
    isLoading,
    items,
    total: isDisplayingCalendarView === wasDisplayingCalendarView
      ? searchResponse?.totalHits ?? 0
      : 0,
    calendarItems,
    calendarProps,
    page,
    pageSize,
    searchText,
    sorts,
    filterLinkOperator,
    filters,
    isExporting,
    checkedRowIds,
    checkedItems,
    allChecked,
    selectionTarget,
    snackPack,
    mapOpen: currentMapSidePanel?.id === mapSidePanel.id,
    highlightedRowIds,
    onSearch,
    onSearchTextChange,
    onFilterLinkOperatorChange,
    onFiltersChange,
    onPageChange,
    onPageSizeChange,
    onSortsChange,
    onRowClick,
    fetchTotal,
    onApplyQuery,
    onClickExport: getDomainTypeSetting(domainTypes, domainType, 'Api') === true
      ? onClickExport
      : undefined,
    onCheckedRowIdsChange,
    onAllCheckedChange,
    onViewChange,
    onMapOpenChange: isNullOrUndefined(gps)
      ? undefined
      : onMapOpenChange
  }
}
