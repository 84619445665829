import { Box, Paper, Popover, Stack, textFieldClasses } from '@mui/material'
import { GridColumns } from '@mui/x-data-grid'
import { MutableRefObject, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { DomainType, Filter, Sort } from 'types'
import FiltersAndSorts from './FiltersAndSorts'
import FiltersPanel from './FiltersPanel'
import SearchInput from './SearchInput'
import SortPanel from './SortPanel'

interface Props {
  domainType: DomainType | null
  panelOpen: string | false
  setPanelOpen(value: string | false): void
  filterLinkOperator: 'and' | 'or'
  filtersRef: MutableRefObject<{
    filterLinkOperator: 'and' | 'or',
    filters: Filter[]
  }>
  filters: Filter[] | undefined
  sorts: Sort[] | undefined
  sortsRef: MutableRefObject<{
    sorts: Sort[]
  }>
  columns: GridColumns
  searchText: string
  onSearch(searchText: string): void
  onSearchTextChange?(searchText: string): void
  onFiltersChange?(filters: Filter[]): void
  onSortsChange?(sorts: Sort[]): void
  sortsAndFiltersPanelKey: string
}

export default function ListToolbar({
  columns,
  panelOpen,
  setPanelOpen,
  filterLinkOperator,
  filters,
  sorts,
  sortsRef,
  domainType,
  searchText,
  onSearch,
  onSearchTextChange,
  onFiltersChange,
  onSortsChange,
  filtersRef,
  sortsAndFiltersPanelKey,
  children
}: PropsWithChildren<Props>): JSX.Element {
  const ref = useRef<HTMLDivElement | null>(null)
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  useEffect(() => {
    const rootElement = ref.current
    if (rootElement !== null) {
      setAnchorEl(rootElement)
    }
  }, [])
  const handleClickAway = useCallback(() => {
    setPanelOpen(false)
  }, [setPanelOpen])
  const panel = useMemo(() => {
    return (
      <>
        {panelOpen === 'sorts' && (
          <SortPanel
            key={sortsAndFiltersPanelKey}
            columns={columns}
            onClose={handleClickAway}
            sortsRef={sortsRef} />
        )}
        {panelOpen === 'filters' && (
          <FiltersPanel
            key={sortsAndFiltersPanelKey}
            columns={columns}
            domainType={domainType}
            onClose={handleClickAway}
            selectedColumn={undefined}
            filtersRef={filtersRef} />
        )}
      </>
    )
  }, [columns, domainType, filtersRef, handleClickAway, panelOpen, sortsAndFiltersPanelKey, sortsRef])
  return (
    <>
      <Stack
        ref={ref}
        direction='row'
        alignItems='center'
        flexWrap='nowrap'
        borderBottom={theme => {
          const colour = theme.palette.grey[{
            light: '300' as const,
            dark: '700' as const
          }[theme.palette.mode]]
          return `1px solid ${colour}`
        }}
        p={1}
        height='61px'
        sx={{
          [`& .${textFieldClasses.root}`]: {
            width: '125px'
          }
        }}>
        {domainType !== null && (
          <>
            <SearchInput
              domainType={domainType}
              searchText={searchText}
              columns={columns}
              filters={filters}
              onSearch={onSearch}
              onSearchTextChange={onSearchTextChange}
              onFiltersChange={onFiltersChange} />
            <FiltersAndSorts
              domainType={domainType}
              filterLinkOperator={filterLinkOperator}
              filters={filters}
              onFiltersChange={onFiltersChange}
              sorts={sorts}
              onSortsChange={onSortsChange}
              panelOpen={panelOpen}
              setPanelOpen={setPanelOpen}
              hideChips />
          </>
        )}
        <Box flexGrow={1} />
        {children}
      </Stack>
      <Popover
        open={panelOpen !== false}
        onClose={handleClickAway}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        onResize={undefined}
        onResizeCapture={undefined}
        sx={{
          zIndex: 1300
        }}>
        <Paper
          elevation={8}
          sx={{
            width: '100%',
            minWidth: '300px',
            maxHeight: '450px'
          }}>
          {panel}
        </Paper>
      </Popover>
    </>
  )
}
