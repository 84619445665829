import { Close } from '@mui/icons-material'
import { TabContext, TabPanel } from '@mui/lab'
import { Box, BoxProps, CircularProgress, Icon, Paper, Tab, Tabs, useTheme } from '@mui/material'
import TooltipIconButton from 'components/utils/TooltipIconButton'
import { useCallback, useEffect, useReducer } from 'react'
import { MultiDataformResultsAttribute } from 'types'
import { MultiResults, Results } from 'types/dataform'
import { getDataformResultsIcon, isNullOrUndefined } from 'utils/helpers'
import { useDomainTypeSetting } from 'utils/hooks'
import ResultsInput from './ResultsInput'
import multiDataformReducer, { defaultState, initialised, resultsChanged, resultsCompleted, tabChanged } from './multiDataformReducer'

interface Props {
  readonly initialMultiResults: MultiResults
  readonly attribute: MultiDataformResultsAttribute | null
  readonly errorMessage?: string
  readonly readOnly?: boolean
  readonly maxHeight?: BoxProps['maxHeight']
  readonly minHeight?: BoxProps['minHeight']
  readonly isLoading?: boolean
  onChange?(value: MultiResults): void
  onComplete?(): void
  onClose?(): void
}

export default function MultiResultsInput({
  initialMultiResults,
  attribute,
  errorMessage,
  readOnly = false,
  maxHeight,
  minHeight,
  isLoading = false,
  onChange,
  onComplete,
  onClose
}: Props): JSX.Element | null {
  const [
    {
      tab,
      multiResults,
      allCompleted
    },
    dispatch
  ] = useReducer(multiDataformReducer, defaultState)
  useEffect(() => {
    if (multiResults === null && initialMultiResults.length > 0) {
      dispatch(initialised(initialMultiResults))
    }
  }, [multiResults, initialMultiResults])
  useEffect(() => {
    if (readOnly === true) {
      return
    }
    if (multiResults !== null) {
      onChange?.(multiResults)
    }
    if (allCompleted) {
      onComplete?.()
    }
  }, [onChange, multiResults, readOnly, allCompleted, onComplete])
  const theme = useTheme()
  const onResultsChange = useCallback((results: Results): void => {
    dispatch(resultsChanged(results))
  }, [])
  const onResultsComplete = useCallback(() => {
    dispatch(resultsCompleted())
  }, [])
  const [dataformIcon] = useDomainTypeSetting(
    ['Dataform', 'Dataform'],
    'Icon',
    'dynamic_form'
  )
  const tabValue = tab ?? multiResults?.[0]?.key ?? 'placeholder'
  return (
    <Box
      component={Paper}
      elevation={2}
      flexGrow={1}>
      <TabContext value={tabValue}>
        <Tabs
          variant='scrollable'
          value={tabValue}
          onChange={(event, tab) => dispatch(tabChanged(tab))}
          sx={{
            borderBottom: 1,
            borderColor: 'divider'
          }}>
          {multiResults === null || isLoading
            ? (
              <Tab
                id='placeholder'
                value='placeholder'
                sx={{ justifyContent: 'flex-start' }}
                label={(
                  <span>
                    {isLoading
                      ? <CircularProgress size='1rem' />
                      : 'Error'}
                  </span>
                )}
                iconPosition='start'
                icon={(
                  <Icon>
                    {dataformIcon}
                  </Icon>
                )} />
            )
            : multiResults.map(({ key, dataform, results }) => {
              const [icon, color] = getDataformResultsIcon(results, theme)
              return (
                <Tab
                  key={key}
                  id={key}
                  value={key}
                  label={(dataform?.Settings.DisplayText ?? '') || (dataform?.Name ?? '') || key}
                  iconPosition='start'
                  icon={(
                    <Icon sx={{ color }}>
                      {icon ?? dataformIcon}
                    </Icon>
                  )} />
              )
            })}
          <Box flexGrow={1} />
          {onClose !== undefined && (
            <TooltipIconButton
              tooltipText='Close'
              icon={<Close />}
              onClick={onClose} />
          )}
        </Tabs>
        {multiResults === null
          ? (
            <TabPanel
              value='placeholder'
              sx={{
                flexGrow: 1,
                padding: 0
              }}>
              <ResultsInput
                dataform={null}
                initialResults={null}
                attribute={null}
                errorMessage={errorMessage}
                noBoxShadow
                maxHeight={maxHeight}
                minHeight={minHeight}
                isLoading={isLoading} />
            </TabPanel>
          )
          : multiResults.map(({ key, dataform, results, errorMessage }) => (
            <TabPanel
              key={key}
              value={key}
              sx={{
                flexGrow: 1,
                padding: 0
              }}>
              <ResultsInput
                dataform={dataform}
                initialResults={results}
                attribute={isNullOrUndefined(attribute)
                  ? null
                  : [attribute, key]}
                errorMessage={errorMessage}
                readOnly={readOnly}
                noBoxShadow
                maxHeight={maxHeight}
                minHeight={minHeight}
                isLoading={isLoading}
                onChange={onResultsChange}
                onComplete={onResultsComplete} />
            </TabPanel>
          ))}
      </TabContext>
    </Box>
  )
}