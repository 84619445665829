import { Button, ButtonGroup, Card, CardActions, CardContent, Grid, Stack, styled, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import DomainTypeButtons from 'components/domainType/DomainTypeButtons'
import DomainTypeIcon from 'components/domainType/DomainTypeIcon'
import TextIconButton from 'components/utils/TextIconButton'
import * as E from 'fp-ts/Either'
import { pipe } from 'fp-ts/function'
import { ComponentProps } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, Link } from 'react-router-dom'
import { push } from 'redux-first-history'
import { getAllDomainTypes } from 'state/reducers'
import { DomainType, Query } from 'types'
import { limitSearchPageSize } from 'utils/api'
import { PAGE_URL } from 'utils/constants'
import { getAnyAllFilters } from 'utils/filters'
import { getDomainTypeSetting, getRootDomainType } from 'utils/helpers'
import { useQueries } from 'utils/hooks'
import { QueryChips } from '../FindPage'

const StyledGrid = styled(Grid)(({ theme }) => ({
  '& .MuiCardHeader-action': {
    alignSelf: 'center',
    marginRight: 0
  }
}))

interface Props {
  domainType: DomainType
}

const currentQuery: Query = {
  Id: '',
  Title: '',
  Filters: [],
  Sorts: []
}

const components: ComponentProps<typeof DomainTypeButtons>['components'] = {
  Container: props => (
    <ButtonGroup
      variant='contained'
      {...props} />
  ),
  Button: props => (
    <TextIconButton
      iconOnly
      {...props} />
  ),
  Empty: null
}

export default function QueryCard({
  domainType
}: Props): JSX.Element | null {
  const dispatch = useDispatch()
  const theme = useTheme()

  const {
    overriderQueries,
    domainTypeQueries
  } = useQueries(domainType)
  const domainTypes = useSelector(getAllDomainTypes)
  const rootDomainType = getRootDomainType(domainTypes, domainType)
  if (overriderQueries.length === 0 && domainTypeQueries.length === 0) {
    return null
  }
  return (
    <StyledGrid
      key={domainType.Id}
      item
      xl={2}
      lg={3}
      md={4}
      sm={6}
      xs={12}>
      <Card
        sx={{
          maxWidth: 350,
          background: theme => theme.palette.background.paper
        }}>
        <CardContent
          sx={{
            p: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 1
          }}>
          <Stack
            display='flex'
            flexDirection='row'
            alignItems='center'>
            <Button
              href='/'
              component={Link}
              sx={{
                color: theme.palette.text.primary,
                textDecoration: 'none',
                display: 'flex',
                flexDirection: 'row',
                gap: 1
              }}
              to={{
                pathname: generatePath(PAGE_URL.FIND, {
                  databaseTable: domainType.DatabaseTable ?? domainType.Name,
                  name: domainType.Name
                })
              }}
              onClick={event => event.stopPropagation()}>
              <DomainTypeIcon
                avatar
                avatarScale={1.2}
                domainType={domainType} />
              {domainType.PluralTitle}
            </Button>
            <Box sx={{
              marginLeft: 'auto'
            }}>
              <DomainTypeButtons
                domainType={domainType}
                target={{
                  type: 'none'
                }}
                on='TableToolbar'
                components={components}
                priority='hideAll'
                renderPopoverButtonInsideContainer />
            </Box>

          </Stack>
          {overriderQueries.length > 0 || domainTypeQueries.length > 0
            ? (
              <QueryChips
                domainType={domainType}
                currentQuery={currentQuery}
                overriderQueries={overriderQueries}
                domainTypeQueries={domainTypeQueries}
                renderContainer
                hideAddButton
                fetchTotal={async (api, query) => {
                  const [anyFilters, allFilters] = getAnyAllFilters(
                    domainTypes,
                    domainType,
                    query.FilterLinkOperator ?? 'and',
                    query.Filters,
                    query.SearchText ?? ''
                  )
                  const response = await api.search(
                    getDomainTypeSetting(domainTypes, domainType, 'DatabaseTable') ?? rootDomainType?.Name ?? '',
                    domainType.Name,
                    anyFilters,
                    allFilters,
                    query.Sorts,
                    1,
                    limitSearchPageSize(0)
                  )
                  return pipe(
                    response,
                    E.map(
                      searchResult => searchResult.totalHits
                    )
                  )
                }}
                onApplyQuery={query => {
                  dispatch(push({
                    pathname: generatePath(PAGE_URL.FIND, {
                      databaseTable: domainType.DatabaseTable ?? domainType.Name,
                      name: domainType.Name
                    }),
                    search: new URLSearchParams({
                      filterLinkOperator: query.FilterLinkOperator ?? 'and',
                      filters: JSON.stringify(query.Filters),
                      sorts: JSON.stringify(query.Sorts),
                      searchText: query.SearchText ?? '',
                      queryId: query.Id
                    }).toString()
                  }))
                }} />
            )
            : null}
        </CardContent>
        <CardActions
          sx={{ p: 0 }} />
      </Card>
    </StyledGrid>
  )
}
