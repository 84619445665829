import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { DomainType, Sort } from 'types'
import { getOverridableDomainTypeSetting } from 'utils/helpers'
import { useOverriders } from './useOverriders'

export function useDefaultSorts(domainType: DomainType): Sort[] {
  const domainTypes = useSelector(getAllDomainTypes)
  const overriders = useOverriders()
  const [defaultSort, defaultSortDirection] = useMemo((): [string, 'asc' | 'desc'] => {
    const defaultSort = getOverridableDomainTypeSetting(
      domainTypes,
      domainType,
      overriders,
      'DefaultSort',
      ''
    )
    const defaultSortDirection = getOverridableDomainTypeSetting(
      domainTypes,
      domainType,
      overriders,
      'DefaultSortDirection',
      'asc'
    )
    return [defaultSort, defaultSortDirection]
  }, [domainType, domainTypes, overriders])
  return useMemo((): Sort[] => {
    return defaultSort !== ''
      ? [
        {
          Property: defaultSort,
          Direction: defaultSortDirection
        }
      ]
      : []
  }, [defaultSort, defaultSortDirection])
}