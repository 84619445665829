import { Box, TooltipProps } from '@mui/material'
import { DomainTypeGridSummary } from 'components/domainType/DomainTypeSummary'
import { ComponentProps, useCallback, useEffect, useMemo } from 'react'
import { DomainType, DomainTypeInstance } from 'types'
import { TOOLTIP_DELAY } from 'utils/constants'
import { useEventBus } from 'utils/hooks'
import LightTooltip from '../utils/LightTooltip'

interface Props extends Omit<TooltipProps, 'title'> {
  domainType: DomainType,
  instance: DomainTypeInstance,
  children: ComponentProps<typeof LightTooltip>['children']
  enableHeadingLink?: boolean
  hoverEventSource?: string
}

export default function DomainTypeTooltip({
  domainType,
  instance,
  children,
  enableHeadingLink = false,
  hoverEventSource = 'DomainTypeTooltip',
  ...props
}: Props): JSX.Element | null {
  const tooltipTitle = useMemo(() => {
    return (
      <Box>
        <DomainTypeGridSummary
          domainType={domainType}
          instance={instance}
          columnsSetting='Summary'
          hideButtons
          enableHeadingLink={enableHeadingLink} />
      </Box>
    )
  }, [domainType, enableHeadingLink, instance])
  const eventBus = useEventBus()
  const hoverEnd = useCallback(() => {
    eventBus.dispatch({
      type: 'hoverEnd',
      domainType,
      instance,
      source: hoverEventSource
    })
  }, [domainType, eventBus, hoverEventSource, instance])
  useEffect(() => {
    if (props.open === false) {
      hoverEnd()
    }
  }, [hoverEnd, props.open])
  return (
    <LightTooltip
      {...props}
      enterDelay={TOOLTIP_DELAY}
      enterNextDelay={TOOLTIP_DELAY}
      minWidth='350px'
      maxWidth='350px'
      title={tooltipTitle}
      onOpen={event => {
        props.onOpen?.(event)
        eventBus.dispatch({
          type: 'hoverStart',
          domainType,
          instance,
          source: hoverEventSource
        })
      }}
      onClose={event => {
        props.onClose?.(event)
        hoverEnd()
      }}>
      {children}
    </LightTooltip>
  )
}