import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'

interface SnackbarMessage {
  readonly key: string
  readonly message: string
}

export interface SnackPack {
  open: boolean
  message: string | undefined
  addMessage(message: string, key?: string): void
  handleClose(event: SyntheticEvent | Event, reason?: string): void
  handleExited(): void
}

export function useSnackPack(): SnackPack {
  const [snackPack, setSnackPack] = useState<readonly SnackbarMessage[]>([])
  const [open, setOpen] = useState(false)
  const [messageInfo, setMessageInfo] = useState<SnackbarMessage | undefined>(undefined)
  useEffect(() => {
    const snack = snackPack[0]
    if (snack === undefined) {
      return
    }
    if (messageInfo === undefined) {
      setMessageInfo(snack)
      setSnackPack(prev => prev.slice(1))
      setOpen(true)
    } else if (open) {
      setOpen(false)
    }
  }, [messageInfo, open, snackPack])
  const addMessage = useCallback((message: string, key?: string) => {
    setSnackPack(prev => {
      if (prev.find(existing => existing.key === key)) {
        return prev
      }
      return [
        ...prev,
        {
          message,
          key: key ?? String(new Date().getTime())
        }
      ]
    })
  }, [])
  const handleClose = useCallback((event: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }, [])
  const handleExited = useCallback(() => {
    setMessageInfo(undefined)
  }, [])
  return useMemo(() => ({
    open,
    message: messageInfo?.message,
    addMessage,
    handleClose,
    handleExited
  }), [addMessage, handleClose, handleExited, messageInfo, open])
}