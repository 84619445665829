import { ContextRefAttributeValue } from 'types'
import { isListAttributeValue } from 'utils/helpers'
import { NarrowedProps } from '../AttributeInput'
import NotSupportedInput from '../NotSupportedInput'
import NonListContextRefInput from './NonListContextRefInput'
import ReadOnlyContextRefInput from './ReadOnlyContextRefInput'

export default function ContextRefInput({
  attributeValue,
  readOnly,
  disabled,
  pathError,
  textFieldProps,
  onChange
}: NarrowedProps<ContextRefAttributeValue>): JSX.Element | null {
  if (isListAttributeValue(attributeValue)) {
    return (
      <NotSupportedInput
        attributeValue={attributeValue}
        textFieldProps={textFieldProps} />
    )
  }
  if (readOnly) {
    return (
      <ReadOnlyContextRefInput
        attributeValue={attributeValue}
        pathError={pathError}
        textFieldProps={textFieldProps} />
    )
  }
  return (
    <NonListContextRefInput
      attributeValue={attributeValue}
      disabled={disabled}
      pathError={pathError}
      textFieldProps={textFieldProps}
      onChange={onChange} />
  )
}
