import { Grid, TextField, TextFieldProps } from '@mui/material'
import AttributeInput, { NotSupportedInput, ReadOnlyDomainTypeInput } from 'components/attribute/AttributeInput'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { DomainTypeAttributeValue, PathError } from 'types'
import { DomainTypeInstanceCodec } from 'utils/codecs'
import { DEFAULT_ATTRIBUTE_INPUT_SIZE, DEFAULT_ATTRIBUTE_INPUT_VARIANT } from 'utils/constants'
import { getEquipmentAttributes } from 'utils/context'
import { getAttributeValue, isListAttributeValue, toErrorText } from 'utils/helpers'

interface EquipmentProps {
  readonly attributeValue: DomainTypeAttributeValue
  readonly readOnly: boolean
  readonly disabled?: boolean
  readonly pathError?: PathError
  readonly textFieldProps?: Partial<TextFieldProps>,
  onChange?(attributeValue: DomainTypeAttributeValue): void
}

export default function EquipmentInput({
  attributeValue,
  readOnly,
  disabled = false,
  pathError,
  textFieldProps,
  onChange
}: EquipmentProps): JSX.Element | null {
  const domainTypes = useSelector(getAllDomainTypes)
  if (isListAttributeValue(attributeValue)) {
    return (
      <NotSupportedInput attributeValue={attributeValue} />
    )
  }
  const {
    equipmentTypeAttribute,
    serialisedEquipmentAttribute,
    quantityAttribute
  } = getEquipmentAttributes(domainTypes)
  const instance = attributeValue.value ?? {}
  if (readOnly) {
    return (
      <ReadOnlyDomainTypeInput
        attributeValue={attributeValue}
        pathError={pathError} />
    )
  }
  return (
    <Grid container>
      <Grid
        item
        xs={3}>
        <TextField
          label={attributeValue.attribute.Title}
          id={attributeValue.attribute.Name}
          variant={DEFAULT_ATTRIBUTE_INPUT_VARIANT}
          fullWidth
          size={DEFAULT_ATTRIBUTE_INPUT_SIZE}
          sx={theme => ({
            '& .MuiInputLabel-root': {
              color: theme.palette.text.primary
            },
            '& .MuiInputLabel-root.Mui-error': {
              color: theme.palette.error.main
            },
            '& .Mui-disabled:before': {
              borderBottomStyle: 'solid !important'
            }
          })}
          required={attributeValue.attribute.Required ?? false}
          error={pathError !== undefined}
          helperText={toErrorText(pathError)}
          {...textFieldProps}
          InputProps={{
            ...textFieldProps?.InputProps,
            readOnly: true,
            disabled: true
          }} />
      </Grid>
      <Grid
        item
        xs={3}>
        <AttributeInput
          attributeValue={getAttributeValue(instance, serialisedEquipmentAttribute)}
          readOnly={false}
          disabled={disabled || attributeValue.value?.IsSerialised === false}
          textFieldProps={textFieldProps}
          onChange={({ value }) => {
            if (onChange === undefined) {
              return
            }
            if (value === null) {
              onChange({
                attribute: attributeValue.attribute,
                value
              })
            } else {
              onChange({
                attribute: attributeValue.attribute,
                value: {
                  SerialisedEquipment: value,
                  EquipmentType: null,
                  IsSerialised: true,
                  Quantity: 1
                }
              })
            }
          }} />
      </Grid>
      <Grid
        item
        xs={3}>
        <AttributeInput
          attributeValue={getAttributeValue(instance, equipmentTypeAttribute)}
          readOnly={false}
          disabled={disabled || attributeValue.value?.IsSerialised === true}
          textFieldProps={textFieldProps}
          onChange={({ value }) => {
            if (onChange === undefined) {
              return
            }
            if (value === null) {
              onChange({
                attribute: attributeValue.attribute,
                value
              })
            } else {
              onChange({
                attribute: attributeValue.attribute,
                value: {
                  SerialisedEquipment: null,
                  EquipmentType: value,
                  IsSerialised: false,
                  Quantity: 1
                }
              })
            }
          }} />
      </Grid>
      <Grid
        item
        xs={3}>
        <AttributeInput
          attributeValue={getAttributeValue(instance, quantityAttribute)}
          readOnly={false}
          disabled={disabled
            || attributeValue.value?.IsSerialised !== false
            || !(
              DomainTypeInstanceCodec.is(attributeValue.value.EquipmentType)
              && attributeValue.value.EquipmentType.SerialisedType === false
            )
          }
          textFieldProps={textFieldProps}
          onChange={({ value }) => {
            if (onChange === undefined) {
              return
            }
            onChange({
              attribute: attributeValue.attribute,
              value: {
                ...attributeValue.value,
                Quantity: value
              }
            })
          }} />
      </Grid>
    </Grid>
  )
}