import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { Attribute, AttributeChainValue, DomainType, DomainTypeAttribute, NonListAttributeValue, Value } from 'types'
import { getIdentifierAttribute, renderAttributeTypeComponent } from 'utils/helpers'
import BoolCell from './BoolCell'
import ContextRefCell from './ContextRefCell'
import DataformResultsCell from './DataformResultsCell'
import DateCell from './DateCell'
import DateTimeCell from './DateTimeCell'
import DomainTypeCell from './DomainTypeCell'
import EnumCell from './EnumCell'
import GuidCell from './GuidCell'
import ListCell from './ListCell'
import MultiDataformResultsCell from './MultiDataformResultsCell'
import NumberCell from './NumberCell'
import RefCell from './RefCell'
import StringCell from './StringCell'

interface Props {
  readonly attributeChainValue: AttributeChainValue
  readonly columnWidth?: number
  readonly disableLink?: boolean
}
interface CellProps {
  readonly attributeValue: NonListAttributeValue
  readonly columnWidth?: number
  readonly disableLink?: boolean
}
export interface NarrowedProps<A extends NonListAttributeValue> extends CellProps {
  readonly attributeValue: A
}

const cellTypes = {
  date: DateCell,
  dateTime: DateTimeCell,
  number: NumberCell,
  guid: GuidCell,
  enum: EnumCell,
  domainType: DomainTypeCell,
  ref: RefCell,
  contextRef: ContextRefCell,
  bool: BoolCell,
  string: StringCell,
  dataformResults: DataformResultsCell,
  multiDataformResults: MultiDataformResultsCell
}

function createCell(props: CellProps): JSX.Element | null {
  const [isValid, component] = renderAttributeTypeComponent(cellTypes, props)
  if (isValid) {
    return component
  }
  return null
}

function getUniqueNotNull<A extends Attribute>(
  value: (Value<A> | null)[],
  attribute: A,
  domainTypes: Partial<Record<string, DomainType>>
): Value<A>[] {
  if (attribute.AttributeType !== 'domainType') {
    return value
      .filter((item, index) => value.indexOf(item) === index)
      .filter(item => item !== null)
      .map(item => item as Value<A>)
  }
  const domainType = domainTypes[attribute.AttributeDomainType]
  const identifierAttribute = getIdentifierAttribute(
    domainTypes,
    domainType
  )
  if (identifierAttribute === undefined) {
    return value
      .filter((item): item is Value<A> => item !== null)
  }
  return value
    .filter((item, index) => {
      return value
        .map(item => (item as Value<DomainTypeAttribute> | null)?.[identifierAttribute.Name])
        .indexOf((item as Value<DomainTypeAttribute> | null)?.[identifierAttribute.Name]) === index
    })
    .filter(item => item !== null)
    .map(item => item as Value<A>)
}

export default function AttributeCell({
  attributeChainValue,
  columnWidth,
  disableLink = false
}: Props): JSX.Element | null {
  const domainTypes = useSelector(getAllDomainTypes)
  return useMemo(() => {
    let attributeValue: NonListAttributeValue
    if (Array.isArray(attributeChainValue.value)) {
      const values = getUniqueNotNull(attributeChainValue.value, attributeChainValue.attribute, domainTypes)
      if (values.length === 1) {
        const value = values[0]
        if (value === undefined) {
          return null
        }
        attributeValue = {
          attribute: {
            ...attributeChainValue.attribute,
            List: false
          },
          value
        }
      } else {
        return (
          <ListCell
            attributeValue={{
              attribute: {
                ...attributeChainValue.attribute,
                List: true
              },
              value: values
            }}
            columnWidth={columnWidth} />
        )
      }
    } else {
      attributeValue = {
        attribute: attributeChainValue.attribute,
        value: attributeChainValue.value
      }
    }
    return createCell({
      attributeValue,
      columnWidth,
      disableLink
    })
  }, [attributeChainValue.attribute, attributeChainValue.value, columnWidth, disableLink, domainTypes])
}
