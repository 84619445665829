import { ROW_HEIGHT } from 'components/pages/FindPage'
import LightTooltip from 'components/utils/LightTooltip'
import { either as E } from 'fp-ts'
import * as t from 'io-ts'
import { useEffect, useState } from 'react'
import { blobToBase64 } from 'utils/helpers'
import { useApi, useDomainTypeContextInstance } from 'utils/hooks'
import { DomainTypeComponentProps } from '..'

export default function ImageHandleCell({
  attributeValue,
  columnWidth
}: DomainTypeComponentProps['cell']): JSX.Element | null {
  const work = useDomainTypeContextInstance('Work', 'Work', t.type({ Id: t.string }))
  const [image, setImage] = useState<string | null>(null)
  const api = useApi()
  useEffect(() => {
    const loadImage = async () => {
      if (!api.isSignedIn
        || work === null
        || attributeValue.value === null) {
        return
      }
      const response = await api.getFileBlob(
        'Work',
        work.Id,
        String(attributeValue.value.Name)
      )
      if (E.isLeft(response)) {
        setImage(null)
        return
      }
      const value = await blobToBase64(response.right, 'image/jpeg')
      if (value !== null) {
        setImage(value)
      } else {
        setImage(null)
      }
    }
    loadImage()
  }, [api, attributeValue.value, work])
  if (attributeValue.value === null) {
    return null
  }
  if (image === null) {
    return (
      <img
        alt='Not found'
        height={ROW_HEIGHT} />
    )
  }
  return (
    <LightTooltip
      maxWidth='400px'
      minWidth='400px'
      title={(
        <img
          src={image}
          alt=''
          width='100%' />
      )}>
      <img
        src={image}
        alt=''
        height={ROW_HEIGHT}
        style={{ cursor: 'pointer' }} />
    </LightTooltip>
  )
}