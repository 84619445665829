import { Autocomplete, Box, Dialog, DialogContent, DialogTitle, Stack, TextField } from '@mui/material'
import AppendDomainTypeContext from 'components/domainType/AppendDomainTypeContext'
import DomainTypeIcon from 'components/domainType/DomainTypeIcon'
import { FormEvent, useCallback, useMemo, useState } from 'react'
import { ButtonLocation, DomainType, DomainTypeAttribute, ListAttribute } from 'types'
import { FIND_DIALOG_KEY, OPERATIONS_DIALOG_KEY, PATH_SEPARATOR } from 'utils/constants'
import { Button, ButtonTarget, useButtons, useDomainTypeListAttributeButtons, useWindowEventListener } from 'utils/hooks'
import useMountEffect from 'utils/hooks/useMountEffect'
import DomainTypeButton from './DomainTypeButtons/DomainTypeButton'
import { ButtonProps } from './DomainTypeButtons/DomainTypeButtons'
import DomainTypeHeading from './DomainTypeHeading'

interface Props {
  readonly domainType: DomainType
  readonly target: ButtonTarget
  readonly on: ButtonLocation
}

function SelfClickingButton(props: ButtonProps): null {
  useMountEffect(() => {
    props.onClick()
  })
  return null
}

type OperationButton =
  | {
    type: 'domainType',
    button: Button
  }
  | {
    type: 'domainTypeListAttribute',
    attributes: ListAttribute<DomainTypeAttribute>[],
    button: Button
  }

function getOptionLabel(button: OperationButton): string {
  if (button.type === 'domainType') {
    return button.button.name
  }
  return button.attributes
    .map(attribute => attribute.Title)
    .concat([button.button.name])
    .join(PATH_SEPARATOR)
}

function getButtonIcon(button: OperationButton): string {
  if (button.button.type === 'action'
    || button.button.type === 'custom') {
    return button.button.icon
  }
  return {
    create: 'add',
    edit: 'edit',
    delete: 'delete'
  }[button.button.type]
}

export default function OperationsDialog({
  domainType,
  target,
  on
}: Props): JSX.Element {
  const [open, setOpen] = useState(false)
  const [button, setButton] = useState<OperationButton | null>(null)
  const onKeyDown = useCallback((event: KeyboardEvent) => {
    if (open || event.key !== OPERATIONS_DIALOG_KEY) {
      return
    }
    event.preventDefault()
    setButton(null)
    setOpen(true)
  }, [open])
  const instances = useMemo(() => {
    return target.type === 'instances'
      ? target.instances
      : []
  }, [target])
  const singleInstance = instances.length === 1
    ? instances[0]
    : undefined
  useWindowEventListener('keydown', onKeyDown)
  const domainTypeButtons = useButtons(domainType, target, on)
    .filter(button => !button.disabled)
  const domainTypeListAttributeButtons = useDomainTypeListAttributeButtons(domainType, singleInstance)
    .filter(({ button }) => !button.disabled)
  const operationButtons = domainTypeButtons
    .map((button): OperationButton => ({
      type: 'domainType',
      button
    }))
    .concat(domainTypeListAttributeButtons
      .map(({ attributes, button }): OperationButton => ({
        type: 'domainTypeListAttribute',
        attributes,
        button
      })))
  const total = useMemo(() => {
    if (target.type === 'none') {
      return 0
    }
    return target.type === 'instances'
      ? target.instances.length
      : target.total
  }, [target])
  return (
    <>
      {button && (
        <AppendDomainTypeContext
          newAttributes={button.type === 'domainTypeListAttribute'
            ? button.attributes
            : []}>
          <DomainTypeButton
            button={button.button}
            target={button.type === 'domainType'
              ? target
              : {
                type: 'instances',
                instances: []
              }}
            Component={SelfClickingButton} />
        </AppendDomainTypeContext>
      )}
      <Dialog
        fullWidth
        maxWidth='md'
        open={open}
        onClose={() => setOpen(false)}
        onKeyDown={event => {
          if (event.key === FIND_DIALOG_KEY) {
            setOpen(false)
          }
        }}>
        <DialogTitle>
          <DomainTypeHeading
            domainType={domainType}
            instance={singleInstance}
            isLoading={false}
            title='Operations:'
            plural={total > 1}
            count={total} />
        </DialogTitle>
        <DialogContent>
          <Box
            component='form'
            display='flex'
            flexDirection='column'
            gap={1}
            autoComplete='off'
            onSubmit={(event: FormEvent) => event.preventDefault()}>
            <Autocomplete
              sx={{ marginTop: 1 }}
              size='small'
              openOnFocus
              onClose={() => {
                setOpen(false)
                if (document.activeElement instanceof HTMLElement) {
                  document.activeElement.blur()
                }
                const autoFocusInput = document.querySelector('input[auto-focus="yes"]')
                if (autoFocusInput instanceof HTMLInputElement) {
                  autoFocusInput.focus()
                }
              }}
              autoHighlight
              renderOption={(props, button) => (
                <li {...props}>
                  <Stack
                    direction='row'
                    gap={1}
                    alignItems='center'>
                    <DomainTypeIcon
                      overrideIcon={getButtonIcon(button)}
                      overrideCornerIcon=''
                      domainType={button.button.domainType} />
                    {getOptionLabel(button)}
                  </Stack>
                </li>
              )}
              getOptionLabel={getOptionLabel}
              options={operationButtons}
              onChange={(event, button) => {
                setOpen(false)
                setButton(button)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputRef={node => open && node?.focus()}
                  label='Operation' />
              )} />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}