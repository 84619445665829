import { useEffect } from 'react'

/**
 * Runs an effect that does not run twice in strict mode.
 * In development, React simulates unmounting and remounting every component
 * which causes useEffect to run twice on first mount. They say this is to help you find bugs,
 * but in some cases it can be a worse bug to run the effect twice.
 * @param effect 
 * @param deps 
 */
export function useStrictModeEffect(
  effect: Parameters<typeof useEffect>[0],
  deps: Parameters<typeof useEffect>[1]
): ReturnType<typeof useEffect> {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(function effectThatDoesNotRunTwiceInStrictMode(this: { ignore?: boolean }) {
    if (this.ignore === undefined) {
      effect()
    }
    return () => {
      this.ignore = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }.bind({}), deps)
}