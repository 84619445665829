import { Box, BoxProps, Drawer, DrawerProps, drawerClasses, styled } from '@mui/material'
import DrawerDragHandle from 'components/utils/DrawerDragHandle'
import { MIN_SIDE_PANEL_WIDTH } from 'utils/constants'
import { useDrawerDragHandle } from 'utils/hooks'
import ScrollBar from './ScrollBar'

interface Props {
  children: JSX.Element | null
  drawerWidth: number | 'max'
  containerWidth: number
  setDrawerWidth: (width: number) => void
  open: boolean
  isFullScreen: boolean
  minParentWidth: number
}

export const defaultDrawerWidth = MIN_SIDE_PANEL_WIDTH
const minDrawerWidth = MIN_SIDE_PANEL_WIDTH

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: prop => prop !== 'drawerWidth' && prop !== 'containerWidth' && prop !== 'isFullScreen' && prop !== 'minParentWidth'
})<DrawerProps & { width: number | '100%', drawerWidth: number | 'max', containerWidth: number, isFullScreen: boolean, minParentWidth: number }>(({
  theme,
  open = false,
  width,
  drawerWidth,
  containerWidth,
  isFullScreen,
  minParentWidth
}) => ({
  width,
  minWidth: open
    ? MIN_SIDE_PANEL_WIDTH
    : 0,
  [`& .${drawerClasses.paper}`]: {
    overflow: 'hidden',
    position: 'relative',
    border: 'none'
  },
  flexShrink: 0,
  pointerEvents: open
    ? undefined
    : 'none',
  boxShadow: open && drawerWidth !== 'max' && containerWidth - drawerWidth < minParentWidth
    ? theme.shadows[3]
    : theme.shadows[0],
  zIndex: isFullScreen
    ? 0
    : theme.zIndex.drawer
}))

const Container = styled(Box, {
  shouldForwardProp: prop => prop !== 'isFullScreen'
})<BoxProps & { isFullScreen: boolean }>(({ isFullScreen }) => ({
  position: 'relative',
  paddingLeft: isFullScreen
    ? 0
    : '5px',
  height: '100%',
  width: '100%'
}))

export default function ResizableDrawer({
  drawerWidth,
  containerWidth,
  children,
  setDrawerWidth,
  open,
  isFullScreen,
  minParentWidth
}: Props): JSX.Element {
  const {
    isDragging,
    ref,
    onMouseDown
  } = useDrawerDragHandle(
    'left',
    minDrawerWidth,
    containerWidth,
    setDrawerWidth
  )
  const width = open
    ? drawerWidth === 'max'
      ? '100%'
      : drawerWidth
    : 0
  return (
    <StyledDrawer
      ref={ref}
      transitionDuration={0}
      width={width}
      drawerWidth={drawerWidth}
      containerWidth={containerWidth}
      isFullScreen={isFullScreen}
      minParentWidth={minParentWidth}
      variant='persistent'
      anchor='right'
      open={open}>
      {!isFullScreen && (
        <DrawerDragHandle
          placement='left'
          isDragging={isDragging}
          onMouseDown={onMouseDown} />
      )}
      <ScrollBar>
        <Container isFullScreen={isFullScreen}>
          {children}
        </Container>
      </ScrollBar>
    </StyledDrawer>
  )
}
