import { FullscreenExitOutlined, FullscreenOutlined } from '@mui/icons-material'
import { Box, Paper, Skeleton } from '@mui/material'
import TooltipIconButton from 'components/utils/TooltipIconButton'
import { DateTime } from 'luxon'
import { useSelector } from 'react-redux'
import { getCompany, getPerson, getPreferences } from 'state/reducers'
import { Company, Person } from 'types'
import { Dashboard, Panel as PanelType } from 'types/dashboard'

interface Props {
  readonly dashboard: Dashboard
  readonly panel: PanelType
  readonly startDate: DateTime
  readonly endDate: DateTime
  readonly isExpanded: boolean
  readonly variableValues: Partial<Record<string, string[]>>
  readonly isLoading: boolean
  onExpand(): void
  onCollapse(): void
}

const rootUrl = window.Config.ServerUrl

function getPanelUrl(
  dashboard: Dashboard,
  panel: PanelType,
  theme: 'dark' | 'light',
  startDate: DateTime,
  endDate: DateTime,
  person: Person | null,
  company: Company | null,
  variableValues: Partial<Record<string, string[]>>
): string {
  const searchParams = new URLSearchParams()
  searchParams.append('orgId', '1')
  searchParams.append('panelId', panel.Id)
  searchParams.append('theme', theme)
  searchParams.append('from', String(startDate.toUTC().toMillis()))
  searchParams.append('to', String(endDate.toUTC().toMillis()))
  searchParams.append('var-signedInPerson', person?.Id ?? '')
  searchParams.append('var-signedInCompany', company?.Id ?? '')
  for (const variable of dashboard.Variables ?? []) {
    for (const value of variableValues[variable.Name] ?? ['All']) {
      searchParams.append(`var-${variable.Name}`, value)
    }
  }
  return `${rootUrl}grafana/d-solo/${dashboard.Subcategory}/${dashboard.Value}?${searchParams.toString()}`
}

export default function Panel({
  dashboard,
  panel,
  startDate,
  endDate,
  isExpanded,
  variableValues,
  isLoading,
  onExpand,
  onCollapse
}: Props): JSX.Element {
  const { theme } = useSelector(getPreferences)
  const height = isExpanded ? 600 : 400
  const person = useSelector(getPerson)
  const company = useSelector(getCompany)
  return (
    <Paper
      sx={{
        '& iframe': {
          border: 0,
          m: '-1px',
          mb: '-7px',
          width: 'calc(100% + 2px)',
          height: height + 2
        },
        overflow: 'hidden',
        position: 'relative'
      }}>
      <Box
        position='absolute'
        top={0}
        right={0}>
        {isExpanded
          ? (
            <TooltipIconButton
              tooltipText='Collapse Panel'
              size='small'
              icon={<FullscreenExitOutlined />}
              disabled={isLoading}
              onClick={onCollapse} />
          )
          : (
            <TooltipIconButton
              tooltipText='Expand Panel'
              size='small'
              icon={<FullscreenOutlined />}
              disabled={isLoading}
              onClick={onExpand} />
          )}
      </Box>
      {isLoading
        ? (
          <Skeleton
            variant='rectangular'
            width='100%'
            height={height} />
        )
        : (
          <iframe
            title={`${dashboard.Value} - ${panel.Title}`}
            src={getPanelUrl(
              dashboard,
              panel,
              theme,
              startDate,
              endDate,
              person,
              company,
              variableValues
            )}>
          </iframe>
        )}
    </Paper>
  )
}