import * as t from 'io-ts'
import { AbsoluteDateFilterValue, AbsoluteDateRangeFilterValue, AbsoluteDateTimeFilterValue, AbsoluteDateTimeRangeFilterValue, CalendarRelativeDateRangeMeasure, DateFilterValue, DateRangeFilterValue, DateTimeFilterValue, DateTimeRangeFilterValue, LastCalendarRelativeDateRange, LastCalendarRelativeDateTimeRange, LastRelativeDateRange, LastRelativeDateTimeRange, NextCalendarRelativeDateRange, NextCalendarRelativeDateTimeRange, NextRelativeDateRange, NextRelativeDateTimeRange, RelativeDateFilterValue, RelativeDateRange, RelativeDateRangeFilterValue, RelativeDateRangeMeasure, RelativeDateTimeFilterValue, RelativeDateTimeRange, RelativeDateTimeRangeFilterValue, RelativeDateTimeRangeMeasure, ThisRelativeDateRange, ThisRelativeDateTimeRange } from './types'

export const AbsoluteDateFilterValueCodec: t.Type<AbsoluteDateFilterValue> = t.type({
  Type: t.literal('AbsoluteDateFilterValue'),
  Value: t.string
})

export const RelativeDateFilterValueCodec: t.Type<RelativeDateFilterValue> = t.type({
  Type: t.literal('RelativeDateFilterValue'),
  Value: t.literal('TodayRelativeDateFilterValueValue')
})

export const DateFilterValueCodec: t.Type<DateFilterValue> = t.union([
  AbsoluteDateFilterValueCodec,
  RelativeDateFilterValueCodec
])

export const AbsoluteDateTimeFilterValueCodec: t.Type<AbsoluteDateTimeFilterValue> = t.type({
  Type: t.literal('AbsoluteDateTimeFilterValue'),
  Value: t.string
})

export const RelativeDateTimeFilterValueCodec: t.Type<RelativeDateTimeFilterValue> = t.type({
  Type: t.literal('RelativeDateTimeFilterValue'),
  Value: t.literal('NowRelativeDateTimeFilterValueValue')
})

export const DateTimeFilterValueCodec: t.Type<DateTimeFilterValue> = t.union([
  AbsoluteDateTimeFilterValueCodec,
  RelativeDateTimeFilterValueCodec
])

export const RelativeDateRangeMeasureCodec: t.Type<RelativeDateRangeMeasure> = t.union([
  t.literal('days'),
  t.literal('weeks'),
  t.literal('months'),
  t.literal('years')
])

export const CalendarRelativeDateRangeMeasureCodec: t.Type<CalendarRelativeDateRangeMeasure> = t.union([
  t.literal('weeks'),
  t.literal('months'),
  t.literal('years')
])

export const NextRelativeDateRangeCodec: t.Type<NextRelativeDateRange> = t.type({
  Type: t.literal('NextRelativeDateRange'),
  Units: t.number,
  Measure: RelativeDateRangeMeasureCodec,
  IncludeToday: t.boolean
})

export const NextCalendarRelativeDateRangeCodec: t.Type<NextCalendarRelativeDateRange> = t.type({
  Type: t.literal('NextCalendarRelativeDateRange'),
  Units: t.number,
  Measure: CalendarRelativeDateRangeMeasureCodec,
  IncludeCurrent: t.boolean
})

export const LastRelativeDateRangeCodec: t.Type<LastRelativeDateRange> = t.type({
  Type: t.literal('LastRelativeDateRange'),
  Units: t.number,
  Measure: RelativeDateRangeMeasureCodec,
  IncludeToday: t.boolean
})

export const LastCalendarRelativeDateRangeCodec: t.Type<LastCalendarRelativeDateRange> = t.type({
  Type: t.literal('LastCalendarRelativeDateRange'),
  Units: t.number,
  Measure: CalendarRelativeDateRangeMeasureCodec,
  IncludeCurrent: t.boolean
})

export const ThisRelativeDateRangeCodec: t.Type<ThisRelativeDateRange> = t.type({
  Type: t.literal('ThisRelativeDateRange'),
  Measure: t.union([
    t.literal('week'),
    t.literal('month'),
    t.literal('year')
  ])
})

export const RelativeDateRangeCodec: t.Type<RelativeDateRange> = t.union([
  NextRelativeDateRangeCodec,
  NextCalendarRelativeDateRangeCodec,
  LastRelativeDateRangeCodec,
  LastCalendarRelativeDateRangeCodec,
  ThisRelativeDateRangeCodec
])

export const AbsoluteDateRangeFilterValueCodec: t.Type<AbsoluteDateRangeFilterValue> = t.type({
  Type: t.literal('AbsoluteDateRangeFilterValue'),
  From: t.string,
  To: t.string
})

export const RelativeDateRangeFilterValueCodec: t.Type<RelativeDateRangeFilterValue> = t.type({
  Type: t.literal('RelativeDateRangeFilterValue'),
  Range: RelativeDateRangeCodec
})

export const DateRangeFilterValueCodec: t.Type<DateRangeFilterValue> = t.union([
  AbsoluteDateRangeFilterValueCodec,
  RelativeDateRangeFilterValueCodec
])

export const RelativeDateTimeRangeMeasureCodec: t.Type<RelativeDateTimeRangeMeasure> = t.union([
  t.literal('minutes'),
  t.literal('hours'),
  t.literal('days'),
  t.literal('weeks'),
  t.literal('months'),
  t.literal('years')
])

export const NextRelativeDateTimeRangeCodec: t.Type<NextRelativeDateTimeRange> = t.type({
  Type: t.literal('NextRelativeDateTimeRange'),
  Units: t.number,
  Measure: RelativeDateTimeRangeMeasureCodec
})

export const NextCalendarRelativeDateTimeRangeCodec: t.Type<NextCalendarRelativeDateTimeRange> = t.type({
  Type: t.literal('NextCalendarRelativeDateTimeRange'),
  Units: t.number,
  Measure: RelativeDateTimeRangeMeasureCodec,
  IncludeCurrent: t.boolean
})

export const LastRelativeDateTimeRangeCodec: t.Type<LastRelativeDateTimeRange> = t.type({
  Type: t.literal('LastRelativeDateTimeRange'),
  Units: t.number,
  Measure: RelativeDateTimeRangeMeasureCodec
})

export const LastCalendarRelativeDateTimeRangeCodec: t.Type<LastCalendarRelativeDateTimeRange> = t.type({
  Type: t.literal('LastCalendarRelativeDateTimeRange'),
  Units: t.number,
  Measure: RelativeDateTimeRangeMeasureCodec,
  IncludeCurrent: t.boolean
})

export const ThisRelativeDateTimeRangeCodec: t.Type<ThisRelativeDateTimeRange> = t.type({
  Type: t.literal('ThisRelativeDateTimeRange'),
  Measure: t.union([
    t.literal('minute'),
    t.literal('hour'),
    t.literal('day'),
    t.literal('week'),
    t.literal('month'),
    t.literal('year')
  ])
})

export const RelativeDateTimeRangeCodec: t.Type<RelativeDateTimeRange> = t.union([
  NextRelativeDateTimeRangeCodec,
  NextCalendarRelativeDateTimeRangeCodec,
  LastRelativeDateTimeRangeCodec,
  LastCalendarRelativeDateTimeRangeCodec,
  ThisRelativeDateTimeRangeCodec
])

export const AbsoluteDateTimeRangeFilterValueCodec: t.Type<AbsoluteDateTimeRangeFilterValue> = t.type({
  Type: t.literal('AbsoluteDateTimeRangeFilterValue'),
  From: t.string,
  To: t.string
})

export const RelativeDateTimeRangeFilterValueCodec: t.Type<RelativeDateTimeRangeFilterValue> = t.type({
  Type: t.literal('RelativeDateTimeRangeFilterValue'),
  Range: RelativeDateTimeRangeCodec
})

export const DateTimeRangeFilterValueCodec: t.Type<DateTimeRangeFilterValue> = t.union([
  AbsoluteDateTimeRangeFilterValueCodec,
  RelativeDateTimeRangeFilterValueCodec
])