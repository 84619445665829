import * as E from 'fp-ts/Either'
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'
import { JsonFromString } from 'io-ts-types'
import { Action, CLEAR_JOB, CLEAR_PENDING_INTEREST_IN_JOB, CONFIRM_INTEREST_IN_JOBS, Job, JobsState, REGISTER_PENDING_INTEREST_IN_JOB, UPDATE_JOB_STATUS } from 'types'
import { JobsStateCodec } from 'utils/codecs'

const getStateFromLocalStorage = (): JobsState => {
  return pipe(
    window.localStorage.getItem('jobs'),
    E.fromNullable([]),
    E.chain(JsonFromString.pipe(t.any).pipe(JobsStateCodec).decode),
    E.match(
      () => ({
        pendingInterest: [],
        jobs: []
      }),
      state => state
    )
  )
}

function reducer(state: JobsState, action: Action): JobsState {
  switch (action.type) {
    case REGISTER_PENDING_INTEREST_IN_JOB:
      if (state.pendingInterest
        .find(job => job.jobId === action.payload.jobId)) {
        return state
      }
      if (state.jobs
        .find(job => job.jobId === action.payload.jobId)) {
        return state
      }
      return {
        ...state,
        pendingInterest: state.pendingInterest.concat([action.payload])
      }
    case CLEAR_PENDING_INTEREST_IN_JOB:
      return {
        ...state,
        pendingInterest: state.pendingInterest
          .filter(job => job.jobId !== action.payload.jobId)
      }
    case CONFIRM_INTEREST_IN_JOBS:
      return {
        pendingInterest: [],
        jobs: state.jobs.concat(state.pendingInterest)
      }
    case UPDATE_JOB_STATUS: {
      const index = state.jobs
        .findIndex(job => job.jobId === action.payload.jobStatus.Id)
      const job = state.jobs[index]
      if (job === undefined) {
        return state
      }
      return {
        ...state,
        jobs: state.jobs.slice(0, index)
          .concat([
            {
              ...job,
              jobStatus: action.payload.jobStatus
            }
          ])
          .concat(state.jobs.slice(index + 1))
      }
    }
    case CLEAR_JOB: {
      return {
        ...state,
        jobs: state.jobs.filter(job => job.jobId !== action.payload.jobId)
      }
    }
    default:
      return state
  }
}

export default function localStorageReducer(
  state: JobsState = getStateFromLocalStorage(),
  action: Action
): JobsState {
  const nextState = reducer(state, action)
  if (nextState !== state) {
    window.localStorage.setItem('jobs', JSON.stringify(nextState))
  }
  return nextState
}

export function getJobs(state: JobsState): Job[] {
  return state.jobs
}
