import { HelpOutlineOutlined } from '@mui/icons-material'
import { Box, Stack, Tooltip } from '@mui/material'
import { PropsWithChildren } from 'react'
import { useSelector } from 'react-redux'
import { getPreferences } from 'state/reducers'
import { Attribute, DomainType } from 'types'
import { isNullOrUndefined } from 'utils/helpers'

interface AttributeHelpProps {
  readonly type: 'attribute'
  readonly attribute: Attribute
}

interface DomainTypeHelpProps {
  readonly type: 'domainType'
  readonly domainType: DomainType
}

type Props = AttributeHelpProps | DomainTypeHelpProps

export default function Help(props: PropsWithChildren<Props>): JSX.Element {
  const {
    help
  } = useSelector(getPreferences)
  if (!help) {
    return <>{props.children}</>
  }
  const helpText = props.type === 'attribute'
    ? props.attribute.Description
    : props.domainType.Description
  if (isNullOrUndefined(helpText)
    || (props.type === 'attribute' && helpText === props.attribute.Title)) {
    return <>{props.children}</>
  }
  return (
    <Stack
      direction='row'
      gap={1}
      alignItems='center'>
      <Box flexGrow={1}>
        {props.children}
      </Box>
      <Tooltip title={helpText}>
        <HelpOutlineOutlined
          sx={{ cursor: 'pointer' }}
          color='info'
          fontSize='small' />
      </Tooltip>
    </Stack>
  )
}