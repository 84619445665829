import { combineReducers, Reducer } from 'redux'
import { Action, PreferencesState } from 'types'
import help from './help'
import openMenuSections from './openMenuSections'
import sideMenuPinned from './sideMenuPinned'
import theme from './theme'

const reducer: Reducer<PreferencesState, Action> = combineReducers({
  theme,
  sideMenuPinned,
  openMenuSections,
  help
})

export default reducer

export function getPreferences(state: PreferencesState): PreferencesState {
  return state
}
