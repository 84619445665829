import AvatarCornerIcon from 'components/utils/AvatarCornerIcon'
import CornerIcon from 'components/utils/CornerIcon'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { DomainType } from 'types'
import { getDomainTypeSetting } from 'utils/helpers'
import { useDomainTypeColour } from 'utils/hooks'

interface DomainTypeIconProps {
  readonly domainType: DomainType
  readonly defaultIcon?: string
  readonly overrideIcon?: string
  readonly overrideCornerIcon?: string
  readonly avatar?: boolean
  readonly filled?: boolean
  readonly avatarScale?: number
}

export default function DomainTypeIcon({
  domainType,
  defaultIcon,
  overrideIcon,
  overrideCornerIcon,
  avatar = false,
  filled = false,
  avatarScale = 1
}: DomainTypeIconProps): JSX.Element | null {
  const domainTypes = useSelector(getAllDomainTypes)
  const icon = overrideIcon ?? getDomainTypeSetting(domainTypes, domainType, 'Icon')
  const cornerIcon = overrideCornerIcon ?? getDomainTypeSetting(domainTypes, domainType, 'CornerIcon')
  const color = useDomainTypeColour(domainType)
  if (icon === null || icon === undefined) {
    if (defaultIcon === undefined) {
      return null
    }
    if (avatar) {
      return (
        <AvatarCornerIcon
          background={color}
          filled={filled}
          icon={defaultIcon}
          avatarScale={avatarScale} />
      )
    }
    return (
      <CornerIcon
        color={color}
        icon={defaultIcon} />
    )
  }
  if (avatar) {
    return (
      <AvatarCornerIcon
        background={color}
        filled={filled}
        icon={icon}
        cornerIcon={cornerIcon}
        textShadowColor={color}
        avatarScale={avatarScale} />
    )
  }
  return (
    <CornerIcon
      color={color}
      icon={icon}
      cornerIcon={cornerIcon} />
  )
}
