import { alpha, AutocompleteRenderInputParams, darken, getContrastRatio, lighten, Theme } from '@mui/material'
import { DomainTypeComponentOverrides } from 'components/overrides'
import { none, some } from 'fp-ts/lib/Option'
import * as t from 'io-ts'
import { DateTime } from 'luxon'
import { Optional } from 'monocle-ts'
import { ContextType } from 'react'
import {
  ApiError,
  Attribute,
  AttributeType,
  AttributeValue,
  AttributeValues,
  ChainValue,
  Company,
  ContextDomainTypeNode,
  ContextTree,
  DataformResultsAttribute,
  DateAttribute,
  DateTimeAttribute,
  DomainType,
  DomainTypeAction,
  DomainTypeAttribute,
  DomainTypeButton,
  DomainTypeInstance,
  DomainTypeListSettings,
  DomainTypeOverride,
  DomainTypeOverrider,
  DomainTypeSettings,
  EnumAttribute,
  EnumeratedType,
  EnumeratedValue,
  GlobalDomainTypeSettings,
  ListAttribute,
  ListAttributeValue,
  MultiDataformResultsAttribute,
  NonListAttribute,
  NonListAttributeValue,
  OverridableDomainTypeSettings,
  PathError,
  PathErrors,
  RefAttribute,
  StringAttribute,
  User,
  Value,
  ValueTypes
} from 'types'
import { Results } from 'types/dataform'
import {
  AttributeCodec,
  AttributeTypeCodecs, DomainTypeInstanceCodec,
  DomainTypeOverriderCodec,
  EditableNotOverridableDomainTypeSettingsCodec,
  GlobalDomainTypeSettingsCodec,
  OverridableDomainTypeSettingsCodec,
  OverridableNotGlobalDomainTypeSettingsCodec
} from 'utils/codecs'
import { CELL_AVATAR_BACKGROUND_ALPHA, FORMS_AUTHENTICATION_COOKIE_NAME, PATH_SEPARATOR, SYSTEM_COMPANY_ID } from 'utils/constants'
import { DomainTypeComponentOverrideContext, DomainTypeContext, DomainTypeSettingsContext, getSettingValue, SettingsContext } from 'utils/context'
import { OverriderDetails } from 'utils/context/DomainTypeOverriderContext'
import { CONTEXT_PREFIX, FilterContext } from 'utils/filters'
import { isDisabled } from 'utils/hooks'

export function getDomainTypeAttributes(
  domainTypes: Partial<Record<string, DomainType>>,
  nullableDomainType: DomainType | null | undefined
): Attribute[] {
  if (isNullOrUndefined(nullableDomainType)) {
    return []
  }
  let domainType = nullableDomainType
  const attributes = [...domainType.Attributes]
  while (domainType.Parent !== undefined && domainType.Parent !== null) {
    const parent = domainTypes[domainType.Parent]
    if (parent === undefined) {
      return attributes
    }
    domainType = parent
    const newAttributes = []
    for (const parentAttribute of domainType.Attributes) {
      const existingIndex = attributes.findIndex(attribute => attribute.Name === parentAttribute.Name)
      const overriden = existingIndex !== -1
      if (overriden) {
        const [existing] = attributes.splice(existingIndex, 1)
        if (existing !== undefined) {
          newAttributes.push(existing)
        }
      } else {
        newAttributes.push(parentAttribute)
      }
    }
    attributes.unshift(...newAttributes)
  }

  return attributes
}

export function getDomainTypeAttribute(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType,
  name: string
): Attribute | undefined {
  const attributeChain = getAttributeChain(domainTypes, domainType, name)
  return attributeChain?.[attributeChain.length - 1]
}

export function getDomainTypeSetting<Setting extends keyof DomainTypeSettings>(
  domainTypes: Partial<Record<string, DomainType>>,
  nullableDomainType: DomainType | null | undefined,
  setting: Setting
): DomainTypeSettings[Setting] | null {
  if (isNullOrUndefined(nullableDomainType)) {
    return null
  }

  let domainType = nullableDomainType

  while ((domainType[setting] === undefined || domainType[setting] === null)
    && domainType.Parent !== undefined
    && domainType.Parent !== null) {
    const parent = domainTypes[domainType.Parent]
    if (parent === undefined) {
      return null
    }
    domainType = parent
  }

  return domainType[setting]
}

export function mergeHierarchicalDomainTypeListSetting<Setting extends keyof DomainTypeListSettings>(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType,
  setting: Setting,
  overrideFromParent?: boolean | undefined
): DomainTypeListSettings[Setting] {
  let settingsValue = domainType[setting]

  const domainTypeDomainType = getDomainType(domainTypes, 'DomainType', 'DomainType')

  if (isNullOrUndefined(domainTypeDomainType)) {
    return []
  }

  const settingsItemAttribute = getDomainTypeAttribute(
    domainTypes,
    domainTypeDomainType,
    setting)

  const settingsItemDomainType = settingsItemAttribute?.AttributeType === 'domainType'
    ? domainTypes[settingsItemAttribute.AttributeDomainType]
    : undefined

  const results = Array.isArray(settingsValue)
    ? [...settingsValue]
    : []

  while (!isNullOrUndefined(domainType.Parent)) {
    const parentDomainType = domainTypes[domainType.Parent]
    if (parentDomainType === undefined) {
      return null
    }

    settingsValue = parentDomainType[setting]

    if (Array.isArray(settingsValue)) {
      settingsValue.forEach(parentSettingsItem => {
        const existingIndex = results.findIndex((settingItem) => {
          if (typeof parentSettingsItem === 'string') {
            return settingItem === parentSettingsItem
          }

          if (settingsItemDomainType === undefined) {
            return settingItem === parentSettingsItem
          }

          if (!DomainTypeInstanceCodec.is(parentSettingsItem)) {
            return false
          }

          const identifier = getIdentifier(domainTypes, settingsItemDomainType)
          const existingSettingsId = String(settingItem[identifier])
          const parentSettingsId = String(parentSettingsItem[identifier])
          return existingSettingsId === parentSettingsId
        })

        const existsAlready = existingIndex !== -1

        if (existsAlready) {
          if (overrideFromParent === true) {
            results[existingIndex] = parentSettingsItem
          }
        } else {
          results.push(parentSettingsItem)
        }
      })
    }

    domainType = parentDomainType
  }

  return results
}

function findDomainTypeOverride(
  overrider: DomainTypeOverrider,
  domainType: DomainType
): DomainTypeOverride | undefined {
  return (overrider.DomainTypeOverrides ?? []).find(o => o.Id === domainType.Id)
}

export function isNullOrUndefined(value: unknown): value is null | undefined {
  return value === null || value === undefined
}
export function isNotNullOrUndefined<T>(value: null | undefined | T): value is T {
  return value !== null && value !== undefined
}

export function getOverridableDomainTypeSettingItem<Setting extends keyof OverridableDomainTypeSettings, Item>(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType,
  overriders: DomainTypeOverrider[],
  setting: Setting,
  selector: (value: OverridableDomainTypeSettings[Setting]) => Item | null
): Item | null {
  let overrides = overriders
    .map(overrider => findDomainTypeOverride(overrider, domainType))
  while (overrides.every(override => isNullOrUndefined(selector(override?.[setting])))
    && isNullOrUndefined(selector(domainType[setting]))
    && !isNullOrUndefined(domainType.Parent)) {
    const parent = domainTypes[domainType.Parent]
    if (parent === undefined) {
      return null
    }
    domainType = parent
    overrides = overriders
      // eslint-disable-next-line no-loop-func
      .map(overrider => findDomainTypeOverride(overrider, domainType))
  }
  const overrideValue = overrides
    .slice()
    .reverse()
    .map(override => selector(override?.[setting]))
    .find(value => !isNullOrUndefined(value))
  if (!isNullOrUndefined(overrideValue)) {
    return overrideValue
  }
  if (isGlobalSetting(setting)) {
    const globalValue = overriders
      .slice()
      .reverse()
      .map(overrider => selector(overrider.GlobalDomainTypeSettings?.[setting]))
      .find(value => !isNullOrUndefined(value))
    if (!isNullOrUndefined(globalValue)) {
      return globalValue
    }
  }
  return selector(domainType[setting])
}

export function getOverridableDomainTypeSetting<Setting extends keyof OverridableDomainTypeSettings>(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType,
  overriders: DomainTypeOverrider[],
  setting: Setting,
  defaultValue: NonNullable<OverridableDomainTypeSettings[Setting]>
): NonNullable<OverridableDomainTypeSettings[Setting]> {
  return getOverridableDomainTypeSettingItem<Setting, OverridableDomainTypeSettings[Setting]>(
    domainTypes,
    domainType,
    overriders,
    setting,
    value => value
  ) ?? defaultValue
}

export function getOverriddenDomainTypeSetting<Setting extends keyof OverridableDomainTypeSettings>(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType,
  overriders: DomainTypeOverrider[],
  setting: Setting
): DomainTypeSettings[Setting] | null {
  let overrides = overriders
    .map(overrider => findDomainTypeOverride(overrider, domainType))
  while (overrides
    .every(override => (override === undefined || override[setting] === undefined || override[setting] === null))
    && domainType.Parent !== undefined
    && domainType.Parent !== null) {
    const parent = domainTypes[domainType.Parent]
    if (parent === undefined) {
      return null
    }
    domainType = parent
    overrides = overriders
      // eslint-disable-next-line no-loop-func
      .map(overrider => findDomainTypeOverride(overrider, domainType))
  }
  const overrideValue = overrides
    .slice()
    .reverse()
    .map(override => override?.[setting])
    .find(value => !isNullOrUndefined(value))
  if (!isNullOrUndefined(overrideValue)) {
    return overrideValue
  }
  if (isGlobalSetting(setting)) {
    const globalValue = overriders
      .slice()
      .reverse()
      .map(overrider => overrider.GlobalDomainTypeSettings?.[setting])
      .find(value => !isNullOrUndefined(value))
    if (!isNullOrUndefined(globalValue)) {
      return globalValue
    }
  }
  return null
}

export function getDomainTypeSettingAttribute<Setting extends keyof DomainTypeSettings>(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType,
  setting: Setting
): Attribute | null {
  const attributeName = getDomainTypeSetting(domainTypes, domainType, setting)
  return getDomainTypeAttributes(domainTypes, domainType)
    .find(attribute => attribute.Name === attributeName) ?? null
}

export function getOverridableDomainTypeSettingAttribute<Setting extends keyof OverridableDomainTypeSettings>(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType,
  overriders: DomainTypeOverrider[],
  setting: Setting
): Attribute | null {
  const attributeName = getOverridableDomainTypeSettingItem<Setting, OverridableDomainTypeSettings[Setting]>(
    domainTypes,
    domainType,
    overriders,
    setting,
    value => value
  )
  return getDomainTypeAttributes(domainTypes, domainType)
    .find(attribute => attribute.Name === attributeName) ?? null
}

export function getDomainTypeSettingAttributeValue<Setting extends keyof DomainTypeSettings>(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType,
  setting: Setting,
  instance: DomainTypeInstance
): AttributeValue['value'] {
  const attribute = getDomainTypeSettingAttribute(domainTypes, domainType, setting)
  return attribute !== null
    ? getAttributeValue(instance, attribute).value
    : null
}

export function getRootDomainType(
  domainTypes: Partial<Record<string, DomainType>>,
  nullableDomainType: DomainType | undefined
): DomainType | null {
  if (nullableDomainType === undefined) {
    return null
  }

  let domainType = nullableDomainType

  while (domainType.Parent !== undefined && domainType.Parent !== null) {
    const parent = domainTypes[domainType.Parent]
    if (parent === undefined) {
      return null
    }
    domainType = parent
  }

  return domainType
}

export function getParentDomainTypes(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType
): DomainType[] {
  const parents = [domainType]
  while (domainType.Parent !== undefined && domainType.Parent !== null) {
    const parent = domainTypes[domainType.Parent]
    if (parent === undefined) {
      return parents
    }
    domainType = parent
    parents.push(domainType)
  }

  return parents
}

function getSubtypes(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType
): DomainType[] {
  return [
    domainType
  ].concat(Object.values(domainTypes)
    .filter(type => type?.Parent === domainType.Id)
    .map(type => type as DomainType)
    .flatMap(type => getSubtypes(domainTypes, type)))
}

function getValueCodec<A extends Attribute>(attribute: ListAttribute<A>): t.Type<Value<A>[]>
function getValueCodec<A extends Attribute>(attribute: NonListAttribute<A>): t.Type<Value<A>>
function getValueCodec<A extends Attribute>(attribute: A): t.Type<Value<A>[]> | t.Type<Value<A>>
function getValueCodec<A extends Attribute>(attribute: A): t.Type<Value<A>[]> | t.Type<Value<A>> {
  const codec = AttributeTypeCodecs[attribute.AttributeType as A['AttributeType']]
  if (attribute.List ?? false) {
    return t.array(codec) as unknown as t.Type<Value<A>[]>
  } else {
    return codec as unknown as t.Type<Value<A>>
  }
}

export function getValue<A extends Attribute>(
  from: DomainTypeInstance,
  attribute: ListAttribute<A>
): Value<A>[] | null
export function getValue<A extends Attribute>(
  from: DomainTypeInstance,
  attribute: NonListAttribute<A>
): Value<A> | null
export function getValue<A extends Attribute>(
  from: DomainTypeInstance,
  attribute: A
): Value<A> | Value<A>[] | null
export function getValue<A extends Attribute>(
  from: DomainTypeInstance,
  attribute: A
): Value<A>[] | Value<A> | null {
  const codec = getValueCodec<A>(attribute)
  const value = from[attribute.Name]
  return codec.is(value)
    ? value
    : null
}

export function isListAttribute(attribute?: Attribute | null): attribute is ListAttribute {
  return attribute?.List === true
}

export function isNonListAttribute(attribute?: Attribute | null): attribute is NonListAttribute {
  return attribute?.List !== true
}

export function getAttributeValue<A extends Attribute>(
  from: DomainTypeInstance,
  attribute: A
): AttributeValue<A> {
  const value = from[attribute.Name]
  if (isListAttribute(attribute)) {
    const codec = getValueCodec<Attribute>(attribute)
    return {
      attribute,
      value: codec.is(value) ? value : null
    } as AttributeValue<A>
  }
  const codec = getValueCodec<Attribute>(attribute)
  return {
    attribute,
    value: codec.is(value) ? value : null
  } as AttributeValue<A>
}

export function getAttributeValues(
  from: DomainTypeInstance,
  attributes: Attribute[]
): AttributeValue[] {
  const values = [] as AttributeValue[]
  for (const attribute of attributes) {
    values.push(getAttributeValue(from, attribute))
  }
  return values
}

export function getChainValue(from: DomainTypeInstance, attributeChain: Attribute[]): ChainValue {
  return attributeChain.reduce<ChainValue>(
    (value, attribute) => {
      if (t.array(DomainTypeInstanceCodec).is(value)) {
        return value.flatMap<Value | null>(item => getValue(item, attribute))
      } else if (DomainTypeInstanceCodec.is(value)) {
        return getValue(value, attribute)
      } else {
        return null
      }
    },
    from
  )
}

export function getAttributeChain(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType,
  name: string | null | undefined
): Attribute[] | undefined {
  if (isNullOrUndefined(name)) {
    return undefined
  }
  const parts = name.split('_')
  const attributeChain: Attribute[] = []
  let attributeDomainType: DomainType | undefined = domainType
  for (const part of parts) {
    if (attributeDomainType === undefined) {
      return undefined
    }
    const attribute: Attribute | undefined = getDomainTypeAttributes(domainTypes, attributeDomainType)
      .find(a => a.Name === part)
    if (attribute === undefined) {
      return undefined
    }
    attributeChain.push(attribute)
    attributeDomainType = attribute.AttributeType === 'domainType'
      ? domainTypes[attribute.AttributeDomainType]
      : undefined
  }
  return attributeChain
}

export function getAttributeChainValues(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType,
  from: DomainTypeInstance,
  name: string
): AttributeValues | undefined {
  const attributeChain = getAttributeChain(domainTypes, domainType, name)
  if (attributeChain === undefined) {
    return undefined
  }
  const values = attributeChain.reduce<AttributeValues['values']>(
    (value, attribute) => {
      if (t.array(t.array(DomainTypeInstanceCodec)).is(value)) {
        return value.flat().map(item => getValue(item, attribute)) as AttributeValues['values']
      } else if (t.array(DomainTypeInstanceCodec).is(value)) {
        return value.map(item => getValue(item, attribute)) as AttributeValues['values']
      } else if (DomainTypeInstanceCodec.is(value)) {
        return [getValue(value, attribute)] as AttributeValues['values']
      } else {
        return []
      }
    },
    [from]
  )
  return {
    attribute: attributeChain[attributeChain.length - 1],
    values
  } as AttributeValues
}

export function getDomainType(
  domainTypes: Partial<Record<string, DomainType>>,
  name: string,
  databaseTable: string | null
): DomainType | null {
  return Object.values(domainTypes)
    .filter((type: DomainType | undefined): type is DomainType => type !== undefined)
    .find(domainType => domainType.Name === name && (domainType.DatabaseTable ?? null) === (databaseTable ?? null)) ?? null
}

export function hasApi(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType | null | undefined
): domainType is DomainType {
  return getDomainTypeSetting(
    domainTypes,
    domainType,
    'Api'
  ) ?? false
}

export function getIdentifier(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType | null | undefined
): string {
  return getDomainTypeSetting(
    domainTypes,
    domainType,
    'Identifier'
  ) ?? 'Id'
}

export function getId(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType | null | undefined,
  instance: DomainTypeInstance
): string {
  const identifier = getIdentifier(domainTypes, domainType)
  return String(instance[identifier])
}

export function getUniqueId(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType | null | undefined,
  instance: DomainTypeInstance
): string {
  return `${domainType?.DatabaseTable}-${getId(domainTypes, domainType, instance)}`
}

export function getIdentifierAttribute(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType | null | undefined
): Attribute | undefined {
  if (isNullOrUndefined(domainType)) {
    return undefined
  }
  const identifier = getIdentifier(domainTypes, domainType)
  return getDomainTypeAttribute(domainTypes, domainType, identifier)
}

export function getHeadingValueFromDomainType(
  settingsContext: ContextType<typeof DomainTypeSettingsContext>,
  domainTypeInstance: DomainTypeInstance | null,
  domainTypes: Partial<Record<string, DomainType>>,
  domainTypeId?: string
): string {
  if (domainTypeId === undefined) {
    return 'Heading'
  }
  const domainType = domainTypes[domainTypeId]
  if (!domainType) {
    return 'Heading'
  }
  if (domainTypeInstance === null) {
    return domainType.Title
  }
  return getHeading(settingsContext, domainTypes, domainType, domainTypeInstance)
}

export function isOfType<T extends AttributeType>(
  attributeValue: AttributeValue,
  attributeType: T,
  list: true
): attributeValue is AttributeValue<Attribute & { AttributeType: T, List: true }>
export function isOfType<T extends AttributeType>(
  attributeValue: AttributeValue,
  attributeType: T,
  list: false
): attributeValue is AttributeValue<Attribute & { AttributeType: T, List?: false }>
export function isOfType<T extends AttributeType>(
  attributeValue: AttributeValue,
  attributeType: T
): attributeValue is AttributeValue<Attribute & { AttributeType: T }>
export function isOfType<T extends AttributeType>(
  attributeValue: AttributeValue,
  attributeType: T,
  list?: boolean
): attributeValue is AttributeValue<Attribute & { AttributeType: T }> {
  if (list !== undefined && list !== (attributeValue.attribute.List ?? false)) {
    return false
  }
  const codec = attributeValue.attribute.List ?? false
    ? t.array(AttributeTypeCodecs[attributeValue.attribute.AttributeType])
    : AttributeTypeCodecs[attributeValue.attribute.AttributeType]
  return AttributeCodec.is(attributeValue.attribute)
    && attributeValue.attribute.AttributeType === attributeType
    && t.union([t.null, codec]).is(attributeValue.value)
}

export function makeAttributeTypeGuard<T extends AttributeType>(
  attributeType: T,
  list: true
): (attribute?: Attribute) => attribute is Attribute & { AttributeType: T, List: true }
export function makeAttributeTypeGuard<T extends AttributeType>(
  attributeType: T,
  list: false
): (attribute?: Attribute) => attribute is Attribute & { AttributeType: T, List?: false }
export function makeAttributeTypeGuard<T extends AttributeType>(
  attributeType: T
): (attribute?: Attribute) => attribute is Attribute & { AttributeType: T }
export function makeAttributeTypeGuard<T extends AttributeType>(
  attributeType: T,
  list?: boolean
): (attribute?: Attribute) => attribute is Attribute & { AttributeType: T } {
  return (attribute?: Attribute): attribute is Attribute & { AttributeType: T } => {
    if (list !== undefined && list !== (attribute?.List ?? false)) {
      return false
    }
    return attribute?.AttributeType === attributeType
  }
}

export function renderAttributeTypeComponent<P extends { attributeValue: NonListAttributeValue }>(
  components: {
    [Key in AttributeType]?: {
      (props: P & { attributeValue: NonListAttributeValue<Attribute & { AttributeType: Key }> }): JSX.Element | null
    }
  },
  props: P
): [boolean, JSX.Element | null]
export function renderAttributeTypeComponent<P extends { attributeValue: ListAttributeValue }>(
  components: {
    [Key in AttributeType]?: {
      (props: P & { attributeValue: ListAttributeValue<Attribute & { AttributeType: Key }> }): JSX.Element | null
    }
  },
  props: P
): [boolean, JSX.Element | null]
export function renderAttributeTypeComponent<P extends { attributeValue: AttributeValue }>(
  components: {
    [Key in AttributeType]?: {
      (props: P & { attributeValue: AttributeValue<Attribute & { AttributeType: Key }> }): JSX.Element | null
    }
  },
  props: P
): [boolean, JSX.Element | null]
export function renderAttributeTypeComponent<P extends { attributeValue: AttributeValue }>(
  components: {
    [Key in AttributeType]?: {
      (props: P & { attributeValue: AttributeValue<Attribute & { AttributeType: Key }> }): JSX.Element | null
    }
  },
  props: P
): [boolean, JSX.Element | null] {
  const cases = Object.keys(components)
    .map(key => [key, components[key as AttributeType]]) as [AttributeType, { (props: P): JSX.Element | null }][]
  for (const [attributeType, Component] of cases) {
    if (isOfType(props.attributeValue, attributeType as AttributeType)) {
      const component = <Component {...props} />
      return [
        true,
        component
      ]
    }
  }
  return [false, null]
}

export function getStringFields(
  domainTypes: Partial<Record<string, DomainType>>,
  domainTypeChain: DomainType[],
  attributeChain: Attribute[] = []
): string[] {
  if (domainTypeChain.length === 0) {
    return []
  }
  const domainType = domainTypeChain[domainTypeChain.length - 1]
  if (domainType === undefined) {
    return []
  }
  return getDomainTypeAttributes(domainTypes, domainType).flatMap<string>(attribute => {
    const isRecursive = attributeChain.includes(attribute)
    if (isRecursive) {
      return []
    }
    const newAttributeChain = [...attributeChain, attribute]
    if (!['string', 'domainType', 'enum'].includes(attribute.AttributeType)) {
      return []
    }
    if (attribute.AttributeType === 'domainType') {
      const attributeDomainType = domainTypes[attribute.AttributeDomainType]
      if (attributeDomainType === undefined) {
        return []
      }
      if (getDomainTypeSetting(domainTypes, attributeDomainType, 'ExpandColumns') ?? false) {
        return getStringFields(domainTypes, [...domainTypeChain, attributeDomainType], newAttributeChain)
      }
    }
    return [newAttributeChain.map(attribute => attribute.Name).join('_')]
  })
}

export function isValidSortAttribute(attribute: Attribute): attribute is NonListAttribute {
  return !(attribute.List ?? false)
}

export function isValidSubtypeAttribute(attribute: Attribute): attribute is NonListAttribute<StringAttribute | EnumAttribute | DomainTypeAttribute> {
  return (
    attribute.AttributeType === 'string'
    || attribute.AttributeType === 'enum'
    || attribute.AttributeType === 'domainType'
    || attribute.AttributeType === 'ref'
  ) && !(attribute.List ?? false)
}

export function getDisplayValueFromAttribute(
  attribute: Attribute | undefined,
  domainType: DomainType,
  instance: DomainTypeInstance): string {
  return (() => {
    if (makeAttributeTypeGuard('string', false)(attribute)) {
      return getValue(instance, attribute)
    } else if (makeAttributeTypeGuard('date', false)(attribute)) {
      return dateToString(getValue(instance, attribute))
    } else if (makeAttributeTypeGuard('dateTime', false)(attribute)) {
      return dateTimeToString(getValue(instance, attribute))
    } else if (makeAttributeTypeGuard('number', false)(attribute)) {
      const numberValue = getValue(instance, attribute)
      return numberValue === null
        ? null
        : String(numberValue)
    } else if (makeAttributeTypeGuard('enum', false)(attribute)) {
      return attribute.EnumeratedType.Values
        .find(ev => ev.Value === getValue(instance, attribute))?.Description ?? null
    } else if (attribute !== undefined && attribute.List !== true) {
      const attributeValue = getAttributeValue(instance, attribute)
      return toStringIfNotNull(attributeValue.value)
    }
  })() ?? domainType.Title
}

export function isValidStartEndDateAttribute(attribute: Attribute | null | undefined): attribute is NonListAttribute<DateAttribute | DateTimeAttribute> {
  if (isNullOrUndefined(attribute)) {
    return false
  }
  return (attribute.AttributeType === 'date'
    || attribute.AttributeType === 'dateTime'
  ) && !(attribute.List ?? false)
}

export function getHeading(
  settingsContext: ContextType<typeof DomainTypeSettingsContext>,
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType,
  instance: DomainTypeInstance
): string {
  const getHeadingSetting = getSettingValue(settingsContext, domainTypes, domainType, 'getHeading')
  if (getHeadingSetting !== undefined) {
    return getHeadingSetting(settingsContext, domainTypes, domainType, instance)
  }
  const attributeName = getDomainTypeSetting(domainTypes, domainType, 'Heading')
  const attribute = getDomainTypeAttributes(domainTypes, domainType)
    .find(attribute => attribute.Name === attributeName)
  if (makeAttributeTypeGuard('domainType', false)(attribute)) {
    return getHeadingValueFromDomainType(
      settingsContext,
      getValue(instance, attribute),
      domainTypes,
      attribute.AttributeDomainType
    )
  }
  return getDisplayValueFromAttribute(attribute, domainType, instance)
}

export function getSubheading(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType,
  instance: DomainTypeInstance
): string | null {
  const path = getDomainTypeSetting(domainTypes, domainType, 'Subheading')
  if (isNullOrUndefined(path)) {
    return null
  }
  const attributeChainValues = getAttributeChainValues(domainTypes, domainType, instance, path)
  if (makeAttributeTypeGuard('string', false)(attributeChainValues?.attribute)) {
    const singleValue = attributeChainValues?.values[0]
    if (attributeChainValues?.values.length === 1
      && !isNullOrUndefined(singleValue)
      && typeof singleValue === 'string') {
      return singleValue
    }
  }
  return null
}

function toStringIfNotNull(value: unknown): string | null {
  return value === null
    ? null
    : String(value)
}

function toStringIfNotNullOrUndefined(value: unknown): string | null {
  return isNullOrUndefined(value)
    ? null
    : String(value)
}

function getNonNullableRootDomainType(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType
): DomainType {
  return getRootDomainType(domainTypes, domainType) ?? domainType
}

export function dateToString(
  date: string | undefined | null,
  format?: Intl.DateTimeFormatOptions
): string | null {
  return date === null || date === undefined || date === ''
    ? null
    : String(DateTime.fromISO(date, { zone: 'utc' })
      .setZone(Intl.DateTimeFormat().resolvedOptions().timeZone)
      .toLocaleString(format ?? DateTime.DATE_MED_WITH_WEEKDAY))
}

export function dateTimeToString(
  date: string | undefined | null,
  format?: Intl.DateTimeFormatOptions
): string | null {
  return date === null || date === undefined || date === ''
    ? null
    : String(DateTime.fromISO(date, { zone: 'utc' })
      .setZone(Intl.DateTimeFormat().resolvedOptions().timeZone)
      .toLocaleString(format ?? DateTime.DATETIME_MED_WITH_WEEKDAY))
}

export function getSubtype(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType,
  instance: DomainTypeInstance,
  cache: Partial<Record<string, DomainType[]>>
): DomainType | undefined {
  const rootDomainType = getNonNullableRootDomainType(domainTypes, domainType)
  const subtypeAttributePath = getDomainTypeSetting(domainTypes, rootDomainType, 'Subtype')?.split('_') ?? []
  if (subtypeAttributePath.length > 1) {
    const subtypeAttributeChainValue = getAttributeChainValues(
      domainTypes,
      domainType,
      instance,
      subtypeAttributePath.join('_')
    )
    const subtypeName = toStringIfNotNullOrUndefined(subtypeAttributeChainValue?.values[0])
    return getCachedSubtypes(domainTypes, rootDomainType, cache)
      .find(domainType => domainType.Name === subtypeName)
  }
  const subtypeAttribute = getDomainTypeAttributes(domainTypes, rootDomainType)
    .filter(isValidSubtypeAttribute)
    .find(attribute => attribute.Name === subtypeAttributePath[0])
  if (subtypeAttribute?.AttributeType === undefined) {
    return undefined
  }
  let subtypeName: string | null = null
  if (subtypeAttribute.AttributeType === 'domainType') {
    const subtypeInstance = getValue(instance, subtypeAttribute)
    const subtypeDomainType = domainTypes[subtypeAttribute.AttributeDomainType]
    if (!subtypeDomainType || !subtypeInstance) {
      return undefined
    }
    const rootSubtypeDomainType = getRootDomainType(domainTypes, subtypeDomainType)
    if (!rootSubtypeDomainType) {
      return undefined
    }
    subtypeName = getSubtype(
      domainTypes,
      rootSubtypeDomainType,
      subtypeInstance,
      cache
    )?.Name ?? null
    if (subtypeName === rootSubtypeDomainType.Name) {
      return rootDomainType
    }
  } else {
    subtypeName = toStringIfNotNull(getValue(instance, subtypeAttribute))
  }
  return getCachedSubtypes(domainTypes, rootDomainType, cache)
    .find(domainType => domainType.Name === subtypeName)
}

export function isDomainTypeListAttribute(attribute: Attribute | null | undefined): attribute is DomainTypeAttribute & { List: true } {
  if (isNullOrUndefined(attribute)) {
    return false
  }
  return attribute.AttributeType === 'domainType' && (attribute.List ?? false)
}

export function isDataformResultsAttribute(attribute: Attribute): attribute is DataformResultsAttribute {
  return attribute.AttributeType === 'dataformResults'
}

export function isMultiDataformResultsAttribute(attribute: Attribute): attribute is MultiDataformResultsAttribute {
  return attribute.AttributeType === 'multiDataformResults'
}

export function toErrorText(pathError: PathError | undefined): string | undefined {
  return typeof pathError === 'string'
    ? pathError
    : undefined
}

export function getErrorAtPath(pathError: PathError | undefined, path: (string | number)[]): PathError | undefined {
  if (path.length === 0 || pathError === undefined) {
    return pathError
  }
  const nextPath = path[0]
  if (nextPath === undefined) {
    return pathError
  }
  if (Array.isArray(pathError)) {
    if (typeof nextPath === 'number') {
      return getErrorAtPath(pathError[nextPath], path.slice(1))
    }
    return undefined
  }
  if (typeof pathError === 'string') {
    return undefined
  }
  return getErrorAtPath(pathError[nextPath], path.slice(1))
}

export function isRequired(attributeValue: AttributeValue): boolean {
  return attributeValue.attribute.Required === true
}

export function validateRequiredAttributes(attributeValues: AttributeValue[]): PathErrors | undefined {
  return attributeValues
    .filter(attributeValue => isRequired(attributeValue)
      && (attributeValue.value === null
        || attributeValue.value === ''
        || attributeValue.inlineAttributeValues !== undefined
        || (isListAttributeValue(attributeValue) && attributeValue.value.length === 0)
        || (isOfType(attributeValue, 'dataformResults', false) && attributeValue.value.Complete !== true)
      )
    )
    .reduce((pathError, invalidAttributeValue) => {
      if (invalidAttributeValue.inlineAttributeValues !== undefined) {
        const nestedPathErrors = validateRequiredAttributes(invalidAttributeValue.inlineAttributeValues)
        if (!nestedPathErrors) {
          return pathError
        }
        return {
          ...pathError,
          [invalidAttributeValue.attribute.Name]: nestedPathErrors
        }
      }
      return {
        ...pathError,
        [invalidAttributeValue.attribute.Name]: `${invalidAttributeValue.attribute.Title} is required`
      }
    }, undefined as PathErrors | undefined)
}

export function isListAttributeValue(attributeValue: AttributeValue): attributeValue is ListAttributeValue {
  return attributeValue.attribute.List ?? false
}

export function isInRole(user: User | null, role?: string | null): boolean {
  return user?.roles.includes(role ?? '') ?? false
}

export function getDomainTypeButtons(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType
): [DomainType, DomainTypeButton][] {
  return (mergeHierarchicalDomainTypeListSetting(domainTypes, domainType, 'Buttons') ?? [])
    .map<[DomainType, DomainTypeButton]>(button => [domainType, button])
}

export function getDomainTypeActions(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType
): [DomainType, DomainTypeAction][] {
  return (mergeHierarchicalDomainTypeListSetting(domainTypes, domainType, 'Actions') ?? [])
    .map<[DomainType, DomainTypeAction]>(action => [domainType, action])
}

export function makeSortFunction<Item, Property>(
  values: Property[] | null | undefined,
  selector: (item: Item) => Property
): (a: Item, b: Item) => number {
  return (a: Item, b: Item) => {
    if (isNullOrUndefined(values)) {
      return 1
    }
    const aIndex = values.indexOf(selector(a))
    const bIndex = values.indexOf(selector(b))
    if (aIndex === -1) {
      return 1
    }

    if (bIndex === -1) {
      return -1
    }

    return aIndex - bIndex
  }
}

export function getAttributeName(attributeValue: AttributeValue): string {
  return attributeValue.attribute.Name
}

export function isGlobalSetting(setting: string): setting is keyof GlobalDomainTypeSettings {
  return setting in GlobalDomainTypeSettingsCodec.type.props
}

export function isOverridableSetting(setting: string): setting is keyof OverridableDomainTypeSettings {
  return isGlobalSetting(setting)
    || setting in OverridableNotGlobalDomainTypeSettingsCodec.type.props
}

export function isOverridableNotGlobalSetting(setting: string): setting is keyof typeof OverridableNotGlobalDomainTypeSettingsCodec.type.props {
  return setting in OverridableNotGlobalDomainTypeSettingsCodec.type.props
}

export function isEditableNotOverridableSetting(setting: string): setting is keyof typeof EditableNotOverridableDomainTypeSettingsCodec.type.props {
  return setting in EditableNotOverridableDomainTypeSettingsCodec.type.props
}

export function roleProtect<T>(
  value: T,
  user: User | null,
  role: string | null
): T | undefined {
  return role !== null && isInRole(user, role)
    ? value
    : undefined
}

export function isValidTimelineGroupByAttribute(attribute: Attribute | null | undefined): attribute is NonListAttribute<StringAttribute | DomainTypeAttribute | EnumAttribute | RefAttribute> {
  return !isNullOrUndefined(attribute)
    && (
      attribute.AttributeType === 'string'
      || attribute.AttributeType === 'domainType'
      || attribute.AttributeType === 'enum'
      || attribute.AttributeType === 'ref'
    )
    && attribute.List === false
}

export function isValidTimelineRouteByAttribute(attribute: Attribute | null | undefined): attribute is NonListAttribute<DomainTypeAttribute> {
  return !isNullOrUndefined(attribute)
    && makeAttributeTypeGuard('domainType', false)(attribute)
}

export function getContextTree(
  context: Omit<ContextType<typeof DomainTypeContext>, 'onInvalidate'>,
  actionDomainType?: DomainType,
  leafNodeInstances: DomainTypeInstance[] = [],
  leafNodeType: 'nested' | 'active' = 'active',
  includePlaceholder = false
): ContextTree {
  const instances = [...context.instances]
  const attributes = [...context.attributes]
  let instance = instances.shift()
  let attribute = attributes.shift()
  const rootNodes: ContextDomainTypeNode[] = []
  let currentNodes = rootNodes
  while (instance !== undefined) {
    const newNodes: ContextDomainTypeNode[] = []
    currentNodes.push({
      domainType: instance[0],
      instance: instance[1],
      type: 'context',
      nodes: attribute !== undefined && isDomainTypeListAttribute(attribute)
        ? [
          {
            attribute,
            nodes: newNodes,
            type: 'context'
          }
        ]
        : []
    })
    currentNodes = newNodes
    instance = instances.shift()
    attribute = attributes.shift()
  }
  if (isNullOrUndefined(actionDomainType)) {
    return rootNodes
  }
  currentNodes.push(...leafNodeInstances.map<ContextDomainTypeNode>(instance => ({
    domainType: actionDomainType,
    instance,
    type: leafNodeType,
    nodes: []
  })))
  if (includePlaceholder && currentNodes.length === 0) {
    currentNodes.push({
      domainType: actionDomainType,
      instance: {},
      type: `${leafNodeType}-placeholder`,
      nodes: []
    })
  }
  return rootNodes
}

export function isLeafNode(node: ContextDomainTypeNode): boolean {
  return node.nodes.length === 0
}

export function isBranchNode(node: ContextDomainTypeNode): boolean {
  return node.nodes.length > 0
}

export function getLeafNodes<T>(tree: ContextTree<T>): ContextDomainTypeNode<T>[] {
  const leafNodes = tree.filter(isLeafNode)
  const branchNodes = tree.filter(isBranchNode)
  return [
    ...leafNodes,
    ...branchNodes.flatMap(node => node.nodes
      .flatMap(attributeNode => getLeafNodes(attributeNode.nodes)))
  ]
}

export function getNodes<T>(tree: ContextTree<T>): ContextDomainTypeNode<T>[] {
  return [
    ...tree,
    ...tree.flatMap(node => node.nodes
      .flatMap(attributeNode => getNodes(attributeNode.nodes)))
  ]
}

export function getBatchInstances(tree: ContextTree): [DomainType, DomainTypeInstance][] {
  return getNodes(tree)
    .filter(node => node.type === 'active' || node.type === 'nested')
    .map<[DomainType, DomainTypeInstance]>(node => [node.domainType, node.instance])
}

export function getDataformResultsCompleteness(results: Results | null | undefined): 0 | 1 | 2 | 3 {
  if (isNullOrUndefined(results)) {
    return 3
  }
  if (results.Complete !== true) {
    return 0
  }
  if (results.AsExpected === false) {
    return 1
  }
  return 2
}

export function getMultiDataformResultsCompleteness(
  multiDataformResults: ValueTypes['multiDataformResults'] | null
): 0 | 1 | 2 | 3 {
  return Math.min(
    3,
    ...Object.values(multiDataformResults ?? {})
      .map(results => getDataformResultsCompleteness(results))
  ) as 0 | 1 | 2 | 3
}

export function getDataformResultsIcon(
  results: Results | null | undefined,
  theme: Theme
): readonly [icon: string | null, colour: string] {
  const completeness = getDataformResultsCompleteness(results)
  return ([
    ['play_arrow', theme.palette.warning.main],
    ['warning', theme.palette.error.main],
    ['check', theme.palette.success.main],
    [null, theme.palette.text.primary]
  ] as const)[completeness]
}

export function getMultiDataformResultsIcon(
  multiDataformResults: ValueTypes['multiDataformResults'] | null,
  theme: Theme
): readonly [icon: string | null, colour: string] {
  const completeness = getMultiDataformResultsCompleteness(multiDataformResults)
  return ([
    ['play_arrow', theme.palette.warning.main],
    ['warning', theme.palette.error.main],
    ['check', theme.palette.success.main],
    [null, theme.palette.text.primary]
  ] as const)[completeness]
}

export function blobToBase64(blob: Blob, mimeType: string): Promise<string | null> {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(
      reader.result
        ?.toString()
        .replace(/^data:.+,/, `data:${mimeType};base64,`) ?? null
    )
    reader.readAsDataURL(blob)
  })
}

export async function wait(ms = 1000): Promise<void> {
  return await new Promise(resolve => {
    setTimeout(resolve, ms)
  })
}

export function getPatchRequestBody(
  domainTypes: Partial<Record<string, DomainType>>,
  node: ContextDomainTypeNode | undefined
): DomainTypeInstance | undefined {
  if (node === undefined) {
    return undefined
  }
  if (isLeafNode(node)) {
    return node.instance
  }

  const nextNode = node.nodes[0]

  if (nextNode === undefined) {
    return node.instance
  }

  const identifier = getDomainTypeSetting(domainTypes, node.domainType, 'Identifier') ?? 'Id'
  const nextBodies = nextNode.nodes
    .map(node => getPatchRequestBody(domainTypes, node))
    .filter(body => body !== undefined)
  return {
    [identifier]: node.instance[identifier],
    [nextNode.attribute.Name]: nextBodies
  }
}

export function getDomainTypeExternalId(domainType: DomainType): string {
  return isNullOrUndefined(domainType.DatabaseTable)
    ? domainType.Name
    : `${domainType.Name}:${domainType.DatabaseTable}`
}

export function requiresNoInstances(action: DomainTypeAction): boolean {
  return !(action.Parameters ?? [])
    .some(parameter => parameter.Type === 'AttributeActionParameter')
    && !(action.Effects ?? [])
      .some(effect => effect.Target === 'instance'
        || effect.Target === 'rootInstance'
        || (effect.Type === 'CreateItemsActionEffect' && effect.Transformer.includes('$.Instances')))
    && !(action.Conditions ?? [])
      .some(condition => !condition.Property.startsWith(CONTEXT_PREFIX))
}

export function doesNotUpdateEntity(action: DomainTypeAction): boolean {
  return (action.Parameters ?? [])
    .every(parameter => parameter.Type !== 'AttributeActionParameter')
    && (action.Effects ?? [])
      .every(effect => effect.Type === 'CopyToFileStoreActionEffect'
        || effect.Type === 'DownloadFromFileStoreActionEffect'
        || (effect.Type === 'QueueJobActionEffect' && effect.DownloadFile)
        || effect.Type === 'SendEmailActionEffect'
        || effect.Type === 'SendPushNotificationActionEffect'
        || effect.Type === 'DownloadInstanceActionEffect')
}

export function switchCase<T, K extends keyof T, C extends (string | number) & T[K], O>(
  over: K,
  cases: { [Value in C]: (value: T & { [Key in K]: Value }) => O }
): (value: T) => O {
  return value => {
    const key = value[over] as C
    return cases[key](value as T & { [Key in K]: typeof key })
  }
}

export function partialSwitchCase<T, K extends keyof T, C extends (string | number) & T[K], O>(
  over: K,
  cases: { [Value in C]?: (value: T & { [Key in K]: Value }) => O },
  defaultValue: (value: T) => O
): (value: T) => O {
  return value => {
    const key = value[over] as C
    return (cases[key] ?? defaultValue)(value as T & { [Key in K]: typeof key })
  }
}

export function getCachedDomainTypeList(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType,
  func: (domainTypes: Partial<Record<string, DomainType>>, domainType: DomainType, cache: Partial<Record<string, DomainType[]>>) => DomainType[],
  cache: Partial<Record<string, DomainType[]>>
): DomainType[] {
  const cacheValue = cache[domainType.Id]
  if (!isNullOrUndefined(cacheValue)) {
    return cacheValue
  }
  const list = func(
    domainTypes,
    domainType,
    cache
  )
  cache[domainType.Id] = list
  return list
}

export function getCachedSubtypes(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType,
  cache: Partial<Record<string, DomainType[]>>
): DomainType[] {
  return getCachedDomainTypeList(
    domainTypes,
    domainType,
    getSubtypes,
    cache
  )
}

export function getCachedParentDomainTypes(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType,
  cache: Partial<Record<string, DomainType[]>>
): DomainType[] {
  return getCachedDomainTypeList(
    domainTypes,
    domainType,
    getParentDomainTypes,
    cache
  )
}

export function getSortedEnumeratedValues(
  enumeratedType: EnumeratedType
): EnumeratedValue[] {
  return enumeratedType.Values
    .slice()
    .sort((v1, v2) => v1.Description < v2.Description ? 1 : -1)
    .sort((v1, v2) => (v1.Order ?? 0) > (v2.Order ?? 0) ? 1 : -1)
}

export function getOverriddenComponent<C extends keyof DomainTypeComponentOverrides>(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType,
  context: ContextType<typeof DomainTypeComponentOverrideContext>,
  component: C
): DomainTypeComponentOverrides[C] {
  const parentDomainTypes = getParentDomainTypes(domainTypes, domainType)
  for (const parent of parentDomainTypes) {
    const key = getDomainTypeExternalId(parent)
    const overriddenComponent = context.overrides[key]?.[component]
    if (overriddenComponent !== undefined) {
      return overriddenComponent as DomainTypeComponentOverrides[C]
    }
  }
  return context.global[component]
}

export function getContrastingColour(
  color: string,
  mode: 'dark' | 'light',
  targetContrastRatio: number
): string {
  const background = mode === 'dark'
    ? '#121212'
    : '#ffffff'
  const contrastRatio = getContrastRatio(color, background)
  if (contrastRatio >= targetContrastRatio) {
    return color
  }
  return {
    dark: lighten,
    light: darken
  }[mode](color, (targetContrastRatio - contrastRatio) / targetContrastRatio)
}

export function getTransparentBackgroundColour(
  color: string,
  mode: 'dark' | 'light',
  targetAlpha = CELL_AVATAR_BACKGROUND_ALPHA
): string {
  const background = mode === 'dark'
    ? '#000000'
    : '#ffffff'
  const contrastRatio = Math.log(getContrastRatio(color, background))
  return alpha(
    color,
    targetAlpha / contrastRatio
  )
}

export function fixMultipleAutocompleteRequiredBehaviour(
  params: AutocompleteRenderInputParams,
  attributeValue: AttributeValue,
  selectedValues: unknown[]
): AutocompleteRenderInputParams['inputProps'] {
  return {
    ...params.inputProps,
    required: (attributeValue.attribute.Required ?? false) && selectedValues.length === 0
  }
}

const getQueryLens = (id: string) => new Optional<unknown, DomainTypeOverrider>(
  settings => {
    if (!OverridableDomainTypeSettingsCodec.is(settings)) {
      return none
    }
    const query = settings.Queries?.find(query => query.Id === id)
    return query !== undefined
      ? some(query)
      : some({
        Id: id
      })
  },
  query => settings => {
    if (!OverridableDomainTypeSettingsCodec.is(settings)) {
      return settings
    }
    const queryIndex = settings.Queries?.findIndex(query => query.Id === id) ?? -1
    if (queryIndex === -1) {
      return {
        ...settings,
        Queries: [
          ...settings.Queries ?? [],
          query
        ]
      }
    }
    return {
      ...settings,
      Queries: [
        ...settings.Queries?.slice(0, queryIndex) ?? [],
        query,
        ...settings.Queries?.slice(queryIndex + 1) ?? []
      ]
    }
  }
)

const getOverrideLens = (id: string) => new Optional<unknown, DomainTypeOverride>(
  overrider => {
    if (!DomainTypeOverriderCodec.is(overrider)) {
      return none
    }
    const override = overrider.DomainTypeOverrides
      ?.find(override => override.Id === id)
    return override !== undefined
      ? some(override)
      : some({ Id: id })
  },
  override => overrider => {
    if (!DomainTypeOverriderCodec.is(overrider)) {
      return overrider
    }
    const overrideIndex = overrider.DomainTypeOverrides?.findIndex(override => override.Id === id) ?? -1
    if (overrideIndex === -1) {
      return {
        ...overrider,
        DomainTypeOverrides: [
          ...overrider.DomainTypeOverrides ?? [],
          override
        ]
      }
    }
    return {
      ...overrider,
      DomainTypeOverrides: [
        ...overrider.DomainTypeOverrides?.slice(0, overrideIndex) ?? [],
        override,
        ...overrider.DomainTypeOverrides?.slice(overrideIndex + 1) ?? []
      ]
    }
  }
)

export function makeOverrideLens(
  path: OverriderDetails['path'],
  domainTypeId: string
): Optional<unknown, DomainTypeOverride> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let lens: Optional<any, DomainTypeOverride> = getOverrideLens(domainTypeId)
  for (const pathStep of path.slice().reverse()) {
    switch (pathStep.type) {
      case 'query':
        lens = getQueryLens(pathStep.id).compose(lens)
        break
      case 'override':
        lens = getOverrideLens(pathStep.id).compose(lens)
    }
  }
  return lens
}

export function getSortableValue(
  settingsContext: ContextType<typeof DomainTypeSettingsContext>,
  domainTypes: Partial<Record<string, DomainType>>,
  attributeValue: AttributeValue<Attribute> | null | undefined
): Value<Attribute> | Value<Attribute>[] | null {
  if (isNullOrUndefined(attributeValue)) {
    return null
  }
  if (isListAttributeValue(attributeValue)) {
    return attributeValue.value
  }
  if (isOfType(attributeValue, 'enum', false)) {
    return getSortedEnumeratedValues(attributeValue.attribute.EnumeratedType)
      .findIndex(enumeratedValue => enumeratedValue.Value === String(attributeValue.value))
  }
  return isOfType(attributeValue, 'domainType', false)
    ? getHeadingValueFromDomainType(
      settingsContext,
      attributeValue.value,
      domainTypes,
      attributeValue.attribute.AttributeDomainType
    )
    : attributeValue.value
}

export function canEditInstancesAttributes(
  domainTypes: Partial<Record<string, DomainType>>,
  subtype: DomainType,
  filterContext: FilterContext,
  settingsContext: SettingsContext,
  instances: DomainTypeInstance[],
  user: User | null,
  attributes: string[]
): 'never' | 'state' | 'permission' | true {
  const editButton = getDomainTypeButtons(domainTypes, subtype)
    .map(([, button]) => button)
    .find(button => button.Type === 'EditButton')
  const editForm = getDomainTypeSetting(domainTypes, subtype, 'EditForm')
  if (editButton === undefined) {
    return 'never'
  }
  if (isDisabled(
    editButton,
    domainTypes,
    subtype,
    filterContext,
    settingsContext,
    {
      type: 'instances',
      instances
    },
    null,
    []
  )) {
    return 'state'
  }
  if (!isInRole(user, editButton.Role)) {
    return 'permission'
  }
  if (!isNullOrUndefined(editForm)
    && attributes.some(name => !editForm.includes(name))) {
    return 'permission'
  }
  return true
}

export function toText(apiError: ApiError): string {
  if (apiError.status === undefined) {
    return 'Something went wrong'
  }
  switch (apiError.status) {
    case 404:
      return 'Not found'
    case 500:
      return 'Server error'
    default:
      return `Error status ${apiError.status}`
  }
}

export function shouldRenderCellAsLink(
  api: boolean,
  idAttribute: Attribute | undefined,
  disableLink: boolean,
  user: User | null,
  role: string | null | undefined
): idAttribute is Attribute {
  return api
    && idAttribute !== undefined
    && !disableLink
    && (isNullOrUndefined(role) || isInRole(user, role))
}

export function getAttributeChainTitleForDisplay(attributeChain: Attribute[]): string {
  let filteredAttributes = attributeChain.filter(attribute => attribute.ExcludeFromHeader !== true)
  if (filteredAttributes.length === 0) {
    const lastAttribute = attributeChain[attributeChain.length - 1]
    if (lastAttribute !== undefined) {
      filteredAttributes = [lastAttribute]
    }
  }

  return filteredAttributes.map(attribute => attribute.Title).join(PATH_SEPARATOR)
}

export function isMapAttribute(
  domainTypes: Partial<Record<string, DomainType>>
) {
  return (attribute: Attribute): attribute is NonListAttribute<DomainTypeAttribute> | NonListAttribute<StringAttribute> => {
    if (attribute.List === true) {
      return false
    }
    if (attribute.AttributeType === 'domainType') {
      return domainTypes[attribute.AttributeDomainType]?.Name === 'What3Words'
    }
    if (attribute.AttributeType === 'string') {
      return attribute.Format === 'gps'
    }
    return false
  }
}

/**
 * A reversed attribute chain starting with the map attribute
 */
type MapAttributeChain = [
  NonListAttribute<DomainTypeAttribute> | NonListAttribute<StringAttribute>,
  ...NonListAttribute<DomainTypeAttribute>[],
]

export function getMapAttributeChains(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType
): MapAttributeChain[] {
  const allAttributes = getDomainTypeAttributes(domainTypes, domainType)
  const mapAttributes = allAttributes
    .filter(isMapAttribute(domainTypes))
  const mapAttributeChains = allAttributes
    .filter(makeAttributeTypeGuard('domainType', false))
    .flatMap((attribute): MapAttributeChain[] => {
      const attributeDomainType = domainTypes[attribute.AttributeDomainType]
      if (attributeDomainType === undefined) {
        return []
      }
      return getMapAttributeChains(domainTypes, attributeDomainType)
        .map(chain => [...chain, attribute])
    })
  return mapAttributes
    .map((attribute): MapAttributeChain => [attribute])
    .concat(mapAttributeChains)
}

export function getLatLong(instance: DomainTypeInstance, mapAttributeChain: MapAttributeChain): [number, number] | undefined {
  const [mapAttribute, ...reversePath] = mapAttributeChain
  const path = reversePath.reverse()
  let from: DomainTypeInstance = instance
  for (const attribute of path) {
    const nextFrom = getValue(from, attribute)
    if (nextFrom === null) {
      return undefined
    }
    from = nextFrom
  }
  if (mapAttribute.AttributeType === 'domainType') {
    const value = getValue(from, mapAttribute)
    if (value === null) {
      return undefined
    }
    return [Number(value['Latitude']), Number(value['Longitude'])]
  }
  const value = getValue(instance, mapAttribute)
  if (value === null) {
    return undefined
  }
  const [lat, long] = value.split(',')
  return [Number(lat), Number(long)]
}

export function validateInstancesMatchCurrentCompany(
  domainTypes: Partial<Record<string, DomainType>>,
  instances: [DomainType, Readonly<DomainTypeInstance>][],
  company: Company | null
): boolean {
  if (instances.length > 0) {
    return instances.every(([domainType, instance]) => {
      const companyAttribute = getDomainTypeAttribute(domainTypes, domainType, 'Company')
      if (companyAttribute === undefined) {
        return true
      }
      const instanceCompany = getValue(instance, companyAttribute) ?? SYSTEM_COMPANY_ID
      return company?.Id === instanceCompany
    })
  }
  return true
}

export function requiresFormsAuthenticationCookieRefresh() {
  return document.cookie.indexOf(FORMS_AUTHENTICATION_COOKIE_NAME) === -1
}

export function chunk<T>(size: number): (array: T[]) => T[][] {
  return array => {
    if (array.length <= size) {
      return [array.slice()]
    }
    return [array.slice(0, size)].concat(chunk<T>(size)(array.slice(size)))
  }
}