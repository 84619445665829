import { DragHandleOutlined } from '@mui/icons-material'
import { Chip, ChipProps } from '@mui/material'
import { useRef } from 'react'
import { Flipped } from 'react-flip-toolkit'
import { useDragToReorder } from 'utils/hooks'

interface Props extends ChipProps {
  readonly id: string
  readonly order: string[]
  onChangeOrder(order: string[]): void
  onCommitOrder(order: string[]): void
}

export default function SortableChip({
  id,
  order,
  onChangeOrder,
  onCommitOrder,
  ...props
}: Props): JSX.Element {
  const dropRef = useRef<HTMLDivElement | null>(null)
  const {
    drag,
    preview,
    drop,
    opacity
  } = useDragToReorder(
    id,
    order,
    'item',
    dropRef,
    'horizontal',
    onChangeOrder,
    onCommitOrder
  )
  return (
    <Flipped flipId={id}>
      <Chip
        {...props}
        ref={node => {
          dropRef.current = node
          preview(drop(node))
        }}
        icon={id !== ''
          ? (
            <div
              ref={drag}
              style={{
                cursor: 'move',
                display: 'flex',
                alignItems: 'center'
              }}>
              <DragHandleOutlined fontSize='small' />
            </div>
          )
          : undefined}
        sx={{ opacity }} />
    </Flipped>
  )
}
