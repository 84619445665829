import * as E from 'fp-ts/Either'
import * as O from 'fp-ts/Option'
import { constNull, constant, identity, pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'
import { DateTime, DurationLike } from 'luxon'
import { Lens } from 'monocle-ts'
import { ContextType } from 'react'
import { DateHeaderProps, Id, LabelFormat } from 'react-calendar-timeline'
import { ActionParameter, AttributeValue, ContextTree, DateAttribute, DateTimeAttribute, DomainType, DomainTypeAction, DomainTypeInstance, DomainTypeOverrider, NonListAttribute, User } from 'types'
import { DomainTypeInstanceCodec } from 'utils/codecs'
import { PATH_SEPARATOR } from 'utils/constants'
import { DomainTypeContext, DomainTypeSettingsContext, SettingsContext } from 'utils/context'
import { FilterContext, applyAllFilters } from 'utils/filters'
import { canEditInstancesAttributes, getAttributeValue, getBatchInstances, getContextTree, getDomainTypeActions, getDomainTypeAttribute, getDomainTypeSetting, getHeading, getIdentifier, getOverridableDomainTypeSettingAttribute, getRootDomainType, getSortableValue, getSubheading, getSubtype, getValue, isDomainTypeListAttribute, isInRole, isNullOrUndefined, isOfType, isValidStartEndDateAttribute, isValidTimelineGroupByAttribute, isValidTimelineRouteByAttribute, makeAttributeTypeGuard } from 'utils/helpers'
import { getFilterContext, getFilterContextFromTree } from 'utils/hooks'
import { ActionDetails, getActionDetails } from 'utils/hooks/useActions'
import { CalendarProps, CalendarTimelineSettings, CustomTimelineGroup, CustomTimelineItem, GroupByValue, ItemTimelineItem, Route, RouteMoveStopPutRequestBody, RouteTimelineItem, Stop } from './types'

export const NULL_GROUP_ID = '__null__'

export function getItemActionDetails(
  user: User | null,
  itemAction: [DomainType, DomainTypeAction] | undefined,
  domainTypeChain: CalendarTimelineSettings[],
  groupItems: DomainTypeInstance[],
  items: DomainTypeInstance[],
  itemDomainTypeContext: ContextType<typeof DomainTypeContext> | null,
  filterContext: FilterContext,
  childDomainTypesCache: Partial<Record<string, DomainType[]>>,
  parentDomainTypesCache: Partial<Record<string, DomainType[]>>
): ActionDetails | undefined {
  if (itemAction === undefined) {
    return undefined
  }
  if (itemDomainTypeContext === null) {
    return undefined
  }
  const [actionDomainType, action] = itemAction
  const contextTree = getContextTree(
    itemDomainTypeContext,
    actionDomainType,
    items,
    groupItems.length > 0
      ? 'nested'
      : 'active'
  )
  const apiDomainType = itemDomainTypeContext.instances[0]?.[0] ?? domainTypeChain[0]?.domainType
  if (apiDomainType === undefined) {
    return undefined
  }
  const pageDomainType = domainTypeChain[domainTypeChain.length - 1]?.domainType
  if (pageDomainType === undefined) {
    return undefined
  }
  return getActionDetails(
    filterContext.domainTypes,
    apiDomainType,
    pageDomainType,
    childDomainTypesCache,
    parentDomainTypesCache,
    contextTree,
    {
      type: 'instances',
      instances: items
    },
    filterContext,
    user,
    actionDomainType,
    action
  )
}

export function canPerformAction(
  actionDetails: ActionDetails | undefined,
  canEdit: ReturnType<typeof canEditInstancesAttributes>
): boolean {
  return actionDetails?.visible === true
    ? !actionDetails.disabled
    : canEdit === true
}

export function getItemDomainTypeContext(
  domainTypeChain: CalendarTimelineSettings[],
  groupItems: DomainTypeInstance[],
  domainTypeContext: ContextType<typeof DomainTypeContext>
): ContextType<typeof DomainTypeContext> | null {
  const itemDomainTypeContext: ContextType<typeof DomainTypeContext> = {
    instances: domainTypeContext.instances.slice(),
    attributes: domainTypeContext.attributes.slice(),
    batchInstances: domainTypeContext.batchInstances.slice()
  }
  for (let i = 0; i < groupItems.length; i++) {
    const groupItem = groupItems[i]
    const timelineSettings = domainTypeChain[i]
    if (groupItem === undefined
      || timelineSettings === undefined
      || timelineSettings.itemsAttribute === null) {
      return null
    }
    itemDomainTypeContext.instances.push([
      timelineSettings.domainType,
      groupItem
    ])
    itemDomainTypeContext.attributes.push(timelineSettings.itemsAttribute)
  }
  return itemDomainTypeContext
}

export function isAttributeParameter(name: string): (parameter: ActionParameter) => boolean {
  return parameter => parameter.Type === 'AttributeActionParameter'
    && parameter.Attribute === name
}

export function isMoveResizeAction(
  action: DomainTypeAction,
  timelineSettings: CalendarTimelineSettings | undefined
): boolean {
  if (timelineSettings === undefined) {
    return false
  }
  const {
    startDateAttribute,
    endDateAttribute,
    groupByAttribute
  } = timelineSettings
  if (startDateAttribute === null) {
    return false
  }
  if (isNullOrUndefined(action.Parameters)) {
    return false
  }
  return action.Parameters.some(isAttributeParameter(startDateAttribute.Name))
    && action.Parameters.some(isAttributeParameter(endDateAttribute?.Name ?? startDateAttribute.Name))
    && (groupByAttribute === null || !action.Parameters.some(isAttributeParameter(groupByAttribute.Name)))
}

export function getCalendarTimelineSettings(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType,
  overriders: DomainTypeOverrider[]
): CalendarTimelineSettings {
  const startDateAttribute = getOverridableDomainTypeSettingAttribute(domainTypes, domainType, overriders, 'StartDate')
  const endDateAttribute = getOverridableDomainTypeSettingAttribute(domainTypes, domainType, overriders, 'EndDate')
  const groupByAttribute = getOverridableDomainTypeSettingAttribute(domainTypes, domainType, overriders, 'TimelineGroupBy')
  const routeByAttribute = getOverridableDomainTypeSettingAttribute(domainTypes, domainType, overriders, 'TimelineRouteBy')
  const itemsAttribute = getOverridableDomainTypeSettingAttribute(domainTypes, domainType, overriders, 'TimelineItems')
  const identifier = getDomainTypeSetting(
    domainTypes,
    domainType,
    'Identifier'
  ) ?? 'Id'
  return {
    domainType,
    startDateAttribute,
    endDateAttribute,
    groupByAttribute,
    routeByAttribute,
    itemsAttribute,
    identifier
  }
}

const attributeNameLens = Lens.fromPath<AttributeValue>()(['attribute', 'Name'])

export function getActionParameterValues(
  domainTypeChain: CalendarTimelineSettings[],
  action: DomainTypeAction | undefined,
  startDate: number,
  endDate: number,
  groupByValue?: GroupByValue
): AttributeValue[] {
  if (action === undefined) {
    return []
  }
  const {
    startDateAttribute,
    endDateAttribute,
    groupByAttribute
  } = domainTypeChain[domainTypeChain.length - 1] ?? {}
  if (isNullOrUndefined(startDateAttribute)
    || isNullOrUndefined(endDateAttribute)) {
    return []
  }
  const startDateParameter = action.Parameters
    ?.find(isAttributeParameter(startDateAttribute.Name))
  const endDateParameter = action.Parameters
    ?.find(isAttributeParameter(endDateAttribute.Name))
  if (startDateParameter === undefined
    || endDateParameter === undefined) {
    return []
  }
  const parameterValues = [
    attributeNameLens.set(startDateParameter.Name)(getAttributeValue(
      {
        [startDateAttribute.Name]: DateTime.fromMillis(startDate).toUTC().toISO()
      },
      startDateAttribute
    )),
    attributeNameLens.set(endDateParameter.Name)(getAttributeValue(
      {
        [endDateAttribute.Name]: DateTime.fromMillis(endDate).toUTC().toISO()
      },
      endDateAttribute
    ))
  ]
  if (!isNullOrUndefined(groupByAttribute)) {
    if (groupByValue?.attributeValue === undefined) {
      return parameterValues
    }
    const groupByParameter = action.Parameters
      ?.find(isAttributeParameter(groupByAttribute.Name))
    if (groupByParameter === undefined) {
      return parameterValues
    }
    parameterValues.push(attributeNameLens.set(groupByParameter.Name)(
      groupByValue.attributeValue
    ))
  }
  return parameterValues
}

export function isChangeGroupAction(
  action: DomainTypeAction,
  timelineSettings: CalendarTimelineSettings | undefined
): boolean {
  if (timelineSettings === undefined) {
    return false
  }
  const {
    startDateAttribute,
    endDateAttribute,
    groupByAttribute
  } = timelineSettings
  if (startDateAttribute === null
    || groupByAttribute === null) {
    return false
  }
  if (isNullOrUndefined(action.Parameters)) {
    return false
  }
  return action.Parameters.some(isAttributeParameter(startDateAttribute.Name))
    && action.Parameters.some(isAttributeParameter(endDateAttribute?.Name ?? startDateAttribute.Name))
    && action.Parameters.some(isAttributeParameter(groupByAttribute.Name))
}

export function getChangeRouteDatesPatchRequestDatabaseTableAndBody(
  domainTypeChain: CalendarTimelineSettings[],
  route: DomainTypeInstance | undefined,
  startDate: number
): [string, DomainTypeInstance] {
  const timelineSettings = domainTypeChain[0]
  if (timelineSettings === undefined) {
    return ['', {}]
  }
  if (route === undefined) {
    return ['', {}]
  }
  return [
    timelineSettings.domainType.DatabaseTable ?? '',
    {
      Id: route[timelineSettings.identifier],
      StartTime: DateTime.fromMillis(startDate).toUTC().toISO(),
      EndTime: null
    }
  ]
}

export function getChangeItemDatesPatchRequestBody(
  domainTypeChain: CalendarTimelineSettings[],
  groupItems: DomainTypeInstance[],
  items: DomainTypeInstance[],
  startDate: number,
  endDate: number,
  groupByValue: GroupByValue | undefined
): DomainTypeInstance | null {
  const domainTypeTimelineSettings = domainTypeChain[0]
  if (domainTypeTimelineSettings === undefined) {
    return null
  }
  const {
    identifier,
    startDateAttribute,
    endDateAttribute,
    itemsAttribute
  } = domainTypeTimelineSettings
  if (groupItems.length === 0) {
    const groupByProperties = groupByValue?.attributeValue !== undefined
      ? {
        [groupByValue.attributeValue.attribute.Name]: groupByValue.attributeValue.value
      }
      : {}
    const item = items[0]
    if (item === undefined) {
      return null
    }
    if (startDateAttribute === null) {
      return null
    }
    return {
      [identifier]: item[identifier],
      [startDateAttribute.Name]: DateTime.fromMillis(startDate).toUTC().toISO(),
      [endDateAttribute?.Name ?? startDateAttribute.Name]: DateTime.fromMillis(endDate).toUTC().toISO(),
      ...groupByProperties
    }
  }
  const groupItem = groupItems[0]
  if (groupItem === undefined) {
    return null
  }
  if (!isDomainTypeListAttribute(itemsAttribute)) {
    return null
  }
  if (groupItems.length > 1) {
    return {
      [identifier]: groupItem[identifier],
      [itemsAttribute.Name]: getChangeItemDatesPatchRequestBody(
        domainTypeChain.slice(1),
        groupItems.slice(1),
        items,
        startDate,
        endDate,
        groupByValue
      )
    }
  }
  return {
    [identifier]: groupItem[identifier],
    [itemsAttribute.Name]: items.map(item => {
      return getChangeItemDatesPatchRequestBody(
        domainTypeChain.slice(1),
        [],
        [item],
        startDate,
        endDate,
        groupByValue
      )
    })
  }
}

export function getTimelineItemChains(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType,
  overriders: DomainTypeOverrider[],
  items: DomainTypeInstance[] = []
): [domainTypeChain: CalendarTimelineSettings[], itemChains: DomainTypeInstance[][]] {
  const domainTypeChain: CalendarTimelineSettings[] = [
    getCalendarTimelineSettings(domainTypes, domainType, overriders)
  ]
  let itemChains: DomainTypeInstance[][] = items.map(item => [item])
  let currentDomainType = domainType
  let timelineItemsAttribute = getOverridableDomainTypeSettingAttribute(domainTypes, currentDomainType, overriders, 'TimelineItems')
  while (timelineItemsAttribute !== null
    && isDomainTypeListAttribute(timelineItemsAttribute)) {
    const itemDomainType = domainTypes[timelineItemsAttribute.AttributeDomainType]
    if (itemDomainType === undefined) {
      break
    }
    currentDomainType = itemDomainType
    domainTypeChain.push(getCalendarTimelineSettings(domainTypes, currentDomainType, overriders))
    const previousItemChains = itemChains
    itemChains = []
    for (const itemChain of previousItemChains) {
      const childItems = getValue(itemChain[itemChain.length - 1] ?? {}, timelineItemsAttribute)
      itemChains.push(...childItems?.map(childItem => [...itemChain, childItem]) ?? [])
    }
    timelineItemsAttribute = getOverridableDomainTypeSettingAttribute(domainTypes, currentDomainType, overriders, 'TimelineItems')
  }
  return [domainTypeChain, itemChains]
}

function getItemBatchInstances(
  instances: DomainTypeInstance[],
  actionDetails: ActionDetails | undefined,
  itemsDomainType: DomainType
): [DomainType, DomainTypeInstance][] {
  return actionDetails
    ? getBatchInstances(actionDetails.contextTree)
    : instances.map((item): [DomainType, Readonly<DomainTypeInstance>] => [itemsDomainType, item])
}

export function getItemFilterContext(
  domainTypes: Partial<Record<string, DomainType>>,
  itemDomainTypeContext: ContextType<typeof DomainTypeContext>,
  instances: DomainTypeInstance[],
  actionDetails: ActionDetails | undefined,
  itemsSubtype: DomainType,
  user: User | null
): FilterContext {
  return getFilterContext(
    domainTypes,
    {
      ...itemDomainTypeContext,
      batchInstances: getItemBatchInstances(
        instances,
        actionDetails,
        itemsSubtype
      )
    },
    user
  )
}

export function getRouteFilterContext(
  domainTypes: Partial<Record<string, DomainType>>,
  item: RouteTimelineItem,
  itemsSubtype: DomainType,
  actionDetails: ActionDetails | undefined,
  user: User | null
): FilterContext {
  const contextTree = getTimelineItemsContextTree(
    item.timelineItems,
    actionDetails?.actionDomainType ?? itemsSubtype
  )
  return getFilterContextFromTree(
    domainTypes,
    contextTree,
    user,
    getItemBatchInstances(
      item.timelineItems.flatMap(item => item.items),
      actionDetails,
      itemsSubtype
    )
  )
}

function getIsAttributeRequiredMessage(
  action: DomainTypeAction | undefined,
  attributeValue: AttributeValue
): string | null {
  if (attributeValue.value !== null) {
    return null
  }
  const parameter = action?.Parameters
    ?.find(isAttributeParameter(attributeValue.attribute.Name))
  if (parameter !== undefined) {
    if (parameter.Required) {
      return `${parameter.Name} is required`
    }
    return null
  }
  if (attributeValue.attribute.Required === true) {
    return `${attributeValue.attribute.Title} is required`
  }
  return null
}

const fromNullable = E.fromNullable<O.Option<string>>(O.none)

export function getAttributeValueInvalidReason(
  attributeValue: AttributeValue | null | undefined,
  filterContext: FilterContext,
  settingsContext: ContextType<typeof DomainTypeSettingsContext>,
  action?: DomainTypeAction
): O.Option<string> {
  return pipe(
    E.Do,
    E.apS('filterContext', E.right(filterContext)),
    E.apS('action', E.right(action)),
    E.apS('attributeValue', fromNullable(attributeValue)),
    E.chainFirst(({
      attributeValue,
      action
    }) => pipe(
      E.fromNullable(constNull)(getIsAttributeRequiredMessage(
        action,
        attributeValue
      )),
      E.swap,
      E.mapLeft(O.some)
    )),
    E.bind(
      'domainTypeAttributeValue',
      ({ attributeValue }) => isOfType(attributeValue, 'domainType', false)
        ? E.right(attributeValue)
        : E.left<O.Option<string>>(O.none)
    ),
    E.bind(
      'attributeDomainType',
      ({ filterContext, domainTypeAttributeValue }) => fromNullable(
        filterContext.domainTypes[domainTypeAttributeValue.attribute.AttributeDomainType]
      )
    ),
    E.bind(
      'instance',
      ({ domainTypeAttributeValue }) => fromNullable(
        domainTypeAttributeValue.value
      )
    ),
    E.chain(({ attributeDomainType, instance, domainTypeAttributeValue }) => applyAllFilters(
      filterContext.domainTypes,
      attributeDomainType,
      instance,
      domainTypeAttributeValue.attribute.Filters ?? [],
      filterContext
    )
      ? E.left(O.none)
      : E.left(O.some(`${getHeading(settingsContext, filterContext.domainTypes, attributeDomainType, instance)} is an invalid ${domainTypeAttributeValue.attribute.Title} value for this item`))
    ),
    E.match(identity, constant(O.none))
  )
}

interface GenericItem {
  readonly moveResizeActionDetails?: ActionDetails
  readonly canEdit: ReturnType<typeof canEditInstancesAttributes>
}

export function getMouseDownMessage(
  item: GenericItem,
  user: User | null
): O.Option<string> {
  if (canPerformAction(item.moveResizeActionDetails, item.canEdit)) {
    return O.none
  }
  if (item.moveResizeActionDetails === undefined) {
    switch (item.canEdit) {
      case 'state':
        return O.some('This item is in a state which does not allow it to be moved or resized')
      case 'permission':
        return O.some('You do not have permission to move or resize this item')
      case 'never':
        return O.some('This item cannot be moved or resized')
      default:
        return O.none
    }
  }
  if (item.moveResizeActionDetails.disabled === true) {
    if (item.moveResizeActionDetails.roles.every(role => isInRole(user, role))) {
      return O.some('This item is in a state which does not allow it to be moved or resized')
    } else {
      return O.some('You do not have permission to move or resize this item')
    }
  }
  return O.none
}

export function getFirstNotNullValueFromChain<T>(
  domainTypes: Partial<Record<string, DomainType>>,
  domainTypeChain: CalendarTimelineSettings[],
  itemChain: DomainTypeInstance[],
  selector: (
    domainTypes: Partial<Record<string, DomainType>>,
    settings: CalendarTimelineSettings | undefined,
    item: DomainTypeInstance
  ) => T | null
): T | null {
  for (let i = domainTypeChain.length - 1; i >= 0; i--) {
    const value = selector(
      domainTypes,
      domainTypeChain[i],
      itemChain[i] ?? {}
    )
    if (value !== null) {
      return value
    }
  }
  return null
}

interface ItemBoundaries {
  startDateAttribute: NonListAttribute<DateAttribute | DateTimeAttribute> | null
  endDateAttribute: NonListAttribute<DateAttribute | DateTimeAttribute> | null
  startDate: string | null
  endDate: string | null
}

export function getItemBoundaries(
  domainTypes: Partial<Record<string, DomainType>>,
  domainTypeChain: CalendarTimelineSettings[],
  itemChain: DomainTypeInstance[]
): ItemBoundaries {
  const defaultStartEndDate = {
    startDateAttribute: null,
    endDateAttribute: null,
    startDate: null,
    endDate: null
  }
  return getFirstNotNullValueFromChain(
    domainTypes,
    domainTypeChain,
    itemChain,
    (domainTypes, settings, item) => {
      if (settings === undefined) {
        return null
      }
      const { startDateAttribute, endDateAttribute } = settings
      if (!isValidStartEndDateAttribute(startDateAttribute)
        || (endDateAttribute !== null && !isValidStartEndDateAttribute(endDateAttribute))) {
        return null
      }
      const startDateValue = getValue(item, startDateAttribute)
      const endDateValue = getValue(item, endDateAttribute ?? startDateAttribute)
      if (startDateValue === null || endDateValue === null) {
        return null
      }
      return {
        startDateAttribute,
        endDateAttribute: endDateAttribute ?? startDateAttribute,
        startDate: startDateValue,
        endDate: endDateValue
      }
    }
  ) ?? defaultStartEndDate
}

const DEFAULT_WIDTH: DurationLike = { minutes: 15 }
export const DAY_START_OFFSET: DurationLike = { hours: 6 }
export const DAY_END_OFFSET: DurationLike = { hours: 6 }

function getEndOfDayAccountingForSQLRoundingOfDateTimesBy3Milliseconds(
  dateTime: DateTime,
  endOffset: DurationLike
): number {
  if (dateTime.equals(dateTime.startOf('day'))) {
    return dateTime.minus({ day: 1 }).endOf('day').minus(endOffset).toMillis()
  }
  return dateTime.endOf('day').minus(endOffset).toMillis()
}

export function getEdgeDates(
  startDateAttribute: NonListAttribute<DateAttribute | DateTimeAttribute>,
  endDateAttribute: NonListAttribute<DateAttribute | DateTimeAttribute> | null,
  startDate: string,
  endDate: string | null,
  view: 'day' | 'week' | 'month'
): [number, number] {
  const startOffset = view === 'day'
    ? DAY_START_OFFSET
    : {}
  const endOffset = view === 'day'
    ? DAY_END_OFFSET
    : {}
  const startDateTime = DateTime.fromISO(startDate)
  const start = startDateAttribute.AttributeType === 'date'
    ? startDateTime.startOf('day').plus(startOffset).toMillis()
    : startDateTime.toMillis()
  if (endDateAttribute === null
    || endDate === null
    || endDate === startDate) {
    const end = startDateAttribute.AttributeType === 'date'
      ? startDateTime.endOf('day').minus(endOffset).toMillis()
      : startDateTime.plus(DEFAULT_WIDTH).toMillis()
    return [start, end]
  }
  const endDateTime = DateTime.fromISO(endDate).toLocal()
  const end = endDateAttribute.AttributeType === 'date'
    ? getEndOfDayAccountingForSQLRoundingOfDateTimesBy3Milliseconds(endDateTime, endOffset)
    : endDateTime.toMillis()
  return [start, end]
}

export function doesNotHaveRequiredSettings(
  settings: CalendarTimelineSettings | undefined
): boolean {
  if (settings === undefined) {
    return true
  }
  const {
    startDateAttribute,
    endDateAttribute
  } = settings
  return startDateAttribute === null
    || !isValidStartEndDateAttribute(startDateAttribute)
    || (endDateAttribute !== null && !isValidStartEndDateAttribute(endDateAttribute))
}

export function getTimelineItemInstance(item: Pick<CustomTimelineItem, 'groupItems' | 'items'>): DomainTypeInstance {
  return item.groupItems[0] ?? item.items[0] ?? {}
}

type GroupsAndItems = [CustomTimelineGroup[], CustomTimelineItem[]]

const EMPTY_GROUPS_AND_ITEMS: GroupsAndItems = [
  [
    {
      id: NULL_GROUP_ID,
      title: ''
    }
  ],
  []
]

const formatOptions: LabelFormat = {
  year: {
    long: 'YYYY',
    mediumLong: 'YYYY',
    medium: 'YYYY',
    short: 'YY'
  },
  month: {
    long: 'MMMM YYYY',
    mediumLong: 'MMMM',
    medium: 'MMMM',
    short: 'MM/YY'
  },
  week: {
    long: 'w',
    mediumLong: 'w',
    medium: 'w',
    short: 'w'
  },
  day: {
    long: 'ddd D',
    mediumLong: 'ddd D',
    medium: 'dd D',
    short: 'DD'
  },
  hour: {
    long: 'HH:00',
    mediumLong: 'HH:00',
    medium: 'HH:00',
    short: 'HH'
  },
  minute: {
    long: 'HH:mm',
    mediumLong: 'HH:mm',
    medium: 'HH:mm',
    short: 'mm'
  }
}

export const labelFormat: Exclude<DateHeaderProps<unknown>['labelFormat'], string | undefined> = (
  [startTime, endTime],
  unit,
  labelWidth
) => {
  if (unit === 'second' || unit === 'isoWeek') {
    return ''
  }
  if (labelWidth < 50) {
    return startTime.format(formatOptions[unit].short)
  } else if (labelWidth < 100) {
    return startTime.format(formatOptions[unit].medium)
  } else if (labelWidth < 150) {
    return startTime.format(formatOptions[unit].mediumLong)
  }
  return startTime.format(formatOptions[unit].long)
}

export function getTimelineGroup(
  domainTypes: Partial<Record<string, DomainType>>,
  domainTypeChain: CalendarTimelineSettings[],
  itemChain: DomainTypeInstance[]
): CustomTimelineGroup {
  const item = itemChain[itemChain.length - 1] ?? {}
  const groupByAttribute = domainTypeChain[domainTypeChain.length - 1]?.groupByAttribute
  if (!isValidTimelineGroupByAttribute(groupByAttribute)) {
    return {
      id: NULL_GROUP_ID,
      title: ''
    }
  }
  if (groupByAttribute.AttributeType !== 'domainType') {
    const value = getValue(item, groupByAttribute)
    return {
      id: value ?? NULL_GROUP_ID,
      title: '',
      attributeValue: {
        attribute: groupByAttribute,
        value
      }
    }
  }
  const domainType = domainTypes[groupByAttribute.AttributeDomainType]
  if (domainType === undefined) {
    return {
      id: NULL_GROUP_ID,
      title: ''
    }
  }
  const value = getValue(item, groupByAttribute)
  const identifier = getDomainTypeSetting(
    domainTypes,
    domainType,
    'Identifier'
  ) ?? 'Id'
  return {
    id: String(value?.[identifier] ?? NULL_GROUP_ID),
    title: '',
    attributeValue: {
      attribute: groupByAttribute,
      value
    }
  }
}

export function getTimelineRoute(
  domainTypes: Partial<Record<string, DomainType>>,
  domainTypeChain: CalendarTimelineSettings[],
  itemChain: DomainTypeInstance[],
  overriders: DomainTypeOverrider[],
  groupId: Id,
  editingItem: CalendarProps['editingItem']
): Omit<RouteTimelineItem, PartialRouteOmitProps> | null {
  const instance = itemChain[itemChain.length - 1] ?? {}
  const timelineSettings = domainTypeChain[domainTypeChain.length - 1]
  const routeByAttribute = timelineSettings?.routeByAttribute
  if (!isValidTimelineRouteByAttribute(routeByAttribute)) {
    return null
  }
  const domainType = domainTypes[routeByAttribute.AttributeDomainType]
  if (domainType === undefined) {
    return null
  }
  const route = getValue(instance, routeByAttribute)
  if (!RouteCodec.is(route)) {
    return null
  }
  const identifier = getIdentifier(domainTypes, domainType)
  const id = String(route[identifier])
  const startValue = DateTime.fromISO(String(route['StartTime'])).toMillis()
  const endValue = DateTime.fromISO(String(route['EndTime'])).toMillis()
  return {
    id,
    type: 'route',
    subtitle: null,
    route,
    group: id === editingItem?.itemId
      ? editingItem.groupByValue?.id ?? groupId
      : groupId,
    groupItems: [],
    items: [route],
    colour: getDomainTypeSetting(domainTypes, domainType, 'Colour') ?? undefined,
    subtype: domainType,
    itemsSubtype: domainType,
    domainTypeChain: [getCalendarTimelineSettings(domainTypes, domainType, overriders)],
    preEditStartTime: startValue,
    start_time: id === editingItem?.itemId
      ? editingItem.startDate
      : startValue,
    end_time: id === editingItem?.itemId
      ? editingItem.endDate
      : endValue,
    timelineItems: []
  }
}

type PartialItemOmitProps = 'subtype' | 'itemsSubtype' | 'context' | 'canEdit' | 'moveResizeActionDetails' | 'changeGroupActionDetails'
type PartialRouteOmitProps = 'context' | 'canEdit' | 'moveResizeActionDetails' | 'changeGroupActionDetails'

export function getTimelineItem(
  domainTypes: Partial<Record<string, DomainType>>,
  domainTypeChain: CalendarTimelineSettings[],
  itemChain: DomainTypeInstance[],
  groupId: string | number,
  routeId: Id | undefined,
  editingItem: CalendarProps['editingItem'],
  settingsContext: ContextType<typeof DomainTypeSettingsContext>,
  subtypesCache: Partial<Record<string, DomainType[]>>,
  view: 'day' | 'week' | 'month'
): Omit<ItemTimelineItem, PartialItemOmitProps> | null {
  const {
    startDate,
    endDate,
    startDateAttribute,
    endDateAttribute
  } = getItemBoundaries(domainTypes, domainTypeChain, itemChain)
  const colour = getFirstNotNullValueFromChain(
    domainTypes,
    domainTypeChain,
    itemChain,
    (domainTypes, settings, item) => {
      if (settings === undefined) {
        return null
      }
      const { domainType } = settings
      const subtype = getSubtype(domainTypes, domainType, item, subtypesCache) ?? domainType
      return getDomainTypeSetting(domainTypes, subtype, 'Colour')
    }
  ) ?? undefined
  if (startDateAttribute === null || startDate === null) {
    return null
  }
  const [startValue, endValue] = getEdgeDates(
    startDateAttribute,
    endDateAttribute,
    startDate,
    endDate,
    view
  )
  const items = itemChain.slice(-1)
  const groupItems = itemChain.slice(0, -1)
  const groupItemsWithAtLeastOne = itemChain.slice(0, 1)
    .concat(itemChain.slice(1, -1))
  const id = groupItemsWithAtLeastOne
    .map((item, i) => {
      const identifier = getIdentifier(domainTypes, domainTypeChain[i]?.domainType)
      return item[identifier]
    })
    .concat([startDate, endDate, groupId, routeId ?? ''])
    .join('_')
  return {
    id,
    type: 'item',
    group: id === editingItem?.itemId
      ? editingItem.groupByValue?.id ?? groupId
      : groupId,
    routeId: routeId,
    title: groupItemsWithAtLeastOne
      .map((item, i) => {
        const domainType = domainTypeChain[i]?.domainType
        if (domainType === undefined) {
          return ''
        }
        const subtype = getSubtype(domainTypes, domainType, item, subtypesCache) ?? domainType
        return getHeading(settingsContext, domainTypes, subtype, item)
      })
      .join(PATH_SEPARATOR),
    subtitle: groupItemsWithAtLeastOne
      .map((item, i) => {
        const domainType = domainTypeChain[i]?.domainType
        if (domainType === undefined) {
          return ''
        }
        const subtype = getSubtype(domainTypes, domainType, item, subtypesCache) ?? domainType
        return getSubheading(domainTypes, subtype, item)
      })
      .join(PATH_SEPARATOR),
    start_time: id === editingItem?.itemId
      ? editingItem.startDate
      : startValue,
    end_time: id === editingItem?.itemId
      ? editingItem.endDate
      : endValue,
    colour,
    groupItems,
    items,
    domainTypeChain
  }
}

export function getGroupSortableValue(
  settingsContext: ContextType<typeof DomainTypeSettingsContext>,
  domainTypes: Partial<Record<string, DomainType>>,
  group: CustomTimelineGroup
): AttributeValue['value'] {
  if (group.id === NULL_GROUP_ID) {
    return null
  }
  return getSortableValue(
    settingsContext,
    domainTypes,
    group.attributeValue
  )
}

export function getGroupSubtype(
  domainTypes: Partial<Record<string, DomainType>>,
  domainTypeChain: CalendarTimelineSettings[],
  itemsDomainType: DomainType,
  subtypesCache: Partial<Record<string, DomainType[]>>,
  timelineItem: NonNullable<ReturnType<typeof getTimelineItem>>
): DomainType {
  const parentInstance = timelineItem.groupItems[timelineItem.groupItems.length - 1]
  if (parentInstance === undefined) {
    return itemsDomainType
  }
  const itemsAttribute = domainTypeChain[domainTypeChain.length - 2]?.itemsAttribute
  if (isNullOrUndefined(itemsAttribute)) {
    return itemsDomainType
  }
  const rootDomainType = getRootDomainType(
    domainTypes,
    domainTypeChain[domainTypeChain.length - 2]?.domainType
  )
  if (rootDomainType === null) {
    return itemsDomainType
  }
  const parentSubtype = getSubtype(
    domainTypes,
    rootDomainType,
    parentInstance,
    subtypesCache
  ) ?? rootDomainType
  const overridenItemsAttribute = getDomainTypeAttribute(
    domainTypes,
    parentSubtype,
    itemsAttribute.Name
  )
  if (overridenItemsAttribute?.AttributeType !== 'domainType') {
    return itemsDomainType
  }
  return domainTypes[overridenItemsAttribute.AttributeDomainType] ?? itemsDomainType
}

export function getSingleItemSubtype(
  domainTypes: Partial<Record<string, DomainType>>,
  itemsDomainType: DomainType,
  subtypesCache: Partial<Record<string, DomainType[]>>,
  timelineItem: NonNullable<ReturnType<typeof getTimelineItem>>
): DomainType {
  const singleInstance = timelineItem.items[0]
  if (singleInstance === undefined) {
    return itemsDomainType
  }
  const rootDomainType = getRootDomainType(
    domainTypes,
    itemsDomainType
  ) ?? itemsDomainType
  return getSubtype(
    domainTypes,
    rootDomainType,
    singleInstance,
    subtypesCache
  ) ?? rootDomainType
}

export function getItemsSubtype(
  domainTypes: Partial<Record<string, DomainType>>,
  domainTypeChain: CalendarTimelineSettings[],
  itemsDomainType: DomainType,
  subtypesCache: Partial<Record<string, DomainType[]>>,
  timelineItem: NonNullable<ReturnType<typeof getTimelineItem>>
): DomainType {
  if (timelineItem.groupItems.length > 0) {
    return getGroupSubtype(
      domainTypes,
      domainTypeChain,
      itemsDomainType,
      subtypesCache,
      timelineItem
    )
  }
  return getSingleItemSubtype(
    domainTypes,
    itemsDomainType,
    subtypesCache,
    timelineItem
  )
}

function compareSortableValues(
  value1: ReturnType<typeof getGroupSortableValue>,
  value2: ReturnType<typeof getGroupSortableValue>
): -1 | 0 | 1 {
  if (value1 === null) {
    return -1
  }
  if (value2 === null) {
    return 1
  }
  if (value1 < value2) {
    return -1
  }
  if (value1 > value2) {
    return 1
  }
  return 0
}

function makeSortGroupsFunction(
  settingsContext: ContextType<typeof DomainTypeSettingsContext>,
  domainTypes: Partial<Record<string, DomainType>>
): (group1: CustomTimelineGroup, group2: CustomTimelineGroup) => -1 | 0 | 1 {
  return (group1, group2) => {
    const group1Value = getGroupSortableValue(settingsContext, domainTypes, group1)
    const group2Value = getGroupSortableValue(settingsContext, domainTypes, group2)
    return compareSortableValues(group1Value, group2Value)
  }
}

export function getTimelineGroupsAndItems(
  domainTypes: Partial<Record<string, DomainType>>,
  domainTypeChain: CalendarTimelineSettings[],
  itemsDomainType: DomainType,
  itemChains: DomainTypeInstance[][],
  editingItem: CalendarProps['editingItem'],
  user: User | null,
  overriders: DomainTypeOverrider[],
  domainTypeContext: ContextType<typeof DomainTypeContext>,
  settingsContext: ContextType<typeof DomainTypeSettingsContext>,
  filterContext: FilterContext,
  subtypesCache: Partial<Record<string, DomainType[]>>,
  view: 'day' | 'week' | 'month',
  allGroups: CustomTimelineGroup[]
): GroupsAndItems {
  const partialTimelineRoutes: Omit<RouteTimelineItem, PartialRouteOmitProps>[] = []
  const timelineGroups: CustomTimelineGroup[] = [...allGroups]
  const partialTimelineItems: NonNullable<ReturnType<typeof getTimelineItem>>[] = []
  for (const itemChain of itemChains) {
    const newGroup = getTimelineGroup(
      domainTypes,
      domainTypeChain,
      itemChain
    )
    const existingGroup = timelineGroups.find(group => group.id === newGroup.id)
    if (existingGroup === undefined) {
      timelineGroups.push(newGroup)
    }
    const newRoute = getTimelineRoute(
      domainTypes,
      domainTypeChain,
      itemChain,
      overriders,
      newGroup.id,
      editingItem
    )
    const existingRoute = partialTimelineRoutes.find(route => route.id === newRoute?.id)
    if (newRoute !== null && existingRoute === undefined) {
      partialTimelineRoutes.push(newRoute)
    }
    const newItem = getTimelineItem(
      domainTypes,
      domainTypeChain,
      itemChain,
      newGroup.id,
      newRoute?.id,
      editingItem,
      settingsContext,
      subtypesCache,
      view
    )
    if (newItem === null) {
      continue
    }
    const existingItem = partialTimelineItems.find(item => item.id === newItem.id)
    if (existingItem === undefined) {
      partialTimelineItems.push(newItem)
    } else {
      existingItem.items.push(...newItem.items)
    }
  }
  const parentDomainTypesCache: Partial<Record<string, DomainType[]>> = {}
  const actionsCache: Partial<Record<string, [DomainType, DomainTypeAction][]>> = {}
  const timelineItems: ItemTimelineItem[] = []
  for (const timelineItem of partialTimelineItems) {
    const fullTimelineItem = getFullTimelineItem(
      domainTypeChain,
      domainTypeContext,
      domainTypes,
      itemsDomainType,
      user,
      filterContext,
      settingsContext,
      subtypesCache,
      parentDomainTypesCache,
      actionsCache,
      timelineItem
    )
    partialTimelineRoutes
      .find(timelineRoute => timelineRoute.id === fullTimelineItem.routeId)
      ?.timelineItems.push(fullTimelineItem)
    timelineItems.push(fullTimelineItem)
  }
  const timelineRoutes: RouteTimelineItem[] = []
  for (const timelineRoute of partialTimelineRoutes) {
    timelineRoutes.push(getFullTimelineRoute(
      domainTypeChain,
      domainTypeContext,
      domainTypes,
      itemsDomainType,
      user,
      filterContext,
      settingsContext,
      subtypesCache,
      parentDomainTypesCache,
      actionsCache,
      timelineRoute
    ))
  }
  if (timelineGroups.length === 0) {
    return EMPTY_GROUPS_AND_ITEMS
  }
  timelineGroups.sort(makeSortGroupsFunction(settingsContext, domainTypes))
  return [timelineGroups, [...timelineRoutes, ...timelineItems]]
}

export function getFullTimelineRoute(
  domainTypeChain: CalendarTimelineSettings[],
  domainTypeContext: ContextType<typeof DomainTypeContext>,
  domainTypes: Partial<Record<string, DomainType>>,
  itemsDomainType: DomainType,
  user: User | null,
  filterContext: FilterContext,
  settingsContext: SettingsContext,
  subtypesCache: Partial<Record<string, DomainType[]>>,
  parentDomainTypesCache: Partial<Record<string, DomainType[]>>,
  actionsCache: Partial<Record<string, [DomainType, DomainTypeAction][]>>,
  timelineRoute: Omit<RouteTimelineItem, PartialRouteOmitProps>
): RouteTimelineItem {
  const canEditStartEndDate = canEditInstancesAttributes(
    domainTypes,
    timelineRoute.subtype,
    filterContext,
    settingsContext,
    [timelineRoute.route as unknown as DomainTypeInstance],
    user,
    ['StartTime', 'EndTime']
  )
  const itemActions = actionsCache[itemsDomainType.Id]
    ?? (actionsCache[itemsDomainType.Id] = getDomainTypeActions(domainTypes, itemsDomainType))
  const itemTimelineSettings = domainTypeChain[domainTypeChain.length - 1]
  const changeGroupAction = itemActions
    .find(([actionDomainType, action]) => isChangeGroupAction(action, itemTimelineSettings))
  const changeGroupActionDetails = getTimelineItemsActionDetails(
    user,
    changeGroupAction,
    timelineRoute.timelineItems,
    filterContext,
    domainTypeContext,
    domainTypeChain[0]?.domainType ?? itemsDomainType,
    itemsDomainType,
    subtypesCache,
    parentDomainTypesCache
  )
  const canEdit = canEditStartEndDate
  const canMove = canEdit === true
  return Object.assign(
    timelineRoute,
    {
      context: undefined,
      changeGroupActionDetails,
      moveResizeActionDetails: undefined,
      canMove,
      canEdit,
      canResize: false,
      canChangeGroup: canPerformAction(changeGroupActionDetails, 'never')
    }
  )
}

export function getFullTimelineItem(
  domainTypeChain: CalendarTimelineSettings[],
  domainTypeContext: ContextType<typeof DomainTypeContext>,
  domainTypes: Partial<Record<string, DomainType>>,
  itemsDomainType: DomainType,
  user: User | null,
  filterContext: FilterContext,
  settingsContext: SettingsContext,
  subtypesCache: Partial<Record<string, DomainType[]>>,
  parentDomainTypesCache: Partial<Record<string, DomainType[]>>,
  actionsCache: Partial<Record<string, [DomainType, DomainTypeAction][]>>,
  timelineItem: Omit<ItemTimelineItem, PartialItemOmitProps>
): ItemTimelineItem {
  const itemDomainTypeContext = getItemDomainTypeContext(
    domainTypeChain,
    timelineItem.groupItems,
    domainTypeContext
  )
  const subtype = getSubtype(
    domainTypes,
    domainTypeChain[0]?.domainType ?? itemsDomainType,
    getTimelineItemInstance(timelineItem),
    subtypesCache
  ) ?? itemsDomainType
  const itemsSubtype = getItemsSubtype(
    domainTypes,
    domainTypeChain,
    itemsDomainType,
    subtypesCache,
    timelineItem
  )
  const itemActions = actionsCache[itemsSubtype.Id]
    ?? (actionsCache[itemsSubtype.Id] = getDomainTypeActions(domainTypes, itemsSubtype))
  const itemTimelineSettings = domainTypeChain[domainTypeChain.length - 1]
  const moveResizeAction = itemActions
    .find(([actionDomainType, action]) => isMoveResizeAction(action, itemTimelineSettings))
  const changeGroupAction = itemActions
    .find(([actionDomainType, action]) => isChangeGroupAction(action, itemTimelineSettings))
  const moveResizeActionDetails = getItemActionDetails(
    user,
    moveResizeAction,
    domainTypeChain,
    timelineItem.groupItems,
    timelineItem.items,
    itemDomainTypeContext,
    filterContext,
    subtypesCache,
    parentDomainTypesCache
  )
  const changeGroupActionDetails = getItemActionDetails(
    user,
    changeGroupAction,
    domainTypeChain,
    timelineItem.groupItems,
    timelineItem.items,
    itemDomainTypeContext,
    filterContext,
    subtypesCache,
    parentDomainTypesCache
  )
  const editButtonFilterContext = getItemFilterContext(
    domainTypes,
    itemDomainTypeContext ?? domainTypeContext,
    timelineItem.items,
    undefined,
    itemsSubtype,
    user
  )
  const canEditStartEndDate = canEditInstancesAttributes(
    domainTypes,
    itemsSubtype,
    editButtonFilterContext,
    settingsContext,
    timelineItem.items,
    user,
    [itemTimelineSettings?.startDateAttribute?.Name, itemTimelineSettings?.endDateAttribute?.Name]
      .filter(t.string.is)
  )
  const canEditGroup = canEditInstancesAttributes(
    domainTypes,
    itemsSubtype,
    editButtonFilterContext,
    settingsContext,
    timelineItem.items,
    user,
    [itemTimelineSettings?.startDateAttribute?.Name, itemTimelineSettings?.endDateAttribute?.Name, itemTimelineSettings?.groupByAttribute?.Name]
      .filter(t.string.is)
  )
  const canMove = canPerformAction(moveResizeActionDetails, canEditStartEndDate)
  return Object.assign(
    timelineItem,
    {
      itemsSubtype,
      subtype,
      context: itemDomainTypeContext ?? undefined,
      moveResizeActionDetails: moveResizeActionDetails?.visible === true
        ? moveResizeActionDetails
        : undefined,
      changeGroupActionDetails: changeGroupActionDetails?.visible === true
        ? changeGroupActionDetails
        : undefined,
      canMove,
      canEdit: canEditStartEndDate,
      canResize: canMove && !isNullOrUndefined(itemTimelineSettings?.endDateAttribute)
        ? 'both'
        : false,
      canChangeGroup: canPerformAction(changeGroupActionDetails, canEditGroup)
    }
  )
}

export function getMinMaxTimes(date: Date, view: CalendarProps['view']): [number, number] {
  const dateTime = DateTime.fromJSDate(date)
  switch (view) {
    case 'day':
      return [dateTime.startOf('day').toMillis(), dateTime.endOf('day').toMillis()]
    case 'week':
      return [dateTime.startOf('week').toMillis(), dateTime.endOf('week').toMillis()]
    case 'month':
    default:
      return [dateTime.startOf('month').toMillis(), dateTime.endOf('month').toMillis()]
  }
}

export function getGroupByPath(domainTypeChain: CalendarTimelineSettings[]): string | null {
  if (isNullOrUndefined(domainTypeChain[domainTypeChain.length - 1]?.groupByAttribute)) {
    return null
  }
  return domainTypeChain
    .map(timelineSettings => {
      return timelineSettings.itemsAttribute?.Name ?? timelineSettings.groupByAttribute?.Name
    })
    .filter(Boolean)
    .join('_')
}

export const TIMELINE_HOVER_EVENT_SOURCE = 'TimelineView'

export function calculateWidthForDuration(
  startTime: number,
  endTime: number,
  width: number,
  duration: number
): number {
  const widthToZoomRatio = width / (endTime - startTime)
  return duration * widthToZoomRatio
}

export function calculateXPositionForTime(
  startTime: number,
  endTime: number,
  width: number,
  time: number
): number {
  const widthToZoomRatio = width / (endTime - startTime)
  const timeOffset = time - startTime
  return timeOffset * widthToZoomRatio
}

export const StopCodec: t.Type<Stop> = t.type({
  Id: t.string,
  TravelMinutes: t.number,
  DwellMinutes: t.number,
  ArrivalTime: t.string,
  DepartureTime: t.string
})

export const RouteCodec: t.Type<Route> = t.type({
  Id: t.string,
  Origin: DomainTypeInstanceCodec,
  OriginDwellMinutes: t.number,
  StartTime: t.string,
  Destination: DomainTypeInstanceCodec,
  DestinationTravelMinutes: t.number,
  DestinationDwellMinutes: t.number,
  EndTime: t.string,
  Stops: t.array(StopCodec)
})

export function getRouteEdge(
  domainTypes: Partial<Record<string, DomainType>>,
  routeDomainType: DomainType,
  route: Route,
  edge: 'Origin' | 'Destination'
): [DomainType, DomainTypeInstance] | null {
  const attribute = getDomainTypeAttribute(domainTypes, routeDomainType, 'Origin')
  if (!makeAttributeTypeGuard('domainType', false)(attribute)) {
    return null
  }
  const domainType = domainTypes[attribute.AttributeDomainType]
  if (domainType === undefined) {
    return null
  }
  return [domainType, route[edge]]
}

export function getRouteOriginAndDestination(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType | undefined,
  route: Route
): [[DomainType, DomainTypeInstance] | null, [DomainType, DomainTypeInstance] | null] {
  if (domainType === undefined) {
    return [null, null]
  }
  return [
    getRouteEdge(domainTypes, domainType, route, 'Origin'),
    getRouteEdge(domainTypes, domainType, route, 'Destination')
  ]
}

type ContextTreeItem = Pick<ItemTimelineItem, 'items' | 'groupItems' | 'domainTypeChain' | 'context'>

function getTimelineItemContextTree(
  item: ContextTreeItem,
  actionDomainType: DomainType,
  leafNodeType: 'nested' | 'active'
): ContextTree {
  if (item.context === undefined) {
    return []
  }
  return getContextTree(
    item.context,
    actionDomainType,
    item.items,
    leafNodeType
  )
}

function getTimelineItemsContextTree(
  timelineItems: ContextTreeItem[],
  actionDomainType: DomainType
): ContextTree {
  return timelineItems.flatMap(item => getTimelineItemContextTree(item, actionDomainType, 'active'))
}

export function getTimelineItemsActionDetails(
  user: User | null,
  itemsAction: [DomainType, DomainTypeAction] | undefined,
  timelineItems: ContextTreeItem[],
  filterContext: FilterContext,
  domainTypeContext: ContextType<typeof DomainTypeContext>,
  domainType: DomainType,
  itemsDomainType: DomainType,
  childDomainTypesCache: Partial<Record<string, DomainType[]>>,
  parentDomainTypesCache: Partial<Record<string, DomainType[]>>
): ActionDetails | undefined {
  if (itemsAction === undefined) {
    return undefined
  }
  const [actionDomainType, action] = itemsAction
  const contextTree = getTimelineItemsContextTree(timelineItems, actionDomainType)
  const apiDomainType = domainTypeContext.instances[0]?.[0] ?? domainType
  const pageDomainType = itemsDomainType
  return getActionDetails(
    filterContext.domainTypes,
    apiDomainType,
    pageDomainType,
    childDomainTypesCache,
    parentDomainTypesCache,
    contextTree,
    {
      type: 'instances',
      instances: timelineItems.map(getTimelineItemInstance)
    },
    filterContext,
    user,
    actionDomainType,
    action
  )
}

export function getMoveStopToSameRoutePutRequestBody(
  route: Route,
  fromIndex: number,
  toIndex: number
): RouteMoveStopPutRequestBody | null {
  if (fromIndex === toIndex
    || fromIndex === toIndex - 1) {
    return null
  }
  const actualToIndex = toIndex > fromIndex
    ? toIndex - 1
    : toIndex
  const newStops = route.Stops.slice()
  const stopsToMove = newStops.splice(fromIndex, 1)
  newStops.splice(actualToIndex, 0, ...stopsToMove)
  return {
    ...route,
    DestinationTravelMinutes: null,
    EndTime: null,
    Stops: newStops
      .map(stop => ({
        ...stop,
        TravelMinutes: null
      }))
  }
}

export const STOP_AVATAR_SCALE = 0.825

export const CREATE_ROUTE_ACTION_NAME = 'Create Route'

export const REMOVE_FROM_ROUTE_ACTION_NAME = 'Remove From Route'