import { JSXElementConstructor } from 'react'
import { Button, ButtonTarget } from 'utils/hooks'
import ActionButton from './ActionButton'
import CreateButton from './CreateButton'
import CustomButton from './CustomButton'
import DeleteButton from './DeleteButton'
import { ButtonProps } from './DomainTypeButtons'
import EditButton from './EditButton'

interface Props {
  readonly target: ButtonTarget,
  readonly button: Button,
  readonly Component: JSXElementConstructor<ButtonProps>
  readonly onComplete?: () => void
}

export default function DomainTypeButton({
  target,
  button,
  Component,
  onComplete
}: Props): JSX.Element | null {
  switch (button.type) {
    case 'action':
      return (
        <ActionButton
          button={button}
          target={target}
          Component={Component}
          onComplete={onComplete} />
      )
    case 'create':
      return (
        <CreateButton
          button={button}
          Component={Component}
          onComplete={onComplete} />
      )
    case 'edit':
      return (
        <EditButton
          button={button}
          target={target}
          Component={Component}
          onComplete={onComplete} />
      )
    case 'delete':
      return (
        <DeleteButton
          button={button}
          target={target}
          Component={Component}
          onComplete={onComplete} />
      )
    case 'custom':
      return (
        <CustomButton
          button={button}
          target={target}
          Component={Component}
          onComplete={onComplete} />
      )
    default:
      return null
  }
}