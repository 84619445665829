import TableViewContext from 'components/pages/FindPage/TableView/TableViewContext'
import * as E from 'fp-ts/Either'
import { useCallback, useContext, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { DomainType, DomainTypeAttribute, DomainTypeInstance, ListAttribute } from 'types'
import { DomainTypeInstanceCodec } from 'utils/codecs'
import { getContextTree, getDomainTypeAttributes, getIdentifierAttribute, getPatchRequestBody, getRootDomainType, getSubtype, getValue, isListAttribute, isNonListAttribute, isNullOrUndefined, makeAttributeTypeGuard } from 'utils/helpers'
import { useApi } from './useApi'
import { useDomainTypeContextWithoutOnInvalidate } from './useDomainTypeContextWithoutOnInvalidate'

interface EditedInstance {
  readonly original: DomainTypeInstance | null
  readonly edited: DomainTypeInstance
}

interface UseEditListAttributeOutput {
  readonly isEditing: boolean
  onEditListAttribute(
    editedInstances: EditedInstance[]
  ): void
}

function getDomainTypeListAttributePatchValue(
  domainTypes: Partial<Record<string, DomainType>>,
  originalInstance: DomainTypeInstance,
  editedInstance: DomainTypeInstance,
  attribute: ListAttribute<DomainTypeAttribute>
): DomainTypeInstance[] | undefined {
  const attributeDomainType = domainTypes[attribute.AttributeDomainType]
  if (isNullOrUndefined(attributeDomainType)) {
    return undefined
  }
  const editedValue = getValue(editedInstance, attribute)
  if (isNullOrUndefined(editedValue)) {
    return undefined
  }
  const originalValue = getValue(originalInstance, attribute)
  if (originalValue === null) {
    return undefined
  }
  const identifierAttribute = getIdentifierAttribute(
    domainTypes,
    attributeDomainType
  )
  if (identifierAttribute === undefined) {
    return editedValue
  }
  const deletedItems: DomainTypeInstance[] = []
  const editedItems: DomainTypeInstance[] = []
  for (const item of originalValue) {
    const matchingEditedItem = editedValue
      .find(editedItem => editedItem[identifierAttribute.Name] === item[identifierAttribute.Name])
    if (isNullOrUndefined(matchingEditedItem)) {
      deletedItems.push({
        ...item as DomainTypeInstance,
        _delete: true
      })
      continue
    }
    editedItems.push(getPatchRequestInstance(
      domainTypes,
      attributeDomainType,
      item,
      matchingEditedItem
    ))
  }
  const newItems = editedValue
    .filter(item => {
      const matchingOriginalItem = originalValue
        .find(originalItem => originalItem[identifierAttribute.Name] === item[identifierAttribute.Name])
      return isNullOrUndefined(matchingOriginalItem)
    })
  return [
    ...deletedItems,
    ...editedItems,
    ...newItems
  ]
}

function getPatchRequestInstance(
  domainTypes: Partial<Record<string, DomainType>>,
  instanceDomainType: DomainType,
  originalInstance: DomainTypeInstance | null,
  editedInstance: DomainTypeInstance
): DomainTypeInstance {
  if (isNullOrUndefined(originalInstance)) {
    return editedInstance
  }
  const patchRequestInstance = {
    ...editedInstance
  }
  const rootDomainType = getRootDomainType(domainTypes, instanceDomainType)
  const subtype = getSubtype(
    domainTypes,
    rootDomainType ?? instanceDomainType,
    editedInstance,
    {}
  )
  const attributes = getDomainTypeAttributes(
    domainTypes,
    subtype ?? instanceDomainType
  )
  const domainTypeAttributes = attributes
    .filter(makeAttributeTypeGuard('domainType'))
  for (const attribute of domainTypeAttributes) {
    const name = attribute.Name
    if (isListAttribute(attribute)) {
      const patchValue = getDomainTypeListAttributePatchValue(
        domainTypes,
        originalInstance,
        editedInstance,
        attribute
      )
      if (!isNullOrUndefined(patchValue)) {
        patchRequestInstance[name] = patchValue
      }
      continue
    }
    if (isNonListAttribute(attribute)) {
      const attributeDomainType = domainTypes[attribute.AttributeDomainType]
      if (isNullOrUndefined(attributeDomainType)) {
        continue
      }
      const editedValue = getValue(editedInstance, attribute)
      if (isNullOrUndefined(editedValue)) {
        continue
      }
      patchRequestInstance[name] = getPatchRequestInstance(
        domainTypes,
        attributeDomainType,
        getValue(originalInstance, attribute),
        editedValue
      )
      continue
    }
  }
  return patchRequestInstance
}

export function useEditListAttribute(
  instanceDomainType: DomainType,
  onSuccess: (
    instances: DomainTypeInstance[],
    apiInstance: DomainTypeInstance
  ) => void
): UseEditListAttributeOutput {
  const [isEditing, setIsEditing] = useState(false)
  const domainTypeContext = useDomainTypeContextWithoutOnInvalidate()
  const tableViewContext = useContext(TableViewContext)
  const domainTypes = useSelector(getAllDomainTypes)
  const api = useApi()
  const pageDomainType = useMemo(() => {
    if (domainTypeContext.instances.length > 0) {
      return domainTypeContext.instances[0]?.[0]
    }
    return tableViewContext.domainType
  }, [domainTypeContext, tableViewContext])
  const onEditListAttribute = useCallback(async (
    editedInstances: EditedInstance[]
  ) => {
    if (pageDomainType === null) {
      return
    }

    const pageRootDomainType = getRootDomainType(domainTypes, pageDomainType)
    if (!api.isSignedIn || pageRootDomainType === null) {
      return
    }

    const patchRequestInstances = editedInstances.map(({ original, edited }) => {
      return getPatchRequestInstance(
        domainTypes,
        instanceDomainType,
        original,
        edited
      )
    })
    const contextWithoutTrailingInstances = {
      ...domainTypeContext,
      instances: domainTypeContext.instances.slice(0, domainTypeContext.attributes.length)
    }
    const contextTree = getContextTree(contextWithoutTrailingInstances, instanceDomainType, patchRequestInstances)
    const body = getPatchRequestBody(domainTypes, contextTree[0])
    if (body === undefined) {
      return
    }

    setIsEditing(true)
    const response = await api.patch(
      pageRootDomainType.Name,
      body
    )
    if (E.isRight(response) && DomainTypeInstanceCodec.is(response.right)) {
      onSuccess(
        editedInstances.map(({ edited }) => edited),
        response.right
      )
    }
    setIsEditing(false)
  }, [pageDomainType, domainTypes, api, domainTypeContext, instanceDomainType, onSuccess])
  return {
    isEditing,
    onEditListAttribute
  }
}