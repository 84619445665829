import { Box } from '@mui/material'
import IsInRole from 'components/auth/IsInRole'
import { RemoteDetailsPage } from 'components/pages/DetailsPage'
import ExportDomainTypesPage from 'components/pages/ExportDomainTypesPage'
import FindPage from 'components/pages/FindPage'
import HomePage from 'components/pages/HomePage'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Route, Routes, generatePath, useParams } from 'react-router-dom'
import { getAllDomainTypes } from 'state/reducers'
import { DomainType } from 'types'
import { MIN_PAGE_WIDTH, PAGE_PADDING, PAGE_URL } from 'utils/constants'
import { getDomainTypeSetting } from 'utils/helpers'
import SidePanelContainer from './SidePanelContainer'
import Unauthorised from './Unauthorised'

function DetailsPageRouteComponent({
  domainType
}: { domainType: DomainType }): JSX.Element {
  const { id = '' } = useParams<{ id: string }>()
  return (
    <SidePanelContainer name='details'>
      <RemoteDetailsPage
        key={`${domainType.Id}_${id}`}
        rootDomainType={domainType} />
    </SidePanelContainer>
  )
}

export default function PortalRoutes(): JSX.Element {
  const domainTypes = useSelector(getAllDomainTypes)
  const findableDomainTypes = useMemo(() => {
    return Object.values(domainTypes)
      .filter((type: DomainType | undefined): type is DomainType => type !== undefined)
      .filter(domainType => domainType.Find)
  }, [domainTypes])
  const apiDomainTypes = useMemo(() => {
    return Object.values(domainTypes)
      .filter((type: DomainType | undefined): type is DomainType => type !== undefined)
      .filter(domainType => domainType.Api)
  }, [domainTypes])
  const routes = useMemo(() => (
    <Routes>
      <Route
        path={PAGE_URL.HOME}
        element={
          <Box
            p={PAGE_PADDING}
            minWidth={MIN_PAGE_WIDTH}>
            <HomePage />
          </Box>
        } />
      {findableDomainTypes.map(domainType => {
        return (
          <Route
            key={domainType.Id}
            path={generatePath(PAGE_URL.FIND, {
              databaseTable: domainType.DatabaseTable ?? domainType.Name,
              name: domainType.Name
            })}
            element={
              <IsInRole
                role={getDomainTypeSetting(domainTypes, domainType, 'ViewRole') ?? null}
                Unauthorised={Unauthorised}>
                <SidePanelContainer name='preview'>
                  <SidePanelContainer name='map'>
                    <Box
                      key={domainType.Id}
                      p={PAGE_PADDING}
                      pt={0}
                      minWidth={MIN_PAGE_WIDTH}>
                      <FindPage
                        domainType={domainType} />
                    </Box>
                  </SidePanelContainer>
                </SidePanelContainer>
              </IsInRole>
            } />
        )
      })}
      {apiDomainTypes.map(domainType => {
        return (
          <Route
            key={domainType.Id}
            path={generatePath(PAGE_URL.DETAILS, {
              databaseTable: domainType.DatabaseTable ?? domainType.Name,
              name: domainType.Name,
              id: ':id'
            })}
            element={(
              <DetailsPageRouteComponent
                domainType={domainType} />
            )} />
        )
      })}
      <Route
        path={PAGE_URL.EXPORT_DOMAIN_TYPES}
        element={(
          <Box
            p={PAGE_PADDING}
            pt={0}
            minWidth={MIN_PAGE_WIDTH}>
            <ExportDomainTypesPage />
          </Box>
        )} />
    </Routes>
  ), [apiDomainTypes, domainTypes, findableDomainTypes])
  return (
    <SidePanelContainer name='root'>
      {routes}
    </SidePanelContainer>
  )
}
