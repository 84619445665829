import { Checkbox, FormControlLabel, InputAdornment } from '@mui/material'
import { DomainTypeInput } from 'components/attribute/AttributeInput'
import { useMemo } from 'react'
import { DomainTypeAttributeValue, DomainTypeInstance } from 'types'
import { Variable } from 'types/dashboard'
import { FIND_DIALOG_KEY, OPERATIONS_DIALOG_KEY } from 'utils/constants'

interface Props {
  variable: Variable
  value: DomainTypeInstance[] | undefined
  onChange(value: DomainTypeInstance[]): void
}

export default function VariableInput({
  variable,
  value,
  onChange
}: Props): JSX.Element {
  const attributeValue = useMemo<DomainTypeAttributeValue>(() => {
    return {
      attribute: {
        Name: variable.Name,
        Title: variable.Label,
        AttributeType: 'domainType',
        AttributeDomainType: variable.DomainType,
        Required: false,
        List: true
      },
      value: value ?? []
    }
  }, [value, variable.DomainType, variable.Label, variable.Name])
  const checked = (value?.length ?? 0) === 0
  return (
    <DomainTypeInput
      attributeValue={attributeValue}
      onChange={attributeValue => {
        if (Array.isArray(attributeValue.value)) {
          onChange(attributeValue.value)
        } else {
          onChange([])
        }
      }}
      textFieldProps={{
        variant: 'outlined',
        size: 'medium',
        onKeyDown: event => {
          if ((event.key === OPERATIONS_DIALOG_KEY || event.key === FIND_DIALOG_KEY)) {
            event.stopPropagation()
          }
        },
        InputProps: checked
          ? {
            startAdornment: (
              <InputAdornment
                position='start'
                sx={{ ml: 1 }}>
                <FormControlLabel
                  onClick={event => {
                    event.preventDefault()
                  }}
                  control={(
                    <Checkbox
                      checked
                      size='small' />
                  )}
                  label='All' />
              </InputAdornment>
            )
          }
          : undefined
      }}
      readOnly={false} />
  )
}