import { NonListStringAttributeValue } from 'types'
import CellTooltip from './CellTooltip'
import { NarrowedProps } from './AttributeCell'
import { Link, useTheme } from '@mui/material'

export default function StringCell({
  attributeValue,
  columnWidth
}: NarrowedProps<NonListStringAttributeValue>): JSX.Element | null {
  const theme = useTheme()
  if (attributeValue.value === null) {
    return null
  }
  if (attributeValue.attribute.Format === 'gps') {
    return (
      <Link
        color={theme.palette.muiPrimary.main}
        href={`https://google.com/maps/search/${attributeValue.value}`}
        target='_blank'>
        View In Google Maps
      </Link>
    )
  }
  return (
    <CellTooltip
      value={attributeValue.value}
      columnWidth={columnWidth} />
  )
}
