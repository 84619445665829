import { Box } from '@mui/material'
import DomainTypeIcon from 'components/domainType/DomainTypeIcon'
import DomainTypeTooltip from 'components/domainType/DomainTypeTooltip'
import { useState } from 'react'
import { Id } from 'react-calendar-timeline'
import { useDrag } from 'react-dnd'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { getIdentifier } from 'utils/helpers'
import { STOP_AVATAR_SCALE, TIMELINE_HOVER_EVENT_SOURCE, calculateXPositionForTime, getTimelineItemInstance } from '../helpers'
import { ItemTimelineItem, Route, StopDragData } from '../types'

interface Props {
  readonly route: Route
  readonly item: ItemTimelineItem
  readonly index: number
  readonly routeStartTime: number
  readonly routeEndTime: number
  readonly editingOffset: number
  readonly width: number
  readonly time: number
  onStopDragStart(id: Id): void
  onStopDragEnd(): void
}

export default function RouteStop({
  route,
  item,
  index,
  routeStartTime,
  routeEndTime,
  editingOffset,
  width,
  time,
  onStopDragStart,
  onStopDragEnd
}: Props): JSX.Element {
  const domainTypes = useSelector(getAllDomainTypes)
  const instance = getTimelineItemInstance(item)
  const identifier = getIdentifier(domainTypes, item.subtype)
  const id = String(instance[identifier])
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [{ opacity }, dragRef] = useDrag<StopDragData, unknown, { opacity: number }>(() => ({
    type: 'stop',
    item() {
      setTooltipOpen(false)
      return {
        route,
        index,
        item
      }
    },
    end() {
      onStopDragEnd()
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
      opacity: monitor.isDragging() ? 0 : 1
    })
  }), [route, index, item, onStopDragEnd])
  return (
    <DomainTypeTooltip
      domainType={item.subtype}
      instance={instance}
      enableHeadingLink
      hoverEventSource={TIMELINE_HOVER_EVENT_SOURCE}
      open={tooltipOpen}
      onOpen={() => setTooltipOpen(true)}
      onClose={() => setTooltipOpen(false)}>
      <Box
        ref={dragRef}
        className={`${item.subtype.DatabaseTable}-${id}`}
        onMouseEnter={() => {
          onStopDragStart(route.Id)
        }}
        onMouseLeave={() => {
          onStopDragEnd()
        }}
        sx={{
          position: 'absolute',
          borderRadius: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '0.75rem',
          left: calculateXPositionForTime(
            routeStartTime,
            routeEndTime,
            width,
            time + editingOffset
          ),
          opacity,
          cursor: 'move'
        }}>
        <DomainTypeIcon
          domainType={item.subtype}
          avatar
          filled
          avatarScale={STOP_AVATAR_SCALE} />
      </Box>
    </DomainTypeTooltip>
  )
}