import DeleteDialog from 'components/domainType/DeleteDialog'
import { JSXElementConstructor, useCallback, useContext, useState } from 'react'
import { DomainTypeContext } from 'utils/context'
import { ButtonTarget, DeleteButton as DeleteButtonType } from 'utils/hooks'
import { ButtonProps } from './DomainTypeButtons'

interface Props {
  readonly button: DeleteButtonType
  readonly target: ButtonTarget
  readonly Component: JSXElementConstructor<ButtonProps>
  readonly onComplete?: () => void
}

export default function DeleteButton({
  button,
  target,
  Component,
  onComplete
}: Props): JSX.Element {
  const [dialogOpen, setDialogOpen] = useState(false)
  const context = useContext(DomainTypeContext)
  const onCloseDialog = useCallback(() => {
    setDialogOpen(false)
    if (onComplete !== undefined) {
      onComplete()
    }
  }, [setDialogOpen, onComplete])
  const onSuccess = useCallback(async () => {
    context.onInvalidate?.()
    onCloseDialog()
  }, [context, onCloseDialog])
  return (
    <>
      <Component
        text={button.name}
        icon='delete'
        onClick={() => setDialogOpen(true)}
        disabled={button.disabled || (target.type === 'instances' && target.instances.length === 0)} />
      <DeleteDialog
        open={dialogOpen}
        domainType={button.domainType}
        target={target}
        onClose={onCloseDialog}
        onDeleteSuccess={onSuccess} />
    </>
  )
}
