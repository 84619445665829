import { ClickAwayListener, Icon, ListItemIcon, ListItemText, MenuItem, MenuList, Popover } from '@mui/material'
import { GridRow, GridRowProps, GridSelectionModel } from '@mui/x-data-grid'
import DomainTypeButtons from 'components/domainType/DomainTypeButtons'
import { ComponentProps, MouseEvent, memo, useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { DomainType, DomainTypeInstance } from 'types'
import { getDomainTypeSetting, getSubtype } from 'utils/helpers'
import { ButtonTarget, useNavigate, useSubtypesCache } from 'utils/hooks'

interface CustomGridRowProps extends GridRowProps {
  items: DomainTypeInstance[]
  domainType: DomainType
  checkedItems: DomainTypeInstance[]
  selectionTarget: ButtonTarget
  onSelectionModelChange(ids: GridSelectionModel): void
}

const components: ComponentProps<typeof DomainTypeButtons>['components'] = {
  Container: MenuList,
  Button: props => (
    <MenuItem
      disabled={props.disabled}
      onClick={props.onClick}>
      <ListItemIcon>
        <Icon>{props.icon}</Icon>
      </ListItemIcon>
      <ListItemText>{props.text}</ListItemText>
    </MenuItem>
  ),
  Empty: () => (
    <MenuItem
      disabled>
      <ListItemText>No Actions</ListItemText>
    </MenuItem>
  )
}

export default memo(function CustomGridRow(params: CustomGridRowProps): JSX.Element {
  const { items, domainType, checkedItems, selectionTarget, onSelectionModelChange, ...gridRowParams } = params
  const domainTypes = useSelector(getAllDomainTypes)
  const identifier = getDomainTypeSetting(domainTypes, domainType, 'Identifier') ?? 'Id'
  const instance = useMemo(() => {
    return items.find(i => String(i[identifier]) === String(gridRowParams.rowId))
  }, [items, identifier, gridRowParams.rowId])
  const subtypesCache = useSubtypesCache()
  const subtype = useMemo(() => {
    return instance !== undefined
      ? getSubtype(domainTypes, domainType, instance, subtypesCache) ?? domainType
      : domainType
  }, [domainType, domainTypes, instance, subtypesCache])
  const navigate = useNavigate()
  const isApiDomainType = getDomainTypeSetting(domainTypes, domainType, 'Api') ?? false
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number
    mouseY: number
  } | null>(null)
  const handleContextMenu = useCallback((event: MouseEvent<HTMLDivElement>) => {
    const id = String(gridRowParams.rowId)
    const checkedItemIds = checkedItems
      .map(i => String(i[identifier]))
    if (checkedItemIds.length > 0
      && !checkedItemIds.includes(id)) {
      onSelectionModelChange(checkedItemIds.concat([id]))
    }
    event.preventDefault()
    setContextMenu(
      contextMenu === null
        ? {
          mouseX: event.clientX - 2,
          mouseY: event.clientY - 4
        }
        : null
    )
  }, [checkedItems, contextMenu, gridRowParams.rowId, identifier, onSelectionModelChange])
  const handleClose = useCallback(() => {
    setContextMenu(null)
  }, [])
  const additionalButtons = useMemo(() => {
    return (isApiDomainType
      && instance !== undefined
      && checkedItems.length <= 1)
      ? [
        {
          text: 'Open In New Tab',
          icon: 'open_in_new',
          onClick: () => navigate.toDetailsPage(domainType, instance, true)
        }
      ]
      : undefined
  }, [checkedItems.length, domainType, instance, isApiDomainType, navigate])
  const target = useMemo<ButtonTarget>(() => {
    return checkedItems.length === 0
      ? {
        type: 'instances',
        instances: instance === undefined
          ? []
          : [instance]
      }
      : selectionTarget
  }, [checkedItems.length, instance, selectionTarget])
  const buttons = useMemo(() => {
    return (
      <ClickAwayListener
        onClickAway={handleClose}>
        <Popover
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference='anchorPosition'
          anchorPosition={contextMenu !== null
            ? {
              top: contextMenu.mouseY,
              left: contextMenu.mouseX
            }
            : undefined}>
          <DomainTypeButtons
            domainType={checkedItems.length === 0
              ? subtype
              : domainType}
            target={target}
            on='TableRow'
            components={components}
            additionalButtons={additionalButtons}
            onComplete={handleClose} />
        </Popover>
      </ClickAwayListener>
    )
  }, [additionalButtons, checkedItems.length, contextMenu, domainType, handleClose, subtype, target])
  if (instance === undefined) {
    return (
      <GridRow {...gridRowParams} />
    )
  }
  return (
    <>
      <GridRow
        {...gridRowParams}
        onContextMenu={handleContextMenu} />
      {buttons}
    </>
  )
})