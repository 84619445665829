import { Box } from '@mui/material'
import DomainTypeIcon from 'components/domainType/DomainTypeIcon'
import DomainTypeTooltip from 'components/domainType/DomainTypeTooltip'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { DomainType, DomainTypeInstance } from 'types'
import { getIdentifier } from 'utils/helpers'
import { STOP_AVATAR_SCALE, TIMELINE_HOVER_EVENT_SOURCE, calculateXPositionForTime } from '../helpers'

interface Props {
  readonly colour: string | null | undefined
  readonly instance: DomainTypeInstance
  readonly domainType: DomainType
  readonly routeStartTime: number
  readonly routeEndTime: number
  readonly width: number
  readonly time: number
}

export default function RouteEdge({
  colour,
  instance,
  domainType,
  routeStartTime,
  routeEndTime,
  width,
  time
}: Props): JSX.Element {
  const domainTypes = useSelector(getAllDomainTypes)
  const identifier = getIdentifier(domainTypes, domainType)
  return (
    <DomainTypeTooltip
      domainType={domainType}
      instance={instance}
      enableHeadingLink
      hoverEventSource={TIMELINE_HOVER_EVENT_SOURCE}>
      <Box
        className={`${domainType.DatabaseTable}-${String(instance[identifier])}`}
        sx={{
          position: 'absolute',
          borderRadius: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '0.75rem',
          left: calculateXPositionForTime(
            routeStartTime,
            routeEndTime,
            width,
            time
          )
        }}>
        <DomainTypeIcon
          domainType={domainType}
          avatar
          filled
          avatarScale={STOP_AVATAR_SCALE} />
      </Box>
    </DomainTypeTooltip>
  )
}