import FileSaver from 'file-saver'
import * as E from 'fp-ts/Either'
import { constant, flow, pipe } from 'fp-ts/lib/function'
import * as RTE from 'fp-ts/ReaderTaskEither'
import * as TE from 'fp-ts/TaskEither'
import * as t from 'io-ts'
import { ActionResponse, AdalProperties, ApiError, DomainType, DomainTypeInstance, Filter, JobStatus, Query, ResetPasswordRequest, SearchResponse, SignInRequest, Sort, User, What3Words } from 'types'
import { ActionResponseCodec, AdalPropertiesCodec, ApiErrorCodec, DomainTypeInstanceCodec, JobStatusCodec, PathErrorsCodec, SearchResponseCodec, ServerErrorCodec, UserCodec, What3WordsCodec } from 'utils/codecs'
import { NumberRangeCodec } from 'utils/codecs/dataform'
import { createApiSearchFilters, FilterContext, getAnyAllFilters } from 'utils/filters'
import { partialSwitchCase, requiresFormsAuthenticationCookieRefresh } from 'utils/helpers'

export interface RequestContext {
  readonly signal?: AbortSignal
}

export interface NotSignedInApiContext extends RequestContext {
  readonly ACCOUNT_URL: string
  readonly OWIN_URL: string
  readonly PASSWORD_URL: string
  readonly fetch: typeof fetch
}

export interface SignedInApiContext extends RequestContext {
  readonly V1_URL: string
  readonly V3_URL: string
  readonly ADMIN_URL: string
  readonly PASSWORD_URL: string
  readonly AUTH_URL: string
  readonly fetch: typeof fetch
  readonly filterContext: FilterContext
  readonly token: string
  readonly companyId: string | null
  updateStateIfEdited(domainTypeName: string, instance: DomainTypeInstance): void
}

type ApiContext = NotSignedInApiContext | SignedInApiContext

interface Endpoint<TParameters extends unknown[], TContext extends ApiContext, TResponse> {
  (...args: TParameters): RTE.ReaderTaskEither<TContext, ApiError, TResponse>
}

interface PromisifiedEndpoint<TParameters extends unknown[], TResponse> {
  (...args: TParameters): Promise<E.Either<ApiError, TResponse>>
}

export type Promisify<T> = T extends Endpoint<infer TParameters, never, infer TResponse>
  ? PromisifiedEndpoint<TParameters, TResponse>
  : never

export function promisify<TParameters extends unknown[], TContext extends ApiContext, TResponse>(
  endpoint: Endpoint<TParameters, TContext, TResponse>,
  context: TContext
): (...args: TParameters) => Promise<E.Either<ApiError, TResponse>> {
  return flow(
    endpoint,
    rte => rte(context),
    te => te()
  )
}

function getJson<TContext extends ApiContext>(
  response: Response
): RTE.ReaderTaskEither<TContext, ApiError, unknown> {
  return () => TE.tryCatch(
    () => response.json().catch(swallowAbortErrors),
    invalidResponse
  )
}

function getContentDispositionFileName(response: Response): string | null {
  return response.headers.get('Content-Disposition')
    ?.split('filename=')[1]
    ?.split(';')[0]
    ?.replaceAll('"', '') ?? null
}

function getBlob<TContext extends ApiContext>(
  response: Response
): RTE.ReaderTaskEither<TContext, ApiError, [Blob, string | null]> {
  return () => TE.tryCatch(
    async (): Promise<[Blob, string | null]> => [
      await response.blob().catch(swallowAbortErrors),
      getContentDispositionFileName(response)
    ],
    invalidResponse
  )
}

function invalidResponse(): ApiError {
  return {
    errorCode: 'api.errorCode.invalidResponse'
  }
}

function invalidResponseRTE(response: Response): RTE.ReaderTaskEither<ApiContext, ApiError, never> {
  return RTE.left({
    errorCode: 'api.errorCode.invalidResponse',
    status: response.status
  })
}

function apiError(apiError: ApiError): (response: Response) => RTE.ReaderTaskEither<ApiContext, ApiError, never> {
  return response => RTE.left({
    ...apiError,
    status: response.status
  })
}

function decodePossibleApiError<TContext extends ApiContext, T>(
  codec: t.Type<T>
): (rte: RTE.ReaderTaskEither<TContext, ApiError, unknown>) => RTE.ReaderTaskEither<TContext, ApiError, T> {
  return RTE.chainEitherK(json => {
    if (codec.is(json)) {
      return E.right(json)
    } else if (ApiErrorCodec.is(json)) {
      return E.left(json)
    }
    return E.left({ errorCode: 'api.errorCode.invalidResponse' })
  })
}

function decodePossibleServerError<TContext extends ApiContext, T>(
  codec: t.Type<T>
): (rte: RTE.ReaderTaskEither<TContext, ApiError, unknown>) => RTE.ReaderTaskEither<TContext, ApiError, T> {
  return RTE.chainEitherK(json => {
    if (ServerErrorCodec.is(json) && Object.keys(json).length === 1) {
      return E.left({
        errorCode: json.Message
      })
    } else if (codec.is(json)) {
      return E.right(json)
    }
    return E.left({ errorCode: 'api.errorCode.invalidResponse' })
  })
}

function decode<TContext extends ApiContext, T>(
  codec: t.Type<T>
): (rte: RTE.ReaderTaskEither<TContext, ApiError, unknown>) => RTE.ReaderTaskEither<TContext, ApiError, T> {
  return RTE.chainEitherK(json => {
    if (codec.is(json)) {
      return E.right(json)
    }
    return E.left({ errorCode: 'api.errorCode.invalidResponse' })
  })
}

function makeFilesFormData(files: File[]): FormData {
  const formData = new FormData()
  for (let i = 0; i < files.length; i++) {
    const file = files[i]
    if (file !== undefined) {
      formData.append(`file[${i}]`, file)
    }
  }
  return formData
}

function saveBlob<TContext extends ApiContext>(
  fileName?: string
): (rte: RTE.ReaderTaskEither<TContext, ApiError, [Blob, string | null]>) => RTE.ReaderTaskEither<TContext, ApiError, void> {
  return RTE.map(([blob, contentDispositionFileName]) => {
    FileSaver.saveAs(blob, fileName ?? contentDispositionFileName ?? 'file')
    return undefined
  })
}

function returnContent<T>(
  content: T
): <TContext extends ApiContext>(response: Response) => RTE.ReaderTaskEither<TContext, ApiError, T> {
  return response => apiContext => TE.right(content)
}

function updateStateIfEdited(
  rootDomainType: string
): (rte: RTE.ReaderTaskEither<SignedInApiContext, ApiError, DomainTypeInstance>) => RTE.ReaderTaskEither<SignedInApiContext, ApiError, DomainTypeInstance> {
  return RTE.chain(instance => RTE.asks(apiContext => {
    apiContext.updateStateIfEdited(rootDomainType, instance)
    return instance
  }))
}

function handle4xx<TContext extends ApiContext>(
  response: Response
): RTE.ReaderTaskEither<TContext, ApiError, never> {
  return pipe(
    response,
    getJson,
    decodePossibleServerError(PathErrorsCodec),
    RTE.chainEitherK((pathErrors): E.Either<ApiError, never> => E.left({
      errorCode: 'api.errorCode.invalidRequest',
      pathErrors
    }))
  )
}

function swallowAbortErrors(error: unknown) {
  if (error instanceof DOMException
    && error.name === 'AbortError') {
    return new Promise<never>(resolve => undefined)
  }
  throw error
}

interface ApiCallParameters<TContext extends ApiContext, TResponse> {
  readonly uri: string | ((apiContext: TContext) => string)
  readonly request: RequestInit | ((apiContext: TContext) => RequestInit)
  readonly handlers: {
    [Value in number]?: (value: Response) => RTE.ReaderTaskEither<TContext, ApiError, TResponse>
  }
}

function apiCall<TContext extends ApiContext, TResponse>({
  uri,
  request,
  handlers
}: ApiCallParameters<TContext, TResponse>): RTE.ReaderTaskEither<TContext, ApiError, TResponse> {
  return pipe(
    (context: TContext) => TE.tryCatch(
      () => context.fetch(
        typeof uri === 'function'
          ? uri(context)
          : uri,
        {
          ...typeof request === 'function'
            ? request(context)
            : request,
          signal: context.signal,
          credentials: 'include'
        }
      ).catch(swallowAbortErrors),
      invalidResponse
    ),
    RTE.chain(
      partialSwitchCase<Response, 'status', number, RTE.ReaderTaskEither<TContext, ApiError, TResponse>>(
        'status',
        handlers,
        invalidResponseRTE
      )
    )
  )
}

export function signIn(
  details: SignInRequest
): RTE.ReaderTaskEither<NotSignedInApiContext, ApiError, User> {
  return apiCall<NotSignedInApiContext, User>({
    uri: ({ ACCOUNT_URL }) => `${ACCOUNT_URL}SignInv2`,
    request: {
      method: 'POST',
      body: JSON.stringify(details),
      headers: {
        'Content-Type': 'application/json'
      }
    },
    handlers: {
      200: flow(
        getJson,
        decodePossibleApiError(UserCodec)
      ),
      400: flow(
        getJson,
        decodePossibleApiError(UserCodec)
      )
    }
  })
}

export function getAdalProperties(): RTE.ReaderTaskEither<NotSignedInApiContext, ApiError, AdalProperties> {
  return apiCall<NotSignedInApiContext, AdalProperties>({
    uri: ({ OWIN_URL }) => `${OWIN_URL}GetAdalProperties`,
    request: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    },
    handlers: {
      200: flow(
        getJson,
        decodePossibleApiError(AdalPropertiesCodec)
      )
    }
  })
}

export function adalSignIn(
  token: string
): RTE.ReaderTaskEither<NotSignedInApiContext, ApiError, User> {
  return apiCall<NotSignedInApiContext, User>({
    uri: ({ OWIN_URL }) => `${OWIN_URL}CallbackV6`,
    request: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ token })
    },
    handlers: {
      200: flow(
        getJson,
        decodePossibleApiError(UserCodec)
      )
    }
  })
}

export function downloadFile(
  uri: string | ((apiContext: SignedInApiContext) => string),
  fileName?: string
): RTE.ReaderTaskEither<SignedInApiContext, ApiError, void> {
  return apiCall<SignedInApiContext, void>({
    uri,
    request: ({ token }) => ({
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }),
    handlers: {
      200: flow(
        getBlob,
        saveBlob(fileName)
      )
    }
  })
}

export function uploadFiles(
  uri: string | ((apiContext: SignedInApiContext) => string),
  files: File[]
): RTE.ReaderTaskEither<SignedInApiContext, ApiError, File[]> {
  return apiCall<SignedInApiContext, File[]>({
    uri,
    request: ({ token }) => ({
      method: 'POST',
      body: makeFilesFormData(files),
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    }),
    handlers: {
      200: returnContent(files)
    }
  })
}

const LimitedSearchPageSizeCodec = t.number.pipe(NumberRangeCodec(0, 400))
type LimitedSearchPageSize = t.TypeOf<typeof LimitedSearchPageSizeCodec>
const maxPageSize = 400 as LimitedSearchPageSize

export const limitSearchPageSize = flow<ReadonlyArray<number>, E.Either<unknown, LimitedSearchPageSize>, LimitedSearchPageSize>(
  LimitedSearchPageSizeCodec.decode,
  E.match(
    constant(maxPageSize),
    t.identity
  )
)

export function search(
  databaseTable: string,
  domainTypeName: string,
  any: Filter[],
  all: Filter[],
  sorts: Sort[],
  page: number,
  pageSize: LimitedSearchPageSize,
  bypassSearchIndex = false,
  bypassDomainTypeFilters?: string[] | null
): RTE.ReaderTaskEither<SignedInApiContext, ApiError, SearchResponse> {
  return apiCall<SignedInApiContext, SearchResponse>({
    uri: ({ V3_URL }) => `${V3_URL}search/${databaseTable.toLowerCase()}?page=${page - 1}&pageSize=${pageSize}`,
    request: ({ filterContext, token }) => ({
      method: 'POST',
      body: JSON.stringify({
        domainTypeName,
        any: createApiSearchFilters(any, filterContext),
        all: createApiSearchFilters(all, filterContext),
        sorts,
        bypassSearchIndex,
        bypassDomainTypeFilters: bypassDomainTypeFilters ?? []
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }),
    handlers: {
      200: flow(
        getJson,
        decode(SearchResponseCodec)
      )
    }
  })
}

export function get(
  rootDomainType: string,
  id: string
): RTE.ReaderTaskEither<SignedInApiContext, ApiError, DomainTypeInstance> {
  return apiCall<SignedInApiContext, DomainTypeInstance>({
    uri: ({ V3_URL }) => `${V3_URL}${rootDomainType.toLowerCase()}?id=${encodeURIComponent(id)}`,
    request: ({ token }) => ({
      headers: {
        Authorization: `Bearer ${token}`
      }
    }),
    handlers: {
      200: flow(
        getJson,
        decode(DomainTypeInstanceCodec)
      )
    }
  })
}

export function all(
  rootDomainType: string
): RTE.ReaderTaskEither<SignedInApiContext, ApiError, DomainTypeInstance[]> {
  return apiCall<SignedInApiContext, DomainTypeInstance[]>({
    uri: ({ V3_URL }) => `${V3_URL}${rootDomainType.toLowerCase()}/all`,
    request: ({ token }) => ({
      headers: {
        Authorization: `Bearer ${token}`
      }
    }),
    handlers: {
      200: flow(
        getJson,
        decode(t.array(DomainTypeInstanceCodec))
      )
    }
  })
}

export function post(
  rootDomainType: string,
  instance: DomainTypeInstance
): RTE.ReaderTaskEither<SignedInApiContext, ApiError, DomainTypeInstance> {
  return apiCall<SignedInApiContext, DomainTypeInstance>({
    uri: ({ V3_URL }) => `${V3_URL}${rootDomainType.toLowerCase()}/`,
    request: ({ token }) => ({
      body: JSON.stringify(instance),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }),
    handlers: {
      201: flow(
        getJson,
        decodePossibleServerError(DomainTypeInstanceCodec),
        updateStateIfEdited(rootDomainType)
      ),
      400: handle4xx,
      409: handle4xx
    }
  })
}

export function put(
  rootDomainType: string,
  instance: DomainTypeInstance
): RTE.ReaderTaskEither<SignedInApiContext, ApiError, DomainTypeInstance> {
  return apiCall<SignedInApiContext, DomainTypeInstance>({
    uri: ({ V3_URL }) => `${V3_URL}${rootDomainType.toLowerCase()}/`,
    request: ({ token }) => ({
      body: JSON.stringify(instance),
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }),
    handlers: {
      200: flow(
        getJson,
        decodePossibleServerError(DomainTypeInstanceCodec),
        updateStateIfEdited(rootDomainType)
      ),
      400: handle4xx,
      409: handle4xx
    }
  })
}

export function resetPassword(
  request: ResetPasswordRequest
): RTE.ReaderTaskEither<NotSignedInApiContext, ApiError, void> {
  return apiCall<NotSignedInApiContext, void>({
    uri: ({ PASSWORD_URL }) => `${PASSWORD_URL}setnew`,
    request: {
      method: 'PUT',
      body: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json'
      }
    },
    handlers: {
      200: returnContent(undefined),
      400: handle4xx,
      409: handle4xx
    }
  })
}

export function forgotPassword(
  username: string
): RTE.ReaderTaskEither<NotSignedInApiContext, ApiError, void> {
  return apiCall<NotSignedInApiContext, void>({
    uri: ({ PASSWORD_URL }) => `${PASSWORD_URL}forgot`,
    request: {
      method: 'PUT',
      body: JSON.stringify({
        username
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    },
    handlers: {
      200: returnContent(undefined)
    }
  })
}

export function getLoginType(
  id: string
): RTE.ReaderTaskEither<SignedInApiContext, ApiError, 'AD' | 'Local'> {
  return pipe(
    refreshFormsAuthenticationCookie(),
    RTE.chain(() => apiCall<SignedInApiContext, 'AD' | 'Local'>({
      uri: ({ AUTH_URL }) => `${AUTH_URL}logintype?id=${encodeURIComponent(id)}`,
      request: {
        method: 'GET'
      },
      handlers: {
        200: flow(
          getJson,
          decode(t.type({
            LoginType: t.union([
              t.literal('AD'),
              t.literal('Local')
            ])
          })),
          RTE.map(({ LoginType }) => LoginType)
        )
      }
    }))
  )
}

export function refreshFormsAuthenticationCookie(): RTE.ReaderTaskEither<SignedInApiContext, ApiError, void> {
  if (!requiresFormsAuthenticationCookieRefresh()) {
    return RTE.right(undefined)
  }
  return apiCall<SignedInApiContext, void>({
    uri: ({ V3_URL }) => `${V3_URL}person/refreshFormsAuthenticationCookie`,
    request: ({ token }) => ({
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }),
    handlers: {
      200: returnContent(undefined)
    }
  })
}

export function changeMyPassword(
  oldPassword: string,
  password: string
): RTE.ReaderTaskEither<SignedInApiContext, ApiError, void> {
  return apiCall<SignedInApiContext, void>({
    uri: ({ PASSWORD_URL }) => `${PASSWORD_URL}change`,
    request: {
      method: 'PUT',
      body: JSON.stringify({
        oldPassword,
        password
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    },
    handlers: {
      200: returnContent(undefined),
      400: handle4xx,
      403: apiError({
        errorCode: 'api.errorCode.incorrectPassword'
      }),
      404: apiError({
        errorCode: 'api.errorCode.personNotFound'
      })
    }
  })
}

export function changePassword(
  id: string,
  password: string
): RTE.ReaderTaskEither<SignedInApiContext, ApiError, void> {
  return apiCall<SignedInApiContext, void>({
    uri: ({ PASSWORD_URL }) => `${PASSWORD_URL}change?id=${encodeURIComponent(id)}`,
    request: {
      method: 'PUT',
      body: JSON.stringify({
        password
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    },
    handlers: {
      200: returnContent(undefined),
      400: handle4xx,
      404: apiError({
        errorCode: 'api.errorCode.personNotFound'
      })
    }
  })
}

export function patch(
  rootDomainType: string,
  instance: DomainTypeInstance
): RTE.ReaderTaskEither<SignedInApiContext, ApiError, DomainTypeInstance> {
  return apiCall<SignedInApiContext, DomainTypeInstance>({
    uri: ({ V3_URL }) => `${V3_URL}${rootDomainType.toLowerCase()}/`,
    request: ({ token }) => ({
      body: JSON.stringify(instance),
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }),
    handlers: {
      200: flow(
        getJson,
        decode(DomainTypeInstanceCodec),
        updateStateIfEdited(rootDomainType)
      ),
      400: handle4xx,
      409: handle4xx
    }
  })
}

export function deleteById(
  rootDomainType: string,
  id: string
): RTE.ReaderTaskEither<SignedInApiContext, ApiError, void> {
  return apiCall<SignedInApiContext, void>({
    uri: ({ V3_URL }) => `${V3_URL}${rootDomainType.toLowerCase()}?id=${encodeURIComponent(id)}`,
    request: ({ token }) => ({
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }),
    handlers: {
      200: returnContent(undefined),
      400: handle4xx,
      409: handle4xx
    }
  })
}

export function deleteByQuery(
  rootDomainType: string,
  domainType: DomainType,
  query: Query,
  expectedTotal: number
): RTE.ReaderTaskEither<SignedInApiContext, ApiError, ActionResponse> {
  return apiCall<SignedInApiContext, ActionResponse>({
    uri: ({ V3_URL }) => `${V3_URL}${rootDomainType.toLowerCase()}/deleteByQuery`,
    request: ({ token, filterContext }) => {
      const [anyFilters, allFilters] = getAnyAllFilters(
        filterContext.domainTypes,
        domainType,
        query.FilterLinkOperator ?? 'and',
        query.Filters,
        query.SearchText ?? '',
        query.AdditionalFilters
      )
      return {
        method: 'POST',
        body: JSON.stringify({
          DomainType: domainType.Id,
          ExpectedTotal: expectedTotal,
          Search: {
            domainTypeName: domainType.Name,
            any: createApiSearchFilters(anyFilters, filterContext),
            all: createApiSearchFilters(allFilters, filterContext),
            sorts: query.Sorts,
            bypassSearchIndex: query.BypassSearchIndex,
            bypassDomainTypeFilters: query.BypassDomainTypeFilters
          }
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }
    },
    handlers: {
      200: flow(
        getJson,
        decode(ActionResponseCodec)
      ),
      400: handle4xx,
      409: handle4xx
    }
  })
}

export function action(
  rootDomainTypeName: string,
  parameters: DomainTypeInstance,
  domainType: DomainType,
  name: string,
  paths: unknown[]
): RTE.ReaderTaskEither<SignedInApiContext, ApiError, ActionResponse> {
  return apiCall<SignedInApiContext, ActionResponse>({
    uri: ({ V3_URL }) => `${V3_URL}${rootDomainTypeName.toLowerCase()}/action`,
    request: ({ token, companyId }) => ({
      body: JSON.stringify({
        DomainType: domainType.Id,
        Name: name,
        Parameters: parameters,
        Paths: paths,
        CompanyId: companyId
      }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }),
    handlers: {
      200: flow(
        getJson,
        decode(ActionResponseCodec)
      ),
      400: handle4xx
    }
  })
}

export function actionByQuery(
  rootDomainTypeName: string,
  parameters: DomainTypeInstance,
  domainType: DomainType,
  name: string,
  query: Query,
  expectedTotal: number
): RTE.ReaderTaskEither<SignedInApiContext, ApiError, ActionResponse> {
  return apiCall<SignedInApiContext, ActionResponse>({
    uri: ({ V3_URL }) => `${V3_URL}${rootDomainTypeName.toLowerCase()}/actionByQuery`,
    request: ({ token, companyId, filterContext }) => {
      const [anyFilters, allFilters] = getAnyAllFilters(
        filterContext.domainTypes,
        domainType,
        query.FilterLinkOperator ?? 'and',
        query.Filters,
        query.SearchText ?? '',
        query.AdditionalFilters
      )
      return {
        body: JSON.stringify({
          DomainType: domainType.Id,
          Name: name,
          Parameters: parameters,
          Search: {
            domainTypeName: domainType.Name,
            any: createApiSearchFilters(anyFilters, filterContext),
            all: createApiSearchFilters(allFilters, filterContext),
            sorts: query.Sorts,
            bypassSearchIndex: query.BypassSearchIndex,
            bypassDomainTypeFilters: query.BypassDomainTypeFilters
          },
          ExpectedTotal: expectedTotal,
          CompanyId: companyId
        }),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }
    },
    handlers: {
      200: flow(
        getJson,
        decode(ActionResponseCodec)
      ),
      400: handle4xx
    }
  })
}

export function exportToCsv(
  databaseTable: string,
  domainTypeName: string,
  any: Filter[],
  all: Filter[],
  sorts: Sort[],
  columns: string[],
  bypassSearchIndex: boolean,
  bypassDomainTypeFilters: string[]
): RTE.ReaderTaskEither<SignedInApiContext, ApiError, void> {
  return apiCall<SignedInApiContext, void>({
    uri: ({ V3_URL }) => `${V3_URL}search/${databaseTable.toLowerCase()}/export?${columns.map(column => `columns=${encodeURIComponent(column)}`).join('&')}`,
    request: ({ filterContext, token }) => ({
      method: 'POST',
      body: JSON.stringify({
        domainTypeName,
        any: createApiSearchFilters(any, filterContext),
        all: createApiSearchFilters(all, filterContext),
        sorts,
        bypassSearchIndex,
        bypassDomainTypeFilters
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }),
    handlers: {
      200: flow(
        getBlob,
        saveBlob(`${domainTypeName}.xlsx`)
      )
    }
  })
}

export function downloadInstance(
  domainTypeName: string,
  instanceId: string
): RTE.ReaderTaskEither<SignedInApiContext, ApiError, void> {
  return downloadFile(
    ({ V3_URL }) => `${V3_URL}${domainTypeName.toLowerCase()}/download?id=${encodeURIComponent(instanceId)}`
  )
}

export function downloadFromInstance(
  domainTypeName: string,
  id: string,
  fileId: string,
  fileName: string
): RTE.ReaderTaskEither<SignedInApiContext, ApiError, void> {
  return downloadFile(
    ({ V3_URL }) => `${V3_URL}${domainTypeName.toLowerCase()}/downloadFile?id=${encodeURIComponent(id)}&filename=${encodeURIComponent(fileId)}`,
    fileName
  )
}

export function downloadFromCache(
  fileName: string
): RTE.ReaderTaskEither<SignedInApiContext, ApiError, void> {
  return downloadFile(
    ({ ADMIN_URL }) => `${ADMIN_URL}downloadFromCache?filename=${encodeURIComponent(fileName)}`,
    fileName
  )
}

export function upload(
  domainTypeName: string,
  files: File[]
): RTE.ReaderTaskEither<SignedInApiContext, ApiError, File[]> {
  return uploadFiles(
    ({ V3_URL }) => `${V3_URL}${domainTypeName.toLowerCase()}/upload/`,
    files
  )
}

export function uploadToInstance(
  domainTypeName: string,
  id: string,
  files: File[]
): RTE.ReaderTaskEither<SignedInApiContext, ApiError, File[]> {
  return uploadFiles(
    ({ V3_URL }) => `${V3_URL}${domainTypeName.toLowerCase()}/uploadFile?id=${encodeURIComponent(id)}`,
    files
  )
}

export function uploadToCache(
  files: File[]
): RTE.ReaderTaskEither<SignedInApiContext, ApiError, File[]> {
  return uploadFiles(
    ({ ADMIN_URL }) => `${ADMIN_URL}uploadToCache/`,
    files
  )
}

export function getJobStatus(
  id: string
): RTE.ReaderTaskEither<SignedInApiContext, ApiError, JobStatus> {
  return apiCall<SignedInApiContext, JobStatus>({
    uri: ({ ADMIN_URL }) => `${ADMIN_URL}jobStatus?id=${encodeURIComponent(id)}`,
    request: ({ token }) => ({
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }),
    handlers: {
      200: flow(
        getJson,
        decode(JobStatusCodec)
      )
    }
  })
}

export function retryUpdates(
  id: string
): RTE.ReaderTaskEither<SignedInApiContext, ApiError, void> {
  return apiCall<SignedInApiContext, void>({
    uri: ({ V3_URL }) => `${V3_URL}work/retryUpdates/${encodeURIComponent(id)}`,
    request: ({ token }) => ({
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }),
    handlers: {
      200: flow(
        getJson,
        decodePossibleServerError(t.any),
        RTE.chainEitherK(() => E.right(undefined))
      )
    }
  })
}

export function validateWhat3Words(
  word1: string,
  word2: string,
  word3: string
): RTE.ReaderTaskEither<SignedInApiContext, ApiError, What3Words> {
  return apiCall<SignedInApiContext, What3Words>({
    uri: ({ V3_URL }) => `${V3_URL}location/validateWhat3Words?word1=${encodeURIComponent(word1)}&word2=${encodeURIComponent(word2)}&word3=${encodeURIComponent(word3)}`,
    request: ({ token }) => ({
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }),
    handlers: {
      200: flow(
        getJson,
        decode(What3WordsCodec)
      ),
      400: handle4xx
    }
  })
}

export function getFileBlob(
  domainTypeName: string,
  id: string,
  fileId: string
): RTE.ReaderTaskEither<SignedInApiContext, ApiError, Blob> {
  return apiCall<SignedInApiContext, Blob>({
    uri: ({ V3_URL }) => `${V3_URL}${domainTypeName.toLowerCase()}/downloadFile?id=${encodeURIComponent(id)}&filename=${encodeURIComponent(fileId)}`,
    request: ({ token }) => ({
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }),
    handlers: {
      200: flow(
        getBlob,
        RTE.map(([blob]) => blob)
      )
    }
  })
}
