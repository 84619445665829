import { Autocomplete, TextField } from '@mui/material'
import { DomainTypeCell } from 'components/attribute/AttributeCell'
import { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { DomainTypeInstance, NonListContextRefAttributeValue } from 'types'
import { DEFAULT_ATTRIBUTE_INPUT_SIZE, DEFAULT_ATTRIBUTE_INPUT_VARIANT } from 'utils/constants'
import { DomainTypeSettingsContext } from 'utils/context'
import { getDomainTypeSetting, getHeading, toErrorText } from 'utils/helpers'
import { useContextRef } from 'utils/hooks'
import { NarrowedProps } from '../AttributeInput'

export default function NonListContextRefInput({
  attributeValue,
  disabled,
  pathError,
  textFieldProps,
  onChange
}: Omit<NarrowedProps<NonListContextRefAttributeValue>, 'readOnly'>): JSX.Element | null {
  const {
    contextList,
    contextValue
  } = useContextRef(attributeValue) ?? {}
  const domainTypes = useSelector(getAllDomainTypes)
  const domainType = domainTypes[attributeValue.attribute.AttributeDomainType]
  const settingsContext = useContext(DomainTypeSettingsContext)
  const [open, setOpen] = useState(false)
  if (!domainType) {
    return null
  }
  const identifier = getDomainTypeSetting(domainTypes, domainType, 'Identifier') ?? 'Id'
  return (
    <Autocomplete
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      disabled={disabled}
      options={contextList ?? []}
      value={contextValue ?? null}
      onChange={(event, value) => {
        if (onChange === undefined) {
          return
        }
        onChange({
          attribute: attributeValue.attribute,
          value: value === null
            ? null
            : String(value[identifier])
        })
      }}
      renderOption={(props, option) => (
        <li {...props}>
          <DomainTypeCell
            attributeValue={{
              attribute: {
                ...attributeValue.attribute,
                AttributeType: 'domainType'
              },
              value: option
            }}
            disableLink />
        </li>
      )}
      getOptionLabel={(option: DomainTypeInstance) => getHeading(settingsContext, domainTypes, domainType, option)}
      renderInput={params => (
        <TextField
          {...params}
          variant={DEFAULT_ATTRIBUTE_INPUT_VARIANT}
          size={DEFAULT_ATTRIBUTE_INPUT_SIZE}
          required={attributeValue.attribute.Required ?? false}
          error={pathError !== undefined}
          helperText={toErrorText(pathError)}
          label={attributeValue.attribute.Title}
          fullWidth
          {...textFieldProps} />
      )} />
  )
}
