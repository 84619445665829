import { Edit, Visibility } from '@mui/icons-material'
import { Dialog, IconButton, InputAdornment, TextField, useTheme } from '@mui/material'
import CornerIcon from 'components/utils/CornerIcon'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { NonListDataformResultsAttributeValue } from 'types'
import { DataformElement, Results } from 'types/dataform'
import { getDataformResultsIcon, isNullOrUndefined } from 'utils/helpers'
import { useDataformResults } from 'utils/hooks'
import { ElementProps } from '../ElementInput'
import ResultsInput from '../ResultsInput'

export default function Dataform({
  group,
  element,
  value,
  onChange,
  required,
  readOnly,
  locked,
  error,
  errorText
}: ElementProps<DataformElement>): JSX.Element | null {
  const [open, setOpen] = useState(false)
  const attributeValue = useMemo<NonListDataformResultsAttributeValue>(() => {
    return {
      attribute: {
        Name: element.Alias,
        Title: element.Text,
        AttributeType: 'dataformResults',
        SelectBy: isNullOrUndefined(element.ExtraParams.dataformName)
          ? 'selectionCriteria'
          : 'name',
        DataformName: element.ExtraParams.dataformName,
        SelectionCategory: element.ExtraParams.selectionCategory,
        SelectionSubcategory: element.ExtraParams.selectionSubcategory
      },
      value
    }
  }, [element.Alias, element.ExtraParams.dataformName, element.ExtraParams.selectionCategory, element.ExtraParams.selectionSubcategory, element.Text, value])
  const [dataform, , errorMessage] = useDataformResults(attributeValue)
  useEffect(() => {
    if (isNullOrUndefined(dataform)) {
      return
    }
    if (resultsRef.current?.DataformId === dataform.Id) {
      return
    }
    resultsRef.current = {
      DataformId: dataform.Id,
      Answers: {},
      Aliases: {}
    }
  }, [dataform, onChange])
  const resultsRef = useRef<Results | null>(value)
  const applyEdits = useCallback(() => {
    setOpen(false)
    if (!readOnly) {
      onChange(resultsRef.current)
    }
  }, [onChange, readOnly])
  const theme = useTheme()
  const [cornerIcon, cornerIconColor] = getDataformResultsIcon(value, theme)
  return (
    <>
      <Dialog
        fullWidth
        maxWidth='md'
        open={open}>
        <ResultsInput
          key={dataform?.Id}
          dataform={dataform}
          initialResults={resultsRef.current}
          attribute={null}
          errorMessage={errorMessage}
          readOnly={readOnly || locked}
          maxHeight='50vh'
          onChange={results => {
            resultsRef.current = results
          }}
          onComplete={applyEdits}
          onClose={applyEdits} />
      </Dialog>
      <TextField
        label={attributeValue.attribute.Title}
        id={attributeValue.attribute.Name}
        variant='outlined'
        fullWidth
        size='small'
        value=''
        required={required}
        helperText={errorMessage ?? errorText}
        error={error || !isNullOrUndefined(errorMessage)}
        InputProps={{
          readOnly: true,
          startAdornment: cornerIcon !== null
            ? (
              <InputAdornment position='start'>
                <CornerIcon
                  icon='dynamic_form'
                  cornerIcon={cornerIcon}
                  cornerIconColor={cornerIconColor} />
              </InputAdornment>
            )
            : null,
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                aria-label='open dataform dialog'
                disabled={isNullOrUndefined(dataform)}
                edge='end'
                onClick={() => {
                  setOpen(true)
                }}>
                {(readOnly || value?.Complete === true)
                  ? <Visibility />
                  : <Edit />}
              </IconButton>
            </InputAdornment>
          )
        }} />
    </>
  )
}