import { FormControl, FormHelperText, FormLabel, Icon, ToggleButton, ToggleButtonGroup, useTheme } from '@mui/material'
import { IconRadioElement } from 'types/dataform'
import { ElementProps } from '../ElementInput'
import { getRadioChoices, getRadioColor } from './Radio'

export default function InlineRadio({
  dataform,
  group,
  element,
  value,
  onChange,
  required,
  readOnly,
  locked,
  expectedValue,
  error,
  errorText
}: ElementProps<IconRadioElement>): JSX.Element | null {
  const choices = getRadioChoices(element)
  const theme = useTheme()

  return (
    <FormControl
      required={required}
      fullWidth
      disabled={locked}
      error={error}>
      <FormLabel
        color='primary'>{element.Text}</FormLabel>
      <ToggleButtonGroup
        exclusive
        value={value}
        onChange={(event, value) => onChange(value)}>
        {choices.map(choice => {
          const splitValue = choice.Value.split(' ')
          const icon = splitValue[0]
          const colour = splitValue.length > 1 ? splitValue[1] : theme.palette.text.primary
          return (
            <ToggleButton
              key={choice.Value}
              size='small'
              value={choice.Value}
              color={getRadioColor(choice.Value, expectedValue)}
              disabled={locked || readOnly}>
              <Icon
                sx={{
                  color: colour
                }}>
                {icon}
              </Icon>
            </ToggleButton>
          )
        })}
      </ToggleButtonGroup>
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  )
}