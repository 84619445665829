import { Add } from '@mui/icons-material'
import { Autocomplete, TextField, TextFieldProps } from '@mui/material'
import AttributeCell from 'components/attribute/AttributeCell'
import AppendDomainTypeContext from 'components/domainType/AppendDomainTypeContext'
import CreateDialog from 'components/domainType/CreateDialog'
import EditDialog from 'components/domainType/EditDialog'
import SortableChip from 'components/utils/SortableChip'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useDrop } from 'react-dnd'
import { Flipper } from 'react-flip-toolkit'
import { DomainType, ListDomainTypeAttributeValue, PathError } from 'types'
import { DEFAULT_ATTRIBUTE_INPUT_SIZE, DEFAULT_ATTRIBUTE_INPUT_VARIANT } from 'utils/constants'
import { DomainTypeSettingsContext } from 'utils/context'
import { fixMultipleAutocompleteRequiredBehaviour, getDomainTypeSetting, getHeading, getTransparentBackgroundColour, makeSortFunction, toErrorText } from 'utils/helpers'
import LabelledInput from '../LabelledInput'

function hasErrorAtIndex(pathError: PathError | undefined, index: number): boolean {
  return Array.isArray(pathError)
    && (typeof pathError[index] === 'string'
      || (typeof pathError[index] === 'object'
        && Object.keys(pathError[index] ?? {}).length > 0))
}

interface ListNonApiDomainTypeProps {
  readonly domainTypes: Partial<Record<string, DomainType>>
  readonly domainType: DomainType
  readonly attributeValue: ListDomainTypeAttributeValue
  readonly disabled?: boolean
  readonly readOnly: boolean
  readonly pathError?: PathError
  readonly textFieldProps?: Partial<TextFieldProps>
  onChange?(attributeValue: ListDomainTypeAttributeValue): void
}

export default function ListNonApiDomainTypeInput({
  domainTypes,
  domainType,
  attributeValue,
  disabled,
  readOnly,
  pathError,
  textFieldProps,
  onChange
}: ListNonApiDomainTypeProps): JSX.Element | null {
  const value = useMemo(
    () => attributeValue.value ?? [],
    [attributeValue.value]
  )
  const [localOrder, setLocalOrder] = useState<string[]>([])
  const identifier = getDomainTypeSetting(domainTypes, domainType, 'Identifier') ?? 'Id'
  useEffect(() => {
    setLocalOrder(value.map(instance => String(instance[identifier])))
  }, [identifier, value])
  const [, drop] = useDrop(() => ({ accept: 'item' }))
  const settingsContext = useContext(DomainTypeSettingsContext)
  const [open, setOpen] = useState<false | 'create' | 'edit'>(false)
  const [editingIndex, setEditingIndex] = useState<number | null>(null)
  const editingInstance = editingIndex === null
    ? undefined
    : value[editingIndex]
  const newAttributes = useMemo(() => {
    return [attributeValue.attribute]
  }, [attributeValue.attribute])
  if (readOnly) {
    return (
      <LabelledInput
        label={attributeValue.attribute.Title}
        required={attributeValue.attribute.Required ?? false}>
        <AttributeCell attributeChainValue={attributeValue} />
      </LabelledInput>
    )
  }
  return (
    <>
      <CreateDialog
        key={open.toString()}
        open={open === 'create'}
        domainType={domainType}
        bypassApi
        onClose={() => setOpen(false)}
        onCreateSuccess={instance => {
          setOpen(false)
          if (onChange === undefined) {
            return
          }
          onChange({
            attribute: attributeValue.attribute,
            value: [...value, {
              ...instance,
              _create: true
            }]
          })
        }} />
      <AppendDomainTypeContext newAttributes={newAttributes}>
        <EditDialog
          key={open.toString()}
          open={open === 'edit'}
          instance={editingInstance ?? {}}
          domainType={domainType}
          formMode={editingIndex === null
            ? undefined
            : value[editingIndex]?._create === true
              ? 'create'
              : undefined}
          pathError={Array.isArray(pathError) && editingIndex !== null
            ? pathError[editingIndex]
            : undefined}
          bypassApi
          onClose={() => setOpen(false)}
          onEditSuccess={instance => {
            setOpen(false)
            setEditingIndex(null)
            if (onChange === undefined || editingIndex === null) {
              return
            }
            onChange({
              attribute: attributeValue.attribute,
              value: [
                ...value.slice(0, editingIndex),
                instance,
                ...value.slice(editingIndex + 1)
              ]
            })
          }} />
      </AppendDomainTypeContext>
      <Autocomplete
        multiple
        open={false}
        disabled={disabled}
        options={value}
        value={value}
        openText='Add'
        onChange={(event, value) => {
          if (onChange === undefined) {
            return
          }
          onChange({
            attribute: attributeValue.attribute,
            value
          })
        }}
        popupIcon={<Add onClick={() => setOpen('create')} />}
        getOptionLabel={option => getHeading(settingsContext, domainTypes, domainType, option)}
        isOptionEqualToValue={(option, value) => option[identifier] === value[identifier]}
        renderTags={(value, getTagProps) => {
          return (
            <Flipper flipKey={localOrder.join(',')}>
              <div ref={drop}>
                {value
                  .slice()
                  .sort(makeSortFunction(localOrder, instance => String(instance[identifier])))
                  .map((instance, index) => (
                    <SortableChip
                      size={textFieldProps?.size ?? DEFAULT_ATTRIBUTE_INPUT_SIZE}
                      label={getHeading(settingsContext, domainTypes, domainType, instance)}
                      color={hasErrorAtIndex(pathError, index)
                        ? 'error'
                        : 'default'}
                      onMouseDown={event => event.stopPropagation()}
                      onClick={() => {
                        setOpen('edit')
                        setEditingIndex(index)
                      }}
                      id={String(instance[identifier] ?? '')}
                      order={localOrder}
                      onChangeOrder={setLocalOrder}
                      onCommitOrder={order => {
                        onChange?.({
                          ...attributeValue,
                          value: value
                            .slice()
                            .sort(makeSortFunction(order, instance => String(instance[identifier])))
                        })
                      }}
                      {...getTagProps({ index })}
                      sx={{
                        background: theme => getTransparentBackgroundColour(
                          theme.palette.primary.main,
                          theme.palette.mode
                        )
                      }}
                      key={String(instance[identifier] ?? index)} />
                  ))}
              </div>
            </Flipper>
          )
        }}
        filterSelectedOptions
        renderInput={params => (
          <TextField
            {...params}
            variant={DEFAULT_ATTRIBUTE_INPUT_VARIANT}
            size={DEFAULT_ATTRIBUTE_INPUT_SIZE}
            required={attributeValue.attribute.Required ?? false}
            error={pathError !== undefined}
            helperText={toErrorText(pathError)}
            label={attributeValue.attribute.Title}
            fullWidth
            {...textFieldProps}
            InputProps={{
              ...params.InputProps,
              ...textFieldProps?.InputProps,
              readOnly: true
            }}
            inputProps={{
              ...textFieldProps?.inputProps,
              ...fixMultipleAutocompleteRequiredBehaviour(params, attributeValue, value)
            }} />
        )} />
    </>
  )
}