import * as t from 'io-ts'
import { DateTime } from 'luxon'
import { PercentageCodec } from 'utils/codecs/dataform'

export interface Settings {
  readonly DisplayText: string | null
  readonly LockOnComplete: boolean
  readonly NoUnexpectedAnswers: boolean
}

export interface Dependency {
  readonly ControllingId?: string | null
  readonly Value?: string | null
  readonly Expression?: string | null
}

export interface Choice {
  readonly Value: string
  readonly Text: string
}

export interface Condition {
  readonly Selector?: string | null
  readonly Value?: string | null
  readonly Expression?: string | null
}

export interface ContextualMask {
  readonly Name: string
  readonly Appearance: 'hidden' | 'readonly' | 'removed'
  readonly Conditions: Condition[]
}

export interface Constraint {
  readonly Type: 'regex' | 'numberRange' | 'feelExpression'
  readonly Rule: string
  readonly Message: string
}

export interface BaseElement {
  readonly Id: string
  readonly Alias: string
  readonly Text: string
  readonly ExpectedResult?: string | null
  readonly DefaultResult?: string | null
  readonly Milestone?: boolean | null
  readonly Required?: boolean | null
  readonly DepsId?: Dependency[] | null
  readonly Choices?: Choice[] | null
  readonly Filters?: string[] | null
  readonly ContextualMasks?: ContextualMask[] | null
  readonly Constraint?: Constraint | null
}

export interface CalculationExtraParams {
  readonly calculation?: string | null
}

export interface SelectElementExtraParams extends CalculationExtraParams {
  readonly MetadataCategory?: string | null
  readonly MetadataSubcategory?: string | null
  readonly MetadataChoices?: string | null
}

export interface TextElementExtraParams extends CalculationExtraParams {
  readonly multiline?: boolean | null
  readonly readonly?: boolean | null
}

export interface StatementElementExtraParams {
  readonly appearance?: 'subheading' | null
}

export interface ImageElementExtraParams {
  readonly appearance: 'file'
}

export interface FileContextElementExtraParams {
  readonly fileContext: 'work' | 'task'
}

export interface EquipmentElementExtraParams {
  readonly searchValue?: string | null
  readonly skipQuantity: boolean
  readonly activeTerm: 'serialisedEquipmentExternalId' | 'equipmentTypeExternalId' | 'description'
  readonly resultType: 'SERIAL' | 'NONSERIAL' | 'BOTH' | 'TYPES'
  readonly STEP_CONFIG_MODE?: string | null
  readonly CONFIG_MODE?: string | null
}

export interface DataformElementExtraParams {
  readonly inline: boolean
  readonly dataformName?: string | null
  readonly selectionCategory?: string | null
  readonly selectionSubcategory?: string | null
}

export enum ElementType {
  Radio = 0,
  Checkbox = 2,
  Number = 3,
  TextBox = 4,
  TextBoxWithQuestion = 5,
  Statement = 6,
  Dropdown = 10,
  InlineRadio = 11,
  Date = 12,
  DateTime = 13,
  Signature = 15,
  Initials = 16,
  Percentage = 17,
  Image = 18,
  Photos = 19,
  Markup = 20,
  Equipment = 21,
  Time = 22,
  Dataform = 23,
  IconRadio = 24
}

export interface RadioElement extends BaseElement {
  readonly ElementType: ElementType.Radio
  readonly ExtraParams?: SelectElementExtraParams | null
}

export interface CheckboxElement extends BaseElement {
  readonly ElementType: ElementType.Checkbox
  readonly ExtraParams?: CalculationExtraParams | null
}

export interface NumberElement extends BaseElement {
  readonly ElementType: ElementType.Number
  readonly ExtraParams?: CalculationExtraParams | null
}

export interface TextBoxElement extends BaseElement {
  readonly ElementType: ElementType.TextBox
  readonly ExtraParams?: TextElementExtraParams | null
}

export interface TextBoxWithQuestionElement extends BaseElement {
  readonly ElementType: ElementType.TextBoxWithQuestion
  readonly ExtraParams?: TextElementExtraParams | null
}

export interface StatementElement extends BaseElement {
  readonly ElementType: ElementType.Statement
  readonly ExtraParams?: StatementElementExtraParams | null
}

export interface DropdownElement extends BaseElement {
  readonly ElementType: ElementType.Dropdown
  readonly ExtraParams?: SelectElementExtraParams | null
}

export interface InlineRadioElement extends BaseElement {
  readonly ElementType: ElementType.InlineRadio
  readonly ExtraParams?: SelectElementExtraParams | null
}

export interface IconRadioElement extends BaseElement {
  readonly ElementType: ElementType.IconRadio
  readonly ExtraParams?: SelectElementExtraParams | null
}

export interface DateElement extends BaseElement {
  readonly ElementType: ElementType.Date
  readonly ExtraParams?: CalculationExtraParams | null
}

export interface DateTimeElement extends BaseElement {
  readonly ElementType: ElementType.DateTime
  readonly ExtraParams?: CalculationExtraParams | null
}

export interface SignatureElement extends BaseElement {
  readonly ElementType: ElementType.Signature
}

export interface InitialsElement extends BaseElement {
  readonly ElementType: ElementType.Initials
}

export interface PercentageElement extends BaseElement {
  readonly ElementType: ElementType.Percentage
}

export interface ImageElement extends BaseElement {
  readonly ElementType: ElementType.Image
  readonly ExtraParams: ImageElementExtraParams
}

export interface PhotosElement extends BaseElement {
  readonly ElementType: ElementType.Photos
  readonly ExtraParams: FileContextElementExtraParams
}

export interface MarkupElement extends BaseElement {
  readonly ElementType: ElementType.Markup
  readonly ExtraParams: FileContextElementExtraParams
}

export interface EquipmentElement extends BaseElement {
  readonly ElementType: ElementType.Equipment
  readonly ExtraParams: EquipmentElementExtraParams
}

export interface TimeElement extends BaseElement {
  readonly ElementType: ElementType.Time
  readonly ExtraParams?: CalculationExtraParams | null
}

export interface DataformElement extends BaseElement {
  readonly ElementType: ElementType.Dataform
  readonly ExtraParams: DataformElementExtraParams
}

export type Element =
  | RadioElement
  | CheckboxElement
  | NumberElement
  | TextBoxElement
  | TextBoxWithQuestionElement
  | StatementElement
  | DropdownElement
  | InlineRadioElement
  | DateElement
  | DateTimeElement
  | SignatureElement
  | InitialsElement
  | PercentageElement
  | ImageElement
  | PhotosElement
  | MarkupElement
  | EquipmentElement
  | TimeElement
  | DataformElement
  | IconRadioElement

export interface NumberRange<L extends number, U extends number> {
  readonly NumberRange: unique symbol
  readonly lower: L
  readonly upper: U
}

export type Percentage = t.TypeOf<typeof PercentageCodec>

export interface SerialisedEquipment {
  readonly IsSerialised: true
  readonly SerialisedEquipment: string
  readonly Quantity: 1
}

export interface EquipmentType {
  readonly IsSerialised: false
  readonly EquipmentType: string
  readonly Quantity: number
}

export type Equipment =
  | SerialisedEquipment
  | EquipmentType

export type ElementValueTypes = {
  [ElementType.Radio]: string
  [ElementType.Checkbox]: boolean
  [ElementType.Number]: number
  [ElementType.TextBox]: string
  [ElementType.TextBoxWithQuestion]: string
  [ElementType.Statement]: null
  [ElementType.Dropdown]: string
  [ElementType.InlineRadio]: string
  [ElementType.Date]: DateTime
  [ElementType.DateTime]: DateTime
  [ElementType.Signature]: string
  [ElementType.Initials]: string
  [ElementType.Percentage]: Percentage
  [ElementType.Image]: null
  [ElementType.Photos]: string[]
  [ElementType.Markup]: string
  [ElementType.Equipment]: Equipment
  [ElementType.Time]: DateTime
  [ElementType.Dataform]: Results
  [ElementType.IconRadio]: string
}

export type DefaultValueContext = Record<string, unknown>

export interface Metadata {
  readonly Category: string
  readonly Subcategory: string
  readonly Value: string
}

export interface Group {
  readonly Id: string
  readonly Title: string
  readonly Type: 'group' | 'photos' | 'markup' | 'signature' | 'equipment' | 'dataform'
  readonly Required?: boolean | null
  readonly Elements: Element[]
}

export interface Dataform {
  readonly Id: string
  readonly Name: string
  readonly Settings: Settings
  readonly ShowDisabledElements: boolean
  readonly Version: string
  readonly Groups: Group[]
}

export interface Results {
  readonly DataformId: string
  readonly Aliases: Partial<Record<string, string>>
  readonly Answers: Record<string, unknown>
  readonly Complete?: boolean | null
  readonly AsExpected?: boolean | null
}

export interface MultiResult {
  readonly key: string
  readonly dataform: Dataform | null
  readonly results: Results | null
  readonly errorMessage?: string
}

export type MultiResults = MultiResult[]

export interface AttachedFileHandle {
  readonly Id: string
  readonly FileId: string
}

export interface EntityWithAttachedFiles {
  readonly Id: string
  readonly AttachedFiles: AttachedFileHandle[]
}

export interface MultiDataformMapping {
  readonly Category: 'MultiDataformMapping'
  readonly Subcategory: string
  readonly Value: string
  readonly Purpose?: string | null
  readonly Order?: string | null
}
