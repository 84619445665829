import { Theme, ThemeProvider, useTheme } from '@mui/material'
import { PropsWithChildren, useMemo } from 'react'
import { DomainType } from 'types'
import { useDomainTypeColour } from 'utils/hooks'

interface Props {
  readonly domainType: DomainType | null
}

export default function DomainTypeTheme({
  domainType,
  children
}: PropsWithChildren<Props>): JSX.Element {
  const color = useDomainTypeColour(domainType)
  const theme = useTheme()
  const newTheme: Theme = useMemo(() => {
    return Object.assign(
      {},
      theme,
      {
        palette: Object.assign(
          {},
          theme.palette,
          {
            primary: theme.palette.augmentColor({
              color: {
                main: color
              }
            })
          }
        )
      }
    )
  }, [color, theme])
  return (
    <ThemeProvider
      theme={newTheme}>
      {children}
    </ThemeProvider>
  )
}
