import { FilterAltOutlined } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { GridColumnHeaderParams, GridColumnHeaderTitle } from '@mui/x-data-grid'
import Help from 'components/utils/Help'
import { useContext } from 'react'
import { Attribute } from 'types'
import TableViewContext from './TableViewContext'

export default function CustomColumnHeader(params: GridColumnHeaderParams & { attribute: Attribute }): JSX.Element {
  const { filters, onClickFilterIcon } = useContext(TableViewContext)
  return (
    <>
      <GridColumnHeaderTitle
        label={params.colDef.headerName ?? ''}
        columnWidth={params.colDef.computedWidth} />
      <Help
        type='attribute'
        attribute={params.attribute} />
      {filters.some(filter => filter.Property === params.field) && (
        <IconButton
          onClick={event => {
            event.stopPropagation()
            onClickFilterIcon(params.field)
          }}
          color='default'
          size='small'
          tabIndex={-1}>
          <FilterAltOutlined className='MuiDataGrid-filterIcon' />
        </IconButton>
      )}
    </>
  )
}