import { Box, useTheme } from '@mui/material'
import SaveDomainTypeSettingButton from 'components/domainType/SaveDomainTypeSettingButton'
import SortableToggleList from 'components/utils/SortableToggleList'
import { ContextType, useCallback, useMemo } from 'react'
import { useDomainTypeSetting } from 'utils/hooks'
import TableViewContext from './TableView/TableViewContext'
import { ATTRIBUTE_LIST_SETTING_POPOVER_WIDTH } from 'utils/constants'

const MAX_HEIGHT = 350

type Keys =
  | 'columns'
  | 'setColumnVisibility'
  | 'domainType'
type Props = Pick<ContextType<typeof TableViewContext>, Keys>

export default function ColumnsPanel({
  columns,
  setColumnVisibility,
  domainType
}: Props): JSX.Element {
  const items = useMemo(() => {
    return columns
      .filter(column => column.type !== 'actions')
      .map(column => ({
        id: column.field,
        title: column.headerName ?? '',
        checked: !(column.hide ?? false)
      }))
  }, [columns])
  const columnOrder = useMemo(() => {
    return domainType !== null
      ? items.filter(item => item.checked).map(item => item.id)
      : []
  }, [domainType, items])
  const [, onChangeItemOrder] = useDomainTypeSetting(
    domainType,
    'Columns',
    []
  )
  const onToggle = useCallback((field: string) => {
    const column = columns.find(column => column.field === field)
    if (column === undefined) {
      return
    }
    setColumnVisibility(column.field, column.hide ?? false)
  }, [columns, setColumnVisibility])
  const theme = useTheme()
  return (
    <Box minWidth={ATTRIBUTE_LIST_SETTING_POPOVER_WIDTH}>
      <h3
        style={{
          margin: 0,
          paddingLeft: theme.spacing(2),
          paddingTop: theme.spacing(1)
        }}>
        Columns
      </h3>
      <SortableToggleList
        textFieldLabel='Filter columns'
        textFieldPlaceholder='Column title'
        items={items}
        itemOrder={columnOrder}
        maxHeight={MAX_HEIGHT}
        onToggle={onToggle}
        onChangeItemOrder={onChangeItemOrder}>
        <Box display='flex'>
          {domainType !== null && (
            <SaveDomainTypeSettingButton
              domainType={domainType}
              setting='Columns'
              value={columns
                .filter(column => column.type !== 'actions')
                .filter(column => !(column.hide ?? false))
                .map(column => column.field)} />
          )}
        </Box>
      </SortableToggleList>
    </Box>
  )
}