import { either as E } from 'fp-ts'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCompanyFulfilled, fetchPersonFulfilled, signOut } from 'state/actions/auth'
import { editDomainType } from 'state/actions/domainTypes'
import { getCompany, getUser } from 'state/reducers'
import { ApiError, DomainTypeInstance, Filter, SearchResponse, Sort, User } from 'types'
import * as api from 'utils/api'
import { NotSignedInApiContext, Promisify, SignedInApiContext, promisify } from 'utils/api'
import { CompanyCodec, DomainTypeCodec, PersonCodec } from 'utils/codecs'
import { useFilterContext } from './useFilterContext'

const URL = window.Config.ServerUrl
const V1_URL = `${URL}api/v1/`
const V3_URL = `${URL}api/v3/`
const ADMIN_URL = `${URL}api/admin/`
const ACCOUNT_URL = `${URL}Account.aspx/`
const OWIN_URL = `${URL}Owin.aspx/`
const AUTH_URL = `${URL}api/auth/`
const PASSWORD_URL = `${AUTH_URL}password/`

interface NotSignedInApiEndpoints {
  signIn: Promisify<typeof api.signIn>
  adalSignIn: Promisify<typeof api.adalSignIn>
  getAdalProperties: Promisify<typeof api.getAdalProperties>
  resetPassword: Promisify<typeof api.resetPassword>
  forgotPassword: Promisify<typeof api.forgotPassword>
}

export interface NotSignedInApi extends NotSignedInApiEndpoints {
  readonly isSignedIn: false
  readonly user: null
  withRequestContext(requestContext: Partial<NotSignedInApiContext>): NotSignedInApi
}

function makeNotSignedInEndpoints(notSignedInApiContext: NotSignedInApiContext): NotSignedInApiEndpoints {
  return {
    signIn: api.promisify(api.signIn, notSignedInApiContext),
    getAdalProperties: api.promisify(api.getAdalProperties, notSignedInApiContext),
    adalSignIn: api.promisify(api.adalSignIn, notSignedInApiContext),
    resetPassword: api.promisify(api.resetPassword, notSignedInApiContext),
    forgotPassword: api.promisify(api.forgotPassword, notSignedInApiContext)
  }
}

interface SignedInApiEndpoints {
  search: Promisify<typeof api.search>
  searchAll: {
    (
      rootDomainType: string,
      domainTypeName: string,
      any: Filter[],
      all: Filter[],
      sorts: Sort[],
      bypassSearchIndex?: boolean,
      bypassDomainTypeFilters?: string[] | null
    ): Promise<E.Either<ApiError, SearchResponse>>
  }
  get: Promisify<typeof api.get>
  all: Promisify<typeof api.all>
  post: Promisify<typeof api.post>
  put: Promisify<typeof api.put>
  patch: Promisify<typeof api.patch>
  delete: Promisify<typeof api.deleteById>
  deleteByQuery: Promisify<typeof api.deleteByQuery>
  action: Promisify<typeof api.action>
  actionByQuery: Promisify<typeof api.actionByQuery>
  export: Promisify<typeof api.exportToCsv>
  upload: Promisify<typeof api.upload>
  uploadToInstance: Promisify<typeof api.uploadToInstance>
  uploadToCache: Promisify<typeof api.uploadToCache>
  downloadInstance: Promisify<typeof api.downloadInstance>
  downloadFromInstance: Promisify<typeof api.downloadFromInstance>
  downloadFromCache: Promisify<typeof api.downloadFromCache>
  getJobStatus: Promisify<typeof api.getJobStatus>
  getFileBlob: Promisify<typeof api.getFileBlob>
  getLoginType: Promisify<typeof api.getLoginType>
  refreshFormsAuthenticationCookie: Promisify<typeof api.refreshFormsAuthenticationCookie>
  changeMyPassword: Promisify<typeof api.changeMyPassword>
  changePassword: Promisify<typeof api.changePassword>
  retryUpdates: Promisify<typeof api.retryUpdates>
  validateWhat3Words: Promisify<typeof api.validateWhat3Words>
}

export interface SignedInApi extends SignedInApiEndpoints {
  readonly isSignedIn: true
  readonly user: User
  withRequestContext(requestContext: Partial<SignedInApiContext>): SignedInApi
}

function makeSignedInApiEndpoints(signedInApiContext: SignedInApiContext): SignedInApiEndpoints {
  return {
    search: promisify(api.search, signedInApiContext),
    async searchAll(
      rootDomainType,
      domainTypeName,
      any,
      all,
      sorts,
      bypassSearchIndex = false,
      bypassDomainTypeFilters = []
    ) {
      const pageSize = api.limitSearchPageSize(400)
      let page = 0
      let totalHits = 0
      let results: DomainTypeInstance[] = []
      let allResults: DomainTypeInstance[] = []
      do {
        const response = await this.search(
          rootDomainType,
          domainTypeName,
          any,
          all,
          sorts,
          page + 1,
          pageSize,
          bypassSearchIndex,
          bypassDomainTypeFilters
        )
        if (E.isLeft(response)) {
          return response
        }
        ({ results, totalHits } = response.right)
        allResults = allResults.concat(results)
      } while (page++ * pageSize + results.length < totalHits)
      return E.right({
        results: allResults,
        totalHits
      })
    },
    get: promisify(api.get, signedInApiContext),
    all: promisify(api.all, signedInApiContext),
    post: promisify(api.post, signedInApiContext),
    put: promisify(api.put, signedInApiContext),
    patch: promisify(api.patch, signedInApiContext),
    delete: promisify(api.deleteById, signedInApiContext),
    deleteByQuery: promisify(api.deleteByQuery, signedInApiContext),
    action: promisify(api.action, signedInApiContext),
    actionByQuery: promisify(api.actionByQuery, signedInApiContext),
    export: promisify(api.exportToCsv, signedInApiContext),
    downloadInstance: promisify(api.downloadInstance, signedInApiContext),
    downloadFromInstance: promisify(api.downloadFromInstance, signedInApiContext),
    downloadFromCache: promisify(api.downloadFromCache, signedInApiContext),
    upload: promisify(api.upload, signedInApiContext),
    uploadToInstance: promisify(api.uploadToInstance, signedInApiContext),
    uploadToCache: promisify(api.uploadToCache, signedInApiContext),
    getJobStatus: promisify(api.getJobStatus, signedInApiContext),
    getFileBlob: promisify(api.getFileBlob, signedInApiContext),
    getLoginType: promisify(api.getLoginType, signedInApiContext),
    refreshFormsAuthenticationCookie: promisify(api.refreshFormsAuthenticationCookie, signedInApiContext),
    changeMyPassword: promisify(api.changeMyPassword, signedInApiContext),
    changePassword: promisify(api.changePassword, signedInApiContext),
    retryUpdates: promisify(api.retryUpdates, signedInApiContext),
    validateWhat3Words: promisify(api.validateWhat3Words, signedInApiContext)
  }
}

type UseApiOutput = NotSignedInApi | SignedInApi

export function useApi(): UseApiOutput {
  const user = useSelector(getUser)
  const company = useSelector(getCompany)
  const filterContext = useFilterContext()
  const dispatch = useDispatch()
  const onUnauthorised = useCallback(() => {
    dispatch(signOut())
  }, [dispatch])

  const fetchCheckAuthorised = useCallback(async (...parameters: Parameters<typeof fetch>) => {
    const response = await fetch(...parameters)
    if (response.status === 401) {
      onUnauthorised()
      throw new Error('Unauthorised')
    }
    return response
  }, [onUnauthorised])

  const notSignedInApiContext = useMemo<NotSignedInApiContext>(() => ({
    ACCOUNT_URL,
    OWIN_URL,
    PASSWORD_URL,
    fetch: (input, init) => fetch(input, init)
  }), [])

  const updateStateIfEdited = useCallback((
    rootDomainType: string,
    json: unknown
  ) => {
    if (rootDomainType === 'DomainType' && DomainTypeCodec.is(json)) {
      dispatch(editDomainType(json))
    } else if (rootDomainType === 'Company' && CompanyCodec.is(json) && json.Id === company?.Id) {
      dispatch(fetchCompanyFulfilled(json))
    } else if (rootDomainType === 'Person' && PersonCodec.is(json) && json.Id === user?.id) {
      dispatch(fetchPersonFulfilled(json))
    }
  }, [dispatch, company?.Id, user?.id])

  return useMemo<UseApiOutput>(() => {
    if (user === null) {
      return {
        isSignedIn: false,
        user: null,
        ...makeNotSignedInEndpoints(notSignedInApiContext),
        withRequestContext(requestContext) {
          return {
            ...this,
            ...makeNotSignedInEndpoints({
              ...notSignedInApiContext,
              ...requestContext
            })
          }
        }
      }
    }
    const signedInApiContext: SignedInApiContext = {
      V1_URL,
      V3_URL,
      ADMIN_URL,
      PASSWORD_URL,
      AUTH_URL,
      fetch: fetchCheckAuthorised,
      token: user.token,
      companyId: company?.Id ?? null,
      updateStateIfEdited,
      filterContext
    }
    return {
      isSignedIn: true,
      user,
      ...makeSignedInApiEndpoints(signedInApiContext),
      withRequestContext(requestContext) {
        return {
          ...this,
          ...makeSignedInApiEndpoints({
            ...signedInApiContext,
            ...requestContext
          })
        }
      }
    }
  }, [company?.Id, fetchCheckAuthorised, filterContext, notSignedInApiContext, updateStateIfEdited, user])
}
