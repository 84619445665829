import { Autocomplete, CircularProgress, TextField, TextFieldProps } from '@mui/material'
import { DomainTypeCell } from 'components/attribute/AttributeCell'
import { useCallback, useContext, useMemo } from 'react'
import { DomainType, DomainTypeInstance, NonListDomainTypeAttributeValue, PathError } from 'types'
import { DEFAULT_ATTRIBUTE_INPUT_SIZE, DEFAULT_ATTRIBUTE_INPUT_VARIANT } from 'utils/constants'
import { DomainTypeSettingsContext } from 'utils/context'
import { getDomainTypeSetting, getHeading, toErrorText } from 'utils/helpers'
import { useApiDomainTypeAutocomplete } from 'utils/hooks'

interface NonListApiDomainTypeProps {
  readonly domainTypes: Partial<Record<string, DomainType>>
  readonly domainType: DomainType
  readonly attributeValue: NonListDomainTypeAttributeValue
  readonly disabled?: boolean
  readonly pathError?: PathError
  readonly textFieldProps?: Partial<TextFieldProps>
  onChange?(attributeValue: NonListDomainTypeAttributeValue): void
}

export default function NonListApiDomainTypeInput({
  domainTypes,
  domainType,
  attributeValue,
  disabled,
  pathError,
  textFieldProps,
  onChange
}: NonListApiDomainTypeProps): JSX.Element | null {
  const identifier = getDomainTypeSetting(domainTypes, domainType, 'Identifier') ?? 'Id'
  const id = attributeValue.value !== null
    ? String(attributeValue.value[identifier])
    : null
  const includeIds = useMemo(() => typeof id === 'string'
    ? [id]
    : [], [id])
  const {
    open,
    setOpen,
    options,
    loading,
    searchText,
    setSearchText
  } = useApiDomainTypeAutocomplete(
    domainTypes,
    domainType,
    attributeValue.attribute.Filters,
    includeIds,
    false,
    attributeValue.attribute.BypassDomainTypeFilters)
  const settingsContext = useContext(DomainTypeSettingsContext)
  const handleChange = useCallback((event: unknown, value: DomainTypeInstance | null) => {
    if (onChange === undefined) {
      return
    }
    onChange({
      attribute: attributeValue.attribute,
      value
    })
  }, [attributeValue.attribute, onChange])
  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      disabled={disabled}
      onChange={handleChange}
      isOptionEqualToValue={(option: DomainTypeInstance, value: DomainTypeInstance) => option[identifier] === value[identifier]}
      renderOption={(props, option) => (
        <li {...props}>
          <DomainTypeCell
            attributeValue={{
              attribute: attributeValue.attribute,
              value: option
            }}
            disableLink />
        </li>
      )}
      getOptionLabel={option => getHeading(settingsContext, domainTypes, domainType, option)}
      filterOptions={options => options}
      value={attributeValue.value}
      inputValue={searchText}
      onInputChange={(event, value) => setSearchText(value)}
      options={options}
      loading={loading}
      renderInput={params => (
        <TextField
          {...params}
          variant={DEFAULT_ATTRIBUTE_INPUT_VARIANT}
          size={DEFAULT_ATTRIBUTE_INPUT_SIZE}
          required={attributeValue.attribute.Required ?? false}
          error={pathError !== undefined}
          helperText={toErrorText(pathError)}
          label={attributeValue.attribute.Title}
          fullWidth
          {...textFieldProps}
          InputProps={{
            ...params.InputProps,
            ...textFieldProps?.InputProps,
            endAdornment: (
              <>
                {loading
                  ? (
                    <CircularProgress
                      color='inherit'
                      size={20} />
                  )
                  : null}
                {params.InputProps.endAdornment}
              </>
            )
          }} />
      )} />
  )
}