import * as E from 'fp-ts/Either'
import * as t from 'io-ts'
import { Json } from 'io-ts-types'
import { Dataform, DefaultValueContext, Element, ElementValueTypes, Results } from 'types/dataform'
import { ElementTypeCodecs, ElementTypeDefaultValueCodecs, ElementTypeRequiredValidation, RegExpFromString } from 'utils/codecs/dataform'
import { isNullOrUndefined } from 'utils/helpers'
import { treeGet } from 'utils/helpers/dataform'
import { ElementGraph, ElementState, State } from './types'

export function getElementValue<T extends Element['ElementType']>(
  results: Results | null,
  id: string,
  elementType: T
): ElementValueTypes[T] | null {
  const value = results?.Answers[id] ?? null
  const codec = ElementTypeCodecs[elementType]
  if (value === null) {
    return null
  }
  const decoded = codec.decode(value)
  return E.isRight(decoded)
    ? typeof decoded.right === 'string'
      ? decoded.right === '' ? null : decoded.right
      : decoded.right
    : null
}

const TREE_GET_PREFIX = 'treeGet'
export function getElementDefaultValue<T extends Element['ElementType']>(
  elementType: T,
  defaultResult: string | null | undefined,
  defaultValueContext: DefaultValueContext
): ElementValueTypes[T] | null {
  let localDefaultResult = defaultResult
  if (isNullOrUndefined(defaultResult)) {
    return null
  }
  const codec = ElementTypeDefaultValueCodecs[elementType]
  if (defaultResult.startsWith(`${TREE_GET_PREFIX}:`)) {
    const [, path] = defaultResult.split(':')
    if (path === undefined) {
      return null
    }
    const treeValue = treeGet(defaultValueContext, path.split('.'))
    if (codec.is(treeValue)) {
      return treeValue === '' ? null : treeValue
    }
    if (typeof treeValue !== 'string') {
      return null
    }
    localDefaultResult = treeValue
  }
  const decoded = codec.decode(localDefaultResult)
  return E.isRight(decoded)
    ? typeof decoded.right === 'string'
      ? decoded.right === '' ? null : decoded.right
      : decoded.right
    : null
}

export function getElementDependencyValue(
  dependencyValue: string | null | undefined,
  expectedResult: string | null | undefined
): RegExp | null {
  const decoded = RegExpFromString.decode(dependencyValue)
  return E.isRight(decoded)
    ? decoded.right
    : getElementExpectedValue(expectedResult)
}

export function getElementExpectedValue(
  expectedResult?: string | null
): RegExp | null {
  const decoded = RegExpFromString.decode(expectedResult)
  return E.isRight(decoded)
    ? decoded.right
    : null
}

export function isElementActive(state: ElementState | undefined): boolean {
  if (state === undefined) {
    return false
  }
  return state.hidden !== true
    && (
      state.dependencyCount === 0
      || state.unmetDependencies.length < state.dependencyCount
    )
}

export function isElementRequired(element: Element): boolean {
  return element.Required ?? false
}

export function hasError(state: ElementState | undefined): boolean {
  return (state?.requiredError ?? false)
    || (state?.constraintError ?? false)
}

export function encodeElementValue<T extends Element['ElementType']>(
  elementType: T,
  value: ElementValueTypes[T] | null
): Json {
  if (value === null) {
    return null
  }
  const codec = ElementTypeCodecs[elementType]
  return codec.encode(value)
}

export function stateToResults(state: State): Results {
  const allElements = state.dataform.Groups
    .flatMap(group => group.Elements)
    .map(element => [element, state.elements[element.Id]] as const)
  const activeElements = allElements
    .filter(([element, state]) => isElementActive(state))
  return {
    DataformId: state.dataform.Id,
    Aliases: activeElements
      .reduce((prev, [element]) => {
        prev[element.Alias] = element.Id
        return prev
      }, {} as Results['Aliases']),
    Answers: activeElements
      .reduce((prev, [element, state]) => {
        prev[element.Id] = encodeElementValue(element.ElementType, state?.value ?? null)
        return prev
      }, {} as Results['Answers']),
    Complete: state.complete,
    AsExpected: activeElements.every(([element, state]) => state?.asExpected ?? true)
  }
}

export function satisfiesRequiredValidation<T extends Element['ElementType']>(
  elementType: T,
  value: unknown
): boolean {
  return ElementTypeCodecs[elementType].is(value)
    && ElementTypeRequiredValidation[elementType](value)
}

export function getDependencyGraph(dataform: Dataform): ElementGraph {
  const elementGraph: ElementGraph = {}
  const allElements = dataform.Groups.flatMap(group => group.Elements)
  for (const element of allElements) {
    elementGraph[element.Id] = (element.DepsId ?? []).map(dependency => dependency.ControllingId).filter(t.string.is)
  }
  return elementGraph
}

export function getCycle(
  id: string,
  elementGraph: ElementGraph,
  visitedElements: string[] = []
): string[] | null {
  const nextElements = elementGraph[id] ?? []
  if (nextElements.some(id => visitedElements.includes(id))) {
    return visitedElements
  }
  for (const nextId of nextElements) {
    const result = getCycle(nextId, elementGraph, [...visitedElements, id])
    if (Array.isArray(result)) {
      return result
    }
  }
  return null
}
