import { DragHandle } from '@mui/icons-material'
import { Box, FormControlLabel, styled, Switch, TextField } from '@mui/material'
import ScrollBar from 'components/navigation/ScrollBar'
import { ChangeEvent, PropsWithChildren, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { useDrop } from 'react-dnd'
import { Flipped, Flipper } from 'react-flip-toolkit'
import { PATH_SEPARATOR } from 'utils/constants'
import { makeSortFunction } from 'utils/helpers'
import { useDragToReorder } from 'utils/hooks'

const PREFIX = 'SortableToggleList'
const classes = {
  container: `${PREFIX}-container`,
  item: `${PREFIX}-item`,
  switch: `${PREFIX}-switch`
}
const Root = styled('div')((
  {
    theme
  }
) => ({
  width: '100%',
  [`& .${classes.container}`]: {
    padding: '8px 0px 8px 8px'
  },
  [`& .${classes.item}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1px 8px 1px 7px'
  },
  [`& .${classes.switch}`]: {
    marginRight: 4
  }
}))

interface ItemProps {
  readonly item: Item
  readonly itemOrder: string[]
  readonly searchValue: string
  dispatch(action: string[] | string): void
  onCommitItemOrder(itemOrder: string[]): void
  onToggleItem(id: string): void
}

function SortableToggleItem({
  item,
  itemOrder,
  searchValue,
  dispatch,
  onCommitItemOrder,
  onToggleItem
}: ItemProps): JSX.Element {
  const onToggle = useCallback(() => {
    onToggleItem(item.id)
  }, [item.id, onToggleItem])
  const onSwitchClick = useCallback(() => {
    dispatch(item.id)
    onToggle()
  }, [dispatch, item.id, onToggle])
  const dropRef = useRef<HTMLDivElement | null>(null)
  const {
    drag,
    preview,
    drop,
    opacity
  } = useDragToReorder(
    item.id,
    itemOrder,
    'item',
    dropRef,
    'vertical',
    dispatch,
    onCommitItemOrder
  )
  const toggle = useMemo(() => {
    return (
      <FormControlLabel
        control={(
          <Switch
            className={classes.switch}
            checked={item.checked}
            disabled={item.disableToggle ?? false}
            onClick={onSwitchClick}
            color='primary'
            size='small' />
        )}
        label={item.title} />
    )
  }, [item.checked, item.disableToggle, item.title, onSwitchClick])
  return (
    <Flipped flipId={item.id}>
      <div
        ref={item.checked
          ? (node => {
            dropRef.current = node
            preview(drop(node))
          })
          : undefined}
        className={classes.item}
        style={{ opacity }}>
        {toggle}
        {item.checked && searchValue === '' && (
          <div
            ref={drag}
            style={{ cursor: 'move' }}>
            <DragHandle fontSize='small' />
          </div>
        )}
      </div>
    </Flipped>
  )
}

export interface Item {
  readonly id: string
  readonly title: string
  readonly checked: boolean
  readonly disableToggle?: boolean
}

function reducer(state: string[], action: string[] | string): string[] {
  if (Array.isArray(action)) {
    return action
  }
  if (state.includes(action)) {
    return state.filter(id => action !== id)
  } else {
    return [...state, action]
  }
}

interface Props {
  readonly textFieldLabel: string
  readonly textFieldPlaceholder: string
  readonly items: Item[]
  readonly itemOrder: string[]
  readonly maxHeight?: number
  onToggle(id: string): void
  onChangeItemOrder(itemOrder: string[]): void
}

export default function SortableToggleList({
  textFieldLabel,
  textFieldPlaceholder,
  items,
  itemOrder,
  maxHeight,
  onChangeItemOrder,
  onToggle,
  children
}: PropsWithChildren<Props>): JSX.Element {
  const [searchValue, setSearchValue] = useState('')
  const onSearchValueChange = useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchValue(event.target.value)
  }, [])
  const [, drop] = useDrop(() => ({ accept: 'item' }))
  const [localItemOrder, dispatch] = useReducer(reducer, itemOrder)
  useEffect(() => {
    dispatch(itemOrder)
  }, [itemOrder])
  const ref = useRef<HTMLInputElement | null>(null)
  const onItemToggle = useCallback((id: string) => {
    onToggle(id)
    ref.current?.select()
  }, [onToggle])
  return (
    <Root>
      <Box
        display='flex'
        flexDirection='column'
        flex={1}>
        <Box p={1}>
          <TextField
            inputRef={ref}
            label={textFieldLabel}
            placeholder={textFieldPlaceholder}
            autoFocus
            value={searchValue}
            onChange={onSearchValueChange}
            variant='standard'
            fullWidth />
        </Box>
        <Box
          display='flex'
          flexDirection='column'
          overflow='auto'
          flex='1 1'
          maxHeight={maxHeight}>
          <ScrollBar style={{ maxHeight: maxHeight }}>
            {children}
            <Flipper flipKey={localItemOrder.join(',')}>
              <div
                className={classes.container}
                ref={drop}>
                {items
                  .filter(item => {
                    if (searchValue === '') {
                      return true
                    }
                    return item.title.replaceAll(PATH_SEPARATOR, ' ').toLowerCase().indexOf(searchValue.toLowerCase()) > -1
                  })
                  .sort(makeSortFunction(localItemOrder, item => item.id))
                  .map(item => (
                    <SortableToggleItem
                      key={item.id}
                      item={item}
                      itemOrder={localItemOrder}
                      searchValue={searchValue}
                      dispatch={dispatch}
                      onCommitItemOrder={onChangeItemOrder}
                      onToggleItem={onItemToggle} />
                  ))}
              </div>
            </Flipper>
          </ScrollBar>
        </Box>
      </Box>
    </Root>
  )
}
