import { Alert, alertClasses, Box, ButtonGroup, Collapse } from '@mui/material'
import AppendDomainTypeContext from 'components/domainType/AppendDomainTypeContext'
import DomainTypeButtons from 'components/domainType/DomainTypeButtons'
import TextIconButton from 'components/utils/TextIconButton'
import { ComponentProps, PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { useDrop } from 'react-dnd'
import { Flipped, Flipper } from 'react-flip-toolkit'
import { TransitionGroup } from 'react-transition-group'
import { DomainType, DomainTypeInstance } from 'types'
import { Dataform, Element, ElementValueTypes, Group } from 'types/dataform'
import { isNullOrUndefined, makeSortFunction } from 'utils/helpers'
import { useDomainType } from 'utils/hooks'
import { isElementActive, State } from './dataformReducer'
import ElementInput from './ElementInput'

interface Props {
  readonly dataform: Dataform | null
  readonly elements: State['elements']
  readonly group: Group | undefined
  onElementValueChange<E extends Element>(
    element: E,
    value: ElementValueTypes[E['ElementType']]
  ): void
  readonly formReadOnly: boolean
  readonly showRequiredErrors: boolean
  readonly editable: boolean
  onChangeElementOrder(elementOrder: string[]): void
}

const components: ComponentProps<typeof DomainTypeButtons>['components'] = {
  Container: props => (
    <ButtonGroup
      variant='contained'
      size='small'
      {...props} />
  ),
  Button: TextIconButton,
  Empty: null
}

function GroupBox(props: PropsWithChildren<unknown>): JSX.Element {
  return (
    <Box
      component='form'
      display='flex'
      flexDirection='column'
      paddingLeft={2}
      paddingRight={2}
      paddingTop={1}
      paddingBottom={1}
      autoComplete='off'>
      {props.children}
    </Box>
  )
}

export default function GroupInput({
  dataform,
  elements,
  group,
  onElementValueChange,
  formReadOnly,
  showRequiredErrors,
  editable,
  onChangeElementOrder
}: Props): JSX.Element | null {
  const groupDomainType = useDomainType('DataformGroup', 'DataformGroup')
  const elementDomainType = useDomainType('DataformElement', 'DataformElement')
  const elementsAttribute = groupDomainType?.Attributes
    .find(attribute => attribute.Name === 'Elements')
  const newInstances = useMemo<[DomainType, DomainTypeInstance][]>(() => {
    return !isNullOrUndefined(groupDomainType)
      ? [[groupDomainType, group as unknown as DomainTypeInstance]]
      : []
  }, [group, groupDomainType])
  const newAttributes = useMemo(() => {
    return !isNullOrUndefined(elementsAttribute)
      ? [elementsAttribute]
      : []
  }, [elementsAttribute])
  const [, drop] = useDrop(() => ({ accept: 'element' }))
  const elementOrder = useMemo(() => {
    return group?.Elements.map(element => element.Id) ?? []
  }, [group?.Elements])
  const [localElementOrder, setLocalElementOrder] = useState(elementOrder)
  useEffect(() => {
    setLocalElementOrder(elementOrder)
  }, [elementOrder])
  if (dataform === null || group === undefined) {
    return null
  }
  const elementButtons = (
    <>
      {editable && !isNullOrUndefined(elementDomainType) && (
        <DomainTypeButtons
          components={components}
          domainType={elementDomainType}
          target={{
            type: 'instances',
            instances: []
          }}
          on='TableToolbar' />
      )}
    </>
  )
  // TODO - different group types
  return (
    <AppendDomainTypeContext
      newInstances={newInstances}
      newAttributes={newAttributes}>
      {group.Elements.length === 0
        ? (
          <Alert
            severity='info'
            action={elementButtons}
            sx={{
              [`& .${alertClasses.action}`]: {
                padding: 0,
                alignItems: 'center'
              }
            }}>
            This group has no elements
          </Alert>
        )
        : (
          <div ref={drop}>
            <Flipper flipKey={localElementOrder.join(',')}>
              <TransitionGroup
                component={GroupBox}>
                {group.Elements
                  // TODO - show disabled elements setting 
                  .filter(element => isElementActive(elements[element.Id]) || editable)
                  .sort(makeSortFunction(localElementOrder, element => element.Id))
                  .map(element => {
                    return (
                      <Collapse key={element.Id}>
                        <Flipped
                          flipId={element.Id}>
                          <div>
                            <ElementInput
                              dataform={dataform}
                              state={elements[element.Id]}
                              group={group}
                              element={element}
                              elementOrder={localElementOrder}
                              onElementValueChange={onElementValueChange}
                              onChangeElementOrder={setLocalElementOrder}
                              onCommitElementOrder={onChangeElementOrder}
                              formReadOnly={formReadOnly}
                              showRequiredErrors={showRequiredErrors}
                              editable={editable} />
                          </div>
                        </Flipped>
                      </Collapse>
                    )
                  })
                  .concat([
                    <Collapse key='buttons'>
                      {elementButtons}
                    </Collapse>
                  ])}
              </TransitionGroup>
            </Flipper>
          </div>
        )}
    </AppendDomainTypeContext>
  )
}