import { TextFieldProps } from '@mui/material'
import Help from 'components/utils/Help'
import { AttributeValue, PathError } from 'types'
import { renderAttributeTypeComponent } from 'utils/helpers'
import BoolInput from './BoolInput'
import ContextRefInput from './ContextRefInput'
import DataformResultsInput from './DataformResultsInput'
import DateInput from './DateInput'
import DateTimeInput from './DateTimeInput'
import DomainTypeInput from './DomainTypeInput/DomainTypeInput'
import EnumInput from './EnumInput'
import GuidInput from './GuidInput'
import MultiDataformResultsInput from './MultiDataformResultsInput'
import NumberInput from './NumberInput'
import RefInput from './RefInput'
import StringInput from './StringInput'

interface Props {
  readonly readOnly: boolean
  readonly attributeValue: AttributeValue
  readonly pathError?: PathError
  readonly disabled?: boolean
  readonly textFieldProps?: Partial<TextFieldProps>
  onChange?(attributeValue: AttributeValue): void
}

export interface NarrowedProps<A extends AttributeValue> {
  readonly readOnly: boolean
  readonly attributeValue: A
  readonly pathError?: PathError
  readonly disabled?: boolean
  readonly textFieldProps?: Partial<TextFieldProps>
  onChange?(attributeValue: A): void
}

const inputTypes = {
  ref: RefInput,
  contextRef: ContextRefInput,
  domainType: DomainTypeInput,
  enum: EnumInput,
  date: DateInput,
  dateTime: DateTimeInput,
  number: NumberInput,
  guid: GuidInput,
  bool: BoolInput,
  string: StringInput,
  dataformResults: DataformResultsInput,
  multiDataformResults: MultiDataformResultsInput
}

export default function AttributeInput(props: Props): JSX.Element | null {
  const [isValid, component] = renderAttributeTypeComponent(inputTypes, {
    ...props,
    readOnly: props.readOnly || (props.attributeValue.attribute.ReadOnly ?? false)
  })
  if (isValid) {
    return (
      <Help
        type='attribute'
        attribute={props.attributeValue.attribute}>
        {component}
      </Help>
    )
  }
  return null
}
