import { LoadingButton } from '@mui/lab'
import { Alert, AlertTitle, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, TextField } from '@mui/material'
import AttributeForm from 'components/attribute/AttributeForm'
import AppendDomainTypeContext from 'components/domainType/AppendDomainTypeContext'
import * as E from 'fp-ts/Either'
import * as t from 'io-ts'
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { confirmInterestInJobs } from 'state/actions/jobs'
import { getAllDomainTypes, getUser } from 'state/reducers'
import { ActionEffect, ActionParameter, ActionPaths, ActionResponse, ApiError, Attribute, AttributeValue, ContextDomainTypeNode, ContextTree, DomainType, DomainTypeAction, DomainTypeInstance, EffectResult, EffectResults, FileActionParameter, PathError, PathErrors, User } from 'types'
import { EffectResultCodec, PathErrorsCodec } from 'utils/codecs'
import { DEFAULT_ATTRIBUTE_INPUT_SIZE, DEFAULT_ATTRIBUTE_INPUT_VARIANT } from 'utils/constants'
import { FormModeContext } from 'utils/context'
import { getAttributeValue, getBatchInstances, getDomainTypeAttribute, getDomainTypeSetting, getNodes, getRootDomainType, isInRole, isNotNullOrUndefined, isNullOrUndefined, requiresNoInstances, toErrorText, toText, validateRequiredAttributes } from 'utils/helpers'
import { ActionButton, ButtonTarget, useApi, usePathErrors, useStrictModeEffect } from 'utils/hooks'
import UploadFileInput, { UploadFileInputHandle } from '../utils/UploadFileInput'
import ActionEffectResultsView, { ActionEffectResults } from './ActionEffectResultsView'
import ConfirmationDialog from './ConfirmationDialog'
import ContextTreeView, { NODE_ID_SEPARATOR, getNodeIds } from './ContextTreeView'
import DomainTypeHeading from './DomainTypeHeading'

function createRequestTree(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType,
  contextTree: ContextDomainTypeNode[] | null,
  selectedNodeIds: string[],
  nodeIdPath: string[] = []
): unknown[] {
  if (contextTree === null) {
    return []
  }
  const identifier = getDomainTypeSetting(domainTypes, domainType, 'Identifier') ?? 'Id'
  return contextTree
    .filter((node, index) => selectedNodeIds
      .includes([...nodeIdPath, String(index)].join(NODE_ID_SEPARATOR)))
    .map((node, index) => createRequestNode(
      domainTypes,
      identifier,
      node,
      selectedNodeIds,
      [...nodeIdPath, String(index)]
    ))
}

function createRequestNode(
  domainTypes: Partial<Record<string, DomainType>>,
  identifier: string,
  node: ContextDomainTypeNode,
  selectedNodeIds: string[],
  nodeIdPath: string[]
): unknown {
  return {
    [identifier]: node.instance[identifier],
    ...node.nodes.reduce<Record<string, unknown>>((prev, curr) => {
      const attributeDomainType = domainTypes[curr.attribute.AttributeDomainType]
      if (attributeDomainType === undefined) {
        return prev
      }
      prev[curr.attribute.Name] = createRequestTree(
        domainTypes,
        attributeDomainType,
        curr.nodes,
        selectedNodeIds,
        [...nodeIdPath, curr.attribute.Name]
      )
      return prev
    }, {})
  }
}

function getPathEffectResults(
  response: ActionPaths | undefined,
  contextTree: ContextDomainTypeNode[]
): ContextTree<EffectResults> {
  return contextTree.map<ContextDomainTypeNode<EffectResults>>((node, index) => {
    const domainTypeResponse = response?.[index]
    return {
      ...node,
      nodes: node.nodes.map(attributeNode => {
        const attributeResponse = domainTypeResponse?.[attributeNode.attribute.Name]
        return {
          ...attributeNode,
          nodes: getPathEffectResults(
            Array.isArray(attributeResponse) && !t.array(EffectResultCodec).is(attributeResponse)
              ? attributeResponse
              : undefined,
            attributeNode.nodes
          )
        }
      }),
      EffectResults: domainTypeResponse?.EffectResults
    }
  })
}

function hasError(effectResult: EffectResult): boolean {
  switch (effectResult.Type) {
    case 'CreateItemsActionEffect':
      return effectResult.Result === 'Error'
        || effectResult.Items.some(createResult => createResult.Result === 'Error')
    default:
      return effectResult.Result === 'Error'
  }
}

function hasDisplayableSuccess(
  effectResult: EffectResult,
  effects: ActionEffect[],
  domainTypes: Partial<Record<string, DomainType>>,
  user: User | null
): boolean {
  switch (effectResult.Type) {
    case 'CreateItemsActionEffect': {
      if (effectResult.Result !== 'Success'
        || !effectResult.Items.some(createResult => createResult.Result === 'Success')) {
        return false
      }
      const effect = effects.find(effect => effect.Name === effectResult.Name)
      if (effect === undefined) {
        return false
      }
      if (effect.Type !== 'CreateItemsActionEffect') {
        return false
      }
      const viewRole = getDomainTypeSetting(
        domainTypes,
        domainTypes[effect.DomainType],
        'ViewRole'
      )
      return isInRole(user, viewRole)
    }
    case 'DownloadFromFileStoreActionEffect':
    case 'QueueJobActionEffect':
    case 'DownloadInstanceActionEffect':
    case 'MoveEquipmentActionEffect':
      return true
    default:
      return false
  }
}

function isFile(parameter: ActionParameter): parameter is FileActionParameter {
  return parameter.Type === 'FileActionParameter'
}

function hasNoEffects(action: DomainTypeAction): boolean {
  return !(action.Parameters ?? []).some(parameter => parameter.Type === 'AttributeActionParameter')
    && (action.Effects ?? []).length === 0
}

function validateRequiredParameters(
  attributeValues: AttributeValue[],
  fileParameters: FileActionParameter[],
  files: Partial<Record<string, [FileActionParameter['UploadType'], File[]]>>
): PathErrors | undefined {
  let pathErrors = validateRequiredAttributes(attributeValues)
  for (const parameter of fileParameters) {
    if (isNullOrUndefined(files[parameter.Name]) && parameter.Required === true) {
      pathErrors = {
        ...pathErrors,
        [parameter.Name]: `${parameter.Name} is required`
      }
    }
  }
  return pathErrors
}

interface Props {
  readonly open: boolean
  readonly actionButton: ActionButton
  readonly target: ButtonTarget
  onClose(): void
  onPerform(): void
}

export default function ActionDialog({
  open,
  actionButton,
  target,
  onClose,
  onPerform
}: Props): JSX.Element {
  const domainTypes = useSelector(getAllDomainTypes)
  const [actionEffectResults, setActionEffectResults] = useState<ActionEffectResults | null>(null)
  const [isPerforming, setIsPerforming] = useState(false)
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false)
  const {
    pathErrors = {},
    setPathErrors,
    removeErrorAtPath
  } = usePathErrors({})
  const [apiError, setApiError] = useState<ApiError | null>(null)
  const domainType = actionButton.apiDomainType
  const rootDomainType = getRootDomainType(domainTypes, domainType)
  const api = useApi()
  const isAdmin = useMemo(() => {
    return requiresNoInstances(actionButton.action)
  }, [actionButton.action])

  const isNestedBatchAction = useMemo(() => {
    return getNodes(actionButton.contextTree).some(node => node.type === 'nested')
  }, [actionButton.contextTree])

  const staticParams = useMemo(() => actionButton.action.Parameters?.reduce<DomainTypeInstance>((prev, current) => {
    if (current.Type === 'StaticActionParameter') {
      prev[current.Name] = current.Value
    }
    return prev
  }, {}) ?? {}, [actionButton.action.Parameters])
  const activeInstances = useMemo(() => {
    return getNodes(actionButton.contextTree)
      .filter(node => node.domainType.Id === actionButton.pageDomainType.Id)
      .map(node => node.instance)
  }, [actionButton.contextTree, actionButton.pageDomainType.Id])
  const singleInstance = useMemo(() => {
    return activeInstances.length === 1
      ? activeInstances[0]
      : undefined
  }, [activeInstances])
  const defaultAttributeValues = useMemo(() => {
    return (actionButton.action.Parameters ?? [])
      .filter(parameter => !actionButton.parameterValues
        ?.find(({ attribute }) => attribute.Name === parameter.Name))
      .flatMap(parameter => {
        if (parameter.Type === 'FileActionParameter') {
          return []
        }

        let attribute

        if (parameter.Type === 'AttributeActionParameter') {
          attribute = getDomainTypeAttribute(domainTypes, actionButton.domainType, parameter.Attribute)
          if (attribute !== undefined && parameter.ReadOnly === true) {
            attribute = {
              ...attribute,
              ReadOnly: true
            }
          }
        }
        if (parameter.Type === 'InputAttributeActionParameter') {
          attribute = parameter.Attribute
        }

        if (attribute === undefined) {
          return []
        }

        const modifiedAttribute: Attribute = {
          ...attribute,
          Name: parameter.Name,
          Required: parameter.Required
        }
        return [getAttributeValue(singleInstance ?? {}, modifiedAttribute)]
      })
  }, [actionButton.action.Parameters, actionButton.domainType, actionButton.parameterValues, domainTypes, singleInstance])

  const [attributeValues, setAttributeValues] = useState(defaultAttributeValues)
  useEffect(() => {
    setAttributeValues(defaultAttributeValues)
  }, [defaultAttributeValues])

  const fileParameters = useMemo(() => {
    return actionButton.action.Parameters
      ?.filter(isFile) ?? []
  }, [actionButton.action.Parameters])
  const fileInputsRef = useRef<Partial<Record<string, UploadFileInputHandle | null>>>({})
  const [files, setFiles] = useState<Partial<Record<string, [FileActionParameter['UploadType'], File[]]>>>({})

  const dispatch = useDispatch()
  const onSuccess = useCallback(() => {
    dispatch(confirmInterestInJobs())
    onPerform()
    setActionEffectResults(null)
  }, [dispatch, onPerform])

  const defaultSelectedNodeIds = useMemo(() => {
    return actionButton
      .contextTree.flatMap((node, index) => getNodeIds(node, [String(index)]))
  }, [actionButton.contextTree])
  const [selectedNodeIds, setSelectedNodeIds] = useState<string[]>(defaultSelectedNodeIds)
  useEffect(() => {
    setSelectedNodeIds(defaultSelectedNodeIds)
  }, [defaultSelectedNodeIds])
  const user = useSelector(getUser)

  const performAction = useCallback(async () => {
    setIsPerforming(true)
    if (rootDomainType === null
      || !api.isSignedIn) {
      setIsPerforming(false)
      return
    }
    const parameters = attributeValues.concat(actionButton.parameterValues ?? []).reduce<DomainTypeInstance>((prev, curr) => {
      prev[curr.attribute.Name] = curr.value
      return prev
    }, staticParams)
    const getFilesByUploadType = (uploadType: FileActionParameter['UploadType']) => {
      return Object.keys(files).filter(key => files[key]?.[0] === uploadType).reduce<File[]>((prev, curr) => {
        const filesToUpload: File[] | undefined = files[curr]?.[1]
        if (isNullOrUndefined(filesToUpload) || filesToUpload.length === 0) {
          return []
        }

        const firstFile = filesToUpload[0]

        if (firstFile === undefined) {
          return []
        }

        // currently only support storing one file against a parameter, but support uploading multiple files 
        parameters[curr] = {
          Name: firstFile.name,
          Type: firstFile.type,
          Size: firstFile.size
        }
        return [prev, filesToUpload].flat()
      }, [])
    }
    const cacheFilesToUpload = getFilesByUploadType('cache')
    if (cacheFilesToUpload.length > 0) {
      await api.uploadToCache(cacheFilesToUpload)
    }
    const customFilesToUpload = getFilesByUploadType('custom')
    if (customFilesToUpload.length > 0) {
      await api.upload(rootDomainType.Name, customFilesToUpload)
    }
    if (hasNoEffects(actionButton.action)) {
      onSuccess()
      setIsPerforming(false)
      return
    }

    const response = target.type === 'instances' || target.type === 'none'
      ? await api.action(
        rootDomainType.Name,
        parameters,
        actionButton.domainType,
        actionButton.action.Name,
        createRequestTree(domainTypes, domainType, actionButton.contextTree, selectedNodeIds)
      )
      : await api.actionByQuery(
        rootDomainType.Name,
        parameters,
        actionButton.domainType,
        actionButton.action.Name,
        target.query,
        target.total
      )
    E.match<ApiError, ActionResponse, void>(
      apiError => {
        setApiError(apiError)
        const responsePathErrors = apiError.pathErrors
        if (responsePathErrors === undefined) {
          setPathErrors({})
        } else if (PathErrorsCodec.is(responsePathErrors.Parameters)) {
          setPathErrors(responsePathErrors.Parameters)
        }
      },
      actionResponse => {
        setApiError(null)
        const pathEffectResults = getPathEffectResults(
          actionResponse.Paths,
          actionButton.contextTree
        )
        const requestEffectResults = actionResponse.EffectResults
        const actionInstanceNodes = getNodes(pathEffectResults)
        const hasEffectErrors = actionInstanceNodes
          .flatMap(node => node.EffectResults ?? [])
          .concat(requestEffectResults)
          .some(hasError)
        const hasDisplayableEffectSucces = actionInstanceNodes
          .flatMap(node => node.EffectResults ?? [])
          .concat(requestEffectResults)
          .some(effectResult => hasDisplayableSuccess(
            effectResult,
            actionButton.action.Effects ?? [],
            domainTypes,
            user
          ))
        if (hasEffectErrors || hasDisplayableEffectSucces) {
          setActionEffectResults({
            PathEffectResults: pathEffectResults,
            RequestEffectResults: requestEffectResults
          })
        } else {
          onSuccess()
        }
      }
    )(response)
    setIsPerforming(false)
  }, [attributeValues, files, rootDomainType, api, actionButton.parameterValues, actionButton.action, actionButton.domainType, actionButton.contextTree, staticParams, target, domainTypes, domainType, selectedNodeIds, setPathErrors, onSuccess, user])

  const showConfirmation = useCallback(() => {
    setConfirmationDialogOpen(true)
  }, [])

  const confirmationDialogClosed = useCallback((result: boolean) => {
    setConfirmationDialogOpen(false)
    if (result) {
      performAction()
    }
  }, [performAction])

  const actionClicked = useCallback(async () => {
    const requiredPathErrors = validateRequiredParameters(
      attributeValues,
      fileParameters,
      files
    )
    if (requiredPathErrors !== undefined) {
      setPathErrors(requiredPathErrors)
      return
    }

    if (isNotNullOrUndefined(actionButton.confirmationAlert)) {
      showConfirmation()
    } else {
      performAction()
    }
  }, [attributeValues, fileParameters, files, actionButton.confirmationAlert, setPathErrors, showConfirmation, performAction])

  const requiresInput = useMemo(() => {
    return attributeValues.length > 0 || fileParameters.length > 0
  }, [attributeValues.length, fileParameters.length])
  const shouldPerformAutomatically = useMemo(() => {
    return !requiresInput
      && apiError === null
      && target.type === 'instances'
      && open
      && !isPerforming
      && isNullOrUndefined(actionEffectResults)
      && !isNestedBatchAction
      && isNullOrUndefined(actionButton.alert)
  }, [requiresInput, apiError, target.type, open, isPerforming, actionEffectResults, isNestedBatchAction, actionButton.alert])
  useStrictModeEffect(() => {
    if (shouldPerformAutomatically) {
      actionClicked()
    }
  }, [actionClicked, shouldPerformAutomatically])
  const [severity, message] = useMemo(() => {
    const nodes = getNodes(actionEffectResults?.PathEffectResults ?? [])
    const hasEffectErrors = nodes
      .flatMap(node => node.EffectResults ?? [])
      .concat(actionEffectResults?.RequestEffectResults ?? [])
      .some(hasError)
    return hasEffectErrors === true
      ? ['warning', 'There was an error triggering one or more action effects'] as const
      : ['success', 'All action effects triggered successfully'] as const
  }, [actionEffectResults])
  const newBatchInstances = useMemo(() => {
    return getBatchInstances(actionButton.contextTree)
  }, [actionButton.contextTree])
  const onDialogClose = useMemo(() => {
    return isNullOrUndefined(actionEffectResults)
      ? undefined
      : onSuccess
  }, [actionEffectResults, onSuccess])
  const disabled = useMemo(() => {
    return isPerforming || (target.type === 'instances' && selectedNodeIds.length === 0 && !isAdmin)
  }, [isAdmin, isPerforming, selectedNodeIds.length, target.type])

  const parameterPathErrors = useMemo(() => {
    return (actionButton.parameterValues ?? [])
      .reduce((parameterPathErrors: Partial<Record<string, PathError>>, parameterValue: AttributeValue) => {
        if (!(parameterValue.attribute.Name in pathErrors)) {
          return parameterPathErrors
        }
        parameterPathErrors[parameterValue.attribute.Name] = pathErrors[parameterValue.attribute.Name]
        return parameterPathErrors
      }, {})
  }, [actionButton.parameterValues, pathErrors])
  const count = useMemo(() => {
    if (target.type === 'none') {
      return 0
    }

    return target.type === 'instances'
      ? activeInstances.length
      : target.total
  }, [activeInstances.length, target])
  const effects = useMemo((): ActionEffect[] => {
    return target.type === 'query'
      ? [
        {
          Name: actionButton.action.Name,
          Type: 'QueueJobActionEffect',
          Target: 'request',
          DownloadFile: false
        }
      ]
      : actionButton.action.Effects ?? []
  }, [actionButton.action.Effects, actionButton.action.Name, target.type])
  return (
    <AppendDomainTypeContext
      newBatchInstances={newBatchInstances}>
      <Dialog
        fullWidth
        maxWidth='md'
        open={open}
        onKeyDown={event => event.stopPropagation()}
        onClose={onDialogClose}>
        {isNullOrUndefined(actionEffectResults)
          ? (
            <>
              <DialogTitle>
                <DomainTypeHeading
                  domainType={actionButton.pageDomainType}
                  instance={singleInstance}
                  isLoading={false}
                  title={`${actionButton.name}:`}
                  plural={count > 1}
                  count={count} />
              </DialogTitle>
              <DialogContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1
                }}>
                {isNestedBatchAction && (
                  <Alert
                    severity='info'>
                    <AlertTitle>
                      Select the items on which to perform the action
                    </AlertTitle>
                    <ContextTreeView
                      contextTree={actionButton.contextTree}
                      includeRootNode
                      defaultUnexpanded
                      selectable
                      selectedNodeIds={selectedNodeIds}
                      onSelectionChange={setSelectedNodeIds} />
                  </Alert>
                )}
                {isNotNullOrUndefined(actionButton.alert) && (
                  <Alert
                    severity={actionButton.alert.Severity}>
                    <AlertTitle>
                      {actionButton.alert.Text}
                    </AlertTitle>
                  </Alert>)}
                {Object.values(parameterPathErrors).map((pathError, index) => (
                  <Alert
                    key={index}
                    severity='error'>
                    {toErrorText(pathError)}
                  </Alert>
                ))}
                {apiError !== null && (
                  <Alert
                    severity='error'>
                    {toText(apiError)}
                  </Alert>
                )}
                {fileParameters.length > 0 && (
                  <Box
                    component='form'
                    display='flex'
                    flexDirection='column'
                    gap={1}
                    autoComplete='off'>
                    {actionButton.action.Parameters
                      ?.filter(isFile)
                      .map(parameter => (
                        <Fragment key={parameter.Name}>
                          <UploadFileInput
                            ref={handle => fileInputsRef.current[parameter.Name] = handle}
                            accept={parameter.Accept ?? undefined}
                            multiple={parameter.Multiple}
                            onChange={inputFiles => {
                              removeErrorAtPath(parameter.Name)
                              setFiles({
                                ...files,
                                [parameter.Name]: isNullOrUndefined(inputFiles) || inputFiles.length === 0
                                  ? undefined
                                  : [parameter.UploadType, inputFiles]
                              })
                            }} />
                          <TextField
                            label={parameter.Name}
                            variant={DEFAULT_ATTRIBUTE_INPUT_VARIANT}
                            fullWidth
                            size={DEFAULT_ATTRIBUTE_INPUT_SIZE}
                            spellCheck='false'
                            value={files[parameter.Name]?.[1]?.[0]?.name ?? ''}
                            required={parameter.Required}
                            error={pathErrors[parameter.Name] !== undefined}
                            helperText={toErrorText(pathErrors[parameter.Name])}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position='end'>
                                  <Button
                                    variant='text'
                                    size='small'
                                    onClick={async () => {
                                      const input = fileInputsRef.current[parameter.Name]
                                      if (isNullOrUndefined(input)) {
                                        return
                                      }
                                      input.open()
                                    }}>
                                    Choose File
                                  </Button>
                                </InputAdornment>
                              )
                            }} />
                        </Fragment>
                      ))}
                  </Box>
                )}
                {attributeValues.length > 0 && (
                  <FormModeContext.Provider value='create'>
                    <AttributeForm
                      attributeValues={attributeValues}
                      pathErrors={pathErrors}
                      onChange={attributeValue => {
                        removeErrorAtPath(attributeValue.attribute.Name)
                        const index = attributeValues
                          .findIndex(a => a.attribute.Name === attributeValue.attribute.Name)
                        const newAttributeValues = [
                          ...attributeValues.slice(0, index),
                          attributeValue,
                          ...attributeValues.slice(index + 1)
                        ]
                        setAttributeValues(newAttributeValues)
                      }}
                      onSubmit={actionClicked} />
                  </FormModeContext.Provider>
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  variant='text'
                  disabled={isPerforming}
                  onClick={onClose}>
                  Cancel
                </Button>
                <LoadingButton
                  loading={isPerforming || shouldPerformAutomatically}
                  disabled={disabled}
                  onClick={actionClicked}>
                  {actionButton.name}
                </LoadingButton>
              </DialogActions>
            </>
          )
          : (
            <>
              <DialogTitle>
                <DomainTypeHeading
                  domainType={actionButton.pageDomainType}
                  instance={singleInstance}
                  isLoading={false}
                  title={`${actionButton.name}:`}
                  plural={count > 1}
                  count={count} />
              </DialogTitle>
              <DialogContent>
                <Alert severity={severity}>
                  <AlertTitle>
                    {message}
                  </AlertTitle>
                  {!isNullOrUndefined(actionEffectResults) && (
                    <ActionEffectResultsView
                      effects={effects}
                      actionEffectResults={actionEffectResults} />
                  )}
                </Alert>
              </DialogContent>
              <DialogActions>
                <Button
                  variant='text'
                  onClick={onSuccess}>
                  Close
                </Button>
              </DialogActions>
            </>
          )}
      </Dialog>
      {isNotNullOrUndefined(actionButton.confirmationAlert) && (
        <ConfirmationDialog
          alert={actionButton.confirmationAlert}
          onClose={confirmationDialogClosed}
          open={confirmationDialogOpen} />)}
    </AppendDomainTypeContext>
  )
}