import { Autocomplete, Stack, TextField } from '@mui/material'
import SaveDomainTypeSettingButton from 'components/domainType/SaveDomainTypeSettingButton'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { DomainType } from 'types'
import { getDomainTypeAttribute, getDomainTypeAttributes, isDomainTypeListAttribute, isValidStartEndDateAttribute, isValidTimelineGroupByAttribute, isValidTimelineRouteByAttribute } from 'utils/helpers'
import { useDomainTypeSetting } from 'utils/hooks'
import { FindPageView } from './types'

interface Props {
  readonly domainType: DomainType
  readonly view: FindPageView
  onClose(): void
}

export default function CalendarTimelineSettingsPanel({
  domainType,
  view,
  onClose
}: Props): JSX.Element {
  const domainTypes = useSelector(getAllDomainTypes)
  const [timelineItems, onItemChange] = useDomainTypeSetting(
    domainType,
    'TimelineItems',
    ''
  )
  const timelineItemsAttribute = getDomainTypeAttribute(domainTypes, domainType, timelineItems)
  const attributes = getDomainTypeAttributes(domainTypes, domainType)
  const filteredAttributes = attributes.filter(isDomainTypeListAttribute)
  let itemsDomainType = domainType
  if (view === 'timeline' && isDomainTypeListAttribute(timelineItemsAttribute)) {
    itemsDomainType = domainTypes[timelineItemsAttribute.AttributeDomainType] ?? domainType
  }
  const [startDate, onStartDateChange] = useDomainTypeSetting(
    itemsDomainType,
    'StartDate',
    ''
  )
  const [endDate, onEndDateChange] = useDomainTypeSetting(
    itemsDomainType,
    'EndDate',
    ''
  )
  const [timelineGroupBy, onGroupChange] = useDomainTypeSetting(
    itemsDomainType,
    'TimelineGroupBy',
    ''
  )
  const [timelineRouteBy, onRouteChange] = useDomainTypeSetting(
    itemsDomainType,
    'TimelineRouteBy',
    ''
  )
  const startDateAttribute = getDomainTypeAttribute(domainTypes, itemsDomainType, startDate)
  const endDateAttribute = getDomainTypeAttribute(domainTypes, itemsDomainType, endDate)
  const timelineGroupByAttribute = getDomainTypeAttribute(domainTypes, itemsDomainType, timelineGroupBy)
  const timelineRouteByAttribute = getDomainTypeAttribute(domainTypes, itemsDomainType, timelineRouteBy)
  const itemAttributes = getDomainTypeAttributes(domainTypes, itemsDomainType)
  const dateOptions = itemAttributes.filter(isValidStartEndDateAttribute)
  const groupByOptions = itemAttributes.filter(isValidTimelineGroupByAttribute)
  const routeByOptions = itemAttributes.filter(isValidTimelineRouteByAttribute)
  return (
    <Stack
      gap={2}
      p={2}>
      {view === 'timeline' && (
        <Stack
          direction='row'
          gap={1}>
          <Autocomplete
            sx={{ flexGrow: 1 }}
            blurOnSelect
            value={attributes.find(attribute => attribute.Name === timelineItemsAttribute?.Name)}
            options={filteredAttributes}
            getOptionLabel={attribute => attribute.Title}
            onChange={(event, value) => {
              onItemChange(value?.Name ?? null)
            }}
            renderInput={params => (
              <TextField
                {...params}
                size='small'
                label='Items' />
            )} />
          <SaveDomainTypeSettingButton
            domainType={domainType}
            setting='TimelineItems'
            value={timelineItemsAttribute?.Name} />
        </Stack>
      )}
      <Stack
        direction='row'
        gap={1}>
        <Autocomplete
          sx={{ flexGrow: 1 }}
          blurOnSelect
          value={itemAttributes.find(attribute => attribute.Name === startDateAttribute?.Name)}
          options={dateOptions}
          getOptionLabel={attribute => attribute.Title}
          onChange={(event, value) => {
            onStartDateChange(value?.Name ?? null)
          }}
          renderInput={params => (
            <TextField
              {...params}
              size='small'
              label='Start Date' />
          )} />
        <SaveDomainTypeSettingButton
          domainType={itemsDomainType}
          setting='StartDate'
          value={startDateAttribute?.Name} />
      </Stack>
      <Stack
        direction='row'
        gap={1}>
        <Autocomplete
          sx={{ flexGrow: 1 }}
          blurOnSelect
          value={itemAttributes.find(attribute => attribute.Name === endDateAttribute?.Name)}
          options={dateOptions}
          getOptionLabel={attribute => attribute.Title}
          onChange={(event, value) => {
            onEndDateChange(value?.Name ?? null)
          }}
          renderInput={params => (
            <TextField
              {...params}
              size='small'
              label='End Date' />
          )} />
        <SaveDomainTypeSettingButton
          domainType={itemsDomainType}
          setting='EndDate'
          value={endDateAttribute?.Name} />
      </Stack>
      {view === 'timeline' && (
        <>
          <Stack
            direction='row'
            gap={1}>
            <Autocomplete
              sx={{ flexGrow: 1 }}
              blurOnSelect
              value={itemAttributes.find(attribute => attribute.Name === timelineGroupByAttribute?.Name)}
              options={groupByOptions}
              getOptionLabel={attribute => attribute.Title}
              onChange={(event, value) => {
                onGroupChange(value?.Name ?? null)
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  size='small'
                  label='Group By' />
              )} />
            <SaveDomainTypeSettingButton
              domainType={itemsDomainType}
              setting='TimelineGroupBy'
              value={timelineGroupByAttribute?.Name} />
          </Stack>
          <Stack
            direction='row'
            gap={1}>
            <Autocomplete
              sx={{ flexGrow: 1 }}
              blurOnSelect
              value={itemAttributes.find(attribute => attribute.Name === timelineRouteByAttribute?.Name)}
              options={routeByOptions}
              getOptionLabel={attribute => attribute.Title}
              onChange={(event, value) => {
                onRouteChange(value?.Name ?? null)
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  size='small'
                  label='Route By' />
              )} />
            <SaveDomainTypeSettingButton
              domainType={itemsDomainType}
              setting='TimelineRouteBy'
              value={timelineRouteByAttribute?.Name} />
          </Stack>
        </>
      )}
    </Stack>
  )
}