import { useMemo } from 'react'
import { DomainTypeAttribute, ListAttribute } from 'types'
import { isNullOrUndefined } from 'utils/helpers'
import { useDomainType } from '.'
import { DATAFORM_RESULTS_DOMAIN_TYPE_NAME } from 'utils/constants'

export function useDataformResultsAttribute(): ListAttribute<DomainTypeAttribute> | null {
  const dataformResultsDomainType = useDomainType(DATAFORM_RESULTS_DOMAIN_TYPE_NAME, null)
  return useMemo(() => {
    if (isNullOrUndefined(dataformResultsDomainType)) {
      return null
    }
    return {
      Name: '@DataformResults',
      Title: 'Dataform Results',
      AttributeType: 'domainType',
      List: true,
      AttributeDomainType: dataformResultsDomainType.Id
    }
  }, [dataformResultsDomainType])
}