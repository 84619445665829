import { TextField, TextFieldProps } from '@mui/material'
import { NonListContextRefAttributeValue, PathError } from 'types'
import { DEFAULT_ATTRIBUTE_INPUT_SIZE, DEFAULT_ATTRIBUTE_INPUT_VARIANT } from 'utils/constants'
import { toErrorText } from 'utils/helpers'
import ContextRefCell from '../../AttributeCell/ContextRefCell'
import LabelledInput from '../LabelledInput'

interface ReadOnlyProps {
  readonly attributeValue: NonListContextRefAttributeValue
  readonly pathError?: PathError
  readonly textFieldProps?: Partial<TextFieldProps>
}

export default function ReadOnlyContextRefInput({
  attributeValue,
  pathError,
  textFieldProps
}: ReadOnlyProps): JSX.Element {
  if (attributeValue.value === null) {
    return (
      <TextField
        label={attributeValue.attribute.Title}
        id={attributeValue.attribute.Name}
        variant={DEFAULT_ATTRIBUTE_INPUT_VARIANT}
        fullWidth
        size={DEFAULT_ATTRIBUTE_INPUT_SIZE}
        required={attributeValue.attribute.Required ?? false}
        error={pathError !== undefined}
        helperText={toErrorText(pathError)}
        {...textFieldProps}
        inputProps={{
          ...textFieldProps?.inputProps,
          readOnly: true
        }} />
    )
  }
  return (
    <LabelledInput
      label={attributeValue.attribute.Title}
      required={attributeValue.attribute.Required ?? false}>
      <ContextRefCell attributeValue={attributeValue} />
    </LabelledInput>
  )
}