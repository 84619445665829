import { Box, BoxProps, CircularProgress } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { OverridableDomainTypeComponent } from 'components/overrides'
import DomainTypeDetailsButton from 'components/utils/DomainTypeDetailsButton'
import Help from 'components/utils/Help'
import { PropsWithChildren, useContext } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { DomainType, DomainTypeInstance } from 'types'
import { DomainTypeSettingsContext } from 'utils/context'
import { getHeading } from 'utils/helpers'
import DomainTypeIcon from './DomainTypeIcon'

interface Props {
  domainType: DomainType
  instance?: DomainTypeInstance
  isLoading: boolean
  title?: string
  plural?: boolean
  count?: number
  flexWrap?: BoxProps['flexWrap']
}

export function DefaultDomainTypeHeading({
  domainType,
  instance = {},
  isLoading,
  title,
  plural = false,
  count,
  flexWrap,
  children
}: PropsWithChildren<Props>): JSX.Element {
  const domainTypes = useSelector(getAllDomainTypes)
  const settingsContext = useContext(DomainTypeSettingsContext)
  const heading = getHeading(settingsContext, domainTypes, domainType, instance)
  const theme = useTheme()
  return (
    <Box
      display='flex'
      alignItems='center'
      gap={1}
      padding={0}
      flexWrap={flexWrap ?? 'nowrap'}
      width='100%'>
      {title !== undefined
        ? (
          <h3 style={{ margin: 0 }}>{title}</h3>
        )
        : null}
      <DomainTypeIcon
        avatar
        avatarScale={1.2}
        domainType={domainType} />
      <Help
        type='domainType'
        domainType={domainType}>
        <h3
          style={{
            margin: 0,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            color: theme.palette.text.secondary
          }}>
          {!isLoading && `${count !== undefined && count > 1 ? `${count} ` : ''}${plural ? domainType.PluralTitle : heading}`}
        </h3>
      </Help>
      <DomainTypeDetailsButton id={domainType.Id} />
      {isLoading
        ? (
          <CircularProgress size='1.5rem' />
        )
        : children}
    </Box>
  )
}

export default function DomainTypeHeading(
  props: PropsWithChildren<Props>
): JSX.Element | null {
  return (
    <OverridableDomainTypeComponent<'heading'>
      component='heading'
      domainType={props.domainType}
      props={props}
      DefaultComponent={DefaultDomainTypeHeading} />
  )
}
