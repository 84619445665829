import { useEffect, useRef, useState } from 'react'
import { Filter, Sort } from 'types'
import { v4 } from 'uuid'
import filtersReducer, { clearEmptyFilters } from './filtersReducer'

interface Output {
  sortsRef: React.MutableRefObject<{
    sorts: Sort[]
  }>
  filtersRef: React.MutableRefObject<{
    filterLinkOperator: 'and' | 'or'
    filters: Filter[]
  }>
  panelKey: string
}

export default function useSortsAndFilters(
  panelOpen: string | false,
  sorts: Sort[],
  onSortsChange: (sorts: Sort[]) => void,
  filters: Filter[],
  onFiltersChange: (filters: Filter[]) => void,
  filterLinkOperator: 'and' | 'or',
  onFilterLinkOperatorChange: (filterLinkOperator: 'and' | 'or') => void
): Output {
  const [panelKey, setPanelKey] = useState(v4())
  useEffect(() => {
    setPanelKey(v4())
  }, [sorts, filters, filterLinkOperator])

  const sortsRef = useRef({
    sorts
  })
  const filtersRef = useRef({
    filters,
    filterLinkOperator
  })
  useEffect(() => {
    sortsRef.current.sorts = sorts
  }, [sorts])
  useEffect(() => {
    filtersRef.current.filterLinkOperator = filterLinkOperator
    filtersRef.current.filters = filters
  }, [filterLinkOperator, filters])

  const lastPanelOpenValueRef = useRef<string | false>(panelOpen)

  useEffect(() => {
    if (lastPanelOpenValueRef.current === 'sorts' && panelOpen !== 'sorts') {
      if (sortsRef.current.sorts !== sorts) {
        onSortsChange(sortsRef.current.sorts)
      }
    }
    if (lastPanelOpenValueRef.current === 'filters' && panelOpen !== 'filters') {
      filtersRef.current.filters = filtersReducer(filtersRef.current.filters, clearEmptyFilters())
      if (filtersRef.current.filterLinkOperator !== filterLinkOperator) {
        onFilterLinkOperatorChange(filtersRef.current.filterLinkOperator)
      }
      if (filtersRef.current.filters !== filters) {
        onFiltersChange(filtersRef.current.filters)
      }
    }

    lastPanelOpenValueRef.current = panelOpen
  }, [panelOpen, lastPanelOpenValueRef, filters, onFiltersChange, onFilterLinkOperatorChange, filterLinkOperator, sorts, onSortsChange])
  return {
    sortsRef,
    filtersRef,
    panelKey
  }
}