import { TextField, TextFieldProps } from '@mui/material'
import AttributeCell from 'components/attribute/AttributeCell'
import { LabelledInput } from 'components/attribute/AttributeInput'
import { DomainTypeAttributeValue, PathError } from 'types'
import { DEFAULT_ATTRIBUTE_INPUT_SIZE, DEFAULT_ATTRIBUTE_INPUT_VARIANT } from 'utils/constants'
import { toErrorText } from 'utils/helpers'

interface ReadOnlyDomainTypeProps {
  readonly attributeValue: DomainTypeAttributeValue
  readonly pathError?: PathError
  readonly textFieldProps?: Partial<TextFieldProps>
}

export default function ReadOnlyDomainTypeInput({
  attributeValue,
  pathError,
  textFieldProps
}: ReadOnlyDomainTypeProps): JSX.Element | null {
  if (attributeValue.value === null) {
    return (
      <TextField
        label={attributeValue.attribute.Title}
        id={attributeValue.attribute.Name}
        variant={DEFAULT_ATTRIBUTE_INPUT_VARIANT}
        fullWidth
        size={DEFAULT_ATTRIBUTE_INPUT_SIZE}
        required={attributeValue.attribute.Required ?? false}
        error={pathError !== undefined}
        helperText={toErrorText(pathError)}
        {...textFieldProps}
        InputProps={{
          ...textFieldProps?.InputProps,
          readOnly: true
        }} />
    )
  }
  return (
    <LabelledInput
      label={attributeValue.attribute.Title}
      required={attributeValue.attribute.Required ?? false}>
      <AttributeCell attributeChainValue={attributeValue} />
    </LabelledInput>
  )
}