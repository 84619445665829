import { CHANGE_THEME, ChangeThemeAction, SET_HELP, SET_MENU_SECTION_OPEN, SetHelpAction, SetMenuSectionOpenAction, TOGGLE_SIDE_MENU_PINNED, ToggleSideMenuPinnedAction } from 'types'

export function changeTheme(darkMode: boolean): ChangeThemeAction {
  return {
    type: CHANGE_THEME,
    payload: {
      theme: darkMode ? 'dark' : 'light'
    }
  }
}

export function toggleSideMenuExpanded(): ToggleSideMenuPinnedAction {
  return {
    type: TOGGLE_SIDE_MENU_PINNED
  }
}

export function setMenuSectionOpen(
  id: string | null,
  open: boolean
): SetMenuSectionOpenAction {
  return {
    type: SET_MENU_SECTION_OPEN,
    payload: {
      id,
      open
    }
  }
}

export function setHelp(value: boolean): SetHelpAction {
  return {
    type: SET_HELP,
    payload: {
      value
    }
  }
}